import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'
import { switcheo } from '../palettes/colors'

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiListItem']>> => ({
  root: {
    fontSize: 13,
    paddingTop: 4,
    paddingBottom: 4,
    '&.Mui-selected': {
      backgroundColor: switcheo.background.grey,
    },
  },
})
