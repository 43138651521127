import { Tendermint37Client } from "@cosmjs/tendermint-rpc"
import { CarbonSDK } from 'carbon-js-sdk'
import { SimpleBlock } from "js/models"
import { SimpleMap, ValPair } from "js/utils"
import { Action } from 'redux'
import { TradeHubSDK } from 'tradehub-api-js'

export enum ActionType {
  CORE_UPDATE_SDK = 'CORE_UPDATE_SDK',
  CORE_UPDATE_TM_WS_CLIENT = 'CORE_UPDATE_TM_WS_CLIENT',
  CORE_UPDATE_CARBON_SDK = 'CORE_UPDATE_CARBON_SDK',
  CORE_UPDATE_VAL_ADDR_MAP = 'CORE_UPDATE_VAL_ADDR_MAP',
  CORE_UPDATE_BLOCKS = 'CORE_UPDATE_BLOCKS',
}

export type CoreAction =
  | UpdateSDKAction
  | UpdateCarbonSDKAction
  | UpdateTmWsClientAction
  | UpdateValAddrMapAction
  | UpdateBlocksAction

export interface UpdateSDKAction extends Action<ActionType.CORE_UPDATE_SDK> {
  sdk: TradeHubSDK | null
}

export function updateSDK(sdk: TradeHubSDK | null): UpdateSDKAction {
  return {
    type: ActionType.CORE_UPDATE_SDK,
    sdk,
  }
}

export interface UpdateTmWsClientAction extends Action<ActionType.CORE_UPDATE_TM_WS_CLIENT> {
  tmWsClient?: Tendermint37Client
}

export function updateTmWsClient(tmWsClient?: Tendermint37Client): UpdateTmWsClientAction {
  return {
    type: ActionType.CORE_UPDATE_TM_WS_CLIENT,
    tmWsClient,
  }
}

export interface UpdateCarbonSDKAction extends Action<ActionType.CORE_UPDATE_CARBON_SDK> {
  sdk?: CarbonSDK
}

export function updateCarbonSDK(sdk?: CarbonSDK): UpdateCarbonSDKAction {
  return {
    type: ActionType.CORE_UPDATE_CARBON_SDK,
    sdk,
  }
}

export interface UpdateValAddrMapAction extends Action<ActionType.CORE_UPDATE_VAL_ADDR_MAP> {
  map: SimpleMap<ValPair>
}

export function updateValAddrMap(map: SimpleMap<ValPair>): UpdateValAddrMapAction {
  return {
    type: ActionType.CORE_UPDATE_VAL_ADDR_MAP,
    map,
  }
}
export interface UpdateBlocksAction extends Action<ActionType.CORE_UPDATE_BLOCKS> {
  blocks: SimpleBlock[]
}

export function updateBlocks(blocks: SimpleBlock[]): UpdateBlocksAction {
  return {
    type: ActionType.CORE_UPDATE_BLOCKS,
    blocks,
  }
}
