import { makeStyles, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import { DataLoadSegment, ListTable, Page, Section, TableCellInfo, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { ReactElement } from 'react'
import { NodeRow } from './components'
import { NodeHeaders } from './nodesConfig'

interface Props { }

const Nodes: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const network = useRedux((state) => state.app.network)
  const nodes = useRedux((state) => state.nodes.nodes)
  const [loading] = useTaskSubscriber(TaskNames.Nodes.List)

  if (network !== CarbonSDK.Network.MainNet) {
    return (
      <Typography variant="body1">Nodes information is only available on MainNet.</Typography>
    )
  }

  return (
    <Page title='Nodes' className={classes.root}>
      <Section>
        <DataLoadSegment loading={loading && !nodes?.length}>
          <ListTable>
            <TableHead>
              <TableRow>
                {NodeHeaders.map((header: TableCellInfo, index: number) => (
                  <TableCell align={header?.align} key={index}>{header?.content}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {nodes?.map((node, index) => (
                // nodeId is not unique
                <NodeRow model={node} key={node.nodeId + index} />
              ))}
            </TableBody>
          </ListTable>
          {!nodes?.length && (
            <TableEmptyState itemName="nodes" />
          )}
        </DataLoadSegment>
      </Section>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
}))

export default Nodes
