import { NodeItem } from 'carbon-js-sdk/lib/insights'
import { Record, RecordOf } from 'immutable'
import { NodesAction, NodesActionType } from '../actions/nodes'

export interface NodeModel extends NodeItem {
  rating: string,
  latency: string,
}
export interface NodesProps {
  nodes: NodeModel[]
}

const makeState: Record.Factory<NodesProps> = Record<NodesProps>({
  nodes: [],
})
export type NodesState = RecordOf<NodesProps>

function nodesReducer(
  state: NodesState = makeState(),
  action: NodesAction,
): NodesState {
  switch (action.type) {
    case NodesActionType.CLEAR:
      return makeState()
    case NodesActionType.SET_NODES:
      return state.updateIn(['nodes'], () => action.nodes)
    default:
      return state
  }
}

export default nodesReducer
