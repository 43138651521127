import BigNumber from "bignumber.js"
import { CDPModule } from "carbon-js-sdk"
import { DebtInfo } from "carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/debt_info"
import { Params } from "carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/params"
import { RewardScheme } from "carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/reward_scheme"
import { Token } from "carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token"
import dayjs from "dayjs"
import { BN_ONE, BN_ZERO, SimpleMap, bnOrZero } from "js/utils"
import moment from "moment"

const secondsInMinBN = new BigNumber(60)
const secondsInHourBN = secondsInMinBN.multipliedBy(60)
const secondsInDayBN = secondsInHourBN.multipliedBy(24)
const secondsInYearBN = secondsInDayBN.multipliedBy(365)

export const getTokenAprArr = async (rewardSchemes: RewardScheme[], tokenPrices: SimpleMap<BigNumber>, tokens: SimpleMap<Token>, type: string, underlyingDenom: string, totalSharesUSD: BigNumber) => {
    const addRewardsUsd = await getRewardMap(rewardSchemes, tokenPrices)
    const schemeKey = `${underlyingDenom}:${type}`
    const rewardsMap = addRewardsUsd[schemeKey] ?? {}
    const totalSharesUSDBN = new BigNumber(totalSharesUSD)
    let totalRewardUsd = BN_ZERO
    Object.keys(rewardsMap).reduce((prev, rewardKey) => {
        const newPrev = [...prev] as any
        const rewardDecimals = Object.values(tokens ?? [])?.find((o) => o.denom === rewardKey)?.decimals
        const rewardUsd = (rewardsMap[rewardKey] ?? BN_ZERO).shiftedBy(-(rewardDecimals ?? 6))
        totalRewardUsd = totalRewardUsd.plus(rewardUsd)
        newPrev.push({
            denom: rewardKey,
            apr: totalSharesUSDBN.isZero() ? BN_ZERO : rewardUsd.div(totalSharesUSDBN),
        })
        return newPrev
    }, [])
    return { overallRewardApr: totalSharesUSDBN.isZero() ? BN_ZERO : totalRewardUsd.div(totalSharesUSDBN) }
}

const getRewardMap = async (rewardSchemes: RewardScheme[], tokenPrices: SimpleMap<BigNumber>) => {
    const schemesArr = Object.values(rewardSchemes)
    const activeSchemes = schemesArr.filter((rewardScheme) => {
        const start = moment.utc(rewardScheme.startTime).unix()
        const end = moment.utc(rewardScheme.endTime).unix()
        const now = moment.utc().unix()
        return now >= start && now < end
    })
    const rewardsUSDMap: any = {}
    for (let ii = 0; ii < activeSchemes.length; ii++) {
        const indivScheme = activeSchemes[ii]
        // get projected rewards earned in 1 year
        const projectedRewardsOwedRaw = getProjectedRewardsOwed(indivScheme, tokenPrices)
        let underlyingDenom = indivScheme.assetDenom.replace("cibt/", "")

        const schemeKey = `${underlyingDenom}:${indivScheme.rewardType}`
        if (!rewardsUSDMap[schemeKey]) {
            rewardsUSDMap[schemeKey] = {}
        }

        if (rewardsUSDMap[schemeKey][indivScheme.rewardDenom]) {
            rewardsUSDMap[schemeKey] = {
                ...rewardsUSDMap[schemeKey],
                [indivScheme.rewardDenom]: rewardsUSDMap[schemeKey][indivScheme.rewardDenom].plus(projectedRewardsOwedRaw),
            }
        } else {
            rewardsUSDMap[schemeKey] = {
                ...rewardsUSDMap[schemeKey],
                [indivScheme.rewardDenom]: projectedRewardsOwedRaw,
            }
        }
    }
    return rewardsUSDMap
}

function getProjectedRewardsOwed(rewardScheme: RewardScheme, tokenPrices: SimpleMap<BigNumber>) {
    const rewardUsdValue = tokenPrices[rewardScheme.rewardDenom] ?? 1
    const rewardAmountPerSecond = bnOrZero(rewardScheme.rewardAmountPerSecond)
    const projectedRewardsOwed = (rewardAmountPerSecond.times(secondsInYearBN))
    return projectedRewardsOwed.times(rewardUsdValue)
}

export const calculateInterestForTimePeriod = (offsetSeconds: number = 0, debtInfo: DebtInfo | undefined, borrowAPY: BigNumber) => {
    if (!debtInfo) return BN_ONE
    const interestAPY = borrowAPY

    const now = dayjs().add(offsetSeconds, 'seconds').toDate()
    const lastDate = debtInfo.lastUpdatedTime ?? now
    const interest = CDPModule.calculateInterestForTimePeriod(interestAPY, lastDate, now)
    return interest
}

const getTotalDebt = (offsetSeconds: number, debtInfo: DebtInfo | undefined, params: Params | undefined, borrowAPY: BigNumber) => {
    if (!debtInfo) return BN_ZERO
    const totalUnderlyingPrincipal = bnOrZero(debtInfo.totalPrincipal)
    const interestRate = calculateInterestForTimePeriod(offsetSeconds, debtInfo, borrowAPY)
    const accumInterest = bnOrZero(debtInfo.totalAccumulatedInterest)

    const newInterest = totalUnderlyingPrincipal.times(interestRate).plus(accumInterest.times(BN_ONE.plus(interestRate)))
    const interest = newInterest.times(BN_ONE.minus(params?.interestFee ?? 0)).dp(0)
    return totalUnderlyingPrincipal.plus(interest)
}

const getCdpRatio = (debtInfo: DebtInfo | undefined, params: Params | undefined, borrowAPY: BigNumber, cdpModuleUnderlyingBalance: BigNumber, cdpTotalSupply: BigNumber) => {
    const totalDebt = getTotalDebt(0, debtInfo, params, borrowAPY)
    const totalUnderlying = BigNumber.sum(cdpModuleUnderlyingBalance, totalDebt)
    const cdpRatio = totalUnderlying.isZero() ? BN_ONE : cdpTotalSupply.div(totalUnderlying)
    return cdpRatio
}

export const getTotalCdpSharesUSD = (debtInfo: DebtInfo | undefined, params: Params | undefined, borrowAPY: BigNumber, decimals: number, totalSupplyBN: BigNumber, cdpModBalances: BigNumber, usd: BigNumber) => {
    const cdpRatio = getCdpRatio(debtInfo, params, borrowAPY, cdpModBalances, totalSupplyBN)
    const underlyingAmount = !cdpRatio.isFinite() ? totalSupplyBN.div(cdpRatio) : totalSupplyBN
    return underlyingAmount.times(usd).shiftedBy(-decimals)
}