import BigNumber from 'bignumber.js';
import { Insights } from 'carbon-js-sdk';
import { QueryAllPoolResponse, QueryCommitmentCurveResponse, QueryRewardCurveResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/query';
import { LiquidityPoolsActionType, clear, setCommitmentCurve, setLiquidityPools, setPoolsTotalLiquidityChartData, setPoolsUtilisationChartData, setPoolsVolume, setPoolsVolumeLiquidityChartData, setRewardCurve, setWeeklyPoolRewards } from 'js/actions/liquidityPools';
import { CARBON_GENESIS_BLOCKTIME, TaskNames } from 'js/constants';
import { SimpleMap } from 'js/utils';
import Long from 'long';
import moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { Effect, call, put, spawn, takeLatest } from 'redux-saga/effects';
import { AppActionType } from '../actions/app';
import { BN_ZERO } from './../utils/number/index';
import Saga from './Saga';
import { runSagaTask, waitforSDK } from './helper';

export default class LiquidityPools extends Saga {
  private readonly isMobile: boolean
  private isFirstLoad: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
    this.isFirstLoad = true
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchPools],
      [this, this.fetchPoolsVolume],
      [this, this.fetchWeeklyPoolRewards],
      [this, this.fetchRewardCurve],
      [this, this.fetchCommitmentCurve],
      [this, this.fetchPoolsTotalLiquidityChart],
      [this, this.fetchPoolsVolumeLiquidityChart],
      [this, this.fetchPoolsUtilisationChart],
      [this, this.watchSetNetwork],
      [this, this.watchDateFilters],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *watchDateFilters(): any {
    yield takeLatest(
      LiquidityPoolsActionType.UPDATE_DATE_FILTERS_TOTAL_LIQUIDITY,
      this.handleDateFiltersTotalLiquidity,
    )
    yield takeLatest(
      LiquidityPoolsActionType.UPDATE_DATE_FILTERS_UTILISATION,
      this.handleDateFiltersUtilisation,
    )
    yield takeLatest(
      LiquidityPoolsActionType.UPDATE_DATE_FILTERS_VOLUME_LIQUIDITY,
      this.handleDateFiltersVolumeLiquidity,
    )
  }

  private *handleDateFiltersTotalLiquidity(action: any): any {
    yield runSagaTask(TaskNames.Pools.TotalLiquidityChart, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon
      const from = moment(moment(action.options.startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(Date.now()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const poolLiquidityResponse = (yield call([sdk.insights, sdk.insights.PoolsLiquidity], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetPoolsLiquidityResponse>
      yield put(setPoolsTotalLiquidityChartData(poolLiquidityResponse.result.entries))
    })
  }

  private *handleDateFiltersUtilisation(action: any): any {
    yield runSagaTask(TaskNames.Pools.UtilisationChart, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon
      const from = moment(moment(action.options.startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(Date.now()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const poolLiquidityResponse = (yield call([sdk.insights, sdk.insights.PoolsVolume], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetPoolsVolumeResponse>
      yield put(setPoolsUtilisationChartData(poolLiquidityResponse.result.entries))
    })
  }

  private *handleDateFiltersVolumeLiquidity(action: any): any {
    yield runSagaTask(TaskNames.Pools.VolumeLiquidityChart, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon 
      const from = moment(moment(action.options.startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(Date.now()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const poolLiquidityResponse = (yield call([sdk.insights, sdk.insights.PoolsVolume], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetPoolsVolumeResponse>
      yield put(setPoolsVolumeLiquidityChartData(poolLiquidityResponse.result.entries))
    })
  }

  private * fetchPools(): any {
    yield runSagaTask(TaskNames.Pools.List, function* () {
      const sdk = yield* waitforSDK()
      const lpQueryClient = sdk.query.liquiditypool
      const pools = (yield call([lpQueryClient, lpQueryClient.PoolAll], {
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })) as QueryAllPoolResponse
      yield put(setLiquidityPools(pools.pools))
    })
  }

  private *fetchPoolsVolume(): any {
    yield runSagaTask(TaskNames.Pools.PoolsVolume, function* () {
      const sdk = yield* waitforSDK()
      //Get Unix time of now and 24hour before
      const from = moment().subtract(1, "day").unix().toString()
      const until = moment().unix().toString()
      const InsightsQueryClient = sdk.insights
      const poolVolumesResponse = (yield call([InsightsQueryClient, InsightsQueryClient.PoolsVolume], {
        from,
        until,
        interval: 'hour'
      })) as Insights.InsightsQueryResponse<Insights.QueryGetPoolsVolumeResponse>
      const poolVolumes24Hour = poolVolumesResponse.result.entries
      const poolVolumeMap: SimpleMap<BigNumber> = {}
      poolVolumes24Hour.forEach(
        (hourlyData) => {
          hourlyData.markets.forEach((poolVolume) => {
            if ((poolVolume.poolId in poolVolumeMap) === false) {
              poolVolumeMap[poolVolume.poolId] = BN_ZERO
            }
            poolVolumeMap[poolVolume.poolId] = new BigNumber(poolVolume.volumeValue).plus(poolVolumeMap[poolVolume.poolId])
          })
        }
      )
      yield put(setPoolsVolume(poolVolumeMap))
    })
  }

  // TODO: Migrate carbon insights
  private *fetchWeeklyPoolRewards(): any {
    yield runSagaTask(TaskNames.Pools.WeeklyRewards, function* () {
      const sdk = yield* waitforSDK()
      const rewards = (yield call([sdk.lp, sdk.lp.getWeeklyRewards])) as BigNumber
      yield put(setWeeklyPoolRewards(rewards))
    })
  }

  private *fetchCommitmentCurve(): any {
    yield runSagaTask(TaskNames.Pools.CommitmentCurve, function* () {
      const sdk = yield* waitforSDK()
      const lpQueryClient = sdk.query.liquiditypool
      const commitmentCurve = (yield call([lpQueryClient, lpQueryClient.CommitmentCurve], {})) as QueryCommitmentCurveResponse
      yield put(setCommitmentCurve(commitmentCurve.commitmentCurve))
    })
  }

  private *fetchRewardCurve(): any {
    yield runSagaTask(TaskNames.Pools.RewardCurve, function* () {
      const sdk = yield* waitforSDK()
      const lpQueryClient = sdk.query.liquiditypool
      const rewardCurve = (yield call([lpQueryClient, lpQueryClient.RewardCurve], {})) as QueryRewardCurveResponse
      yield put(setRewardCurve(rewardCurve.rewardCurve))
    })
  }

  private *fetchPoolsTotalLiquidityChart(): any {
    if (this.isMobile && this.isFirstLoad) {
      this.isFirstLoad = true
      return
    }
    yield runSagaTask(TaskNames.Pools.TotalLiquidityChart, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon
      const from = moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(Date.now()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const poolLiquidityResponse = (yield call([sdk.insights, sdk.insights.PoolsLiquidity], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetPoolsLiquidityResponse>
      yield put(setPoolsTotalLiquidityChartData(poolLiquidityResponse.result.entries))
    })
  }

  private *fetchPoolsVolumeLiquidityChart(): any {
    if (this.isMobile && this.isFirstLoad) {
      this.isFirstLoad = true
      return
    }
    yield runSagaTask(TaskNames.Pools.VolumeLiquidityChart, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon 
      const from = moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(Date.now()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const poolLiquidityResponse = (yield call([sdk.insights, sdk.insights.PoolsVolume], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetPoolsVolumeResponse>
      yield put(setPoolsVolumeLiquidityChartData(poolLiquidityResponse.result.entries))
    })
  }

  private *fetchPoolsUtilisationChart(): any {
    if (this.isMobile) return
    yield runSagaTask(TaskNames.Pools.UtilisationChart, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon
      const from = moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(Date.now()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const poolLiquidityResponse = (yield call([sdk.insights, sdk.insights.PoolsVolume], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetPoolsVolumeResponse>
      yield put(setPoolsUtilisationChartData(poolLiquidityResponse.result.entries))
    })
  }
}
