import { Box, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { ProposalTypes, TxTypes, OrderTypes } from 'js/constants'
import React, { ReactElement } from 'react'

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  labelMap: { [index: string]: string }
  labelsOverride?: { [index: string]: string }
  fallbackToWord?: boolean
}

const toWord = (input?: string) => {
  if (!input) {
    return ''
  }
  return input.replace(/(?:_[a-z1-9]|^[a-z])/g, (word, index) => {
    return ' ' + word.substr(-1).toUpperCase();
  }).replace(/\s{2,}/g, '')
}

const ParsedLabel: React.FC<Props> = (props: Props): ReactElement<Props> => {
  const { className, children, labelMap, labelsOverride, fallbackToWord, ...rest } = props
  const classes = useStyles(props)
  let label: string = labelMap[(children as string) || '']

  if (!label) {
    if (fallbackToWord) {
      label = toWord(children as string)
    } else {
      label = children as string
    }
  } else if (labelsOverride) {
    label = labelsOverride[label] ?? label
  }

  return (
    <Box component="span" {...rest} className={clsx(classes.root, className)}>
      {label}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))

export default ParsedLabel

type LabelProps = Partial<Props>

export const ProposalTopicLabel: React.FC<LabelProps> = (
  props: LabelProps,
): ReactElement<LabelProps> => (
  <ParsedLabel {...props} labelMap={ProposalTypes.Topics} />
)

export const ProposalParameterLabel: React.FC<LabelProps> = (
  props: LabelProps,
): ReactElement<LabelProps> => (
  <ParsedLabel {...props} labelMap={ProposalTypes.Parameters} />
)

export const TxTypeLabel: React.FC<LabelProps> = (
  props: LabelProps,
): ReactElement<LabelProps> => {
  return (
    <ParsedLabel {...props} labelMap={TxTypes.Types} fallbackToWord />
  )
}

export const OrderStatusLabel: React.FC<LabelProps> = (
  props: LabelProps,
): ReactElement<LabelProps> => {
  return (
    <ParsedLabel {...props} labelMap={OrderTypes.Status} fallbackToWord />
  )
}
