
// used to escape free input text so that
// signature will be generated the same way
// as that of cosmos
export const escapeHtmlGo = (input?: string) => {
  if (!input) return ''
  return input
    .replace(/\u003c/g, '\\u003c')  // <
    .replace(/\u003e/g, '\\u003e')  // >
    .replace(/\u0026/g, '\\u0026')  // &
    .replace(/\u2028/g, '\\u2028')  // line separator
    .replace(/\u2029/g, '\\u2029')  // paragraph separator
}

export const unescapeHtmlGo = (input?: string) => {
  if (!input) return ''
  return input
    .replace(/\\u003c/g, '\u003c')  // <
    .replace(/\\u003e/g, '\u003e')  // >
    .replace(/\\u0026/g, '\u0026')  // &
    .replace(/\\u2028/g, '\u2028')  // line separator
    .replace(/\\u2029/g, '\u2029')  // paragraph separator
}
