import { Proposal, ProposalStatus } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/gov'
import { QueryParamsResponse, QueryProposalResponse, QueryProposalsRequest, QueryProposalsResponse } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/query'
import { QueryDelegatorDelegationsResponse } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/query'
import { QueryAllPoolResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/query'
import { AppActionType } from 'js/actions/app'
import { FetchSubspaceParamsAction, GovernanceActionTypes, clearSubmitProposalFormState, setDelegations, setDepositParams, setIsSubmittingProposal, setProposal, setProposals, setSubmitProposalDepositAmount, setSubmitProposalError, setSubmitProposalId, setSubspaceParams, setTallyParams, updateLiveVoteTally } from 'js/actions/governance'
import { setLiquidityPools } from 'js/actions/liquidityPools'
import { clear } from 'js/actions/validators'
import customToast from 'js/components/Toast/Toast'
import { TaskNames } from 'js/constants'
import { TallyResultResponse } from 'js/reducers/governance'
import Long from 'long'
import { SagaIterator } from 'redux-saga'
import { Effect, all, call, delay, put, select, spawn, takeLatest } from 'redux-saga/effects'
import Saga from './Saga'
import { runSagaTask, waitforSDK } from './helper'
import { QueryParamsResponse as QueryParamsResponseBank } from 'carbon-js-sdk/lib/codec/cosmos/bank/v1beta1/query'
import { QueryParamsResponse as QueryParamsResponseAuth } from 'carbon-js-sdk/lib/codec/cosmos/auth/v1beta1/query'
import { QueryParamsResponse as QueryParamsResponseDistribution } from 'carbon-js-sdk/lib/codec/cosmos/distribution/v1beta1/query'
import { QueryParamsResponse as QueryParamsResponseGov } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/query'
import { QueryParamsResponse as QueryParamsResponseSlashing } from 'carbon-js-sdk/lib/codec/cosmos/slashing/v1beta1/query'
import { QueryParamsResponse as QueryParamsResponseStaking } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/query'
import { QueryParamsResponse as QueryParamsResponseOracle } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/oracle/query'
import { SubspaceParams, SubspaceParamsMap } from 'js/models'
import { GovParametersType } from '@cosmjs/launchpad/build/lcdapi/gov'

export default class Governance extends Saga {
  private readonly isMobile: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchTallyParameters]),
      call([this, this.fetchDepositParameters]),
      spawn([this, this.pollProposals]),
      spawn([this, this.pollDelegations]),
      spawn([this, this.watchSubmitProposal]),
      spawn([this, this.fetchPools]),
      spawn([this, this.watchSetNetwork]),
      spawn([this, this.watchFetchSubspaceParams]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }


  private *pollProposals(): any {
    while (true) {
      yield runSagaTask(TaskNames.Governance.Proposals, function* () {
        const sdk = yield* waitforSDK()
        const govQueryClient = sdk.query.gov

        const proposalsResponse = (yield call([govQueryClient, govQueryClient.Proposals], QueryProposalsRequest.fromPartial({
          pagination: {
            limit: new Long(10000),
            offset: Long.UZERO,
            countTotal: false,
            reverse: false,
          }
        }))) as QueryProposalsResponse
        const proposals = proposalsResponse.proposals

        proposals.sort((lhs, rhs) => {
          return rhs.submitTime?.getTime()! - lhs.submitTime?.getTime()! || lhs.id.toInt() - rhs.id.toInt()
        })


        const finalizedProposals: Proposal[] = []
        const liveProposals: Proposal[] = []

        proposals.forEach((proposal) => {
          if ([ProposalStatus.PROPOSAL_STATUS_PASSED, ProposalStatus.PROPOSAL_STATUS_REJECTED].includes(proposal.status)) {
            finalizedProposals.push(proposal)
          } else {
            liveProposals.push(proposal)
          }
        })
        const liveTallies = (yield all(liveProposals.map((proposal: Proposal) => {
          return call([
            sdk,
            govQueryClient.TallyResult
          ], { proposalId: proposal.id })
        }))) as TallyResultResponse[]

        yield all(finalizedProposals.map((proposal: Proposal) => {
          return put(updateLiveVoteTally(proposal.id.toString(), proposal.finalTallyResult!))
        }))
        yield all(liveTallies.map((tally: TallyResultResponse, index: number) => {
          return put(updateLiveVoteTally(liveProposals[index].id.toString(), tally.tally))
        }))

        yield put(setProposals(proposals))
      })
      yield delay(10000)
    }
  }

  private *fetchTallyParameters(): any {
    yield runSagaTask(TaskNames.Governance.TallyParams, function* () {
      const sdk = yield* waitforSDK()
      const govQueryClient = sdk.query.gov
      const tallyParams = (yield call([govQueryClient, govQueryClient.Params], {
        paramsType: 'tallying',
      })) as QueryParamsResponse
      yield put(setTallyParams(tallyParams.tallyParams!))
    })
  }

  private *fetchDepositParameters(): any {
    yield runSagaTask(TaskNames.Governance.DepositParams, function* () {
      const sdk = yield* waitforSDK()
      const govQueryClient = sdk.query.gov
      const depositParams = (yield call([govQueryClient, govQueryClient.Params], {
        paramsType: 'deposit',
      })) as QueryParamsResponse
      yield put(setDepositParams(depositParams.depositParams!))
    })
  }

  private *pollDelegations(): any {
    while (true) {
      yield runSagaTask(TaskNames.Governance.Delegations, function* () {
        const sdk = yield* waitforSDK()
        if (!sdk?.wallet) return
        const stakingQueryClient = sdk.query.staking
        const delegationsResponse = (yield call([stakingQueryClient, stakingQueryClient.DelegatorDelegations], {
          delegatorAddr: sdk.wallet.bech32Address,
        })) as QueryDelegatorDelegationsResponse
        yield put(setDelegations(delegationsResponse.delegationResponses))
      })
      yield delay(10000)
    }
  }


  private *watchSubmitProposal(): any {
    yield takeLatest(GovernanceActionTypes.SUBMIT_PROPOSAL, this.handleExecuteSubmitProposal)
  }

  private *handleExecuteSubmitProposal(action: any): any {
    try {
      yield put(setIsSubmittingProposal(true))
      const sdk = yield* waitforSDK()
      const govQueryClient = sdk.query.gov
      const result = yield call([sdk.gov, sdk.gov.submit], action.proposal)
      if (result?.msgResponses?.length) {
        const events = result.events
        const proposalObject = events.find((event: any) => event?.type === 'submit_proposal')
        const proposalAttributes = proposalObject?.attributes
        const proposalIdObject = proposalAttributes?.find((attribute: any) => attribute?.key === 'proposal_id')
        const proposalId = proposalIdObject?.value
        const proposalIdLong = new Long(proposalId)

        // Delay page load and notification until new proposal is found from rest call
        let newProposal = null
        while (!newProposal) {
          const getProposalsResponse = (yield govQueryClient.Proposal({
            proposalId: proposalIdLong,
          })) as QueryProposalResponse
          newProposal = getProposalsResponse.proposal
          if (newProposal) {
            yield put(setProposal(newProposal))
            yield put(setSubmitProposalDepositAmount(''))
            yield put(setSubmitProposalError(null))
            yield put(clearSubmitProposalFormState())
            customToast('Notification', 'Proposal sucessfully submitted')
          }
          yield delay(2000)
        }
        yield put(setSubmitProposalId(proposalId))
      }
    } catch (err) {
      yield put(setSubmitProposalError(err as any))
    } finally {
      yield put(setIsSubmittingProposal(false))
    }
  }

  private * fetchPools(): any {
    yield runSagaTask(TaskNames.Pools.List, function* () {
      const sdk = yield* waitforSDK()
      const lpQueryClient = sdk.query.liquiditypool
      const pools = (yield call([lpQueryClient, lpQueryClient.PoolAll], {
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })) as QueryAllPoolResponse
      yield put(setLiquidityPools(pools.pools))
    })
  }

  private *watchFetchSubspaceParams(): any {
    yield takeLatest(GovernanceActionTypes.FETCH_SUBSPACE_PARAMS, this.fetchSubspaceParams)
  }

  private *fetchSubspaceParams(action: FetchSubspaceParamsAction): any {
    const sdk = yield* waitforSDK()
    let params: SubspaceParams | undefined
    switch (action.subspace) {
      case 'auth':
        const authResponse: QueryParamsResponseAuth = (yield call([sdk.query.auth, sdk.query.auth.Params], {}))
        params = authResponse?.params
        break
      case 'bank':
        const bankResponse: QueryParamsResponseBank = (yield call([sdk.query.bank, sdk.query.bank.Params], {}))
        params = bankResponse?.params
        break
      case 'distribution':
        const distributionResponse: QueryParamsResponseDistribution = (yield call([sdk.query.distribution, sdk.query.distribution.Params], {}))
        params = distributionResponse?.params
        break
      case 'staking':
        const stakingResponse: QueryParamsResponseStaking = (yield call([sdk.query.staking, sdk.query.staking.Params], {}))
        params = stakingResponse?.params
        break
      case 'slashing':
        const slashingResponse: QueryParamsResponseSlashing = (yield call([sdk.query.slashing, sdk.query.slashing.Params], {}))
        params = slashingResponse?.params
        break
      case 'gov':
        // use any paramsType. all params should still be included in response
        const govResponse: QueryParamsResponseGov = (yield call([sdk.query.gov, sdk.query.gov.Params], { paramsType: GovParametersType.Deposit }))
        params = govResponse?.params
        break
      case 'oracle':
        const oracleResponse: QueryParamsResponseOracle = (yield call([sdk.query.oracle, sdk.query.oracle.Params], {}))
        params = oracleResponse?.params
        break
      default:
        throw new Error('unsupported subspace')
    }
    if (params) {
      const currentParams: SubspaceParamsMap | undefined = yield select((state: any) => state.governance.subspaceParams)
      if (currentParams) {
        currentParams[action.subspace] = params
        yield put(setSubspaceParams(currentParams, action.subspace))
      } else {
        let subspaceParams: SubspaceParamsMap = {}
        subspaceParams[action.subspace] = params
        yield put(setSubspaceParams(subspaceParams, action.subspace))
      }
    }
  }
}
