import { Box, BoxProps, Grid, InputLabel, makeStyles, TextField, Theme } from '@material-ui/core'
import { NetworkConfigs } from 'carbon-js-sdk/lib/constant'
import { Blockchain, CHAIN_IDS } from 'carbon-js-sdk/lib/util/blockchain'
import { TokenInitInfo } from 'carbon-js-sdk/lib/util/external'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { StatefulButton } from 'js/components'
import { BlockchainSelectInput } from 'js/components/form'
import { blockchainValueNameMap } from 'js/components/form/BlockchainSelectInput/BlockchainSelectInput'
import { TutorialKeys } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { SearchIcon } from 'js/layouts/Header/assets'
import { CreateTokenProposalFormState } from 'js/models/Governance'
import { actions } from 'js/store'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CreateTokenProposalInitialFormState } from '../constants'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import { requiredFields } from './Helpers/FormConstraints'

interface Props extends BoxProps {
}

const OLD_NEP5_ASSET_ID = [
  'ab38352559b8b203bde5fddfa0b07d8b2525e132', // SWTH
  '78e6d16b914fe15bc16150aeb11d0c2a8e532bdd', // SWH
  '7f86d61ff377f1b12e589a5907152b57e2ad9a7a', // ACAT
  'a0777c3ce2b169d4a23bcba4565e3225a0122d95', // APH
  'a58b56b30425d3d1f8902034996fcac4168ef71d', // ASA
  'de2ed49b691e76754c20fe619d891b78ef58e537', // AVA
  '87d6c2ae68d6a6f411eb72232e75133b2ffc7142', // COUP
  '45d493a6f73fa5f404244a5fb8472fc014ca5885', // CPX
  'b951ecbbc5fe37a9c280a76cb0ce0014827294cf', // DBC
  'acbc532904b6b51b5ea6d19b803d78af70e7e6f9', // EFX
  '591f92493cd6dd003470e41376f79a515abc707f', // GUARD
  'e9a85bc36e409e3f5cca5c43bc3bff338d7ada08', // IMU
  '3a4acd3647086e7c44398aac0349802e6a171129', // NEX
  'c36aee199dbba6c3f439983657558cfb67629599', // NKN
  'c9c0fc5a2b66a29d6b14601e752e6e1a445e088d', // NOS
  'a721d5893480260bd28ca1f395f2c465d0b5b1c2', // NRVE
  '0e86a40588f715fcaf7acd1812d50af478e6e917', // OBT
  'ceab719b8baa2310f232ee0d277c061704541cfb', // ONT
  'af7c7328eee5a275a3bcaee2bf0cf662b5e739be', // PKC
  '39e27f01420ffda3943bcf81e7254769a88437fb', // PKX
  '1578103c13e39df15d0d29826d957e85d770d8c9', // PHX
  '2199c8dd506f73afddcbd6146c6271626931f735', // PROQ
  '0dc27e3977160128c0dd6077a4b5a8b088eed151', // RCPT
  'ecc6b20d3ccac1ee9ef109af5a7cdb85706b1df9', // RPX
  '2328008e6f6c7bd157a342e789389eb034d9cbc4', // RHT
  '6fad54d8cc692fc808fd97a207836a846c217705', // SDS
  'a4f408df2a1ec2a950ec5fd06d7b9dc5f83b9e73', // SDT
  '0a517e427e0de64e9fdbf66ee4dc94ef9e7dad9c', // SDUSDC
  '7146278a76c33fc6bb870fcaa428e3cdb16809ac', // SDUSD
  'd3526b437d65f8c12c902e14320abd440ad1f726', // SENNO
  '132947096727c84c7f9e076c90f08fec3bc17f18', // TKY
  '08e8c4400f1af2c20c28e0018f29535eb85d15b6', // TNC
  '78fd589f7894bf9642b4a573ec0e6957dfd84c48', // TOLL
  'd613223fa138a1555ff711581982462acde209c5', // TMN
  '0d821bd7b6d53f5c2b40e217c6defc8bbe896cf5', // QLC
  '06fa8be9b6609d963e8fc63977b9f8dc5f10895f', // LRN
  'bb3b54ab244b3658155f2db4429fc38ac4cef625', // LX (Old)
  '9c1f315264200988a0d2fbb5489f2c9adb9c765f', // LX (Old 2)
  'cea5a3963a5813a26accc6bc67e9be9d14d395f0', // LX (New)
  'ed07cffad18f1308db51920d99a2af60ac66a7b3', // SOUL
  'ac116d4b8d4ca55e6b6d4ecce2192039b51cccc5', // ZPT
  'f8ff65af5a2c1e7891e485eac2f4e016a1893e75', // NRVEP
  '467673ca33f912b1295e4375125df196039a5465', // RHTC
  'bac0d143a547dc66a1d6a2b7d66b06de42614971', // BRDG
]

const CreateTokenProposalMainForm: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const network = useRedux((state) => state.app.network)
  const sdk = useRedux(state => state.core.carbonSDK)
  const [runQueryToken, loading] = useAsyncTask("queryToken")
  const formState = (useRedux((state) => state.governance.submitProposalFormState) || CreateTokenProposalInitialFormState) as CreateTokenProposalFormState
  const [showForm, setShowForm] = useState(formState.name?.length > 0)
  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    dispatch(setSubmitProposalFormState(formState))
    validateRequiredFields(formState)
    // eslint-disable-next-line
  }, [showForm])

  const validateRequiredFields = (formState: CreateTokenProposalFormState) => {
    const requiredFieldsConstraints = getRequiredFieldsConstraints(requiredFields)
    const errors = getErrorMessages(formState, requiredFieldsConstraints)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const autoGenerateTitle = (formKey: keyof CreateTokenProposalFormState, inputValue: string): string => {
    const name = formKey === 'name' ? inputValue : formState.name

    return `Create ${name} token`
  }

  const handleFormChange = (key: keyof CreateTokenProposalFormState, isTitleInvolved?: boolean) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value
      const newFormState = {
        ...formState,
        [key]: value as string,
        title: isTitleInvolved ? autoGenerateTitle(key, value as string) : formState.title,

        ...key === 'blockchain' && {
          chainId: CHAIN_IDS[value as Blockchain].toString(),
        },
      }

      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  useEffect(() => {
    let lockProxyHash = ''
    switch (formState.blockchain) {
      case Blockchain.Ethereum:
        lockProxyHash = NetworkConfigs[network].eth.lockProxyAddr
        break
      case Blockchain.BinanceSmartChain:
        lockProxyHash = NetworkConfigs[network].bsc.lockProxyAddr
        break
      case Blockchain.Neo:
        if (OLD_NEP5_ASSET_ID.includes(formState.tokenAddress)) {
          lockProxyHash = '17d0f66eca7fcbfddc8d9706f20513bf5d7419cd'
        } else {
          lockProxyHash = '1f8966cd760cc8cd877b27ba1f4443059f664d16'
        }
        break
    }
    dispatch(setSubmitProposalFormState({
      ...formState,
      lockProxyHash,
    }))

    // eslint-disable-next-line
  }, [formState.blockchain])

  const onQuery = () => {
    runQueryToken(async () => {
      if (!formState.blockchain || !formState.tokenAddress || !sdk) return
      let address = formState.tokenAddress
      let tokenInfo: TokenInitInfo 
      try {
        const bridges = await sdk.query.coin.BridgeAll({})
        const blockchainBridge = bridges.bridges.find((b) => b.chainName === blockchainValueNameMap[formState.blockchain])
        switch (formState.blockchain) {
          case Blockchain.Neo: {
            tokenInfo = await sdk.neo.retrieveNEP5Info(address)
            break
          }
          case Blockchain.BinanceSmartChain: {
            if (!address.startsWith('0x')) {
              address = `0x${address}`
            }
            tokenInfo = await sdk.bsc.retrieveERC20Info(address)
            break
          }
          default: {
            if (!address.startsWith('0x')) {
              address = `0x${address}`
            }
            tokenInfo = await sdk.eth.retrieveERC20Info(address)
            break
          }
        }
        dispatch(setSubmitProposalFormState({
          ...formState,
          name: tokenInfo.name,
          symbol: tokenInfo.symbol,
          decimals: tokenInfo.decimals.toString(),
          bridgeId: blockchainBridge?.bridgeId.toString(),
          tokenAddress: address,
        }))
        setShowForm(true)
        validateRequiredFields(formState);
      } catch (err) {
        dispatch(setSubmitProposalFormValidationErrors(['Token address not found']))
        console.error(err)
      }
    })
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <BlockchainSelectInput label="Blockchain" value={formState.blockchain} onChange={handleFormChange('blockchain')} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box display="flex">
            <TextField
              fullWidth
              label="Token Address"
              InputLabelProps={{ shrink: true }}
              value={formState.tokenAddress}
              onChange={handleFormChange('tokenAddress')}
            />
            <Box>
              <InputLabel shrink>&nbsp;</InputLabel>
              <StatefulButton
                className={classes.iconButton}
                onClick={onQuery}
                loading={loading}
                variant='contained'
                color='primary'
              >
                <SearchIcon />
              </StatefulButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {showForm && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="Internal Chain ID"
              InputLabelProps={{ shrink: true }}
              placeholder="Select Blockchain"
              InputProps={{ readOnly: true }}
              value={formState.chainId}
              onChange={handleFormChange('chainId')}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              fullWidth
              label="Bridge ID"
              InputLabelProps={{ shrink: true }}
              placeholder="Select Blockchain"
              value={formState.bridgeId}
              InputProps={{ readOnly: true }}
              onChange={handleFormChange('bridgeId')}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              label="Deposit Contract Address"
              InputLabelProps={{ shrink: true }}
              placeholder="Select Blockchain"
              value={formState.lockProxyHash}
              InputProps={{ readOnly: true }}
              onChange={handleFormChange('lockProxyHash')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Token Full Name"
              InputLabelProps={{ shrink: true }}
              value={formState.name}
              onChange={handleFormChange('name', true)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Number of decimals of token"
              type="number"
              InputLabelProps={{ shrink: true }}
              value={formState.decimals}
              onChange={handleFormChange('decimals')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Symbol"
              InputLabelProps={{ shrink: true }}
              value={formState.symbol}
              onChange={handleFormChange('symbol')}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  iconButton: {
    minWidth: 0,
    margin: "auto",
    marginLeft: theme.spacing(1),
    "& svg": {
      height: "1em",
      width: "1em",
      "& path": {
        fill: "#fff",
      },
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1rem",
  },
  warningIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  menuItems: {
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

export default CreateTokenProposalMainForm
