import { Box, Button, createStyles, Hidden, makeStyles, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { ReactComponent as CosmosApp } from 'assets/LedgerCosmosApp.svg'
import clsx from 'clsx'
import { finishSign, requestSign, setSignErrors } from 'js/actions/app'
import { StatefulButton } from 'js/components'
import customToast from 'js/components/Toast/Toast'
import { Paths } from 'js/constants'
import { useRedux } from 'js/hooks'
import { actions } from 'js/store'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { AddressUtils, CarbonSDK, CosmosLedger } from 'carbon-js-sdk'

interface Props {
}

const LedgerLogin: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props)

  const dispatch = useDispatch()
  const network = useRedux((state) => state.app.network)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const history = useHistory()
  const [isFindingLedger, setIsFindingLedger] = useState(false)
  const [isLedgerFound, setIsLedgerFound] = useState(false)
  const [ledgerError, setLedgerError] = useState<string | null>(null)

  const handleLogin = async (ledger: CosmosLedger) => {
    if (!sdk) return;

    await sdk.connectWithLedger(ledger, {
      onRequestSign: () => {
        dispatch(setSignErrors(undefined))
        dispatch(requestSign())
      },
      onSignComplete: () => {
        dispatch(finishSign())
      },
    });

    dispatch(actions.Core.updateCarbonSDK(sdk))
    customToast('Success!', 'You have successfully logged in.')
    history.push(sessionStorage.getItem(`before-login`) ?? Paths.Home)
  }

  const findLedger = async () => {
    setLedgerError(null)
    setIsFindingLedger(true)

    // Create new instance of SDK so that user can connect regardless of whether or not carbonSDK store is defined or not
    const sdk = await CarbonSDK.instance({
      network: CarbonSDK.parseNetwork(network, CarbonSDK.Network.MainNet)!,
    })

    // delay to ensure eth app is stable for connection
    await new Promise((resolve) => setTimeout(resolve, 1000))
    try {
      const ledger = await new CosmosLedger(
        {},
        AddressUtils.SWTHAddress.keyDerivationPath(), // HDPATH
        AddressUtils.SWTHAddress.getBech32Prefix(sdk.network), // BECH32PREFIX
      ).connect()

      setLedgerError(null)
      setIsLedgerFound(true)
      // give time for the ledger to be set
      handleLogin(ledger)
    } catch (error) {
      if ((error as any).message === 'The device is already open.') {
        setLedgerError('Could not access ledger, please refresh the page and try again.')
      } else {
        setLedgerError((error as any).message)
      }
    } finally {
      setIsFindingLedger(false)
    }
  }

  const cancelFindLedger = () => {
    setIsFindingLedger(false)
  }

  return (
    <React.Fragment>
      <Typography variant="h1">
        Connect with ledger
      </Typography>
      <Typography className={classes.subtitle} variant="body1">
        Follow the instructions below to login via your ledger device.
      </Typography>

      <Box marginTop={4} className={classes.instructions}>
        <Box display='flex'>
          <CosmosApp className={classes.cosmosAppIcon} />
          <div>
            Choose
            {' '}
            <b>the Cosmos App</b>
            {' '}
            on your device.
          </div>
        </Box>
      </Box>

      {ledgerError && (
        <Box marginTop={0} marginBottom={2}>
          <Alert severity="error">
            <AlertTitle>Ledger Error</AlertTitle>
            {ledgerError}
          </Alert>
        </Box>
      )}

      {isFindingLedger && (
        <Box marginTop={0} marginBottom={2}>
          <Alert severity="info">
            <AlertTitle>Select your ledger and connect</AlertTitle>
            You should see a pop up window with a list of compatible devices.
            If the pop up does not appear:
            <ul>
              <li>
                Connected Ledger device might be busy (ensure the ledger screen shows
                {' '}
                <strong>Cosmos ready</strong>
                ).
              </li>
              <li>WebHID might be disabled on your browser.</li>
            </ul>
            <br />
            If you are using an older version of Chrome,  you may need to enable
            experimental features to use WebHID.
          </Alert>
        </Box>
      )}

      <Box className={classes.btnBox}>
        <Hidden only="xs">
          {isFindingLedger && (
            <Button
              className={clsx(classes.button, classes.cancelBtn)}
              color="secondary"
              onClick={cancelFindLedger}
            >
              Cancel
            </Button>
          )}
        </Hidden>
        <StatefulButton
          className={clsx(classes.button, {
            [classes.submitBtn]: !(isFindingLedger || isLedgerFound),
            [classes.submitBtnLoading]: isFindingLedger || isLedgerFound,
          })}
          tabIndex={3}
          loading={isFindingLedger || isLedgerFound}
          variant="contained"
          color="secondary"
          onClick={findLedger}
        >
          {isLedgerFound ? 'Logging in...' : 'Connect'}
        </StatefulButton>
        <Hidden smUp>
          {isFindingLedger && (
            <Button
              className={clsx(classes.button, classes.cancelBtn)}
              color="secondary"
              onClick={cancelFindLedger}
            >
              Cancel
            </Button>
          )}
        </Hidden>
      </Box>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  instructions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1rem 1.25rem',
    background: theme.palette.background.paper,
    ...theme.typography.body2,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    marginBottom: '1rem',
  },
  btnBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  cosmosAppIcon: {
    marginRight: theme.spacing(5),
  },
  polling: {
    background: theme.palette.secondary.main,
    minWidth: '7rem',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.common.white,
    padding: '0.6rem 1.25rem',
    fontSize: '0.875rem',
  },
  button: {
    padding: theme.spacing(1, 2),
    justifyContent: 'center',
    minWidth: 'unset',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  cancelBtn: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    margin: theme.spacing(0, 1, 0, 0),
    textTransform: 'initial',
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(1, 0, 0, 0),
    },
  },
  pollText: {
    marginLeft: '1rem',
  },
  spinnerIcon: {
    height: '1.1rem',
    animation: '$spin 5s linear infinite',
  },
  submitBtn: {
    justifyContent: 'space-between',
    minWidth: '12.5rem',
    padding: theme.spacing(1.5),
  },
  submitBtnLoading: {
    justifyContent: 'center',
    minWidth: '12.5rem',
    padding: theme.spacing(1.5),
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  subtitle: {
    marginTop: theme.spacing(1.25),
  },
}))

export default LedgerLogin
