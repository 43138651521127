import { makeStyles, TableBody, Theme } from '@material-ui/core'
import { Models } from 'carbon-js-sdk'
import { DataLoadSegment, ListTable, Section } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import { adjustHuman, parseNumber } from 'js/utils'
import React, { ReactElement } from 'react'
import { ListTableRow } from '../components'

type Props = {
  params?: Models.Slashing.Params
}

const SlashingParams: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { params } = props
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Validators.Slashing)

  const cellFormat = (key: keyof Models.Slashing.Params) => {
    switch (key) {
      case 'downtimeJailDuration':
        const downtime = parseNumber(params?.[key]?.seconds?.toString())
        return downtime?.toFormat(2) ?? '-'
      case 'slashFractionDoubleSign':
      case 'slashFractionDowntime':
      case 'minSignedPerWindow':
        const num = params?.[key]?.toString() && Buffer.from(params?.[key]).toString()
        return num ? `${adjustHuman(num).times(100).toFormat(2)}%` : '-'
      case 'signedBlocksWindow':
      default:
        return params?.[key]?.toString() ?? '-'
    }
  }

  return (
    <Section title="Slashing Parameters" className={classes.section}>
      <DataLoadSegment loading={loading || !params}>
        {
          params && (
            <ListTable>
              <TableBody>
                {
                  Object.keys(params).map((each) => {
                    const label = each?.replace(/([A-Z])/g, " $1")
                    if (each === 'downtimeJailDuration') {
                      return (
                        <ListTableRow
                          headerClass={classes.headerClass}
                          key={each}
                          keyVal={label}
                          valueClass={classes.valueClass}
                        >
                          <div className={classes.stringContainer}>
                            <div>{cellFormat(each)}</div>
                            <div className={classes.sharePercent}>seconds</div>
                          </div>
                        </ListTableRow>
                      )
                    }
                    return (
                      <ListTableRow
                        headerClass={classes.headerClass}
                        key={each}
                        keyVal={label}
                        valueClass={classes.valueClass}
                      >
                        {cellFormat(each as keyof Models.Slashing.Params)}
                      </ListTableRow>
                    )
                  })
                }
              </TableBody>
            </ListTable>

          )
        }
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  headerClass: {
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
  section: {
    height: '100%',
  },
  sharePercent: {
    color: theme.palette.grey[500],
    marginLeft: 6,
  },
  stringContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  valueClass: {
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    },
  },
}))

export default SlashingParams
