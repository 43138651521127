import { Box, makeStyles, TableBody, TableCell, TableHead, TableRow, Theme, Tooltip } from '@material-ui/core'
import StarIcon from '@material-ui/icons/StarOutlined'
import { CellLink, DataLoadSegment, ListTable, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { useState } from 'react'
import PaginationByData from 'js/components/PaginationByData'
import { getVoteOption } from '../helper'
import { shortenHash } from 'js/helpers'
import dayjs from 'dayjs'

const ValidatorVotes: React.FC<{}> = () => {
  const [votesLoading] = useTaskSubscriber(TaskNames.Proposal.Votes)
  const sortedVotes = useRedux((state) => state.proposal.validatorVotes)
    .sort((prev, next) => next.height - prev.height)
  const valAddrMap = useRedux((state) => state.core.valAddrMap)
  const wallet = useRedux((state) => state.core.carbonSDK?.wallet)
  const classes = useStyles()

  // For pagination
  const [page, setPage] = useState(1)
  const itemsPerPage = 10
  const validatorVotesToDisplay = sortedVotes.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)
  return (
    <Box>
      <DataLoadSegment loading={votesLoading}>
        {validatorVotesToDisplay.length > 0 ? (
          <>
            <ListTable>
              <TableHead>
                <TableRow>
                  <TableCell>Height</TableCell>
                  <TableCell>Option</TableCell>
                  <TableCell>Validator</TableCell>
                  <TableCell>Delegations</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Hash</TableCell>
                  <TableCell>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {validatorVotesToDisplay.map((vote, index) =>
                  <TableRow key={vote.hash + index} hover>
                    <TableCell>
                      <CellLink to={`/block/${vote.height}`}>{vote.height}</CellLink>
                    </TableCell>
                    <TableCell>{getVoteOption(vote.input)}</TableCell>
                    <TableCell>
                      <CellLink to={`/validator/${vote.validatorAddress}`}>
                        {valAddrMap[vote.validatorAddress]?.carbonValidator.description?.moniker ?? vote.validatorAddress}
                      </CellLink>
                    </TableCell>
                    <TableCell>
                      {vote.numOfDelegations}
                    </TableCell>
                    <TableCell>
                      <CellLink to={`/account/${vote.address}`}>{shortenHash(vote.address, 10)}</CellLink>
                      {
                        // displays an icon if voter is same as current user
                        wallet?.bech32Address === vote.address && (
                          <Tooltip arrow placement="top" title="Your vote">
                            <StarIcon className={classes.meIcon} />
                          </Tooltip>
                        )
                      }
                    </TableCell>
                    <TableCell>
                      <CellLink to={`/transaction/${vote.hash}`}>{shortenHash(vote.hash, 10)}</CellLink>
                    </TableCell>
                    <TableCell align="right">{dayjs(vote.timestamp).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </ListTable>
            <Box marginTop={3}>
              <PaginationByData
                data={sortedVotes}
                setPage={setPage}
                page={page}
                itemsPerPage={itemsPerPage} />
            </Box>
          </>
        ) : <TableEmptyState itemName="votes" />}
      </DataLoadSegment>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  meIcon: {
    width: '0.75rem',
    height: '0.75rem',
    verticalAlign: 'sub',
    marginLeft: theme.spacing(2),
  },
  moniker: {
    lineHeight: '1.2rem',
    minWidth: '14rem',
  },
}))

export default ValidatorVotes