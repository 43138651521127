import { Transaction as InsightsTransactions } from 'carbon-js-sdk/lib/insights'
import { Record, RecordOf } from 'immutable'
import { TransactionsAction, TransactionsActionType } from '../actions/transactions'
import { MsgGasCost } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/fee/fee'
import { Transaction } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/export'

export interface TransactionsResponse {
  data: Transaction[],
  meta: {
    totalPages: number,
  }
}

export interface TransactionsProps {
  transactions: TransactionsResponse
  types: string[]
  gasFees: MsgGasCost[]
  transactionTotalChartData: InsightsTransactions[]
  transactionMsgCountChartData: InsightsTransactions[]
  disablePaginationForwardButton: boolean
}

const makeState: Record.Factory<TransactionsProps> = Record<TransactionsProps>({
  transactions: {
    data: [],
    meta: {
      totalPages: 0,
    }
  },
  types: [],
  gasFees: [],
  transactionTotalChartData: [],
  transactionMsgCountChartData: [],
  disablePaginationForwardButton: false,
})
export type TransactionsState = RecordOf<TransactionsProps>

function transactionsReducer(
  state: TransactionsState = makeState(),
  action: TransactionsAction,
): TransactionsState {
  switch (action.type) {
    case TransactionsActionType.CLEAR:
      return makeState()
    case TransactionsActionType.SET_TRANSACTIONS:
      return state.updateIn(['transactions'], () => action.transactions)
    case TransactionsActionType.SET_TYPES:
      return state.updateIn(
        ['types'],
        (): string[] => {
          return action.types
        },
      )
    case TransactionsActionType.SET_GAS_FEES:
      return state.updateIn(['gasFees'], () => action.gasFees)
    case TransactionsActionType.SET_TRANSACTION_TOTAL_CHART:
      return state.updateIn(['transactionTotalChartData'], () => action.transactionTotalChartData)
    case TransactionsActionType.SET_TRANSACTION_MSG_CHART:
      return state.updateIn(['transactionMsgCountChartData'], () => action.transactionMsgCountChartData)
    case TransactionsActionType.SET_DISABLE_PAGINATION_FORWARD_BUTTON:
      return state.updateIn(['disablePaginationForwardButton'], () => action.disablePaginationForwardButton)
    default:
      return state
  }
}

export default transactionsReducer
