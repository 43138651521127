import { Box, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import { TradeEvent } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/broker/export'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, PaginationByPage, Section, TableEmptyState } from 'js/components'
import { TaskNames, userTradesPaginationLimit } from 'js/constants'
import { shorten } from 'js/helpers/utils'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { TradesReponse } from 'js/reducers/account'
import { BIG_ZERO, adjustHuman, bnOrZero } from 'js/utils'
import moment from 'moment'
import React, { ReactElement, ReactNode } from 'react'

export interface TradesProps {
  tradesByAccount: TradesReponse
  title: string
  action: any
  markets: any
  address: string
  loadname: string[]
  loadCondition?: boolean
}

interface Props extends TradesProps { }

const TradesByAccount: React.FunctionComponent<Props> = ({
  tradesByAccount,
  title,
  action,
  markets,
  address,
  loadname = [],
  loadCondition = true,
}: Props): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.App.Markets, ...loadname)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [page, setPage] = React.useState(1)
  return (
    <Section title={title}>
      <DataLoadSegment loading={loading && loadCondition}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Block</TableCell>
              <TableCell>Market</TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell>Side</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Fees</TableCell>
              <TableCell align="right">Matched At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tradesByAccount.data.map((trade, index) => renderTrade(trade, index, sdk))}</TableBody>
        </ListTable>
        {tradesByAccount.data.length > 0 && (
          <Box marginTop={3}>
            <PaginationByPage
              response={tradesByAccount}
              action={action}
              page={page}
              setPage={setPage}
              paginationLimit={userTradesPaginationLimit}
            />
          </Box>
        )}
        {tradesByAccount.data.length === 0 && (
          <TableEmptyState itemName="trades" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTrade(tradeByAccount: TradeEvent, index: number, sdk?: CarbonSDK): ReactNode {
    const {
      marketId,
      price,
      quantity,
      takerAddress,
      takerSide,
      takerId,
      takerFeeAmount,
      takerFeeDenom,
      makerSide,
      makerId,
      makerFeeAmount,
      makerFeeDenom,
      blockHeight,
      blockCreatedAt,
    } = tradeByAccount
    const side = address === takerAddress ? takerSide : makerSide
    const feeAmount = side === takerSide ? takerFeeAmount : makerFeeAmount
    const feeDenom = side === takerSide ? takerFeeDenom : makerFeeDenom
    const orderId = side === takerSide ? takerId : makerId
    const priceBN = adjustHuman(price).shiftedBy(bnOrZero(markets?.[marketId]?.basePrecision.sub(markets?.[marketId]?.quotePrecision)?.toNumber()).toNumber())
    const quantityBN = sdk?.token.toHuman(markets?.[marketId]?.base, bnOrZero(quantity)) ?? BIG_ZERO
    const feeBN = sdk?.token.toHuman(feeDenom, bnOrZero(feeAmount)) ?? BIG_ZERO
    const lotDp = bnOrZero(markets[marketId]?.lotSize).dp() ?? 0
    const tickDp = bnOrZero(markets[marketId]?.tickSize).dp() ?? 0
    const totalDenom = markets?.[marketId]?.quote
    return (
      <TableRow
        key={`${orderId}${index.toString()}accountTrade`}
        hover
      >
        <TableCell>
          <div>
            <div>
              <CellLink to={`/block/${blockHeight?.toNumber()}`}>
                {blockHeight?.toNumber()}
              </CellLink>
            </div>
            <div>
              <Typography style={{ color: 'rgb(140 142 143)', fontSize: '0.78em' }}>
                {moment(blockCreatedAt).format('YYYY-MM-DD HH:mm:ss')}
              </Typography>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <CellLink to={`/market/${encodeURIComponent(marketId)}`}>{marketId}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/order/${orderId}`}>{shorten(orderId)}</CellLink>
        </TableCell>
        <TableCell>{side}</TableCell>
        <TableCell align="right">{quantityBN.toFormat(lotDp)}</TableCell>
        <TableCell align="right">{priceBN.toFormat(tickDp)}</TableCell>
        <AmountDenomCell denom={totalDenom} amount={quantityBN.multipliedBy(priceBN).toFormat(tickDp)} />
        {/* <TableCell align="right">{quantityBN.multipliedBy(priceBN).toFormat(lotDp)}</TableCell> */}
        <AmountDenomCell denom={feeDenom} amount={feeBN.toFormat(8)} align="right" />
        <TableCell align="right">
          <CellLink to={`/block/${blockHeight.toString()}`}>{blockHeight.toString()}</CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default TradesByAccount
