import {
  Box,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from '@material-ui/core'
import { Block } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/block'
import clsx from 'clsx'
import { CellLink, DataLoadSegment, DatetimeCell, ListTable, PaginationByPage, Section, TableEmptyState } from 'js/components'
import { blocksPaginationLimit, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { BlockResponse } from 'js/reducers/blocks'
import React, { ReactElement, ReactNode } from 'react'

export interface BlocksProps {
  action: any
  blocks: BlockResponse
  className?: string
  filter: boolean
  loadname?: string[]
  title?: string
  titleClass?: string
}

interface Props extends BlocksProps { }

const Blocks: React.FunctionComponent<Props> = ({
  blocks,
  action,
  className,
  filter = false,
  loadname = [],
  title,
  titleClass,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const valAddrMap = useRedux((state) => state.core.valAddrMap)
  const [loading] = useTaskSubscriber(
    TaskNames.App.Validators,
    ...loadname,
  )
  const [page, setPage] = React.useState(1)
  return (
    <Section className={clsx(classes.root, className)} title={title} titleClass={titleClass}>
      <DataLoadSegment
        filter={filter}
        loading={loading}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Height</TableCell>
              <TableCell>Proposer</TableCell>
              <TableCell align="right">Txs</TableCell>
              <TableCell align="right">Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{blocks.models.map(renderBlock)}</TableBody>
        </ListTable>
        {blocks.models?.length > 0 && (
          <Box paddingTop={3}>
            <PaginationByPage
              response={blocks as any}
              action={action}
              page={page}
              setPage={setPage}
              paginationLimit={blocksPaginationLimit}
            />
          </Box>
        )}
        {blocks.models?.length === 0 && (
          <TableEmptyState itemName="blocks" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderBlock(block: Block): ReactNode {
    const { blockHeight, time, count, proposerAddress } = block
    const valPair = Object.values(valAddrMap).find((pair) => {
      if (!pair.tmValidator?.address) return false;
      return Buffer.from(pair.tmValidator?.address).toString("hex") === proposerAddress;
    })
    return (
      <TableRow key={blockHeight.toNumber()} hover>
        <TableCell>
          <CellLink to={`/block/${blockHeight.toNumber()}`}>{blockHeight.toNumber()}</CellLink>
        </TableCell>
        <TableCell>
          {!valPair ? (
            proposerAddress
          ) : (
            <CellLink to={`/validator/${valPair.carbonValidator.operatorAddress}`}>
              {valPair.carbonValidator.description?.moniker}
            </CellLink>
          )}
        </TableCell>
        <TableCell align="right">{count}</TableCell>
        <DatetimeCell data={time} />
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 3, 3),
  },
}))

export default Blocks
