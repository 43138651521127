import { NewBlockEvent, Block as TmBlock } from "@cosmjs/tendermint-rpc/build/tendermint37";
import { Block } from "carbon-js-sdk/lib/codec/Switcheo/carbon/misc/block";
import dayjs from 'dayjs';

export class SimpleBlock {
  constructor(
    public readonly height: number,
    public readonly proposerAddress: string,
    public readonly txCount: number,
    public readonly time: dayjs.Dayjs,
  ) { }

  static fromBlockEvent(event: NewBlockEvent | TmBlock) {
    return new SimpleBlock(
      parseInt(event.header.height.toString()),
      Buffer.from(event.header.proposerAddress).toString("hex"),
      event.txs.length,
      dayjs(event.header.time.toString()),
    )
  }

  static fromBlock(block: Block) {
    return new SimpleBlock(
      block.blockHeight.toNumber(),
      block.proposerAddress,
      block.count,
      dayjs(block.time),
    )
  }
}
