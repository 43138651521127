import { Backdrop, Box, Button, makeStyles, MenuItem, MenuList, Paper, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import clsx from 'clsx'
import { setMenuDropClose, setNetDropClose, setWalletDropClose, setWalletDropOpen } from 'js/actions/app'
import { useRedux } from 'js/hooks'
import { returnUrlNet } from 'js/utils'
import { actions } from 'js/store'
import { updateCarbonSDK } from 'js/store/core'
import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { User } from '../assets'
import { switcheo } from 'js/theme/palettes/colors'

type Props = {
  toggleDrawer?: (forceClose?: boolean) => (event: any) => void
}

const YourWalletDropdown: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { toggleDrawer } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { network, walletDrop } = useRedux((state) => state.app)
  const sdk = useRedux((state) => state.core.carbonSDK)

  const handleClick = () => {
    dispatch(setMenuDropClose())
    dispatch(setNetDropClose())
    dispatch(walletDrop ? setWalletDropClose() : setWalletDropOpen())
  }

  const handleClose = () => dispatch(setWalletDropClose())
  const logout = () => {
    dispatch(actions.Core.updateSDK(null))
    dispatch(updateCarbonSDK(undefined))
    history.push('/login')
  }
  return (
    <Box className={classes.root}>
      <Button
        onClick={handleClick}
        className={classes.button}
        startIcon={<User className={classes.userIcon} />}
      >
        <Box className={classes.buttonBox}>
          <Typography className={classes.loginText} variant='h3'>
            Your Wallet
          </Typography>
          {walletDrop ? <ArrowDropUp className={classes.arrow} /> : <ArrowDropDown className={classes.arrow} />}
        </Box>
      </Button>
      {
        walletDrop && (
          <Box>
            <Backdrop
              className={clsx(classes.backdrop, { open: walletDrop })}
              invisible
              open={walletDrop}
              onClick={handleClose}
            />
            <Paper className={classes.menu} elevation={isMobile ? 0 : 1}>
              <MenuList className={classes.menuList}>
                <MenuItem
                  component={NavLink}
                  to={`/account/${sdk?.wallet?.bech32Address}?net=${returnUrlNet(network)}`}
                  className={classes.menuItem}
                  activeClassName={classes.activeMenuItem}
                  onClick={toggleDrawer && toggleDrawer()}
                >
                  Your Address Profile
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={logout}>
                  Logout
                </MenuItem>
                {/* <ListSubheader>Your Voting Power</ListSubheader>
                <MenuItem>TODO</MenuItem> */}
              </MenuList>
            </Paper>
          </Box>
        )
      }
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  activeMenuItem: {
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  backdrop: {
  },
  menu: {
    top: '3.25rem',
    right: 0,
    zIndex: 10,
    position: 'absolute',
    backgroundColor: switcheo.background.grey,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      backgroundColor: 'transparent',
      padding: theme.spacing(0, 1.5),
      borderRadius: 0,
    },
  },
  menuList: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  menuItem: {
    fontSize: '1rem',
    padding: theme.spacing(2, 3),
    minHeight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
    },
  },
  button: {
    minWidth: '8.9375rem',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0, 1, 2),
    },
  },
  buttonBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  loginText: {
    color: theme.palette.text.hint,
    fontWeight: 'normal',
    textTransform: 'initial',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'bold',
      fontSize: '1.375rem',
    },
  },
  userIcon: {
    height: '1.125rem',
    width: '1.125rem',
    '& path': {
      fill: theme.palette.text.hint,
    },
  },
  arrow: {
    '& path': {
      fill: theme.palette.text.hint,
    },
  },
}))

export default YourWalletDropdown
