import { Insights } from 'carbon-js-sdk'
import { QueryTradesRequest, QueryTradesResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/broker/query'
import { QueryGetMarketResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/query'
import { QueryAllOrderRequest, QueryAllOrderResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/query'
import { TaskNames } from 'js/constants'
import { Comparators } from 'js/utils'
import { getAdjustedMarket } from 'js/utils/market'
import Long from 'long'
import { SagaIterator } from 'redux-saga'
import { Effect, call, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { MarketActionType, clear, setLargePositions, setMarket, setMarketStats, setOrders, setProfitablePositions, setRiskyLongPositions, setTrades } from '../actions/market'
import Saga from './Saga'
import { getInitializedSDK, runSagaTask, waitforSDK } from './helper'

export default class M extends Saga {
  private readonly market: string
  private readonly isMobile: boolean
  constructor(market: string, isMobile: boolean) {
    super()

    this.isMobile = isMobile
    this.market = market
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchMarket],
      [this, this.fetchMarketStats],
      [this, this.fetchOrders],
      [this, this.fetchTrades],
      [this, this.watchSetNetwork],
      [this, this.watchOrdersFilters],
      [this, this.watchTradesFilters],
      [this, this.fetchLargePositions],
      [this, this.watchLargeFilters],
      [this, this.fetchRiskyLongPositions],
      [this, this.watchRiskyLongsFilters],
      [this, this.watchRiskyShortsFilters],
      [this, this.watchProfitableFilters],
      [this, this.fetchProfitablePositions],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchMarket() {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.Detail, function* () {
      const sdk = yield* waitforSDK()
      const marketQueryClient = sdk.query.market

      const marketResponse = (yield call([marketQueryClient, marketQueryClient.Market], {
        id: marketName,
      })) as QueryGetMarketResponse
      if (marketResponse.marketId) {
        yield put(setMarket(getAdjustedMarket(marketResponse.marketId, sdk)))
      }
    })
  }

  // TODO: Set up websocket
  private *fetchMarketStats(): any {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.Stats, function* () {
      const sdk = yield* getInitializedSDK()

      const [marketStats] = (yield sdk.api.getMarketStats({ market: marketName })) as RestModels.MarketStat[]
      yield put(setMarketStats(marketStats))
    })
  }

  private *fetchOrders(): any {
    const market = this.market
    yield runSagaTask(TaskNames.Market.OrderList, function* () {
      const sdk = yield* waitforSDK()
      const orderQueryClient = sdk.query.order

      const ordersResponse = (yield call([orderQueryClient, orderQueryClient.OrderAll], QueryAllOrderRequest.fromPartial({
        marketId: market,
        pagination: {
          limit: 300,
        }
      })
      )) as QueryAllOrderResponse
      yield put(setOrders(ordersResponse.orders.sort((a, b) => b.blockHeight.toNumber() - a.blockHeight.toNumber())))
    })
  }

  // TODO: Delete after migration complete
  private *watchOrdersFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_ORDERS_FILTERS,
      this.handleOrdersFilters.bind(this),
    )
  }

  // TODO: Delete after migration complete
  private *handleOrdersFilters(action: any): Generator {
    const market = this.market
    yield runSagaTask(TaskNames.Market.OrderFilter, function* () {
      const sdk = yield* getInitializedSDK()
      const orders = (yield sdk.api.getOrdersPaged({
        market,
        limit: 20,
        ...action.options.pagination,
      })) as RestTypes.ResultsMinMax<RestModels.Order>
      orders.data.sort(Comparators.sortOrder)
      // yield put(setOrders(orders))
    });
  }

  private *fetchTrades(): any {
    const market = this.market
    yield runSagaTask(TaskNames.Market.TradeList, function* () {
      const sdk = yield* waitforSDK()
      const brokerQueryClient = sdk.query.broker
      const trades = (yield call([brokerQueryClient, brokerQueryClient.Trades],
        QueryTradesRequest.fromPartial({
          marketId: market,
          pagination: {
            limit: new Long(20),
            offset: Long.UZERO,
            countTotal: true,
            reverse: false,
          }
        }),
      )) as QueryTradesResponse
      yield put(setTrades({
        data: trades.trades,
        meta: {
          totalPages: trades.pagination?.total.toNumber() ?? 0,
        }
      }))
    })
  }

  private *watchTradesFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_TRADES_FILTERS,
      this.handleTradesFilters.bind(this),
    )
  }

  private *handleTradesFilters(action: any): Generator {
    const market = this.market
    yield runSagaTask(TaskNames.Market.TradeFilter, function* () {
      const sdk = yield* waitforSDK()
      const brokerQueryClient = sdk.query.broker
      const page = Number(action.options.page)
      const offset = (page - 1) * 20
      const offsetLong = Long.fromNumber(offset >= 0 ? offset : 0)

      const trades = (yield call([brokerQueryClient, brokerQueryClient.Trades],
        QueryTradesRequest.fromPartial({
          marketId: market,
          pagination: {
            limit: new Long(20),
            offset: offsetLong,
            countTotal: true,
            reverse: false,
          }
        }),
      )) as QueryTradesResponse
      yield put(setTrades({
        data: trades.trades,
        meta: {
          totalPages: trades.pagination?.total.toNumber() ?? 0,
        }
      }))
    })
  }

  private *fetchLargePositions(): any {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.LargePositions, function* () {
      const sdk = yield* waitforSDK()
      const result = (yield sdk.insights.PositionsView({ view: "size" as Insights.PositionViewOptions }, { market: marketName ?? '' })) as Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
      yield put(setLargePositions(result))
    })
  }

  private *fetchRiskyLongPositions(): any {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.LongRisky, function* () {
      const sdk = yield* waitforSDK()
      const result = (yield sdk.insights.PositionsView({ view: "risk" as Insights.PositionViewOptions }, { market: marketName ?? '' })) as Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
      yield put(setRiskyLongPositions(result))
    })
  }

  private *fetchProfitablePositions(): any {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.ProfitablePositions, function* () {
      const sdk = yield* waitforSDK()

      const result = (yield sdk.insights.PositionsView({ view: "profit" as Insights.PositionViewOptions }, { market: marketName ?? '' })) as Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
      yield put(setProfitablePositions(result))
    })
  }

  private *watchLargeFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_LARGE_POSITIONS,
      this.handleLargePositions.bind(this),
    )
  }

  private *handleLargePositions(action: any): Generator {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.LargeFilter, function* () {
      const sdk = yield* waitforSDK()
      const result = (yield sdk.insights.PositionsView({ view: "size" as Insights.PositionViewOptions }, { market: marketName ?? '' })) as Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
      yield put(setLargePositions(result))
    })
  }

  private *watchProfitableFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_PROFITABLE_POSITIONS,
      this.handleProfitablePositions.bind(this),
    )
  }

  private *handleProfitablePositions(action: any): Generator {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.ProfitableFilter, function* () {
      const sdk = yield* waitforSDK()
      const result = (yield sdk.insights.PositionsView({ view: "profit" as Insights.PositionViewOptions }, { market: marketName ?? '' })) as Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
      yield put(setProfitablePositions(result))
    })
  }

  private *watchRiskyLongsFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_RISKY_LONG_POSITIONS,
      this.handleRiskyLongs.bind(this),
    )
  }

  private *handleRiskyLongs(action: any): Generator {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.LongRiskyFilter, function* () {
      const sdk = yield* waitforSDK()
      const result = (yield sdk.insights.PositionsView({ view: "risk" as Insights.PositionViewOptions }, { market: marketName ?? '' })) as Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
      yield put(setProfitablePositions(result))
    })
  }

  private *watchRiskyShortsFilters(): any {
    yield takeLatest(
      MarketActionType.UPDATE_RISKY_SHORT_POSITIONS,
      this.handleRiskyShorts.bind(this),
    )
  }

  private *handleRiskyShorts(action: any): Generator {
    const marketName = this.market
    yield runSagaTask(TaskNames.Market.ShortRiskyFilter, function* () {
      const sdk = yield* waitforSDK()
      const result = (yield sdk.insights.PositionsView({ view: "risk" as Insights.PositionViewOptions }, { market: marketName ?? '' })) as Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
      yield put(setProfitablePositions(result))
    })
  }
}
