import { Coin } from '@cosmjs/stargate'
import { Divider, Grid, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import { AccountSVG, BlockTimeSVG, TotalBlocksSVG, TransactionSVG } from 'assets'
import { BigNumber } from 'bignumber.js'
import { BondStatus } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/staking'
import { Section, TabsButtons } from 'js/components'
import { TaskNames } from 'js/constants'
import { useAsyncTask } from 'js/hooks'
import { RootState } from 'js/store'
import { switcheo } from 'js/theme/palettes/colors'
import { BIG_ZERO, BN_ZERO, bnOrZero, parseNumber } from 'js/utils'
import Long from 'long'
import React, { ReactElement, Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Blocks, HomeStatsBox, StatsBox,
  StatsNumber, Transactions
} from './components'

interface Props { }

const TotalUsersCountChart = React.lazy(() => import('./components/TotalUsersCountChart'));
const ActiveUsersCountChart = React.lazy(() => import('./components/ActiveUsersCountChart'));
const DistributionRewardsChart = React.lazy(() => import('./components/DistributionRewardsChart'));

const Dashboard: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const insurance = useSelector((state: any) => state.dashboard.insurance)
  const insuranceIsLoaded = useSelector((state: any) => state.dashboard.insuranceIsLoaded)
  const volume = useSelector((state: any) => state.dashboard.volume)
  const volumeIsLoaded = useSelector((state: any) => state.dashboard.volumeIsLoaded)

  const geckoPrices = useSelector((state: any) => state.app.geckoPrices)
  const totalSupply = useSelector((state: any) => state.app.total)
  const sdk = useSelector((state: RootState) => state.core.carbonSDK)
  const [fetchNoOfWallet, loading] = useAsyncTask('fetchNoOfWallet')
  const [fetchChainTVL, loadingChainTVL] = useAsyncTask('fetchChainTVL')
  const [walletNum, setWalletNum] = useState(undefined)
  const [chainTvl, setChainTVL] = useState<undefined | BigNumber>(undefined)
  const [tabId, setTabId] = useState(0)
  const [swthPrices, setSwthPrices] = useState<any>(undefined)
  const tabItems = [
    { label: 'Total Users' },
    { label: 'Active Users' },
  ]

  useEffect(() => {
    if (walletNum !== undefined) return
    fetchNoOfWallet(async () => {
      const getWalletNumRaw = await fetch('https://api-insights.carbon.network/user/latest')
      const walletNum = (await getWalletNumRaw.json())?.result?.entries?.account?.account_number
      setWalletNum(walletNum)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (chainTvl !== undefined) return
    if (!sdk) return
    if (Object.keys(sdk.token.usdValues).length < 3) return
    fetchChainTVL(async () => {
      const getTokenSupplies = await sdk.query.bank.TotalSupply({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      // await sdk.token.reloadUSDValues()
      const totalTokenSuppliesInUsd = getTokenSupplies.supply.reduce((accum: any, token: Coin) => {
        const tokenPrice = sdk.token.usdValues[token.denom] ?? BIG_ZERO
        const decimal = sdk.token.getDecimals(token.denom) ?? 0
        return accum.plus(tokenPrice.multipliedBy(token.amount).shiftedBy(-decimal))
      }, BIG_ZERO)
      setChainTVL(totalTokenSuppliesInUsd)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, Object.keys(sdk?.token?.usdValues ?? {}).length])

  const handleChange = (event: any, newValue: number) => {
    setTabId(newValue)
  }

  const insuranceAmount = bnOrZero(insurance)
  const { switcheo } = geckoPrices

  useEffect(() => {
    if (!sdk || swthPrices) return
    const getSwthOraclePrice = async () => {
      const oraclePrices = await sdk?.token.getUSDValuesFromPricingModule() ?? {}
      const usdPriceBN = bnOrZero(oraclePrices['swth'] ?? switcheo.usd)
      const btcPriceBN = usdPriceBN.dividedBy(bnOrZero(switcheo.usd).dividedBy(bnOrZero(switcheo.btc))) // x * SWTH = BTC 
      const ethPriceBN = usdPriceBN.dividedBy(bnOrZero(switcheo.usd).dividedBy(bnOrZero(switcheo.eth)))
      setSwthPrices({
        usdPriceBN,
        btcPriceBN,
        ethPriceBN
      })
    }
    getSwthOraclePrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  const usdPriceBN = swthPrices?.usdPriceBN || bnOrZero(switcheo.usd)
  const btcPriceBN = swthPrices?.btcPriceBN || bnOrZero(switcheo.btc) // x * SWTH = BTC 
  const ethPriceBN = swthPrices?.ethPriceBN || bnOrZero(switcheo.eth)
  const mcap = usdPriceBN.times(totalSupply)

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      {/* <Grid item xs={12}>
        <Box>
          <Paper>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 10fr', padding: '1em 2em', gap: '1em' }}>
              <ErrorOutlineRounded style={{ color: 'red' }} />
              <Typography variant={"h3"}>
                Chain upgrade is currently in progress, some services may not be available
              </Typography>
            </div>
          </Paper>
        </Box>
      </Grid> */}
      <HomeStatsBoxController />

      <Grid item sm={12} md={9} className={classes.chartGridWithTab}>
        <TabsButtons handleChange={handleChange} items={tabItems} tabClass={classes.tab} value={tabId} tabsClass={classes.tabs} />
        <Suspense fallback={<div><LinearProgress /></div>}>
          <TotalUsersCountChart view={tabId === 0 ? 'total' : 'active'} />
        </Suspense>
      </Grid>
      <Grid item sm={12} md={3}>
        <Section className={classes.sectionWrap}>
          <Grid container justifyContent="center">
            <Grid item className={classes.section}>
              <Typography className={classes.statsTitle} variant={"h4"} component={"span"}>
                {`SWTH Price and Market Stats`}
              </Typography>
              <Divider
                className={classes.divider}
                variant="fullWidth"
              />
              <StatsNumber
                label="SWTH Price"
                loadname={[
                  TaskNames.App.GeckoCoin,
                ]}
                isLoaded={!!(!usdPriceBN.isZero() && swthPrices?.usdPriceBN)}
                title={`$${usdPriceBN.toFormat(4)}`}
              />
              <Divider
                className={classes.divider}
                variant="fullWidth"
              />
              <StatsNumber
                label="Market Cap"
                loadname={[
                  TaskNames.App.GeckoCoin,
                  TaskNames.App.TotalSupply,
                ]}
                isLoaded={!mcap.isZero() && swthPrices}
                title={`$${mcap.toFormat(0)}`}
              />
              <Divider
                className={classes.divider}
                variant="fullWidth"
              />
              <StatsNumber
                label="Total Supply"
                loadname={[
                  TaskNames.App.TotalSupply,
                ]}
                isLoaded={totalSupply?.gt(1000)}
                title={totalSupply?.toFormat(0)}
                subtitle="SWTH"
              />
              <Divider
                className={classes.divider}
                variant="fullWidth"
              />
              <StatsNumber
                label="Chain TVL"
                loadname={[
                  'fetchChainTVL'
                ]}
                isLoaded={!loadingChainTVL}
                title={chainTvl ? `$${chainTvl?.toFormat(2)}` : '-'}
              />
              <Divider
                className={classes.divider}
                variant="fullWidth"
              />
              <StatsNumber
                label="Total Wallets"
                loadname={[
                  'fetchNoOfWallet'
                ]}
                isLoaded={!loading}
                title={walletNum ?? '-'}
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="Volume 24H"
                loadname={[
                  TaskNames.Dashboard.MarketStats,
                ]}
                isLoaded={volumeIsLoaded}
                title={`$${bnOrZero(volume).times(2).toFormat(2)}`}
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="Insurance"
                loadname={[
                  TaskNames.App.InsuranceSupply,
                ]}
                isLoaded={insuranceIsLoaded}
                title={`$${insuranceAmount.toFormat(2)}`}
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="1 USD"
                loadname={[
                  TaskNames.App.GeckoCoin,
                ]}
                isLoaded={!!(!usdPriceBN.isZero() && swthPrices)}
                title={new BigNumber(1).div(usdPriceBN).toFormat(0)}
                subtitle="SWTH"
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="1 BTC"
                loadname={[
                  TaskNames.App.GeckoCoin,
                ]}
                isLoaded={!!(!btcPriceBN.isZero() && swthPrices)}
                title={new BigNumber(1).div(btcPriceBN).toFormat(0)}
                subtitle="SWTH"
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
              <StatsNumber
                label="1 ETH"
                loadname={[
                  TaskNames.App.GeckoCoin,
                ]}
                isLoaded={!!(!ethPriceBN.isZero() && swthPrices)}
                title={new BigNumber(1).div(ethPriceBN).toFormat(0)}
                subtitle="SWTH"
              />
              <Divider
                className={classes.divider}
                variant="middle"
              />
            </Grid>
          </Grid>
        </Section>
      </Grid>
      <Grid item sm={12} className={classes.chartGrid}>
        <Suspense fallback={<div><LinearProgress /></div>}>
          <ActiveUsersCountChart />
        </Suspense>
      </Grid>
      <Grid item sm={12} md={6}>
        <Blocks />
      </Grid>
      <Grid item sm={12} md={6}>
        <Transactions />
      </Grid>
      <Grid item sm={12} className={classes.chartGrid}>
        <Suspense fallback={<div><LinearProgress /></div>}>
          <DistributionRewardsChart />
        </Suspense>
      </Grid>
    </Grid >
  )
}

const HomeStatsBoxController = () => {
  const totalStaked = useSelector((state: any) => state.app.totalStaked)
  const valAddrMap = useSelector((state: any) => state.core.valAddrMap)
  const avgBlockTime = useSelector((state: any) => state.app.avgBlockTime)
  const blocks = useSelector((state: any) => state.core.blocks)
  const totalSupply = useSelector((state: any) => state.app.total)

  const blockTimeBN = bnOrZero(avgBlockTime)
  const [latestBlock] = blocks

  const bondedPercent = totalStaked.div(totalSupply).isFinite()
    ? totalStaked.div(totalSupply).times(100)
    : BN_ZERO
  let activeValidators = BN_ZERO
  let totalValidators = bnOrZero(23)
  // tslint:disable-next-line:forin
  for (const k in valAddrMap) {
    if (valAddrMap[k].carbonValidator.status === BondStatus.BOND_STATUS_BONDED) {
      // tslint:disable:no-increment-decrement
      activeValidators = activeValidators.plus(1)
    }
  }

  const HomeStatsBoxes: StatsBox[] = [
    {
      asset: BlockTimeSVG,
      content: blockTimeBN.toFixed(4),
      footer: 'seconds',
      loadname: [
        TaskNames.Dashboard.BlockTime,
      ],
      isLoaded: !blockTimeBN.isZero(),
      title: `Block Time (Last ${Math.max(blocks.length - 1, 1)})`,
    },
    {
      asset: TotalBlocksSVG,
      content: parseNumber(latestBlock?.height)?.toFormat(),
      footer: '',
      loadname: [
        TaskNames.App.LatestBlock,
      ],
      isLoaded: !!latestBlock?.height,
      title: 'Total Blocks',
    },
    {
      asset: AccountSVG,
      content: `${bondedPercent.toFormat(2)}%`,
      footer: `${totalStaked.toFormat(0)} SWTH`,
      loadname: [
        TaskNames.App.StakingPool,
        TaskNames.App.TotalSupply,
      ],
      isLoaded: !bondedPercent.isZero(),
      title: 'Bonded',
    },
    {
      asset: TransactionSVG,
      content: activeValidators.toFormat(0),
      footer: `${activeValidators.toFormat(0)} / ${totalValidators.toFormat(0)}`,
      loadname: [
        TaskNames.App.Validators,
      ],
      isLoaded: !activeValidators.isZero(),
      title: 'Active Validators',
    },
  ]

  return (
    <>
      {HomeStatsBoxes.map((homeBox: StatsBox, index: number) => (
        <HomeStatsBox {...homeBox} key={index} />
      ))}
    </>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  tab: {
    fontSize: '1.125rem',
  },
  tabs: {
    backgroundColor: switcheo.background.white
  },
  section: {
    padding: '3px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  equal: {
    textAlign: 'center',
  },
  divider: {
    alignSelf: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.25),
  },
  sectionWrap: {
    marginBottom: 0,
  },
  chartGrid: {
    margin: 0,
    minHeight: '100%',
  },
  chartGridWithTab: {
    margin: 0
  },
  statsTitle: {
    fontWeight: 'bold',
  },
}))

export default Dashboard
