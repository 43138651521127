import { SagaIterator } from "redux-saga";
import Saga from "./Saga";
import { Effect, all, call, put } from "redux-saga/effects";
import { clear, setLiveVoteTally, setProposalDeposits, setProposalDetails, setProposalNonValidatorVotes, setProposalValidatorVotes } from "js/actions/proposal";
import { TaskNames, validatorDelegationsLimit } from "js/constants";
import { runSagaTask, waitforSDK } from "./helper";
import { Insights } from "carbon-js-sdk";
import { PageRequest } from "carbon-js-sdk/lib/codec/cosmos/base/query/v1beta1/pagination";
import { ProposalValidatorVoteEntry } from "js/reducers/proposal";
import Long from "long";
import { QueryProposalResponse } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/query'
import { TallyResultResponse } from "js/reducers/governance";

export default class Proposal extends Saga {
  private readonly proposalId: number;
  private readonly isMobile: boolean;
  constructor(proposalId: number, isMobile: boolean) {
    super()
    this.isMobile = isMobile
    this.proposalId = proposalId
  }

  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchVotes], this.proposalId),
      call([this, this.fetchDetails], this.proposalId)
    ]
  }

  private *fetchVotes(proposalId: number): any {
    yield runSagaTask(TaskNames.Proposal.Votes, function* () {
      const sdk = yield* waitforSDK()

      const tallyResponse = (yield sdk.query.gov.TallyResult(
        { proposalId: new Long(proposalId) }
      )) as TallyResultResponse

      yield put(setLiveVoteTally(tallyResponse.tally))

      const insightsQueryClient = sdk.insights
      const pathParams = { proposalId }
      const queryParams = {}

      const response = (yield insightsQueryClient.ProposalVotes(
        pathParams,
        queryParams
      )) as Insights.InsightsQueryResponse<Insights.QueryGetProposalVotesResponse>

      const deposits = response.result.entries.filter(entry => entry.type === "deposit")
      const nonValidatorVotes = response.result.entries.filter(entry => entry.type === "vote" && !entry.validatorAddress)
      const validatorVotes = response.result.entries.filter(entry => entry.type === "vote" && entry.validatorAddress)

      const processedValidatorVotes = (yield all(validatorVotes.map(async (entry) => {
        const validatorDelegations = await sdk.query.staking.ValidatorDelegations({
          validatorAddr: entry.validatorAddress,
          pagination: PageRequest.fromPartial({
            limit: validatorDelegationsLimit
          })
        })
        return { numOfDelegations: validatorDelegations.delegationResponses.length, ...entry }
      }))) as ProposalValidatorVoteEntry[]

      yield put(setProposalDeposits(deposits))
      yield put(setProposalNonValidatorVotes(nonValidatorVotes))
      yield put(setProposalValidatorVotes(processedValidatorVotes))
    })
  }

  private *fetchDetails(proposalId: number): any {
    yield runSagaTask(TaskNames.Proposal.Details, function* () {
      const sdk = yield* waitforSDK()
      const govQueryclient = sdk.query.gov

      const response = (yield govQueryclient.Proposal({
        proposalId: new Long(proposalId)
      })) as QueryProposalResponse

      yield put(setProposalDetails(response?.proposal))
    })
  }
}