import { Insights } from 'carbon-js-sdk'
import { Action } from 'redux'

export enum LeaderboardActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_30D_PNL = 'SET_30D_PNL',
  SET_LIFETIME_PNL = 'SET_LIFETIME_PNL',
  UPDATE_30D_PNL_FILTERS = 'UPDATE_30D_PNL_FILTERS',
  UPDATE_LIFETIME_PNL_FILTERS = 'UPDATE_LIFETIME_PNL_FILTERS',
  SET_LEADERBOARD_MARKET = 'SET_LEADERBOARD_MARKET',
}

export type LeaderboardAction =
  | ClearAction
  | Set30DPnLAction
  | SetLifetimePnLAction
  | Update30dPnLAction
  | UpdateLifetimePnLAction
  | SetLeaderboardMarketAction

export interface ClearAction extends Action<LeaderboardActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: LeaderboardActionType.CLEAR,
  }
}

export interface Set30DPnLAction extends Action<LeaderboardActionType.SET_30D_PNL> {
  d30Pnl: Insights.IndivPnl[]
}

export function set30dPnl(d30Pnl: Insights.IndivPnl[]): Set30DPnLAction {
  return {
    type: LeaderboardActionType.SET_30D_PNL,
    d30Pnl,
  }
}
export interface SetLifetimePnLAction extends Action<LeaderboardActionType.SET_LIFETIME_PNL> {
  lifetimePnl: Insights.IndivPnl[]
}

export function setLifetimePnl(lifetimePnl: Insights.IndivPnl[]): SetLifetimePnLAction {
  return {
    type: LeaderboardActionType.SET_LIFETIME_PNL,
    lifetimePnl,
  }
}

export interface Update30dPnLAction
  extends Action<LeaderboardActionType.UPDATE_30D_PNL_FILTERS> {
  options: any
}

export function update30dPnl(
  options: any,
): Update30dPnLAction {
  return {
    type: LeaderboardActionType.UPDATE_30D_PNL_FILTERS,
    options,
  }
}

export interface UpdateLifetimePnLAction
  extends Action<LeaderboardActionType.UPDATE_LIFETIME_PNL_FILTERS> {
  options: any
}

export function updateLifetimePnl(
  options: any,
): UpdateLifetimePnLAction {
  return {
    type: LeaderboardActionType.UPDATE_LIFETIME_PNL_FILTERS,
    options,
  }
}

export interface SetLeaderboardMarketAction
  extends Action<LeaderboardActionType.SET_LEADERBOARD_MARKET> {
  leaderboardMarket: string
}

export function setLeaderboardMarket(
  leaderboardMarket: string
): SetLeaderboardMarketAction {
  return {
    type: LeaderboardActionType.SET_LEADERBOARD_MARKET,
    leaderboardMarket,
  }
}
