import { Box, makeStyles, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, ReactNode, useState } from 'react'
import { bnOrZero, BN_ZERO } from 'js/utils'
import { Models } from 'carbon-js-sdk'
import PaginationByData from 'js/components/PaginationByData'

interface Props {
  className?: string
  delegations: Models.Staking.DelegationResponse[]
  title: string
}

const Component: React.FunctionComponent<Props> = ({
  className,
  delegations,
  title,
}: Props): ReactElement<Props> => {
  let totalShares = BN_ZERO
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Validator.Delegations)

  delegations.forEach((delegation) => {
    totalShares = totalShares.plus(bnOrZero(delegation.delegation?.shares))
  })

  // For pagination
  const [page, setPage] = useState(1)
  const itemsPerPage = 20
  const delegationsToDisplay = delegations.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)
  return (
    <Section title={title} titleClass={clsx(classes.title, className)}>
      <DataLoadSegment
        loading={loading}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Share</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{delegationsToDisplay?.map((delegation, index) => renderDelegator(delegation, index))}</TableBody>
        </ListTable>
        {delegations.length > 0 && (
            <Box marginTop={3}>
              <PaginationByData
                data={delegations}
                setPage={setPage}
                page={page}
                itemsPerPage={itemsPerPage}
              />
            </Box>
          )}
        {!delegations?.length && (
          <TableEmptyState itemName="delegations" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderDelegator(delegation: Models.Staking.DelegationResponse, index: number): ReactNode {
    const delegatorAddress = delegation.delegation?.delegatorAddress ?? "-"
    const amountBN = bnOrZero(delegation.balance?.amount).shiftedBy(-8)
    const shareBN = bnOrZero(delegation.delegation?.shares).div(totalShares).times(100)
    return (
      <TableRow key={delegatorAddress+index} hover>
        <TableCell>
          <CellLink to={`/account/${delegatorAddress}`}>
            {delegatorAddress}
          </CellLink>
        </TableCell>
        <AmountDenomCell amount={amountBN.toFormat(8)} denom={delegation.balance?.denom} />
        <TableCell align="right">{shareBN.toFormat(2)}%</TableCell>
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textAlign: 'left',
  },
}))

export default Component
