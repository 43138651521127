import { reverseHex } from '@cityofzion/neon-core/lib/u'
import { ListItem, makeStyles } from '@material-ui/core'
import { toBech32Address } from '@zilliqa-js/crypto'
import BigNumber from 'bignumber.js'
import { BlockchainUtils, CarbonSDK } from 'carbon-js-sdk'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import React, { ReactElement, useMemo } from 'react'

import { ReactComponent as GroupTokenIndicator } from 'assets/GroupTokenIndicator.svg'

import { CellLink, Section } from 'js/components'
import { TextComponent } from 'js/components/Text'
import { BlockChainPaths } from 'js/constants'
import { useRedux } from 'js/hooks'
import { returnUrlNet, reverseStr } from 'js/utils'

interface TokenWithSupply extends Token {
  totalSupply: BigNumber
}
interface Props {
  token: TokenWithSupply
}

function isTrue(params: boolean) {
  return params ? 'true' : 'false'
}

const Information: React.FunctionComponent<Props> = ({
  token,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux(state => state.core.carbonSDK)
  const { network } = useRedux((state) => state.app)
  const blockchain = useMemo(() => sdk?.token.getBlockchain(token.denom), [token, sdk])

  const getAssetIDLink = () => {
    const paths = sdk?.network === CarbonSDK.Network.MainNet ? BlockChainPaths.mainnet : BlockChainPaths.devnet
    switch (blockchain) {
      case BlockchainUtils.Blockchain.Ethereum: {
        if (token.denom === 'eth1') {
          return paths.eth.replace(':path', '/')
        }
        return paths.eth.replace(':path', `/token/0x${token.tokenAddress}`)
      }
      case BlockchainUtils.Blockchain.BinanceSmartChain: {
        if (token.denom === 'bnb1') {
          return paths.bsc.replace(':path', '/')
        }
        return paths.bsc.replace(':path', `/token/0x${token.tokenAddress}`)
      }
      case BlockchainUtils.Blockchain.Zilliqa: {
        if (token.denom === 'zil1' || token.tokenAddress === '0000000000000000000000000000000000000000') {
          return paths.zil.replace(':path', '/')
        }
        return paths.zil.replace(':path', `/address/${toBech32Address(token.tokenAddress)}`)
      }
      case BlockchainUtils.Blockchain.Neo3: {
        return paths.neo3.replace(':path', `/tokens/nep17/0x${reverseHex(token.tokenAddress)}`)
      }
      case BlockchainUtils.Blockchain.Neo: {
        if (token.denom === 'nneo1') {
          return paths.neo.replace(':path', `/nep5/${token.tokenAddress}/page/1`)
        }
        return paths.neo.replace(':path', `/nep5/${reverseStr(token.tokenAddress, 2)}/page/1`)
      }
      default:
        return ''
    }
  }

  const getLockProxyLink = () => {
    const paths = sdk?.network === CarbonSDK.Network.MainNet ? BlockChainPaths.mainnet : BlockChainPaths.devnet;
    switch (blockchain) {
      case BlockchainUtils.Blockchain.Ethereum: {
        return paths.eth.replace(':path', `/address/0x${token.bridgeAddress}`)
      }
      case BlockchainUtils.Blockchain.BinanceSmartChain: {
        return paths.bsc.replace(':path', `/address/0x${token.bridgeAddress}`)
      }
      case BlockchainUtils.Blockchain.Zilliqa: {
        return paths.zil.replace(':path', `/address/${toBech32Address(token.bridgeAddress)}`)
      }
      case BlockchainUtils.Blockchain.Neo3: {
        return paths.neo3.replace(':path', `/contract/0x${reverseHex(token.bridgeAddress)}`)
      }
      case BlockchainUtils.Blockchain.Neo: {
        return paths.neo.replace(':path', `/address/${reverseStr(token.bridgeAddress, 2)}/page/1`)
      }
      default:
        return ''
    }
  }
  const assetIDLink = getAssetIDLink();
  const lockProxyLink = getLockProxyLink();
  const isGroupedToken = token?.denom === 'cgt/1'
  return (
    <Section title="Details">
      <ListItem disableGutters>
        <div className={classes.header}>Name</div>
        <div className={classes.string}>{token.name}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Symbol</div>
        <div className={classes.string}>
          <TextComponent
            showIcon={isGroupedToken}
            svgIcon={isGroupedToken ? GroupTokenIndicator : undefined}
          >
            {token.symbol}
          </TextComponent>
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Decimals</div>
        <div className={classes.string}>{token.decimals.toString()}</div>
      </ListItem>
      {
        token.chainId.toString() !== "244"
          ? <ListItem disableGutters>
            <div className={classes.header}>Creator</div>
            <CellLink href={`/account/${token.creator}?net=${returnUrlNet(network)}`}>
              <div className={classes.string}>{token.creator}</div>
            </CellLink>
          </ListItem>
          : undefined
      }
      <ListItem disableGutters>
        <div className={classes.header}>Denom</div>
        <div className={classes.string}>{token?.denom}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Token ID</div>
        <div className={classes.string}>{token.id.toString()}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Bridge ID</div>
        <div className={classes.string}>{token.bridgeId.toString()}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Chain ID</div>
        <div className={classes.string}>{`${token.chainId.toString()} (${blockchain ?? " - "})`}</div>
      </ListItem>
      {
        token.chainId.toString() !== "244"
          ? <ListItem disableGutters>
            <div className={classes.header}>Bridge Address</div>
            {
              lockProxyLink
                ?
                <CellLink href={lockProxyLink}>
                  <div className={classes.string}>{token.bridgeAddress}</div>
                </CellLink>
                : <div className={classes.string}>{token.bridgeAddress}</div>
            }
          </ListItem>
          : undefined
      }
      {
        token.chainId.toString() !== "244"
          ? <ListItem disableGutters>
            <div className={classes.header}>Token Address</div>
            {
              assetIDLink
                ?
                <CellLink href={assetIDLink}>
                  <div className={classes.string}>{token.tokenAddress}</div>
                </CellLink>
                : <div className={classes.string}>{token.tokenAddress}</div>
            }
          </ListItem>
          : undefined
      }
      <ListItem disableGutters>
        <div className={classes.header}>Is Active</div>
        <div className={classes.string}>{isTrue(token.isActive)}</div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Total Supply</div>
        <div className={classes.string}>{token?.totalSupply?.toNumber() ?? '-'}</div>
      </ListItem>
    </Section>
  )
}

const useStyles = makeStyles({
  string: {
    wordBreak: 'break-all',
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
})

export default Information
