import { AdminModule } from 'carbon-js-sdk'
import { SetCommitmentCurveProposalFormState } from 'js/models'
import store from 'js/store'
import { BIG_ZERO, escapeHtmlGo, parseNumber } from 'js/utils'
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { CarbonTx } from "carbon-js-sdk"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { MsgSetCommitmentCurve } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/tx'

export function setCommitmentCurveProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as SetCommitmentCurveProposalFormState

  const { title, summary, metadata, maxDuration, maxRewardMultiplier } = formState
  const setCommitmentCurveProposalParams: AdminModule.SetCommitmentCurveParams = {
    maxDuration: parseNumber(maxDuration, BIG_ZERO)!.toNumber(),
    maxRewardMultiplier: parseNumber(maxRewardMultiplier, BIG_ZERO)!.toNumber(),
  }

  const msg = MsgSetCommitmentCurve.fromPartial({
    creator: authority,
    setCommitmentCurveParams: setCommitmentCurveProposalParams
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    initialDeposit,
    expedited: false,
    proposer: proposer ?? "",
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgSetCommitmentCurve,
        value: MsgSetCommitmentCurve.encode(msg).finish()
      }
    ],
  }

  return value
}
