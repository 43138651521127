import { makeStyles, Theme, Typography } from '@material-ui/core';
import {
  SwapHoriz,
  ArrowForward,
  ArrowBack
} from '@material-ui/icons';
import { Section, TokenIcon } from 'js/components';
import { useRedux } from 'js/hooks';
import { switcheo } from 'js/theme/palettes/colors';
import React from 'react';
import clsx from 'clsx';

interface PoolRoutesProp {
  market: any;
  poolRouteFlow: any[];
}

const PoolRoutes = (props: PoolRoutesProp) => {
  const { market, poolRouteFlow } = props;
  const { carbonSDK: sdk } = useRedux((state) => state.core);
  const marketSymbolA = sdk?.token.getSymbol(market?.base ?? '');
  const marketSymbolB = sdk?.token.getSymbol(market?.quote ?? '');
  const classes = useStyles();
  return (
    <Section title="Pool Routes" style={{ overflowX: 'scroll' }}>
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1em' }}>
            <Typography>Buy -{'>'}</Typography>
            <Typography>{'<'}- Sell</Typography>
          </div> */}
      <br />
      <div style={{ padding: '1.5em 1em 0em 1em', minHeight: `${14 + ((poolRouteFlow?.length - 1) * 8)}em`, minWidth: `${40 + ((poolRouteFlow?.length - 1) * 10.5)}em`, maxWidth: '1200px' }}>
        {
          // eslint-disable-next-line array-callback-return
          poolRouteFlow.map((routesParsed, index) => {
            return (
              <div className={clsx(classes.routePath, { larger: index !== 0 })} key={index}>
                <div className={clsx(classes.routeItemsWrapper, { larger: index !== 0 })}>
                  {index === 0 && (
                    <div className={classes.startToken}>
                      <div><b>{marketSymbolA?.toUpperCase()}</b></div>
                      <TokenIcon className={classes.token} tokenSymbol={marketSymbolA} />
                      <div style={{ position: 'absolute', bottom: 10, left: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant={'h6'} style={{ color: switcheo.red }}>
                            SELL
                          </Typography>
                          <ArrowForward style={{ color: switcheo.red }} />
                        </div>
                      </div>
                    </div>
                  )}
                  <div style={{ position: 'relative' }}>
                    <div style={{}} className={clsx(classes.routeItems, { larger: index !== 0 })}>
                      {routesParsed.map((o: any) => {
                        const symbolA = sdk?.token.getSymbol(o.denomA);
                        const symbolB = sdk?.token.getSymbol(o.denomB);
                        return (
                          <div key={symbolA ?? '' + symbolB ?? '' + o?.poolId}>
                            <div className={classes.poolDetails}>
                              <TokenIcon className={classes.token} denom={o.denom} tokenSymbol={symbolA} />
                              <SwapHoriz />
                              <TokenIcon className={classes.token} denom={o.denom} tokenSymbol={symbolB} />
                              <div>
                                <Typography variant={'caption'} className={classes.caption}>
                                  Pool <b>{o?.poolId}</b>
                                </Typography>
                                <br />
                                <Typography variant={'caption'} className={classes.caption}>
                                  <b>{`${symbolA?.toUpperCase()}/${symbolB?.toUpperCase()}`}</b>
                                </Typography>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {index === 0 && (
                    <div className={classes.endToken}>
                      <div><b>{marketSymbolB?.toUpperCase()}</b></div>
                      <TokenIcon className={classes.token} tokenSymbol={marketSymbolB} />
                      <div style={{ position: 'absolute', bottom: 10, right: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ArrowBack style={{ color: switcheo.green[400] }} />
                          <Typography variant={'h6'} style={{ color: switcheo.green[400] }}>
                            BUY
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </Section>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  routeItemsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 8fr 1fr',
    height: '100%',
    '&.larger': {
      gridTemplateColumns: 'auto',
    }
  },
  routeItems: {
    position: 'relative',
    display: 'flex',
    placeContent: 'space-around',
    alignContent: 'flex-end',
    transform: `translateY(10%)`,
    height: '100%',
    '&.larger': {
      transform: `translateY(50%)`,
    }
  },
  poolDetails: {
    height: '6em',
    width: '9em',
    border: '1px solid darkgrey',
    padding: '0.5em 1em',
    placeSelf: 'center',
    background: switcheo.background.white,
    textAlign: 'center',
    borderRadius: '8px'
  },
  routePath: {
    position: 'relative',
    height: '4em',
    borderStyle: 'none dashed dashed dashed',
    borderWidth: '3px',
    borderRadius: '4px',
    borderColor: 'darkgrey',
    '&.larger': {
      height: '8em'
    }
  },
  token: {
    width: '30px',
    height: '30px',
  },
  startToken: {
    textAlign: 'center',
    justifySelf: 'flex-start',
    transform: 'translate(-52%,-25%)'
  },
  endToken: {
    textAlign: 'center',
    justifySelf: 'flex-end',
    transform: 'translate(51%,-25%)'
  },
  caption: {
    lineHeight: 0
  }
}));

export default PoolRoutes