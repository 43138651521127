import { Box, BoxProps, Grid, makeStyles, TextField, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { TokenSelectInput } from 'js/components/form'
import { TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { CommunityPoolSpendProposalFormState } from 'js/models/Governance'
import { actions } from 'js/store'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CommunityPoolSpendProposalInitialFormState } from '../constants'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import { communityPoolSpendRequiredFields } from './Helpers/FormConstraints'


interface Props extends BoxProps {
}

const CommunityPoolSpendMainForm: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const formState = (useRedux((state) => state.governance.submitProposalFormState) || CommunityPoolSpendProposalInitialFormState) as CommunityPoolSpendProposalFormState

  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    dispatch(setSubmitProposalFormState(formState))
    validateRequiredFields(formState)
    // eslint-disable-next-line
  }, [])

  const validateRequiredFields = (formState: CommunityPoolSpendProposalFormState) => {
    const requiredFieldsConstraints = getRequiredFieldsConstraints(communityPoolSpendRequiredFields)
    const errors = getErrorMessages(formState, requiredFieldsConstraints)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const handleFormChange = (key: keyof CommunityPoolSpendProposalFormState) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      const newFormState = {
        ...formState,
        [key]: value,
      }

      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TokenSelectInput value={formState.denom} onChange={handleFormChange('denom')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              label="Recipient"
              InputLabelProps={{ shrink: true }}
              value={formState.recipient}
              onChange={handleFormChange('recipient')}
            />
        </Grid>
        <Grid item xs={12}>
          <TextField
              fullWidth
              label="Amount"
              InputLabelProps={{ shrink: true }}
              value={formState.amount}
              onChange={handleFormChange('amount')}
            />
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0, 3),
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1rem",
  },
  warningIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  menuItems: {
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

export default CommunityPoolSpendMainForm

