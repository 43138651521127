import { Backdrop, Box, Hidden, makeStyles, MenuItem, MenuList, Paper } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { CarbonSDK } from 'carbon-js-sdk'
import clsx from 'clsx'
import { setNetwork } from 'js/actions/app'
import { useRedux } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { getNetworkLabel } from 'js/utils'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

const NetworkToggle: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props: any) => {
  const { children, className, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { network } = useRedux((state) => state.app)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const onSelectNetwork = (newNetwork: CarbonSDK.Network) => {
    if (newNetwork !== network) {
      dispatch(setNetwork(newNetwork))
    }
    setDropdownOpen(false)
  }

  const selectNetworkHandler = (option: CarbonSDK.Network) => {
    return () => onSelectNetwork(option)
  }

  const handleMenuClick = () => setDropdownOpen(!dropdownOpen)

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Hidden smDown>
        <MenuItem
          className={clsx(classes.dropdownItem, classes.dropdownSelect)}
          onClick={handleMenuClick}
        >
          Carbon {getNetworkLabel(network)}
          {
            dropdownOpen
              ? (
                <ArrowDropUp className={classes.dropdownIcon} />
              ) : (
                <ArrowDropDown className={classes.dropdownIcon} />
              )
          }
        </MenuItem>
        {
          dropdownOpen && (
            <React.Fragment>
              <Backdrop
                className={classes.backdrop}
                invisible
                open={dropdownOpen}
                onClick={() => setDropdownOpen(false)}
              />
              <Paper className={classes.menuPaper}>
                <MenuList className={classes.menuList}>
                  {Object.values(CarbonSDK.Network).map((option, index) =>
                    <MenuItem
                      className={classes.dropdownItem}
                      selected={false}
                      key={index}
                      onClick={selectNetworkHandler(option)}
                    >
                      Carbon {getNetworkLabel(option)}
                    </MenuItem>,
                  )}
                </MenuList>
              </Paper>
            </React.Fragment>
          )
        }
      </Hidden>
      <Hidden mdUp>
        <MenuList className={classes.menuList}>
          {Object.values(CarbonSDK.Network).map((option, index) =>
            <MenuItem
              className={classes.dropdownItem}
              selected={option === network}
              key={index}
              onClick={selectNetworkHandler(option)}
            >
              {getNetworkLabel(option)}
            </MenuItem>,
          )}
        </MenuList>
      </Hidden>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  menuList: {
    paddingBottom: 0,
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  backdrop: {
    zIndex: 999,
  },
  menuPaper: {
    position: 'absolute',
    top: '2.5rem',
    zIndex: 1000,
    backgroundColor: switcheo.background.grey,
  },
  dropdownItem: {
    fontSize: '1rem',
    minWidth: 116,
    justifyContent: 'flex-start',
    minHeight: theme.spacing(6),
    '&:hover': {
      backgroundColor: switcheo.background.grey,
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: switcheo.background.grey,
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        backgroundColor: switcheo.background.grey,
        [theme.breakpoints.down('sm')]: {
          backgroundColor: 'transparent',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
    },
  },
  dropdownIcon: {
    verticalAlign: 'middle',
  },
  dropdownSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '11.25rem',
  },
}))

export default NetworkToggle
