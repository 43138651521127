import { Box, makeStyles, Paper, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'

interface Props {
  children: any
  title?: string
}

const TableBox: React.FunctionComponent<Props> = ({
  children,
  title,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  return (
    <Paper className={classes.container}>
      <Box p={2}>
        {!!title && (
          <Typography variant="h5" noWrap gutterBottom>
            {title}
          </Typography>
        )}
        {children}
      </Box>
    </Paper>
  )
}

const useStyles = makeStyles({
  container: {
    height: '100%',
    marginBottom: 16,
    overflowX: 'auto',
  },
})

export default TableBox
