import { makeStyles, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { CellLink, DataLoadSegment, ListTable, Section, TableEmptyState, TooltipHint } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement } from 'react'

type Props = {
  oracleWindowStartBlock: number
  oracleWindowEndBlock: number
  validators: any
  oracleIds: string[]
}

const OracleSlashing: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const classes = useStyles()
  const { oracleWindowStartBlock, oracleWindowEndBlock, validators, oracleIds: oracleIdsUnfiltered } = props
  const oracleIds = oracleIdsUnfiltered.filter((o) => o !== '.CSTRIDE')
  const [loading] = useTaskSubscriber(TaskNames.Validators.Distribution)
  const validatorsArray: any = Object.values(validators)
  const maxVotesMap: any = {}
  //construct map
  for (const oracleId of oracleIds) {
    maxVotesMap[oracleId] = 0
  }

  //find the max
  for (const validator of validatorsArray) {
    if (!validator?.vote) continue //fix race condition
    for (const oracleId of oracleIds) {
      if (validator?.vote[oracleId]?.count > maxVotesMap[oracleId]) maxVotesMap[oracleId] = validator.vote[oracleId]?.count
    }
  }

  let leadingVotes: { [id: string]: number } = {
    total: 0
  }
  validatorsArray.forEach((val: any) => {
    const oracleArray = Object.values(val?.vote ?? {})
    const totalVotes = val?.vote ? oracleArray.reduce((acc: any, curr: any) => {
      if (!leadingVotes[curr?.id]) leadingVotes[curr?.id] = 0
      leadingVotes[curr?.id] = Math.max(Number(curr?.count ?? 0), leadingVotes[curr?.id])
      return acc + (Number(curr?.count) ?? 0)
    }, 0) : 0
    val.totalVotes = totalVotes ?? 0
    leadingVotes.total = Math.max(Number(totalVotes), leadingVotes.total)
  })

  validatorsArray.sort((a: any, b: any) => b?.slashCount - a?.slashCount || a?.totalVotes - b?.totalVotes)

  return (
    <Section title={"Oracle Uptime"}>
      <DataLoadSegment loading={loading}>
        <div className={classes.blockInfoWrapper}>
          <span style={{ textAlign: "left", alignSelf: 'center' }}>
            <Typography variant="h4">
              Current Block Window: <CellLink to={`/block/${oracleWindowStartBlock}`}>{oracleWindowStartBlock.toString()}</CellLink> ~ <CellLink to={`/block/${oracleWindowEndBlock}`}>{oracleWindowEndBlock.toString()}</CellLink>
            </Typography>
            <Typography variant="h4">
              Current Leading Total Votes: <strong>{leadingVotes.total}</strong>
            </Typography>
          </span>
          <div id="legends" className={classes.legendsWrapper}>
            <TooltipHint title="Slashed and >75% difference from leading vote.">
              <div id="legends" className={classes.legends}>
                <span className={clsx(classes.legendBoxes, 'red')} />
                <span>
                  <span>Slashed</span>
                </span>
              </div>
            </TooltipHint>
            <TooltipHint title=">10% difference from leading vote. At risk to be slashed.">
              <div id="legends" className={classes.legends}>
                <span className={clsx(classes.legendBoxes, 'orange')} />
                <span>
                  <span>Unhealthy</span>
                </span>
              </div>
            </TooltipHint>
            <TooltipHint title="Within 1% difference from leading vote">
              <div id="legends" className={classes.legends}>
                <span className={clsx(classes.legendBoxes, 'green')} />
                <span>
                  <span>Healthy</span>
                </span>
              </div>
            </TooltipHint>
          </div>
        </div>
        <br />
        <div className={classes.overflow}>
          <ListTable>
            <TableHead>
              <TableRow>
                <TableCell>Validator</TableCell>
                <TableCell>Oracles (Votes)</TableCell>
                <TableCell>Total Votes</TableCell>
                <TableCell>vs Leading(%)</TableCell>
                <TableCell align="right">Slash Counter</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {validatorsArray.map((val: any) => {
                const difference = leadingVotes.total - (val?.totalVotes ?? 0)
                const differenceInPercentage = (difference / leadingVotes.total) * 100
                const isRed = val?.slashCount?.toNumber() > 0
                const isOrange = differenceInPercentage > 10
                return (
                  <TableRow key={val?.validator + val?.slashCount}>
                    <TableCell>
                      <CellLink to={`/validator/${val?.validator}`} className={classes.valName}>
                        <h3>{val.moniker}</h3>
                      </CellLink>
                    </TableCell>
                    <TableCell>
                      <div className={classes.healthWrapper}>
                        {oracleIds.map((oracleId) => {
                          const difference = leadingVotes[oracleId] - (val?.vote?.[oracleId]?.count ?? 0)
                          const differenceInPercentage = (difference / leadingVotes[oracleId]) * 100
                          const isRed = val?.slashCount?.toNumber() > 0 && differenceInPercentage > 75
                          const isOrange = differenceInPercentage > 10
                          return <div className={clsx(classes.health, 'green', { orange: isOrange, red: isRed })} key={oracleId + leadingVotes[oracleId]}>
                            <TooltipHint title={
                              <div className={classes.toolTipWrapper}>
                                <div>
                                  <div>
                                    Votes
                                  </div>
                                  <div style={{ padding: '0px 5px' }}>
                                    {val?.vote?.[oracleId]?.count ?? 0}
                                  </div>
                                </div>
                                <div style={{ borderLeft: '1px solid white', padding: '0px 10px 0px 15px' }}>
                                  <div>
                                    Leading
                                  </div>
                                  <div>
                                    {leadingVotes[oracleId]}
                                  </div>
                                </div>
                              </div>}>
                              <div>
                                <Typography className={classes.oracleId}>
                                  {oracleId}
                                </Typography>
                                <Typography>
                                  <strong>{val?.vote?.[oracleId]?.count ?? 0}</strong>
                                </Typography>
                              </div>
                            </TooltipHint>
                          </div>
                        })}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={clsx(classes.healthColor, { orange: isOrange, red: isRed })}>
                        <h1>{val?.totalVotes as string}</h1>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={clsx(classes.healthColor, { green: differenceInPercentage < 1, orange: isOrange, red: isRed })}>
                        <h1>{(difference > 0 ? '-' : '+') + difference} ({differenceInPercentage.toFixed(2)}%)</h1>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={clsx(classes.healthColor, { green: val?.slashCount?.toNumber() < 1, orange: isOrange, red: isRed })}>
                        <h1>{val?.slashCount?.toNumber()}</h1>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </ListTable>
        </div>
        {!!!validatorsArray.length && <TableEmptyState itemName="Oracle Voting" />}
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  blockInfoWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.only('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  legendsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto',
  },
  legends: {
    display: 'grid',
    gridTemplateColumns: 'auto 5em',
    placeItems: 'baseline',
    maxWidth: '7rem',
    minWidth: '7rem',
    justifySelf: 'end',
    gap: '2px',
    [theme.breakpoints.only('xs')]: {
      justifySelf: 'flex-start',
      marginTop: theme.spacing(1)
    },
  },
  legendBoxes: {
    aspectRatio: '4/1',
    height: 10,
    '&.green': {
      backgroundColor: '#38C156'
    },
    '&.orange': {
      backgroundColor: '#D78100'
    },
    '&.red': {
      backgroundColor: '#E84B55'
    },
  },
  toolTipWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    placeContent: 'center',
    placeItems: 'center',
    textAlign: 'center'
  },
  oracleId: {
    fontSize: '0.65rem',
  },
  additionalInfoWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1.3fr 1fr',
    marginLeft: '1rem'
  },
  overflow: {
    height: '20rem',
    overflow: "auto",
    padding: '0px 10px'
  },
  valName: {
    display: 'flex',
    placeItems: 'center',
  },
  Wrapper: {
    padding: '0.5rem 1rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1.5fr 2.4fr',
    width: '100%',
    borderTop: '1px solid lightgrey',
    // borderBottom: '1px solid lightgrey',
    borderRadius: '8px',
    // margin: '0.5em 0em',
    gap: '1rem',
    boxShadow: 'none',
  },
  healthWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    justifyContent: 'center',
    alignItems: 'center',
  },
  health: {
    color: 'white',
    boxSizing: 'border-box',
    padding: '0.1rem 0rem',
    border: '0.5px solid white',
    textAlign: 'center',
    backgroundColor: 'grey',
    height: '100%',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    minWidth: '5.7em',
    '&.green': {
      backgroundColor: '#38C156'
    },
    '&.orange': {
      backgroundColor: '#D78100'
    },
    '&.red': {
      backgroundColor: '#E84B55'
    },
  },
  healthColor: {
    // color: 'lightgrey',
    '&.green': {
      color: '#38C156'
    },
    '&.orange': {
      color: '#D78100'
    },
    '&.red': {
      color: '#E84B55'
    },
  }
}))

export default OracleSlashing
