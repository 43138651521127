import { Insights } from 'carbon-js-sdk'
import { QueryGetLiquidationAndADLResponse, QueryGetLiquidationEngineResponse } from 'carbon-js-sdk/lib/insights'
import { TaskNames } from 'js/constants'
import Long from 'long'
import { SagaIterator } from 'redux-saga'
import {
  Effect,
  put,
  spawn,
  takeLatest
} from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import {
  clear,
  EventsActionType,
  setExpiredMarkets,

  setLeAccOrders, setLEOrders
} from '../actions/events'
import { runSagaTask, waitforSDK } from './helper'
import Saga from './Saga'
import { QueryAllMarketResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/query'

export default class Events extends Saga {
  private readonly isMobile: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchEventsLE],
      [this, this.fetchLiquidations],
      [this, this.fetchEventsSettledMarkets],
      [this, this.watchSetNetwork],
      [this, this.watchOrdersFilters],
      [this, this.watchLeAccOrdersFilters],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchEventsLE(): any {
    yield runSagaTask(TaskNames.Events.LEOrders, function* () {
      const sdk = yield* waitforSDK()

      const orders = (yield sdk.insights.LiquidationEngine({
        offset: 0,
        limit: 10,
        sort: "DESC",
      })) as Insights.InsightsQueryResponse<QueryGetLiquidationEngineResponse>
      yield put(setLEOrders(orders?.result))
    });
  }

  private *fetchEventsSettledMarkets(): any {
    yield runSagaTask(TaskNames.Events.SettledMarkets, function* () {
      const sdk = yield* waitforSDK()

      const markets = (yield sdk.query.market.MarketAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })) as QueryAllMarketResponse
      const marketMap: any = {}
      markets?.markets.map((market) => {
        if (market.isSettled) {
          marketMap[market.id] = market
        }
        return null
      })
      yield put(setExpiredMarkets(marketMap))
    });
  }

  private *fetchLiquidations(action?: any): any {
    yield runSagaTask(TaskNames.Events.LETrades, function* () {
      const sdk = yield* waitforSDK()
      const orders = (yield sdk.insights.LiquidationAndADL({
        offset: 0,
        limit: 10,
        sort: "DESC",
        ...action?.options,
      })) as Insights.InsightsQueryResponse<QueryGetLiquidationAndADLResponse>
      yield put(setLeAccOrders(orders?.result))
    });
  }
  private *watchLeAccOrdersFilters(): any {
    yield takeLatest(
      EventsActionType.UPDATE_LE_ACC_ORDERS_FILTERS,
      this.fetchLiquidations,
    )
  }

  private *watchOrdersFilters(): any {
    yield takeLatest(
      EventsActionType.UPDATE_ORDERS_FILTERS,
      this.handleFilters,
    )
  }
  private *handleFilters(action: any): Generator {
    yield runSagaTask(TaskNames.Events.LEOrderFilter, function* () {
      const sdk = yield* waitforSDK()

      const orders = (yield sdk.insights.LiquidationEngine({
        offset: 0,
        limit: 10,
        sort: "DESC",
        ...action.options,
      })) as Insights.InsightsQueryResponse<QueryGetLiquidationEngineResponse>

      yield put(setLEOrders(orders?.result))
    });
  }
}
