import { CarbonSDK } from 'carbon-js-sdk'
import { AppActionType } from 'js/actions/app'
import { actions, RootState } from 'js/store'
import { Middleware } from 'redux'

const WalletKey = '@account/wallet'
const NetworkKey = '@account/network'

const sessionStorageMiddleware: Middleware = () => (next) => (action) => {
  const { type } = action
  switch (type) {
    case actions.Core.ActionType.CORE_UPDATE_CARBON_SDK:
      const sdk = action.sdk as CarbonSDK | undefined
      const mnemonics = sdk?.wallet?.mnemonic
      if (mnemonics) {
        sessionStorage.setItem(WalletKey, mnemonics)
      } else {
        sessionStorage.removeItem(WalletKey)
      }
      break
    case AppActionType.SET_NETWORK:
      const network = CarbonSDK.parseNetwork(action.network)!
      sessionStorage.setItem(NetworkKey, network)
      break
  }
  return next(action)
}

export const loadSessionStorageState = () => {
  const state: Partial<RootState> = {
    core: {
      sdk: null,
      carbonSDK: undefined,
      storedMnemonics: sessionStorage.getItem(WalletKey),
      storedNetwork: sessionStorage.getItem(NetworkKey),
      valAddrMap: {},
      blocks: [],
    },
  }

  return state
}

export default sessionStorageMiddleware
