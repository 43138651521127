import { Accordion, AccordionSummary, makeStyles, Theme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { ColumnLoading } from './ColumnLoading';

export const AccordionWithColumnLoading = () => {
  const classes = useStyles()
  return (
    <Accordion expanded={false}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        id="loading-panel"
      >
        <div className={classes.loadingContainer}>
          <ColumnLoading />
          <ColumnLoading />
          <ColumnLoading />
          <ColumnLoading />
          <ColumnLoading />
          <ColumnLoading />
          <ColumnLoading />
          <ColumnLoading />
        </div>
      </AccordionSummary>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1rem auto auto auto 27rem auto auto auto',
    gap: '1.5em',
    padding: '10px 0px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
      gap: '0.5em'
    }
  },
  accordionSummary: {
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column',
      '& > .MuiAccordionSummary-content': {
        width: '100%'
      },
    }
  }
}))