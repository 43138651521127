import BigNumber from 'bignumber.js'
import { Proposal, ProposalStatus, TallyParams } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/gov'
import { Constants } from 'js/constants'
import { useRedux } from 'js/hooks'
import { TallyResultResponse } from 'js/reducers/governance'
import { adjustHuman, BIG_ZERO, bnOrZero } from 'js/utils'
import React from 'react'

interface Props {
  proposal: Proposal
}

enum states {
  WaitMinDeposit,
  WaitQuorum,
  PassedThreshold,
  FailedThreshold,
  RejectedVeto,
  Rejected,
  RejectedMinQuorum,
  Executed,
}

const ProposalStatusProp: React.FC<Props> = (props: Props) => {
  const valAddrMap = useRedux((state) => state.core.valAddrMap)
  const voteTallies = useRedux((state) => state.governance.liveVoteTallies)
  const tallyParams = useRedux((state) => state.governance.tallyParams)
  const sdk = useRedux((state) => state.core.carbonSDK)

  const { proposal } = props
  const voteTally = voteTallies[proposal.id.toString()]
  const totalStakingPower = Object.values(valAddrMap).reduce((sumShares, valpair) => {
    const delegatorShares = sdk?.token.toHuman('swth', adjustHuman(valpair.carbonValidator.delegatorShares)) ?? BIG_ZERO
    return sumShares = sumShares.plus(delegatorShares)
  }, BIG_ZERO)

  const state = getState(proposal, voteTally, totalStakingPower, tallyParams)
  return (
    <React.Fragment>
      {getMessage(state, tallyParams)}
    </React.Fragment>
  )
}

function getState(proposal: Proposal,
  voteTally: TallyResultResponse | undefined,
  totalStakingPower: BigNumber,
  tallyParams: TallyParams | undefined,
) {
  let state = states.WaitMinDeposit

  switch (proposal.status) {
    case ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD:
      return state
    case ProposalStatus.PROPOSAL_STATUS_PASSED:
      return states.Executed
    case ProposalStatus.PROPOSAL_STATUS_REJECTED:
      return states.Rejected
    case ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD:
      state = states.WaitQuorum
  }

  const resultYes = bnOrZero(voteTally?.tally.yesCount!)
  const resultNo = bnOrZero(voteTally?.tally.noCount!)
  const resultVeto = bnOrZero(voteTally?.tally.noWithVetoCount!)
  const resultAbstain = bnOrZero(voteTally?.tally.abstainCount!)
  const totalVoted = resultYes
    .plus(resultNo)
    .plus(resultVeto)
    .plus(resultAbstain)

  // Quorum: more than 40% of the total staked tokens at
  //  the end of the voting period need to have voted
  const totalStakingPowerUnitless = totalStakingPower.shiftedBy(Constants.Decimals.SWTH)
  if ((totalVoted.div(totalStakingPowerUnitless)).lt(
    new BigNumber(tallyParams?.quorum!) || 0.4)) {
    state = states.WaitQuorum

    // Veto: More than 33.4% of the tokens that participated in the vote,
    //  not counting “Abstain” votes, have vetoed the decision “No (With Veto)”.
  } else if ((resultVeto.div(totalVoted.minus(resultAbstain))).gt(new BigNumber(tallyParams?.vetoThreshold!) || 0.334)) {
    state = states.RejectedVeto

    // Threshold: More than 50% or a majority of the tokens that participated in the vote,
    //  excluding “Abstain” votes must have voted “Yes”
  } else if ((resultYes.div(totalVoted.minus(resultAbstain))).gt(new BigNumber(tallyParams?.threshold!) || 0.5)) {
    state = states.PassedThreshold

  } else if (((resultNo.plus(resultVeto)).div(totalVoted.minus(resultAbstain))).gt(new BigNumber(tallyParams?.threshold!) || 0.5)) {
    state = states.FailedThreshold
  }

  return state
}

function getPercent(value: BigNumber) {
  return `${value.shiftedBy(2).toFormat(2)}%`
}

function getMessage(state: states, tallyParams: TallyParams | undefined) {
  switch (state) {
    case states.WaitMinDeposit:
      return 'Waiting to reach minimum deposit'
    case states.WaitQuorum:
      return 'Waiting to reach quorum'
    case states.PassedThreshold:
      return `Reached pass threshold`
    case states.Executed:
      return 'Proposal was executed'
    case states.RejectedMinQuorum:
      return 'Failed to reach minimum quorum'
    case states.Rejected:
      return 'Proposal was rejected'
    case states.FailedThreshold:
      return `Over ${getPercent((new BigNumber(tallyParams?.threshold!) || 1.5).minus(1).abs())} voted for No or No with Veto`
    case states.RejectedVeto:
      return `Over ${getPercent((new BigNumber(tallyParams?.vetoThreshold!) || 0.334))} voted for No with Veto`
    default:
      return ''
  }
}

export default ProposalStatusProp
