import { Validator as TmValidator } from "@cosmjs/tendermint-rpc/build/tendermint37"
import BigNumber from "bignumber.js"
import { Models } from 'carbon-js-sdk'

export type ValueOf<T> = T[keyof T]
export const toValueKeyMap = (input: { [index: string]: string }) => {
  const output: { [index: string]: string } = {}
  for (const key in input) {
    output[input[key]] = key
  }
  return output
}

export interface SimpleMap<T = string> {
  [index: string]: T
}

export interface ResultsMinMax<T = any> {
  data: T[]
  max: Long
  min: Long
}

export interface ValPair {
  tmValidator?: TmValidator;
  carbonValidator: Models.Staking.Validator;
  consAddress?: string;
}

export type DailyLiquiditySum = {
  x: moment.Moment;
  y: number;
};

export type DelegationRewards = {
  denom: string;
  native: RewardEntry[]
  alliance: RewardEntry[]
}

export type RewardEntry = {
  validatorAddress: string;
  amount: BigNumber
}