import { FormControl, Grid, Input, InputLabel, makeStyles, MenuItem, Select, Theme } from '@material-ui/core'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { TooltipHint } from 'js/components'
import React from 'react'
import { fieldTypes } from './types'

const FieldMapper = (props: any, formState: any, handleFormChange: any) => {
  const classes = useStyles()
  switch (props.type) {
    case fieldTypes.select:
      return (
        <FormControl fullWidth>
          <InputLabel shrink>
            {props.label}
            {
              props.tooltip &&
              <TooltipHint title={props.tooltip} />
            }
          </InputLabel>
          <Select
            displayEmpty
            value={formState[props.id] || ''}
            onChange={handleFormChange(props.id)}
            MenuProps={{
              getContentAnchorEl: null,
            }}>
            {
              props.placeholder &&
              <MenuItem value="" disabled>{props.placeholder}</MenuItem>
            }
            {
              props.options.map((option: string) =>
                <MenuItem key={option} value={option}>{option}</MenuItem>,
              )
            }

          </Select>
        </FormControl>)
    case fieldTypes.text:
      return (
        <FormControl fullWidth>
          <InputLabel shrink>
            {props.label}
            {
              props.tooltip &&
              <TooltipHint title={props.tooltip} />
            }
          </InputLabel>
          <Input value={formState[props.id]}
            fullWidth
            placeholder={props.placeholder}
            onChange={handleFormChange(props.id)} />
        </FormControl>
      )
    case fieldTypes.number:
      return (
        <FormControl fullWidth>
          <InputLabel shrink>
            {props.label}
            {
              props.tooltip &&
              <TooltipHint title={props.tooltip} />
            }
          </InputLabel>
          <Input value={formState[props.id]}
            fullWidth
            type="number"
            placeholder={props.placeholder}
            onChange={handleFormChange(props.id)} />
        </FormControl>
      )
    case fieldTypes.datetime:
      return (
        <Grid item xs={12}>
          <InputLabel shrink>
            {props.label}
            {
              props.tooltip &&
              <TooltipHint title={props.tooltip} />
            }
          </InputLabel>
          <FormControl fullWidth>
            <KeyboardDateTimePicker
              value={formState[props.id]}
              onChange={(value) => handleFormChange(props.id, value)}
              DialogProps={{
                className: classes.datetimePicker,
              }}
              invalidDateMessage={''}
            />
          </FormControl>
        </Grid>
      )
    default:
      return null
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  datetimePicker: {
    '& .MuiPickersModal-dialogRootWider': {
      maxWidth: '20rem',
    },
    '& .MuiPickersBasePicker-pickerView': {
      maxWidth: 'unset',
    },
    '& .MuiPickerDTToolbar-toolbar': {
      '& .MuiPickersToolbarText-toolbarTxt': {
        '&.MuiTypography-subtitle1': {
          ...theme.typography.subtitle1,
          fontSize: '1rem',
          maxHeight: '1.85rem',
        },
        '&.MuiTypography-h4': {
          ...theme.typography.h4,
          fontSize: '2rem',
          lineHeight: 1.17,
          letterSpacing: '0.00735rem',
          fontWeight: 600,
        },
        '&.MuiTypography-h3': {
          ...theme.typography.h3,
          fontSize: '3rem',
          lineHeight: 1.04,
          letterSpacing: '0rem',
        },
      },
    },
    '& .MuiPickerDTTabs-tabs': {
      border: '1px solid transparent',
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    '& .MuiPickersYearSelection-container': {
      '& .MuiPickersYear-root': {
        fontSize: '1.25rem',
        height: '2.375rem',
        '&.MuiPickersYear-yearSelected': {
          fontWeight: 600,
          fontSize: '1.6rem',
        },
      },
    },
    '& .MuiDialogActions-root': {
      '& >button': {
        padding: theme.spacing(1, 1.75),
        minWidth: 'unset',
        textTransform: 'initial',
      },
    },
  },
}))

export default FieldMapper
