import {
  Divider,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { ReactComponent as LinkIcon } from 'assets/HorizontalArrow.svg'
import { CellLink, ProfileAvatarIcon, Section } from 'js/components'
import { switcheo } from 'js/theme/palettes/colors'
import React, { Fragment, ReactElement } from 'react'

interface KeyBase {
  url: string
  image: string
}

interface Props {
  className?: string
  validator: any
  imgLoading: boolean
  keybaseProfile: KeyBase
  setImgLoading: any
}

const ProfileAvatar: React.FunctionComponent<Props> = ({
  imgLoading,
  keybaseProfile,
  setImgLoading,
  validator,
}: Props): ReactElement<Props> => {
  const classes = useStyles()

  return (
    <Section className={classes.box}>
      <CellLink href={keybaseProfile?.url}>
        <ProfileAvatarIcon
          avatarClass={classes.avatar}
          initialClass={classes.initial}
          srcUrl={keybaseProfile?.image}
          loading={imgLoading}
          setLoading={setImgLoading}
          name={validator?.moniker}
          spinnerSize="2rem"
        />
      </CellLink>
      <Typography className={classes.status}>{validator?.bond_status}</Typography>
      <Typography variant="h2" className={classes.title}>{validator?.moniker}</Typography>
      <Divider className={classes.divider} />
      {validator?.website && (
        <Fragment>
          <CellLink href={validator?.website} className={classes.linkBox}>
            <Typography className={classes.website}>{validator?.website}</Typography>
            <LinkIcon className={classes.icon} />
          </CellLink>
          <Divider className={classes.divider} />
        </Fragment>
      )}
      {!!validator?.active_since && (
        <Typography className={classes.activeDate}>
          Registered since: {validator?.active_since}
        </Typography>
      )}
      {validator.details && (
        <Typography className={classes.description}>
          {validator?.details}
        </Typography>
      )}
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  activeDate: {
    margin: theme.spacing(1.5, 0),
  },
  avatar: {
    width: '4.375rem',
    height: '4.375rem',
  },
  box: {
    backgroundColor: theme.palette.primary.light,
    height: "100%",
    position: 'relative',
    textAlign: 'left',
  },
  description: {
    margin: theme.spacing(2, 0, 1),
    fontSize: "0.8rem",
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  icon: {
    margin: theme.spacing(0.75, 0),
    maxWidth: theme.spacing(2),
    height: theme.spacing(2),
    transition: "transform .05s ease-in-out",
    transform: "rotate(0deg)",
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(0)",
    },
  },
  initial: {
    fontSize: '2rem',
  },
  linkBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    "&:hover, &:focus": {
      backgroundColor: switcheo.hover,
      '& $icon': {
        transform: "rotate(-45deg)",
      },
    },
  },
  placeholder: {
    fontSize: '2rem',
  },
  spinner: {
    '&.MuiCircularProgress-colorPrimary': {
      color: theme.palette.primary.contrastText,
    },
  },
  status: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  title: {
    margin: theme.spacing(2, 0, 1.5),
  },
  website: {
    fontSize: "1rem",
    margin: theme.spacing(0.75, 0),
    color: theme.palette.primary.main,
  },
}))

export default ProfileAvatar
