import { IndexedTx } from '@cosmjs/stargate'
import { ListItem, makeStyles, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { CarbonTx } from 'carbon-js-sdk'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { CellLink, Section } from 'js/components'
import { fromNow } from 'js/helpers/dayjs'
import { useAsyncTask, useRedux } from 'js/hooks'
import React, { ReactElement, useEffect, useState } from 'react'

interface Props {
  transaction: IndexedTx,
  decodedTx?: CarbonTx.Tx
}

const TransactionDetail: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const classes = useStyles()
  const { transaction, decodedTx } = props
  const sdk = useRedux((state) => state.core.carbonSDK)
  const addressMsg = (decodedTx?.body?.messages?.[0] as any)?.value
  const address = addressMsg?.creator ?? addressMsg?.fromAddress ?? addressMsg?.sender ?? '-'
  const feeDeductionEvent = transaction.events.find((event) => event.type === 'Switcheo.carbon.fee.FeeDeductionEvent')
  const feeDenom = feeDeductionEvent?.attributes.find((attr) => attr.key === 'denom')?.value
  const feeAmount = feeDeductionEvent?.attributes.find((attr) => attr.key === 'fee_amount')?.value
  const gasCost = feeDeductionEvent?.attributes.find((attr) => attr.key === 'gas_cost')?.value

  let gasDeducted = 'unable to retrieve'
  let feeSymbol = ''
  if (sdk && feeDenom && feeAmount && gasCost) {
    const feeAmountBN = new BigNumber(JSON.parse(feeAmount))
    const gasCostBN = new BigNumber(JSON.parse(gasCost))

    if (feeAmountBN.isFinite() && gasCostBN.isFinite()) {
      gasDeducted = sdk.token.toHuman(JSON.parse(feeDenom), feeAmountBN.plus(gasCostBN)).toString()
      feeSymbol = sdk.token.getSymbol(JSON.parse(feeDenom))
    }
  }

  const [blockTime, setBlockTime] = useState<dayjs.Dayjs | null>(null)
  const [runQueryBlock] = useAsyncTask('queryBlock')

  useEffect(() => {
    if (!sdk) return
    runQueryBlock(async () => {
      try {
        const chainQueryClient = sdk.query.chain
        const block = await chainQueryClient.getBlock(transaction.height)
        setBlockTime(dayjs(block.header.time))
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line
  }, [sdk])

  return (
    <Section>
      <ListItem disableGutters>
        <div className={classes.header}>Transaction Hash</div>
        <div className={clsx(classes.string, classes.value)}>
          {transaction?.hash}
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>From</div>
        {/* TODO: Get username */}
        <CellLink to={`/account/${address}`} className={classes.value}>
          {address}
        </CellLink>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Status</div>
        <div className={classes.value}>
          {transaction?.code === 0 ? 'Success' : 'Fail'}
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Height</div>
        <CellLink to={`/block/${transaction?.height}`} className={classes.value}>
          {transaction?.height}
        </CellLink>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Block Time</div>
        <div className={classes.value}>
          {blockTime ? blockTime.format('YYYY-MM-DD HH:mm:ss') : "-"}
          <span className={classes.timeAgo}>{blockTime ? (fromNow(blockTime)) : ""}</span>
        </div>
      </ListItem>
      {/* <ListItem disableGutters>
          <div className={classes.header}>Gas (used / limit)</div>
          <div className={classes.value}>
            <div style={{ display: 'inline' }}>
              {gasUsed} / {gasLimit}
            </div>
            <div
              style={{
                color: grey[500],
                display: 'inline',
                marginLeft: 6,
                fontStyle: 'italic',
              }}
            >
              ({gasUsedBN.toFormat(8)} SWTH / {gasLimitBN.toFormat(8)} SWTH)
            </div>
          </div>
        </ListItem> */}
      <ListItem disableGutters>
        <div className={classes.header}>Gas Deducted</div>
        <div className={classes.value}>
          <div className={classes.mainContent}>
            {gasDeducted}
          </div>
          <div className={classes.sideContent}>
            {feeSymbol?.toUpperCase()}
          </div>
        </div>
      </ListItem>
      <ListItem disableGutters>
        <div className={classes.header}>Memo</div>
        <div className={classes.value}>{decodedTx?.body?.memo || '-'}</div>
      </ListItem>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  string: {
    wordBreak: 'break-all',
  },
  header: {
    flexBasis: '25%',
    paddingRight: '1rem',
  },
  value: {
    width: '47.5rem',
    overflowX: 'auto',
  },
  mainContent: {
    display: 'inline',
  },
  sideContent: {
    color: theme.palette.grey[500],
    display: 'inline',
    marginLeft: theme.spacing(0.75),
  },
  timeAgo: {
    marginLeft: theme.spacing(1.25),
    color: theme.palette.grey[500],
  },
}))

export default TransactionDetail
