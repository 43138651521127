import { makeStyles, TableBody, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { ListTable, Section } from 'js/components'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'
import { ProfileDetailRow } from '../components'

interface KeyBase {
  url: string
  image: string
}

interface Props {
  validator: any
  keybaseProfile: KeyBase
}

const ProfileDetails: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { keybaseProfile, validator } = props
  const classes = useStyles()

  const rows = [
    {
      href: keybaseProfile.url !== '' ? keybaseProfile.url : '',
      keyClass: classes.border,
      title: 'Identity',
      value: cellFormat(validator?.identity),
      valueClass: classes.border,
    },
    {
      keyClass: classes.border,
      title: 'Security contact',
      value: cellFormat(validator?.securityContact),
      valueClass: classes.border,
    },
    {
      keyClass: classes.border,
      to: `/account/${validator?.walletAddress}`,
      title: 'Wallet address',
      value: cellFormat(validator?.walletAddress),
      valueClass: classes.border,
    },
    {
      keyClass: classes.border,
      title: 'Operator address',
      value: cellFormat(validator?.operatorAddress),
      valueClass: classes.border,
    },
    {
      keyClass: classes.border,
      title: 'Consensus pubkey',
      value: cellFormat(validator?.consensusPubkey),
      valueClass: classes.border,
    },
    {
      keyClass: classes.border,
      title: 'Consensus address',
      value: cellFormat(validator?.consensusAddress),
      valueClass: classes.border,
    },
    {
      keyClass: clsx(classes.border, classes.endRow),
      title: 'Consensus address hex',
      value: cellFormat(validator?.consensusAddressHex),
      valueClass: clsx(classes.border, classes.endRow),
    }
  ]

  return (
    <Section variant="primary" className={classes.box}>
      <ListTable>
        <TableBody>
          {
            rows.map((row, id) =>
              <ProfileDetailRow key={id} {...row} />,
            )
          }
        </TableBody>
      </ListTable>
    </Section>
  )
}

const cellFormat = (value: any) => {
  if (value) {
    return value
  }
  return "-"
}

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderBottom: `1px solid ${theme.palette.secondary.main} !important`,
  },
  box: {
    height: "100%",
  },
  endRow: {
    "&.MuiTableCell-root": {
      borderBottom: '1px solid transparent',
    },
  },
  row: {
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: switcheo.hover,
      },
    },
    '&:hover': {
      backgroundColor: switcheo.hover,
    },
  },
}))

export default ProfileDetails
