import { Order } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/order'
import { TradesReponse } from 'js/reducers/account'
import { Action } from 'redux'

export enum MarketsActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_ORDERS = 'SET_ORDERS',
  SET_TRADES = 'SET_TRADES',
  SET_MARKETS_VOLUME_CHART = 'SET_MARKETS_VOLUME_CHART',
  UPDATE_DATE_FILTERS_MARKETS_VOLUME_CHART = 'UPDATE_DATE_FILTERS_MARKETS_VOLUME_CHART',
  UPDATE_ORDERS_FILTERS = 'UPDATE_ORDERS_FILTERS',
  UPDATE_TRADES_FILTERS = 'UPDATE_TRADES_FILTERS',
}

export type MarketsAction =
  | ClearAction
  | SetOrdersAction
  | SetTradesAction
  | SetMarketsVolumeChartAction
  | UpdateOrdersFiltersAction
  | UpdateTradesFiltersAction
  | UpdateDateFiltersMarketsVolumeChartAction


export interface ClearAction extends Action<MarketsActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: MarketsActionType.CLEAR,
  }
}

export interface SetOrdersAction extends Action<MarketsActionType.SET_ORDERS> {
  orders: Order[]
}

export function setOrders(orders: Order[]): SetOrdersAction {
  return {
    type: MarketsActionType.SET_ORDERS,
    orders,
  }
}
export interface SetTradesAction extends Action<MarketsActionType.SET_TRADES> {
  trades: TradesReponse
}

export function setTrades(trades: TradesReponse): SetTradesAction {
  return {
    type: MarketsActionType.SET_TRADES,
    trades,
  }
}

export interface SetMarketsVolumeChartAction
  extends Action<MarketsActionType.SET_MARKETS_VOLUME_CHART> {
  marketsVolumeChartData: any
}
export function setMarketsVolumeChartData(marketsVolumeChartData: any): SetMarketsVolumeChartAction {
  return {
    type: MarketsActionType.SET_MARKETS_VOLUME_CHART,
    marketsVolumeChartData,
  }
}

export interface UpdateDateFiltersMarketsVolumeChartAction
  extends Action<MarketsActionType.UPDATE_DATE_FILTERS_MARKETS_VOLUME_CHART> {
  options: any
}

export function updateDateFiltersMarketsVolumeChart(options: any): UpdateDateFiltersMarketsVolumeChartAction {
  return {
    type: MarketsActionType.UPDATE_DATE_FILTERS_MARKETS_VOLUME_CHART,
    options,
  }
}

export interface UpdateOrdersFiltersAction
  extends Action<MarketsActionType.UPDATE_ORDERS_FILTERS> {
  options: any
}

export function updateOrdersFilters(options: any): UpdateOrdersFiltersAction {
  return {
    type: MarketsActionType.UPDATE_ORDERS_FILTERS,
    options,
  }
}

export interface UpdateTradesFiltersAction
  extends Action<MarketsActionType.UPDATE_TRADES_FILTERS> {
  options: any
}

export function updateTradesFilters(options: any): UpdateTradesFiltersAction {
  return {
    type: MarketsActionType.UPDATE_TRADES_FILTERS,
    options,
  }
}
