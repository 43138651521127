import { Record, RecordOf } from 'immutable'
import { RestModels } from 'tradehub-api-js'
import { LeaderboardHistoryAction, LeaderboardHistoryActionType } from '../actions/leaderboardHistory'

export interface LeaderboardHistoryProps {
  leaderboard?: RestModels.LeaderboardResult
  fromDate: string
  toDate: string
}

const makeState: Record.Factory<LeaderboardHistoryProps> = Record<LeaderboardHistoryProps>({
  leaderboard: undefined,
  fromDate: "",
  toDate: "",
})

export type LeaderboardHistoryState = RecordOf<LeaderboardHistoryProps>

function leaderboardHistoryReducer(
  state: LeaderboardHistoryState = makeState(),
  action: LeaderboardHistoryAction,
): LeaderboardHistoryState {
  switch (action.type) {
    case LeaderboardHistoryActionType.CLEAR:
      return makeState()
    case LeaderboardHistoryActionType.SET_LEADERBOARD_HISTORY:
      return state.updateIn(['leaderboard'], () => action.leaderboard)
    default:
      return state
  }
}

export default leaderboardHistoryReducer

