import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import PaginationButtons from './PaginationButtons'
// tslint:disable:jsx-no-lambda

interface Props {
  response: any
  action: any
  filters?: any
  page: number
  setPage: any
  removeLast?: boolean
  paginationLimit: number
  disablePaginationForwardButton?: boolean
  pageReset?: boolean
  setPageReset?: any
  isTransaction?: boolean
}

const PaginationByPage: React.FunctionComponent<Props> = ({
  response,
  action,
  filters,
  page,
  setPage,
  removeLast,
  // paginationLimit,
  disablePaginationForwardButton,
  setPageReset,
  isTransaction,
}: Props): ReactElement<Props> => {
  const dispatch = useDispatch()
  const data: any = response.data ?? response.models
  const totalPages: number = response?.totalPages ?? response?.meta?.totalPages
  const handleButtonClick = (event: any) => {
    if (data?.length > 0) {
      let newPage: any = page
      newPage = disablePaginationForwardButton ? newPage - 1 : page
      switch (event) {
        case 'next':
          newPage += 1
          break
        case 'last':
          newPage = totalPages
          break
        case 'previous':
          newPage -= 1
          break
        case 'first':
          newPage = 1
          break
        default:
          break
      }
      setPage(newPage)
      if (setPageReset) {
        setPageReset(false)
      }
      dispatch(
        action({
          page: newPage,
          filters,
          blockHeight: response.blockHeight ?? 0,
          totalCount: response.totalCount ?? 0,
          totalPages: response.totalPages ?? 0,
        }),
      )
    }
  }
  const disableBack: boolean = page <= 1 ? true : false
  const disableForward: boolean = isTransaction ? false : page >= totalPages ? true : false
  return (
    <PaginationButtons
      handleButtonClick={handleButtonClick}
      disableBack={disableBack}
      disableForward={disablePaginationForwardButton || disableForward}
      removeLast={removeLast}
    />
  )
}

export default PaginationByPage
