import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiTableCell']>> => ({
  root: {
    fontSize: '0.875rem',
    padding: '0.5rem 1.875rem 0.5rem 0.75rem',
  },
  head: {
    fontSize: 13,
  },
})
