import { ParameterChangeProposalFormState } from 'js/models'
import { getErrorMessages } from '../../Helpers/InputConstraints'
import { getParamType } from './Modules'

export const requiredFields = ['topic', 'parameter', 'value']

const typeConstraints = {
  positiveUnsignedInteger: {
    numericality: {
      greaterThan: 0,
      onlyInteger: true,
    },
  },
  unsignedInteger: {
    numericality: {
      greaterThanOrEqualTo: 0,
      onlyInteger: true,
    },
  },
  positiveNumber: {
    numericality: {
      greaterThan: 0,
    },
  },
  percent: {
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100,
    },
  },
}

export function parameterChangeValidator(formState: ParameterChangeProposalFormState) {
  let errors: string[] = []
  formState.parameters.forEach((paramDetail) => {
    const parameterType = getParamType(paramDetail.parameter)!
    switch (parameterType.type) {
      case 'uint64':
      case 'int64':
      case 'uint16':
        const constraints = parameterType.parameter === 'HistoricalEntries'
          ? typeConstraints.unsignedInteger
          : typeConstraints.positiveUnsignedInteger
        errors = getErrorMessages(formState, { value: constraints })
        break
      case 'coin':
      case 'time':
        errors = getErrorMessages(formState, { value: typeConstraints.positiveNumber })
        break
      case 'percent':
        errors = getErrorMessages(formState, { value: typeConstraints.percent })
        break
      default:
        break
    }
  })

  return errors
}
