import { fieldTypes } from "../../GenericProposal/Helpers/types";
import { getErrorMessages, getInputConstraints } from "../../Helpers/InputConstraints";

export const updatePoolFormRequiredFields = ['poolID', 'swapFee', 'numQuotes'];

export const updatePoolConfig = {
    swapFee: {
      id: 'swapFee',
      type: fieldTypes.number,
      constraints: {
        lessThan: 1,
      },
    },
    numQuotes: {
      id: 'numQuotes',
      type: fieldTypes.number,
      constraints: {
        greaterThan: 0,
        lessThan: 20,
        onlyInteger: true,
      },
    },
  }

export function updatePoolValidator(formState: any) {
  return getErrorMessages(formState, getInputConstraints(updatePoolConfig))
}