import { List, Record, RecordOf } from 'immutable'

export interface TxLogProps {
  rawLog: string
  parsedLog: {
    [key in number | 'error']?: []
  }
}

export const makeTxLog: Record.Factory<TxLogProps> = Record<TxLogProps>({
  rawLog: '',
  parsedLog: {},
})

export type TxLog = RecordOf<TxLogProps>

export interface Msg {
  msgType: string
  msg: string
}

// export interface FullTxProps {
//   id: number
//   hash: string
//   address: string
//   username: string
//   code: string
//   gasUsed: number
//   gasLimit: number
//   memo: string
//   height: number
//   blockTime: string
//   msgs: List<Msg>
// }

// export class FullTransaction extends Record<FullTxProps>({
//   id: 0,
//   hash: '',
//   address: '',
//   username: '',
//   code: '-1',
//   gasUsed: 0,
//   gasLimit: 0,
//   memo: '',
//   height: 0,
//   blockTime: '',
//   msgs: List<Msg>([]),
// }) { }

export interface VoteTxProps {
  gasUsed: string // string representation of number
  gasWanted: string // string representation of number
  height: string // string representation of number 
  logs: {
    [key: number]: any
  }
  rawLog: string
  timestamp: string // string representation of timestamp
  tx: {
    type: string
    value: any
  }
  txhash: string
}

export const makeVoteTx: Record.Factory<VoteTxProps> = Record<VoteTxProps>({
  gasUsed: '0',
  gasWanted: '0',
  height: '0',
  logs: {},
  rawLog: '',
  timestamp: '1970-01-01T00:00:00Z',
  tx: {
    type: '',
    value: {},
  },
  txhash: '',
})

export type VoteTx = RecordOf<VoteTxProps>

export interface VoteTxsArrProps {
  data: List<VoteTx>
}

export type VoteTxsArr = RecordOf<
  VoteTxsArrProps
>

export const makeVoteTxsArr: Record.Factory<
  VoteTxsArrProps
> = Record<VoteTxsArrProps>({
  data: List<VoteTx>(),
})
