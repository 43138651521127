import { Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

interface ColumnLoadingProps {
  width?: number | string;
  height?: number | string;
}
export const ColumnLoading = (props: ColumnLoadingProps) => {
  const { width, height = 13 } = props;
  const randomWidth = Math.floor(Math.random() * (100 - 80 + 1)) + 80;
  return (
    <div style={{ width }}>
      <Skeleton variant="rect" height={height} width={width ?? `${randomWidth}%`} />
      <Divider style={{ margin: '5px 0px' }} />
      <Skeleton variant="rect" height={height} width={width ?? `${randomWidth}%`} />
    </div>
  );
};
