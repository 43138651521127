import { AdminModule } from 'carbon-js-sdk'
import { CreateOracleProposalFormState } from 'js/models'
import store from 'js/store'
import { BIG_ZERO, escapeHtmlGo, parseNumber } from 'js/utils'
import { fieldTypes } from "../Helpers/types"
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { CarbonTx } from "carbon-js-sdk"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { MsgCreateOracle } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/oracle/tx'

export const createOracleFormRequiredFields = [
  'oracleId',
  'oracleDescription',
  'minTurnoutPercentage',
  'maxResultAge',
  'securityType',
  'resultStrategy',
  'resolution',
  'spec',
]

export function createOracleConfig() {
  return {
    oracleId: {
      id: 'oracleId',
      label: 'Oracle ID',
      type: fieldTypes.text,
      tooltip: 'Unique identifier for oracle',
      placeholder: 'Eg. DETH',
      constraints: {
        validPattern: true,
      },
    },
    oracleDescription: {
      id: 'oracleDescription',
      label: 'Oracle Description',
      type: fieldTypes.text,
      tooltip: 'Description of oracle',
      constraints: {
        validPattern: true,
      },
    },
    minTurnoutPercentage: {
      id: 'minTurnoutPercentage',
      label: 'Min Turnout Percentage',
      type: fieldTypes.number,
      tooltip: 'Minimum percentage for a oracle vote to be accepted as an oracle result',
      constraints: {
        greaterThan: 0,
        lessThanOrEqualTo: 100,
        onlyInteger: true,
      },
    },
    maxResultAge: {
      id: 'maxResultAge',
      label: 'Max Result Age',
      type: fieldTypes.number,
      tooltip: 'Maximum age an oracle vote will last',
      constraints: {
        greaterThan: 0,
        onlyInteger: true,
      },
    },
    securityType: {
      id: 'securityType',
      label: 'Security Type',
      type: fieldTypes.select,
      options: ["SecuredByValidators"],
      tooltip: 'Set to "SecuredByValidators"',
    },
    resultStrategy: {
      id: 'resultStrategy',
      label: 'Result Strategy',
      type: fieldTypes.select,
      options: ["exact", "median"],
      tooltip: 'Strategy to pick data for oracle result, "exact" or "median"',
    },
    resolution: {
      id: 'resolution',
      label: 'Resolution',
      type: fieldTypes.number,
      tooltip: 'Rate at which oracle votes should be submitted',
      constraints: {
        greaterThan: 0,
        onlyInteger: true,
      },
    },
    spec: {
      id: 'spec',
      label: 'Spec',
      type: fieldTypes.text,
      tooltip: 'Specification for oracle service to generate oracle votes',
      constraints: {
        validJSON: true,
      },
    },
  }
}

export function createOracleProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as CreateOracleProposalFormState

  const { title, summary, metadata, oracleId, oracleDescription, minTurnoutPercentage, maxResultAge,
    securityType, resultStrategy, resolution, spec } = formState
  const createOracleParams: AdminModule.CreateOracleParams = {
    id: oracleId,
    description: oracleDescription,
    minTurnoutPercentage: parseNumber(minTurnoutPercentage, BIG_ZERO)!.toNumber(),
    maxResultAge: parseNumber(maxResultAge, BIG_ZERO)!.toNumber(),
    securityType: securityType,
    resultStrategy: resultStrategy,
    resolution: parseNumber(resolution, BIG_ZERO)!.toNumber(),
    spec,
  }

  const msg = MsgCreateOracle.fromPartial({
    creator: authority,
    createOracleParams
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    initialDeposit,
    expedited: false,
    proposer: proposer ?? "",
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgCreateOracle,
        value: MsgCreateOracle.encode(msg).finish()
      }
    ],
  }

  return value
}
