import { Box, makeStyles, TableCell, TableRow, TableRowProps, Theme, Typography } from '@material-ui/core'
import { GovUtils } from 'carbon-js-sdk'
import { Proposal, ProposalStatus } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/gov'
import { ClientUpdateProposal } from 'carbon-js-sdk/lib/codec/ibc/core/client/v1/client'
import clsx from 'clsx'
import { Paths } from 'js/constants'
import { unescapeHtmlGo } from 'js/utils'
import React, { ReactElement } from 'react'
import CellLink from '../CellLink'
import { ProposalTopicLabel } from '../ParsedLabel'
import { PeriodTag, ProposalStatusProp, VoteStatisticGroup } from './components'

type Props = TableRowProps & {
  proposal: Proposal
}

const ProposalRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, children, proposal, ...rest } = props
  const classes = useStyles(props)
  let decodedContent
  if (proposal?.messages.length > 0) {
    decodedContent = proposal?.messages[0].typeUrl === "/ibc.core.client.v1.ClientUpdateProposal" ? { ...proposal?.messages[0], value: ClientUpdateProposal.decode(proposal?.messages[0].value) } : GovUtils.decodeContent(proposal?.messages[0]);
  }

  const title = proposal.title.length !== 0 ? proposal.title : decodedContent?.value?.title
  const topic = decodedContent?.value?.topic

  const isDepositPeriod =
    proposal.status === ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD

  return (
    <TableRow {...rest} className={clsx(classes.root, className)}>
      <TableCell className={classes.titleCell}>
        <Box marginBottom={1}>
          <CellLink
            color="inherit"
            variant="h3"
            to={Paths.Proposal.replace(':proposalId', proposal.id.toString())}
          >
            {!!topic && (
              <React.Fragment>
                [<ProposalTopicLabel>{topic}</ProposalTopicLabel>]
                {' '}
              </React.Fragment>
            )}
            {!title ? "<No Title>" : unescapeHtmlGo(title)}
          </CellLink>
        </Box>
        <Typography variant="body2">
          <ProposalStatusProp proposal={proposal} />
        </Typography>
      </TableCell>
      <TableCell>
        {!isDepositPeriod && (
          <VoteStatisticGroup proposal={proposal} />
        )}
      </TableCell>
      <TableCell>
        <PeriodTag proposal={proposal} width={1} />
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  titleCell: {
    width: '100%',
    minWidth: "250px",
  },
}))

export default ProposalRow
