import { Record, RecordOf } from 'immutable'
import { MarketsAction, MarketsActionType } from '../actions/markets'
import { TradesReponse } from './account'
import { Order } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/order'

export interface MarketsProps {
  orders: Order[]
  trades: TradesReponse
  marketsVolumeChartData: any
}

const makeState: Record.Factory<MarketsProps> = Record<MarketsProps>({
  orders: [],
  trades: {
    data: [],
    meta: {
      totalPages: 0,
    }
  },
  marketsVolumeChartData: [],
})
export type MarketsState = RecordOf<MarketsProps>

function marketsReducer(
  state: MarketsState = makeState(),
  action: MarketsAction,
): MarketsState {
  switch (action.type) {
    case MarketsActionType.CLEAR:
      return makeState()
    case MarketsActionType.SET_ORDERS:
      return state.updateIn(['orders'], () => action.orders)
    case MarketsActionType.SET_TRADES:
      return state.updateIn(['trades'], () => action.trades)
    case MarketsActionType.SET_MARKETS_VOLUME_CHART:
      return state.updateIn(['marketsVolumeChartData'], () => action.marketsVolumeChartData)
    default:
      return state
  }
}

export default marketsReducer
