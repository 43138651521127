type NameLabelList = {
  [index: string]: string
}

export const TxTypes = {
  Types: {
    send: 'Send',
    set_leverage: 'Set Leverage',
    create_order: 'Create Order',
    delegate: 'Delegate',
    cancel_order: 'Cancel Order',
    update_profile: 'Update Profile',
    create_validator: 'Create Validator',
    initiate_liquidation: 'Initiate Liquidation',
    create_oracle_vote: 'Create Oracle Vote',
    mint_token: 'Mint Token',
    edit_validator: 'Edit Validator',
    vote: 'Vote',
    withdraw_validator_commission: 'Withdraw Validator Commission',
    activate_sub_account: 'Activate Sub-account',
    unjail: 'Unjail',
    withdraw_delegator_reward: 'Withdraw Delegator Reward',
    create_sub_account: 'Create Sub-account',
    deposit: 'Deposit',
    submit_proposal: 'Submit Proposal',
  } as NameLabelList,
} as const

export const OrderTypes = {
  Status: {
    cancelled: 'Cancelled',
    filled: 'Filled',
  } as NameLabelList,
} as const

export const ProposalTypes = {
  Topics: {
    authentication: 'Authentication',
    auth: 'Authentication',
    bank: 'Bank',
    distribution: 'Distribution',
    governance: 'Governance',
    gov: 'Governance',
    mint: 'Mint',
    slashing: 'Slashing',
    staking: 'Staking',
    oracle: 'Oracle',
  } as NameLabelList,
  Parameters: {
    maxMemoCharacters: 'Max Memo Characters',
    txSigLimit: 'Tx Sig Limit',
    txSizeCostPerByte: 'Tx Size Cost Per Byte',
    sigVerifyCostED25519: 'Sig Verify Cost ED25519',
    sigVerifyCostSecp256k1: 'Sig Verify Cost secp256k1',
    defaultSendEnabled: 'Default Send Enabled',
    'depositparams-min_deposit': 'Min Deposit',
    'depositparams-max_deposit_period': 'Max Deposit Period',
    'votingparams-voting_period': 'Voting Period',
    quorum: 'Quorum',
    threshold: 'Threshold',
    vetoThreshold: 'Veto Threshold',
    communityTax: 'Community Tax',
    withdrawAddrEnabled: 'Withdraw Address Enabled',
    signedBlocksWindow: 'Signed Blocks Window',
    minSignedPerWindow: 'Min Signed Per Window',
    downtimeJailDuration: 'Downtime Jail Duration',
    slashFractionDoubleSign: 'Slash Fraction Double Sign',
    slashFractionDowntime: 'Slash Fraction Downtime',
    unbondingTime: 'Unbonding Time',
    maxValidators: 'Max Validators',
    keyMaxEntries: 'Key Max Entries',
    bondDenom: 'Bond Denom',
    historicalEntries: 'Historical Entries',
    oracleSlashEnabled: 'Oracle Slash Enabled',
    oracleSlashWindowBlock: 'Oracle Slash Window Block',
    oracleSlashInitial: 'Oracle Slash Initial',
    oracleSlashIncrement: 'Oracle Slash Increment',
    oracleContinuousSlashMax: 'Oracle Continuous Slash Max',
    newlyBondedWindowAllowance: 'Newly Bonded Window Allowance',
    voteTimestampFutureAllowance: 'Vote Timestamp Future Allowance',
    oracleMinVoteFactor: 'Oracle Min Vote Factor',
    maxPowerToSlashFactor: 'Max Power To Slash Factor',
  } as NameLabelList,
} as const

export const BlockChainMap: { [key: string]: string } = {
  eth: 'Ethereum',
  neo: 'Neo',
  swth: 'Switcheo',
  zil: 'Zilliqa',
  bsc: 'Binance Smart Chain',
}

export const TaskNames = {
  App: {
    InitializeSDK: 'initialize-sdk',
    GeckoCoin: 'gecko-coin-app',
    InsuranceSupply: 'insurance-supply-app',
    Markets: 'markets-app',
    NeoPrice: 'neo-price-app',
    Oracles: 'oracles-app',
    SigningInfo: 'signing-info-app',
    StakingPool: 'staking-pool-app',
    SwthSupply: 'swth-supply-app',
    TotalSupply: 'total-supply-app',
    Validators: 'validators-app',
    LatestBlock: 'latest-block-app',
    Nodes: 'nodes-app',
    AllOracleTokenPrices: 'all-oracle-token-prices-app',
  },
  Account: {
    Delegations: 'delegations-account',
    CrossChainTransfers: 'crosschain-transfers-account',
    CrosschainTransferFilter: 'crosschain-transfer-filter-account',
    Holdings: 'holdings-account',
    OpenOrdersFilter: 'open-order-filter-account',
    OpenOrders: 'open-orders-account',
    OrderFilter: 'order-filter-account',
    OrderList: 'order-list-account',
    Positions: 'positions-account',
    PositionsFilter: 'positions-filter-account',
    PositionsHistory: 'positions-history-account',
    PositionsHistoryFilter: 'positions-history-filter-account',
    Profile: 'profile-account',
    Rewards: 'rewards-account',
    TradesFilter: 'trades-filter-account',
    TradesByAccount: 'trades-by-account',
    TransactionFilter: 'transaction-filter-account',
    Transactions: 'transactions-account',
    TransactionType: 'transaction-type-account',
    InternalTransfer: 'internal-transfer-account',
    UpdateInternalTransfer: 'update-internal-transfer'
  },
  Alliance: {
    AllAlliances: 'all-alliances',
    AllianceAPY: 'alliance-apy',
    AlliancesStakeChart: 'alliances-stake-chart',
    AlliancesRewardsChart: 'alliances-rewards-chart',
  },
  Blocks: {
    List: 'blocks-component',
    Filter: 'blocks-filter-component',
  },
  Block: {
    Transaction: 'transaction-block',
    Info: 'info-block',
    Events: 'events-block',
  },
  Dashboard: {
    Blocks: 'blocks-dashboard',
    BlockTime: 'blocktime-dashboard',
    MarketStats: 'market-stats-dashboard',
    Transactions: 'transactions-dashboard',
    Wallets: 'wallets-dashboard',
    Distribution: 'distribution-dashboard',
    Apr: 'apr-dashboard',
    ActiveUsersChart: 'active-users-chart-dashboard',
    TotalUsersChart: 'total-users-chart-dashboard',
    DistributionRewardsChart: 'distribution-rewards-chart-dashboard',
  },
  Events: {
    LEOrderFilter: 'le-order-filter-events',
    LEOrders: 'le-orders-events',
    LETrades: 'le-trades-events',
    SettledMarkets: 'settled-markets-events',
  },
  Governance: {
    DepositParams: 'deposit-params-governance',
    Delegations: 'delegations-governance',
    DepositHistory: 'deposit-history-governance',
    Proposals: 'proposals-governance',
    TallyParams: 'tally-params-governance',
    VoteHistory: 'vote-history-governance',
  },
  Proposal: {
    Details: 'details-proposal',
    Votes: 'votes-proposal',
  },
  Market: {
    Detail: 'detail-market',
    OrderFilter: 'order-filter-market',
    OrderList: 'order-list-market',
    ProfitableFilter: 'profitable-filter-market',
    ProfitablePositions: 'profitable-positions-market',
    LargeFilter: 'large-filter-market',
    LargePositions: 'large-positions-market',
    LongRisky: 'long-risky-market',
    LongRiskyFilter: 'long-risky-filter-market',
    ShortRisky: 'short-risky-market',
    ShortRiskyFilter: 'short-risky-filter-market',
    Stats: 'stats-market',
    TopProfits: 'top-profits-market',
    TradeFilter: 'trade-filter-market',
    TradeList: 'trade-list-market',
  },
  Leaderboard: {
    D30: '30days-leaderboard',
    Lifetime: 'lifetime-leaderboard',
    D30Filters: 'days-30-filters',
    LifetimeFilters: 'lifetime-filters',
  },
  LeaderboardHistory: {
    leaderboard: 'leaderboard',
  },
  Markets: {
    OrderFilter: 'order-filter-markets',
    Orders: 'orders-markets',
    TradeFilter: 'trade-filter-markets',
    Trades: 'trades-markets',
    MarketStats: 'stats-markets',
    VolumeChart: 'volume-chart-markets',
    DateFilter: 'date-filter-markets',
  },
  Nodes: {
    List: 'list-nodes',
  },
  Order: {
    Detail: 'detail-order',
    TradesByAccount: 'trades-by-account',
  } as NameLabelList,
  Position: {
    Detail: 'detail-position',
  } as NameLabelList,
  Pools: {
    List: 'list-pools',
    WeeklyRewards: 'weekly-rewards-pools',
    RewardCurve: 'reward-curve-pools',
    CommitmentCurve: 'commitment-curve-pools',
    PoolsVolume: 'volume-pools',
    TotalLiquidityChart: 'total-liquidity-chart-pools',
    VolumeLiquidityChart: 'volume-liquidity-chart-pools',
    UtilisationChart: 'utilisation-chart-pools',
    DateFilterTotalLiquidity: 'date-filter-total-liquidity-pools',
    DateFilterVolumeLiquidity: 'date-filter-volume-liquidity-pools',
    DateFilterUtilisation: 'date-filter-utilisation-pools',
  },
  Token: {
    Detail: 'detail-token',
    RichList: 'rich-list-token',
    PricesChart: 'prices-chart-token',
  },
  Tokens: {
    CirculateSupply: 'circulate-supply-tokens',
    Info: 'info-tokens',
  },
  Transaction: {
    Detail: 'detail-transaction',
    Log: 'log-transaction',
  },
  Transactions: {
    Filter: 'filter-transactions',
    List: 'list-transactions',
    Types: 'types-transactions',
    TotalChart: 'total-chart-transactions',
    MsgChart: 'msg-chart-transactions',
    DateFilterTotal: 'date-filter-total-transactions',
    DateFilterMsg: 'date-filter-msg-transactions',
  },
  Validator: {
    Blocks: 'blocks-validator',
    BlocksFilter: 'blocks-filter-validator',
    Detail: 'detail-validator',
    Delegations: 'delegations-validator',
  },
  Validators: {
    BlockTime: 'block-time-validators',
    Distribution: 'distribution-validators',
    List: 'list-validators',
    Slashing: 'slashing-validators',
    TotalStakedChart: 'total-staked-chart-validators',
    DateFilter: 'date-filter-validators',
  },
  Cdp: {
    assets: 'all-assets'
  }
} as const

export const FuturesDenomOverride = {
  WBTC: 'BTC',
  USDC: 'USD',
}

export const FuturesNameOverride: {
  [key: string]: string
} = {
  'Wrapped BTC': 'Bitcoin',
}
