import { createStyles, InputBase, Theme, withStyles } from '@material-ui/core'

export default withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
        color: '#073C50',
      },
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #073C50',
      fontSize: 16,
      padding: '0 12px',
      color: '#073C50',
    },
  }),
)(InputBase)
