import BigNumber from 'bignumber.js'
import { CommitmentCurve, ExtendedPool, RewardCurve } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/export'
import { SimpleMap } from 'carbon-js-sdk/lib/util/type'
import { Action } from 'redux'

export enum LiquidityPoolsActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_LIQUIDITY_POOLS = 'SET_LIQUIDITY_POOLS',
  SET_WEEKLY_POOL_REWARDS = 'SET_WEEKLY_POOL_REWARDS',
  SET_COMMITMENT_CURVE = 'SET_COMMITMENT_CURVE',
  SET_REWARD_CURVE = 'SET_REWARD_CURVE',
  SET_POOLS_VOLUME = 'SET_POOLS_VOLUME',
  SET_POOLS_TOTAL_LIQUIDITY_CHART = 'SET_POOLS_TOTAL_LIQUIDITY_CHART',
  SET_POOLS_VOLUME_LIQUIDITY_CHART = 'SET_POOLS_VOLUME_LIQUIDITY_CHART',
  SET_POOLS_UTILISATION_CHART = 'SET_POOLS_UTILISATION_CHART',
  UPDATE_DATE_FILTERS_TOTAL_LIQUIDITY = 'UPDATE_DATE_FILTERS_TOTAL_LIQUIDITY',
  UPDATE_DATE_FILTERS_VOLUME_LIQUIDITY = 'UPDATE_DATE_FILTERS_VOLUME_LIQUIDITY',
  UPDATE_DATE_FILTERS_UTILISATION = 'UPDATE_DATE_FILTERS_UTILISATION',
}

export type LiquidityPoolsAction =
  | ClearAction
  | SetLiquidityPoolsAction
  | SetWeeklyPoolRewardsAction
  | SetCommitmentCurveAction
  | SetRewardCurveAction
  | SetPoolsVolumeAction
  | SetPoolsTotalLiquidityChartAction
  | SetPoolsVolumeLiquidityChartAction
  | SetPoolsUtilisationChartAction
  | UpdateDateFiltersTotalLiquidityChartAction
  | UpdateDateFiltersVolumeLiquidityChartAction
  | UpdateDateFiltersUtilisationChartAction

export interface ClearAction extends Action<LiquidityPoolsActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: LiquidityPoolsActionType.CLEAR,
  }
}

export interface SetLiquidityPoolsAction extends Action<LiquidityPoolsActionType.SET_LIQUIDITY_POOLS> {
  pools: ExtendedPool[]
}

export function setLiquidityPools(pools: ExtendedPool[]): SetLiquidityPoolsAction {
  return {
    type: LiquidityPoolsActionType.SET_LIQUIDITY_POOLS,
    pools,
  }
}

export interface SetWeeklyPoolRewardsAction extends Action<LiquidityPoolsActionType.SET_WEEKLY_POOL_REWARDS> {
  rewards: BigNumber
}
export function setWeeklyPoolRewards(rewards: BigNumber): SetWeeklyPoolRewardsAction {
  return {
    type: LiquidityPoolsActionType.SET_WEEKLY_POOL_REWARDS,
    rewards,
  }
}
export interface SetCommitmentCurveAction extends Action<LiquidityPoolsActionType.SET_COMMITMENT_CURVE> {
  commitmentCurve?: CommitmentCurve
}
export function setCommitmentCurve(commitmentCurve?: CommitmentCurve): SetCommitmentCurveAction {
  return {
    type: LiquidityPoolsActionType.SET_COMMITMENT_CURVE,
    commitmentCurve,
  }
}

export interface SetRewardCurveAction extends Action<LiquidityPoolsActionType.SET_REWARD_CURVE> {
  rewardCurve?: RewardCurve
}
export function setRewardCurve(rewardCurve?: RewardCurve): SetRewardCurveAction {
  return {
    type: LiquidityPoolsActionType.SET_REWARD_CURVE,
    rewardCurve,
  }
}
export interface SetPoolsVolumeAction extends Action<LiquidityPoolsActionType.SET_POOLS_VOLUME> {
  poolsVolumeMap: SimpleMap<BigNumber>
}
export function setPoolsVolume(poolsVolumeMap: SimpleMap<BigNumber>): SetPoolsVolumeAction {
  return {
    type: LiquidityPoolsActionType.SET_POOLS_VOLUME,
    poolsVolumeMap,
  }
}
export interface SetPoolsTotalLiquidityChartAction extends Action<LiquidityPoolsActionType.SET_POOLS_TOTAL_LIQUIDITY_CHART> {
  poolsTotalLiquidityChartData: any
}
export function setPoolsTotalLiquidityChartData(poolsTotalLiquidityChartData: any): SetPoolsTotalLiquidityChartAction {
  return {
    type: LiquidityPoolsActionType.SET_POOLS_TOTAL_LIQUIDITY_CHART,
    poolsTotalLiquidityChartData,
  }
}

export interface SetPoolsVolumeLiquidityChartAction extends Action<LiquidityPoolsActionType.SET_POOLS_VOLUME_LIQUIDITY_CHART> {
  poolsVolumeLiquidityChartData: any
}
export function setPoolsVolumeLiquidityChartData(poolsVolumeLiquidityChartData: any): SetPoolsVolumeLiquidityChartAction {
  return {
    type: LiquidityPoolsActionType.SET_POOLS_VOLUME_LIQUIDITY_CHART,
    poolsVolumeLiquidityChartData,
  }
}

export interface SetPoolsUtilisationChartAction extends Action<LiquidityPoolsActionType.SET_POOLS_UTILISATION_CHART> {
  poolsUtilisationChartData: any
}
export function setPoolsUtilisationChartData(poolsUtilisationChartData: any): SetPoolsUtilisationChartAction {
  return {
    type: LiquidityPoolsActionType.SET_POOLS_UTILISATION_CHART,
    poolsUtilisationChartData,
  }
}

export interface UpdateDateFiltersTotalLiquidityChartAction
  extends Action<LiquidityPoolsActionType.UPDATE_DATE_FILTERS_TOTAL_LIQUIDITY> {
  options: any
}

export function updateDateFiltersTotalLiquidityChartData(options: any): UpdateDateFiltersTotalLiquidityChartAction {
  return {
    type: LiquidityPoolsActionType.UPDATE_DATE_FILTERS_TOTAL_LIQUIDITY,
    options,
  }
}
export interface UpdateDateFiltersVolumeLiquidityChartAction
  extends Action<LiquidityPoolsActionType.UPDATE_DATE_FILTERS_VOLUME_LIQUIDITY> {
  options: any
}

export function updateDateFiltersVolumeLiquidityChartData(options: any): UpdateDateFiltersVolumeLiquidityChartAction {
  return {
    type: LiquidityPoolsActionType.UPDATE_DATE_FILTERS_VOLUME_LIQUIDITY,
    options,
  }
}
export interface UpdateDateFiltersUtilisationChartAction
  extends Action<LiquidityPoolsActionType.UPDATE_DATE_FILTERS_UTILISATION> {
  options: any
}

export function updateDateFiltersUtilisationChartData(options: any): UpdateDateFiltersUtilisationChartAction {
  return {
    type: LiquidityPoolsActionType.UPDATE_DATE_FILTERS_UTILISATION,
    options,
  }
}