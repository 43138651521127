import { makeStyles, TableCell, TableRow, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { CellLink, TooltipHint } from 'js/components'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'

interface Props {
  href?: string
  keyClass?: string
  title: string
  to?: string
  tooltip?: string
  value: string
  valueClass?: string
}

const ProfileDetailRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { href, keyClass, title, to, tooltip, value, valueClass } = props
  const classes = useStyles()

  return (
    <TableRow className={classes.row}>
      <TableCell align="left" className={clsx(classes.tableKey, keyClass)}>
        {title}
        {tooltip && (
          <TooltipHint title={tooltip} className={classes.tooltip} />
        )}
      </TableCell>
      <TableCell className={clsx(classes.tableValue, valueClass)}>
        {href && href !== '' && (
          <CellLink href={href}>
            {value}
          </CellLink>
        )}
        {to && to !== '' && (
          <CellLink to={to}>
            {value}
          </CellLink>
        )}
        {(!href || href === '') && (!to || to === '') ? value : null}
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: switcheo.hover,
      },
    },
    '&:hover': {
      backgroundColor: switcheo.hover,
    },
  },
  tableKey: {
    padding: theme.spacing(1.5, 0),
    minWidth: "9rem",
  },
  tableValue: {
    padding: theme.spacing(1.5, 0, 1.5, 1),
    "&.MuiTableCell-root:last-child": {
      textAlign: 'left',
    },
  },
  tooltip: {
    cursor: 'pointer',
  },
}))

export default ProfileDetailRow
