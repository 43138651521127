import { IndexedTx } from '@cosmjs/stargate'
import { BlockProp } from 'js/reducers/blocks'
import { Action } from 'redux'
import { BlockResultsResponse } from '@cosmjs/tendermint-rpc/build/tendermint37/responses'

export enum BlockActionType {
  CLEAR = 'CLEAR_BLOCK',
  SET_TRANSACTIONS = 'SET_BLOCK_TRANSACTIONS',
  SET_EVENTS = 'SET_BLOCK_EVENTS',
  SET_BLOCK_INFO = 'SET_BLOCK_INFO',
}

export type BlockAction =
  | ClearAction
  | SetTransactionsAction
  | SetEventsAction
  | SetBlockInfoAction

export interface ClearAction extends Action<BlockActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: BlockActionType.CLEAR,
  }
}

export interface SetTransactionsAction
  extends Action<BlockActionType.SET_TRANSACTIONS> {
  transactions: IndexedTx[]
}
export function setTransactions(
  transactions: IndexedTx[],
): SetTransactionsAction {
  return {
    type: BlockActionType.SET_TRANSACTIONS,
    transactions,
  }
}

export interface SetEventsAction extends Action<BlockActionType.SET_EVENTS> {
  events: BlockResultsResponse
}
export function setEvents(events: BlockResultsResponse): SetEventsAction {
  return {
    type: BlockActionType.SET_EVENTS,
    events,
  }
}
export interface SetBlockInfoAction
  extends Action<BlockActionType.SET_BLOCK_INFO> {
  blockInfo: BlockProp
}
export function setBlockInfo(blockInfo: BlockProp): SetBlockInfoAction {
  return {
    type: BlockActionType.SET_BLOCK_INFO,
    blockInfo,
  }
}
