import { Models } from 'carbon-js-sdk'
import { Stake } from 'carbon-js-sdk/lib/insights'
import { Record, RecordOf } from 'immutable'
import { ValidatorsAction, ValidatorsActionType } from '../actions/validators'

export interface ValidatorsProps {
  slashingParams?: Models.Slashing.Params
  distributionParams?: Models.Distribution.Params
  blockTime: number
  totalStakedChartData: Stake[]
}

const makeState: Record.Factory<ValidatorsProps> = Record<ValidatorsProps>({
  slashingParams: undefined,
  distributionParams: undefined,
  blockTime: 0,
  totalStakedChartData: [],
})
export type ValidatorsState = RecordOf<ValidatorsProps>

function validatorsReducer(
  state: ValidatorsState = makeState(),
  action: ValidatorsAction,
): ValidatorsState {
  switch (action.type) {
    case ValidatorsActionType.CLEAR:
      return makeState()
    case ValidatorsActionType.SET_SLASHING_PARAMS:
      return state.updateIn(['slashingParams'], () => action.slashingParams)
    case ValidatorsActionType.SET_DISTRIBUTION_PARAMS:
      return state.updateIn(['distributionParams'], () => action.distributionParams)
    case ValidatorsActionType.SET_BLOCK_TIME:
      return state.updateIn(['blockTime'], () => action.blockTime)
    case ValidatorsActionType.SET_TOTAL_STAKED_CHART_DATA:
      return state.updateIn(['totalStakedChartData'], () => action.totalStakedChartData)
    default:
      return state
  }
}

export default validatorsReducer
