import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  Link,
  Toolbar,
} from '@material-ui/core'
import { alpha, makeStyles, Theme } from '@material-ui/core/styles'
import { CarbonSDK } from 'carbon-js-sdk'
import clsx from 'clsx'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { CloseIcon, MenuIcon, CarbonScanLogo, CarbonScanLogoBeta } from './assets'
import { DrawerMenuList, NavBar, SearchInput } from './components'
interface Props {
  network: CarbonSDK.Network
}

const Header: React.FunctionComponent<Props> = ({
  network,
}: Props): ReactElement<Props> => {
  const [state, setState] = React.useState({
    isDrawerOpen: false,
    isHeaderRaised: false,
  })

  useEffect(() => {
    if (!state.isDrawerOpen) {
      setTimeout(
        () =>
          setState((state) => ({
            ...state,
            isHeaderRaised: false,
          })),
        225,
      ) // drawer transform animation is 225ms
    } else {
      setState((state) => ({
        ...state,
        isHeaderRaised: true,
      }))
    }
  }, [state.isDrawerOpen])

  const toggleDrawer = (forceClose?: boolean) => {
    return (event: any) => {
      if (event.type === 'keydown' && event.key === 'Enter') {
        event.preventDefault()
        setState({
          ...state,
          isDrawerOpen: false,
        })
        return
      }
      if (event.type === 'click' && event.target.id !== 'search-input') {
        setState({
          ...state,
          isDrawerOpen: !forceClose && !state.isDrawerOpen,
        })
      }
    }
  }

  const url = window.location.href
  let isBeta = false
  if (url.includes('beta-')) isBeta = true

  const classes = useStyles()
  return (
    <AppBar
      position="fixed"
      elevation={0}
      className={clsx(classes.root, {
        [classes.raisedHeader]: state.isHeaderRaised,
      })}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters className={classes.toolbar}>
          <Link
            underline="none"
            component={RouterLink}
            to={'/'}
            className={classes.title}
          >
            {
              isBeta ? <CarbonScanLogoBeta className={classes.carbonScanLogo} />
                : <CarbonScanLogo className={classes.carbonScanLogo} />
            }
          </Link>
          <Box flex={1} />
          <SearchInput className={classes.searchInput} network={network} />
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer()}
          >
            {!state.isDrawerOpen && (
              <MenuIcon className={classes.menuIcon} />
            )}
            {state.isDrawerOpen && (
              <CloseIcon className={classes.menuIcon} />
            )}
          </IconButton>
        </Toolbar>
      </Container>
      <Box className={classes.navBarContainer}>
        <Container maxWidth="lg">
          <NavBar />
        </Container>
      </Box>
      <Drawer
        anchor="top"
        open={state.isDrawerOpen}
        onClose={toggleDrawer(false)}
        className={classes.drawerMenu}
      >
        <DrawerMenuList network={network} toggleDrawer={toggleDrawer} />
      </Drawer>
    </AppBar>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    '&.MuiPaper-outlined': {
      border: 'unset',
    },
  },
  raisedHeader: {
    zIndex: theme.zIndex.modal + 1,
  },
  drawerMenu: {
    '& .MuiDrawer-paperAnchorTop': {
      top: '3.5rem',
      maxHeight: `calc(100% - 3.5rem)`,
    },
    '& .MuiList-root': {
      [theme.breakpoints.up('sm')]: {
        overflow: 'scroll',
      },
      overflow: 'scroll',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuButton: {
    padding: theme.spacing(0),
    '& .MuiSvgIcon-root': {
      width: '1.5rem',
      height: '1.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  network: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    border: 'none',
    borderRadius: '5px',
    '& .MuiFormLabel-root': {
      color: 'white',
      opacity: 0.75,
    },
    '& .MuiSelect-icon': {
      color: 'white',
      opacity: 0.75,
    },
    '&:hover': {
      backgroundColor: '#424242',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#575c64',
      },
    },
  },
  selectContainer: {
    border: '1px solid #575c64',
    borderRadius: '5px',
  },
  selectRoot: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#575c64',
    },
  },
  selectInput: {
    color: 'white',
    padding: theme.spacing(1, 3, 1.1, 2.5),
    '&:focus': {
      border: 'none',
    },
  },
  searchInput: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  carbonScanLogo: {
    width: '16.4rem',
    [theme.breakpoints.down('sm')]: {
      width: '14rem',
    },
  },
  navBarContainer: {
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuIcon: {
    width: '1.4375rem',
    height: '1.4375rem',
    display: 'none',
    '& path': {
      fill: switcheo.default.white,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'unset',
    },
  },
  title: {
    color: 'white',
  },
  toolbar: {
    [theme.breakpoints.up("md")]: {
      minHeight: theme.spacing(10),
    },
  },
}))

export default Header
