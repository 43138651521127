import { Blockchain, CarbonSDK } from "carbon-js-sdk"

export const Paths = {
  Foundation: {
    About: "https://switcheo.com/",
    Press: "https://switcheo.network/press",
    Blog: "https://blog.switcheo.network/",
    Support: "https://support.switcheo.network/",
  },
  EcoSystem: {
    Carbon: 'https://carbon.network/',
    Demex: 'https://app.dem.exchange/trade',
    Stake: 'https://app.dem.exchange/stake',
    Pool: 'https://app.dem.exchange/pools',
    Tradehub: 'https://legacy-scan.carbon.network/',
    Hydrogen: 'https://hydrogen.carbon.network',
    CarbonHub: 'https://hub.carbon.network',
  },
  Resources: {
    API: 'https://docs.carbon.network/api-references',
    Github: 'https://github.com/Switcheo/carbon-bootstrap',
    DevFund: 'https://commonwealth.im/carbon-protocol/discussion/4486-how-to-apply-for-a-grant-under-the-switcheo-development-fund',
    MediaKit: 'https://switcheo-assets.s3-ap-southeast-1.amazonaws.com/Switcheo_MediaKit_2020.zip',
    PrivacyPolicy: 'https://switcheo.network/privacy-policy',
    GovernanceGuide: 'https://forum.carbon.network/topic/35/what-is-governance',
    TypesOfProposals: 'https://forum.carbon.network/topic/36/types-of-proposals',
    HowToVote: 'https://forum.carbon.network/topic/34/how-to-vote-for-proposals',
    CreateAndSubmitProposals: 'https://forum.carbon.network/topic/33/how-to-create-and-submit-proposals',
    Guide: 'https://guide.carbon.network/',
  },
  Social: {
    Github: 'https://github.com/switcheo',
    Discord: 'https://discord.com/invite/SPh62Hf',
    Telegram: 'https://t.me/demex_community',
    Twitter: 'https://twitter.com/0xcarbon',
    Reddit: 'https://www.reddit.com/r/Switcheo/',
    Medium: 'https://medium.com/switcheo',
    LinkedIn: 'https://www.linkedin.com/company/switcheonetwork/',
    Facebook: 'https://www.facebook.com/SwitcheoExchange/',
    Youtube: 'https://www.youtube.com/channel/UCqZ9sxvw-0UoHzno4-d97oQ/playlists',
  },

  Home: '/',
  Dashboard: '/dashboard',
  Proposal: '/governance/proposal/:proposalId',
  SubmitProposal: '/governance/submit-proposal',
  Governance: '/governance',
  Account: '/account/:address',
  Blocks: '/blocks',
  Block: '/block/:height',
  Transaction: '/transaction/:hash',
  Transactions: '/transactions',
  Tokens: '/tokens',
  Token: '/token/:symbol',
  Markets: '/markets',
  Market: '/market/:market',
  Nodes: '/nodes',
  Order: '/order/:id',
  Position: '/position/:address/:market/:status',
  Events: '/events',
  Validators: '/validators',
  Validator: '/validator/:address',
  Deposit: '/deposit',
  Login: '/login',
  Search: '/search',
  Pools: '/pools',
  Pool: '/pool/:poolId',
  Leaderboard: '/leaderboard',
  LeaderboardHistory: '/leaderboard_history',
  Hydrogen: 'https://hydrogen.carbon.network',
  LiquidationAndADL: '/market/liquidation-adl',
  Futures: '/market/futures',
  CDP: '/cdp',
  CDPAsset: '/cdp/:symbol',
  Group: '/group',
  TradingHistory: '/trading',
  Stake: 'https://hub.carbon.network/stake',
  Insights: '/insights-view',
  Alliance: '/alliance',
  Oracles: '/oracles',
} as const

export const TokenIconPath = `https://raw.githubusercontent.com/Switcheo/token-icons/main/tokens/:token_name.:file_type`
export const BlockchainIconPath = `https://raw.githubusercontent.com/Switcheo/token-icons/main/blockchains/:blockchain_name.:file_type`

export const BlockChainPaths = {
  [CarbonSDK.Network.MainNet]: {
    [Blockchain.Terra]: 'https://finder.terra.money/mainnet:path',
    [Blockchain.Ethereum]: 'https://etherscan.io:path',
    [Blockchain.BinanceSmartChain]: 'https://bscscan.com:path',
    [Blockchain.Neo]: 'https://neo2.neotube.io:path',
    [Blockchain.Neo3]: 'https://neo3.neotube.io:path',
    [Blockchain.Zilliqa]: 'https://viewblock.io/zilliqa:path',
    [Blockchain.PolyNetwork]: 'https://explorer.poly.network:path',
    [Blockchain.Osmosis]: 'https://www.mintscan.io/osmosis:path',
    [Blockchain.Ibc]: 'https://ibc.iobscan.io:path',
    [Blockchain.Juno]: 'https://www.mintscan.io/juno:path',
    [Blockchain.CosmosHub]: 'https://www.mintscan.io/cosmos:path',
    [Blockchain.Evmos]: 'https://www.mintscan.io/evmos:path',
    [Blockchain.Axelar]: 'https://www.mintscan.io/axelar:path',
    [Blockchain.Stride]: 'https://www.mintscan.io/stride:path',
    [Blockchain.Kujira]: 'https://www.mintscan.io/kujira:path',
    [Blockchain.Terra2]: 'https://finder.terra.money/mainnet:path',
    [Blockchain.Comdex]: 'https://www.mintscan.io/comdex:path',
    [Blockchain.StafiHub]: 'https://www.mintscan.io/stafi:path',
    [Blockchain.Persistence]: 'https://www.mintscan.io/persistence:path',
    [Blockchain.Stargaze]: 'https://www.mintscan.io/stargaze:path',
    [Blockchain.Quicksilver]: 'https://www.mintscan.io/quicksilver:path',
    [Blockchain.Agoric]: 'https://atomscan.com/agoric:path',
    [Blockchain.Canto]: 'https://atomscan.com/canto:path',
    [Blockchain.OmniFlixHub]: 'https://www.mintscan.io/omniflix:path',
    [Blockchain.Polygon]: 'https://polygonscan.com:path',
    [Blockchain.Sommelier]: 'https://www.mintscan.io/sommelier:path',
    [Blockchain.Arbitrum]: 'https://arbiscan.io:path',
    'noble': 'https://www.mintscan.io/noble:path'
  } as const,
  [CarbonSDK.Network.DevNet]: {
    [Blockchain.Ethereum]: 'https://ropsten.etherscan.io:path',
    [Blockchain.BinanceSmartChain]: 'https://testnet.bscscan.com:path',
    [Blockchain.Neo]: 'https://testnet.neotube.io:path',
    [Blockchain.Neo3]: 'https://neo3.testnet.neotube.io:path',
    [Blockchain.Zilliqa]: 'https://viewblock.io/zilliqa:path?network=testnet',
    [Blockchain.PolyNetwork]: 'https://explorer.poly.network/testnet:path',
    [Blockchain.Osmosis]: '',
    [Blockchain.Juno]: '',
    [Blockchain.Ibc]: '',
    [Blockchain.CosmosHub]: '',
    [Blockchain.Terra]: 'https://finder.terra.money/testnet:path',
    [Blockchain.Evmos]: '',
    [Blockchain.Axelar]: '',
    [Blockchain.Stride]: '',
    [Blockchain.Kujira]: '',
    [Blockchain.Terra2]: 'https://finder.terra.money/testnet:path',
    [Blockchain.Comdex]: '',
    [Blockchain.StafiHub]: '',
    [Blockchain.Persistence]: '',
    [Blockchain.Stargaze]: '',
    [Blockchain.Quicksilver]: '',
    [Blockchain.Agoric]: '',
    [Blockchain.Canto]: '',
    [Blockchain.OmniFlixHub]: '',
    [Blockchain.Polygon]: '',
    [Blockchain.Sommelier]: '',
    [Blockchain.Arbitrum]: '',
    'noble': '',
  } as const,
} as const

export const getBlockChain = (network: string, chainID: number) => {
  switch (network) {
    case 'MAINNET':
      return BlockChainMapMainNet[chainID]
    case 'TESTNET':
      return BlockChainMapTestNet[chainID]
    case 'DEVNET':
      return BlockChainMapDevNet[chainID]
    default:
      return undefined
  }
}

const BlockChainMapMainNet: { [string: number]: string } = {
  1: 'Btc',
  2: 'Eth',
  3: 'Ont',
  4: 'Neo',
  5: 'Switcheo',
  6: 'Bsc',
  7: 'Heco',
  8: 'OKex',
}

const BlockChainMapDevNet: { [string: number]: string } = {
  1: 'Btc',
  2: 'Eth',
  3: 'Ont',
  5: 'Neo(N2)',
  79: 'Bsc',
  7: 'Heco',
  200: 'OKex',
  106: 'Palette',
  11: 'NEO(N3)',
  110: 'Zil'
}

const BlockChainMapTestNet: { [string: number]: string } = {
  1: 'Btc',
  2: 'Eth',
  3: 'Ont',
  4: 'Neo',
  182: 'Switcheo',
  8: 'Cosmos',
  111: 'Zil'
}
