import { Backdrop, Box, Button, FormControlLabel, IconButton, Link, makeStyles, Paper, Switch, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { AddIcon, MobileMenuArrowIcon, NetworkIcon } from 'assets';
import { CarbonSDK } from 'carbon-js-sdk';
import { Network, NetworkConfigs } from 'carbon-js-sdk/lib/constant/network';
import clsx from 'clsx';
import { setAutoSelectNode, setFormNode, setMenuDropClose, setNetDropClose, setNetDropOpen, setSelectedNodes, setWalletDropClose, showNodeInfoForm } from 'js/actions/app';
import { Paths } from 'js/constants';
import { NodeModel } from "js/reducers/nodes";
import { updateCarbonSDK } from 'js/store/core';
import { switcheo } from 'js/theme/palettes/colors';
import { getNetworkLabel } from 'js/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import customToast from '../Toast/Toast';
import { NetworkToggle, NodeInfoForm, NodeTable } from './components';

const NodeSelectDropdown: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props: any) => {
  const { children, className, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const netDrop = useSelector((state: any) => state.app.netDrop)
  const network = useSelector((state: any) => state.app.network) as Network
  const isShowNodeInfoForm = useSelector((state: any) => state.app.isShowNodeInfoForm)
  const nodes = useSelector((state: any) => state.app.nodes)
  const selectedNodes = useSelector((state: any) => state.app.selectedNodes)
  const autoSelectNode = useSelector((state: any) => state.app.autoSelectNode)
  const connectError = useSelector((state: any) => state.app.connectError)
  const [selectingNode, setSelectingNode] = useState<NodeModel | null>(null)

  const selectedNodeName = useMemo(() => selectedNodes[network]?.moniker, [selectedNodes, network])

  const recommendedNode = useMemo(() => {
    const bestNode = nodes.sort((a: { rating: number; }, b: { rating: number; }) => b.rating - a.rating)[0]
    return bestNode
  }, [nodes])
  const handleSelectNode = async (node: NodeModel, forceUpdate?: boolean) => {
    if (!node || (node.appBuild) !== network) return
    setSelectingNode(node)
    try {
      const updatedSDK = await CarbonSDK.instance({
        network,
        config: {
          tmWsUrl: node.tmWsUrl ?? NetworkConfigs[network].tmWsUrl,
          tmRpcUrl: node.rpcUrl,
          restUrl: node.restUrl,
          wsUrl: node.wsUrl,
          faucetUrl: node.faucetUrl,
          insightsUrl: node.insightsUrl,
        }
      })
      dispatch(updateCarbonSDK(updatedSDK))
      dispatch(setSelectedNodes({
        ...selectedNodes,
        [network]: node,
      }))
      if (node.moniker !== recommendedNode?.moniker) {
        dispatch(setAutoSelectNode(false))
      }
      customToast(
        `Node selected: ${node.moniker}`,
        `RPC URL: ${node.rpcUrl}`,
      )
      setSelectingNode(null)
    } catch (error) {
      if (selectingNode?.moniker !== selectedNodeName) {
        setSelectingNode(null)
      }
      customToast(
        `Node connection error: ${(error as Error).message}`,
        'Please check the console for more details, or try switching to another node.',
      )
      if (node.moniker === selectedNodeName && selectedNodeName !== recommendedNode.moniker) {
        handleSelectNode(recommendedNode)
      }
    }
  }

  useEffect(() => {
    if (connectError) {
      customToast(
        `Network connection error: ${connectError.message}`,
        'Please check the console for more details.',
      )
    }
  }, [connectError])

  // useEffect(() => {
  //   if (selectedNodeName) {
  //     handleSelectNode(selectedNodes[network])
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [network])

  useEffect(() => {
    if ((autoSelectNode && selectedNodeName !== recommendedNode?.moniker) || !selectedNodeName) {
      handleSelectNode(recommendedNode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectNode, recommendedNode])

  const onOpenMenu = () => {
    dispatch(setMenuDropClose())
    dispatch(setWalletDropClose())
    if (netDrop) {
      dispatch(setNetDropClose())
    } else {
      dispatch(setNetDropOpen())
    }
  }

  const onCloseMenu = () => {
    dispatch(setNetDropClose())
  }

  const handleAutoSelect = () => {
    dispatch(setAutoSelectNode(!autoSelectNode))
  }

  const handleAddCustomNode = () => {
    dispatch(showNodeInfoForm())
    dispatch(setFormNode(undefined))
  }
  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {isMobile ? (
        <Button
          onClick={onOpenMenu}
          className={classes.button}
          startIcon={<NetworkIcon className={classes.networkIcon} />}
        >
          <Box className={classes.buttonBox}>
            <Typography className={classes.networkText} variant='h3'>
              Network - {getNetworkLabel(network)}
            </Typography>
            <MobileMenuArrowIcon className={clsx(classes.arrowSvg, { active: netDrop })} />
          </Box>
        </Button>
      ) : (
        <Tooltip arrow title={getNetworkLabel(network)} placement="left" classes={{
          tooltipArrow: classes.tooltipArrow,
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}>
          <IconButton
            onClick={onOpenMenu}
            className={classes.button}
          >
            <NetworkIcon className={classes.networkIcon} />
          </IconButton>
        </Tooltip>
      )}
      {
        netDrop && (
          <React.Fragment>
            <Backdrop
              className={clsx(classes.backdrop, { open: netDrop })}
              invisible
              open={netDrop}
              onClick={onCloseMenu}
            />
            <Paper className={classes.menuPaper} elevation={isMobile ? 0 : 1}>
              {isShowNodeInfoForm ? (
                <NodeInfoForm handleSelectNode={handleSelectNode} />
              ) : (
                <Box className={classes.networkMenu}>
                  <Box>
                    <Box className={classes.dropdownHeader}>
                      <NetworkToggle />
                      <FormControlLabel
                        className={classes.switch}
                        control={(
                          <Switch
                            color="secondary"
                            onChange={handleAutoSelect}
                            checked={autoSelectNode}
                          />
                        )}
                        label={(
                          <Typography className={classes.switchLabel}>
                            Auto-select {autoSelectNode ? 'On' : 'Off'}
                          </Typography>
                        )}
                        labelPlacement="start"
                      />
                    </Box>
                    <NodeTable handleSelectNode={handleSelectNode} recommendedNode={recommendedNode} selectingNode={selectingNode} />
                    <Button className={classes.addButton} onClick={handleAddCustomNode}>
                      <AddIcon className={classes.addIcon} />
                      Add Custom Node
                    </Button>
                  </Box>
                  <Box className={classes.dropdownFooter}>
                    Want to host a new node on Carbon? &nbsp;
                    <Link underline="none" href={Paths.Social.Github}>
                      Visit Github
                    </Link>
                  </Box>
                </Box>
              )}
            </Paper>
          </React.Fragment>
        )
      }
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  backdrop: {
    width: '100%',
  },
  switch: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
    minWidth: '10rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      paddingLeft: theme.spacing(1),
      marginRight: 0,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  switchLabel: {
    fontSize: '0.75rem',
  },
  button: {
    marginRight: theme.spacing(1.25),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0, 1, 2),
      padding: theme.spacing(1),
    },
  },
  networkText: {
    color: theme.palette.text.hint,
    fontWeight: 'normal',
    textTransform: 'initial',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'bold',
      fontSize: '1.375rem',
    },
  },
  buttonBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  menuPaper: {
    position: 'absolute',
    top: '2.5rem',
    right: 0,
    backgroundColor: switcheo.background.grey,
    width: '21.875rem',
    minHeight: '33.75rem',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      padding: theme.spacing(0, 1.5, 1),
      borderRadius: 0,
    },
  },
  networkMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  networkIcon: {
    width: 16,
    height: 16,
    '& path': {
      fill: theme.palette.text.hint,
    },
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  },
  tooltipArrow: {
    backgroundColor: switcheo.background.grey,
    padding: theme.spacing(1),
  },
  tooltip: {
    ...theme.typography.body2,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
  },
  arrow: {
    color: switcheo.background.grey,
  },
  dropdownHeader: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  addButton: {
    width: '100%',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.text.hint,
    textTransform: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontWeight: 'bold',
    borderRadius: 0,
    whiteSpace: 'nowrap',
  },
  addIcon: {
    marginRight: theme.spacing(1),
    '& path': {
      stroke: theme.palette.text.hint,
    },
  },
  dropdownFooter: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      flexDirection: 'column',
    },
  },
  arrowSvg: {
    '& path': {
      fill: theme.palette.text.hint,
    },
    transition: 'transform 0.2s ease-out',
    marginRight: '0.6em',
    '&.active': {
      transform: "rotate(-90deg)"
    }
  },
}))

export default NodeSelectDropdown
