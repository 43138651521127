import { makeStyles, SvgIcon, SvgIconProps } from '@material-ui/core'
import { ReactComponent as PlaceholderIcon } from 'assets/Placeholder.svg'
import React, { useState } from 'react'
import clsx from 'clsx'
import { BlockchainIconPath } from 'js/constants'

function getBlockchainIconURL(blockchain: string, fileType: string = 'svg') {
  return BlockchainIconPath.replace(':blockchain_name', blockchain.replace(" ", "%20")).replace(':file_type', fileType)
}

const blockchainNameMap: {
  [index: string]: string
} = {
  "Native": "Carbon",
  "Terra Classic": "Terra",
}

export interface BlockchainIconProps extends SvgIconProps {
  blockchain?: string
}

const BlockchainIcon: React.FunctionComponent<BlockchainIconProps> = (
  props: BlockchainIconProps,
) => {
  const { blockchain, className, ...rest } = props
  const classes = useStyles()
  const [imageSrcError, setImageSrcError] = useState(false);
  const [imageSrcIndex, setImageSrcIndex] = useState(0);

  let symbolToFetch = ""
  if (blockchain) {
    symbolToFetch = blockchainNameMap[blockchain] ?? (blockchain === 'Ibc' ? 'IBC' : blockchain)
  }

  const imageSrcArray = symbolToFetch
    ? [
      getBlockchainIconURL(symbolToFetch, 'svg'),
      getBlockchainIconURL(symbolToFetch, 'png'),
    ] : []

  const handleImgSrcError = () => {
    if (imageSrcIndex < imageSrcArray.length - 1) {
      setImageSrcIndex(imageSrcIndex + 1);
    } else {
      setImageSrcError(true);
    }
  }
  let defaultIcon: React.ReactNode = <PlaceholderIcon />

  return !imageSrcError && symbolToFetch ? (
    <img className={clsx(classes.svg, className)} src={imageSrcArray[imageSrcIndex]} onError={handleImgSrcError} alt={symbolToFetch} />
  ) : (
    <SvgIcon
      {...rest}
      className={clsx(classes.svg, className)}
    >
      {defaultIcon}
    </SvgIcon>
  )
}

const useStyles = makeStyles({
  svg: {
    fontSize: 'inherit',
    width: '2em',
    height: '2em',
  },
})

export default BlockchainIcon