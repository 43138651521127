import BigNumber from "bignumber.js"
import { CarbonTx } from "carbon-js-sdk"
import { MsgUpdateAlliance } from "carbon-js-sdk/lib/codec"
import { DecCoin } from "carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import store from "js/store"
import { SHIFT_DECIMALS, escapeHtmlGo } from "js/utils"
import { fieldTypes } from "../Helpers/types"

export const setUpdateAllianceFields = ['denom', 'rewardWeight', 'takeRate', 'rewardChangeRate', 'rewardChangeInterval']

export function setUpdateAllianceConfig() {
  return {
    denom: {
      id: 'denom',
      label: 'Denom',
      type: fieldTypes.text,
    },
    rewardWeight: {
      id: 'rewardWeight',
      label: 'Reward Weight in %',
      type: fieldTypes.number,
      tooltip: 'Eg. Entering 0.01 will result in 1%',
      constraints: {
        greaterThanOrEqualTo: 0,
        // onlyInteger: true,
      },
    },
    takeRate: {
      id: 'takeRate',
      label: 'Take Rate in %',
      type: fieldTypes.number,
      tooltip: 'Eg. Entering 0.01 will result in 1%',
      constraints: {
        greaterThanOrEqualTo: 0,
        // onlyInteger: true,
      },
    },
    rewardChangeRate: {
      id: 'rewardChangeRate',
      label: 'Reward Change Rate',
      type: fieldTypes.number,
      // tooltip: 'rewardChangeRate',
      constraints: {
        greaterThanOrEqualTo: 0,
        // onlyInteger: true,
      },
    },
    rewardChangeInterval: {
      id: 'rewardChangeInterval',
      label: 'Reward Change Interval in seconds',
      type: fieldTypes.number,
      // tooltip: '',
      constraints: {
        greaterThanOrEqualTo: 0,
        // onlyInteger: true,
      },
    },
  }
}


export function setAllianceUpdateValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const formState = store.getState().governance.submitProposalFormState as any

  const { title, summary, metadata, denom, rewardWeight, takeRate, rewardChangeRate, rewardChangeInterval } = formState!

  const msg = MsgUpdateAlliance.fromPartial({
    authority: authority,
    denom: denom,
    rewardWeight: BigNumber(rewardWeight).shiftedBy(SHIFT_DECIMALS).toString(),
    takeRate: BigNumber(takeRate).shiftedBy(SHIFT_DECIMALS).toString(),
    rewardChangeRate: BigNumber(rewardChangeRate).shiftedBy(SHIFT_DECIMALS).toString(),
    rewardChangeInterval: {
      seconds: rewardChangeInterval
    },
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    proposer: proposer ?? "",
    initialDeposit,
    expedited: false,
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgAllianceUpdateAlliance,
        value: MsgUpdateAlliance.encode(msg).finish(),
      }
    ],
  }

  return value
}
