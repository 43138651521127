import { Divider, Typography } from '@material-ui/core';
import { CopyTradeDarkSVG } from 'assets';
import { CellLink, TooltipHint } from 'js/components';
import { copyTextToClipBoard, truncateStr } from 'js/helpers';
import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web'
import { bnOrZero } from 'js/utils';
import { Skeleton } from '@material-ui/lab';

interface ColumnProps {
    title: string
    text: string | number
    copy?: boolean
    truncate?: boolean
    unit?: string
    dollarSign?: boolean
    link?: string
    loading?: boolean
    titleUnit?: string
}

export const Column = (props: ColumnProps) => {
    const { title, text, copy = false, truncate = false, unit, dollarSign, link, loading, titleUnit } = props;
    const [copied, setCopied] = useState(false)
    const copyText = (text: string) => {
        copyTextToClipBoard(text)
        //set Copied to true to show Copied UI
        setCopied(true);
        setTimeout(() => setCopied(false), 1300)
    }

    const textDecimals = isNumeric(text) ? bnOrZero(text).dp() : 0
    // react-spring
    const springValue = useSpring({
        // val: Number(text),
        from: { val: Number(0) },
        to: { val: Number(isNumeric(text) ? text : 0) },
        config: {
            velocity: 0.02,
            friction: 10,
            mass: 0.8
        },
        immediate: isNumeric(text) ? Number(text) === 0 : true
    });

    function isNumeric(n: any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
    return (
        <span style={{ overflow: 'hidden' }}>
            <Typography component="span" variant="subtitle2">
                {title}
            </Typography>
            {titleUnit && (<Typography variant="caption" component="span" style={{ color: 'rgb(140 142 143)', verticalAlign: 'top', marginLeft: 2 }}>{titleUnit}</Typography>)}
            <Divider />
            <Typography variant="h6">
                {
                    !loading && link ? (
                        <>
                            <CellLink to={link}>
                                {truncate ? truncateStr(text.toString(), 5, 4, "...") : isNumeric(text) ? <animated.span id={`${title}${text}`} key={`${title}${text}`}>{springValue.val.to((value) => {
                                    return dollarSign ? "$" + value.toLocaleString(undefined, { maximumFractionDigits: 2 }) : value.toLocaleString(undefined, { maximumFractionDigits: textDecimals ?? undefined })
                                })}</animated.span> : text} {unit && (<Typography variant="caption" component="span" style={{ color: 'rgb(140 142 143)', verticalAlign: 'text-bottom' }}>{unit}</Typography>)}
                            </CellLink>
                            {copy && <TooltipHint title={"Copied!"} open={copied}>
                                <CopyTradeDarkSVG onClick={() => copyText(text.toString())} fontSize={"small"} style={{ cursor: 'pointer' }} />
                            </TooltipHint>}
                        </>
                    )
                        : <>
                            {truncate ? truncateStr(text.toString(), 5, 4, "...") : isNumeric(text) ? <animated.span id={`${title}${text}`} key={`${title}${text}`}>{springValue.val.to((value) => {
                                return dollarSign ? "$" + value.toLocaleString(undefined, { maximumFractionDigits: 2 }) : value.toLocaleString(undefined, { maximumFractionDigits: textDecimals ?? undefined })
                            })}</animated.span> : text} {unit && (<Typography variant="caption" component="span" style={{ color: 'rgb(140 142 143)', verticalAlign: 'text-bottom' }}>{unit}</Typography>)} {copy && <TooltipHint title={"Copied!"} open={copied}>
                                <CopyTradeDarkSVG onClick={() => copyText(text.toString())} fontSize={"small"} style={{ cursor: 'pointer' }} />
                            </TooltipHint>}
                        </>

                }
                {
                    loading && <div><Skeleton /> </div>
                }
            </Typography>
        </span>
    );
};
