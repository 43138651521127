export function isBase64(encodedString: string) {
  const decodedString = Buffer.from(encodedString, "base64").toString("ascii")
  const rencodedString = Buffer.from(decodedString).toString("base64")
  return rencodedString === encodedString
}

export function convertBlockEventUint8ArrayToString(events: any[]) {
  for (let event of events) {
    for (let attribute of event.attributes as any) {
      if (attribute.key?.length && isBase64(attribute?.key ?? "")) {
        const keyAscii = Buffer.from(attribute.key, 'base64').toString('ascii') ?? ""
        attribute.key = keyAscii
      }
      if (attribute?.value?.length && isBase64(attribute?.value ?? "")) {
        const valueAscii = Buffer.from(attribute.value, 'base64').toString('ascii') ?? ""
        attribute.value = valueAscii
      }
    }
  }
}