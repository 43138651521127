import { Insights } from 'carbon-js-sdk'
import { TaskNames } from 'js/constants'
import moment from 'moment'
import { SagaIterator } from 'redux-saga'
import { Effect, put, select, spawn, takeLatest } from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import { clear, LeaderboardActionType, set30dPnl, setLifetimePnl } from '../actions/leaderboard'
import { runSagaTask, waitforSDK } from './helper'
import Saga from './Saga'

export default class Leaderboard extends Saga {
  private readonly isMobile: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchLeaderboard30D],
      [this, this.fetchLeaderboardLifetime],
      [this, this.watchSetNetwork],
      [this, this.watch30dPnlFilters],
      [this, this.watchLifetimePnlFilters],
      [this, this.watchSetLeaderboardMarket],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchLeaderboard30D(): any {
    yield runSagaTask(TaskNames.Leaderboard.D30, function* () {
      const sdk = yield* waitforSDK()
      const selectedMarket = (yield select((state) => state.leaderboard.leaderboardMarket)) as string
      const queryParams = { sort: 'DESC' as 'DESC', market: selectedMarket === "all" ? "" : selectedMarket }
      const unix30dAgo = moment().subtract(30, 'days').unix()
      const pathParams = { fromUnix: unix30dAgo, toUnix: moment().unix() }
      const leaderboardResult = (yield sdk.insights.Leaderboard(pathParams, queryParams)) as Insights.InsightsQueryResponse<Insights.QueryGetLeaderboardResponse>
      yield put(set30dPnl(leaderboardResult?.result?.entries?.rows as Insights.IndivPnl[]))
    })
  }

  private *fetchLeaderboardLifetime(): any {
    yield runSagaTask(TaskNames.Leaderboard.Lifetime, function* () {
      const sdk = yield* waitforSDK()
      const selectedMarket = (yield select((state) => state.leaderboard.leaderboardMarket)) as string
      const queryParams = { sort: 'DESC' as 'DESC', market: selectedMarket === "all" ? "" : selectedMarket }
      const unix30dAgo = moment().subtract(10, 'years').unix()
      const pathParams = { fromUnix: unix30dAgo, toUnix: moment().unix() }
      const leaderboardResult = (yield sdk.insights.Leaderboard(pathParams, queryParams)) as Insights.InsightsQueryResponse<Insights.QueryGetLeaderboardResponse>
      yield put(setLifetimePnl(leaderboardResult?.result?.entries?.rows as Insights.IndivPnl[]))
    })
  }

  private *watch30dPnlFilters(): any {
    yield takeLatest(
      LeaderboardActionType.UPDATE_30D_PNL_FILTERS,
      this.handle30dPnlFilters,
    )
  }

  private *handle30dPnlFilters(action: any): any {
    yield runSagaTask(TaskNames.Leaderboard.D30Filters, function* () {
      const sdk = yield* waitforSDK()
      const selectedMarket = (yield select((state) => state.leaderboard.leaderboardMarket)) as string

      const queryParams = {
        sort: 'DESC' as 'DESC', market: selectedMarket === "all" ? "" : selectedMarket
      }
      const unix30dAgo = moment().subtract(10, 'years').unix()
      const pathParams = { fromUnix: unix30dAgo, toUnix: moment().unix() }
      const leaderboardResult = (yield sdk.insights.Leaderboard(pathParams, queryParams)) as Insights.InsightsQueryResponse<Insights.QueryGetLeaderboardResponse>
      yield put(set30dPnl(leaderboardResult?.result?.entries?.rows))
    })
  }
  private * watchLifetimePnlFilters(): any {
    yield takeLatest(
      LeaderboardActionType.UPDATE_LIFETIME_PNL_FILTERS,
      this.handleLifetimePnlFilters,
    )
  }

  private * handleLifetimePnlFilters(action: any): any {
    yield runSagaTask(TaskNames.Leaderboard.LifetimeFilters, function* () {
      const sdk = yield* waitforSDK()
      const selectedMarket = (yield select((state) => state.leaderboard.leaderboardMarket)) as string
      const queryParams = { sort: 'DESC' as 'DESC', market: selectedMarket === "all" ? "" : selectedMarket }
      const unix30dAgo = moment().subtract(10, 'years').unix()
      const pathParams = { fromUnix: unix30dAgo, toUnix: moment().unix() }
      const leaderboardResult = (yield sdk.insights.Leaderboard(pathParams, queryParams)) as Insights.InsightsQueryResponse<Insights.QueryGetLeaderboardResponse>
      yield put(setLifetimePnl(leaderboardResult?.result?.entries?.rows as Insights.IndivPnl[]))
    })
  }

  private * watchSetLeaderboardMarket(): any {
    yield takeLatest(
      LeaderboardActionType.SET_LEADERBOARD_MARKET,
      this.handle30dPnlFilters,
    )
    yield takeLatest(
      LeaderboardActionType.SET_LEADERBOARD_MARKET,
      this.handleLifetimePnlFilters,
    )
  }
}
