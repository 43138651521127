import { Box, BoxProps, Button, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, makeStyles, TextField, Typography } from '@material-ui/core'
import { CaretLeft, DeleteIcon } from 'assets'
import { hideNodeInfoForm, setCustomNodes, setFormNode } from 'js/actions/app'
import { StandardBtn, StatefulButton } from 'js/components'
import { TaskName } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

interface Props extends BoxProps {
  handleSelectNode: (node: any, forceUpdate?: boolean) => void
}

const NodeInfoForm: React.FC<Props> = (props: Props) => {
  const { handleSelectNode } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { nodes, network, customNodes, selectedNodes, formNode } = useRedux((state) => state.app)
  const filteredCustomNodes = customNodes.filter((node) => node.appBuild === network)
  const customIndex = customNodes.findIndex((node) => node === formNode)
  const isCustomNode = !formNode || customIndex > -1
  const [formState, setFormState] = useState<any>({})
  const [openDialog, setOpenDialog] = useState(false)
  const [formErrors, setFormErrors] = useState<string[]>([])
  const [runSaveNode, loading, error] = useAsyncTask(TaskName.SaveNode)

  useEffect(() => {
    if (formNode) {
      setFormState(formNode)
    }
  }, [formNode])

  const handleBack = () => {
    dispatch(hideNodeInfoForm())
    setFormNode(undefined)
    setFormErrors([])
  }

  const validateForm = (formState: any) => {
    setFormErrors([])
    const errors = []
    const moniker = formState.moniker as string
    const nodeMatch = nodes.find((node) => node.moniker === moniker)
    const filteredNodeMatch = filteredCustomNodes.find((node) => node.moniker === moniker)
    if (nodeMatch || (filteredNodeMatch && filteredNodeMatch.moniker !== formNode?.moniker)) {
      errors.push('moniker')
    }
    const rpcUrl = formState.rpcUrl as string
    if (!rpcUrl.startsWith('http://') && !rpcUrl.startsWith('https://')) {
      errors.push('rpcUrl')
    }
    const restUrl = formState.restUrl as string
    if (!restUrl.startsWith('http://') && !restUrl.startsWith('https://')) {
      errors.push('restUrl')
    }
    const wsUrl = formState.wsUrl as string
    if (!wsUrl.startsWith('ws://') && !wsUrl.startsWith('wss://')) {
      errors.push('wsUrl')
    }
    const tmWsUrl = formState.tmWsUrl as string
    if (!tmWsUrl.startsWith('ws://') && !tmWsUrl.startsWith('wss://')) {
      errors.push('tmWsUrl')
    }
    const faucetUrl = formState.faucetUrl as string
    if (!faucetUrl.startsWith('http://') && !faucetUrl.startsWith('https://')) {
      errors.push('faucetUrl')
    }
    const insightsUrl = formState.insightsUrl as string
    if (!insightsUrl.startsWith('http://') && !insightsUrl.startsWith('https://')) {
      errors.push('insightsUrl')
    }
    setFormErrors(errors)
    if (errors.length > 0) {
      throw new Error()
    }
  }

  const handleSave = () => {
    runSaveNode(async () => {
      validateForm(formState)

      const newNode = {
        ...formNode,
        appBuild: network,
        ...formState,
      }
      const newNodes = customNodes
      if (formNode) {
        newNodes[customIndex] = newNode
      } else {
        newNodes.push(newNode)
      }
      dispatch(setCustomNodes(newNodes))
      if (selectedNodes[network]?.moniker === formNode?.moniker) {
        handleSelectNode(formState, true)
      }
      handleBack()
    })
  }

  const handleDelete = () => {
    const newNodes = customNodes
    newNodes.splice(customIndex, 1)
    dispatch(setCustomNodes(newNodes))
    if (selectedNodes[network]?.moniker === formNode?.moniker) {
      const newNode = nodes[0]
      handleSelectNode(newNode)
    }
    handleBack()
  }

  const handleOpenDialog = () => setOpenDialog(true)

  const handleCloseDialog = () => setOpenDialog(false)

  const handleFormChange = (key: string) => (event: ChangeEvent<{ value: unknown }>) => setFormState({
    ...formState,
    [key]: event.target.value as string,
  })

  const inputFields = [
    {
      key: 'moniker',
      label: 'Node Name',
    },
    {
      key: 'rpcUrl',
      label: 'RPC URL',
    },
    {
      key: 'restUrl',
      label: 'Rest URL',
    },
    {
      key: 'wsUrl',
      label: 'Websocket URL',
    },
    {
      key: 'faucetUrl',
      label: 'Faucet URL',
    },
    {
      key: 'insightsUrl',
      label: 'Insights URL',
    },
    {
      key: 'tmWsUrl',
      label: 'tmWebSocket URL'
    }
  ]

  return (
    <Box className={classes.root}>
      <Button
        onClick={handleBack}
        className={classes.backButton}
      >
        <CaretLeft className={classes.backArrow} />Back
      </Button>
      {inputFields.map((field) => (
        <FormControl fullWidth key={field.key} className={classes.formControl}>
          <InputLabel shrink className={classes.inputLabel}>
            {field.label}
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            color="secondary"
            value={formState[field.key] ?? ''}
            onChange={handleFormChange(field.key)}
            disabled={!isCustomNode}
            error={!!formErrors.find((error) => error === field.key)}
          />
        </FormControl>
      ))}
      {error && formErrors[0] === 'moniker' && (
        <Typography className={classes.error}>
          Another node with the same name already exists
        </Typography>
      )}
      {error && (formErrors[0] !== 'moniker' || formErrors.length > 1) && (
        <Typography className={classes.error}>
          URL inputs must be valid HTTP/WS URLs
        </Typography>
      )}
      {isCustomNode && (
        <Box className={classes.buttons}>
          <Box display="flex">
            <StandardBtn
              className={classes.button}
              variant='outlined'
              color='secondary'
              onClick={handleBack}
            >
              Cancel
            </StandardBtn>
            <StatefulButton
              className={classes.button}
              variant='contained'
              color='secondary'
              onClick={handleSave}
              loading={loading}
            >
              Save
            </StatefulButton>
          </Box>
          {customIndex > -1 && (
            <IconButton onClick={handleOpenDialog}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle classes={{
          root: classes.dialogTitle,
        }}>
          Delete Node?
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText color="secondary" className={classes.dialogContent}>
            Are you sure you want to delete this node?
          </DialogContentText>
          <Box display="flex" justifyContent="space-between">
            <StandardBtn
              variant='outlined'
              color='secondary'
              onClick={handleCloseDialog}
            >
              Cancel
            </StandardBtn>
            <StandardBtn
              variant='contained'
              color='secondary'
              onClick={handleDelete}
            >
              Delete
            </StandardBtn>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  backButton: {
    ...theme.typography.body2,
    fontSize: '0.875rem',
    fontWeight: 'bold',
    textTransform: 'none',
    padding: theme.spacing(0.75, 0.25),
    margin: theme.spacing(1.5),
  },
  button: {
    padding: theme.spacing(1.25, 3),
    marginLeft: theme.spacing(2),
  },
  backArrow: {
    height: '1rem',
    width: '1rem',
    marginRight: theme.spacing(1),
  },
  formControl: {
    padding: theme.spacing(2, 3),
  },
  inputLabel: {
    paddingLeft: theme.spacing(4),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3),
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  dialogContent: {
    paddingBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(3),
  },
}))

export default NodeInfoForm
