import { Box, BoxProps, Grid, makeStyles, TextField, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { PoolSelectInput } from 'js/components/form'
import { TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { UpdatePoolProposalFormState } from 'js/models/Governance'
import { actions } from 'js/store'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UpdatePoolProposalInitialFormState } from '../constants'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import { updatePoolFormRequiredFields } from './Helpers/FormConstraints'

interface Props extends BoxProps {
}

const UpdatePoolMainForm: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const formState = (useRedux((state) => state.governance.submitProposalFormState) || UpdatePoolProposalInitialFormState) as UpdatePoolProposalFormState

  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    dispatch(setSubmitProposalFormState(formState))
    validateRequiredFields(formState)
    // eslint-disable-next-line
  }, [])

  const validateRequiredFields = (formState: UpdatePoolProposalFormState) => {
    const requiredFieldsConstraints = getRequiredFieldsConstraints(updatePoolFormRequiredFields)
    const errors = getErrorMessages(formState, requiredFieldsConstraints)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const autoGenerateTitle = (formKey: keyof UpdatePoolProposalFormState, inputValue: string): string => {
    const poolID = formKey === 'poolID' ? inputValue : formState.poolID

    return `Update for pool ${poolID}`
  }

  const handleFormChange = (key: keyof UpdatePoolProposalFormState, isTitleInvolved?: boolean) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      const newFormState = {
        ...formState,
        [key]: value,
        title: isTitleInvolved ? autoGenerateTitle(key, value) : formState.title,
      }

      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PoolSelectInput value={formState.poolID} onChange={handleFormChange('poolID', true)} />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
              fullWidth
              label="Swap Fee %"
              type="number"
              InputLabelProps={{ shrink: true }}
              value={formState.swapFee}
              onChange={handleFormChange('swapFee')}
              placeholder='0.1'
            />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
              fullWidth
              label="Number of Quotes"
              type="number"
              InputLabelProps={{ shrink: true }}
              value={formState.numQuotes}
              onChange={handleFormChange('numQuotes')}
              placeholder='1'
            />
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0, 3),
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1rem",
  },
  warningIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  menuItems: {
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

export default UpdatePoolMainForm

