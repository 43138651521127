import { TableRow } from '@material-ui/core'
import { TableCellInfo, TableSectionCell, TooltipHint } from 'js/components'
import { useRedux } from 'js/hooks'
import { FullTokenBalance } from 'js/models/Balance'
import React, { ReactElement } from 'react'

interface Props extends FullTokenBalance {
}

const HoldingsRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const sdk = useRedux(state => state.core.carbonSDK)
  const { total, staked, available, order, position, denom, supplied, collateralized, evm } = props
  const decimals = sdk?.token.getDecimals(denom) ?? 0
  const symbol = sdk?.token.getTokenName(denom) ?? denom

  const HoldingCells: TableCellInfo[] = [
    {
      align: 'inherit',
      content: (
        <TooltipHint title={denom}>
          <span>{symbol}</span>
        </TooltipHint>
      ),
      to: `/token/${encodeURIComponent(denom)}`,
    },
    {
      content: total.toFormat(decimals),
      bold: true
    },
    {
      content: available.toFormat(decimals),
    },
    {
      content: order.toFormat(decimals),
    },
    {
      content: position.toFormat(decimals),
    },
    {
      content: staked.toFormat(decimals),
    },
    {
      content: supplied.toFormat(decimals),
    },
    {
      content: collateralized.toFormat(decimals),
    },
    {
      content: evm.toFormat(),
    },
  ]

  return (
    <TableRow key={denom} hover>
      {HoldingCells.map((cell: any, index: number) => (
        <TableSectionCell
          key={index}
          {...cell}
        />
      ))}
    </TableRow>
  )
}

export default HoldingsRow
