import { Box, makeStyles, Theme } from '@material-ui/core'
import { SortIconDefault, SortIconDown, SortIconUp } from 'assets'
import BigNumber from 'bignumber.js'
import { ExtendedPool } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/query'
import { CommonAssetName } from 'carbon-js-sdk/lib/constant'
import { DataLoadSegment, Page, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import useSessionStorage from 'js/hooks/useSessionStorage'
import { switcheo } from 'js/theme/palettes/colors'
import { adjustHuman, BIG_ZERO, BN_ZERO, bnOrZero } from 'js/utils'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Long } from 'tradehub-api-js/build/main/lib/tradehub/providers'
import LiquidityPoolRow from './LiquidityPoolRow'
import PoolFilter, { FilterOption } from './PoolFilter'
import PoolSearcher from './PoolSearcher'

interface Props {
}

export type rowData = {
  id: Long,
  denom: string,
  symbolA: string,
  symbolB: string,
  isCdpTokenA: boolean,
  isCdpTokenB: boolean,
  weightA: BigNumber,
  weightB: BigNumber,
  chainA: string,
  chainB: string,
  amountABN: BigNumber,
  amountBBN: BigNumber,
  notionalTokenA: BigNumber,
  notionalTokenB: BigNumber,
  notionalAB: BigNumber,
  notionalVolume: BigNumber,
  marketName: string,
  market: string,
  apy: BigNumber
}

const AllPools: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const { pools, poolsVolumeMap, rewards } = useRedux((state) => state.pools)
  // const { markets } = useRedux((state) => state.app)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [loading] = useTaskSubscriber(TaskNames.Pools.List, TaskNames.Pools.PoolsVolume)
  const classes = useStyles()
  const pageLimitUnit = 9
  const [pageLoadLimit, setPageLoadLimit] = useSessionStorage("count", pageLimitUnit)
  let chainSet = new Set<string>();
  const [order, setOrder] = useState(-1);
  const [orderType, setOrderType] = useState('liquidity');
  const [chains, setChains] = useState(chainSet)

  const total = useMemo(() => {
    let result = BIG_ZERO
    pools.forEach((p: ExtendedPool) =>
      result = result.plus(new BigNumber(p.rewardsWeight))
    )
    return result
  }, [pools])

  const rowsData = useMemo(() => {
    const computeRowAttributes = (pool: ExtendedPool, chainSet: Set<string>) => {
      const {
        pool: poolInfo,
        rewardsWeight,
      } = pool
      const { id, denomA, denomB, amountA, amountB, denom } = poolInfo!
      const symbolA = sdk?.token.getTokenName(denomA) ?? "TokenA"
      const symbolB = sdk?.token.getTokenName(denomB) ?? "TokenB"
      let chainA = sdk?.token.getBlockchain(denomA) ?? "Terra"
      let chainB = sdk?.token.getBlockchain(denomB) ?? "Terra"
      const isCdpTokenA = denomA.includes("cibt/")
      const isCdpTokenB = denomB.includes("cibt/")

      const chainNameMap: {
        [index: string]: string
      } = {
        native: 'Carbon',
        eth: 'Ethereum',
        bsc: 'BSC',
        neo: 'Neo Legacy',
        zil: 'Zilliqa',
        btc: 'Osmosis',
        neo3: 'Neo N3'
      }

      if (chainNameMap[chainA]) {
        chainA = chainNameMap[chainA]
      }

      if (chainNameMap[chainB]) {
        chainB = chainNameMap[chainB]
      }
      chainSet.add(chainA)
      chainSet.add(chainB)
      const amountABN = sdk?.token.toHuman(denomA, bnOrZero(amountA)) ?? BN_ZERO
      const amountBBN = sdk?.token.toHuman(denomB, bnOrZero(amountB)) ?? BN_ZERO
      let volume = poolsVolumeMap[pool.pool?.id.toNumber()!] ?? BN_ZERO
      // const marketName = markets[market]?.displayName ?? market
      const swthUSD = sdk?.token.getUSDValue(CommonAssetName.swth) ?? BN_ZERO
      let weeklyRewards = rewards.times(rewardsWeight).div(total)
      if (weeklyRewards.isNaN()) {
        weeklyRewards = BN_ZERO
      }
      // const quoteCurrency = markets[market]?.base ?? ''
      // const quoteUSDBN = sdk?.token.getUSDValue(quoteCurrency) ?? BN_ZERO
      const notionalVolume = volume
      const notionalWeeklyRewards = swthUSD.times(weeklyRewards)
      const tokenAUSD = sdk?.token.getUSDValue(denomA) ?? BN_ZERO
      const notionalTokenA = amountABN.times(tokenAUSD)

      const tokenBUSD = sdk?.token.getUSDValue(denomB) ?? BN_ZERO
      const notionalTokenB = amountBBN.times(tokenBUSD)

      const notionalAB = notionalTokenA.plus(notionalTokenB)
      const apy = notionalAB.isZero() ? BN_ZERO : notionalWeeklyRewards.div(notionalAB).times(52).shiftedBy(2)

      const weightA = adjustHuman(poolInfo?.weightA ?? '').shiftedBy(2)
      const weightB = adjustHuman(poolInfo?.weightB ?? '').shiftedBy(2)
      let rowData: rowData = {
        id: id,
        denom: denom,
        symbolA: symbolA,
        symbolB: symbolB,
        isCdpTokenA: isCdpTokenA,
        isCdpTokenB: isCdpTokenB,
        weightA: weightA,
        weightB: weightB,
        chainA: chainA,
        chainB: chainB,
        amountABN: amountABN,
        amountBBN: amountBBN,
        notionalTokenA: notionalTokenA,
        notionalTokenB: notionalTokenB,
        notionalAB: notionalAB,
        notionalVolume: notionalVolume,
        marketName: '',
        market: '',
        apy: apy
      }
      return rowData
    }
    const populateRowData = () => {
      var data: rowData[] = [];
      var chainSet = new Set<string>();
      pools.forEach((p: ExtendedPool) => {
        data.push(computeRowAttributes(p, chainSet))
      })
      setChains(chainSet)
      return data
    }
    return populateRowData();
  }, [pools, poolsVolumeMap, sdk, rewards, total]);

  const [filters, setFilters] = useState<string[]>([])
  const [searchInput, setSearchInput] = useState('')
  const [filterdPools, setFilteredPools] = useState(rowsData)
  const [sortedPools, setSortedPools] = useState(rowsData)

  const sortOrder = useCallback(
    (option: string, order: number) => {
      let results = filterdPools;
      if (order === 0) {
        //default order by id
        results.sort((a: rowData, b: rowData) => {
          if (a.id.toNumber() > b.id.toNumber()) {
            return 1;
          } else {
            return -1;
          }
        })
      } else {
        results.sort((a: rowData, b: rowData) => {
          var first = BN_ZERO; var second = BN_ZERO;
          switch (option) {
            case 'liquidity': first = a.notionalAB; second = b.notionalAB;
              break;
            case 'volume': first = a.notionalVolume; second = b.notionalVolume;
              break;
            case 'rewards': first = a.apy; second = b.apy;
              break;
            default: first = BN_ZERO; second = BN_ZERO;
          }

          if (first.isGreaterThan(second)) {
            return order;
          } else {
            return -(order);
          }
        })
      }
      setSortedPools([...results])
    }, [filterdPools]
  );

  useEffect(() => {
    setFilteredPools(rowsData)
  }, [rowsData])

  useEffect(() => {
    sortOrder(orderType, order)
  }, [orderType, order, sortOrder])

  useEffect(() => {
    let filteredData = rowsData;
    if (filters.length !== 0) {
      filteredData = rowsData.filter(
        (rowData) => {
          return filters.includes(rowData.chainA) || filters.includes(rowData.chainB)
        }
      )
    }
    if (searchInput !== '') {
      filteredData = filteredData.filter(
        (rowData) => {
          return rowData.symbolB?.toUpperCase()?.includes(searchInput?.toUpperCase()) || rowData.symbolA?.toUpperCase()?.includes(searchInput?.toUpperCase()) || sdk?.token?.getSymbol(rowData.denom)?.toUpperCase()?.includes(searchInput?.toUpperCase()) || rowData.denom?.toLowerCase().includes(searchInput?.toLowerCase()) || rowData.marketName.includes(searchInput) || rowData.id.toString().includes(searchInput)
        }
      )
    }
    setFilteredPools(filteredData)
  }, [rowsData, filters, searchInput, sdk])

  const changeOrder = (type: string) => {
    setOrderType(type);
    if (type !== orderType || order === 0) {
      setOrder(-1); // descending order
    } else if (order === -1) {
      setOrder(1); // ascending order
    } else {
      setOrder(0);
    }
  }

  const onFilter = (filters: string[]) => {
    // function passed to the selection drop down for filter
    setFilters(filters);
  }
  const onSearch = (searchInput: string) => {
    setSearchInput(searchInput)
  }

  const mapTypeToOption = (type: string): FilterOption => {
    return {
      value: type,
      label: type
    }
  }

  const onLoadMore = () => {
    let newPageLimit = pageLoadLimit + pageLimitUnit > pools.length ? pools.length : pageLoadLimit + pageLimitUnit
    setPageLoadLimit(newPageLimit)
  }

  return (
    <Page title="All Pools" rightMenu={
      <Box className={classes.rightMenuWrapper}>
        <PoolFilter
          onFilter={onFilter}
          options={[...chains].map(mapTypeToOption)}
        />
        <PoolSearcher onSearch={onSearch} />
      </Box>
    }>
      <DataLoadSegment loading={loading && chains.size === 0}>
        <div className={classes.poolListContainer}>
          <div className={classes.poolListHeader}>
            <span style={{ width: '350px' }}>Pool</span>
            <span style={{ width: '265px' }}>Token Liquidity</span>
            <div className={classes.poolListSortHeader} onClick={() => changeOrder('liquidity')}>
              Total Value
              {
                orderType !== 'liquidity' || order === 0
                  ? <SortIconDefault />
                  : order === 1 ? <SortIconUp /> : <SortIconDown />
              }
            </div>
            <div className={classes.poolListSortHeader} onClick={() => changeOrder('volume')}>24H Volume
              {
                orderType !== 'volume' || order === 0
                  ? <SortIconDefault />
                  : order === 1 ? <SortIconUp /> : <SortIconDown />
              } </div>
            <div className={classes.poolListSortHeader} onClick={() => changeOrder('rewards')}>Rewards APY {
              orderType !== 'rewards' || order === 0
                ? <SortIconDefault />
                : order === 1 ? <SortIconUp /> : <SortIconDown />
            } </div>
            <span style={{ width: '250px' }}>Market</span>
          </div>
          {sortedPools.map((rowData: any, index) => (
            index < pageLoadLimit ?
              <LiquidityPoolRow
                key={rowData.denom ?? index}
                data={rowData}
                loadMore={index === pageLoadLimit - 1}
                loadedAll={index === pools.length - 1}
                onLoadMore={onLoadMore}
              /> : undefined
          ))}
        </div>
        {!sortedPools.length && (
          <TableEmptyState itemName="pools" />
        )}
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    marginBottom: theme.spacing(2),
  },
  rightMenuWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  chartTitle: {
    display: 'block',
  },
  chartTitleFirst: {
    fontWeight: 'bold',
  },
  tooltip: {
    pointer: 'cursor',
    align: 'center',
  },
  chartTitleBox: {
    paddingBottom: theme.spacing(2),
  },
  chartTitleBlock: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  chartTitleBoxVolumeLiquidity: {
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  rightPadding: {
    paddingRight: theme.spacing(2),
  },
  poolListContainer: {
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none'
  },
  poolListHeader: {
    display: 'flex',
    minWidth: '1200px',
    padding: '13px 32px',
    fontWeight: 400,
    fontSize: 14,
    color: switcheo.text.grey,
    verticalAlign: 'middle',
    lineHeight: '20px'
  },
  poolListSortHeader: {
    width: '125px',
    cursor: 'pointer',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    display: 'flex',
  }
}))

export default AllPools
