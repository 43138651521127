import { makeStyles, Theme, Tooltip, TooltipProps } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

type Props = Partial<TooltipProps> & {
  title: string | ReactElement
  iconHint?: boolean
}

const TooltipHint: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const {
    className,
    children,
    iconHint,
    ...rest
  } = props
  const classes = useStyles(props)

  return (
    <Tooltip arrow placement='top' {...rest} className={clsx(classes.root, className)}>
      {!children ? <HelpIcon className={classes.icon} /> : children }
    </Tooltip>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  icon: {
    verticalAlign: 'middle',
    height: '.6em',
    width: '.6em',
    marginLeft: theme.spacing(.5),
    marginBottom: theme.spacing(.5),
  },
}))

export default TooltipHint
