import { Box, BoxProps, Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { fetchSubspaceParams, setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { ProposalParameterLabel, ProposalTopicLabel, TooltipHint } from 'js/components'
import { ProposalTypes, TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { ParameterChangeProposalFormState, ParameterDetail } from 'js/models/Governance'
import { actions } from 'js/store'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ParameterChangeProposalInitialFormState } from '../constants'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import { requiredFields } from './Helpers/FormConstraints'
import { Module, Modules, getParamType } from './Helpers/Modules'


interface Props extends BoxProps {
}

type ReplacementParams = {
  [index: string]: string[]
}
const replaceParams: ReplacementParams = {
  'gov:depositparams': [
    'depositparams-min_deposit',
    'depositparams-max_deposit_period',
  ],
  'gov:votingparams': [
    'votingparams-voting_period',
  ],
}

const ParameterChangeProposalMainForm: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const formState = (useRedux((state) => state.governance.submitProposalFormState) || ParameterChangeProposalInitialFormState) as ParameterChangeProposalFormState
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [module, setModule] = useState<Module | null>(null)

  const defaultParams: ParameterDetail = {
    topic: '',
    parameter: '',
    value: '',
  }
  const [currentParamIndex, setCurrentParamIndex] = useState(0)
  const requiredFieldsConstraints = getRequiredFieldsConstraints(requiredFields)
  const curParam = formState.parameters[currentParamIndex]
  const isCurrentParamValid = getErrorMessages(formState.parameters[currentParamIndex], requiredFieldsConstraints).length === 0

  const updateFormState = (paramDetail: ParameterDetail, paramIndex: number) => {
    const parameters = formState.parameters
    parameters[paramIndex] = paramDetail
    const newFormState = {
      ...formState,
      title: autoGenerateTitle(parameters),
      parameters,
    }
    dispatch(setSubmitProposalFormState(newFormState))
  }

  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // set default parameter if form state is empty
    if (formState?.parameters.length === 0) {
      dispatch(setSubmitProposalFormState({
        ...formState,
        parameters: [defaultParams],
      }))
    }
    if (curParam?.topic) {
      const module = Modules.find((m: Module) => m.name === curParam.topic) || null
      setModule(module)

      if (module && sdk) {
        dispatch(fetchSubspaceParams(module.name))
      }

      if (module && !module.params.includes(curParam.parameter)) {
        // clear parameter if module is changed
        const newParams = {
          ...curParam,
          parameter: '',
          value: '',
        }
        updateFormState(newParams, currentParamIndex)
      }
    }
    // eslint-disable-next-line
  }, [curParam?.topic, formState?.parameters])

  const autoGenerateTitle = (paramDetails: ParameterDetail[]): string => {
    if (paramDetails.length === 0) {
      return ''
    }
    let title = ''
    paramDetails.forEach((param, index) => {
      const parameterType = getParamType(param.parameter)
      let value = param.value
      if (parameterType && value) {
        switch (parameterType.type) {
          case 'coin':
            value += ' SWTH'
            break

          case 'percent':
            value += '%'
            break

          case 'time':
            value += ' hours'
            break
        }
      }
      const friendlyParameterLabel = ProposalTypes.Parameters[param.parameter || ''] || param.parameter
      title = title.concat(`${index > 0 ? ' And' : 'Change'} ${friendlyParameterLabel} to ${value}`)
    })

    return title
  }

  const validateRequiredFields = (formState: ParameterChangeProposalFormState) => {
    const parameterDetails = formState.parameters

    let errors: string[] = []
    parameterDetails.forEach((paramDetail) => {
      const error = getErrorMessages(paramDetail, requiredFieldsConstraints)
      errors = errors.concat(error)
    })

    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const handleFieldChange = (key: keyof ParameterDetail, paramIndex: number) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      const params = formState.parameters

      if (!value) {
        return
      }

      setCurrentParamIndex(paramIndex)

      const newParams = {
        ...params[paramIndex],
        [key]: value,
      }
      params[paramIndex] = newParams

      const newFormState = {
        ...formState,
        title: autoGenerateTitle(params),
        parameters: params,
      }
      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  const handleAddParams = () => {
    setCurrentParamIndex(currentParamIndex + 1)
    const updatedParameters = formState.parameters.concat({
      topic: '',
      parameter: '',
      value: '',
    })
    const newFormState = {
      ...formState,
      parameters: updatedParameters,
    }

    dispatch(setSubmitProposalFormState(newFormState))
    validateRequiredFields(newFormState)
  }


  const generateParams = (module: Module): string[] => {
    return module.params.reduce<string[]>((outputParams, currentParam): string[] => {
      const paramKey = `${module.name}:${currentParam}`

      // replacement found, use replacement params
      if (replaceParams[paramKey]) {
        return outputParams.concat(...replaceParams[paramKey])
      }

      // no replacement found, use current param
      return [...outputParams, currentParam]
    }, [])
  }

  const valueInput = (parameterDetail: ParameterDetail, paramIndex: number) => {
    if (!parameterDetail) {
      return (
        <TextField label='Value' value='-' fullWidth disabled />
      )
    }
    const parameterType = getParamType(parameterDetail.parameter)

    switch (parameterType?.type) {
      case 'uint64':
      case 'uint32':
      case 'int64':
      case 'uint16':
      case 'percent':
      case 'coin':
      case 'time':
      case 'number':
      case 'string': {
        return (
          <TextField
            fullWidth
            label="Value"
            InputLabelProps={{ shrink: true }}
            InputProps={parameterType.inputProps}
            value={parameterDetail.value}
            onChange={handleFieldChange('value', paramIndex)}
            placeholder={parameterType.example}
          />
        )
      }
      case 'bool': {
        return (
          <FormControl fullWidth>
            <InputLabel shrink>Value</InputLabel>
            <Select
              displayEmpty
              placeholder="Select Value"
              value={parameterDetail.value}
              onChange={handleFieldChange('value', paramIndex)}
              MenuProps={{
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="true" className={classes.menuItems}>True</MenuItem>
              <MenuItem value="false" className={classes.menuItems}>False</MenuItem>
            </Select>
          </FormControl>
        )
      }
      default: {
        return (
          <TextField label='Value' value='Not Available' fullWidth disabled />
        )
      }
    }
  }

  const parameterForm = (paramDetail: ParameterDetail, paramIndex: number) => {
    let paramModule = module
    if (paramDetail?.topic) {
      paramModule = Modules.find((m: Module) => m.name === paramDetail.topic) || null
    }
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Topic
              <TooltipHint title="Choose the module that is responsible for the parameter you want to change." />
            </InputLabel>
            <Select
              displayEmpty
              placeholder="Select Module"
              value={paramDetail?.topic}
              onChange={handleFieldChange('topic', paramIndex)}
              MenuProps={{
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className={classes.menuItems} value="" disabled>
                Select Module
              </MenuItem>
              {Modules.map((m: Module, index: number) => (
                <MenuItem key={index} value={m.name} className={classes.menuItems}>
                  <ProposalTopicLabel>{m.name}</ProposalTopicLabel>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Parameter
              <TooltipHint title="Parameter represents the function of Carbon. Choose the parameter that you desire to alter." />
            </InputLabel>
            <Select
              displayEmpty
              placeholder="Select Parameter"
              value={paramDetail?.parameter}
              onChange={handleFieldChange('parameter', paramIndex)}
              MenuProps={{
                getContentAnchorEl: null,
              }}
            >
              {!paramModule && (
                <MenuItem value="" disabled className={classes.menuItems}>
                  Enter topic first
                </MenuItem>
              )}
              {paramModule && (
                <MenuItem value="" disabled className={classes.menuItems}>
                  Select Parameter
                </MenuItem>
              )}
              {!!paramModule &&
                generateParams(paramModule).map((param, index) => (
                  <MenuItem key={index} value={param} className={classes.menuItems}>
                    <ProposalParameterLabel>{param}</ProposalParameterLabel>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          {valueInput(paramDetail, paramIndex)}
        </Grid>
      </Grid>
    )
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h3">
            Deposit
          </Typography>
        </Grid>
        {
          formState.parameters.map((paramDetail, index) => {
            return (
              <Grid key={`param-change-proposal-${index}`} item xs={12}>
                {parameterForm(paramDetail, index)}
              </Grid>
            )
          })
        }
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleAddParams}
            name="previous"
            disabled={!isCurrentParamValid}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  menuItems: {
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
}))

export default ParameterChangeProposalMainForm
