import { Theme, makeStyles } from '@material-ui/core'
import { UpdateInternalTransfer } from 'js/actions/account'
import { TaskNames } from 'js/constants'
import { RootState } from 'js/store'
import React, { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import InternalTransfer from './InternalTransfer'

export interface InternalTransferProp {
  address: string
}

interface Props extends InternalTransferProp { }

const InternalTransfersWrapper: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const { address } = props
  const [resetPage, setResetPage] = useState(false)
  // const [filters, setFilters] = useState<{ [index: string]: boolean }>()

  const sdk = useSelector((state: RootState) => state.core.carbonSDK)

  const classes = useStyles()

  return (
    <>
      <InternalTransfer
        title="Internal Transfers"
        sdk={sdk}
        action={UpdateInternalTransfer}
        filterStatus
        loadName={[
          TaskNames.Account.InternalTransfer,
        ]}
        sectionClass={classes.transactions}
        resetPage={resetPage}
        setPageReset={setResetPage}
        address={address}
      // rightMenu={
      //   <TransactionFilter
      //     className={classes.filter}
      //     onFilter={onFiltersChange}
      //     filters={filters}
      //     options={types.map(mapTypeToOption)}
      //   />
      // }
      />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  transactions: {
    padding: theme.spacing(3),
  },
  filter: {
    marginBottom: theme.spacing(2),
  },
}))

export default InternalTransfersWrapper
