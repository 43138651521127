import { Box, BoxProps, Button, Divider, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { GovUtils } from 'carbon-js-sdk'
import { setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { StandardBtn, StatefulButton } from 'js/components'
import { useRedux } from 'js/hooks'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DetailsStepFormConstraints } from '../Forms/DetailsStep'
import { getErrorMessages, getInputConstraints } from '../Forms/Helpers/InputConstraints'
import ProposalStepProgress from './ProposalStepProgress'

interface Props extends BoxProps {
  steps: React.ReactNode[]
  titles: string[]
  type: GovUtils.ProposalTypes | ''
  subtitles?: string[]
  onSubmit: () => void
  noSubmit?: boolean
  config?: any
  customValidator?: (formState: any) => string[]
}

const Wizard: React.FC<Props> = (props: Props) => {
  const { children, className, steps, onSubmit, titles, subtitles,
    noSubmit, type, config, customValidator, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const formState = useRedux((state) => state.governance.submitProposalFormState)
  const validationErrors = useRedux((state) => state.governance.submitProposalFormValidationErrors)
  const submitError = useRedux((state) => state.governance.submitProposalError)
  const isSubmitting = useRedux((state) => state.governance.isSubmittingProposal)
  const wallet = useRedux((state) => state.core.carbonSDK?.wallet)
  const [stepNum, setStepNum] = useState(0)
  const isLastPage = stepNum === steps.length - 1
  const activePage = steps[stepNum]
  const disableNext = useMemo(() => {
    if (stepNum > 0) {
      return validationErrors.length > 0
    }
    return false
  }, [stepNum, validationErrors])

  const handleBack = () => {
    const newStep = stepNum - 1
    setStepNum(newStep)
  }

  const handleNext = () => {
    if (stepNum === 1 && steps.length === 4 && formState) {
      const inputConstraints = !!config ? getInputConstraints(config) : {}
      const errors = !!customValidator
        ? customValidator(formState)
        : getErrorMessages(formState, inputConstraints)

      dispatch(setSubmitProposalFormValidationErrors(errors))
      if (errors.length > 0) return
    }
    const newStep = stepNum + 1
    setStepNum(newStep)
    if (newStep === 2 && steps.length === 4) {
      const errors = getErrorMessages(formState, DetailsStepFormConstraints)
      dispatch(setSubmitProposalFormValidationErrors(errors))
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <ProposalStepProgress className={classes.stepProgress} currentStep={stepNum} stepTitles={titles} />

      <Box className={classes.dividerBox}>
        <Divider />
      </Box>
      <Box className={classes.titleBox}>
        <Typography className={subtitles?.[stepNum] ? '' : classes.title} variant="h2">
          {titles[stepNum]}
        </Typography>
      </Box>
      {
        subtitles?.[stepNum] && (
          <Box className={classes.subtitleBox}>
            <Typography className={classes.title} variant="h4">
              {subtitles[stepNum]}
            </Typography>
          </Box>
        )
      }
      {activePage}

      {/* Errors */}
      {!!submitError && (
        <Box className={classes.titleBox}>
          <Typography color='error'>{submitError.message}</Typography>
        </Box>
      )}
      <Box className={classes.titleBox}>
        {
          validationErrors.map((msg) =>
            msg.includes("can't be blank")
              ? null
              : (
                <Typography color='error'>{msg}</Typography>
              ),
          )
        }
      </Box>

      {/* Buttons */}
      <Box className={classes.actions}>
        {!(stepNum === 0) && wallet && (
          <Button
            className={classes.button}
            onClick={handleBack}
            disabled={stepNum <= 0}
            variant="contained"
            color="secondary"
          >
            Previous
          </Button>
        )}
        {!wallet && (
          <StandardBtn
            to="/login"
            variant="contained"
            color="secondary"
          >
            <Typography variant="h3" className={classes.loginLabel}>Login to submit a proposal</Typography>
          </StandardBtn>
        )}
        <Box flex={1} />
        {!isLastPage && wallet && (
          <Button
            className={classes.button}
            onClick={handleNext}
            variant="contained"
            disabled={disableNext}
            color="secondary"
          >
            Next
          </Button>
        )}
        {isLastPage && wallet && !noSubmit && (
          <StatefulButton
            className={classes.button}
            variant="contained"
            color="secondary"
            loading={isSubmitting}
            onClick={onSubmit}
          >
            <Typography variant="h3">Submit</Typography>
          </StatefulButton>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    display: 'flex',
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 0),
    },
  },
  button: {
    marginBottom: theme.spacing(1),
  },
  dividerBox: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(4),
    },
  },
  stepProgress: {
    margin: theme.spacing(4, 0, 6),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0, 3.5),
    },
  },
  subtitleBox: {
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 0,
      marginTop: theme.spacing(1.5),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  titleBox: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(0),
    },
  },
  loginLabel: {
    marginRight: theme.spacing(2),
  },
}))

export default Wizard
