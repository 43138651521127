import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

type Props = BoxProps & {
  header?: string
  value?: string
}

const DataRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { header, value, className, children, ...rest } = props
  const classes = useStyles(props)

  return (
    <Box
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant='body1'>{header}</Typography>
      <Box flex={1} />
      <Typography variant='body1'>{value}</Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.5, 0),
    display: 'flex',
  },
}))

export default DataRow
