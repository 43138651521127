import { makeStyles, TableRow, Theme } from '@material-ui/core'
import { TokenUtils } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { TableCellInfo, TableSectionCell } from 'js/components'
import { useRedux } from 'js/hooks'
import React, { ReactElement } from 'react'

type Props = {
  model: Market
}

const MarketsRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> | null => {
  const { model } = props

  const classes = useStyles()
  const sdk = useRedux(state => state.core.carbonSDK)
  const symbolOverride = model.marketType === 'futures' ? TokenUtils.FuturesDenomOverride : undefined
  const baseSymbol = sdk?.token.getTokenName(model.base ?? '', symbolOverride).toUpperCase() ?? ''
  const quoteSymbol = sdk?.token.getTokenName(model.quote ?? '', symbolOverride).toUpperCase() ?? ''

  const expiryDayjs = dayjs(model.expiryTime)
  let dateShort: string = expiryDayjs.format('DD MMM YYYY')
  const now: Dayjs = dayjs()
  const diffInYears: number = now.diff(expiryDayjs, 'year')
  if (diffInYears > 10) {
    dateShort = '-'
  }

  // if (!model.isActive) {
  //   return null
  // }

  const formatDisplayName = () => {
    if (model.marketType === 'spot') {
      return `${baseSymbol} / ${quoteSymbol}`
    }
    if (model.marketType === 'futures' && (model.id.includes("PERP") || (model.displayName.includes("PERP")))) {
      return `${baseSymbol} - PERP`
    }
    if (model.marketType === 'futures' && !model.id.includes("PERP")) {
      return `${baseSymbol} - ${dateShort}`
    }
    return ''
  }

  const MarketCells: TableCellInfo[] = [
    {
      align: 'inherit',
      className: classes.displayName,
      content: formatDisplayName(),
      to: `/market/${encodeURIComponent(model.id)}`,
    },
    {
      align: 'inherit',
      content: model.id,
    },
    {
      align: 'inherit',
      content: model.marketType,
    },
    {
      content: model.lotSize,
    },
    {
      content: model.tickSize,
    },
    {
      content: model.minQuantity,
    },
    // {
    //   content: percentFormat(model.makerFee),
    // },
    // {
    //   content: percentFormat(model.takerFee),
    // },
    {
      content: model.indexOracleId.length === 0 ? '-' : model.indexOracleId,
    },
    {
      align: 'inherit',
      content: booleanFormat(model.isActive),
    },
    {
      align: 'inherit',
      content: booleanFormat(model.isSettled),
    },
    {
      content: model.expiryTime,
      dateBoxClass: classes.dateBox,
      type: 'date',
    },
    {
      content: model.createdBlockHeight.toString(),
      to: `/block/${model.createdBlockHeight.toString()}`,
    }
  ]

  return (
    <TableRow hover>
      {MarketCells.map((cell: TableCellInfo, index: number) => (
        <TableSectionCell
          className={clsx(classes.tableClass, cell.className)}
          key={index}
          {...cell}
        />
      ))}
    </TableRow>
  )
}

// const percentFormat = (value: string | number) => {
//   if (typeof value === 'string') {
//     value = parseFloat(value)
//   }

//   if (value) {
//     return `${new BigNumber(value).times(100).toFormat(3)}%`
//   }
//   return '-'
// }

const booleanFormat = (value: any) => {
  return value ? 'true' : 'false'
}

const useStyles = makeStyles((theme: Theme) => ({
  dateBox: {
    display: 'block',
    minHeight: '2.25rem',
  },
  displayName: {
    minWidth: '9.6rem',
    paddingRight: '1.15rem',
  },
  tableClass: {
    padding: '0.5rem 1.15rem 0.5rem 0.7rem',
  },
}))

export default MarketsRow
