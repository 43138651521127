import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import CellLink from 'js/components/CellLink'
import { Paths } from 'js/constants'
import React, { ReactElement } from 'react'
import { ReactComponent as ArtworkSvg } from './art-voting-power.svg'

type Props = BoxProps

const Step3: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, className, children, ...rest } = props
  const classes = useStyles(props)

  return (
    <Box
      {...rest}
      className={clsx(classes.root, className)}
    >
      <ArtworkSvg className={classes.artwork} />
      <Typography variant='body1' paragraph>
        The amount of SWTH you delegate to validators determines your <strong>voting power</strong>.
        The more tokens you delegate, the bigger your influence when you vote on a proposal.
        Do note that deposits are not included in your voting power.
      </Typography>
      <Typography variant='body1' paragraph>
        There are four voting options for every proposal and you can only choose ONE option: <strong>
          Yes, No, No With Veto and Abstain</strong>.
        Learn more about each of the voting options{' '}
        <CellLink
          underline='hover'
          href={Paths.Resources.HowToVote}
        >
          here
        </CellLink>.
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  artwork: {
    display: 'block',
    margin: '36px auto 54px auto',
    maxWidth: '15rem',
    width: '100%',
  },
}))

export default Step3
