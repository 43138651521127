import { makeStyles, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import { CarbonSDK } from 'carbon-js-sdk'
import { TradeEvent } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/broker/event'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import dayjs from 'dayjs'
import { CellLink, ListTable } from 'js/components'
import { capitalizeFirstLetter } from 'js/helpers'
import { shorten } from 'js/helpers/utils'
import { useRedux } from 'js/hooks'
import { adjustHuman, BIG_ZERO, bnOrZero, SimpleMap } from 'js/utils'
import React, { ReactElement, ReactNode } from 'react'

interface Props {
  trades: TradeEvent[]
  type?: string
  takerOrMaker?: string
  address?: string
}

const RelatedTrades: React.FunctionComponent<Props> = (props): ReactElement<Props> => {
  const classes = useStyles()
  const { trades, type, address } = props
  const sdk = useRedux((state) => state.core.carbonSDK)
  const markets = useRedux((state) => state.app.markets)
  return (
    <>
      <ListTable>
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Side</TableCell>
            {type && (
              <TableCell>Type</TableCell>
            )}
            <TableCell>Taker/Maker</TableCell>
            <TableCell align="right">Price</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Counter Order ID</TableCell>
            <TableCell>Counter Party Address</TableCell>
            <TableCell align="right">Match at</TableCell>
            <TableCell>Height</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{trades.map((trade: TradeEvent) => renderTrade(trade, type, classes, markets, sdk, address))}</TableBody>
      </ListTable>
    </>
  )
}

function renderTrade(
  trade: TradeEvent,
  type: string | undefined,
  classes: ClassNameMap,
  markets: SimpleMap<Market>,
  sdk?: CarbonSDK,
  address?: string
): ReactNode {
  const {
    blockCreatedAt,
    blockHeight,
    takerSide,
    makerSide,
    takerId,
    makerId,
    makerAddress,
    takerAddress,
    quantity,
    price,
    marketId,
  } = trade
  const takerOrMaker = makerAddress === address ? "maker" : "taker"
  const time = dayjs(blockCreatedAt)
  const priceBN = adjustHuman(trade.price).shiftedBy(markets?.[marketId]?.basePrecision.sub(markets?.[marketId]?.quotePrecision).toNumber())
  const quantityBN = sdk?.token.toHuman(markets?.[marketId].base, bnOrZero(trade.quantity)) ?? BIG_ZERO
  const orderId = takerOrMaker === "taker" ? takerId : makerId
  const counterId = takerOrMaker === "taker" ? makerId : takerId
  const counterAddress = takerOrMaker === "taker" ? makerAddress : takerAddress

  return (
    <TableRow key={orderId + quantity + price + blockCreatedAt + takerId + makerId} hover>
      <TableCell>
        <CellLink to={`/order/${orderId}`}>
          <span className={classes.longString}>
            {shorten(orderId)}
          </span>
        </CellLink>
      </TableCell>
      <TableCell>
        {capitalizeFirstLetter(takerOrMaker === "taker" ? takerSide : makerSide)}
      </TableCell>
      {
        type && (
          <TableCell>
            {capitalizeFirstLetter(type)}
          </TableCell>
        )
      }
      <TableCell>
        {capitalizeFirstLetter(takerOrMaker)}
      </TableCell>
      <TableCell align="right">
        {priceBN.toString()}
      </TableCell>
      <TableCell>
        {quantityBN.toString()}
      </TableCell>
      <TableCell>
        <CellLink to={`/order/${counterId}`}>
          <span className={classes.longString}>
            {shorten(counterId)}
          </span>
        </CellLink>
      </TableCell>
      <TableCell>
        <CellLink to={`/account/${counterAddress}`}>
          <span className={classes.longString}>
            {shorten(counterAddress)}
          </span>
        </CellLink>
      </TableCell>
      <TableCell align="right">
        <span className={classes.longString}>
          {time.format('YYYY-MM-DD HH:mm:ss')}
        </span>
      </TableCell>
      <TableCell>
        <CellLink to={`/block/${blockHeight.toString()}`}>{blockHeight.toString()}</CellLink>
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  longString: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flex: 1,
    overflow: 'hidden',
    display: 'inline-block'

  },
}))
export default RelatedTrades
