import React from 'react'
import { toast } from 'react-toastify'
import Notifcation from './Notification'

export default function customToast(title: string, message: string): void {
  const notif = (
    <Notifcation title={title} message={message} />
  )
  toast(notif, { position: "top-right", autoClose: 3000, closeOnClick: true })
}
