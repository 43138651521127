import { updatePositions, updatePositionsHistory } from 'js/actions/account'
import { Positions } from 'js/components'
import { TaskNames } from 'js/constants'
import { RootState } from 'js/store'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'

export interface PositionsProps {
}

interface Props extends PositionsProps { }

const PositionsWrapper: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const positions = useSelector((state: RootState) => state.account.positions)
  const positionsHistory = useSelector((state: RootState) => state.account.positionsHistory)
  return (
    <>
      <Positions
        title="Positions"
        action={updatePositions}
        positions={positions}
        loadName={[
          TaskNames.Account.Positions
        ]}
      />
      <Positions
        title="Positions History"
        action={updatePositionsHistory}
        positions={positionsHistory}
        closedOnly={true}
        loadName={[
          TaskNames.Account.PositionsHistory
        ]}
      />
    </>
  )
}

export default PositionsWrapper
