import { makeStyles, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core'
import { MsgGasCost } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/fee/fee'
import { DataLoadSegment, ListTable, Section } from 'js/components'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { bnOrZero } from 'js/utils'
import React, { ReactElement } from 'react'

// tslint:disable:jsx-no-lambda
interface Props {
  fees: MsgGasCost[]
  loadName: string[]
  title: string
}

const GasFees: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { fees, loadName, title } = props
  const classes = useStyles()
  const [loading] = useTaskSubscriber(...loadName)
  const sdk = useRedux((state) => state.core.carbonSDK)
  return (
    <Section className={classes.root} title={title}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Transaction Type</TableCell>
              <TableCell>Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fees.map((fee) => {
              const feeAmount = sdk?.token.toHuman('swth', bnOrZero(fee.gasCost))
              return (
                <TableRow key={fee.msgType}>
                  <TableCell>
                    {fee.msgType.replaceAll('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                  </TableCell>
                  <TableCell>
                    {feeAmount?.toString()} SWTH
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </ListTable>
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1, 3, 3),
  },
}))

export default GasFees

