import { Box, BoxProps, Divider, makeStyles, Theme } from '@material-ui/core'
import TimeIcon from '@material-ui/icons/AccessTime'
import { Proposal, ProposalStatus } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/gov'
import clsx from 'clsx'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: 'a few seconds',
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
})

type Props = BoxProps & {
  proposal: Proposal
}

const PeriodTag: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, children, proposal, ...rest } = props
  const classes = useStyles(props)

  const depositEndTime = proposal && dayjs(proposal.depositEndTime?.valueOf())
  const voteEndTime = proposal && dayjs(proposal.votingEndTime?.valueOf())

  const isVotingPeriod =
    proposal.status === ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD
  const isPassed = proposal.status === ProposalStatus.PROPOSAL_STATUS_PASSED
  const isRejected =
    proposal.status === ProposalStatus.PROPOSAL_STATUS_REJECTED

  const colorClass = {
    [classes.success]: isPassed,
    [classes.warning]: isVotingPeriod,
    [classes.disabled]: isRejected,
  }

  const getPeriodLabel = (status: ProposalStatus) => {
    switch (status) {
      case ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD:
        return 'Voting Period'
      case ProposalStatus.PROPOSAL_STATUS_PASSED:
        return 'Passed'
      case ProposalStatus.PROPOSAL_STATUS_REJECTED:
        return 'Rejected'
      case ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD:
      default:
        return 'Deposit Period'
    }
  }

  const getTimeLeftLabel = (proposal: Proposal) => {
    switch (proposal.status) {
      case ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD:
        return `${voteEndTime.fromNow(true)} left`
      case ProposalStatus.PROPOSAL_STATUS_PASSED:
      case ProposalStatus.PROPOSAL_STATUS_REJECTED:
        return `Ended ${voteEndTime?.format('D MMMM YYYY')}`
      case ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD:
      default:
        return `${depositEndTime.fromNow(true)} left`
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className, colorClass)}>
      <Box className={classes.container}>
        {getPeriodLabel(proposal.status)}
        <Divider orientation="vertical" flexItem />
        <TimeIcon /> {getTimeLeftLabel(proposal)}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    padding: theme.spacing(0.5, 1.5),
    backgroundColor: switcheo.primary[100],
    color: switcheo.primary[500],
    fontSize: '0.75rem',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      marginRight: theme.spacing(0.5),
    },
    '& .MuiDivider-root': {
      margin: theme.spacing(0.5, 1.5),
      backgroundColor: switcheo.primary[500],
      opacity: 0.25,
    },
  },
  warning: {
    backgroundColor: switcheo.warning.light,
    color: switcheo.warning.primary,
    '& .MuiDivider-root': {
      backgroundColor: switcheo.warning.primary,
    },
  },
  success: {
    backgroundColor: switcheo.success.light,
    color: switcheo.success.primary,
    '& .MuiDivider-root': {
      backgroundColor: switcheo.success.primary,
    },
  },
  disabled: {
    backgroundColor: switcheo.background.grey,
    color: switcheo.text.primary,
    '& .MuiDivider-root': {
      backgroundColor: switcheo.text.primary,
    },
  },
}))

export default PeriodTag
