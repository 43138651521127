import { BlockResponse } from 'js/reducers/blocks'
import { Action } from 'redux'

export enum BlocksActionType {
  CLEAR = 'CLEAR_BLOCKS',
  RECEIVE_BLOCK = 'RECEIVE_BLOCKS_BLOCK',
  SET_BLOCKS = 'SET_BLOCKS_BLOCKS',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
}

export type BlocksAction = ClearAction | SetBlocksAction | UpdateFiltersAction

export interface ClearAction extends Action<BlocksActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: BlocksActionType.CLEAR,
  }
}

export interface SetBlocksAction extends Action<BlocksActionType.SET_BLOCKS> {
  blocks: BlockResponse
}
export function setBlocks(
  blocks: BlockResponse,
): SetBlocksAction {
  return {
    type: BlocksActionType.SET_BLOCKS,
    blocks,
  }
}

export interface UpdateFiltersAction
  extends Action<BlocksActionType.UPDATE_FILTERS> {
  options: any
}

export function updateFilters(options: any): UpdateFiltersAction {
  return {
    type: BlocksActionType.UPDATE_FILTERS,
    options,
  }
}
