import { Token, TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { Action } from 'redux'

export enum TokensActionType {
  CLEAR = 'CLEAR_TOKENS',
  SET_TOKENS_BALANCES = 'SET_TOKENS_BALANCES',
  SET_TOKENS_INFO = 'SET_TOKENS_INFO',
}

export type TokensAction =
  | ClearAction
  | SetTotalBalancesAction
  | SetTokensInfoAction

export interface ClearAction extends Action<TokensActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: TokensActionType.CLEAR,
  }
}

export interface SetTotalBalancesAction
  extends Action<TokensActionType.SET_TOKENS_BALANCES> {
  total: TokenBalance[]
}

export function setTotalBalances(total: TokenBalance[]): SetTotalBalancesAction {
  return {
    type: TokensActionType.SET_TOKENS_BALANCES,
    total,
  }
}

export interface SetTokensInfoAction
  extends Action<TokensActionType.SET_TOKENS_INFO> {
  info: Token[]
}

export function setTokensInfo(info: Token[]): SetTokensInfoAction {
  return {
    type: TokensActionType.SET_TOKENS_INFO,
    info,
  }
}
