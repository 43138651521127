import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, Section, TableEmptyState } from 'js/components'
import PaginationByData from 'js/components/PaginationByData'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { BN_ZERO, SHIFT_DECIMALS } from 'js/utils'
import { default as React, ReactElement, ReactNode, useState } from 'react'

interface ValidatorRewardData {
  validatorAddress: string
  denom: string
  nativeAmount: BigNumber;
  allianceAmount: BigNumber;
}

const Rewards: React.FunctionComponent<{}> = (): ReactElement => {
  const [loading] = useTaskSubscriber(TaskNames.Account.Rewards)
  const rewards = useRedux((state) => state.account.rewards)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const valAddrMap = useRedux((state) => state.core.valAddrMap)

  const validatorRewardData: ValidatorRewardData[] = []

  rewards.forEach(delegationRewards => {
    const denom = delegationRewards.denom

    delegationRewards.native.forEach(rewardEntry => {
      const validatorAddress = rewardEntry.validatorAddress
      const index = validatorRewardData.findIndex(rowData =>
        rowData.denom === denom && rowData.validatorAddress === validatorAddress)

      if (index !== -1) {
        validatorRewardData[index].nativeAmount.plus(rewardEntry.amount)
      } else {
        validatorRewardData.push({ denom, validatorAddress, nativeAmount: rewardEntry.amount, allianceAmount: BN_ZERO })
      }
    }
    )

    delegationRewards.alliance.forEach(rewardEntry => {
      const validatorAddress = rewardEntry.validatorAddress
      const index = validatorRewardData.findIndex(rowData =>
        rowData.denom === denom && rowData.validatorAddress === validatorAddress)

      if (index !== -1) {
        validatorRewardData[index].allianceAmount.plus(rewardEntry.amount)
      } else {
        validatorRewardData.push({ denom, validatorAddress, nativeAmount: BN_ZERO, allianceAmount: rewardEntry.amount })
      }
    }
    )
  }
  )

  // For pagination
  const [page, setPage] = useState(1)
  const itemsPerPage = 10
  const rewardsToDisplay = validatorRewardData?.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage) ?? []
  return (
    <Section title="Staking Rewards">
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Validator</TableCell>
              <TableCell align='right'>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rewardsToDisplay.map(renderReward)}</TableBody>
        </ListTable>
        {rewardsToDisplay.length > 0 && (
          <Box marginTop={3}>
            <PaginationByData
              data={validatorRewardData}
              setPage={setPage}
              page={page}
              itemsPerPage={itemsPerPage}
            />
          </Box>
        )}
        {!rewards.length && (
          <TableEmptyState itemName="rewards" />
        )}
      </DataLoadSegment>
    </Section >
  )

  function renderReward(reward: ValidatorRewardData, index: number): ReactNode {
    const { denom, validatorAddress, allianceAmount, nativeAmount } = reward
    const decimals = sdk?.token.getDecimals(denom) ?? 0
    const totalAmount = allianceAmount.plus(nativeAmount).shiftedBy(-(decimals + SHIFT_DECIMALS)).toFormat(decimals)

    return (
      <TableRow key={denom + index.toString()} hover>
        <TableCell>
          <CellLink to={`/validator/${validatorAddress}`}>
            {validatorAddress in valAddrMap
              ? valAddrMap[validatorAddress]?.carbonValidator.description?.moniker
              : validatorAddress}
          </CellLink>
        </TableCell>
        <AmountDenomCell denom={denom} amount={totalAmount} tooltipTitle={<>
          <div>{`Alliance: ${allianceAmount.shiftedBy(-(decimals + SHIFT_DECIMALS)).toFormat(decimals)}`}</div>
          <div>{`Native: ${nativeAmount.shiftedBy(-(decimals + SHIFT_DECIMALS)).toFormat(decimals)}`}</div></>} />
      </TableRow>
    )
  }
}

export default Rewards
