import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import { ListTable, Section } from 'js/components'
import { bnOrZero, getDecimalPlaces } from 'js/utils'
import React, { ReactElement } from 'react'
import { RestResponse } from 'tradehub-api-js'

interface Props {
  stats?: RestResponse.MarketStat
  market: Market
}

interface LabelValue {
  label: string
  value: string
}

const StatsSection: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { market, stats } = props

  const priceDecimals = getDecimalPlaces(market.tickSize) || 0

  const StatsCell: LabelValue[] = [
    {
      label: 'High',
      value: bnOrZero(stats?.day_high).toFormat(priceDecimals),
    },
    {
      label: 'Low',
      value: bnOrZero(stats?.day_low).toFormat(priceDecimals),
    },
    {
      label: 'Open',
      value: bnOrZero(stats?.day_open).toFormat(priceDecimals),
    },
    {
      label: 'Close',
      value: bnOrZero(stats?.day_close).toFormat(priceDecimals),
    },
    {
      label: 'Volume',
      value: bnOrZero(stats?.day_volume).toFormat(2),
    },
    {
      label: 'Index Price',
      value: bnOrZero(stats?.index_price).toFormat(priceDecimals),
    },
    {
      label: 'Mark Price',
      value: bnOrZero(stats?.mark_price).toFormat(priceDecimals),
    },
    {
      label: 'Last Price',
      value: bnOrZero(stats?.last_price).toFormat(priceDecimals),
    },
  ]

  return (
    <Section title="Stats">
      <ListTable>
        <TableHead>
          <TableRow>
            {StatsCell.map((stat: LabelValue, index: number) => (
              <TableCell align="right" key={index}>
                {stat.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            {StatsCell.map((stat: LabelValue, index: number) => (
              <TableCell align="right" key={index}>
                {stat.value}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </ListTable>
    </Section>
  )
}

export default StatsSection
