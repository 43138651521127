import { Constants } from "js/constants"
import { AuthSubspaceParams, DistributionSubspaceParams, GovSubspaceParams, OracleSubspaceParams, ParameterChangeProposalFormState, ParameterDetail, SlashingSubspaceParams, StakingSubspaceParams, SubmitProposalFormState, SubspaceParamsMap } from 'js/models/Governance'
import { escapeHtmlGo, parseNumber } from "js/utils"
import { getParamType } from './Modules'
import { DecCoin } from "carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"

import { MsgUpdateParams as MsgAuthUpdateParams } from "carbon-js-sdk/lib/codec/cosmos/auth/v1beta1/tx"
import { MsgUpdateParams as MsgBankUpdateParams } from "carbon-js-sdk/lib/codec/cosmos/bank/v1beta1/tx"
import { MsgUpdateParams as MsgDistributionUpdateParams } from "carbon-js-sdk/lib/codec/cosmos/distribution/v1beta1/tx"
import { MsgUpdateParams as MsgStakingUpdateParams } from "carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/tx"
import { MsgUpdateParams as MsgSlashingUpdateParams } from "carbon-js-sdk/lib/codec/cosmos/slashing/v1beta1/tx"
import { MsgUpdateParams as MsgGovUpdateParams } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { MsgUpdateParams as MsgOracleUpdateParams } from "carbon-js-sdk/lib/codec/Switcheo/carbon/oracle/tx"

import { CarbonTx } from "carbon-js-sdk"
import { decTypeDecimals } from "carbon-js-sdk/lib/constant"
import { omit } from 'lodash'
import { Duration } from "carbon-js-sdk/lib/codec"


export function parameterChangeProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string, form: SubmitProposalFormState, oldParams?: SubspaceParamsMap): MsgSubmitProposal {
  const formState = form as ParameterChangeProposalFormState

  return postProcessParameterChange(formState, proposer, initialDeposit, authority, oldParams)
}

type PostProcessResult = {
  value: string
  parameter: string
}
type InputPostProcessor = (input: string | number) => PostProcessResult

const postProcessors: { [index: string]: InputPostProcessor } = {
  'depositparams-min_deposit': (input) => {
    return {
      parameter: 'minDeposit',
      value: JSON.stringify([{
        denom: 'swth',
        amount: input,
      }]),
    }
  },
  'depositparams-max_deposit_period': (input) => {
    return {
      parameter: 'maxDepositPeriod',
      value: JSON.stringify(
        Duration.fromPartial({
          seconds: parseInt(input as string)
        })
      ),
    }
  },
  'votingparams-voting_period': (input) => {
    return {
      parameter: 'votingPeriod',
      value: JSON.stringify(
        Duration.fromPartial({
          seconds: parseInt(input as string)
        })
      ),
    }
  },
  'downtimeJailDuration': (input) => {
    return {
      parameter: 'downtimeJailDuration',
      value: JSON.stringify(
        Duration.fromPartial({
          seconds: parseInt(input as string)
        })
      )
    }
  },
  'unbondingTime': (input) => {
    return {
      parameter: 'unbondingTime',
      value: JSON.stringify(
        Duration.fromPartial({
          seconds: parseInt(input as string)
        })
      )
    }
  },
}

const createMsg = (parameters: ParameterDetail[], authority: string, oldParams?: SubspaceParamsMap): {
  typeUrl: string;
  value: Uint8Array;
}[] => {
  let messages: {
    typeUrl: string;
    value: Uint8Array;
  }[] = []

  // Map topic to parameters
  const groupedParameters: { [topic: string]: ParameterDetail[] } = {}
  parameters.forEach((paramDetail: ParameterDetail) => {
    // insert if not exist
    if (!groupedParameters[paramDetail.topic]) {
      groupedParameters[paramDetail.topic] = [{ ...paramDetail }]
    }
    // if exist, find or append
    const index = groupedParameters[paramDetail.topic].findIndex((p) => p.parameter === paramDetail.parameter)
    if (index === -1) {
      groupedParameters[paramDetail.topic].push({ ...paramDetail })
    } else {
      groupedParameters[paramDetail.topic][index] = { ...paramDetail }
    }
  })

  // Create messages for each topic
  for (const topic in groupedParameters) {
    const paramDetails = groupedParameters[topic]

    switch (topic) {
      case 'auth':
        const oldAuthParams = oldParams?.auth as AuthSubspaceParams
        if (!oldAuthParams) throw new Error('Could not fetch current parameters')
        messages.push(formatAuthUpdateMsg(paramDetails, authority, oldAuthParams))
        break
      case 'bank':
        messages.push(formatBankUpdateMsg(paramDetails, authority))
        break
      case 'distribution':
        const oldDistParams = oldParams?.distribution as DistributionSubspaceParams
        if (!oldDistParams) throw new Error('Could not fetch current parameters')
        messages.push(formatDistributionUpdateMsg(paramDetails, authority, oldDistParams))
        break
      case 'staking':
        const oldStakingParams = oldParams?.staking as StakingSubspaceParams
        if (!oldStakingParams) throw new Error('Could not fetch current parameters')
        messages.push(formatStakingUpdateMsg(paramDetails, authority, oldStakingParams))
        break
      case 'slashing':
        const oldSlashingParams = oldParams?.slashing as SlashingSubspaceParams
        if (!oldSlashingParams) throw new Error('Could not fetch current parameters')
        messages.push(formatSlashingUpdateMsg(paramDetails, authority, oldSlashingParams))
        break
      case 'gov':
        const oldGovParams = oldParams?.gov as GovSubspaceParams
        if (!oldGovParams) throw new Error('Could not fetch current parameters')
        messages.push(formatGovUpdateMsg(paramDetails, authority, oldGovParams))
        break
      case 'oracle':
        const oldOracleParams = oldParams?.oracle as OracleSubspaceParams
        if (!oldOracleParams) throw new Error('Could not fetch current parameters')
        messages.push(formatOracleUpdateMsg(paramDetails, authority, oldOracleParams))
        break

      default:
        throw new Error(`Unsupported topic: ${topic}`)
    }


  }

  return messages
}

const formatAuthUpdateMsg = (paramDetails: ParameterDetail[], authority: string, oldParams: AuthSubspaceParams): {
  typeUrl: string;
  value: Uint8Array;
} => {
  let newParams = oldParams
  paramDetails.forEach((paramDetail: ParameterDetail) => {
    newParams = {
      ...newParams,
      [paramDetail.parameter]: paramDetail.value
    }
  })

  const value = MsgAuthUpdateParams.fromPartial({
    authority,
    params: newParams
  })

  return {
    typeUrl: CarbonTx.Types.MsgAuthUpdateParams,
    value: MsgAuthUpdateParams.encode(value).finish(),
  }
}

const formatBankUpdateMsg = (paramDetails: ParameterDetail[], authority: string): {
  typeUrl: string;
  value: Uint8Array;
} => {
  let newParams = {}
  paramDetails.forEach((paramDetail: ParameterDetail) => {
    newParams = {
      ...newParams,
      [paramDetail.parameter]: paramDetail.value
    }
  })

  const value = MsgBankUpdateParams.fromPartial({
    authority,
    params: newParams
  })

  return {
    typeUrl: CarbonTx.Types.MsgBankUpdateParams,
    value: MsgBankUpdateParams.encode(value).finish(),
  }
}

const formatDistributionUpdateMsg = (paramDetails: ParameterDetail[], authority: string, oldParams: DistributionSubspaceParams): {
  typeUrl: string;
  value: Uint8Array;
} => {
  // Remove deprecated parameters
  const nonDeprecatedOldParams = omit(oldParams, ['baseProposerReward', 'bonusProposerReward'])

  let newParams = nonDeprecatedOldParams
  paramDetails.forEach((paramDetail: ParameterDetail) => {
    newParams = {
      ...newParams,
      [paramDetail.parameter]: paramDetail.value
    }
  })

  const value = MsgDistributionUpdateParams.fromPartial({
    authority,
    params: newParams
  })

  return {
    typeUrl: CarbonTx.Types.MsgDistributionUpdateParams,
    value: MsgDistributionUpdateParams.encode(value).finish(),
  }
}

const formatStakingUpdateMsg = (paramDetails: ParameterDetail[], authority: string, oldParams: StakingSubspaceParams): {
  typeUrl: string;
  value: Uint8Array;
} => {
  let newParams = oldParams
  paramDetails.forEach((paramDetail: ParameterDetail) => {
    if (paramDetail.parameter === 'unbondingTime') {
      paramDetail.value = JSON.parse(paramDetail.value as string)
    }
    newParams = {
      ...newParams,
      [paramDetail.parameter]: paramDetail.value
    }
  })

  const value = MsgStakingUpdateParams.fromPartial({
    authority,
    params: newParams
  })

  return {
    typeUrl: CarbonTx.Types.MsgStakingUpdateParams,
    value: MsgStakingUpdateParams.encode(value).finish(),
  }
}

const formatSlashingUpdateMsg = (paramDetails: ParameterDetail[], authority: string, oldParams: SlashingSubspaceParams): {
  typeUrl: string;
  value: Uint8Array;
} => {
  let newParams = oldParams
  paramDetails.forEach((paramDetail: ParameterDetail) => {
    let value: string | number | Uint8Array = paramDetail.value
    if (paramDetail.parameter === 'downtimeJailDuration') {
      value = JSON.parse(paramDetail.value as string)
    }

    if (paramDetail.parameter === 'minSignedPerWindow' || paramDetail.parameter === 'slashFractionDoubleSign' || paramDetail.parameter === 'slashFractionDowntime') {
      // convert to uint8array
      value = new Uint8Array((paramDetail.value as string).length)
      for (let i = 0; i < (paramDetail.value as string).length; i++) {
        value[i] = (paramDetail.value as string).charCodeAt(i);
      }

    }

    newParams = {
      ...newParams,
      [paramDetail.parameter]: value
    }
  })

  const value = MsgSlashingUpdateParams.fromPartial({
    authority,
    params: newParams
  })

  return {
    typeUrl: CarbonTx.Types.MsgSlashingUpdateParams,
    value: MsgSlashingUpdateParams.encode(value).finish(),
  }
}

const formatGovUpdateMsg = (paramDetails: ParameterDetail[], authority: string, oldParams: GovSubspaceParams): {
  typeUrl: string;
  value: Uint8Array;
} => {
  let newParams = oldParams
  paramDetails.forEach((paramDetail: ParameterDetail) => {
    if (paramDetail.parameter === 'votingPeriod' || paramDetail.parameter === 'maxDepositPeriod' || paramDetail.parameter === 'minDeposit') {
      paramDetail.value = JSON.parse(paramDetail.value as string)
    }
    newParams = {
      ...newParams,
      [paramDetail.parameter]: paramDetail.value
    }
  })

  const value = MsgGovUpdateParams.fromPartial({
    authority,
    params: newParams
  })

  return {
    typeUrl: CarbonTx.Types.MsgGovUpdateParams,
    value: MsgGovUpdateParams.encode(value).finish(),
  }
}

const formatOracleUpdateMsg = (paramDetails: ParameterDetail[], authority: string, oldParams: OracleSubspaceParams): {
  typeUrl: string;
  value: Uint8Array;
} => {
  let newParams = oldParams
  paramDetails.forEach((paramDetail: ParameterDetail) => {
    newParams = {
      ...newParams,
      [paramDetail.parameter]: paramDetail.value
    }
  })

  const value = MsgOracleUpdateParams.fromPartial({
    authority,
    params: newParams
  })

  return {
    typeUrl: CarbonTx.Types.MsgOracleUpdateParams,
    value: MsgOracleUpdateParams.encode(value).finish(),
  }
}

function postProcessParameterChange(formState: ParameterChangeProposalFormState, proposer: string | undefined, initialDeposit: DecCoin[], authority: string, oldParams?: SubspaceParamsMap): MsgSubmitProposal {
  let { title, summary, metadata, parameters } = formState

  title = escapeHtmlGo(title)
  summary = escapeHtmlGo(summary)
  parameters = processParameterDetails(parameters)

  const messages = createMsg(parameters, authority, oldParams)

  return MsgSubmitProposal.fromPartial({
    title,
    summary,
    metadata,
    initialDeposit,
    proposer: proposer ?? '',
    messages,
  })
}

const processParameterDetails = (paramDetails: ParameterDetail[]): ParameterDetail[] => {
  paramDetails.forEach((paramDetail: ParameterDetail, index) => {
    // Validate
    const { parameter, value } = paramDetail
    if (!parameter || !value) return

    const paramType = getParamType(parameter)
    const parsedValue = parseNumber(value)
    if (!paramType || !parsedValue || !parsedValue.isFinite()) return

    // Process
    let formattedDetail = paramDetail

    // Format value based on type
    switch (paramType.type) {
      case 'uint32':
        formattedDetail.value = parsedValue.toNumber()
        break
      case 'int64':
      case 'uint16':
        formattedDetail.value = parsedValue.toString()
        break
      case 'number':
        formattedDetail.value = parsedValue.shiftedBy(decTypeDecimals).toString()
        break
      case 'percent':
        formattedDetail.value = parsedValue.shiftedBy(-2).toString()
        break
      case 'coin':
        formattedDetail.value = parsedValue.shiftedBy(Constants.Decimals.SWTH).integerValue().toString()
        break
      case 'time':
        formattedDetail.value = parsedValue.times(Constants.TimeFactor.SecondsToHour).toString()
        break
    }

    // Format parameter and value based on parameter
    const postProcessor = postProcessors[parameter]
    if (postProcessor) {
      const result = postProcessor(formattedDetail.value)
      formattedDetail.parameter = result.parameter
      formattedDetail.value = result.value
    } else if (paramType.type === "uint32") {
      formattedDetail.value = `${formattedDetail.value}`
    } else if (['coin', 'time'].includes(paramType.type)) {
      formattedDetail.value = `"${formattedDetail.value}"`
    }

    paramDetails[index] = formattedDetail
  })

  return paramDetails
}

