import { Box, BoxProps, Divider, makeStyles, Theme, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { ProposalStatus } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1beta1/gov'
import clsx from 'clsx'
import { CellLink, Section, StandardBtn } from 'js/components'
import { Paths } from 'js/constants'
import { useRedux } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { adjustHuman, BIG_ZERO, bnOrZero, makeMoneyLabel } from 'js/utils'
import React from 'react'
import { DataRow, DataScale } from '../../components'

type Props = BoxProps & {
  onClickVote: () => void
}

const VoteDetails: React.FC<Props> = (props: Props) => {
  const { className, onClickVote, ...rest } = props
  const proposal = useRedux((state) => state.proposal.details)
  const isLiveProposal = proposal && ![ProposalStatus.PROPOSAL_STATUS_PASSED, ProposalStatus.PROPOSAL_STATUS_REJECTED].includes(proposal.status)
  const liveVoteTally = useRedux((state) => state.proposal.liveVoteTally)
  const voteTally = isLiveProposal ? liveVoteTally : proposal?.finalTallyResult
  const valAddrMap = useRedux((state) => state.core.valAddrMap)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const classes = useStyles(props)
  const denom = proposal?.totalDeposit[0].denom.toUpperCase()


  const totalStakingPower = Object.values(valAddrMap).reduce((sumShares, valpair) => {
    const delegatorShares = sdk?.token.toHuman('swth', adjustHuman(valpair.carbonValidator.delegatorShares ?? '')) ?? BIG_ZERO
    return sumShares = sumShares.plus(delegatorShares)
  }, BIG_ZERO)

  const resultYes = bnOrZero(voteTally?.yesCount).shiftedBy(-8)
  const resultNo = bnOrZero(voteTally?.noCount).shiftedBy(-8)
  const resultVeto = bnOrZero(voteTally?.noWithVetoCount).shiftedBy(-8)
  const resultAbstain = bnOrZero(voteTally?.abstainCount).shiftedBy(-8)
  const totalVoted = resultYes
    .plus(resultNo)
    .plus(resultVeto)
    .plus(resultAbstain)

  const makeVotedLabel = (result: BigNumber) => {
    const resultPercent = totalVoted.gt(0) ? result.div(totalVoted) : BIG_ZERO
    return `${makeMoneyLabel(result.shiftedBy(8))} ${denom} (${resultPercent.shiftedBy(2).toFormat(3)}%)`
  }

  const getVotedProgress = (result: BigNumber) => {
    const resultPercent = totalStakingPower.gt(0) ? result.div(totalStakingPower) : BIG_ZERO
    if (resultPercent.eq(0)) return 0
    return BigNumber.max(resultPercent.shiftedBy(2), 1).toNumber()
  }

  return (
    <Section
      className={clsx(classes.root, className)}
      title='Participate in Voting'
      {...rest}
    >
      <Box marginBottom={1.5}>
        <DataRow header='Total staking power' value={`${totalStakingPower.toFormat(0)} ${denom}`} />
        <Divider className={classes.divider} />
        <DataRow header='Voted power' value={`${makeMoneyLabel(totalVoted, { decimals: 0 })} ${denom}`} />
      </Box>

      <Box>
        <DataScale
          header='Yes'
          value={makeVotedLabel(resultYes)}
          progress={getVotedProgress(resultYes)}
          color={switcheo.green[400]}
        />

        <DataScale
          header='No'
          value={makeVotedLabel(resultNo)}
          progress={getVotedProgress(resultNo)}
          color={switcheo.danger.primary}
        />

        <DataScale
          header='No with veto'
          value={makeVotedLabel(resultVeto)}
          progress={getVotedProgress(resultVeto)}
          color={switcheo.yellow}
        />

        <DataScale
          header='Abstain'
          value={makeVotedLabel(resultAbstain)}
          progress={getVotedProgress(resultAbstain)}
          color='#B3B3B3'
        />
      </Box>
      {proposal?.status === ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD && (
        sdk?.wallet ?
          <StandardBtn
            variant="contained"
            color="secondary"
            onClick={onClickVote}
            className={classes.button}
          >
            <Typography variant="h3">Vote</Typography>
          </StandardBtn>
          :
          <StandardBtn
            to="/login"
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            <Typography variant="h3">Login to vote</Typography>
          </StandardBtn>
      )}
      <Box marginTop={1.5}>
        <Typography variant='body2'>How is voting tallied?&nbsp;
          <CellLink underline="hover" href={Paths.Resources.GovernanceGuide}>
            Learn more
          </CellLink>
        </Typography>
      </Box>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  divider: {
    backgroundColor: switcheo.primary[300],
  },
  button: {
    marginTop: '1rem',
  },
}))

export default VoteDetails
