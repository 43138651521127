import { Theme, makeStyles } from '@material-ui/core'
import { updateFilters } from 'js/actions/account'
import { Transactions } from 'js/components'
import { TaskNames } from 'js/constants'
import { RootState } from 'js/store'
import TransactionFilter, { FilterOption } from 'js/views/Transactions/TransactionList/TransactionFilter'
import React, { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export interface TransactionsProps {
}

interface Props extends TransactionsProps { }

const TransactionsWrapper: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const [resetPage, setResetPage] = useState(false)
  const [filters, setFilters] = useState<{ [index: string]: boolean }>()

  const transactions = useSelector((state: RootState) => state.account.transactions)
  const types = useSelector((state: RootState) => state.account.types)

  const classes = useStyles()
  const dispatch = useDispatch()

  const mapTypeToOption = (type: string): FilterOption => {
    return {
      value: type,
    }
  }

  const onFiltersChange = (types: string[]) => {
    const newFilters: { [index: string]: boolean } = {}
    types.forEach((type) => type !== undefined && (newFilters[type] = true))
    setResetPage(true)
    setFilters(newFilters)
    dispatch(updateFilters({ filters: newFilters }))
  }

  return (
    <>
      <Transactions
        title="Transactions"
        transactions={transactions}
        action={updateFilters}
        filterStatus
        loadName={[
          TaskNames.Account.Transactions,
          TaskNames.Account.TransactionType,
          TaskNames.Account.TransactionFilter,
        ]}
        filters={filters}
        sectionClass={classes.transactions}
        resetPage={resetPage}
        setPageReset={setResetPage}
        rightMenu={
          <TransactionFilter
            className={classes.filter}
            onFilter={onFiltersChange}
            filters={filters}
            options={types.map(mapTypeToOption)}
          />
        }
      />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  transactions: {
    padding: theme.spacing(3),
  },
  filter: {
    marginBottom: theme.spacing(2),
  },
}))

export default TransactionsWrapper
