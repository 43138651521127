import { Box, FormControl, InputLabel, MenuItem, Select, SelectProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useRedux } from "js/hooks";
import React from "react";

interface Props extends SelectProps {
  defaultText?: string
}

const MarketSelectInput: React.FC<Props> = (props: Props) => {
  const { children, label, className, onChange, defaultText, ...rest } = props;
  const classes = useStyles();
  const markets = useRedux((state) => state.app.markets)

  const _defaultText = defaultText ?? "Select Market"

  const _onChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>, child: React.ReactNode) => {
    onChange?.(event, child)
  }

  return (
    <Box className={classes.root}>
      <FormControl fullWidth>
        <InputLabel shrink>
          {label}
        </InputLabel>
        <Select
          displayEmpty
          {...rest}
          className={clsx(classes.select, className)}
          onChange={_onChange}
          MenuProps={{ getContentAnchorEl: null }}
        >
          <MenuItem value="" disabled>{_defaultText}</MenuItem>
          {
            Object.values(markets).map((market: any) => (
              <MenuItem key={market.id} value={market.id}>{market.description}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  select: {

  },
}))

export default MarketSelectInput;
