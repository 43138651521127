import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import { updateDateFiltersMarketsVolumeChart } from 'js/actions/markets'
import { ChartContainer, TableSection, TooltipHint } from 'js/components'
import Page from 'js/components/Page'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { bnOrZero } from 'tradehub-api-js/build/main/lib/tradehub/utils'
import { MarketsRow } from './components'
import MarketVolumeChart from './components/MarketVolumeChart'
import { MarketsHeader } from './marketsConfig'

interface Props { }

const Markets: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const { marketsVolumeChartData } = useRedux((state) => state.markets)
  const { markets: marketsRaw } = useRedux((state) => state.app)
  const [currentIndex, setCurrentIndex] = useState<number>(marketsVolumeChartData?.length - 1 ?? 0)
  const tradingVolume = marketsVolumeChartData[currentIndex]?.volumeValue ?? 0

  const markets = Object.values(marketsRaw).sort((a, b) => (b.isActive ? 1 : 0) - (a.isActive ? 1 : 0) || b.createdBlockHeight.toNumber() - a.createdBlockHeight.toNumber()) // sort by isActive

  useEffect(() => {
    if (marketsVolumeChartData?.length > 0) setCurrentIndex(marketsVolumeChartData?.length - 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketsVolumeChartData?.length])

  const setCurrentView = (index: number) => {
    if (index !== currentIndex) {
      setCurrentIndex(index)
    }
  }
  const setCurrentViewCb = useCallback(setCurrentView, [currentIndex])
  return (
    <Page title="Markets">
      <ChartContainer
        csvFileName={"marketVolume"}
        taskNames={[TaskNames.Markets.VolumeChart]}
        title={
          <Box className={classes.chartTitleBox}>
            <Box className={classes.chartTitle}>
              <Typography className={classes.chartTitleFirst} variant={"h4"} component={"span"}>
                {`Trading Volume`} <TooltipHint title={"Trading Volume of popular markets on Carbon"} className={classes.tooltip} />
              </Typography>
            </Box>
            <Typography variant={"h3"} component={"span"}>
              {`$${bnOrZero(tradingVolume).toFormat()}`}
            </Typography>
          </Box>
        }
        action={updateDateFiltersMarketsVolumeChart}
        enableView
      >
        <MarketVolumeChart data={marketsVolumeChartData} callback={setCurrentViewCb} markets={marketsRaw} />
      </ChartContainer>
      <TableSection
        headerCells={MarketsHeader}
        loadName={TaskNames.App.Markets}
        models={Object.values(markets)}
        rowComponent={MarketsRow}
        title="Markets"
        itemName="markets"
        pagination
        searchBar
        searchKeys={["name", "description", "displayName"]}
      />
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    marginBottom: theme.spacing(2),
  },
  chartTitle: {
    display: 'block',
  },
  chartTitleFirst: {
    fontWeight: 'bold',
  },
  tooltip: {
    pointer: 'cursor',
    align: 'center',
  },
  chartTitleBox: {
    paddingBottom: theme.spacing(2),
  }
}))

export default Markets
