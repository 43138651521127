import { Typography } from '@material-ui/core'
import { Pool } from 'carbon-js-sdk/lib/insights'
import {
  updateOrdersFilters,
  updateRiskyLongPositions,
  updateTradesFilters
} from 'js/actions/market'
import { CellLink, DataLoadSegment, NotFoundState, Orders, Page, Trades } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useAsyncTask, useRedux, useTaskSubscriber } from 'js/hooks'
import Long from 'long'
import React, { Fragment, ReactElement, useEffect, useState } from 'react'
import {
  LargePositions,
  // LargePositions,
  MarketInfo, PoolRoutes, ProfitablePositions, RiskyPositions
} from './components'
import { PoolRoute } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/export'

interface Props { }

const Market: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const {
    market,
    // stats,
    orders,
    trades,
    largePositions,
    riskyLongs,
    // riskyShorts,
    profitablePositions,
  } = useRedux((state) => state.market)
  const { markets } = useRedux((state) => state.app)
  const { carbonSDK: sdk } = useRedux((state) => state.core)
  // TODO: Add TaskNames.Market.Stats once market stats is migrated
  const [marketLoading] = useTaskSubscriber(TaskNames.Market.Detail)
  const [getPoolRoute, poolRouteLoading] = useAsyncTask('getPoolRoute')
  const [getPoolInfo, poolInfoLoading] = useAsyncTask('getPoolInfo')
  const [routes, setRoutes] = useState<PoolRoute[]>([])
  const [pools, setPools] = useState<Pool[]>([])
  const loading = marketLoading || poolRouteLoading || poolInfoLoading

  useEffect(() => {
    if (!sdk || !market?.id) return
    getPoolRoute(async () => {
      const poolRoutes = await sdk?.query.liquiditypool.PoolRouteAll({
        pagination: {
          limit: Long.fromNumber(10000),
          offset: Long.UZERO,
          countTotal: true,
          reverse: false,
          key: new Uint8Array()
        }
      })
      if (poolRoutes) setRoutes(poolRoutes?.poolRoutes?.filter((o) => o.marketId === market?.id))
    })

    getPoolInfo(async () => {
      const poolsInfo = await sdk.insights.Pools({ limit: 10000 })
      if (poolsInfo?.result?.models) setPools(poolsInfo.result.models)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, market?.id])

  // construct pool buy and sell flow
  const poolRouteFlow: any[][] = []
  let index = 0
  for (const route of routes) {
    const poolRouteArray: any[] = []
    poolRouteFlow[index] = []
    for (const ids of route.poolIds) {
      const poolInfo = pools.find((pool) => pool.poolId.toString() === ids.toString())
      if (!poolInfo) continue
      const prevToken = poolRouteArray[poolRouteArray.length - 1]
      const { denomA, denomB } = poolInfo
      let firstToken = denomA
      let secondToken = denomB
      if (!prevToken) {
        firstToken = market?.base === denomA ? denomA : denomB
        secondToken = market?.quote === denomA ? denomA : denomB
      } else {
        firstToken = prevToken === denomA ? denomA : denomB
        secondToken = prevToken === denomB ? denomB : denomA
      }
      poolRouteFlow[index].push({ ...poolInfo, denomA: firstToken, denomB: secondToken })
    }
    index++
  }

  return (
    <Page title={market?.description} >
      <DataLoadSegment loading={loading}>
        {market?.id && (
          <Fragment>
            {/* <StatsSection market={market} stats={stats} /> */}
            <MarketInfo market={market} />
            {
              poolRouteFlow.length > 0 && <PoolRoutes market={market} poolRouteFlow={poolRouteFlow} />
            }
            {
              market.marketType === 'futures' && (
                <>
                  <ProfitablePositions
                    profitablePositions={profitablePositions}
                    markets={markets}
                  />
                  <LargePositions largePositions={largePositions} markets={markets} />
                  <RiskyPositions
                    positions={riskyLongs}
                    title="Risky Positions"
                    action={updateRiskyLongPositions}
                    markets={markets}
                    loadName={[
                      TaskNames.Market.LongRisky,
                      TaskNames.Market.LongRiskyFilter,
                    ]}
                  />
                </>
              )
            }
            <Orders
              filterStatus
              orders={orders}
              title="Recent Orders"
              action={updateOrdersFilters}
              markets={markets}
              loadName={[
                TaskNames.Market.OrderList,
                TaskNames.Market.OrderFilter,
              ]}
            />
            <Trades
              filterStatus
              trades={trades}
              title="Recent Trades"
              action={updateTradesFilters}
              markets={markets}
              loadName={[
                TaskNames.Market.TradeList,
                TaskNames.Market.TradeFilter,
              ]}
            />
          </Fragment>
        )}
        {!market?.id && (
          <NotFoundState title="No Market Found">
            <Typography variant="body1">
              We can’t find any market with this name. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Markets}>
                Markets
              </CellLink>
              &nbsp;page to view existing markets.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

export default Market
