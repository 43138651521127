import { Link, LinkProps, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'

type Props =
  // for use with internal routing
  // `<CellLink to='/path'>Go</CellLink>`
  | LinkProps<typeof RouterLink>

  // for use with external routing
  // `<CellLink href='/path'>Go</CellLink>`
  | (LinkProps & { to?: string })

const CellLink: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, children, color, to, href, ...rest } = props
  const classes = useStyles(props)

  return (
    <Link
      underline="none"
      {...{
        ...(typeof to === 'string' && {
          to,
          component: RouterLink,
        }),
        ...(typeof href === 'string' && {
          href,
          target: '_blank',
          rel: 'noopener noreferrer',
        }),
      }}
      color={color}
      {...rest}
      className={clsx(classes.root, className, {
        [classes.blueText]: !color,
      })}
    >
      {children}
    </Link>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontWeight: 700,
  },
  blueText: {
    color: theme.palette.text.hint,
  },
}))

export default CellLink
