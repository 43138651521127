import { APIPosition } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/position/position'
import { Action } from 'redux'

export enum PositionActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_POSITION = 'SET_POSITION',
}

export type PositionActon = ClearAction | SetPositionAction

export interface ClearAction extends Action<PositionActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: PositionActionType.CLEAR,
  }
}

export interface SetPositionAction extends Action<PositionActionType.SET_POSITION> {
  position: APIPosition
}

export function setPosition(position: APIPosition): SetPositionAction {
  return {
    type: PositionActionType.SET_POSITION,
    position,
  }
}
