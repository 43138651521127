import * as Core from './core/action'
import * as Layout from './layout/action'
import * as Tutorial from './tutorial/action'

export type ActionType = {
  [index: string]: string
}
export type ActionModule = {
  ActionType: ActionType
}
export type ActionModules = {
  [index: string]: ActionModule
}

export const actions = {
  Core,
  Layout,
  Tutorial,
}

// test for conflicting action names
const allActions: ActionModules = actions
const globalActionNames: Record<string, string> = {}
for (const moduleName of Object.keys(actions)) {
  const moduleActions = allActions[moduleName].ActionType
  for (const actionName of Object.values(moduleActions)) {
    if (globalActionNames[actionName]) {
      throw new Error(`redux action name conflict - ${actionName} is already declared in ${moduleName} module`)
    }
    globalActionNames[actionName] = moduleName
  }
}
