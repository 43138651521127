import { Box, makeStyles, Tab, Tabs, TextField, Theme, Typography } from '@material-ui/core'
import { sha256 } from 'js-sha256'
import { Page } from 'js/components'
import React, { ReactElement, useEffect, useState } from 'react'
import { CompetitionSettings } from './components/CompetitionSettings'

interface Props { }

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (): ReactElement<Props> => {
  const classes = useStyles()
  const [tab, setTab] = useState(0)
  const [password, setPassword] = useState('')
  const [authStatus, setAuthStatus] = useState(false)

  const handleTabChange = (event: any, id: number) => {
    setTab(id)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (sha256(password).toString() === '79d3db46ef30279dd4a30dfc9c4c71b85fc0d8f73c025cb355749283e92bd713' && !authStatus) {
      setAuthStatus(true)
    } else if (!authStatus) setAuthStatus(false)
  })

  return (
    <Page title="Insights Controls">
      {
        !authStatus && (
          <div className={classes.modal}>
            <div style={{ backgroundColor: 'white', borderRadius: 10, padding: '8em 17em', border: '1px solid black' }}>
              <Typography variant="h1">Passsword</Typography>
              <br />
              <TextField style={{ borderRadius: '8px' }} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
          </div>
        )
      }
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Competitions" id="comp" />
          <Tab label="Alert Logs" id="TBC1" />
          <Tab label="TBC" id="TBC2" />
        </Tabs>
      </Box>
      <div className={classes.tabDivContainer} hidden={tab !== 0}>
        <CompetitionSettings password={password} />
      </div>
      <div hidden={tab !== 1}>
        Coming soon!
      </div>
      <div hidden={tab !== 2}>
        Coming soon!
      </div>
    </Page>
  )
}

export const useStyles = makeStyles((theme: Theme) => ({
  tabDivContainer: {
    minHeight: '20rem',
    display: 'grid',
    padding: '1em 0em'
  },
  modal: {
    display: 'flex',
    width: '80%',
    height: '100%',
    position: 'absolute',
    zIndex: 999,
    backdropFilter: 'blur(11px)',
    placeContent: 'flex-start',
    placeItems: 'center',
    paddingTop: '10em',
    gap: '1em',
    flexDirection: 'column'
  }
}))
