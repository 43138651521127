import bech32 from 'bech32'
import { CarbonSDK } from 'carbon-js-sdk'
export const isValidBlock = (input: string) => {
  const inputToNumber = Number(input)
  if (isNaN(inputToNumber)) return false
  return inputToNumber > 0
}

export const isValidAddress = (input: string, network: CarbonSDK.Network) => {
  try {
    const decodedAddress = bech32.decode(input)
    if (network === CarbonSDK.Network.MainNet || network === CarbonSDK.Network.DevNet) {
      return decodedAddress.prefix === 'swth'
    }
    return decodedAddress.prefix === 'tswth'
  } catch (err) {
    return false // when there are errors when decoding
  }
}

export const isValidator = (input: string, network: CarbonSDK.Network) => {
  try {
    const decodedAddress = bech32.decode(input)
    if (network === CarbonSDK.Network.MainNet) {
      return decodedAddress.prefix === 'swthvaloper'
    }
    return decodedAddress.prefix === 'tswthvaloper'
  } catch (err) {
    return false // when there are errors when decoding
  }
}

export const isValidTransaction = (input: string) => {
  const isHashRegex = /^[0-9A-F]+$/
  return input.match(isHashRegex) && input.length === 64
}
