import { Box, TableBody, TableHead, TableRow, Theme, makeStyles } from '@material-ui/core'
import { DataLoadSegment, ListTable, Section, TableCellInfo, TableEmptyState, TableSectionCell } from 'js/components'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, useEffect, useState } from 'react'
import PaginationByData from './PaginationByData'
import TableSearchBar from './TableSearchBar'

interface Props {
  cellClass?: string
  headerCells: TableCellInfo[]
  loadName: string
  models: any,
  rowComponent: any
  title?: string
  addInfo?: {
    [index: string]: any
  }
  itemName?: string
  pagination?: boolean
  searchBar?: boolean
  searchKeys?: string[]
  placeholder?: string
  loading?: boolean
}

const TableSection: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { cellClass, headerCells = [], loadName, models = [], rowComponent: RowComponent, title, addInfo, itemName, pagination = false, searchBar, searchKeys, placeholder = "", loading } = props
  const [isLoading] = useTaskSubscriber(loadName)
  const classes = useStyles()

  //Pagination Settings
  const itemsPerPage = 10
  const [page, setPage] = useState<number>(1)
  let itemsToDisplay = [...models]
  let itemsFiltered: any = []
  //Search Bar
  const [search, setSearch] = useState<string>("")
  if (search !== '' && searchKeys) {
    itemsToDisplay = models.filter((o: any) => {
      for (let searchKey of searchKeys) {
        if (o[searchKey]?.toLowerCase()?.includes(search?.toLowerCase())) return true
      }
      return false
    })
    itemsFiltered = [...itemsToDisplay]
  }

  useEffect(() => {
    if (page !== 1) setPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = ((page - 1) * itemsPerPage) + (itemsPerPage)
  itemsToDisplay = pagination && itemsToDisplay.length && itemsPerPage ? [...itemsToDisplay.slice(startIndex, endIndex)] : itemsToDisplay
  return (
    <Section title={title} rightMenu={
      <Box className={classes.rightMenuWrapper}>
        {
          searchBar && <TableSearchBar onSearch={setSearch} placeholder={placeholder} />
        }
      </Box>
    }>
      <DataLoadSegment
        loading={loading || isLoading}
      >
        <ListTable className={classes.tableWrapper}>
          <TableHead>
            <TableRow>
              {headerCells.map((cell, index) => {
                const { align = 'right', content } = cell
                return (
                  <TableSectionCell
                    align={align}
                    className={cellClass}
                    content={content}
                    key={index}
                  />
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsToDisplay.map((model: any, index: number) => (
              <RowComponent
                key={`rowComponent${index}`}
                model={model}
                addInfo={addInfo}
              />
            ))
            }
          </TableBody>
        </ListTable>
        {(!models || models?.length <= 0 || models?.size <= 0) && (
          <TableEmptyState itemName={itemName} />
        )}
      </DataLoadSegment>
      {pagination && (
        <Box marginTop={3}>
          <PaginationByData
            data={search === "" ? models : itemsFiltered}
            setPage={setPage}
            page={page}
            itemsPerPage={itemsPerPage}
          />
        </Box>
      )}
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    // minHeight: '40rem'
  },
  rightMenuWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
}))

export default TableSection
