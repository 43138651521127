import { Tendermint37Client, ValidatorsResponse } from '@cosmjs/tendermint-rpc/build/tendermint37';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { CarbonSDK, Insights, Models } from 'carbon-js-sdk';
import { InsightsQueryClient } from 'carbon-js-sdk/lib/clients';
import { GetLatestValidatorSetResponse } from "carbon-js-sdk/lib/codec/cosmos/base/tendermint/v1beta1/query";
import { BondStatus } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/staking';
import { CoinGeckoTokenNames, NetworkConfigs } from 'carbon-js-sdk/lib/constant';
import { TaskNames } from 'js/constants';
import { SimpleBlock } from "js/models";
import { NodeModel } from 'js/reducers/nodes';
import { actions } from 'js/store';
import { ActionType, CoreState, updateCarbonSDK, updateTmWsClient } from 'js/store/core';
import { BIG_ZERO, SimpleMap, ValPair, bnOrZero, logger, parseNet, uuidv4 } from 'js/utils';
import { getAdjustedMarket } from 'js/utils/market';
import Long from 'long';
import { SagaIterator } from 'redux-saga';
import { Effect, call, delay, put, race, select, spawn, take, takeLatest } from 'redux-saga/effects';
import { AppActionType, setAllOracleTokenPrices, setAvgBlockTime, setConnectError, setDelegators, setGeckoPrices, setMarkets, setNeoPrice, setNodes, setOracles, setSWTHSupply, setSelectedNodes, setSigningInfo, setTokensMap, setTotalBonded, setTotalStaked, setTotalSupply } from '../actions/app';
import { defaultGeckoPrice, defaultNeoPrice } from '../reducers/app';
import Saga from './Saga';
import webSocketSaga, { emitEvent } from './WebSocket';
import { runSagaTask, selectState, waitForTmWsClient, waitforSDK } from './helper';
import { QueryAllMarketResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/query';
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market';
import { QueryResultsResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/oracle/query';
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token';
import { QueryTokenPriceAllResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/pricing/query';
import { QueryAllBlockRequest, QueryAllBlockResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/query';

async function queryGecko(): Promise<Response> {
  const removeDuplicateMap: any = {}
  let tokensQuery: string = ""
  const geckoEntriesArray: string[][] = Object.entries(CoinGeckoTokenNames)
  // Get all Gecko Coins from CoinGeckoTokenNames and insert into query
  for (const [, value] of geckoEntriesArray) {
    if (!removeDuplicateMap[value]) {
      removeDuplicateMap[value] = 1
      tokensQuery += `${value},`
    }
  }
  tokensQuery = tokensQuery.slice(0, -1)
  return fetch(
    `https://api.coingecko.com/api/v3/simple/price?ids=${tokensQuery}&vs_currencies=usd,btc,eth,sgd`,
  )
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
      }
      return {
        switcheo: defaultGeckoPrice,
        ethereum: defaultGeckoPrice,
        bitcoin: defaultGeckoPrice,
        "usd-coin": defaultGeckoPrice,
        "celsius-degree-token": defaultGeckoPrice,
        "neon-exchange": defaultGeckoPrice,
        neo: defaultGeckoPrice,
        "binance-usd": defaultGeckoPrice,
        "bitcoin-bep2": defaultGeckoPrice,
        "wrapped-bitcoin": defaultGeckoPrice,
        binancecoin: defaultGeckoPrice,
      }
    })
    .catch((error) => ({
      switcheo: defaultGeckoPrice,
      ethereum: defaultGeckoPrice,
      bitcoin: defaultGeckoPrice,
      "usd-coin": defaultGeckoPrice,
      "celsius-degree-token": defaultGeckoPrice,
      "neon-exchange": defaultGeckoPrice,
      neo: defaultGeckoPrice,
      "binance-usd": defaultGeckoPrice,
      "bitcoin-bep2": defaultGeckoPrice,
      "wrapped-bitcoin": defaultGeckoPrice,
      binancecoin: defaultGeckoPrice,
    }))
}

async function getNeoPrice(): Promise<Response> {
  return fetch(
    'https://api.coingecko.com/api/v3/simple/price?ids=neo&vs_currencies=usd',
  )
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
      }
      return { neo: defaultNeoPrice }
    })
    .catch((error) => ({ neo: defaultNeoPrice }))
}

async function getNodeLatency(rpcUrl: string): Promise<number> {
  const start = Date.now()
  try {
    await axios.get(`${rpcUrl}/status`)
    const latency = Date.now() - start //in milisecond
    return latency
  }
  catch (err) {
    console.error(err)
    return 999
  }
}

async function getNodesRating(nodes: NodeModel[]): Promise<NodeModel[]> {
  for (const node of nodes) {
    //wsUptime, insightUpTime, rpcUpTime
    const wsWeightage = 3;
    const insightWeightage = 3;
    const rpcWeightage = 3;
    const latency = await getNodeLatency(node.rpcUrl)
    let latencyRating = 0.0
    switch (true) {
      case latency <= 100:
        latencyRating = 1
        break
      case latency <= 200:
        latencyRating = 0.8
        break
      case latency <= 400:
        latencyRating = 0.6
        break
      case latency <= 600:
        latencyRating = 0.4
        break
      case latency < 999:
        latencyRating = 0.2
        break
      default:
        latencyRating = 0.0
        break
    }
    const latencyWeightage = 1
    const rating = ((parseInt(node?.wsUptime) * wsWeightage) + (parseInt(node?.insightUptime) * insightWeightage) + (parseInt(node?.rpcUptime) * rpcWeightage))
    const ratingPercent = rating * 0.01 //convert to rating of 0.0 to 1 
    const latencyRatingPercent = latencyRating * latencyWeightage
    const finalRating = (ratingPercent + latencyRatingPercent) / (wsWeightage + insightWeightage + rpcWeightage + latencyWeightage)
    node.rating = finalRating.toFixed(2)
    node.latency = latency.toString()
  }
  return nodes
}

export default class App extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchValidators]),
      call([this, this.fetchMarkets]),
      call([this, this.fetchCoinGecko]),
      call([this, this.fetchNeo]),
      call([this, this.fetchSigningInfo]),
      call([this, this.fetchOracles]),
      call([this, this.fetchSWTHsupply]),
      call([this, this.fetchTokensInfo]),
      call([this, this.fetchTotalSupply]),
      call([this, this.fetchStakingPool]),
      call([this, this.fetchAllOracleTokenPrices]),

      call([this, this.initBlocks]),

      spawn([this, this.watchCarbonSDK]),
      spawn([this, this.watchSetNetwork]),
      spawn([this, this.listenBlocks]),
      spawn([this, this.updateAvgBlockTime]),
      spawn([this, this.pollNodes]),
      spawn(webSocketSaga)
    ]
  }

  private *fetchSWTHsupply(): any {
    yield runSagaTask(TaskNames.App.SwthSupply, function* () {
      const sdk = yield* waitforSDK()
      const bankQueryClient = sdk.query.bank

      const totalSupply = (yield call([bankQueryClient, bankQueryClient.TotalSupply], {})) as Models.Bank.QueryTotalSupplyResponse
      const swthSupply = totalSupply.supply.find((supply: any) => supply.denom === 'swth')
      yield put(setSWTHSupply(sdk.token.toHuman('swth', bnOrZero(swthSupply?.amount))))
    })
  }

  private *watchSetNetwork() {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
    const search = window.location.search
    const params = new URLSearchParams(search)
    const previousNet = params.get('net')
    logger("app saga", "watchSetNetwork", previousNet)
    if (previousNet !== null) {
      const network = (yield select((state) => state.app.network)) as CarbonSDK.Network
      if (network !== previousNet) {
        const net = parseNet(network)
        params.set('net', net)
        window.history.replaceState(
          '',
          '',
          `${window.location.pathname}?${params}`,
        )
      }
    }
  }

  private *watchCarbonSDK() {
    while (true) {
      try {
        const network = (yield selectState((state) => state.app.network)) as CarbonSDK.Network
        const selectedNodes = (yield selectState((state) => state.app.selectedNodes)) as SimpleMap<any>
        let sdk = (yield selectState((state) => state.core.carbonSDK)) as CarbonSDK | undefined
        if (!sdk) {
          yield runSagaTask(TaskNames.App.InitializeSDK, function* () {
            yield put(setConnectError(undefined))
            logger("app saga", "watchCarbonSDK runSagaTask")
            const coreState = (yield selectState((state) => state.core)) as CoreState
            const selectedNode = selectedNodes[network]
            const params = new URLSearchParams(window.location.search)
            const rawNetwork = params.get('net') ?? coreState.storedNetwork ?? network

            logger("app saga", "instantiate carbon sdk", CarbonSDK.parseNetwork(rawNetwork), selectedNode)
            const newNetwork = CarbonSDK.parseNetwork(rawNetwork)!

            sdk = (yield CarbonSDK.instance({
              network: newNetwork,
              config: {
                tmRpcUrl: selectedNode?.rpcUrl,
                tmWsUrl: selectedNode?.tmWsUrl,
                restUrl: selectedNode?.restUrl,
                wsUrl: selectedNode?.wsUrl,
                faucetUrl: selectedNode?.faucetUrl,
                insightsUrl: selectedNode?.insightsUrl,
              },
            })) as CarbonSDK

            if (coreState.storedMnemonics) {
              sdk = (yield call([sdk, sdk.connectWithMnemonic], coreState.storedMnemonics)) as CarbonSDK
            } else if (coreState.carbonSDK?.wallet) {
              sdk = (yield call([sdk, sdk.connect], coreState.carbonSDK?.wallet)) as CarbonSDK
            }

            yield put(updateCarbonSDK(sdk))
            const tmWsClient = selectedNode?.tmWsUrl ? (yield Tendermint37Client.connect(NetworkConfigs[newNetwork].tmWsUrl)) as Tendermint37Client : undefined;
            yield put(updateTmWsClient(tmWsClient))
          }, function* (error) {
            const selectedNodes = (yield selectState((state) => state.app.selectedNodes)) as SimpleMap<any>
            const connectError = (yield selectState((state) => state.app.connectError)) as Error | undefined
            if (!connectError) {
              yield put(setConnectError(error))
            }
            yield put(setSelectedNodes({
              ...selectedNodes,
              [network]: undefined,
            }))
          })
        }
      } finally {
        yield race({
          sdkUpdated: take(ActionType.CORE_UPDATE_CARBON_SDK),
        })
      }
    }
  }

  private *fetchValidators(): any {
    yield runSagaTask(TaskNames.App.Validators, function* () {
      const sdk = yield* waitforSDK()
      const validatorsResponse = (yield call([sdk.query.staking, sdk.query.staking.Validators], {
        status: '',
      })) as Models.Staking.QueryValidatorsResponse
      const carbonValidators = validatorsResponse.validators

      const tmValidatorResponse = (yield call([sdk.tmClient, sdk.tmClient.validatorsAll])) as ValidatorsResponse
      const tmValidators = tmValidatorResponse.validators;


      const valsetResponse = (yield sdk.query.cosmosTm.GetLatestValidatorSet({})) as GetLatestValidatorSetResponse;
      const valsetValidators = valsetResponse.validators ?? [];

      let totalBonded = BIG_ZERO

      const valAddrMap: SimpleMap<ValPair> = {};
      const delegatorsMap: SimpleMap<Models.Staking.DelegationResponse[]> = {}

      for (const validator of carbonValidators) {
        if (!validator.consensusPubkey) continue;

        const validatorAddress = validator.operatorAddress;
        const consensusPubkey = Buffer.from(validator.consensusPubkey.value).slice(2).toString('hex');
        const tmValidator = tmValidators.find((validator) => {
          if (!validator.pubkey?.data) return false;
          return Buffer.from(validator.pubkey.data).toString("hex") === consensusPubkey;
        });

        if (validator.status === BondStatus.BOND_STATUS_BONDED) {
          totalBonded = totalBonded.plus(bnOrZero(validator.tokens))
        }

        valAddrMap[validatorAddress] = {
          carbonValidator: validator,
          tmValidator,
          consAddress: valsetValidators.find((item) => Buffer.from(item.pubKey?.value ?? new Uint8Array()).slice(2).toString('hex') === consensusPubkey)?.address
        };
      }
      // const delegatorsList = (yield all(carbonValidators.map((val: Models.Staking.Validator) => {
      //   return call([sdk.query.staking, sdk.query.staking.ValidatorDelegations], {
      //     validatorAddr: val.operatorAddress,
      //   })
      // }))) as Models.Staking.QueryValidatorDelegationsResponse[]
      // carbonValidators.forEach((val, index) => {
      //   delegatorsMap[val.operatorAddress] = delegatorsList[index].delegationResponses
      // })
      yield put(setDelegators(delegatorsMap))
      yield put(actions.Core.updateValAddrMap(valAddrMap))
      yield put(setTotalBonded(totalBonded))
    })
  }

  private *fetchMarkets(): any {
    yield runSagaTask(TaskNames.App.Markets, function* () {
      const sdk = yield* waitforSDK()
      const marketQueryClient = sdk.query.market

      const marketsResponse = (yield call([marketQueryClient, marketQueryClient.MarketAll], {
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })) as QueryAllMarketResponse
      const marketMap = marketsResponse.markets.reduce((result, market) => {
        result[market.id] = getAdjustedMarket(market, sdk)
        return result
      }, {} as SimpleMap<Market>)
      yield put(setMarkets(marketMap))
    })
  }

  private *fetchSigningInfo(): any {
    yield runSagaTask(TaskNames.App.SigningInfo, function* () {
      const sdk = yield* waitforSDK()
      const slashingQueryClient = sdk.query.slashing

      const info = (yield call([slashingQueryClient, slashingQueryClient.SigningInfos], {})) as Models.Slashing.QuerySigningInfosResponse

      const output = {} as SimpleMap<Models.Slashing.ValidatorSigningInfo>
      info.info.forEach((item: Models.Slashing.ValidatorSigningInfo) => {
        output[item.address] = item
      })
      yield put(setSigningInfo(output))
    })
  }
  private *fetchStakingPool(): any {
    yield runSagaTask(TaskNames.App.StakingPool, function* () {
      const sdk = yield* waitforSDK()
      const stakingQueryClient = sdk.query.staking

      const pool = (yield call([stakingQueryClient, stakingQueryClient.Pool], {})) as Models.Staking.QueryPoolResponse
      const totalStaked = sdk.token.toHuman('swth', bnOrZero(pool.pool?.bondedTokens).plus(bnOrZero(pool.pool?.notBondedTokens)))

      yield put(setTotalStaked(totalStaked))
    })
  }

  private *fetchCoinGecko(): any {
    const geckoPriceUuid = uuidv4()

    yield put(actions.Layout.addBackgroundLoading(TaskNames.App.GeckoCoin, geckoPriceUuid))
    try {
      const results = (yield call(queryGecko)) as any
      yield put(setGeckoPrices(results))
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err)
    } finally {
      yield put(actions.Layout.removeBackgroundLoading(geckoPriceUuid))
    }
  }

  private *fetchNeo(): any {
    const neoUuid = uuidv4()

    yield put(actions.Layout.addBackgroundLoading(
      TaskNames.App.NeoPrice,
      neoUuid,
    ))
    try {
      const results = (yield call(getNeoPrice)) as any
      yield put(setNeoPrice(results.neo))
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error(err)
    } finally {
      yield put(actions.Layout.removeBackgroundLoading(neoUuid))
    }
  }

  private *fetchOracles(): any {
    yield runSagaTask(TaskNames.App.Oracles, function* () {
      const sdk = yield* waitforSDK()
      const oracleQueryClient = sdk.query.oracle
      const oracles = (yield call([oracleQueryClient, oracleQueryClient.ResultsLatest], {
      })) as QueryResultsResponse
      yield put(setOracles(oracles.results))
    });
  }

  private *fetchTokensInfo(): any {
    yield runSagaTask(TaskNames.Tokens.Info, function* () {
      const sdk = yield* waitforSDK()
      const allTokens = (yield sdk.token.getAllTokens()) as Token[]
      let allTokensMap: SimpleMap<Token> = {}
      for (const token of allTokens) {
        allTokensMap[token.id] = token
      }
      yield put(setTokensMap(allTokensMap))
    })
  }

  // TODO: Add swth supply on bsc and eth
  private *fetchTotalSupply(): any {
    yield runSagaTask(TaskNames.App.TotalSupply, function* () {
      const sdk = yield* waitforSDK()
      const bankQueryClient = sdk.query.bank

      const swthSupply = (yield call([bankQueryClient, bankQueryClient.TotalSupply], {
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })) as Models.Bank.QueryTotalSupplyResponse
      const swthSupplyIndex = swthSupply.supply.find((o) => o.denom === "swth")
      yield put(setTotalSupply(sdk.token.toHuman('swth', bnOrZero(swthSupplyIndex?.amount))))
    })
  }

  private *fetchAllOracleTokenPrices(): any {
    yield runSagaTask(TaskNames.App.AllOracleTokenPrices, function* () {
      const sdk = yield* waitforSDK()
      const tokenPricesResponse = (yield call([sdk.query.pricing, sdk.query.pricing.TokenPriceAll], {})) as QueryTokenPriceAllResponse
      yield put(setAllOracleTokenPrices(tokenPricesResponse?.tokenPrices))
    })
  }

  private *initBlocks(): any {
    try {
      const sdk = yield* waitforSDK()
      const network = sdk.network
      const miscQueryClient = sdk.query.misc
      const initBlockResult = (yield call([miscQueryClient, miscQueryClient.BlockAll], QueryAllBlockRequest.fromPartial({
        pagination: {
          limit: new Long(101),
          offset: Long.UZERO,
          countTotal: false,
          reverse: false,
        }
      })
      )) as QueryAllBlockResponse;
      logger("app saga", "init blocks", initBlockResult)
      const blocks = initBlockResult.blocks.map(SimpleBlock.fromBlock);
      emitEvent?.({ type: "block", result: blocks, network });
    } catch (error) {
      console.error("init blocks error")
      console.error(error)
    }
  }


  private *listenBlocks(): any {
    const listener = (network: CarbonSDK.Network) => ({
      next: (value: any) => {
        const block = SimpleBlock.fromBlockEvent(value);
        emitEvent?.({ type: 'block', result: [block], network })
      },
      error: (err: any) => {
        console.error("block subscription error")
        console.error(err)
      },
    });

    while (true) {
      logger("app saga", "listenBlocks")
      let subscription = null;
      const tmWsClient = yield* waitForTmWsClient()
      const network = (yield* waitforSDK())?.network
      try {
        const stream = tmWsClient.subscribeNewBlock()
        subscription = yield call([stream, stream.subscribe], listener(network));

        yield race({
          tmWsClientChanged: take(actions.Core.ActionType.CORE_UPDATE_TM_WS_CLIENT),
        });
      } catch (error) {
        console.error("listenBlocks error")
        console.error(error)
      } finally {
        logger("app saga", "unsubscribing blocks")
        subscription?.unsubscribe();
      }
    }
  }

  private *updateAvgBlockTime(): any {
    while (true) {
      try {
        const action = yield take(ActionType.CORE_UPDATE_BLOCKS);
        const blocks: SimpleBlock[] = action.blocks;
        if (blocks.length < 2) continue;

        const [latestBlock] = blocks.slice(0, 1);
        const [firstBlock] = blocks.slice(-1);
        const totalTime = latestBlock.time.diff(firstBlock.time, "milliseconds");
        const blockCount = latestBlock.height - firstBlock.height;
        const avgBlockTime = new BigNumber(totalTime).div(1000).div(blockCount - 1).dp(4).toNumber();
        yield put(setAvgBlockTime(avgBlockTime))
      } catch (error) {
        console.error(error)
      }
    }
  }

  // TODO: Finalise nodes API and replace 'any' type with model
  // Waiting for backend node list API completion
  private *pollNodes(): any {
    while (true) {
      try {
        yield runSagaTask(TaskNames.App.Nodes, function* () {
          const network = (yield selectState((state) => state.app.network)) as CarbonSDK.Network
          const selectedNodes = (yield selectState((state) => state.app.selectedNodes)) as SimpleMap<NodeModel>
          const defaultConfig = NetworkConfigs[network]
          const defaultNode = {
            nodeId: '',
            rpcUrl: defaultConfig.tmRpcUrl,
            restUrl: defaultConfig.restUrl,
            wsUrl: defaultConfig.wsUrl,
            faucetUrl: defaultConfig.faucetUrl,
            insightsUrl: defaultConfig.insightsUrl,
            tmWsUrl: defaultConfig.tmWsUrl,
            moniker: network + ' default node',
            appBuild: network,
            lastupdated: '',
            rpcUptime: '100',
            wsUptime: '100',
            insightUptime: '100',
            rating: '0',
            latency: '0',
          } as NodeModel

          // Add archive node(TODO: Remove if it is added to the node list API)
          const archiveNode = {
            nodeId: '',
            rpcUrl: 'https://tm-api-archive.carbon.network/',
            restUrl: 'https://api.carbon.network',
            wsUrl: 'wss://ws-api.carbon.network/ws',
            faucetUrl: '',
            insightsUrl: 'https://api-insights.carbon.network',
            tmWsUrl: 'wss://tm-api.carbon.network/',
            moniker: 'Mainnet Archive Node',
            appBuild: network,
            lastupdated: '',
            rpcUptime: '100',
            wsUptime: '100',
            insightUptime: '100',
            rating: '0',
            latency: '0',
          } as NodeModel

          let nodesWithRatings = []

          const sdk = (yield selectState((state) => state.core.carbonSDK)) as CarbonSDK | undefined
          const insightsClient = sdk?.insights ?? new InsightsQueryClient(defaultConfig)
          yield put(setNodes([defaultNode])) // set default node first before trying to get node list from insight
          yield put(setSelectedNodes({
            ...selectedNodes,
            [network]: defaultNode,
          }))
          try {
            const nodesResponse = (yield call([insightsClient, insightsClient.Nodes], {})) as Insights.InsightsQueryResponse<Insights.QueryGetNodesResponse>
            const nodes = [...nodesResponse?.result?.models] as NodeModel[]
            if (network === 'mainnet') {
              nodes.push(archiveNode) // Add archive node to the list (TODO: Remove when added to the node list API)
            }
            nodesWithRatings = (yield call(getNodesRating, nodes)) as NodeModel[]
            nodesWithRatings.sort((a: NodeModel, b: NodeModel) => parseInt(b.rating) - parseInt(a.rating))
            yield put(setNodes(nodes))
            const customNodes = (yield selectState((state) => state.app.customNodes)) as any[]
            const filteredCustomNodes = customNodes.filter((node) => node.appBuild === network)

            let selectedNode = nodesWithRatings.find((node: any) => node.moniker === selectedNodes[network]?.moniker)
              ?? filteredCustomNodes.find((node: any) => node.moniker === selectedNodes[network]?.moniker)
            if (!selectedNode) {
              selectedNode = nodesWithRatings[0]
            }
            yield put(setSelectedNodes({
              ...selectedNodes,
              [network]: selectedNode,
            }))
          } catch {
            // if both sdk and insights query client failed, set default node
            yield put(setNodes([defaultNode]))
            yield put(setSelectedNodes({
              ...selectedNodes,
              [network]: defaultNode,
            }))
          }
        })
      } finally {
        yield race({
          netUpdated: take(AppActionType.SET_NETWORK),
          delay: delay(300000), // 5mins
        })
      }
    }
  }
}
