import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import BackLink from './BackLink'

type Props = Omit<BoxProps, 'title'> & {
  title?: string | React.ReactNode
  rightMenu?: React.ReactNode
  backLink?: string
  backLabel?: string
  variant?: string
}

const Page: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const {
    title,
    className,
    children,
    rightMenu,
    backLink,
    backLabel,
    variant = 'h1',
    ...rest
  } = props
  const classes = useStyles(props)

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {backLink && (
        <BackLink to={backLink}>{backLabel || 'Back'}</BackLink>
      )}
      <Box className={classes.header}>
        {!!title && (
          <Typography className={classes.title} variant={variant as Variant}>
            {title}
          </Typography>
        )}
        {rightMenu}
      </Box>
      {children}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}))

export default Page
