import { updateTrades } from 'js/actions/account'
import { TradesByAccount } from 'js/components'
import { TaskNames } from 'js/constants'
import { RootState } from 'js/store'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'

export interface TradesProps {
  address: string
}

interface Props extends TradesProps { }

const TradesWrapper: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const { address } = props
  const tradesByAccount = useSelector((state: RootState) => state.account.tradesByAccount)
  const markets = useSelector((state: RootState) => state.app.markets)
  return (
    <>
      <TradesByAccount
        title="Recent Trades"
        tradesByAccount={tradesByAccount}
        action={updateTrades}
        markets={markets}
        address={address}
        loadname={[
          TaskNames.Account.TradesByAccount,
          TaskNames.Account.TradesFilter,
        ]}
        loadCondition={!tradesByAccount}
      />
    </>
  )
}

export default TradesWrapper
