import BigNumber from 'bignumber.js'
import { Models } from 'carbon-js-sdk'
import { Action } from 'redux'
import { RestModels, RestTypes } from 'tradehub-api-js'

export enum ValidatorActionType {
  CLEAR = 'CLEAR_VALIDATOR',
  SET_PROFILE = 'SET_PROFILE',
  SET_VALIDATOR = 'SET_VALIDATOR',
  SET_DELEGATIONS = 'SET_DELEGATIONS',
  SET_VALIDATOR_BLOCKS = 'SET_VALIDATOR_BLOCKS',
  UPDATE_BLOCKS_FILTERS = 'UPDATE_BLOCKS_FILTERS',
  SET_SELFBOND_AMOUNT = 'SET_SELFBOND_AMOUNT',
}

export type ValidatorAction =
  | ClearAction
  | SetProfileAction
  | SetValidatorAction
  | SetDelegationsAction
  | SetBlocksAction
  | SetSelfbondAmountAction

export interface ClearAction extends Action<ValidatorActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: ValidatorActionType.CLEAR,
  }
}

export interface SetProfileAction
  extends Action<ValidatorActionType.SET_PROFILE> {
  profile: RestModels.Profile
}

export function setProfile(profile: RestModels.Profile): SetProfileAction {
  return {
    type: ValidatorActionType.SET_PROFILE,
    profile,
  }
}

export interface SetValidatorAction
  extends Action<ValidatorActionType.SET_VALIDATOR> {
  validator: Models.Staking.Validator
}

export function setValidator(validator: Models.Staking.Validator): SetValidatorAction {
  return {
    type: ValidatorActionType.SET_VALIDATOR,
    validator,
  }
}

export interface SetDelegationsAction
  extends Action<ValidatorActionType.SET_DELEGATIONS> {
  delegations: Models.Staking.DelegationResponse[]
}

export function setDelegations(delegations: Models.Staking.DelegationResponse[]): SetDelegationsAction {
  return {
    type: ValidatorActionType.SET_DELEGATIONS,
    delegations,
  }
}

export interface SetBlocksAction
  extends Action<ValidatorActionType.SET_VALIDATOR_BLOCKS> {
  blocks: RestTypes.ResultsMinMax<RestModels.Block>
}
export function setBlocks(
  blocks: RestTypes.ResultsMinMax<RestModels.Block>,
): SetBlocksAction {
  return {
    type: ValidatorActionType.SET_VALIDATOR_BLOCKS,
    blocks,
  }
}

export interface UpdateFiltersAction
  extends Action<ValidatorActionType.UPDATE_BLOCKS_FILTERS> {
  options: any
}

export function updateBlocksFilters(options: any): UpdateFiltersAction {
  return {
    type: ValidatorActionType.UPDATE_BLOCKS_FILTERS,
    options,
  }
}

export interface SetSelfbondAmountAction
  extends Action<ValidatorActionType.SET_SELFBOND_AMOUNT> {
  selfbond: BigNumber
}

export function setSelfbondAmount(
  selfbond: BigNumber,
): SetSelfbondAmountAction {
  return {
    type: ValidatorActionType.SET_SELFBOND_AMOUNT,
    selfbond,
  }
}
