import BigNumber from 'bignumber.js'
import { ExtendedPool } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/query'
import { CommitmentCurve, RewardCurve } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/reward'
import { SimpleMap } from 'carbon-js-sdk/lib/util/type'
import { Record, RecordOf } from 'immutable'
import { LiquidityPoolsAction, LiquidityPoolsActionType } from 'js/actions/liquidityPools'
import { BIG_ZERO } from 'js/utils'

export interface LiquidityPoolsProps {
  pools: ExtendedPool[]
  rewards: BigNumber
  rewardCurve?: RewardCurve
  commitmentCurve?: CommitmentCurve
  poolsVolumeMap: SimpleMap<BigNumber>
  poolsTotalLiquidityChartData: any
  poolsVolumeLiquidityChartData: any
  poolsUtilisationChartData: any
}

const makeState: Record.Factory<LiquidityPoolsProps> = Record<LiquidityPoolsProps>({
  pools: [],
  rewards: BIG_ZERO,
  rewardCurve: undefined,
  commitmentCurve: undefined,
  poolsVolumeMap: {},
  poolsTotalLiquidityChartData: [],
  poolsVolumeLiquidityChartData: [],
  poolsUtilisationChartData: [],
})
export type LiquidityPoolsState = RecordOf<LiquidityPoolsProps>

function poolsReducer(
  state: LiquidityPoolsState = makeState(),
  action: LiquidityPoolsAction,
): LiquidityPoolsState {
  switch (action.type) {
    case LiquidityPoolsActionType.CLEAR:
      return makeState()
    case LiquidityPoolsActionType.SET_LIQUIDITY_POOLS:
      return state.updateIn(['pools'], () => action.pools)
    case LiquidityPoolsActionType.SET_WEEKLY_POOL_REWARDS:
      return state.updateIn(['rewards'], () => action.rewards)
    case LiquidityPoolsActionType.SET_REWARD_CURVE:
      return state.updateIn(['rewardCurve'], () => action.rewardCurve)
    case LiquidityPoolsActionType.SET_COMMITMENT_CURVE:
      return state.updateIn(['commitmentCurve'], () => action.commitmentCurve)
    case LiquidityPoolsActionType.SET_POOLS_VOLUME:
      return state.updateIn(['poolsVolumeMap'], () => action.poolsVolumeMap)
    case LiquidityPoolsActionType.SET_POOLS_TOTAL_LIQUIDITY_CHART:
      return state.updateIn(['poolsTotalLiquidityChartData'], () => action.poolsTotalLiquidityChartData)
    case LiquidityPoolsActionType.SET_POOLS_VOLUME_LIQUIDITY_CHART:
      return state.updateIn(['poolsVolumeLiquidityChartData'], () => action.poolsVolumeLiquidityChartData)
    case LiquidityPoolsActionType.SET_POOLS_UTILISATION_CHART:
      return state.updateIn(['poolsUtilisationChartData'], () => action.poolsUtilisationChartData)
    default:
      return state
  }
}

export default poolsReducer
