import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { addDays } from 'date-fns'
import { CARBON_GENESIS_BLOCKTIME } from 'js/constants'
import { switcheo } from 'js/theme/palettes/colors'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { createStaticRanges, DateRangePicker } from 'react-date-range'
import { useDispatch } from 'react-redux'

type Props = {
  ranges: any,
  onChange: any,
  setOpen: any,
  action: any,
}

const DateRangePickerUI: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { ranges, onChange, setOpen, action } = props
  const theme = useTheme()
  const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [dateRange, setDateRange] = React.useState<any>(ranges);
  const customRanges = [
    {
      label: 'Last 24 Hours',
      range: () => ({
        startDate: new Date(addDays(new Date(), -1)),
        endDate: new Date()
      })
    },
    {
      label: 'Last 7 Days',
      range: () => ({
        startDate: new Date(addDays(new Date(), -7)),
        endDate: new Date()
      }),
    },
    {
      label: 'Last 14 Days',
      range: () => ({
        startDate: new Date(addDays(new Date(), -14)),
        endDate: new Date()
      }),
    },
    {
      label: 'Last 30 Days',
      range: () => ({
        startDate: new Date(addDays(new Date(), -30)),
        endDate: new Date()
      }),
    },
    {
      label: 'Last 90 Days',
      range: () => ({
        startDate: new Date(addDays(new Date(), -90)),
        endDate: new Date()
      }),
    },
    {
      label: 'Last 180 Days',
      range: () => ({
        startDate: new Date(addDays(new Date(), -180)),
        endDate: new Date()
      }),
    },
    {
      label: 'All Time',
      range: () => ({
        startDate: new Date(CARBON_GENESIS_BLOCKTIME),
        endDate: new Date()
      }),
    },
    {
      label: 'Custom',
      range: () => ({
        startDate: new Date(),
        endDate: new Date()
      }),
      isSelected: () => {
        const diff = moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days')
        const allTimeDiff = moment(new Date()).diff(moment(CARBON_GENESIS_BLOCKTIME), 'days')
        const diffCheck = [180, 90, 30, 14, 7, 1, allTimeDiff].includes(diff)
        return !(diffCheck && moment(dateRange.endDate).format("YYYY-MM-DD") === (moment(new Date()).format("YYYY-MM-DD")))
      },
    },
  ] as any
  const totalRanges = [...createStaticRanges(customRanges)]
  const handleDateChange = (date: any) => {
    const dates = date.selection
    setDateRange(dates)
    if (dateRange.endDate !== dates.endDate && !moment(dates.startDate).isSame(moment(dates.endDate))) {
      onChange(date)
      setOpen(false)
      dispatch(
        action({
          startDate: dates.startDate,
          endDate: dates.endDate,
        }),
      )
    }
  }
  return (
    <DateRangePicker
      className={classes.root}
      ranges={[dateRange]}
      onChange={handleDateChange}
      minDate={new Date(CARBON_GENESIS_BLOCKTIME)}
      maxDate={moment().toDate()}
      inputRanges={[]}
      rangeColors={[switcheo.default.accent]}
      color={switcheo.black[100]}
      staticRanges={totalRanges}
      months={isMobileSm ? 1 : 2}
      direction="horizontal"
      editableDateInputs={true}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '8px',
    background: switcheo.background.grey,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
    '& .rdrDefinedRangesWrapper, & .rdrStaticRange, & .rdrCalendarWrapper, & .rdrMonthAndYearWrapper, & .rdrDateRangeWrapper, & .rdrDateDisplayWrapper': {
      background: switcheo.background.grey,
    },
    '& .rdrCalendarWrapper, & .rdrDateRangeWrapper': {
      borderRadius: '0 8px 8px 0',
      [theme.breakpoints.down('xs')]: {
        borderRadius: '8px',
      },
    },
    '& .rdrStaticRange': {
      '&:first-child': {
        borderRadius: '8px 0 0 0',
      },
      [theme.breakpoints.down('xs')]: {
        borderRadius: '8px 0 0 0',
      },
    },
    '& .rdrStaticRangeLabel': {
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    '& .rdrMonthAndYearWrapper': {
      borderRadius: '0 8px 0 0',
      [theme.breakpoints.down('xs')]: {
        borderRadius: '8px 8px 0 0',
      },
    },
    '& .rdrDefinedRangesWrapper': {
      width: 'max-content',
      borderRadius: '8px 0 0 8px',
      [theme.breakpoints.down('xs')]: {
        width: 'inherit',
        height: '80px',
        overflow: 'auto',
        borderRadius: '8px 0 8px 8px',
      },
    },
    '& .rdrDateDisplayItem': {
      boxShadow: 'none',
    },
    '& .rdrDateDisplayItem input': {
      color: switcheo.text.primary,
      background: switcheo.background.white,
      borderRadius: '4px',
    },
    '& .rdrDaySelected, & .rdrStartEdge, & .rdrEndEdge, & .rdrInRange, & .rdrDayToday .rdrDayNumber span:after, & .rdrNextPrevButton:hover, & .rdrStaticRange:hover .rdrStaticRangeLabel, & .rdrStaticRange:focus .rdrStaticRangeLabel': {
      background: switcheo.primary[200],
    },
    '& .rdrStaticRange:hover .rdrStaticRangeLabel': {
      '&:first-child': {
        borderRadius: '8px 0 0 0',
      }
    },
    '& .rdrMonthAndYearPickers select:hover': {
      backgroundColor: switcheo.primary[200]
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span, &.rdrDefinedRangesWrapper, &.rdrStaticRangeSelected, & .rdrWeekDay': {
      color: switcheo.text.primary,
      fontWeight: 'bold',
    },
    // underline for the current day
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, & .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, & .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, & .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after, & .rdrDayToday .rdrDayNumber span:after': {
      background: switcheo.text.primary,
    },
    '& .rdrNextPrevButton': {
      background: 'none',
    },
    '& .rdrMonthName': {
      color: switcheo.text.primary,
      borderRadius: '8px',
      textAlign: 'center',
      fontSize: '1rem',
    },
    '& .rdrDateDisplayWrapper': {
      borderRadius: '0 0 8px 0',
      order: '1',
      [theme.breakpoints.down('xs')]: {
        borderRadius: '0 0 8px 8px',
      },
    },
    '& .rdrDateRangePickWrapper': {
      zIndex: '1',
    },
  },
}))

export default React.memo(DateRangePickerUI)
