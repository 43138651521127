import { TableCell } from '@material-ui/core'
import React, { Fragment, ReactElement } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { CellLink, DatetimeCell } from '../components'

export type TableCellInfo = {
  align?: 'center'
    | 'inherit'
    | 'justify'
    | 'left'
    | 'right'
  className?: string
  dateBoxClass?: string
  content: any
  href?: string
  to?: string
  type?: 'date' | 'flag' | 'text'
  bold?: boolean
}

export const TableSectionCell: React.FunctionComponent<TableCellInfo> = (
  props: TableCellInfo,
): ReactElement<TableCellInfo> => {
  const { align = 'right', className, dateBoxClass, content, href, to, type = 'text', bold = false } = props
  const fontWeightStyling = { fontWeight: 700 };
  switch(type) {
    case 'date':
      return (
        <Fragment>
          <DatetimeCell className={className} dateBoxClass={dateBoxClass} data={content || '00-00-00'}/>
        </Fragment>
      )
    case 'flag':
      return (
        <TableCell className={className} style={bold ? fontWeightStyling : {}}>
          <ReactCountryFlag countryCode={content} />
        </TableCell>
      )
    default:
      return (
        <TableCell className={className} align={align || 'right' }  style={bold ? fontWeightStyling : {}}>
          {(to || href) && (
            <CellLink href={href} to={to}>{content ?? ''}</CellLink>
          )}
          {(!to && !href) && content}
        </TableCell>
      )
  }
}
