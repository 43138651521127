import { Box, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import { CaretDown, LoadChartIcon } from 'assets'
import clsx from 'clsx'
import { CARBON_GENESIS_BLOCKTIME } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import moment from 'moment'
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Section } from '.'
import ChartViewFilter from './ChartViewFilter'
import DateRangePickerUI from './DateRangePickerUI'
import ExportCSV from './ExportToExcel'

type Props = {
  title?: ReactNode
  children: ReactNode | any
  action?: any
  onSelectDate?: (data: any) => void
  onSelectView?: (data: string) => void
  className?: string
  footer?: string
  enableView?: boolean
  taskNames?: string[]
  date?: boolean
  maintenance?: boolean
  csvFileName?: string
}

const ChartContainer: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, children, action, className, footer, onSelectDate, enableView, taskNames, date = true, maintenance, csvFileName } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState<string>("day")
  const [clickToLoad, setClickToLoad] = React.useState<boolean>(false)
  const [loading] = useTaskSubscriber(...taskNames ?? [])
  const earliest = moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months'))
  const [dateRange, setDateRange] = React.useState<any>({
    startDate: earliest.toDate(),
    endDate: moment().toDate(),
    key: 'selection',
  });
  const startDate = dateRange.startDate
  const endDate = dateRange.endDate
  const dateRanges = useMemo(() => { return { startDate, endDate, key: 'selection' } }, [startDate, endDate])

  const isMobile = localStorage.mobile || window.navigator.maxTouchPoints > 1 || window.screen.width < 500;

  const handleDateChange = useCallback((date: any) => {
    const dates = date.selection
    setDateRange(dates)
    if (onSelectDate) {
      onSelectDate(dates)
    }
  }, [onSelectDate])

  useEffect(() => {
    if ((isMobile && !clickToLoad) || !action) return
    dispatch(
      action({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        interval: view
      }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, clickToLoad])
  return (
    <Section className={clsx(classes.chartContainer, className)}>
      {
        (!maintenance && isMobile && (!clickToLoad || (loading && clickToLoad))) && (
          <div className={classes.loadChartContainer}>
            {
              loading ? <CircularProgress />
                : <LoadChartIcon onClick={() => setClickToLoad(true)} />
            }
          </div>
        )
      }
      {
        maintenance && (
          <div className={classes.maintenanceContainer}>
            <Typography variant="h3">This Chart is currently unavaliable due to database maintenance</Typography>
          </div>
        )
      }
      {
        (!isMobile && loading) && (
          <div className={classes.normalLoadChartContainer}>
            <CircularProgress />
          </div>
        )
      }
      <Box className={classes.chartHeader}>
        {title}
        {
          date && (
            <div className={classes.datePickerContainer}>
              <div className={classes.datePicker} onClick={() => setOpen(!open)}>
                <Typography variant={"body1"} align="center" component={'span'}>
                  <span className={classes.datePickerText}>
                    {
                      (
                        dateRange?.startDate.getFullYear === moment(CARBON_GENESIS_BLOCKTIME).toDate().getFullYear &&
                        dateRange?.startDate.getMonth() === moment(CARBON_GENESIS_BLOCKTIME).toDate().getMonth() &&
                        dateRange?.startDate.getDate() === moment(CARBON_GENESIS_BLOCKTIME).toDate().getDate() &&
                        dateRange?.endDate.getFullYear() === moment().toDate().getFullYear()
                        && dateRange?.endDate.getMonth() === moment().toDate().getMonth()
                        && dateRange?.endDate.getDate() === moment().toDate().getDate()
                      ) ?
                        "All Time" :
                        `${moment(dateRange?.startDate).format("D MMM YYYY")}  -  ${moment(dateRange?.endDate).format("D MMM YYYY")}`
                    }
                  </span>
                  <CaretDown className={clsx(classes.datePickerButtonIcon, { open })} />
                </Typography>
              </div>
              {
                csvFileName && (<ExportCSV csvData={children?.props?.data ?? []} fileName={csvFileName ?? 'data'} />)
              }
              <div style={{ display: "inline-block" }}>
                {
                  enableView && (
                    <ChartViewFilter options={[
                      { value: "day", label: "Daily" },
                      { value: "week", label: "Weekly" },
                      { value: "month", label: "Monthly" }]}
                      className={classes.chartViewFilter}
                      onFilter={setView}
                    />
                  )
                }
                {
                  open && (<div className={classes.dateRangePickerUIContainer}>
                    <DateRangePickerUI
                      ranges={dateRanges}
                      onChange={handleDateChange}
                      setOpen={setOpen}
                      action={action}
                    />
                  </div>
                  )
                }
              </div>
            </div>
          )
        }
      </Box>
      {children}
      <div className={classes.chartFooter}>{footer}</div>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  chartViewFilter: {
    margin: "0px 10px",
    display: 'block'
  },
  chartContainer: {
    margin: "20px 0px",
    position: 'relative',
    height: '30em',
    paddingBottom: "5rem",
    [theme.breakpoints.down('xs')]: {
      paddingBottom: "9rem",
    },
  },
  chartFooter: {
    paddingTop: theme.spacing(1),
    fontSize: '0.5rem',
    display: 'flex',
    justifyContent: 'right',
  },
  chartHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  datePickerContainer: {
    position: 'relative',
    display: "inline-block",
    float: "right",
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      justifyContent: 'center',
      justifyItems: 'center',
      gridGap: '0.5rem'
    },
  },
  datePicker: {
    minWidth: "15em",
    fontWeight: 800,
    float: 'right',
    backgroundColor: switcheo.background.grey,
    padding: '5px 15px',
    borderRadius: 8,
    pointer: 'cursor'
  },
  datePickerText: {
    verticalAlign: 'sub',
  },
  dateRangePickerUIContainer: {
    maxWidth: '60rem',
    position: 'absolute',
    right: 0,
    top: 40,
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      left: 0,
    },
  },
  tooltip: {
    pointer: 'cursor',
  },
  datePickerButtonIcon: {
    float: 'right',
    transition: 'transform 0.3s',
    marginTop: '1px',
    '&.open': {
      transform: 'rotate(-180deg)',
    }
  },
  chartTitle: {
    display: 'block',
  },
  chartTitleFirst: {
    fontWeight: 'bold',
  },
  loadChartContainer: {
    background: 'rgb(255 255 255 / 80%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '1/1',
    width: '14rem',
    position: 'absolute',
    zIndex: 1,
    top: '36%',
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  maintenanceContainer: {
    background: 'rgb(255 255 255 / 80%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '2/1',
    maxWidth: '20rem',
    position: 'absolute',
    zIndex: 1,
    top: '36%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    padding: 20
  },
  normalLoadChartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: '1/1',
    width: '14rem',
    position: 'absolute',
    zIndex: 1,
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, 0)'
  }
}))

export default ChartContainer