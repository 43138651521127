import { Box, BoxProps, FormControl, Grid, Input, InputLabel, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { TooltipHint } from 'js/components'
import { TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { SetCommitmentCurveProposalFormState } from 'js/models/Governance'
import { actions } from 'js/store'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SetCommitmentCurveProposalInitialFormState } from '../constants'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import { setCommitmentCurveFormRequiredFields } from './Helpers/FormConstraints'

interface Props extends BoxProps {
}

const SetCommitmentCurveProposalMainForm: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const formState = (useRedux((state) => state.governance.submitProposalFormState) || SetCommitmentCurveProposalInitialFormState) as SetCommitmentCurveProposalFormState
  const requiredFieldsConstraints = getRequiredFieldsConstraints(setCommitmentCurveFormRequiredFields)

  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    dispatch(setSubmitProposalFormState(formState))
    validateRequiredFields(formState)
    // eslint-disable-next-line
  }, [])

  const validateRequiredFields = (formState: SetCommitmentCurveProposalFormState) => {
    const errors = getErrorMessages(formState, requiredFieldsConstraints)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const autoGenerateTitle = (formKey: keyof SetCommitmentCurveProposalFormState, inputValue: string): string => {
    const maxDuration = formKey === 'maxDuration' ? inputValue : formState.maxDuration
    const maxRewardMultiplier = formKey === 'maxRewardMultiplier' ? inputValue : formState.maxRewardMultiplier

    return `Set max duration to ${maxDuration} and max reward multiplier to ${maxRewardMultiplier}`
  }

  const handleFormChange = (key: keyof SetCommitmentCurveProposalFormState, isTitleInvolved?: boolean) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string

      const newFormState = {
        ...formState,
        [key]: value,
        title: isTitleInvolved ? autoGenerateTitle(key, value) : formState.title,
      }

      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Max Duration
              <TooltipHint title={'Maximum lock period in seconds'} />
            </InputLabel>
            <Input
              fullWidth
              type="number"
              value={formState.maxDuration}
              onChange={handleFormChange('maxDuration', true)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>
              Max Reward Multiplier
              <TooltipHint title={'Maximum yield multiplier in percentage. Value should not be less than 100 or it will have a negative multiplier effect'} />
            </InputLabel>
            <Input
              fullWidth
              type="number"
              value={formState.maxRewardMultiplier}
              onChange={handleFormChange('maxRewardMultiplier', true)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1rem",
  },
  warningIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  menuItems: {
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

export default SetCommitmentCurveProposalMainForm
