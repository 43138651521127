import { SagaIterator } from 'redux-saga'
import { all, call } from 'redux-saga/effects'
import App from './App'
import Router from './Router'

export default function* rootSaga(): SagaIterator {
  const router: Router = new Router()
  const app: App = new App()
  yield all([call([router, router.start]), call([app, app.start])])
}
