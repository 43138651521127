import { Box, BoxProps, CircularProgress, Divider, Hidden, IconButton, makeStyles, Radio, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core'
import { ArrowDown, ArrowUp, EditIcon, EyeOn, InfoIcon, Rating } from 'assets'
import clsx from 'clsx'
import { setFormNode, showNodeInfoForm } from 'js/actions/app'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

interface Props extends BoxProps {
  handleSelectNode: (node: any, forceUpdate?: boolean) => void
  recommendedNode: any
  selectingNode: any
}

const NodeTable: React.FC<Props> = (props: Props) => {
  const { children, className, handleSelectNode, recommendedNode, selectingNode, ...rest } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { nodes, network, customNodes, selectedNodes, connectError } = useRedux((state) => state.app)
  const [loading] = useTaskSubscriber(TaskNames.App.Nodes)
  const [sort, setSort] = useState({ prop: 'Rating', direction: 'desc' })
  const selectedNodeName = useMemo(() => selectedNodes[network]?.moniker, [selectedNodes, network])
  const handleViewNodeInfo = (node: any) => {
    dispatch(showNodeInfoForm())
    dispatch(setFormNode(node))
  }

  const sortFunc = (prop: string) => {
    if (prop === sort.prop) {
      setSort({
        ...sort,
        direction: sort.direction === 'asc' ? 'desc' : 'asc',
      })
      return
    }
    setSort({
      prop,
      direction: 'asc',
    })
  }

  const returnSortIcon = (sortKey?: string) => {
    if (sortKey && sortKey === sort.prop) {
      return sort.direction === 'asc'
        ? <ArrowUp className={clsx(classes.sortIcon, { single: sort.prop === sortKey })} />
        : <ArrowDown className={clsx(classes.sortIcon, { single: sort.prop === sortKey })} />
    }
    return undefined
  }

  const sortName = (nodes: any[], direction: string) => {
    return nodes.sort((nodeA: any, nodeB: any) => {
      const nameA = nodeA.moniker
      const nameB = nodeB.moniker
      if (nameA < nameB) {
        return direction === 'desc' ? 1 : -1
      }
      if (nameA > nameB) {
        return direction === 'desc' ? -1 : 1
      }
      return 0
    })
  }

  const sortRating = (nodes: any[], direction: string) => {
    return nodes.sort((nodeA: any, nodeB: any) => {
      const ratingA = nodeA.rating
      const ratingB = nodeB.rating
      return direction === 'desc' ? ratingB - ratingA : ratingA - ratingB
    })
  }


  interface UptimeStyle {
    bad?: boolean,
    good?: boolean,
    ok?: boolean
  }
  const colorizedUptime = (uptime: number = 0) => {
    let style: UptimeStyle = {}
    if (uptime < 60) style.bad = true
    else if (uptime < 90) style.ok = true
    else style.good = true
    return style
  }

  const colorizedLatency = (latency: number = 999) => {
    let style: UptimeStyle = {}
    if (latency > 300) style.bad = true
    else if (latency > 100) style.ok = true
    else style.good = true
    return style
  }

  const {
    sortedNodes,
    sortedCustomNodes,
  } = useMemo(() => {
    const filteredCustomNodes = customNodes.filter((node) => node.appBuild === network)
    switch (sort.prop) {
      case 'Name':
        return {
          sortedNodes: sortName(nodes, sort.direction),
          sortedCustomNodes: sortName(filteredCustomNodes, sort.direction),
        }
      case 'Rating':
        return {
          sortedNodes: sortRating(nodes, sort.direction),
          sortedCustomNodes: filteredCustomNodes,
        }
      default:
        return {
          sortedNodes: nodes,
          sortedCustomNodes: filteredCustomNodes,
        }
    }
  }, [nodes, customNodes, sort, network])

  const renderNode = (node: any, index: number, isCustom: boolean) => {
    return (
      <TableRow className={clsx({ [classes.topTable]: !isCustom })} key={index} hover>
        <TableCell>
          {selectingNode?.moniker === node.moniker && !connectError ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <Radio
              size="small"
              color="secondary"
              value={index}
              checked={node.moniker === selectedNodeName}
              classes={{
                root: classes.radio,
              }}
              onChange={() => handleSelectNode(node)}
            />
          )}
        </TableCell>
        <TableCell>
          <Box className={classes.nodeDetailsBox}>
            <Box className={classes.nodeNameBox}>
              <Typography className={clsx(classes.name, { [classes.selected]: node.moniker === selectedNodeName })}>
                {node.moniker}
              </Typography>
              {recommendedNode?.moniker === node.moniker && (
                <Box className={classes.recommendedTag}>Best</Box>
              )}
            </Box>
            <Hidden smDown>
              <Typography className={classes.url}>{node.rpcUrl}</Typography>
            </Hidden>
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex" flexDirection="row-reverse">
            <IconButton
              classes={{
                root: classes.iconButton,
              }}
              onClick={() => handleViewNodeInfo(node)}
            >
              {isCustom ? <EditIcon className={classes.icon} /> : <EyeOn className={classes.icon} />}
            </IconButton>
            {!isCustom && (
              <React.Fragment>
                <Tooltip arrow title={(
                  <Box className={classes.tooltip}>
                    <Box className={classes.attributeRow}>
                      <Typography className={classes.attribute}>Latency</Typography>
                      <Typography className={clsx(classes.value, { ...colorizedLatency(node?.latency) })}>
                        {node.latency}ms
                      </Typography>
                    </Box>
                    <Box className={classes.attributeRow}>
                      <Typography className={classes.attribute}>WS Uptime (24H)</Typography>
                      <Typography className={clsx(classes.value, { ...colorizedUptime(node?.wsUptime) })}>
                        {node.wsUptime}%
                      </Typography>
                    </Box>
                    <Box className={classes.attributeRow}>
                      <Typography className={classes.attribute}>RPC Uptime (24H)</Typography>
                      <Typography className={clsx(classes.value, { ...colorizedUptime(node?.rpcUptime) })}>
                        {node.rpcUptime}%
                      </Typography>
                    </Box>
                    <Box className={classes.attributeRow}>
                      <Typography className={classes.attribute}>Insight Uptime (24H)</Typography>
                      <Typography className={clsx(classes.value, { ...colorizedUptime(node?.insightUptime) })}>
                        {node.insightUptime}%
                      </Typography>
                    </Box>
                  </Box>
                )} placement="left">
                  <InfoIcon className={classes.icon} />
                </Tooltip>
                <Rating className={clsx(classes.indicator, {
                  five: node.rating >= 0.8,
                  four: node.rating >= 0.6 && node.rating < 0.8,
                  three: node.rating >= 0.4 && node.rating < 0.6,
                  two: node.rating >= 0.2 && node.rating < 0.4,
                  one: node.rating < 0.2,
                })} />
              </React.Fragment>
            )}
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Table className={classes.table}>
        <Hidden smDown>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell className={classes.headerCell} onClick={() => sortFunc('Name')}>
                <Box display="flex" alignItems="center">
                  Node Name
                  {returnSortIcon('Name')}
                </Box>
              </TableCell>
              <TableCell align="right" className={classes.headerCell} onClick={() => sortFunc('Rating')}>
                <Box display="flex" alignItems="center">
                  Node Rating
                  {returnSortIcon('Rating')}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
        </Hidden>
        {loading ? (
          <TableBody>
            {sortedNodes.map((node, index) => renderNode(node, index, false))}
            <TableRow className={classes.customHeader}>
              <TableCell colSpan={4}>
                <Box className={classes.loading}>
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {sortedNodes.map((node, index) => renderNode(node, index, false))}
          </TableBody>
        )}
        {sortedCustomNodes.length > 0 && (
          <TableBody>
            <TableRow className={classes.customHeader}>
              <TableCell colSpan={4}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Divider className={classes.tableDivider} />
                  Custom Nodes
                  <Divider className={classes.tableDivider} />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        <TableBody>{sortedCustomNodes.map((node, index) => renderNode(node, index, true))}</TableBody>
      </Table>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    maxHeight: '23.5rem',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  table: {
    backgroundColor: 'transparent',
    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingRight: 0,
      '&:first-child': {
        paddingRight: 0,
      },
      '&:last-child': {
        paddingLeft: 0,
      },
    },
    '& th': {
      fontSize: '0.75rem',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  topTable: {
    '& .MuiTableCell-root': {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: 'none',
    },
  },
  tableDivider: {
    backgroundColor: theme.palette.divider,
    width: '35%',
  },
  customHeader: {
    '& .MuiTableCell-root': {
      padding: 0,
      color: theme.palette.divider,
      fontSize: '0.75rem',
      borderBottom: 'none',
      '&:last-child': {
        padding: 0,
      },
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  selected: {
    fontWeight: 'bold',
  },
  radio: {
    padding: 0,
    color: theme.palette.secondary.main,
  },
  nodeDetailsBox: {
    wordBreak: 'break-all',
    [theme.breakpoints.up('md')]: {
      width: '12.5rem',
    },
  },
  nodeNameBox: {
    display: 'flex',
  },
  recommendedTag: {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`,
    margin: theme.spacing(0, 1),
    borderRadius: 4,
    fontSize: '0.625rem',
    letterSpacing: '-0.04em',
    height: '1rem',
    padding: theme.spacing(0, 0.25),
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  name: {
  },
  url: {
    fontSize: '0.75rem',
    color: theme.palette.text.disabled,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltip: {
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    flexDirection: 'column',
  },
  attributeRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  attribute: {
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(4),
  },
  value: {
    textTransform: 'capitalize',
    "&.bad": {
      color: theme.palette.error.light
    },
    "&.ok": {
      color: theme.palette.warning.light
    },
    "&.good": {
      color: theme.palette.success.light
    },
  },
  icon: {
    width: '1rem',
    verticalAlign: 'middle',
  },
  iconButton: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(5),
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
    },
    '& path': {
      fill: theme.palette.text.primary,
    },
  },
  sortIcon: {
    marginLeft: theme.spacing(0.5),
    height: '1rem',
    '&.single': {
      '& path': {
        fill: theme.palette.text.primary,
      },
    },
  },
  headerCell: {
    minWidth: '8rem',
    color: theme.palette.text.disabled,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  indicator: {
    margin: theme.spacing(0, 1),
    verticalAlign: 'middle',
    '& rect': {
      fill: theme.palette.text.disabled,
    },
    '&.five': {
      '& rect': {
        fill: theme.palette.success.main,
      },
    },
    '&.four': {
      '& rect': {
        fill: theme.palette.success.main,
        '&:last-child': {
          fill: theme.palette.text.disabled,
        },
      },
    },
    '&.three': {
      '& rect': {
        fill: theme.palette.warning.main,
        '&:nth-child(n+4)': {
          fill: theme.palette.text.disabled,
        },
      },
    },
    '&.two': {
      '& rect': {
        fill: theme.palette.warning.main,
        '&:nth-child(n+3)': {
          fill: theme.palette.text.disabled,
        },
      },
    },
    '&.one': {
      '& rect:first-child': {
        fill: theme.palette.error.main,
      },
    },
  },
}))

export default NodeTable
