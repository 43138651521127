import { createStyles, makeStyles } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import React from 'react'
import { useDispatch } from 'react-redux'
import { TradeHubSigner } from 'tradehub-api-js'

import Modal from 'js/components/Modal'
import { useRedux } from 'js/hooks'
import { setSignErrors } from 'js/actions/app'

interface Props {

}

const RequestSigningModal: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isSigningModalShown = useRedux((state) => state.app.isSigningModalShown)
  const sdk = useRedux((state) => state.core.sdk)
  const signError = useRedux((state) => state.app.signError)

  const onClose = () => {
    // clear error if signing is already completed
    if (!isSigningModalShown) {
      dispatch(setSignErrors(undefined))
    }
  }

  if (sdk?.wallet?.signer.type !== TradeHubSigner.Type.Ledger) return null

  return (
    <Modal
      className={classes.root}
      title="Approve Ledger Transaction"
      handleClose={onClose}
      canClose={!isSigningModalShown}
      showActions={false}
      open={isSigningModalShown || !!signError}
    >
      {!signError && (
        <Alert severity="info">
          <AlertTitle>Check your Ledger</AlertTitle>
          Authorize the transaction by selecting
          {' '}
          <strong>Approve</strong>
          {' '}
          on your Ledger device.
        </Alert>
      )}

      {!!signError && (
        <Alert severity="error">
          <AlertTitle>Ledger Error</AlertTitle>
          {signError}
        </Alert>
      )}
    </Modal>
  )
}

const useStyles = makeStyles((theme) => createStyles({
  root: {},
}))

export default RequestSigningModal
