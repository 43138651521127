import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import CellLink from 'js/components/CellLink'
import React, { ReactElement } from 'react'
import { ReactComponent as ArtworkSvg } from './art-submit-proposal.svg'

type Props = BoxProps

const Step1: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, className, children, ...rest } = props
  const classes = useStyles(props)

  return (
    <Box
      {...rest}
      className={clsx(classes.root, className)}
    >
      <ArtworkSvg className={classes.artwork} />
      <Typography variant='body1' paragraph>
      Proposals passed by voters are automatically implemented on Carbon and cannot be reversed. Therefore, extreme care should be taken to ensure proposals made are beneficial to the growth of the Carbon ecosystem.
      </Typography>
      <Typography variant='body1' paragraph>
        To have maximum clarity on the topics to propose for change, engage with the community
        on our <CellLink href='https://forum.switcheo.foundation/'>governance forum</CellLink> and <CellLink href='https://discord.com/invite/2bfwaVA'>Discord</CellLink>.
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  artwork: {
    display: 'block',
    margin: '35px auto 60px auto',
    maxWidth: '15rem',
    width: '100%',
  },
}))

export default Step1
