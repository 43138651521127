import { Divider, makeStyles, Theme, ListItem, Typography, Button } from '@material-ui/core'
import React, { ReactElement, useMemo, useState } from 'react'
import { CellLink, Section, TooltipHint } from 'js/components'
import dayjs, { Dayjs } from 'dayjs'
import TwitterIcon from '@material-ui/icons/Twitter'
import { copyTextToClipBoard } from 'js/helpers'
import { switcheo } from 'js/theme/palettes/colors'
import { CopyTradeDarkSVG } from 'assets'
import { CancelReason } from '../Order'

interface Props {
  address: string,
  orderId: string,
  username: string,
  orderStatus: string,
  initiator: string,
  blockCreatedAt: string,
  blockHeight: number,
  twitterHandler: string,
  isPosition?: boolean,
  orderCancelReason?: number
}

const OrderUserDetails: React.FunctionComponent<Props> = (props): ReactElement<Props> => {
  const classes = useStyles()
  const { orderId, username, address, orderStatus, initiator, blockCreatedAt, blockHeight, twitterHandler, isPosition, orderCancelReason } = props
  const time: Dayjs = dayjs(blockCreatedAt)
  const [copied, setCopied] = useState(false);

  const account = useMemo(() => {
    if (username.length) return username
    if (address.length) return address
    return '-'
  }, [address, username])

  const copyText = (text: string) => {
    copyTextToClipBoard(text)
    //set Copied to true to show Copied UI
    setCopied(true);
    setTimeout(() => setCopied(false), 1300)
  }

  return (
    <Section variant="primary" className={classes.section}>
      <ListItem disableGutters>
        <div className={classes.header}>{isPosition ? 'Position ID' : 'Order ID'}</div>
        <div className={classes.flexContainer}>
          <TooltipHint title={orderId}>
            <span className={classes.longString}>
              {orderId}
            </span>
          </TooltipHint>
          <TooltipHint title={"Copied!"} open={copied}>
            <CopyTradeDarkSVG onClick={() => copyText(orderId)} fontSize={"small"} className={classes.iconButton} />
          </TooltipHint>
        </div>
      </ListItem>
      <Divider
        className={classes.divider}
        variant="middle"
      />
      <ListItem disableGutters>
        <div className={classes.header}>Account</div>
        <div className={classes.longString}>
          <CellLink to={`/account/${address}`}>{account}</CellLink>
        </div>
      </ListItem>
      <Divider
        className={classes.divider}
        variant="middle"
      />
      <ListItem disableGutters className={classes.socialmedia}>
        <div className={classes.header}>Twitter</div>
        <div className={classes.string}>
          {twitterHandler}
          {
            twitterHandler !== "-" && (
              <Button
                // variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<TwitterIcon fontSize={'small'} className={classes.twitterIcon} />}
                href={`https://twitter.com/intent/follow?screen_name=${twitterHandler}&tw_p=followbutton`}
              >
                Follow
              </Button>
            )
          }
        </div>
      </ListItem>
      <Divider
        className={classes.divider}
        variant="middle"
      />
      <ListItem disableGutters>
        <div className={classes.header}>Status</div>
        <Typography className={classes.status} variant="h3">
          <div className={classes.string}>{orderStatus.toUpperCase()}</div>
        </Typography>
      </ListItem>
      <Divider
        className={classes.divider}
        variant="middle"
      />
      {
        orderStatus.toUpperCase() === "CANCELLED" && orderCancelReason && (
          <>
            <ListItem disableGutters>
              <div className={classes.header}>Reason</div>
              <Typography className={classes.status} variant="h3">
                <div className={classes.string}>{CancelReason[orderCancelReason]}</div>
              </Typography>
            </ListItem>
            <Divider
              className={classes.divider}
              variant="middle"
            />
          </>
        )
      }
      {
        !isPosition && (
          <>
            <ListItem disableGutters>
              <div className={classes.header}>Created By</div>
              <div className={classes.string}>{initiator.toUpperCase()}</div>
            </ListItem>
            <Divider
              className={classes.divider}
              variant="middle"
            />
          </>
        )
      }
      <ListItem disableGutters>
        <div className={classes.header}>Created At</div>
        <div className={classes.string}>{blockCreatedAt === "-" ? blockCreatedAt : time.format('YYYY-MM-DD HH:mm:ss')}</div>
      </ListItem>
      <Divider
        className={classes.divider}
        variant="middle"
      />
      <ListItem disableGutters>
        <div className={classes.header}>Height</div>
        <div className={classes.string}><CellLink to={`/block/${blockHeight}`}>{blockHeight}</CellLink></div>
      </ListItem>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    alignSelf: 'center',
    width: '100%',
    margin: theme.spacing(0.75, 0),
  },
  section: {
    height: '100%',
  },
  string: {
    wordBreak: 'break-word',
  },
  flexContainer: {
    display: 'contents'
  },
  longString: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flex: 1,
    overflow: 'hidden',
    display: 'inline-block'

  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
    minWidth: '7.7rem'
  },
  status: {
    fontSize: 'inherit'
  },
  socialmedia: {
    marginBottom: theme.spacing(7)
  },
  twitterIcon: {
    margin: theme.spacing(0, -0.5, 0, 0.5),
    fontSize: '1rem !important'
  },
  button: {
    borderRadius: 0,
    backgroundColor: switcheo.twitter, //* find where is the color plat
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0.25, 0.75),
    fontSize: 'xx-small',
    fontWeight: 'bold',
    minWidth: 0,
    textAlign: 'center',
    color: switcheo.default.white,
    wordBreak: 'normal',
    "&:hover": {
      backgroundColor: switcheo.twitterHover
    }
  },
  iconButton: {
    cursor: 'pointer'
  }
}))

export default OrderUserDetails
