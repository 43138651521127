import { useMediaQuery, useTheme } from '@material-ui/core';
import { bnOrZero } from 'carbon-js-sdk/lib/util/number';
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import 'chartjs-adapter-moment';
import chartPluginCrosshair from 'chartjs-plugin-crosshair';
import { CARBON_GENESIS_BLOCKTIME } from 'js/constants';
import { formatUtilisationChartData } from 'js/helpers';
import { switcheo } from 'js/theme/palettes/colors';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

type Props = {
  volume: any,
  liquidity: any,
  dateFilter: any,
}

const UtilisationChart: React.FunctionComponent<any> = (
  props: any,
): ReactElement<Props> => {
  const { volume, liquidity, dateFilter } = props
  const theme = useTheme()
  const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'))
  const maxXAxis = isMobileXs ? 3 : isMobileSm ? 5 : 10
  const toolTipPosition = isMobileSm ? 'top' : 'right' as const
  const yTitleShowWhenMobile = isMobileXs ? false : true
  const [chartData, setchartData] = useState<any>(undefined);
  const [chartLabels, setchartLabels] = useState<any>(undefined);
  const startDate = moment(CARBON_GENESIS_BLOCKTIME);
  const endDate = moment();
  const dateFilterStart = moment(dateFilter.startDate);
  const dateFilterEnd = moment(dateFilter.endDate);
  const needFilterDataByDate = startDate.format('ll') !== dateFilterStart.format('ll') || endDate.format('ll') !== dateFilterEnd.format('ll');

  ChartJS.register(...registerables, chartPluginCrosshair);
  useEffect(() => {
    let filteredVolume = volume;
    let filteredLiquidity = liquidity;

    if (needFilterDataByDate) {
      filteredVolume = volume.filter(
        (dailyData: any) =>
          moment(dailyData.date) >= dateFilter.startDate && moment(dailyData.date) <= moment(dateFilter.endDate).endOf('day'))
      filteredLiquidity = liquidity.filter(
        (dailyData: any) =>
          moment(dailyData.date) >= dateFilter.startDate && moment(dailyData.date) <= moment(dateFilter.endDate).endOf('day')
      )
    }
    if (volume && liquidity) {
      const [labels, chartData] = formatUtilisationChartData(filteredVolume, filteredLiquidity)
      setchartData(chartData)
      setchartLabels(labels)
    }
  }, [volume, liquidity, dateFilter, needFilterDataByDate,])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time' as 'time',
        time: {
          unit: 'day' as 'day',
          displayFormats: {
            day: 'DD MMM',
            month: 'MMM YY'
          },
          tooltipFormat: "ll",
        },
        ticks: {
          autoSkip: false,
          beginAtZero: true,
          alwaysShowLastTick: true,
          display: true,
          maxTicksLimit: maxXAxis,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
        }
      },
      y: {
        title: {
          display: yTitleShowWhenMobile,
          text: 'Ratio %',
        },
        ticks: {
          callback: function (val: any) {
            if (val <= 100) {
              return bnOrZero(val).toFormat();
            }
          },
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: true,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
        }
      }
    },
    plugins: {
      TimeScale: {
        x: {
          type: 'time'
        }
      },
      tooltip: {
        mode: 'index' as 'index',
        intersect: false,
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 10,
        boxPadding: 2,
        callbacks: {
          label: function (val: any) {
            return val.formattedValue + "%";
          },
        }
      },
      crosshair: {
        line: {
          color: switcheo.text.grey,
          width: 2,
          dashPattern: [5, 5],
        }
      },
      legend: {
        position: toolTipPosition as any,
        align: 'start' as const,
        fullSize: false,
        labels: {
          boxWidth: 20,
          boxHeight: 5,
        },
      },
    },
  };

  const finalData = {
    labels: chartLabels ?? [],
    datasets: [
      {
        label: 'Liquidity Utilisation Rate',
        fill: false,
        lineTension: 0.2,
        backgroundColor: switcheo.chart[0],
        borderColor: switcheo.chart[0],
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: switcheo.chart[0],
        pointBackgroundColor: switcheo.chart[0],
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: switcheo.chart[0],
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: chartData?.utilisationRate ?? [],
      },
    ],
  };

  return (
    <Line options={options} data={finalData} />
  )
}

export default UtilisationChart

