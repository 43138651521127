import useRedux from './useRedux'

const SYMBOL_OVERRIDE: {
  [symbol: string]: string
} = {
  NNEO: 'nNEO',
}

interface NameMap {
  [denom: string]: string
}

let tokenMap: NameMap = {}

export const assetSymbol = (denom?: string, overrideMap: NameMap = {}) => {
  if (typeof denom !== 'string') return ''

  // Convert denom to lowercase in case developer inputs a denom with uppercase characters
  const symbol = (tokenMap?.[denom?.toLowerCase()] ?? denom ?? '-').toUpperCase()
  if (SYMBOL_OVERRIDE[symbol]) {
    return SYMBOL_OVERRIDE[symbol]
  }

  if (overrideMap[symbol]) return overrideMap[symbol]

  return symbol
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (overrideMap: NameMap = {}) => {
  const tokensMap = useRedux((state) => state.app.tokensMap)
  for (const key in tokensMap) { // eslint-disable-line @typescript-eslint/no-unused-vars
    const value = tokensMap[key]
    tokenMap[value.denom ?? '-'] = value.symbol ?? value.denom ?? '-'
  }
  return (denom?: string, labelOverrides: NameMap = overrideMap) => assetSymbol(denom, labelOverrides)
}
