import { Insights } from "carbon-js-sdk";
import { AllianceAsset } from "carbon-js-sdk/lib/codec";
import { APYMap } from "js/reducers/alliance";
import { Action } from "redux";

export enum AllianceActionType {
  CLEAR = 'CLEAR_ALLIANCE',
  SET_ALLIANCE_ASSETS = 'SET_ALLIANCE_ASSETS',
  SET_ALLIANCE_APY = 'SET_ALLIANCE_APY',
  FETCH_ALLIANCES_STAKE_CHART = 'FETCH_ALLIANCES_STAKE_CHART',
  FETCH_ALLIANCES_REWARDS_CHART = 'FETCH_ALLIANCES_REWARDS_CHART',
  SET_ALLIANCES_STAKE_CHART = 'SET_ALLIANCES_STAKE_CHART',
  SET_ALLIANCES_REWARDS_CHART = 'SET_ALLIANCES_REWARD_CHART',
}

export type AllianceAction =
  | ClearAction
  | SetAllianceAssetsAction
  | SetAllianceAPYAction
  | FetchAlliancesRewardsChartAction
  | FetchAlliancesStakeChartAction
  | SetAlliancesRewardsChartDataAction
  | SetAlliancesStakeChartDataAction

interface ClearAction extends Action<AllianceActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: AllianceActionType.CLEAR,
  }
}

interface SetAllianceAssetsAction extends Action<AllianceActionType.SET_ALLIANCE_ASSETS> {
  allianceAssets: AllianceAsset[]
}
export function setAllianceAssets(allianceAssets: AllianceAsset[]): SetAllianceAssetsAction {
  return {
    type: AllianceActionType.SET_ALLIANCE_ASSETS,
    allianceAssets
  }
}

interface SetAllianceAPYAction extends Action<AllianceActionType.SET_ALLIANCE_APY> {
  denomToAPYMap: APYMap
}
export function setAllianceAPY(denomToAPYMap: APYMap): SetAllianceAPYAction {
  return {
    type: AllianceActionType.SET_ALLIANCE_APY,
    denomToAPYMap
  }
}

export interface FetchAlliancesStakeChartAction extends Action<AllianceActionType.FETCH_ALLIANCES_STAKE_CHART> {
  options: any
}

export function fetchAlliancesStakeChart(options: any): FetchAlliancesStakeChartAction {
  return {
    type: AllianceActionType.FETCH_ALLIANCES_STAKE_CHART,
    options,
  }
}

export interface FetchAlliancesRewardsChartAction extends Action<AllianceActionType.FETCH_ALLIANCES_REWARDS_CHART> {
  options: any
}

export function fetchAlliancesRewardsChart(options: any): FetchAlliancesRewardsChartAction {
  return {
    type: AllianceActionType.FETCH_ALLIANCES_REWARDS_CHART,
    options,
  }
}

interface SetAlliancesStakeChartDataAction extends Action<AllianceActionType.SET_ALLIANCES_STAKE_CHART> {
  alliancesStakeChartData: Insights.AlliancesStake[]
}

export function setAlliancesStakeChartData(alliancesStakeChartData: Insights.AlliancesStake[]): SetAlliancesStakeChartDataAction {
  return {
    type: AllianceActionType.SET_ALLIANCES_STAKE_CHART,
    alliancesStakeChartData
  }
}

interface SetAlliancesRewardsChartDataAction extends Action<AllianceActionType.SET_ALLIANCES_REWARDS_CHART> {
  alliancesRewardsChartData: Insights.AlliancesRewards[]
}

export function setAlliancesRewardsChartData(alliancesRewardsChartData: Insights.AlliancesRewards[]): SetAlliancesRewardsChartDataAction {
  return {
    type: AllianceActionType.SET_ALLIANCES_REWARDS_CHART,
    alliancesRewardsChartData
  }
}