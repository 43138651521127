import { ValidatorsResponse } from '@cosmjs/tendermint-rpc/build/tendermint37'
import { Insights, Models } from 'carbon-js-sdk'
import { PageRequest } from 'carbon-js-sdk/lib/codec/cosmos/base/query/v1beta1/pagination'
import { GetLatestValidatorSetResponse } from "carbon-js-sdk/lib/codec/cosmos/base/tendermint/v1beta1/query"
import { BondStatus } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/staking'
import dayjs from 'dayjs'
import { CARBON_GENESIS_BLOCKTIME, TaskNames, validatorDelegationsLimit } from 'js/constants'
import { actions } from 'js/store'
import { BIG_ZERO, SimpleMap, ValPair, bnOrZero } from 'js/utils'
import moment from 'moment'
import { SagaIterator } from 'redux-saga'
import { Effect, all, call, put, spawn, takeLatest } from 'redux-saga/effects'
import { AppActionType, setDelegators, setTotalBonded } from '../actions/app'
import { ValidatorsActionType, clear, setDistributionParams, setSlashingParams, setTotalStakedChartData } from '../actions/validators'
import Saga from './Saga'
import webSocketSaga from './WebSocket'
import { runSagaTask, waitforSDK } from './helper'

export default class Validators extends Saga {
  private readonly isMobile: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchSlashingParameters]),
      call([this, this.fetchDistributionParameters]),
      call([this, this.fetchValidators]),
      call([this, this.fetchTotalStakedChart]),
      spawn([this, this.watchSetNetwork]),
      spawn([this, this.watchDateFilters]),
      spawn(webSocketSaga),

    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *watchDateFilters(): any {
    yield takeLatest(ValidatorsActionType.UPDATE_DATE_FILTERS, this.handleDateFilters)
  }

  private *handleDateFilters(action: any): any {
    yield runSagaTask(TaskNames.Validators.DateFilter, function* () {
      const sdk = yield* waitforSDK()
      const from = dayjs(action.options.startDate).unix().toString()
      const until = dayjs(action.options.endDate).unix().toString()
      const totalStakedResponse = (yield call([sdk.insights, sdk.insights.Stake], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetStakeResponse>
      yield put(setTotalStakedChartData(totalStakedResponse.result.entries))
    })
  }

  private *fetchTotalStakedChart(): any {
    if (this.isMobile) return
    yield runSagaTask(TaskNames.Validators.TotalStakedChart, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon 
      const from = moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = dayjs(Date.now()).unix().toString()
      const totalStakedResponse = (yield call([sdk.insights, sdk.insights.Stake], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetStakeResponse>
      yield put(setTotalStakedChartData(totalStakedResponse.result.entries))
    })
  }

  private *fetchSlashingParameters(): any {
    yield runSagaTask(TaskNames.Validators.Slashing, function* () {
      const sdk = yield* waitforSDK()
      const slashingQueryClient = sdk.query.slashing

      const params = (yield call([slashingQueryClient, slashingQueryClient.Params], {})) as Models.Slashing.QueryParamsResponse
      yield put(setSlashingParams(params.params))
    })
  }

  private *fetchDistributionParameters(): any {
    yield runSagaTask(TaskNames.Validators.Distribution, function* () {
      const sdk = yield* waitforSDK()
      const distributionQueryClient = sdk.query.distribution

      const params = (yield call([distributionQueryClient, distributionQueryClient.Params], {})) as Models.Distribution.QueryParamsResponse
      yield put(setDistributionParams(params.params))
    })
  }

  private *fetchValidators(): any {
    yield runSagaTask(TaskNames.App.Validators, function* () {
      const sdk = yield* waitforSDK()
      const validatorsResponse = (yield call([sdk.query.staking, sdk.query.staking.Validators], {
        status: '',
      })) as Models.Staking.QueryValidatorsResponse
      const carbonValidators = validatorsResponse.validators

      const tmValidatorResponse = (yield call([sdk.tmClient, sdk.tmClient.validatorsAll])) as ValidatorsResponse
      const tmValidators = tmValidatorResponse.validators;
      const valsetResponse = (yield sdk.query.cosmosTm.GetLatestValidatorSet({})) as GetLatestValidatorSetResponse;
      const valsetValidators = valsetResponse.validators ?? [];

      let totalBonded = BIG_ZERO

      const valAddrMap: SimpleMap<ValPair> = {};
      const delegatorsMap: SimpleMap<Models.Staking.DelegationResponse[]> = {}

      for (const validator of carbonValidators) {
        if (!validator.consensusPubkey) continue;

        const validatorAddress = validator.operatorAddress;
        const consensusPubkey = Buffer.from(validator.consensusPubkey.value).slice(2).toString('hex');
        const tmValidator = tmValidators.find((validator) => {
          if (!validator.pubkey?.data) return false;
          return Buffer.from(validator.pubkey.data).toString("hex") === consensusPubkey;
        });

        if (validator.status === BondStatus.BOND_STATUS_BONDED) {
          totalBonded = totalBonded.plus(bnOrZero(validator.tokens))
        }

        valAddrMap[validatorAddress] = {
          carbonValidator: validator,
          tmValidator,
          consAddress: valsetValidators.find((item) => Buffer.from(item.pubKey?.value ?? new Uint8Array()).slice(2).toString('hex') === consensusPubkey)?.address
        };
      }
      // get from other saga store
      const delegatorsList = (yield all(carbonValidators.map((val: Models.Staking.Validator) => {
        return call([sdk.query.staking, sdk.query.staking.ValidatorDelegations], {
          validatorAddr: val.operatorAddress,
          pagination: PageRequest.fromPartial({
            limit: validatorDelegationsLimit
          })
        })

      }))) as Models.Staking.QueryValidatorDelegationsResponse[]
      carbonValidators.forEach((val, index) => {
        delegatorsMap[val.operatorAddress] = delegatorsList[index].delegationResponses
      })
      yield put(setDelegators(delegatorsMap))
      yield put(actions.Core.updateValAddrMap(valAddrMap))
      yield put(setTotalBonded(totalBonded))
    })
  }
}
