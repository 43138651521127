import { Block } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/block'
import { Record, RecordOf } from 'immutable'
import { BlocksAction, BlocksActionType } from '../actions/blocks'

export interface BlockProp {
  id: string
  height: number
  time: string
  txs: readonly Uint8Array[]
  proposerAddress: string
}

export interface BlockResponse {
  models: Block[]
  meta: {
    totalPages: number
  }
}

export interface BlocksProps {
  blocks: BlockResponse
}
const makeState: Record.Factory<BlocksProps> = Record<BlocksProps>({
  blocks: {
    models: [],
    meta: {
      totalPages: 0,
    }
  },
})
export type BlocksState = RecordOf<BlocksProps>

function blocksReducer(
  state: BlocksState = makeState(),
  action: BlocksAction,
): BlocksState {
  switch (action.type) {
    case BlocksActionType.CLEAR:
      return makeState()
    case BlocksActionType.SET_BLOCKS:
      return state.set('blocks', action.blocks)
    default:
      return state
  }
}

export default blocksReducer
