import dayjs, { Dayjs } from 'dayjs'

function parseString(num: number, singularSuffix: string, puralSuffix: string) {
  if (num > 1) return `${num} ${puralSuffix} ago`
  if (num > 0) return `${num} ${singularSuffix} ago`
  if (num < -1) return `${Math.abs(num)} ${puralSuffix} more`
  return `${Math.abs(num)} ${singularSuffix} more`
}

export function fromNow(time: Dayjs): string {
  const now: Dayjs = dayjs()

  const diffInYears: number = now.diff(time, 'year')
  if (diffInYears > 10) {
    return ''
  }
  if (diffInYears) {
    return parseString(diffInYears, 'year', 'years')
  }

  const diffInMonths: number = now.diff(time, 'month')
  if (diffInMonths) {
    return parseString(diffInMonths, 'month', 'months')
  }

  const diffInDays: number = now.diff(time, 'day')
  if (diffInDays) {
    return parseString(diffInDays, 'day', 'days')
  }

  const diffInHours: number = now.diff(time, 'hour')
  if (diffInHours) {
    return parseString(diffInHours, 'hour', 'hours')
  }

  const diffInMinutes: number = now.diff(time, 'minute')
  if (diffInMinutes) {
    return parseString(diffInMinutes, 'minute', 'minutes')
  }

  const diffInSeconds: number = now.diff(time, 'second')
  return parseString(diffInSeconds, 'second', 'seconds')
}
