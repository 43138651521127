import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

interface Props {
  children: any
  className?: string
  title: string
  titleClass?: string
}

const PaperBox: React.FunctionComponent<Props> = ({
  children,
  className,
  title,
  titleClass,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  return (
    <Box className={clsx(classes.container, className)} p={2}>
      <Typography noWrap className={clsx(classes.title, titleClass)}>
        {title}
      </Typography>
      {children}
    </Box>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    backgroundColor: theme.palette.primary.light,
    minHeight: 150,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 8,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '22px',
    fontWeight: 700,
    minHeight: 30,
  },
}))

export default PaperBox
