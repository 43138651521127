import { TableBody, TableHead, TableRow } from '@material-ui/core'
import { BigNumber } from 'bignumber.js'
import { CarbonSDK } from 'carbon-js-sdk'
import { Commitment } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/reward'
import { DataLoadSegment, ListTable, Section, TableCellInfo, TableEmptyState, TableSectionCell } from 'js/components'
import { TaskNames } from 'js/constants'
import { useAsyncTask, useTaskSubscriber } from 'js/hooks'
import { FullTokenBalance } from 'js/models/Balance'
import { RootState } from 'js/store'
import { BIG_ZERO, bnOrZero } from 'js/utils'
import { getUserCDPBalance } from 'js/views/Cdp/helper'
import Long from 'long'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { UserCDPBalance } from '../Account'
import HoldingsRow from './HoldingsRow'
import { Pool } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/liquiditypool'
import { ethers } from 'ethers'
import { SWTHAddress } from 'carbon-js-sdk/lib/util/address'

interface Props {
  address: string
}

const balanceOfABI = [
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

const Holdings: React.FunctionComponent<Props> = ({
  address
}: Props): ReactElement<Props> => {
  const balance = useSelector((state: RootState) => state.account.balance)
  const [loading] = useTaskSubscriber(TaskNames.Account.Holdings)
  const delegations = useSelector((state: RootState) => state.account.delegations)
  const sdk = useSelector((state: RootState) => state.core.carbonSDK)
  const [getLiquidityPools] = useAsyncTask('getLiquidityPools')
  const [liquidityPools, setLiquidityPools] = useState<Pool[]>([])
  const [stakedMap, setStakedMap] = useState<Record<string, BigNumber>>({})
  const [isSingleLpLoading, setSingleLpLoading] = useState(false)
  const [singleLpDetails, setSingleLpDetails] = useState<Record<string, string>>({})
  const [userCDPBalance, setUserCDPBalance] = useState<UserCDPBalance[]>([])
  const [loadingCdp, setLoadingCdp] = useState(false)
  const [userEVMBalance, setUserEVMBalance] = useState<any>([])

  const balanceRemovedLpN = useMemo(() => Object.values(balance).filter((balance) => !CarbonSDK.TokenClient.isPoolToken(balance.denom) && !CarbonSDK.TokenClient.isCdpToken(balance.denom)), [balance])
  const allLpTokenArrayN = useMemo(() => Object.values(balance).filter((balance) => CarbonSDK.TokenClient.isPoolToken(balance.denom) && !CarbonSDK.TokenClient.isCdpToken(balance.denom)), [balance])
  const allCIBTTokens = useMemo(() => Object.values(balance).filter((balance) => CarbonSDK.TokenClient.isCdpToken(balance.denom)), [balance])

  useEffect(() => {
    if (!address || !sdk || loadingCdp || loading) return
    const processCDP = async () => {
      setLoadingCdp(true)
      const userCDPBalanceResult = await getUserCDPBalance(allCIBTTokens, address, sdk)
      setLoadingCdp(false)
      setUserCDPBalance(userCDPBalanceResult)
    }
    processCDP()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, sdk, allCIBTTokens, loading])

  const getStakedTokenInfo = useCallback((committedLpDenoms: Commitment[]) => {
    if (!sdk || !address) return
    const stakedLPMap: any = {}
    committedLpDenoms.forEach((denom) => {
      stakedLPMap[denom.liquidity?.denom ?? ""] = denom.liquidity?.amount
    })
    return stakedLPMap
  }, [address, sdk])

  const lpDenoms = useMemo(() => [...allLpTokenArrayN.map((balance) => balance.denom)], [allLpTokenArrayN]);
  const lpDenomLength = lpDenoms.length
  useEffect(() => {
    if (!address || isSingleLpLoading) return
    const getSingleLpDetails = async () => {
      if (!isSingleLpLoading && lpDenomLength > 0) {
        setSingleLpLoading(true)
        //get all committed pool tokens
        const allCommittedPoolTokens = await sdk?.query.liquiditypool.CommitmentAll({ address: address })
        const singleLpDetailsMap = getStakedTokenInfo(allCommittedPoolTokens?.commitments ?? [])
        setSingleLpDetails(singleLpDetailsMap)
        setSingleLpLoading(false)
      }
    }
    getSingleLpDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpDenomLength, lpDenoms, address]);

  const finalLpDetails = useMemo(() => {
    const finalAllLpToken = new Map(); //
    allLpTokenArrayN.forEach((balance) => {
      const { denom, available } = balance;
      const availableBN = new BigNumber(available)
      finalAllLpToken.set(denom, { ...balance, available: availableBN })
    })
    for (let [denom, amount] of Object.entries(singleLpDetails)) {
      const token = finalAllLpToken.get(denom)
      const amountBN = bnOrZero(amount)
      if (token) {
        finalAllLpToken.set(denom, { ...token, available: token?.available?.plus(amountBN) ?? BIG_ZERO })
      }
      else {
        finalAllLpToken.set(denom, {
          available: amountBN,
          denom: denom,
          order: 0,
          position: 0
        })
      }
    }
    return finalAllLpToken
  }, [allLpTokenArrayN, singleLpDetails])

  const getLpTokenRatio = (amt: number, lpDenom: string) => {
    const tokenABIndex = liquidityPools?.findIndex((lps: any) => lps.denom === lpDenom)
    if (tokenABIndex > 0) {
      const tokenAB = liquidityPools[tokenABIndex]
      const denomA = tokenAB.denomA
      const denomB = tokenAB.denomB
      const tokenABAvailableBN = new BigNumber(amt)
      const poolSharesAmountBN = new BigNumber(tokenAB.sharesAmount)
      const poolTokenAAmountBN = new BigNumber(tokenAB.amountA)
      const poolTokenBAmountBN = new BigNumber(tokenAB.amountB)
      const poolSharesBN = tokenABAvailableBN.div(poolSharesAmountBN)
      const tokenA = poolSharesBN.times(poolTokenAAmountBN)
      const tokenB = poolSharesBN.times(poolTokenBAmountBN)
      return { denomA, tokenA, denomB, tokenB }
    }
    return {}
  }

  useEffect(() => {
    if (finalLpDetails?.size) {
      let stakedMapTemp: Record<string, BigNumber> = {}
      finalLpDetails.forEach((lp) => {
        const lpTokenInfo = getLpTokenRatio(lp.available, lp.denom)
        if (lpTokenInfo?.denomA && lpTokenInfo?.denomB) {
          const { denomA, tokenA, denomB, tokenB } = lpTokenInfo
          if (stakedMapTemp[denomA]) {
            stakedMapTemp[denomA] = stakedMapTemp[denomA].plus(tokenA)
          }
          else {
            stakedMapTemp[denomA] = tokenA
          }
          if (stakedMapTemp[denomB]) {
            stakedMapTemp[denomB] = stakedMapTemp[denomB].plus(tokenB)
          }
          else {
            stakedMapTemp[denomB] = tokenB
          }
        }
      })
      setStakedMap(stakedMapTemp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalLpDetails, liquidityPools])

  //get Liquidity
  useEffect(() => {
    getLiquidityPools(async () => {
      if (!sdk) return
      const pools = await sdk.query.liquiditypool.PoolAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      const allPools = pools?.pools?.map((pool) => pool.pool)
      setLiquidityPools(allPools as Pool[] ?? [])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  const stakedTokenMap = useMemo(() => {
    let delegatedTokenMap: any = {}
    delegations.forEach((tk) => {
      const { denom, amount } = tk.balance!
      if (!delegatedTokenMap[denom]) delegatedTokenMap[denom] = bnOrZero(amount)
      else delegatedTokenMap[denom] = bnOrZero(amount).plus(delegatedTokenMap[denom])
    })
    return delegatedTokenMap
  }, [delegations]);


  const headerCells: TableCellInfo[] = [
    {
      align: 'inherit',
      content: 'Tokens',
    },
    {
      content: 'Total',
      bold: true,
    },
    {
      content: 'Available',
    },
    {
      content: 'Order',
    },
    {
      content: 'Position',
    },
    {
      content: 'Staked',
    },
    {
      content: 'Lent',
    },
    {
      content: 'Collateral',
    },
    {
      content: 'EVM',
    }
  ]

  useEffect(() => {
    if (!sdk || !address) return
    const getEVMBalances = async () => {
      //get all evm tokens
      const tokenInfo = await sdk.token.getAllTokens()
      const evmTokens = (await sdk.query.erc20.TokenPairs({}))?.tokenPairs
      const evmMergeAcc = await sdk.query.evmmerge.MappedEvmAddress({ address })
      const toAddressBytes = SWTHAddress.getAddressBytes(evmMergeAcc?.mappedAddress, sdk.network);
      const toAddressHex = Buffer.from(toAddressBytes).toString("hex");
      const batchCalls: any[] = []
      const urlPrefix = sdk.network === 'mainnet' ? '' : sdk.network === 'testnet' ? 'test-' : ''
      const provider = new ethers.providers.JsonRpcBatchProvider(`https://${urlPrefix}evm-api.carbon.network`)
      for (const evmToken of evmTokens) {
        const { erc20Address, denom } = evmToken
        const contract = new ethers.Contract(erc20Address, balanceOfABI, provider)
        const balanceCall = {
          denom,
          call: contract.balanceOf(`0x${toAddressHex}`)
        }
        batchCalls.push(balanceCall)
      }
      // const balance = await contract.balanceOf('0x1E9Eb8d2320A52DCD91A4016b1471947844ed903')
      // Execute all batch calls
      const results = (await Promise.all(batchCalls.map(({ call }) => call))) as ethers.BigNumber[]
      const resultsParsed = results.map((amt, index) => {
        const denom = batchCalls[index].denom
        const decimals = tokenInfo.find((o) => o.denom === denom)?.decimals
        return { denom, amt: amt.toString(), decimals }
      })
      setUserEVMBalance(resultsParsed)
    }
    getEVMBalances()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!sdk, address])

  const finalBalance = useMemo(() => {
    if (loading) return []
    // check if stakedMap or UserCDPBalance contains token that is not in user wallet
    for (const stakedTokenDenom of Object.keys(stakedMap)) {
      const isInBalanceList = balanceRemovedLpN.find((o) => o.denom === stakedTokenDenom)
      if (!isInBalanceList) balanceRemovedLpN.push({
        available: "0",
        order: "0",
        position: "0",
        denom: stakedTokenDenom
      })
    }
    for (const cdpToken of userCDPBalance) {
      const isInBalanceList = balanceRemovedLpN.find((o) => o.denom === cdpToken.underlyingDenom)
      if (!isInBalanceList) balanceRemovedLpN.push({
        available: "0",
        order: "0",
        position: "0",
        denom: cdpToken.underlyingDenom
      })
    }
    for (const evmBalance of userEVMBalance) {
      const { denom } = evmBalance
      const isInBalanceList = balanceRemovedLpN.find((o) => o.denom === denom)
      if (!isInBalanceList) balanceRemovedLpN.push({
        available: "0",
        order: "0",
        position: "0",
        denom: denom
      })
    }
    return [...balanceRemovedLpN.map((bal) => {
      const { available, order, position, denom } = bal
      const availableBN = sdk?.token.toHuman(denom, bnOrZero(available)) ?? BIG_ZERO
      const orderBN = sdk?.token.toHuman(denom, bnOrZero(order)) ?? BIG_ZERO
      const positionBN = sdk?.token.toHuman(denom, bnOrZero(position)) ?? BIG_ZERO
      let totalBN = availableBN.plus(orderBN).plus(positionBN)
      let stakedAmount = BIG_ZERO
      if (stakedTokenMap[denom]) {
        stakedAmount = sdk?.token.toHuman(denom, stakedTokenMap[denom]) ?? BIG_ZERO
        totalBN = totalBN.plus(stakedAmount)
      }
      if (stakedMap[denom]) {
        const stakedBN = bnOrZero(stakedMap[denom])
        const stakedBNShifted = sdk?.token.toHuman(denom, stakedBN) ?? BIG_ZERO
        totalBN = totalBN.plus(stakedBNShifted)
        stakedAmount = stakedAmount.plus(stakedBNShifted)
      }
      const cdpBal = userCDPBalance.find((cdpBal) => cdpBal.underlyingDenom === denom)
      if (cdpBal) {
        totalBN = totalBN.plus(cdpBal.underlying).plus(cdpBal.collateralized)
      }
      const evmBalance = userEVMBalance.find((evmToken: any) => evmToken.denom === denom)
      if (evmBalance) {
        const { amt, decimals } = evmBalance
        totalBN = totalBN.plus(new BigNumber(amt).shiftedBy(-decimals))
      }
      return {
        total: totalBN,
        available: availableBN,
        order: orderBN,
        position: positionBN,
        staked: stakedAmount,
        denom,
        supplied: bnOrZero(cdpBal?.underlying),
        collateralized: bnOrZero(cdpBal?.collateralized),
        evm: bnOrZero(evmBalance?.amt).shiftedBy(-(evmBalance?.decimals ?? 0))
      }
    })]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(userEVMBalance).length, balanceRemovedLpN.length, stakedMap.length, loading, Object.keys(stakedTokenMap)?.length, sdk, userCDPBalance.length])

  return (
    <Section title="Wallet Balance">
      <DataLoadSegment
        loading={loading || loadingCdp}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              {headerCells.map((cell: any, index: number) => (
                <TableSectionCell key={index} {...cell} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalBalance?.filter((o: FullTokenBalance) => o.total.isGreaterThan(0))
              .sort((a: FullTokenBalance, b: FullTokenBalance) => Number(b.total.toString()) - Number(a.total.toString()))
              .map((balance: FullTokenBalance, index: number) => (
                <HoldingsRow
                  key={index}
                  {...balance}
                />
              ))}
          </TableBody>
        </ListTable>
        {!balance?.length && (
          <TableEmptyState itemName="balance records" />
        )}
      </DataLoadSegment>
    </Section>
  )
}

export default Holdings
