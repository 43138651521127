import { makeStyles, TableBody, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import { ListTable, Section } from 'js/components'
import { SignedBlocksCount, TooltipsMsgs } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { bnOrZero } from 'js/utils'
import React, { ReactElement, useEffect, useState } from 'react'
import { ProfileDetailRow } from '../components'

interface Props {
  validator: any
}

type RowInfo = {
  keyClass?: string
  title: string
  tooltip?: string
  value: any
  valueClass?: string
}

type RowsData = RowInfo[]

const CurrentPool: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { validator } = props
  const classes = useStyles()
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [signedBlockWindow, setSignedBlockWindow] = useState<BigNumber>(SignedBlocksCount)
  const [getSignedBlockWindow] = useAsyncTask("getSignedBlockWindow")
  useEffect(() => {
    if (!sdk) return
    getSignedBlockWindow(async () => {
      const slashingParams = await sdk?.query.slashing.Params({})
      const signedBlockBN = bnOrZero(slashingParams?.params?.signedBlocksWindow.toNumber() ?? 216000)
      setSignedBlockWindow(signedBlockBN)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  const rows: RowsData = [
    {
      title: 'Total Stake',
      tooltip: TooltipsMsgs.totalStake,
      value: cellFormat(signedBlockWindow, '', validator?.tokens),
      valueClass: classes.rowValue,
    },
    {
      title: 'Self Stake',
      tooltip: TooltipsMsgs.selfStake,
      value: cellFormat(signedBlockWindow, '', validator?.selfBond),
      valueClass: classes.rowValue,
    },
    {
      title: 'Delegator shares',
      value: cellFormat(signedBlockWindow, '', validator?.delegatorShares),
      valueClass: classes.rowValue,
    },
    {
      title: 'Delegators',
      value: cellFormat(signedBlockWindow, '', validator?.delegators),
      valueClass: classes.rowValue,
    },
    {
      title: 'Bond status',
      value: cellFormat(signedBlockWindow, '', validator?.bondStatus),
      valueClass: clsx(classes.bondStatus, classes.rowValue),
    },
    {
      title: 'Commission',
      value: cellFormat(signedBlockWindow, 'percentage', validator?.commissionRate),
      tooltip: TooltipsMsgs.commission_rate,
      valueClass: classes.rowValue,
    },
    {
      title: 'Max rate',
      value: cellFormat(signedBlockWindow, 'percentage', validator?.maxRate),
      valueClass: classes.rowValue,
    },
    {
      title: 'Max change rate',
      value: cellFormat(signedBlockWindow, 'percentage', validator?.maxChangeRate),
      valueClass: classes.rowValue,
    },
    {
      title: 'Jailed',
      value: cellFormat(signedBlockWindow, 'status', validator?.jailed),
      valueClass: classes.rowValue,
    },
    {
      keyClass: classes.endRow,
      title: 'Uptime',
      tooltip: TooltipsMsgs.uptime,
      value: cellFormat(signedBlockWindow, 'uptime', validator?.missedBlocksCount),
      valueClass: classes.endRowValue,
    },
  ]

  return (
    <Section title="Current Pool" titleClass={classes.title}>
      <ListTable>
        <TableBody>
          {
            rows.map((row, id) =>
              <ProfileDetailRow key={id} {...row} />,
            )
          }
        </TableBody>
      </ListTable>
    </Section>
  )
}

const cellFormat = (signedBlockWindow: BigNumber, name: string, value: any) => {
  switch (name) {
    case 'percentage':
      if (value) {
        return `${parseFloat(value).toFixed(2)} %`
      }
      break
    case 'status':
      if (value) {
        return 'Yes'
      }
      return 'No'

    case 'uptime':
      if (typeof value === 'undefined') {
        return '0%'
      }
      const missedBlocks = new BigNumber(value)
      if (missedBlocks.comparedTo(signedBlockWindow) === 1) {
        return `100% (0)`
      }
      return `${signedBlockWindow.minus(missedBlocks).div(signedBlockWindow).times(100).toFormat(2)}% (${value})`
    default:
      if (value) {
        return value
      }
      break
  }
  return "-"
}

const useStyles = makeStyles((theme: Theme) => ({
  bondStatus: {
    textTransform: 'capitalize',
  },
  rowValue: {
    '&.MuiTableCell-root:last-child': {
      textAlign: 'right !important',
    },
  },
  endRow: {
    "&.MuiTableCell-root": {
      borderBottom: '1px solid transparent',
    },
  },
  endRowValue: {
    "&.MuiTableCell-root": {
      borderBottom: '1px solid transparent',
      '&:last-child': {
        textAlign: 'right !important',
      },
    },
  },
  title: {
    textAlign: 'left',
  },
}))

export default CurrentPool
