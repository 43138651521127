import { Record, RecordOf } from 'immutable'
import { SubmitProposalFormState, SubspaceParamsMap } from 'js/models/Governance'
import { SimpleMap } from 'js/utils'
import { GovernanceAction, GovernanceActionTypes } from '../actions/governance'
import { DepositParams, Proposal, TallyParams, TallyResult } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/gov'
import { DelegationResponse } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/staking'
import { Transaction } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/transaction'

export interface TallyResultResponse {
  tally: TallyResult
}

export interface GovernanceProps {
  proposal: Proposal | null
  proposals: Proposal[]
  depositParams?: DepositParams
  tallyParams?: TallyParams
  delegations: DelegationResponse[]
  liveVoteTallies: SimpleMap<TallyResultResponse>
  depositTxs: Transaction[]
  voteTxs: Transaction[]
  isSpamProposalsFiltered: boolean
  isMyProposals: boolean
  submitProposalFormState: SubmitProposalFormState | null
  submitProposalFormValidationErrors: string[]
  submitProposalDepositAmount: string
  isSubmittingProposal: boolean
  submitProposalId: number | null
  submitProposalError: Error | null
  subspaceParams: SubspaceParamsMap | undefined
}

const makeState: Record.Factory<GovernanceProps> = Record<GovernanceProps>({
  proposal: null,
  proposals: [],
  depositParams: undefined,
  tallyParams: undefined,
  delegations: [],
  liveVoteTallies: {},
  depositTxs: [],
  voteTxs: [],
  isSpamProposalsFiltered: true,
  isMyProposals: false,
  submitProposalFormState: null,
  submitProposalFormValidationErrors: [],
  submitProposalDepositAmount: '',
  isSubmittingProposal: false,
  submitProposalId: null,
  submitProposalError: null,
  subspaceParams: undefined,
})
export type GovernanceState = RecordOf<GovernanceProps>

function governanceReducer(
  state: GovernanceState = makeState(),
  action: GovernanceAction,
): GovernanceState {
  switch (action.type) {
    case GovernanceActionTypes.SET_PROPOSAL:
      return state.updateIn(['proposal'], () => action.proposal)
    case GovernanceActionTypes.SET_PROPOSALS:
      return state.updateIn(['proposals'], () => action.proposals)
    case GovernanceActionTypes.SET_DEPOSIT_PARAMS:
      return state.updateIn(['depositParams'], () => action.depositParams)
    case GovernanceActionTypes.SET_TALLY_PARAMS:
      return state.updateIn(['tallyParams'], () => action.tallyParams)
    case GovernanceActionTypes.SET_DELEGATIONS:
      return state.updateIn(['delegations'], () => action.delegations)
    case GovernanceActionTypes.APPEND_LIVE_VOTE_TALLY:
      const newMap = state.liveVoteTallies
      newMap[action.id] = { tally: action.tally }
      return state.updateIn(['liveVoteTallies'], () => newMap)
    case GovernanceActionTypes.CLEAR_LIVE_VOTE_TALLY:
      return state.updateIn(['liveVoteTallies'], () => { })
    case GovernanceActionTypes.SET_DEPOSIT_TXS:
      return state.updateIn(['depositTxs'], () => action.txs)
    case GovernanceActionTypes.SET_VOTE_TXS:
      return state.updateIn(['voteTxs'], () => action.txs)
    case GovernanceActionTypes.SET_IS_SPAM_PROPOSALS_FILTERED:
      return state.updateIn(['isSpamProposalsFiltered'], () => action.isSpamProposalsFiltered)
    case GovernanceActionTypes.SET_IS_MY_PROPOSALS:
      return state.updateIn(['isMyProposals'], () => action.isMyProposals)
    case GovernanceActionTypes.SET_SUBMIT_PROPOSAL_FORM_STATE:
      return state.updateIn(['submitProposalFormState'], () => action.form)
    case GovernanceActionTypes.CLEAR_SUBMIT_PROPOSAL_FORM_STATE:
      return state.updateIn(['submitProposalFormState'], () => null)
    case GovernanceActionTypes.SET_SUBMIT_PROPOSAL_FORM_VALIDATION_ERRORS:
      return state.updateIn(['submitProposalFormValidationErrors'], () => action.errors)
    case GovernanceActionTypes.SET_SUBMIT_PROPOSAL_DEPOSIT_AMOUNT:
      return state.updateIn(['submitProposalDepositAmount'], () => action.amount)
    case GovernanceActionTypes.SET_IS_SUBMITTING_PROPOSAL:
      return state.updateIn(['isSubmittingProposal'], () => action.isSubmitting)
    case GovernanceActionTypes.SET_SUBMIT_PROPOSAL_ID:
      return state.updateIn(['submitProposalId'], () => action.id)
    case GovernanceActionTypes.SET_SUBMIT_PROPOSAL_ERROR:
      return state.updateIn(['submitProposalError'], () => action.msg)
    case GovernanceActionTypes.SET_SUBSPACE_PARAMS:
      return state.updateIn(['subspaceParams'], () => action.params)
    default:
      return state
  }
}

export default governanceReducer
