import { ConnectedRouter } from 'connected-react-router'
import App from 'js/layouts/App'
import store, { history } from 'js/store'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'
//Calendar css
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Chart, registerables } from 'chart.js'
import chartPluginCrosshair from 'chartjs-plugin-crosshair';

ReactGA.initialize('UA-119697219-9')

Chart.defaults.set("animation", { duration: 1000 })
Chart.register(...registerables, chartPluginCrosshair)

const Root = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </ConnectedRouter>
  </Provider>
)

ReactDOM.render(Root, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
