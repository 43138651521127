import { Insights } from "carbon-js-sdk";
import { AllianceAsset } from "carbon-js-sdk/lib/codec";
import { Record, RecordOf } from "immutable";
import { AllianceAction, AllianceActionType } from "js/actions/alliance";

export interface APYMap {
  [denom: string]: number
}

export interface AllianceProps {
  allianceAssets: AllianceAsset[]
  denomToAPYMap: APYMap
  alliancesStakeChartData: Insights.AlliancesStake[]
  alliancesRewardsChartData: Insights.AlliancesRewards[]
}

const makeState: Record.Factory<AllianceProps> = Record<AllianceProps>({
  allianceAssets: [],
  denomToAPYMap: {},
  alliancesStakeChartData: [],
  alliancesRewardsChartData: [],
})

export type AllianceState = RecordOf<AllianceProps>

function allianceReducer(
  state: AllianceState = makeState(),
  action: AllianceAction,
): AllianceState {
  switch (action.type) {
    case AllianceActionType.CLEAR:
      return makeState()
    case AllianceActionType.SET_ALLIANCE_ASSETS:
      return state.updateIn(['allianceAssets'], () => action.allianceAssets)
    case AllianceActionType.SET_ALLIANCE_APY:
      return state.updateIn(['denomToAPYMap'], () => action.denomToAPYMap)
    case AllianceActionType.SET_ALLIANCES_STAKE_CHART:
      return state.updateIn(['alliancesStakeChartData'], () => action.alliancesStakeChartData)
    case AllianceActionType.SET_ALLIANCES_REWARDS_CHART:
      return state.updateIn(['alliancesRewardsChartData'], () => action.alliancesRewardsChartData)
    default:
      return state
  }
}

export default allianceReducer