import { Button, Tooltip } from "@material-ui/core";
import {
    GetApp
} from '@material-ui/icons';
import BigNumber from "bignumber.js";
import * as FileSaver from "file-saver";
import { bnOrZero } from "js/utils";
import React from "react";
import * as XLSX from "xlsx";

type Props = {
    csvData: Object[];
    fileName: string;
};

const ExportCSV: React.FC<Props> = ({ csvData, fileName }) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (csvData: any, fileName: string) => {
        let ws: XLSX.WorkSheet = []
        let wb: any = { Sheets: {}, SheetNames: [] };
        switch (fileName) {
            case 'marketVolume': {
                const result: any[] = []
                const formattedData = csvData.map((o: any) => { return { date: o.date, volume: o.volumeValue } })
                ws = XLSX.utils.json_to_sheet(formattedData);
                csvData.forEach((o: any) => {
                    o.markets.forEach((market: any) => {
                        result.push({
                            date: o.date,
                            market: market.market,
                            volume: market.volumeValue,
                        })
                    })
                })
                wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                if (result.length) {
                    wb.Sheets.dataByMarket = XLSX.utils.json_to_sheet(result);
                    wb.SheetNames.push("dataByMarket")
                }
                break;
            }
            case 'activeUserReturningVsNew': {

                const formatCsvData: any[] = []
                const newUserData = csvData?.datasets.find((o: any) => o.label === "New Users")
                const returningData = csvData?.datasets.find((o: any) => o.label === "Returning Users")
                returningData?.data?.forEach((o: any, index: number) => formatCsvData.push({ date: o.x?.format(), newUsers: newUserData?.data?.[index].y, returning: o.y }))
                ws = XLSX.utils.json_to_sheet(formatCsvData);
                wb.Sheets.activeUsersReturningVsNew = ws;
                wb.SheetNames.push("activeUsersReturningVsNew")
                break;
            }
            case 'totalUser': {
                const formatCsvData = csvData?.datasets[0].data?.map((o: any) => { return { date: o.x?.format(), usersCount: o.y } })
                ws = XLSX.utils.json_to_sheet(formatCsvData);
                wb.Sheets.totalUsers = ws;
                wb.SheetNames.push("totalUsers")
                break;
            }
            case 'activeUser': {
                const dailyData = csvData?.datasets.find((o: any) => o.label === "Daily Active Users")
                const weeklyData = csvData?.datasets.find((o: any) => o.label === "Weekly Active Users")
                const monthlyData = csvData?.datasets.find((o: any) => o.label === "Monthly Active Users")
                const formatCsvDataDaily = dailyData?.data?.map((o: any) => { return { date: o.x?.format(), usersCount: o.y } })
                const wsDaily = XLSX.utils.json_to_sheet(formatCsvDataDaily);
                const formatCsvDataMonthly = monthlyData?.data?.map((o: any) => { return { date: o.x?.format(), usersCount: o.y } })
                const wsMonthly = XLSX.utils.json_to_sheet(formatCsvDataMonthly);
                const formatCsvDataWeekly = weeklyData?.data?.map((o: any) => { return { date: o.x?.format(), usersCount: o.y } })
                const wsWeekly = XLSX.utils.json_to_sheet(formatCsvDataWeekly);

                wb.Sheets.dailyActiveUser = wsDaily
                wb.SheetNames.push("dailyActiveUser")
                wb.Sheets.weeklyActiveUser = wsWeekly
                wb.SheetNames.push("weeklyActiveUser")
                wb.Sheets.monthlyActiveUser = wsMonthly
                wb.SheetNames.push("monthlyActiveUser")
                break;
            }
            case 'distribution': {
                const result: any[] = []
                const summaryPage = []
                const distributionByDay: any = {}
                csvData?.datasets.map((data: { data: any; }) => data.data)
                // ws = XLSX.utils.json_to_sheet(fake ?? []);
                csvData?.datasets.forEach((o: any) => {
                    o.data.forEach((denom: any) => {
                        if (!distributionByDay[denom?.x?.format()]) distributionByDay[denom?.x?.format()] = bnOrZero(denom.y)
                        else distributionByDay[denom?.x?.format()] = distributionByDay[denom?.x?.format()].plus(bnOrZero(denom.y))
                        result.push({
                            date: denom?.x?.format(),
                            denom: o.label,
                            balanceShifted: denom.z,
                            balanceValue: denom.y,
                        })
                    })
                })

                for (const [date, amountValue] of Object.entries(distributionByDay)) {
                    summaryPage.push({ date, amountValue: (amountValue as BigNumber)?.toNumber() })
                }
                // wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                if (summaryPage.length) {
                    wb.Sheets.summary = XLSX.utils.json_to_sheet(summaryPage);
                    wb.SheetNames.push("summary")
                }
                if (result.length) {
                    wb.Sheets.dataByDenom = XLSX.utils.json_to_sheet(result);
                    wb.SheetNames.push("dataByDenom")
                }
                break;
            }
            default:
                break;
        }
        if (wb && wb?.SheetNames?.length) {
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });

            FileSaver.saveAs(data, fileName + fileExtension);
        }
    };

    return (
        <Button onClick={(e: any) => exportToCSV(csvData, fileName)}>
            <Tooltip title={"Download as excel"}>
                <GetApp />
            </Tooltip>
        </Button>
    );
};

export default ExportCSV
