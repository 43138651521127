import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { Network } from 'carbon-js-sdk/lib/constant'
import RequestSigningModal from 'js/components/RequestSigningModal'
import createTheme, { ThemeKey } from 'js/theme/createTheme'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import Body from './Body'
import Footer from './Footer'
import Header from './Header'
import Tutorials from './Tutorials'

interface Props { }

const App: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const network = useSelector((state: any) => state.app.network)
  // const mode = useSelector((state: any) => state.app.mode)
  // const [theme, setTheme] = useState(createTheme(MuiTheme.Mode.Bright))
  const newTheme = createTheme("bright" as ThemeKey)
  // useEffect(() => {
  //   console.log("setting theme")
  //   setTheme(newTheme)
  // }, [mode])
  return (
    <ThemeProvider theme={newTheme}>
      <CssBaseline />
      <Header network={network as Network} />
      <Body />
      <Footer />
      <Tutorials />
      <RequestSigningModal />
    </ThemeProvider>
  )
}

export default App
