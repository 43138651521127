import { useMediaQuery, useTheme } from '@material-ui/core';
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market';
import { bnOrZero } from 'carbon-js-sdk/lib/util/number';
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import 'chartjs-adapter-moment';
import chartPluginCrosshair from 'chartjs-plugin-crosshair';
import { formatMarketVolumeChartData } from 'js/helpers';
import { switcheo } from 'js/theme/palettes/colors';
import { hexToRgba, SimpleMap } from 'js/utils';
import React, { ReactElement, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

type Props = {
  data: any,
  callback: (id: any) => void
  markets: SimpleMap<Market>
}

const MarketVolumeChart: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { data, callback, markets } = props
  const theme = useTheme()
  const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'))
  const maxXAxis = isMobileXs ? 3 : isMobileSm ? 5 : 10
  const toolTipPosition = isMobileSm ? 'top' : 'right' as const
  const yTitleShowWhenMobile = isMobileXs ? false : true
  const [chartData, setchartData] = useState<any>(undefined)
  const [chartLabels, setchartLabels] = useState<any>(undefined)

  ChartJS.register(...registerables, chartPluginCrosshair)
  useEffect(() => {
    if (data && Object.keys(markets).length) {
      const [labels, sortedData] = formatMarketVolumeChartData(data)
      const dataConfig: any = []
      sortedData?.forEach((item: any, index: number) => {
        const marketDisplayName = markets[item[0]]?.displayName
        const labelToDisplay = marketDisplayName ?? item[0]
        const label = labelToDisplay.includes("_") ? labelToDisplay?.toUpperCase().replace(/_/, " / ").replace(/\d/g, "") ?? "" : labelToDisplay
        dataConfig.push({
          label,
          fill: true,
          lineTension: 0.2,
          backgroundColor: hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90),
          borderColor: hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90),
          borderDash: [],
          borderDashOffset: 0.0,
          pointBorderColor: hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90),
          pointBackgroundColor: hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90),
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90),
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: item[1] ?? [],
          hidden: ["Other Markets"].includes(item[0]) ? true : false,
        })
      })
      setchartData(dataConfig)
      setchartLabels(labels)
    }
  }, [data, markets])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: true,
    },
    scales: {
      x: {
        type: 'time' as 'time',
        time: {
          unit: 'day' as 'day',
          displayFormats: {
            day: 'DD MMM',
            month: 'MMM YY'
          },
          tooltipFormat: "ll",
        },
        ticks: {
          autoSkip: false,
          beginAtZero: true,
          alwaysShowLastTick: true,
          display: true,
          maxTicksLimit: maxXAxis,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
        }
      },
      y: {
        stacked: true,
        title: {
          display: yTitleShowWhenMobile,
          text: 'Volume'
        },
        ticks: {
          callback: function (val: any) {
            return bnOrZero(val).toFormat()
          },
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: true,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
        }
      }
    },
    plugins: {
      TimeScale: {
        x: {
          type: 'time'
        }
      },
      tooltip: {
        mode: 'index' as 'index',
        intersect: false,
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 10,
        boxPadding: 2,
        callbacks: {
          beforeTitle: function (context: any) {
            callback(context[0].dataIndex)
            return ""
          },
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }
            return label;
          }
        }
      },
      crosshair: {
        line: {
          color: switcheo.text.grey,
          width: 2,
          dashPattern: [5, 5],
        }
      },
      legend: {
        position: toolTipPosition as any,
        align: 'start' as const,
        fullSize: false,
        labels: {
          boxWidth: 20,
          boxHeight: 5,
        },
      },
    },
  };

  const finalData = {
    labels: chartLabels ?? [],
    datasets: chartData ?? [],
  };
  return (
    <Line options={options} data={finalData} />
  )
}

export default MarketVolumeChart

