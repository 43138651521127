import { TableCell, Typography } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { fromNow } from 'js/helpers/dayjs'
import React, { ReactElement } from 'react'

interface Props {
  className?: string
  data: any
  dateBoxClass?: string
}

const DatetimeCell: React.FunctionComponent<Props> = ({
  className,
  data,
  dateBoxClass,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const daysTime: Dayjs = dayjs(data)
  let formattedDate: string = daysTime.format('YYYY-MM-DD HH:mm:ss')
  const now: Dayjs = dayjs()
  const diffInYears: number = now.diff(daysTime, 'year')
  if (diffInYears > 10) {
    formattedDate = '-'
  }
  return (
    <TableCell className={className} align="right">
      <div className={clsx(classes.root, dateBoxClass)}>
        {
          formattedDate !== '-' ? (
            <React.Fragment>
              <Typography className={classes.time}>{formattedDate}</Typography>
              <Typography className={classes.timeAgo}>
                ({fromNow(daysTime)})
              </Typography>
            </React.Fragment>
          ) : (
            <Typography className={classes.timeNull}>-</Typography>
          )
        }
      </div>
    </TableCell>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  time: {
    whiteSpace: 'nowrap',
    fontSize: '0.875rem',
    marginRight: theme.spacing(0.5),
  },
  timeAgo: {
    color: grey[500],
    fontSize: '0.875rem',
  },
  timeNull: {
    alignItems: 'center',
    display: 'flex',
    height: '2.25rem',
    justifyContent: 'flex-end',
  },
}))

export default DatetimeCell
