import { IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core'
import React, { ReactElement } from 'react'

interface Props {
  href: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  label: string
}

const SocialLink: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const { href, icon, label } = props
  const classes = useStyles()
  const Icon = icon

  const goToLink = () => window.open(href, '_blank')

  return (
    <Tooltip arrow placement="bottom" title={label}>
      <IconButton onClick={goToLink} classes={{
        root: classes.root,
      }}>
        <Icon className={classes.svg} />
      </IconButton>
    </Tooltip>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  svg: {
    width: '1.1rem',
    height: '1.1rem',
  },
}))

export default SocialLink
