import { Box, Divider, FormControl, makeStyles, MenuItem, Select, Theme } from '@material-ui/core'
import { GovUtils } from 'carbon-js-sdk'
import { clearSubmitProposalFormState, setSubmitProposalDepositAmount, setSubmitProposalError, setSubmitProposalFormValidationErrors, submitProposal } from 'js/actions/governance'
import { useRedux } from 'js/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Wizard } from '../components'
import CommunityPoolSpendMainForm from './CommunityPoolSpendProposal/CommunityPoolSpendProposal'
import { communityPoolSpendConfig } from './CommunityPoolSpendProposal/Helpers/FormConstraints'
import { communityPoolSpendProposalValue } from './CommunityPoolSpendProposal/Helpers/ProposalValue'
import { CreateOracleProposalInitialFormState, SetMsgFeeProposalInitialFormState, SetRewardCurveProposalInitialFormState, SetSettlementPriceProposalInitialFormState, SetUpdateAlliancelInitialFormState, SoftwareUpgradeProposalInitialFormState } from './constants'
import CreateTokenProposalMainForm from './CreateTokenProposal/CreateTokenProposalMainForm'
import { createTokenValidator } from './CreateTokenProposal/Helpers/FormConstraints'
import { createTokenProposalValue } from './CreateTokenProposal/Helpers/ProposalValue'
import DepositStep from './DepositStep'
import DetailsStep from './DetailsStep'
import { createOracleConfig, createOracleFormRequiredFields, createOracleProposalValue } from './GenericProposal/Config/CreateOracle'
import { setMsgFeeConfig, setMsgFeeFormRequiredFields, setMsgFeeProposalValue } from './GenericProposal/Config/SetMsgFee'
import { setRewardCurveConfig, setRewardCurveFormRequiredFields, setRewardCurveProposalValue } from './GenericProposal/Config/SetRewardCurve'
import { setSettlementPriceProposalValue, settlementPriceConfig, settlementPriceFormRequiredFields } from './GenericProposal/Config/SetSettlementPrice'
import { softwareUpgradeConfig, softwareUpgradeFormRequiredFields, softwareUpgradeProposalValue, softwareUpgradeValidator } from './GenericProposal/Config/SoftwareUpgrade'
import { textProposalValue } from './GenericProposal/Config/Text'
import { cancelUpgradeProposalValue } from './GenericProposal/Config/CancelUpgrade'
import GenericProposalMainForm from './GenericProposal/GenericProposalMainForm'
import formatDepositAmount from './Helpers/FormatDeposit'
import { parameterChangeValidator } from './ParameterChangeProposal/Helpers/FormConstraints'
import { parameterChangeProposalValue } from './ParameterChangeProposal/Helpers/ProposalValue'
import ParameterChangeProposalMainForm from './ParameterChangeProposal/ParameterChangeProposalMainForm'
import { setCommitmentCurveConfig } from './SetCommitmentCurveProposal/Helpers/FormConstraints'
import { setCommitmentCurveProposalValue } from './SetCommitmentCurveProposal/Helpers/ProposalValue'
import SetCommitmentCurveProposalMainForm from './SetCommitmentCurveProposal/SetCommitmentCurveProposalMainForm'
import { setPoolRewardWeightsValidator } from './SetPoolRewardWeightsProposal/Helpers/FormConstraints'
import { setPoolRewardWeightsProposalValue } from './SetPoolRewardWeightsProposal/Helpers/ProposalValue'
import SetPoolRewardWeightsProposalMainForm from './SetPoolRewardWeightsProposal/SetPoolRewardWeightsProposalMainForm'
import { updateMarketValidator } from './UpdateMarketProposal/Helpers/FormConstraints'
import { updateMarketProposalValue } from './UpdateMarketProposal/Helpers/ProposalValue'
import UpdateMarketProposalMainForm from './UpdateMarketProposal/UpdateMarketProposalMainForm'
import { updatePoolConfig, updatePoolValidator } from './UpdatePoolProposal/Helpers/FormConstraints'
import { updatePoolProposalValue } from './UpdatePoolProposal/Helpers/ProposalValue'
import UpdatePoolMainForm from './UpdatePoolProposal/UpdatePoolProposalMainForm'
import { MsgSubmitProposal } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/tx'
import { SWTHAddress } from 'carbon-js-sdk/lib/util/address'
import { setAllianceUpdateValue, setUpdateAllianceConfig, setUpdateAllianceFields } from './GenericProposal/Config/Alliances'

const MainForm: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [proposal, setProposal] = React.useState('')
  const wallet = useRedux((state) => state.core.carbonSDK?.wallet)
  const deposit = useRedux((state) => state.governance.submitProposalDepositAmount)
  const network = useRedux((state) => state.app.network)
  const proposalForm = useRedux((state) => state.governance.submitProposalFormState)
  const oldParams = useRedux((state) => state.governance.subspaceParams)
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProposal(event.target.value as string)
    dispatch(clearSubmitProposalFormState())
    dispatch(setSubmitProposalDepositAmount(''))
    dispatch(setSubmitProposalError(null))
    dispatch(setSubmitProposalFormValidationErrors([]))
  }

  const formattedDeposit = formatDepositAmount(deposit)
  const authority = SWTHAddress.getModuleAddress('gov', network)

  const executeSubmit = (proposalMsg: MsgSubmitProposal) => {
    if (!wallet || !network || !proposalMsg || proposal === '') return
    // after confirming that wallet exists, append to proposer field of MsgSubmitProposal
    proposalMsg.proposer = wallet.bech32Address
    dispatch(submitProposal(proposalMsg))
  }

  const proposalDropdown: React.ReactNode = (
    <Box className={classes.proposalDropdown}>
      <FormControl fullWidth>
        <Select
          displayEmpty
          value={proposal}
          onChange={handleChange}
          MenuProps={{
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="" disabled>Select a proposal type</MenuItem>
          {/* <MenuItem value={GovUtils.ProposalTypes.CreateToken}>Create Token</MenuItem> */}
          <MenuItem value={GovUtils.ProposalTypes.CreateOracleV2}>Create Oracle</MenuItem>
          {/* <MenuItem value={GovUtils.ProposalTypes.CreateMarket}>Create Market</MenuItem> */}
          <MenuItem value={GovUtils.ProposalTypes.UpdateMarketV2}>Update Market</MenuItem>
          {/* <MenuItem value={GovUtils.ProposalTypes.LinkPool}>Link Pool</MenuItem> */}
          {/* <MenuItem value={GovUtils.ProposalTypes.UnlinkPool}>Unlink Pool</MenuItem> */}
          <MenuItem value={GovUtils.ProposalTypes.UpdatePoolV2}>Update Pool</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.SetRewardsWeightsV2}>Set Pool Reward Weight</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.SetRewardCurveV2}>Set Reward Curve</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.SetCommitmentCurveV2}>Set Commitment Curve</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.SettlementPriceV2}>Set Settlement Price</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.SetMsgGasCostV2}>Set Message Gas Cost</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.ParameterChange}>Parameter Change</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.SoftwareUpgradeV2}>Software Upgrade</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.CancelSoftwareUpgradeV2}>Cancel Software Upgrade</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.CommunityPoolSpendV2}>Community Pool Spend</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.UpdateAllianceV2}>Update Alliance Proposal</MenuItem>
          <MenuItem value={GovUtils.ProposalTypes.Text}>Text</MenuItem>
        </Select>
      </FormControl>
      <Divider variant="fullWidth" light />
    </Box>
  )

  const withDetailsStepTitles = ['Select Proposal', 'Proposal Details', 'Proposal Description', 'Deposit']
  const noDetailsStepTitles = ['Select Proposal', 'Proposal Description', 'Deposit']

  return (
    <Box className={classes.root}>
      {
        proposal === '' &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit}
          steps={[proposalDropdown]}
          noSubmit
          titles={withDetailsStepTitles}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.CreateToken &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(createTokenProposalValue(wallet?.bech32Address, formattedDeposit))}
          steps={[
            proposalDropdown,
            <CreateTokenProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to create a token. Token symbol must be unique"]}
          titles={withDetailsStepTitles}
          customValidator={createTokenValidator}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.CreateOracleV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(createOracleProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={createOracleConfig()}
              initialFormState={CreateOracleProposalInitialFormState}
              requiredFields={createOracleFormRequiredFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to create a new Oracle. A use case is to determine the index price for a market"]}
          titles={withDetailsStepTitles}
          config={createOracleConfig()}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.UpdateMarketV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(updateMarketProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <UpdateMarketProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to update market parameters. All of the fields are compulsory and will override existing parameters even if left blank"]}
          titles={withDetailsStepTitles}
          customValidator={updateMarketValidator}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.UpdatePoolV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(updatePoolProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <UpdatePoolMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to update pool which includes to change the swap fee that each liquidity pool is taking and to change the number of quotes by the AMM for orders on each side of the market."]}
          titles={withDetailsStepTitles}
          config={updatePoolConfig}
          customValidator={updatePoolValidator}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.SetRewardsWeightsV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(setPoolRewardWeightsProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <SetPoolRewardWeightsProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose the respective pools weights to determine the distribution of rewards/fees among pools. Distribution ratio for a given pool is calculated by taking the weight of the pool divided by the total weight of all pools. Pools that are not included in the proposal will remain unchanged"]}
          titles={withDetailsStepTitles}
          customValidator={setPoolRewardWeightsValidator}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.SetRewardCurveV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(setRewardCurveProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={setRewardCurveConfig()}
              initialFormState={SetRewardCurveProposalInitialFormState}
              requiredFields={setRewardCurveFormRequiredFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose a reward curve that determines the rewards & fees allocation to staked liquidity pool tokens"]}
          titles={withDetailsStepTitles}
          config={setRewardCurveConfig()}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.SetCommitmentCurveV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(setCommitmentCurveProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <SetCommitmentCurveProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose a commitment curve that determines a linear yield multiplier based on the duration of the locked duration"]}
          titles={withDetailsStepTitles}
          config={setCommitmentCurveConfig}
        />
      }

      {
        proposal === GovUtils.ProposalTypes.SettlementPriceV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(setSettlementPriceProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={settlementPriceConfig()}
              initialFormState={SetSettlementPriceProposalInitialFormState}
              requiredFields={settlementPriceFormRequiredFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose a settlement price in the event that there is no settlement price for an expired futures market"]}
          titles={withDetailsStepTitles}
          config={settlementPriceConfig()}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.UpdateAllianceV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(setAllianceUpdateValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={setUpdateAllianceConfig()}
              initialFormState={SetUpdateAlliancelInitialFormState}
              requiredFields={setUpdateAllianceFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to update Alliance token."]}
          titles={noDetailsStepTitles}
          config={setUpdateAllianceConfig()}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.SetMsgGasCostV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(setMsgFeeProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={setMsgFeeConfig()}
              initialFormState={SetMsgFeeProposalInitialFormState}
              requiredFields={setMsgFeeFormRequiredFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to set the fee for a message."]}
          titles={noDetailsStepTitles}
          config={setMsgFeeConfig()}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.CommunityPoolSpendV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(communityPoolSpendProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <CommunityPoolSpendMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to spend the community pool fund by sending amount to recipient address"]}
          titles={withDetailsStepTitles}
          config={communityPoolSpendConfig}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.ParameterChange &&
        <Wizard
          type={proposal}
          onSubmit={() => {
            if (proposalForm) {
              executeSubmit(parameterChangeProposalValue(wallet?.bech32Address, formattedDeposit, authority, proposalForm, oldParams))
            }
          }}
          steps={[
            proposalDropdown,
            <ParameterChangeProposalMainForm />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', 'Propose a parameter change in the chain']}
          titles={withDetailsStepTitles}
          customValidator={parameterChangeValidator}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.SoftwareUpgradeV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(softwareUpgradeProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <GenericProposalMainForm
              config={softwareUpgradeConfig()}
              initialFormState={SoftwareUpgradeProposalInitialFormState}
              requiredFields={softwareUpgradeFormRequiredFields}
            />,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose a software upgrade to happen at a specific block height or time. If there is another scheduled plan, it will override it"]}
          titles={withDetailsStepTitles}
          customValidator={softwareUpgradeValidator}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.CancelSoftwareUpgradeV2 &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(cancelUpgradeProposalValue(wallet?.bech32Address, formattedDeposit, authority))}
          steps={[
            proposalDropdown,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Propose to abort a previously voted upgrade"]}
          titles={noDetailsStepTitles}
        />
      }
      {
        proposal === GovUtils.ProposalTypes.Text &&
        <Wizard
          type={proposal}
          onSubmit={() => executeSubmit(textProposalValue(wallet?.bech32Address, formattedDeposit))}
          steps={[
            proposalDropdown,
            <DetailsStep />,
            <DepositStep />,
          ]}
          subtitles={['', "Proposals that do not involve a modification of the source code go under this type. For example, an opinion poll would use a proposal of type PlainTextProposal"]}
          titles={noDetailsStepTitles}
        />
      }
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  proposalDropdown: {
    padding: theme.spacing(3, 3, 6, 3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
}))

export default MainForm
