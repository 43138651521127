import { Action } from 'redux'
import { RestModels } from 'tradehub-api-js'

export enum LeaderboardHistoryActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_LEADERBOARD_HISTORY = 'SET_LEADERBOARD_HISTORY',
  UPDATE_LEADERBOARD_HISTORY = 'UPDATE_LEADERBOARD_HISTORY',
}

export type LeaderboardHistoryAction =
  | ClearAction
  | SetLeaderboardHistoryAction
  | UpdateLeaderboardHistoryAction

export interface ClearAction extends Action<LeaderboardHistoryActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: LeaderboardHistoryActionType.CLEAR,
  }
}

export interface SetLeaderboardHistoryAction extends Action<LeaderboardHistoryActionType.SET_LEADERBOARD_HISTORY> {
    leaderboard: RestModels.LeaderboardResult
}

export function setLeaderboardHistory(leaderboard: RestModels.LeaderboardResult): SetLeaderboardHistoryAction {
  return {
    type: LeaderboardHistoryActionType.SET_LEADERBOARD_HISTORY,
    leaderboard,
  }
}

export interface UpdateLeaderboardHistoryAction extends Action<LeaderboardHistoryActionType.UPDATE_LEADERBOARD_HISTORY> {
    leaderboard: RestModels.LeaderboardResult
}

export function updateLeaderboardHistory(leaderboard: RestModels.LeaderboardResult): UpdateLeaderboardHistoryAction {
  return {
    type: LeaderboardHistoryActionType.UPDATE_LEADERBOARD_HISTORY,
    leaderboard,
  }
}
