import { Box, Card, Link, makeStyles, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { CarbonSDK, ChainInfo, Keplr, KeplrAccount, KeplrWindow } from 'carbon-js-sdk'
import { StatefulButton } from 'js/components'
import customToast from 'js/components/Toast/Toast'
import { Paths } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { updateCarbonSDK } from 'js/store/core'
import { switcheo } from 'js/theme/palettes/colors'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { TypographyLabel } from './components'
import { ReactComponent as KeplrAccess } from './keplr-access.svg'

interface Props { }

const KeplrWalletLogin: React.FC<Props> = (props: Props) => {
  const sdk = useRedux((state) => state.core.carbonSDK)
  const network = useRedux((state) => state.app.network)
  const [runConnectKeplr, loading, error] = useAsyncTask('connectKeplr')
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [chainInfo, setChainInfo] = useState<ChainInfo | null>(null)

  const hasKeplr = typeof (window as any).keplr !== 'undefined'

  useMemo(async () => {
    if (!sdk) return null
    await KeplrAccount.getChainInfo(sdk).then((chainInfo) => { setChainInfo(chainInfo) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk?.networkConfig])

  const handleLogin = async (keplr: Keplr) => {
    if (!chainInfo || !keplr) return
    const account = await keplr.getKey(chainInfo.chainId)
    const keplrSigner = KeplrAccount.createKeplrSigner(keplr, chainInfo, account);
    const pubKeyBase64 = Buffer.from(account.pubKey).toString('base64')
    const connectedSDK = await CarbonSDK.instanceWithSigner(
      keplrSigner,
      pubKeyBase64,
      { network },
      { providerAgent: 'keplr-extension' },
    )

    dispatch(updateCarbonSDK(connectedSDK))
    customToast('Success!', 'You have successfully logged in.')
    history.push(sessionStorage.getItem(`before-login`) ?? Paths.Home)
  }

  const onLogin = async () => {
    runConnectKeplr(async () => {
      if (!hasKeplr || !chainInfo) return
      const keplr = (window as KeplrWindow).keplr!

      await keplr.experimentalSuggestChain(chainInfo)

      try {
        await keplr.enable(chainInfo.chainId)
        handleLogin(keplr)
      } catch (error) {
        if ((error as any)?.message === 'key doesn\'t exist') {
          throw new Error('No Keplr account set up. Click on the Keplr extension to set up your account.')
        }
        throw error
      }
    })
  }

  return (
    <Box className={classes.container}>
      <Typography variant="h1">
        Connect with Keplr Wallet
      </Typography>
      <Card elevation={0} className={classes.card}>
        <Box display="flex" justifyContent="center" paddingX={8} marginTop={8}>
          <KeplrAccess className={classes.svg} />
        </Box>
        {!hasKeplr && (
          <TypographyLabel padding={6} align="center"><strong>Enable Keplr Wallet</strong> extension or install with the button below.</TypographyLabel>
        )}

        {hasKeplr && (
          <TypographyLabel padding={6} align="center">&ldquo;Approve&rdquo; connection on the Keplr Wallet window.</TypographyLabel>
        )}
      </Card>

      {!!error?.message && (
        <Box marginTop={4}>
          <Alert severity="error">
            <AlertTitle>Login Error</AlertTitle>
            {error.message}
          </Alert>
        </Box>
      )}

      <Box className={classes.actionClass}>
        {!hasKeplr && (
          <Link href="https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap" target="_blank">
            <StatefulButton
              className={classes.loginBtn}
              tabIndex={3}
              loading={loading}
              variant="contained"
              color="secondary"
            >
              Get Keplr for Chrome
            </StatefulButton>
          </Link>
        )}
        {hasKeplr && (
          <StatefulButton
            className={classes.loginBtn}
            tabIndex={3}
            loading={loading}
            variant="contained"
            color="secondary"
            onClick={onLogin}
          >
            Login
          </StatefulButton>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  actionClass: {
    marginTop: theme.spacing(4),
    justifyContent: 'flex-end',
  },
  breakword: {
    wordBreak: 'break-all',
  },
  container: {
    border: `1px solid ${switcheo.mono.A500}`,
    padding: theme.spacing(3),
  },
  card: {
    backgroundColor: 'transparent',
    display: 'flex',
    borderRadius: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    // marginBottom: theme.spacing(8),
    margin: theme.spacing(4, 0),
    border: `1px solid ${switcheo.mono.A500}`,
  },
  title: {
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  subtitleBox: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  svg: {
    height: 100,
  },
  loginBtn: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
}))

export default KeplrWalletLogin
