import { makeStyles, TableCell, TableRow, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { Models } from 'carbon-js-sdk'
import { CellLink, ProfileAvatarIcon } from 'js/components'
import { SignedBlocksCount } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { adjustHuman, BIG_ZERO, bnOrZero, parseNumber, SimpleMap, ValPair } from 'js/utils'
import React, { ReactElement, useEffect, useState } from 'react'
import { getBondStatus } from '../validatorsConfig'
import { getSWTHAddressFromValidatorOperatorAddress } from 'js/helpers'
import clsx from "clsx"

type Props = {
  delegatorList: Models.Staking.DelegationResponse[]
  index: number
  info: SimpleMap<Models.Slashing.ValidatorSigningInfo>
  totalTokens: BigNumber
  validator?: Models.Staking.Validator
  valAddrMap: SimpleMap<ValPair>
  slashCount: number
  participation: number
  totalProposal: number
}

const ValidatorRow: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux((state) => state.core.carbonSDK)
  const { delegatorList = [], index, info, totalTokens, validator, valAddrMap, slashCount, participation, totalProposal } = props
  const stakedTokenBN = sdk?.token.toHuman('swth', bnOrZero(validator?.tokens ?? '')) ?? BIG_ZERO
  const delegatorsNum = bnOrZero(delegatorList.length)
  const commissionBN = adjustHuman(validator?.commission?.commissionRates?.rate ?? '').shiftedBy(2) //shift by 2 becasue its %
  const sharePercent = stakedTokenBN.div(totalTokens)
  const [image, setImage] = useState('')
  const [loading, setLoading] = useState(false)
  const [signedBlockWindow, setSignedBlockWindow] = useState<BigNumber>(SignedBlocksCount)
  const [getSignedBlockWindow] = useAsyncTask("getSignedBlockWindow")
  const walletAddress = getSWTHAddressFromValidatorOperatorAddress(validator?.operatorAddress, sdk?.network)
  let selfBond = BIG_ZERO
  // tslint:disable: prefer-for-of
  // tslint:disable: no-increment-decrement

  useEffect(() => {
    if (!sdk) return
    getSignedBlockWindow(async () => {
      const slashingParams = await sdk?.query.slashing.Params({})
      const signedBlockBN = bnOrZero(slashingParams?.params?.signedBlocksWindow.toNumber() ?? 216000)
      setSignedBlockWindow(signedBlockBN)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  for (let i = 0; i < delegatorList.length; i++) {
    if (delegatorList[i]?.delegation?.delegatorAddress === walletAddress) {
      selfBond = sdk?.token.toHuman('swth', bnOrZero(delegatorList[i]?.balance?.amount)) ?? BIG_ZERO
    }
  }

  const consensusAddress = valAddrMap[validator?.operatorAddress ?? ""]?.consAddress ?? ""

  const description = validator?.description
  useEffect(() => {
    if (description?.identity === '') {
      setImage('')
    } else {
      setLoading(true)
      fetch(
        `https://keybase.io/_/api/1.0/user/lookup.json?key_fingerprint=${description?.identity}&fields=basics,pictures`,
      )
        .then((r: any) => (r ? r.json() : null))
        .then((json: any) => {
          if (json && json?.them && json?.them[0]) {
            const user = json?.them[0]
            const newImg = user?.pictures?.primary?.url
            setImage(newImg)
          }
        })
    }
  }, [description])

  const uptime = () => {
    const missedBlocks = parseNumber(info[consensusAddress]?.missedBlocksCounter?.toNumber() ?? 216000)
    if (!missedBlocks) {
      return '0%'
    }
    if (missedBlocks.comparedTo(signedBlockWindow) === 1) {
      return 100
    }
    return signedBlockWindow.minus(missedBlocks).div(signedBlockWindow).times(100).toFormat(2)
  }

  const uptimeDisplay = uptime()
  return (
    <TableRow key={validator?.operatorAddress} hover>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <div className={classes.nameCell}>
          <ProfileAvatarIcon
            avatarClass={classes.avatar}
            initialClass={classes.initial}
            srcUrl={image}
            loading={loading}
            name={description?.moniker ?? ''}
            setLoading={setLoading}
            spinnerSize="0.6rem"
          />
          <CellLink to={`/validator/${validator?.operatorAddress}`}>
            {description?.moniker}
          </CellLink>
        </div>
      </TableCell>
      <TableCell align="right" className={classes.numCell}>
        <div>{stakedTokenBN.toFormat(0) ? `${stakedTokenBN.toFormat(0)} SWTH` : '-'}</div>
        <div>({sharePercent.times(100).toFormat(2) ? `${sharePercent.times(100).toFormat(2)}%` : '-'})</div>
      </TableCell>
      <TableCell align="right" className={classes.numCell}>
        <div>{selfBond.toFormat(0) ? `${selfBond.toFormat(0)} SWTH` : '-'}</div>
        <div>
          ({selfBond.div(stakedTokenBN).times(100)
            ? `${selfBond.div(stakedTokenBN).times(100).toFormat(2)}%`
            : '-'})
        </div>
      </TableCell>
      <TableCell align="right">
        {delegatorsNum.toFormat(0)}
      </TableCell>
      <TableCell align="right" className={clsx(classes.participation, { red: (participation || 0) < 5, orange: participation >= 5 && participation < 8, green: participation >= 8 })}>
        {participation || 0} / {totalProposal}
      </TableCell>
      <TableCell align="right">{commissionBN.toFormat(2)}%</TableCell>
      <TableCell align="right">
        <div className={clsx(classes.uptime, { errorRed: uptimeDisplay < 85, red: uptimeDisplay < 85, orange: uptimeDisplay < 98, green: uptimeDisplay >= 98 })}>{uptimeDisplay}%</div>
        <div>({info[consensusAddress]?.missedBlocksCounter.toNumber() || 0})</div>
      </TableCell>
      <TableCell align="right" className={clsx(classes.slashCount, { red: slashCount > 0, green: slashCount === 0 })}>
        {slashCount ?? "-"}
      </TableCell>
      <TableCell align="right" className={classes.bondStatus}>
        {getBondStatus(validator?.status ?? -1)}
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  participation: {
    '&.green': {
      color: theme.palette.success.dark
    },
    '&.orange': {
      color: theme.palette.warning.light
    },
    '&.red': {
      color: theme.palette.error.main
    }
  },
  uptime: {
    '&.green': {
      color: theme.palette.success.dark
    },
    '&.orange': {
      color: theme.palette.success.light
    },
    '&.red': {
      color: theme.palette.warning.light
    },
    '&.errorRed': {
      color: theme.palette.error.main
    }
  },
  slashCount: {
    '&.red': {
      color: theme.palette.error.main
    },
    '&.green': {
      color: theme.palette.success.main
    }
  },
  avatar: {
    width: '1.2rem',
    height: '1.2rem',
    marginRight: theme.spacing(1),
  },
  bondStatus: {
    textTransform: 'capitalize',
  },
  initial: {
    fontSize: '0.6rem',
  },
  nameCell: {
    display: 'flex',
    minWidth: '12rem',
  },
  numCell: {
    minWidth: '11.2rem',
  },
}))

export default ValidatorRow
