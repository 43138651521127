import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import CellLink from 'js/components/CellLink'
import { Paths } from 'js/constants'
import React, { ReactElement } from 'react'
import { ReactComponent as ArtworkSvg } from './art-deposit.svg'

type Props = BoxProps

const Step2: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, className, children, ...rest } = props
  const classes = useStyles(props)

  return (
    <Box
      {...rest}
      className={clsx(classes.root, className)}
    >
      <ArtworkSvg className={classes.artwork} />
      <Typography variant='body1' paragraph>
        Proposals require <strong>a minimum
          10,000 SWTH deposit (not voting power)</strong> within 2 days of submission to
        proceed for voting.
      </Typography>
      <Typography variant='body1' paragraph>
        Deposits of passed proposals will be returned to depositors. 
        Deposits of rejected proposals will either be returned or burnt depending on a few factors like quorum, threshold and the % of the “NoWithVeto” vote.
        Learn more about the potential risks <CellLink
          underline='hover'
          href={Paths.Resources.CreateAndSubmitProposals}
        >here
        </CellLink>.
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  artwork: {
    display: 'block',
    margin: '14px auto 42px auto',
    maxWidth: '15rem',
    width: '100%',
  },
}))

export default Step2
