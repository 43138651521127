import { makeStyles, TableBody, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { DataLoadSegment, ListTable, Section } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import { bnOrZero } from 'js/utils'
import React, { ReactElement } from 'react'
import { ListTableRow } from '../../../Staking/Validators/components'
import { CommitmentCurve as CommitmentCurveType } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/reward'

interface Props {
  params: CommitmentCurveType
}

const CommitmentCurve: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { params } = props
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Pools.CommitmentCurve)

  const cellFormat = (key: keyof CommitmentCurveType) => bnOrZero(params[key]?.toString()).toString()

  return (
    <Section title="Commitment Curve" className={classes.section}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableBody>
            {
              (Object.keys(params) as (keyof CommitmentCurveType)[]).map((each) => {
                if (each === 'maxCommitmentDuration') {
                  return (
                    <ListTableRow
                      headerClass={classes.headerClass}
                      key={each}
                      keyVal={each}
                      valueClass={classes.valueClass}
                    >
                      <div className={classes.stringContainer}>
                        <div>{cellFormat(each)}</div>
                        <div className={classes.sharePercent}>seconds</div>
                      </div>
                    </ListTableRow>
                  )
                }
                if (each === 'maxRewardMultiplier') {
                  const humanValue = new BigNumber(cellFormat(each)).shiftedBy(-2)
                  let humanValueString = ''
                  if (!humanValue.isNaN()) {
                    humanValueString = `(${humanValue.toFormat()}%)`
                  }
                  return (
                    <ListTableRow
                      headerClass={classes.headerClass}
                      key={each}
                      keyVal={each}
                      valueClass={classes.valueClass}
                    >
                      <div className={classes.stringContainer}>
                        <div>{cellFormat(each)}</div>
                        <div className={classes.sharePercent}>{humanValueString}</div>
                      </div>
                    </ListTableRow>
                  )
                }
                return (
                  <ListTableRow
                    headerClass={classes.headerClass}
                    key={each}
                    keyVal={each}
                    valueClass={classes.valueClass}
                  >
                    {cellFormat(each)}
                  </ListTableRow>
                )
              })
            }
          </TableBody>
        </ListTable>
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  headerClass: {
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
  section: {
    height: '100%',
  },
  sharePercent: {
    color: theme.palette.grey[500],
    marginLeft: 6,
  },
  stringContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  valueClass: {
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    },
  },
}))

export default CommitmentCurve
