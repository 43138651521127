import { BondStatus } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/staking'
import { TaskNames, TooltipsMsgs } from 'js/constants'

export type IndivCell = {
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify',
  label: string,
  tooltip?: string,
  underline: boolean
}

export const HeaderCells: IndivCell[] = [
  {
    label: '#',
    underline: false,
  },
  {
    label: 'Validators',
    underline: false,
  },
  {
    align: 'right',
    label: 'Total Stake (%)',
    tooltip: TooltipsMsgs.total_stake_percent,
    underline: true,
  },
  {
    align: 'right',
    label: 'Self Stake',
    tooltip: TooltipsMsgs.self_stake,
    underline: true,
  },
  {
    align: 'right',
    label: 'Delegators',
    underline: false,
  },
  {
    align: 'right',
    label: 'Participation',
    underline: true,
    tooltip: TooltipsMsgs.participation
  },
  {
    align: 'right',
    label: 'Commission',
    tooltip: TooltipsMsgs.commission_rate,
    underline: true,
  },
  {
    align: 'right',
    label: 'Uptime',
    tooltip: TooltipsMsgs.uptime,
    underline: true,
  },
  {
    align: 'right',
    label: 'Slash Count',
    tooltip: TooltipsMsgs.slashCount,
    underline: true,
  },
  {
    align: 'right',
    label: 'Bond Status',
    underline: false,
  },
]

export const LoadNames = [
  TaskNames.Validators.Distribution,
  TaskNames.App.Validators,
  TaskNames.Validators.Slashing,
]

export const getBondStatus = (status: number) => {
  switch (status) {
    case BondStatus.BOND_STATUS_BONDED:
      return 'Bonded'
    case BondStatus.BOND_STATUS_UNBONDED:
      return 'Unbonded'
    case BondStatus.BOND_STATUS_UNBONDING:
      return 'Unbonding'
    case BondStatus.BOND_STATUS_UNSPECIFIED:
    case BondStatus.UNRECOGNIZED:
    default:
      return 'Unknown'
  }
}
