import { Insights, Models } from 'carbon-js-sdk'
import { TaskNames } from 'js/constants'
import { bnOrZero } from 'js/utils'
import { SagaIterator } from 'redux-saga'
import { call, Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import { clear, setRichAccountsBalances, setTokenInfo } from '../actions/token'
import { runSagaTask, waitforSDK } from './helper'
import Saga from './Saga'
import { QueryGetTokenResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/query'
// import moment from 'moment'
// import { Interval } from 'carbon-js-sdk/lib/insights'

export default class Token extends Saga {
  private readonly symbol: string
  private readonly isMobile: boolean
  constructor(symbol: string, isMobile: boolean) {
    super()
    this.isMobile = isMobile
    this.symbol = symbol
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchToken], this.symbol),
      call([this, this.fetchRichList], this.symbol),
      // call([this, this.fetchOraclesPriceChart], this.symbol),
      // spawn([this, this.watchDateFilters]),
      spawn([this, this.watchSetNetwork]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchToken(denom: any): any {
    const decodedDenom = decodeURIComponent(denom)
    yield runSagaTask(TaskNames.Token.Detail, function* () {
      const sdk = yield* waitforSDK()
      const coinQueryClient = sdk.query.coin
      const bankQueryClient = sdk.query.bank
      let token: any = {}
      try {
        token = (yield call([coinQueryClient, coinQueryClient.Token], { denom: decodedDenom })) as QueryGetTokenResponse
        const tokenSupplyResponse = (yield call([bankQueryClient, bankQueryClient.SupplyOf], { denom: decodedDenom })) as Models.Bank.QuerySupplyOfResponse
        const tokenSupply = bnOrZero(tokenSupplyResponse?.amount?.amount).shiftedBy(-Number(token?.token?.decimals) ?? 0)
        if (token.token) token.token.totalSupply = tokenSupply
      }
      catch (err) {
        console.error(err)
        if (!token || !token?.token) {
          const allTokens = sdk.token.tokens
          token.token = Object.values(allTokens)?.find((o: any) => o.denom === decodedDenom)
        }
      }
      if (token.token) {
        yield put(setTokenInfo(token.token))
      }
    })
  }

  private *fetchRichList(denom: string): any {
    yield runSagaTask(TaskNames.Token.RichList, function* () {
      const sdk = yield* waitforSDK()
      const richList = (yield call([sdk.insights, sdk.insights.BalanceList], { denom })) as Insights.InsightsQueryResponse<Insights.QueryGetBalanceListResponse>
      yield put(setRichAccountsBalances(richList.result.models))
    })
  }

  //   private *fetchOraclesPriceChart(denom: string): any {
  //     yield runSagaTask(TaskNames.Token.PricesChart, function* () {
  //       const sdk = yield* waitforSDK()
  //       const from = moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDTHH:MM:SS+00'))
  //       const untilSubtracted = moment(moment(Date()).utc().format('YYYY-MM-DDTHH:MM:SS+00'))
  //       const { interval, intervalDivision } = getAdjustedIntervalDivision(from, untilSubtracted)
  //       const oraclesPriceResponse = (yield call([sdk.insights, sdk.insights.OraclePrices], {
  //         from: from.unix().toString(),
  //         until: untilSubtracted.unix().toString(),
  //         denom,
  //         interval,
  //         intervalDivision,
  //       })) as Insights.InsightsQueryResponse<Insights.QueryGetOraclesPriceResponse>
  //       yield put(setOraclesPriceChartData(oraclesPriceResponse.result.entries))
  //     })
  //   }

  //   private *watchDateFilters(): any {
  //     yield takeLatest(
  //       TokenActionType.UPDATE_DATE_FILTERS_ORACLES_PRICE_CHART,
  //       (action: any) => this.handleDateFiltersOraclesPrice(action, this.symbol),
  //     )
  //   }

  //   private *handleDateFiltersOraclesPrice(action: any, denom: string): any {
  //     yield runSagaTask(TaskNames.Token.PricesChart, function* () {
  //       const sdk = yield* waitforSDK()
  //       const from = moment(moment(action.options.startDate).utc().format('YYYY-MM-DDTHH:MM:SS+00'))
  //       const until = moment(moment(action.options.endDate).utc().format('YYYY-MM-DDTHH:MM:SS+00'))
  //       const { interval, intervalDivision } = getAdjustedIntervalDivision(from, until)
  //       const oraclesPriceResponse = (yield call([sdk.insights, sdk.insights.OraclePrices], {
  //         denom,
  //         from: from.unix().toString(),
  //         until: until.unix().toString(),
  //         interval,
  //         intervalDivision,
  //       })) as Insights.InsightsQueryResponse<Insights.QueryGetOraclesPriceResponse>
  //       yield put(setOraclesPriceChartData(oraclesPriceResponse.result.entries))
  //     })
  //   }
  // }

  // const getAdjustedIntervalDivision = (from: moment.Moment, until: moment.Moment) => {
  //   const daysDiff = until.diff(from, 'days')
  //   const monthsDiff = until.diff(from, 'months')

  //   if (daysDiff <= 1) {
  //     return { interval: "hour" as Interval, intervalDivision: 5 }
  //   } else if (daysDiff <= 7) {
  //     return { interval: "hour" as Interval, intervalDivision: 30 }
  //   } else if (daysDiff <= 14) {
  //     return { interval: "hour" as Interval, intervalDivision: 60 }
  //   }

  //   if (monthsDiff <= 3) {
  //     return { interval: "day" as Interval, intervalDivision: 60 }
  //   }

  //   return { interval: "week" as Interval, intervalDivision: 60 }
} 