import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiGrid']>> => ({
  item: {
    maxWidth: '100%',
    flexGrow: 1,
  },
})
