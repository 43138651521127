import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputBase, InputLabel, makeStyles, Theme, Typography } from '@material-ui/core'
import base58 from 'bs58'
import clsx from 'clsx'
import CellLink from 'js/components/CellLink'
import StatefulButton from 'js/components/StatefulButton'
import customToast from 'js/components/Toast/Toast'
import { Paths, TaskName } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { updateCarbonSDK } from 'js/store/core'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { decrypt as triplesecDecrypt } from 'triplesec'
import { ReactComponent as CloseIcon } from './Close.svg'
import LoginInput from './LoginInput'
import { ReactComponent as UploadIcon } from './Upload.svg'
import { useHistory } from 'react-router'

const decrypt = (
  walletKey: string,
  password: string,
): Promise<string> => {
  const bytes = base58.decode(walletKey)
  return new Promise((resolve, reject) => {
    triplesecDecrypt(
      { key: Buffer.from(password), data: bytes },
      async (err: Error | null, buff: Buffer | null) => {
        if (err) {
          reject(err)
        } else if (!buff) {
          reject("Could not decrypt key, empty result");
        } else {
          resolve(buff.toString())
        }
      },
    )
  })
}

const initialFormState = {
  walletKey: '',
  password: '',
  isFileUpload: false,
}
type FormState = typeof initialFormState

const EncryptedKeyLogin: React.FC = () => {
  const classes = useStyles()
  const sdk = useRedux((state) => state.core.carbonSDK)
  const history = useHistory()
  const dispatch = useDispatch()
  const [formState, setFormState] = useState<FormState>({ ...initialFormState })
  const fileUploadRef = useRef<HTMLInputElement>()
  const [runLogin, loadingLogin, errorLogin] = useAsyncTask(TaskName.Login)
  const [runParseFile, loadingParseFile, errorParseFile, clearParseError] = useAsyncTask(TaskName.ParseFile)

  const handleChange = (key: keyof FormState) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({
        ...formState,
        [key]: event.target.value,
      })
    }
  }

  const handleUpload = () => {
    fileUploadRef?.current?.click()
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      handleLogin()
    }
  }

  const handleLogin = () => {
    clearParseError()
    runLogin(async () => {
      if (!sdk) return

      const { walletKey, password } = formState
      const mnemonics = await decrypt(walletKey, password)
      const connectedSDK = await sdk.connectWithMnemonic(mnemonics)

      dispatch(updateCarbonSDK(connectedSDK))
      customToast('Success!', 'You have successfully logged in.')
      history.push(sessionStorage.getItem(`before-login`) ?? Paths.Home)
    })
  }

  const handleWalletFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { files } = event.target
    runParseFile(async () => {
      const file: File = files!.item(0)!
      const text: string = await file.text()

      setFormState({
        ...formState,
        walletKey: JSON.parse(text),
        isFileUpload: true,
      })
    }, async (error: Error) => {
      setFormState({
        ...formState,
        walletKey: '',
        isFileUpload: false,
      })
    })
  }

  const cancelFileUpload = () => {
    setFormState({
      ...formState,
      walletKey: '',
      isFileUpload: false,
    })
    // resets value of file input so I can upload another file.
    if (fileUploadRef.current) {
      fileUploadRef!.current!.value = ''
    }
  }

  if (sdk?.wallet) return <Redirect to={Paths.Governance} />

  return (
    <React.Fragment>
      <Typography variant="h1">Login to Your Wallet</Typography>
      <Typography className={classes.subtitle} variant="body1">
        Enter or upload your Encrypted Key, then enter your Password to
        access your wallet.
      </Typography>
      <FormControl fullWidth className={classes.loginForm}>
        <InputLabel>Encrypted Key</InputLabel>
        {!formState.isFileUpload && (
          <LoginInput
            onKeyDown={handleKeyDown}
            showText
            inputProps={{ tabIndex: 1 }}
            onChange={handleChange('walletKey')}
            isDecrypting={loadingLogin}
          />
        )}
        <FormControl fullWidth>
          <InputBase
            inputRef={fileUploadRef}
            type="file"
            className={clsx(classes.fileUpload, {
              [classes.hiddenFileUpload]: !formState.isFileUpload,
            })}
            onChange={handleWalletFileChange}
            endAdornment={
              <InputAdornment position="end">
                <CloseIcon
                  onClick={cancelFileUpload}
                  className={classes.clearIcon}
                />
              </InputAdornment>
            }
          />
          <FormHelperText hidden={!errorParseFile} error>
            File error: {errorParseFile?.message}
          </FormHelperText>
        </FormControl>
        <Typography variant="body1" className={classes.uploadText}>
          Or upload Encrypted Key
          <IconButton className={classes.upload} onClick={handleUpload}>
            <UploadIcon />
            Upload
          </IconButton>
        </Typography>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel shrink>Your Password</InputLabel>
        <LoginInput
          onKeyDown={handleKeyDown}
          inputProps={{ tabIndex: 2 }}
          showText={false}
          onChange={handleChange('password')}
          isDecrypting={loadingLogin}
        />
        <FormHelperText hidden={!errorLogin} error>
          Login failed: {errorLogin?.message}
        </FormHelperText>
      </FormControl>
      <Box className={classes.loginContainer}>
        <Box className={classes.actions}>
          <StatefulButton
            className={classes.loginBtn}
            tabIndex={3}
            loading={loadingLogin || loadingParseFile}
            variant="contained"
            color="secondary"
            onClick={handleLogin}
          >
            Login
          </StatefulButton>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography
              variant="body1"
              className={classes.lostPasswordText}
            >
              <CellLink href="https://app.dem.exchange/reset_password">
                Lost Key or Password?
              </CellLink>
            </Typography>
          </Box>
        </Box>
        <Typography className={classes.noWalletText}>
          Don't have a wallet?{' '}
          <CellLink
            underline="hover"
            href="https://app.dem.exchange/register"
          >
            Sign Up
          </CellLink>{' '}
            on Switcheo Staking
        </Typography>
      </Box>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  subtitle: {
    marginTop: theme.spacing(1.25),
  },
  loginBtn: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
  loginContainer: {
    marginTop: theme.spacing(2),
  },
  loginForm: {
    marginTop: theme.spacing(5),
  },
  lostPasswordText: {
    '& a': {
      fontWeight: 'bold',
      color: theme.palette.text.disabled,
      letterSpacing: '-0.0125rem',
    },
    marginLeft: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(1.5),
    },
  },
  noWalletText: {
    marginTop: theme.spacing(6),
  },
  fileUpload: {
    '&.MuiInputBase-root': {
      margin: theme.spacing(3, 0, 0),
      border: `1px solid #0F214F`,
    },
    '& .MuiInputBase-input': {
      padding: '0.625rem 0.75rem',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginRight: theme.spacing(1.25),
    },
  },
  hiddenFileUpload: {
    display: 'none',
  },
  hideErrorMessage: {
    visibility: 'hidden',
  },
  uploadText: {
    margin: theme.spacing(0.625, 0, 3),
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(0.625, 0, 2.5),
    },
  },
  clearIcon: {
    height: '0.875rem',
    width: '0.875rem',
    cursor: 'pointer',
  },
  upload: {
    '&.MuiIconButton-root': {
      fontSize: '0.875rem',
      borderRadius: 0,
      color: theme.palette.text.hint,
      padding: theme.spacing(0, 1.5),
    },
    '& .MuiIconButton-label': {
      '& svg': {
        marginRight: theme.spacing(0.875),
        height: '0.875rem',
        width: '0.875rem',
        '& path': {
          fill: theme.palette.text.hint,
        },
      },
    },
  },
}))

export default EncryptedKeyLogin
