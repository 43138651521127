import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  Chart as ChartJS,
  registerables,
} from 'chart.js';
import 'chartjs-adapter-moment';
import chartPluginCrosshair from 'chartjs-plugin-crosshair';
import { CARBON_GENESIS_BLOCKTIME } from 'js/constants';
import { formatIndividualPoolChartData } from 'js/helpers';
import { switcheo } from 'js/theme/palettes/colors';
import { bnOrZero, hexToRgba } from 'js/utils';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';

type Props = {
  volume: any,
  liquidity: any,
  dateFilter: any,
  price: any,
  setTotalLiquidity: (data: any) => void
  setTotalVolume: (data: any) => void
}

const IndividualPoolChart: React.FunctionComponent<Props> = (
  props: any,
): ReactElement<Props> => {
  const { volume, liquidity, price, dateFilter, setTotalLiquidity, setTotalVolume } = props
  const theme = useTheme()
  const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'))
  const maxXAxis = isMobileXs ? 3 : isMobileSm ? 5 : 10
  const yTitleShowWhenMobile = isMobileXs ? false : true
  const toolTipPosition = isMobileSm ? 'top' : 'right' as const
  const [chartData, setchartData] = useState<any>(undefined)
  const [chartLabels, setchartLabels] = useState<any>(undefined)
  ChartJS.register(...registerables, chartPluginCrosshair);
  const dateFilterStart = moment(dateFilter.startDate);
  const dateFilterEnd = moment(dateFilter.endDate);
  const needFilterDataByDate = moment(CARBON_GENESIS_BLOCKTIME).format('ll') !== dateFilterStart.format('ll') || moment().format('ll') !== dateFilterEnd.format('ll');

  useEffect(() => {
    if (volume && liquidity) {
      let filteredVolume = volume;
      let filteredLiquidity = liquidity;
      let startDate = dateFilter.startDate;
      let endDate = dateFilter.endDate;
      if (needFilterDataByDate) {
        filteredVolume = volume.filter(
          (dailyData: any) =>
            moment(dailyData.t) >= moment(startDate).startOf('day')
            && moment(dailyData.t) <= moment(endDate).endOf('day'))
        filteredLiquidity = liquidity.filter(
          (dailyData: any) =>
            moment(dailyData.date) >= moment(startDate).startOf('day')
            && moment(dailyData.date) <= moment(endDate).endOf('day')
        )
      }
      let nearestStartDate = startDate;
      // if is all time use the first date with data
      if (!needFilterDataByDate) {
        let volumeStartDate = startDate;
        let liquidityStartDate = startDate;
        if (filteredVolume.length > 0) {
          volumeStartDate = filteredVolume.slice(-1)[0].t;
        }
        if (filteredLiquidity.length > 0) {
          liquidityStartDate = filteredLiquidity[0].date;
        }

        // Get the date that first returns results
        nearestStartDate = moment(volumeStartDate) > moment(liquidityStartDate) ? liquidityStartDate : volumeStartDate
        nearestStartDate = moment(nearestStartDate) > moment(startDate) ? nearestStartDate : startDate
      }

      const [labels, chartData] = formatIndividualPoolChartData(nearestStartDate, dateFilter.endDate, filteredVolume, filteredLiquidity, price)
      const dataConfigs = [
        {
          label: 'Liquidity Utilisation Rate',
          data: chartData?.utility ?? [],
          color: 2,
          hidden: false,
          yAxisID: 'y' as 'y',
          type: 'line' as const,
          fill: false
        },
        {
          label: 'Pool Liquidity',
          data: chartData?.liquidity ?? [],
          color: 1,
          hidden: false,
          yAxisID: 'y1' as 'y1',
          type: 'bar' as const,
          fill: true
        },
        {
          label: 'Pool Volume',
          data: chartData?.volume ?? [],
          color: 0,
          hidden: false,
          yAxisID: 'y2' as 'y2',
          type: 'bar' as const,
          fill: true
        },
      ]

      const generateDataSets = dataConfigs.map((dataConfig: any) => {
        return {
          label: dataConfig.label,
          fill: dataConfig.fill,
          lineTension: 0.2,
          backgroundColor: hexToRgba(switcheo.chart[dataConfig.color], 0.90),
          borderColor: hexToRgba(switcheo.chart[dataConfig.color], 0.90),
          borderDash: [],
          borderWidth: 2,
          borderDashOffset: 0.0,
          pointBorderColor: hexToRgba(switcheo.chart[dataConfig.color], 0.90),
          pointBackgroundColor: hexToRgba(switcheo.chart[dataConfig.color], 0.90),
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: hexToRgba(switcheo.chart[dataConfig.color], 0.90),
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: dataConfig.data,
          hidden: dataConfig.hidden,
          yAxisID: dataConfig.yAxisID,
          type: dataConfig.type
        }
      })
      if (chartData?.volume.length > 0) {
        setTotalVolume(chartData?.volume.slice(-1)[0].y);
      }
      if (chartData?.liquidity.length > 0) {
        setTotalLiquidity(chartData?.liquidity.slice(-1)[0].y);
      }
      setchartData(generateDataSets)
      setchartLabels(labels)
    }
  }, [volume, liquidity, dateFilter, needFilterDataByDate, setTotalLiquidity, setTotalVolume, price])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: true,
    },
    scales: {
      x: {
        // stacked: true,
        type: 'time' as 'time',
        time: {
          unit: 'day' as 'day',
          displayFormats: {
            day: 'DD MMM',
            month: 'MMM YY'
          },
          tooltipFormat: "ll",
        },
        ticks: {
          autoSkip: false,
          beginAtZero: true,
          alwaysShowLastTick: true,
          display: true,
          maxTicksLimit: maxXAxis,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
        }
      },
      y: {
        display: false,
        position: 'right' as 'right',
        title: {
          display: yTitleShowWhenMobile,
          text: 'Pool Utilisation Rate'
        },
        ticks:
        {
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
          z: 1,
        }
      },
      y1: {
        display: true,
        position: 'right' as 'right',
        title: {
          display: yTitleShowWhenMobile,
          text: 'Liquidity'
        },
        ticks:
        {
          callback: function (val: any) {
            return bnOrZero(val).toFormat()
          },
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
          z: 1,
        }
      },
      y2: {
        position: 'left' as 'left',
        // staked: true,
        title: {
          display: yTitleShowWhenMobile,
          text: 'Volume'
        },
        ticks: {
          steps: 6,
          callback: function (val: any) {
            return bnOrZero(val).toFormat()
          },
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
          z: 1,
        }
      },
    },
    plugins: {
      TimeScale: {
        x: {
          type: 'time'
        }
      },
      tooltip: {
        mode: 'index' as 'index',
        intersect: false,
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 10,
        boxPadding: 2,
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }

            if (context.parsed.y === null) {
              return;
            }

            if (label === "Liquidity Utilisation Rate: ") {
              label += (context.parsed.y * 100).toFixed(2) + "%"
            } else {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }

            return label;
          },
        }
      },
      crosshair: {
        line: {
          color: switcheo.text.grey,
          width: 2,
          dashPattern: [5, 5],
        }
      },
      legend: {
        position: toolTipPosition as any,
        align: 'start' as const,
        fullSize: false,
        labels: {
          boxWidth: 20,
          boxHeight: 8,
        },
        reverse: true
      },
    },
  };

  const finalData = {
    labels: chartLabels ?? [],
    datasets: chartData ?? [],
  };
  return (
    <Chart type='bar' options={options} data={finalData} />
  )
}

export default IndividualPoolChart;
