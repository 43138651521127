import { QueryAllBlockRequest, QueryAllBlockResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/query'
import { blocksPaginationLimit, TaskNames } from 'js/constants'
import Long from 'long'
import { SagaIterator } from 'redux-saga'
import { call, Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import { BlocksActionType, clear, setBlocks } from '../actions/blocks'
import { runSagaTask, waitforSDK } from './helper'
import Saga from './Saga'

export default class Blocks extends Saga {
  private readonly isMobile: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
  }
  /** @override */
  public * stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchBlocks],
      [this, this.watchSetNetwork],
      [this, this.watchFilters],
    ].map(spawn)
  }

  private * watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private * fetchBlocks(): any {
    yield runSagaTask(TaskNames.Blocks.List, function* () {
      const sdk = yield* waitforSDK()
      const miscQueryClient = sdk.query.misc
      const response = (yield call([miscQueryClient, miscQueryClient.BlockAll], QueryAllBlockRequest.fromPartial({
        pagination: {
          limit: new Long(blocksPaginationLimit),
          offset: Long.UZERO,
          countTotal: true,
          reverse: false,
        },
      })
      )) as QueryAllBlockResponse
      yield put(setBlocks({
        models: response.blocks,
        meta: {
          totalPages: Math.ceil(response.pagination?.total?.toNumber()! / blocksPaginationLimit),
        }
      }))
    })
  }

  private * watchFilters(): any {
    yield takeLatest(BlocksActionType.UPDATE_FILTERS, this.handleFilters)
  }

  private * handleFilters(action: any): Generator {
    yield runSagaTask(TaskNames.Blocks.Filter, function* () {
      const sdk = yield* waitforSDK()
      const miscQueryClient = sdk.query.misc as any
      const page = Number(action.options.page)
      const offset = (page - 1) * blocksPaginationLimit
      const offsetLong = Long.fromNumber(offset >= 0 ? offset : 0)

      const response = (yield call([miscQueryClient, miscQueryClient.BlockAll],
        QueryAllBlockRequest.fromPartial({
          pagination: {
            limit: new Long(blocksPaginationLimit),
            offset: offsetLong,
            countTotal: true,
            reverse: false,
          }
        }),
      )) as QueryAllBlockResponse
      yield put(setBlocks({
        models: response.blocks,
        meta: {
          totalPages: Math.ceil(response.pagination?.total?.toNumber()! / blocksPaginationLimit),
        }
      }))
    })
  }
}
