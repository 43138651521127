import { Action } from 'redux'
import { RestModels } from 'tradehub-api-js'

export enum NodesActionType {
  CLEAR = 'CLEAR_NODES',
  SET_NODES = 'SET_NODES',
}

export type NodesAction = ClearAction | SetNodesAction

export interface ClearAction extends Action<NodesActionType.CLEAR> {}
export function clear(): ClearAction {
  return {
    type: NodesActionType.CLEAR,
  }
}

export interface SetNodesAction extends Action<NodesActionType.SET_NODES> {
  nodes: RestModels.NodeData[]
}

export function setNodes(nodes: RestModels.NodeData[]): SetNodesAction {
  return {
    type: NodesActionType.SET_NODES,
    nodes,
  }
}
