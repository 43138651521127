import { Button, makeStyles, Theme, Typography } from '@material-ui/core'
import { useRedux } from 'js/hooks'
import React, { ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { User } from '../assets'
import YourWalletDropdown from './YourWalletDropdown'

type Props = {
  toggleDrawer?: (forceClose?: boolean) => (event: any) => void
}

const Login: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { toggleDrawer } = props
  const classes = useStyles(props)
  const wallet = useRedux((state) => state.core.carbonSDK?.wallet)

  return (
    <div className={classes.root}>
      {wallet ? (
        <YourWalletDropdown toggleDrawer={toggleDrawer} />
      ) : (
        <Button
          component={RouterLink}
          to={'/login'}
          variant="text"
          className={classes.button}
          startIcon={<User className={classes.userIcon} />}
          onClick={toggleDrawer && toggleDrawer()}
        >
          <Typography className={classes.loginText} variant="h3">
            Login
          </Typography>
        </Button>
      )}
    </div>

  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    minHeight: '4rem',
    position: 'relative',
    '& .MuiButton-root': {
      minWidth: 'unset',
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
  loginText: {
    color: theme.palette.text.hint,
    fontWeight: 'normal',
    textTransform: 'initial',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'bold',
      fontSize: '1.375rem',
    },
  },
  userIcon: {
    height: '1.125rem',
    width: '1.125rem',
    '& path': {
      fill: theme.palette.text.hint,
    },
  },
}))

export default Login
