import { makeStyles, Typography } from '@material-ui/core'
import { CellLink, DataLoadSegment, NotFoundState, Page } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { Fragment, ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import BlockCountDown from './BlockCountdown'
import BlockEvents from './BlockEvents'
import BlockInfo from './BlockInfo'
import TransactionMsgs from './TransactionMsgs'

interface Props { }

const Block: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const { height: requestedHeight } = useParams();
  const block = useRedux((state) => state.block)
  const [latestBlock] = useRedux(state => state.core.blocks.slice(-1))
  const [loading] = useTaskSubscriber(TaskNames.App.Validators, TaskNames.Block.Info)

  const latestBlockHeight = latestBlock?.height ?? 0;

  const { events, transactions, blockInfo } = block
  const blockHeight = blockInfo?.height ?? requestedHeight ?? 0;

  const isInThePast = Number(blockHeight) <= Number(latestBlockHeight)

  return (
    <Page className={classes.root}>
      <DataLoadSegment loading={loading}>
        {blockInfo && (
          <Fragment>
            <BlockInfo blockInfo={blockInfo} />
            <TransactionMsgs transactions={transactions} />
            <BlockEvents events={events} />
          </Fragment>
        )}
        {(!blockInfo && !isInThePast) && (
          <BlockCountDown />
        )}
        {(!blockInfo && isInThePast) && (
          <NotFoundState title="No Block Found">
            <Typography variant="body1">
              We can’t find any block with this ID. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Blocks}>
                Blocks
              </CellLink>
              &nbsp;page to view existing blocks.
            </Typography>
            <Typography variant="body1">
              You may also check if this block exists in our legacy chain (TradeHub) &nbsp;
              <CellLink href={Paths.EcoSystem.Tradehub + `/block/${blockHeight}`}>
                here
              </CellLink>
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles({
  root: {},
})

export default Block
