import { useRedux } from 'js/hooks'
import { LoadingTasks } from 'js/models'
import { actions } from 'js/store'
import { uuidv4 } from 'js/utils'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

export type ErrorHandler = (error: any) => (() => void)

export type AsyncTaskTask<T> = () => Promise<T>
export type AsyncTaskErrorHandler = (error: Error) => Promise<boolean | undefined | void>

export type AsyncTaskRunner<T> = (task: AsyncTaskTask<T>, errorHandler?: AsyncTaskErrorHandler) => Promise<void>
export type AsyncTaskLoading = boolean
export type AsyncTaskError = Error | null
export type AsyncTaskClearError = () => void

export type AsyncTaskOutput<T> = [
  AsyncTaskRunner<T>,
  AsyncTaskLoading,
  AsyncTaskError,
  AsyncTaskClearError,
]

const parseError = (original: Error): Error => {
  const error = original
  return error
}
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default <T>(taskname: string): AsyncTaskOutput<T> => {
  const [error, setError] = useState<Error | null>(null)
  const dispatch = useDispatch()
  const loadingTasks = useRedux<LoadingTasks>((store) => store.layout.loadingTasks)

  const cleanup = () => {
    setError(null)
  }

  const asyncTaskRunner = async (task: AsyncTaskTask<T>, errorHandler?: AsyncTaskErrorHandler): Promise<void> => {
    if (typeof task !== 'function') {
      throw new Error('async task not a function')
    }
    if (typeof cleanup === 'function') cleanup()

    const taskUuid = uuidv4()

    dispatch(actions.Layout.addBackgroundLoading(taskname, taskUuid))
    try {
      await task()
    } catch (rawError) {
      if (errorHandler) {
        const handleErrorResult = await errorHandler(rawError as any)
        if (handleErrorResult === false) {
          return
        }
      }

      const error = parseError(rawError as any)
      setError(error)
    } finally {
      dispatch(actions.Layout.removeBackgroundLoading(taskUuid))
    }
  }

  const clearError = () => {
    setError(null)
  }

  const loadingState = !!loadingTasks[taskname]
  return [asyncTaskRunner, loadingState, error, clearError]
}
