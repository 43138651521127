import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { TextProposalFormState } from "js/models"
import store from "js/store"
import { escapeHtmlGo } from "js/utils"
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { MsgCancelUpgrade } from "carbon-js-sdk/lib/codec/cosmos/upgrade/v1beta1/tx"
import { CarbonTx } from "carbon-js-sdk"

export function cancelUpgradeProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const formState = store.getState().governance.submitProposalFormState as TextProposalFormState

  const { title, summary, metadata } = formState

  const msg = MsgCancelUpgrade.fromPartial({
    authority
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    initialDeposit,
    expedited: false,
    proposer: proposer ?? "",
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgCancelUpgrade,
        value: MsgCancelUpgrade.encode(msg).finish()
      }
    ],
  }

  return value
}
