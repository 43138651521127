import { Order } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/order'
import { Action } from 'redux'

export enum OrderActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_ORDER = 'SET_ORDER',
}

export type OrderActon = ClearAction | SetOrderAction

export interface ClearAction extends Action<OrderActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: OrderActionType.CLEAR,
  }
}

export interface SetOrderAction extends Action<OrderActionType.SET_ORDER> {
  order?: Order
}

export function setOrder(order?: Order): SetOrderAction {
  return {
    type: OrderActionType.SET_ORDER,
    order,
  }
}
