import { Insights } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/export'
import { Order } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/order'
import { TradesReponse } from 'js/reducers/account'
import { Action } from 'redux'
import { RestResponse } from 'tradehub-api-js'

export enum MarketActionType {
  CLEAR = 'CLEAR_POSITIONS',
  SET_STATS = 'SET_STATS',
  SET_MARKET = 'SET_MARKET',
  SET_ORDERS = 'SET_ORDERS',
  SET_TRADES = 'SET_TRADES',
  UPDATE_ORDERS_FILTERS = 'UPDATE_ORDERS_FILTERS',
  UPDATE_TRADES_FILTERS = 'UPDATE_TRADES_FILTERS',
  SET_LARGE_POSITIONS = 'SET_LARGE_POSITIONS',
  UPDATE_LARGE_POSITIONS = 'UPDATE_LARGE_POSITIONS',
  SET_RISKY_LONG_POSITIONS = 'SET_RISKY_LONG_POSITIONS',
  UPDATE_RISKY_LONG_POSITIONS = 'UPDATE_RISKY_LONG_POSITIONS',
  SET_RISKY_SHORT_POSITIONS = 'SET_RISKY_SHORT_POSITIONS',
  UPDATE_RISKY_SHORT_POSITIONS = 'UPDATE_RISKY_SHORT_POSITIONS',
  SET_PROFITABLE_POSITIONS = 'SET_PROFITABLE_POSITIONS',
  UPDATE_PROFITABLE_POSITIONS = 'UPDATE_PROFITABLE_POSITIONS',
}

export type MarketAction =
  | ClearAction
  | SetMarketAction
  | SetMarketStatsAction
  | SetOrdersAction
  | SetTradesAction
  | UpdateOrdersFiltersAction
  | UpdateTradesFiltersAction
  | SetLargePositionsAction
  | UpdateLargePositionsAction
  | SetRiskyLongPositionsAction
  | UpdateRiskyLongPositionsAction
  | SetProfitablePositionsAction
  | UpdateProfitablePositionsAction

export interface ClearAction extends Action<MarketActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: MarketActionType.CLEAR,
  }
}

export interface SetMarketAction extends Action<MarketActionType.SET_MARKET> {
  market: Market
}

export function setMarket(market: Market): SetMarketAction {
  return {
    type: MarketActionType.SET_MARKET,
    market,
  }
}

export interface SetMarketStatsAction
  extends Action<MarketActionType.SET_STATS> {
  stats: RestResponse.MarketStat
}

export function setMarketStats(stats: RestResponse.MarketStat): SetMarketStatsAction {
  return {
    type: MarketActionType.SET_STATS,
    stats,
  }
}

export interface SetOrdersAction extends Action<MarketActionType.SET_ORDERS> {
  orders: Order[]
}

export function setOrders(orders: Order[]): SetOrdersAction {
  return {
    type: MarketActionType.SET_ORDERS,
    orders,
  }
}
export interface SetTradesAction extends Action<MarketActionType.SET_TRADES> {
  trades: TradesReponse
}

export function setTrades(trades: TradesReponse): SetTradesAction {
  return {
    type: MarketActionType.SET_TRADES,
    trades,
  }
}

export interface UpdateOrdersFiltersAction
  extends Action<MarketActionType.UPDATE_ORDERS_FILTERS> {
  options: any
}

export function updateOrdersFilters(options: any): UpdateOrdersFiltersAction {
  return {
    type: MarketActionType.UPDATE_ORDERS_FILTERS,
    options,
  }
}

export interface UpdateTradesFiltersAction
  extends Action<MarketActionType.UPDATE_TRADES_FILTERS> {
  options: any
}

export function updateTradesFilters(options: any): UpdateTradesFiltersAction {
  return {
    type: MarketActionType.UPDATE_TRADES_FILTERS,
    options,
  }
}

export interface SetLargePositionsAction
  extends Action<MarketActionType.SET_LARGE_POSITIONS> {
  largePositions: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
}

export function setLargePositions(
  largePositions: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>,
): SetLargePositionsAction {
  return {
    type: MarketActionType.SET_LARGE_POSITIONS,
    largePositions,
  }
}

export interface UpdateLargePositionsAction
  extends Action<MarketActionType.UPDATE_LARGE_POSITIONS> {
  options: any
}

export function updateLargePositions(options: any): UpdateLargePositionsAction {
  return {
    type: MarketActionType.UPDATE_LARGE_POSITIONS,
    options,
  }
}

export interface SetRiskyLongPositionsAction
  extends Action<MarketActionType.SET_RISKY_LONG_POSITIONS> {
  riskyPositions: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
}

export function setRiskyLongPositions(
  riskyPositions: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>,
): SetRiskyLongPositionsAction {
  return {
    type: MarketActionType.SET_RISKY_LONG_POSITIONS,
    riskyPositions,
  }
}

export interface UpdateRiskyLongPositionsAction
  extends Action<MarketActionType.UPDATE_RISKY_LONG_POSITIONS> {
  options: any
}

export function updateRiskyLongPositions(
  options: any,
): UpdateRiskyLongPositionsAction {
  return {
    type: MarketActionType.UPDATE_RISKY_LONG_POSITIONS,
    options,
  }
}


export interface SetProfitablePositionsAction
  extends Action<MarketActionType.SET_PROFITABLE_POSITIONS> {
  profitablePositions: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
}

export function setProfitablePositions(
  profitablePositions: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>,
): SetProfitablePositionsAction {
  return {
    type: MarketActionType.SET_PROFITABLE_POSITIONS,
    profitablePositions,
  }
}

export interface UpdateProfitablePositionsAction
  extends Action<MarketActionType.UPDATE_PROFITABLE_POSITIONS> {
  options: any
}

export function updateProfitablePositions(
  options: any,
): UpdateProfitablePositionsAction {
  return {
    type: MarketActionType.UPDATE_PROFITABLE_POSITIONS,
    options,
  }
}
