import { Insights } from 'carbon-js-sdk'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { call, Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import { clear, setTokensInfo, setTotalBalances } from '../actions/tokens'
import { runSagaTask, waitforSDK } from './helper'
import Saga from './Saga'

export default class Tokens extends Saga {
  private readonly isMobile: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchTotalBalances],
      [this, this.fetchTokens],
      [this, this.watchSetNetwork],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchTotalBalances(): any {
    yield runSagaTask(TaskNames.Tokens.CirculateSupply, function* () {
      const sdk = yield* waitforSDK()

      const balanceResponse = (yield call([sdk.insights, sdk.insights.BalanceSupply])) as Insights.InsightsQueryResponse<Insights.QueryGetBalanceSupplyResponse>
      yield put(setTotalBalances(balanceResponse.result.entries))
    });
  }

  private *fetchTokens(): any {
    yield runSagaTask(TaskNames.Tokens.Info, function* () {
      const sdk = yield* waitforSDK()
      const allTokens = (yield sdk.token.getAllTokens()) as Token[]

      yield put(setTokensInfo(allTokens))
    })
  }
}
