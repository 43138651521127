import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { ReactComponent as SvgNoData } from 'assets/NoData.svg'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

interface Props {
  boxClass?: string
  emptyMessage?: string
  itemName?: string
  svgClass?: string
  textClass?: string
}

const TableEmptyState: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { boxClass, emptyMessage, itemName = 'items', svgClass, textClass } = props
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={12} className={clsx(classes.box, boxClass)}>
        <SvgNoData className={clsx(classes.graphic, svgClass)} />
        <Typography variant="body1" align="center" className={textClass}>
          {
            emptyMessage || `No ${itemName.toLowerCase()} to show yet`
          }
        </Typography>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    margin: theme.spacing(4, 0),
  },
  graphic: {
    display: 'block',
    margin: theme.spacing(0, 'auto', 2),
    maxWidth: '7.875rem',
    height: '7.875rem',
  },
}))

export default TableEmptyState
