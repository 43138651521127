import { List, ListItem, makeStyles, Theme } from '@material-ui/core'
import dayjs from 'dayjs'
import { CellLink, Section } from 'js/components'
import { fromNow } from 'js/helpers/dayjs'
import { useRedux } from 'js/hooks'
import { BlockProp } from 'js/reducers/blocks'
import React, { ReactElement, useMemo } from 'react'

export interface BlockInfoProps {
  blockInfo: BlockProp
}

interface Props extends BlockInfoProps { }

const BlockInfo: React.FunctionComponent<Props> = ({
  blockInfo,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const valAddrMap = useRedux((state) => state.core.valAddrMap)
  const time = dayjs(blockInfo.time)
  const valConsAddress = blockInfo.proposerAddress

  const validator = useMemo(() => {
    return Object.values(valAddrMap).find((valpair) => {
      return valpair.tmValidator?.address
        && Buffer.from(valpair.tmValidator.address).toString("hex") === valConsAddress
    })?.carbonValidator;
  }, [valAddrMap, valConsAddress])

  return (
    <Section title="Overview">
      <List>
        <ListItem disableGutters>
          <div className={classes.header}>Height</div>
          <div className={classes.value}>{blockInfo.height}</div>
        </ListItem>
        <ListItem disableGutters>
          <div className={classes.header}>Created On</div>
          <div className={classes.value}>
            {time.format('YYYY-MM-DD HH:mm:ss')}
            <span className={classes.timeAgo}>({fromNow(time)})</span>
          </div>
        </ListItem>
        <ListItem disableGutters>
          <div className={classes.header}>Validated By</div>
          <div className={classes.value}>
            {!validator?.operatorAddress ? (
              validator?.description?.moniker
            ) : (
              <CellLink to={`/validator/${validator?.operatorAddress}`}>
                {validator?.description?.moniker}
              </CellLink>
            )}
          </div>
        </ListItem>
        <ListItem disableGutters>
          <div className={classes.header}>Transaction Count</div>
          <div className={classes.value}>
            {blockInfo.txs?.length ?? 0}
          </div>
        </ListItem>
        <ListItem disableGutters>
          <div className={classes.header}>Block Hash</div>
          <div className={classes.value}>{blockInfo.id}</div>
        </ListItem>
      </List>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    flexBasis: '25%',
    paddingRight: '1rem',
  },
  value: {
    width: '47.5rem',
    overflowX: 'auto',
  },
  timeAgo: {
    marginLeft: theme.spacing(1.25),
    color: theme.palette.grey[500],
  },
}))

export default BlockInfo
