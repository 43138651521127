import { BoxProps } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { DepositProposal, SubmitProposal } from './components'

type Props = BoxProps
const Tutorials: React.FunctionComponent<Props> = (

): ReactElement<Props> => {
  return (
    <>
      <SubmitProposal />
      <DepositProposal />
    </>
  )
}

export default Tutorials
