import { Box, makeStyles, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { BigNumber } from 'bignumber.js'
import { Insights } from 'carbon-js-sdk'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, Section, TableCellInfo, TableEmptyState, TableSectionCell } from 'js/components'
import PaginationByData from 'js/components/PaginationByData'
import { useTaskSubscriber } from 'js/hooks'
import moment from 'moment'
import React, { ReactElement, ReactNode, useState } from 'react'

interface Props {
  pnl?: Insights.IndivPnl[],
  title: string
  tasks: any
  action: any
}

const HeaderCells: TableCellInfo[] = [
  { align: 'inherit', content: 'Rank' },
  { align: 'inherit', content: 'Account' },
  { align: 'center', content: 'Realised PnL' },
  { align: 'center', content: 'Unrealised PnL' },
  { content: 'Total PNL' },
]

const Pnl: React.FunctionComponent<Props> = ({
  pnl,
  title,
  tasks,
  action,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(tasks)
  const fromTime = title?.includes("Lifetime") ? moment("2022-01-11") : moment().subtract(30, 'days')
  const toTime = moment()
  const [page, setPage] = useState(1)
  const itemsPerPage = 10
  return (
    <Section title={title}>
      <DataLoadSegment loading={loading || !pnl}>
        <div className={classes.dateParams}>
          <div className={classes.group}>
            <b style={{ minWidth: 40 }}>From </b>
            <b style={{ minWidth: 40 }}>To </b>
          </div>
          <div className={classes.group}>
            {/* <CellLink to={`/block/${pnl?.from_block}`}><span>{pnl?.from_block}</span></CellLink>
            <CellLink to={`/block/${pnl?.to_block}`}><span>{pnl?.to_block}</span></CellLink> */}
          </div>
          <div className={classes.group}>
            <span className={classes.time}>({fromTime.format('YYYY-MM-DD HH:mm:ss')})</span>
            <span className={classes.time}>({toTime.format('YYYY-MM-DD HH:mm:ss')})</span>
          </div>
        </div>
        <ListTable>
          <TableHead>
            <TableRow>
              {HeaderCells.map((header: TableCellInfo, index: number) => (
                <TableSectionCell key={index} {...header} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {pnl?.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage).map(renderPnl)}
          </TableBody>
        </ListTable>

        {pnl?.length && (
          <Box marginTop={3}>
            <PaginationByData
              data={pnl}
              setPage={setPage}
              page={page}
              itemsPerPage={itemsPerPage}
            />
          </Box>
        )}
        {(!pnl || pnl?.length <= 0) && (
          <TableEmptyState itemName="pnl-30d" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderPnl(
    p: Insights.IndivPnl,
    index: number,
  ): ReactNode {
    const realizedPnlBN: BigNumber = new BigNumber(p?.realizedPnl)
    const unrealizedPnlBN: BigNumber = new BigNumber(p?.unrealizedPnl)
    const totalPnlBN: BigNumber = new BigNumber(p?.totalPnl)
    const offset = (page - 1) * itemsPerPage
    const decimals: number = 6
    return (
      <TableRow key={p?.address} hover>
        <TableCell style={{ width: '60px' }}>
          {index + 1 + offset}
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${p?.address}`}>
            {p?.address ?? "-"}
          </CellLink>
        </TableCell>
        <AmountDenomCell amount={realizedPnlBN.toFormat(decimals)} denom="" />
        <AmountDenomCell amount={unrealizedPnlBN.toFormat(decimals)} denom="" />
        <AmountDenomCell amount={totalPnlBN.toFormat(decimals)} denom="USD" />
      </TableRow>
    )
  }
}

const useStyles = makeStyles(() => ({
  dateParams: {
    display: 'flex',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
  },
  time: {
    color: grey[500],
  },
}))

export default Pnl
