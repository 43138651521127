import {
  Avatar,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery,
} from '@material-ui/core'
import { BigNumber } from 'bignumber.js'
import dayjs, { Dayjs } from 'dayjs'
import { CellLink, ListTable, Section, TooltipHint } from 'js/components'
import { fromNow } from 'js/helpers/dayjs'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { Follow } from 'react-twitter-widgets'
import { RestModels } from 'tradehub-api-js'
import { copyTextToClipBoard, shorten } from 'js/helpers'
import { CopyTradeDarkSVG } from 'assets'
import { Profile as ProfileType } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/profile/export'
import { SWTHAddress } from 'carbon-js-sdk/lib/util/address'
import { RootState } from 'js/store'
import { useSelector } from 'react-redux'

interface Props {
  className?: string
  profile?: ProfileType
  validator?: RestModels.ValDescription
}

const Profile: React.FunctionComponent<Props> = ({
  profile,
  validator,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const smallScreen: boolean = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  )
  const sdk = useSelector((state: RootState) => state.core.carbonSDK)
  const xsmallScreen = useMediaQuery('(max-width:375px)');
  const combined: any = { ...profile, ...validator }
  const [evmAddress, setEvmAddress] = useState<string | undefined>(undefined)
  const [copied, setCopied] = useState(false);
  const [keybaseProfile, setKeybaseProfile] = useState({
    url: '',
    image: '',
  })
  const address: string = profile?.address ? profile.address : 'Invalid address'
  const cellRows = ['username', 'twitter', 'lastSeen', 'lastSeenBlock']
  useEffect(() => {
    if (validator?.identity.length !== 40) return

    fetch(
      `https://keybase.io/_/api/1.0/user/lookup.json?key_fingerprint=${validator.identity}&fields=basics,pictures`,
    )
      .then((r: any) => (r ? r.json() : null))
      .then((json: any) => {
        if (json && json?.them && json?.them[0]) {
          const user = json?.them[0]
          const image = user?.pictures?.primary?.url
          const url = `https://keybase.io/${user?.basics?.username}`
          setKeybaseProfile({ url, image })
        }
      })
  }, [validator])

  useEffect(() => {
    if (!sdk) return
    const getEvmAcc = async () => {
      const evmMergeAcc = await sdk.query.evmmerge.MappedEvmAddress({ address })
      const toAddressBytes = SWTHAddress.getAddressBytes(evmMergeAcc?.mappedAddress, sdk.network);
      const toAddressHex = Buffer.from(toAddressBytes).toString("hex");
      setEvmAddress(evmMergeAcc?.mappedAddress ? `0x${toAddressHex}` : undefined)
    }
    getEvmAcc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])

  const copyText = (text: string) => {
    copyTextToClipBoard(text)
    //set Copied to true to show Copied UI
    setCopied(true);
    setTimeout(() => setCopied(false), 1300)
  }

  return (
    <Section variant="primary" className={xsmallScreen ? classes.sectionXSmallScreen : classes.section}>
      <div className={classes.titleContainer}>
        <TooltipHint title={address}>
          <span className={classes.title}>
            {smallScreen ? shorten(address) : address}
          </span>
        </TooltipHint>
        <TooltipHint title={"Copied!"} open={copied}>
          <CopyTradeDarkSVG onClick={() => copyText(address)} fontSize={"small"} className={classes.iconButton} />
        </TooltipHint>
      </div>
      {evmAddress && (
        <div className={classes.subtitleContainer}>
          <TooltipHint title={evmAddress}>
            <span className={classes.title}>
              {smallScreen ? shorten(evmAddress) : evmAddress}
            </span>
          </TooltipHint>
          {/* <TooltipHint title={"Copied!"} open={copied}>
            <CopyTradeDarkSVG onClick={() => copyText(evmAddress)} fontSize={"small"} className={classes.iconButton} />
          </TooltipHint> */}
        </div>)
      }
      <div className={classes.profileContainer}>
        <div className={classes.profile}>
          <div className={classes.profileHeader}>
            {keybaseProfile.image !== '' && (
              <Avatar
                alt="Profile Image from Keybase"
                variant="square"
                className={classes.avatar}
                src={keybaseProfile.image}
              />
            )}
          </div>
          <ListTable>
            <TableBody>{cellRows.map(renderItem)}</TableBody>
          </ListTable>
        </div>
      </div>
    </Section>
  )

  function renderItem(key: any): ReactNode {
    const label: string = (key.charAt(0).toUpperCase() + key.slice(1))
      .split('_')
      .join(' ')

    switch (label) {
      case 'Identity':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <span>
                {keybaseProfile.url === '' ? (
                  combined[key]
                ) : (
                  <CellLink href={keybaseProfile.url}>{combined[key]}</CellLink>
                )}
              </span>
            </TableCell>
          </TableRow>
        )
      case 'Twitter':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <span className={classes.stringWithoutTwitter}>
                {!combined[key] ? "-" :
                  <>
                    <CellLink href={`https://twitter.com/${combined[key]}`}>
                      {combined[key]}
                    </CellLink>
                    <Follow username={`${combined[key]}`} />
                  </>
                }
              </span>
            </TableCell>
          </TableRow>
        )
      case 'Website':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <span>
                <CellLink href={`${combined[key]}`}>{combined[key]}</CellLink>
              </span>
            </TableCell>
          </TableRow>
        )
      case 'LastSeenBlock':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{"Last seen block"}</TableCell>
            <TableCell className={classes.string}>
              {combined[key] === '0' ? (
                <div className={classes.never}>never</div>
              ) : (
                <CellLink to={`/block/${combined[key]}`}>
                  <span>
                    {combined[key]}
                  </span>
                </CellLink>
              )}
            </TableCell>
          </TableRow>
        )
      case 'LastSeen':
        const time: Dayjs | "-" = combined[key] ? dayjs(combined[key]) : "-"
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{"Last seen time"}</TableCell>
            <TableCell className={classes.string}>
              <span>
                {time !== "-" && time.year() < 2019 ? (
                  <div className={classes.never}>never</div>
                ) : (
                  <React.Fragment>
                    {time !== "-" ? time.format('YYYY-MM-DD HH:mm:ss') : "-"}
                    <span className={classes.timeAgo}>({time !== "-" ? fromNow(time) : "-"})</span>
                  </React.Fragment>
                )}
              </span>
            </TableCell>
          </TableRow>
        )
      case 'Jailed':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              {combined[key] ? 'yes' : 'no'}
            </TableCell>
          </TableRow>
        )
      case 'Wallet address':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <CellLink to={`/account/${combined[key]}`}>
                {combined[key]}
              </CellLink>
            </TableCell>
          </TableRow>
        )
      case 'Commission rate':
      case 'Max rate':
      case 'Max change rate':
        const num: BigNumber = new BigNumber(combined[key]).times(100)
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>{num.toFormat(2)}%</TableCell>
          </TableRow>
        )
      case 'Active since':
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              {combined.bond_status === 'bonded' ? (
                <CellLink to={`/block/${combined[key]}`}>
                  {combined[key]}
                </CellLink>
              ) : (
                '-'
              )}
            </TableCell>
          </TableRow>
        )
      case 'Unbonding time':
        if (combined.bond_status === 'bonded') return
        const unbondTime: Dayjs = dayjs(combined[key])
        if (unbondTime.year() < 2019) return
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              {unbondTime.format('YYYY-MM-DD HH:mm:ss')}
            </TableCell>
          </TableRow>
        )
      case 'Unbonding height':
        if (combined[key] === 0) return
        if (combined.bond_status === 'bonded') return
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>
              <CellLink to={`/block/${combined[key]}`}>
                {combined[key]}
              </CellLink>
            </TableCell>
          </TableRow>
        )
      default:
        return (
          <TableRow className={classes.tableRow} key={label} hover>
            <TableCell className={classes.header}>{label}</TableCell>
            <TableCell className={classes.string}>{(!combined[key] || combined[key]?.length === 0) ? '-' : combined[key]}</TableCell>
          </TableRow>
        )
    }
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
  },
  sectionXSmallScreen: {
    padding: theme.spacing(2, 1.3)
  },
  header: {
    flexBasis: '33%',
    paddingRight: '1rem',
  },
  string: {
    wordBreak: 'break-all',
  },
  stringWithoutTwitter: {
    wordBreak: 'break-all',
  },
  profileHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  profileContainer: {
    display: 'flex',
    overflow: 'scroll'
  },
  profile: {
    flexGrow: 1,
  },
  timeAgo: {
    marginLeft: theme.spacing(1.25),
    color: theme.palette.grey[500],
    whiteSpace: 'nowrap',
  },
  tableRow: {
    height: '3.125rem',
    '&.MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: switcheo.hover,
      },
    },
    '&:hover': {
      backgroundColor: switcheo.hover,
    },
  },
  never: {
    color: theme.palette.grey[500],
  },
  avatar: {
    width: '3.125rem',
    height: '3.125rem',
  },
  titleContainer: {
    fontSize: '1.5rem',
    marginBottom: theme.spacing(1.25),
  },
  subtitleContainer: {
    fontSize: '1rem',
    marginBottom: theme.spacing(1.25),
  },
  title: {
    fontWeight: 700,
  },
  iconButton: {
    margin: theme.spacing(0, 0.5, 0, 0.5),
    cursor: 'pointer'
  },
}))

export default Profile
