import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import TutorialDialog from 'js/components/TutorialDialog'
import { Tutorial, TutorialDialogProps } from 'js/components/TutorialDialog/TutorialDialog'
import { TutorialKeys } from 'js/constants'
import React, { ReactElement } from 'react'
import { Step1, Step2, Step3, Step4 } from './components'

type Props = TutorialDialogProps

const TUTORIAL_KEY = TutorialKeys.SubmitProposal

const tutorials: Tutorial[] = [{
  TutorialContent: Step1,
  title: 'Submitting a proposal',
  skippable: true,
  nextButtonLabel: 'Next: Deposits',
}, {
  TutorialContent: Step2,
  title: 'Deposits for Proposals',
  skippable: true,
  nextButtonLabel: 'Next: Voting',
}, {
  TutorialContent: Step3,
  title: 'Voting Power And Options',
  skippable: true,
  nextButtonLabel: 'Next: Passing Proposals',
}, {
  TutorialContent: Step4,
  title: 'How to pass a proposal',
  skippable: false,
  nextButtonLabel: 'Start Now',
  nextAction: 'close',
}]

const SubmitProposal: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> | null => {
  const { className, children, ...rest } = props
  const classes = useStyles(props)

  return (
    <TutorialDialog
      tutorialKey={TUTORIAL_KEY}
      tutorials={tutorials}
      {...rest}
      className={clsx(classes.root, className)}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
}))

export default SubmitProposal
