import { AdminModule } from 'carbon-js-sdk'
import { SetRewardCurveProposalFormState } from 'js/models'
import store from 'js/store'
import { escapeHtmlGo } from "js/utils"
import { fieldTypes } from "../Helpers/types"
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { CarbonTx } from "carbon-js-sdk"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { MsgSetRewardCurve } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/tx'

export const setRewardCurveFormRequiredFields = [
  'startTime',
  'initialRewardBps',
  'reductionMultiplierBps',
  'reductionIntervalSeconds',
  'reductions',
  'finalRewardBps',
]

export function setRewardCurveConfig() {
  return {
    startTime: {
      id: 'startTime',
      label: 'Start Time',
      type: fieldTypes.datetime,
      constraints: {
        inFuture: true,
      },
    },
    initialRewardBps: {
      id: 'initialRewardBps',
      label: 'Initial Reward Bps',
      type: fieldTypes.number,
      tooltip: 'Initial rewards/fees to be distributed in basis points',
      constraints: {
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },
    reductionMultiplierBps: {
      id: 'reductionMultiplierBps',
      label: 'Reduction Multiplier Bps',
      type: fieldTypes.number,
      tooltip: 'Rewards/fees to be reduced in basis points on each interval',
      constraints: {
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },
    reductionIntervalSeconds: {
      id: 'reductionIntervalSeconds',
      label: 'Reduction Interval Seconds',
      type: fieldTypes.number,
      tooltip: 'Interval period in seconds',
      constraints: {
        greaterThanOrEqualTo: 60,
        onlyInteger: true,
      },
    },
    reductions: {
      id: 'reductions',
      label: 'Reductions',
      type: fieldTypes.number,
      tooltip: 'Number of intervals that will apply reduce the allocation',
      constraints: {
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },
    finalRewardBps: {
      id: 'finalRewardBps',
      label: 'Final Reward Bps',
      type: fieldTypes.number,
      tooltip: 'Minimum rewards/fees to be distributed in basis points',
      constraints: {
        greaterThanOrEqualTo: 0,
        onlyInteger: true,
      },
    },
  }
}

export function setRewardCurveProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as SetRewardCurveProposalFormState

  const { title, summary, metadata, startTime, initialRewardBps, reductionMultiplierBps,
    reductionIntervalSeconds, reductions, finalRewardBps } = formState!

  const setRewardCurveProposalParams: AdminModule.SetRewardCurveParams = {
    startTime: new Date(startTime),
    initialRewardBps: parseInt(initialRewardBps),
    reductionMultiplierBps: parseInt(reductionMultiplierBps),
    reductionIntervalSeconds: parseInt(reductionIntervalSeconds),
    reductions: parseInt(reductions),
    finalRewardBps: parseInt(finalRewardBps),
  }

  const msg = MsgSetRewardCurve.fromPartial({
    creator: authority,
    setRewardCurveParams: setRewardCurveProposalParams
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    expedited: false,
    initialDeposit,
    proposer: proposer ?? "",
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgSetRewardCurve,
        value: MsgSetRewardCurve.encode(msg).finish()
      }
    ],
  }

  return value
}
