
import BigNumber from 'bignumber.js'
import { BN_ZERO } from 'js/utils'

// taken from switcheo-disco
/**
 * Calculates estimated liq. price
 * (refer to https://www.notion.so/switcheo/Calculating-Est-Liq-Price-Futures-8dd7ae05301648ffb86a60f900c13ae3 for full Est. Liq Price calculation)
 *
 * Required Margin = (requiredMM * abs(position.size) * position.avgEntryPrice).decimalPlaces(0, BigNumber.ROUND_CEIL)
 * Est. Liq Price = (requiredMargin - allocatedMargin + (position.size * entryPrice)) / position.size
 * @param requiredMM required maintenance margin (see getRequiredMM for calculation)
 * @param size adjusted value of position size (no need to abs())
 * @param avgEntryPrice adjusted value of position.avgEntryPrice
 * @param allocatedMargin adjusted value of position.allocatedMargin
 * @returns Est. Liq Price (BigNumber)
 */
export default function estLiqPrice(
  requiredMM: BigNumber,
  size: BigNumber,
  avgEntryPrice: BigNumber,
  allocatedMargin: BigNumber,
): BigNumber {
  const requiredMargin = requiredMM.times(size.abs()).times(avgEntryPrice)
  return size.isZero() ? BN_ZERO : requiredMargin.minus(allocatedMargin).plus(size.times(avgEntryPrice)).div(size)
}

/**
 * Calculates initial margin fraction (IMF), which is used to calculate max position leverage
 * i.e. for btc_z29, margin fraction = 1% + 0.0005% every 0.1 BTC
 *
 * IMF = initialMarginBase + (floor[PosSize / RiskStepSize] * InitialMarginStep)
 * @param size adjusted value of position size
 * @param initialMarginBase adjusted value of market.initialMarginBase (i.e. 1%)
 * @param riskStepSize adjusted value of market.riskStepSize (i.e. 0.1 BTC)
 * @param initialMarginStep adjusted value of market.initialMarginStep (i.e. 0.0005%)
 * @returns IMF (BigNumber)
 */
export function getInitialMarginFraction(
  size: BigNumber,
  initialMarginBase: BigNumber,
  riskStepSize: BigNumber,
  initialMarginStep: BigNumber,
): BigNumber {
  // Calculates margin fraction based on size (i.e. 0.0005% every 0.1 BTC)
  const marginFractionIncrement = riskStepSize.isZero()
    ? new BigNumber(0)
    : size.abs().div(riskStepSize).integerValue(BigNumber.ROUND_FLOOR).times(initialMarginStep)
  return initialMarginBase.plus(marginFractionIncrement)
}

/**
* Calculates required maintenance margin (requiredMM), which is used to calculate est liquidation price
*
* RequiredMM = IMF * market.maintenanceMarginRatio
* @param imf adjusted value of IMF (see getInitialMarginFraction for calculation)
* @param maintenanceMarginRatio adjusted value of market.maintenanceMarginRatio
* @returns RequiredMM (BigNumber)
*/
export function getRequiredMM(
  imf: BigNumber,
  maintenanceMarginRatio: BigNumber,
): BigNumber {
  return imf.times(maintenanceMarginRatio)
}