import { Models } from 'carbon-js-sdk'
import { TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { Order } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/order'
import { CrossChainTransferDetailed } from 'carbon-js-sdk/lib/hydrogen'
import { Profile } from 'carbon-js-sdk/lib/insights'
import { InternalTransferResponse, TradesReponse } from 'js/reducers/account'
import { PositionsResponse } from 'js/reducers/position'
import { TransactionsResponse } from 'js/reducers/transactions'
import { DelegationRewards } from 'js/utils'
import { Action } from 'redux'

export enum AccountActionType {
  CLEAR = 'CLEAR_ACCOUNT',
  SET_BALANCE = 'SET_BALANCE',
  SET_TRANSACTIONS = 'SET_ACCOUNT_TRANSACTIONS',
  SET_TYPES = 'SET_TYPES',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
  SET_PROFILE = 'SET_PROFILE',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_POSITIONS = 'SET_POSITIONS',
  UPDATE_POSITIONS = 'UPDATE_POSITIONS',
  SET_POSITIONS_HISTORY = 'SET_POSITION_HISTORY',
  UPDATE_POSITIONS_HISTORY = 'UPDATE_POSITIONS_HISTORY',
  SET_OPEN_ORDERS = 'SET_OPEN_ORDERS',
  UPDATE_OPEN_ORDERS = 'UPDATE_OPEN_ORDERS',
  SET_ORDERS = 'SET_ORDERS',
  UPDATE_ORDERS = 'UPDATE_ORDERS',
  SET_ACCOUNT_TRADES = 'SET_ACCOUNT_TRADES',
  UPDATE_TRADES = 'UPDATE_TRADES',
  SET_DELEGATIONS = 'SET_DELEGATIONS',
  SET_REWARDS = 'SET_REWARDS',
  SET_CROSSCHAIN_TRANSFERS = 'SET_CROSSCHAIN_TRANSFERS',
  SET_DISABLE_PAGINATION_FORWARD_BUTTON = 'SET_DISABLE_PAGINATION_FORWARD_BUTTON',
  SET_INTERNAL_TRANSFER = 'SET_INTERNAL_TRANSFER',
  UPDATE_INTERNAL_TRANSFER = 'UPDATE_INTERNAL_TRANSFER',
  SET_INTERNAL_TRANSFER_DISABLE_PAGINATION_FORWARD_BUTTON = 'SET_INTERNAL_TRANSFER_DISABLE_PAGINATION_FORWARD_BUTTON'
}

export type AccountAction =
  | ClearAction
  | SetBalanceAction
  | SetTransactionsAction
  | SetTransactionTypesAction
  | SetInternalTransferAction
  | UpdateInternalTransferAction
  | SetInternalTransferDisablePaginationForwardButtonAction
  | UpdateFiltersAction
  | SetProfileAction
  | SetAddressAction
  | SetPositionsAction
  | UpdatePositionsAction
  | SetPositionsHistoryAction
  | UpdatePositionsHistoryAction
  | SetOpenOrdersAction
  | UpdateOpenOrdersAction
  | SetOrdersAction
  | UpdateOrdersAction
  | SetTradesAction
  | SetDelegationsAction
  | SetRewardsAction
  | SetCrossChainTransfersAction
  | SetDisablePaginationForwardButtonAction

export interface ClearAction extends Action<AccountActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: AccountActionType.CLEAR,
  }
}

export interface SetBalanceAction
  extends Action<AccountActionType.SET_BALANCE> {
  balance: TokenBalance[]
}

export function setBalance(balance: TokenBalance[]): SetBalanceAction {
  return {
    type: AccountActionType.SET_BALANCE,
    balance,
  }
}

export interface UpdateInternalTransferAction
  extends Action<AccountActionType.UPDATE_INTERNAL_TRANSFER> {
  options: any
}


export function UpdateInternalTransfer(
  options: any,
): UpdateInternalTransferAction {
  return {
    type: AccountActionType.UPDATE_INTERNAL_TRANSFER,
    options,
  }
}
export interface SetInternalTransferAction
  extends Action<AccountActionType.SET_INTERNAL_TRANSFER> {
  internalTransfer: InternalTransferResponse
}


export function setInternalTransfer(
  internalTransfer: InternalTransferResponse,
): SetInternalTransferAction {
  return {
    type: AccountActionType.SET_INTERNAL_TRANSFER,
    internalTransfer,
  }
}

export interface SetTransactionsAction
  extends Action<AccountActionType.SET_TRANSACTIONS> {
  transactions: TransactionsResponse //TODO: replace with new gRPC
}

export function setTransactions(
  transactions: TransactionsResponse,
): SetTransactionsAction {
  return {
    type: AccountActionType.SET_TRANSACTIONS,
    transactions,
  }
}
export interface SetProfileAction
  extends Action<AccountActionType.SET_PROFILE> {
  profile?: Profile
}

export function setProfile(profile?: Profile): SetProfileAction {
  return {
    type: AccountActionType.SET_PROFILE,
    profile,
  }
}

export interface UpdatePositionsAction
  extends Action<AccountActionType.UPDATE_POSITIONS> {
  options: any
}

export function updatePositions(options: any): UpdatePositionsAction {
  return {
    type: AccountActionType.UPDATE_POSITIONS,
    options,
  }
}

export interface SetPositionsAction
  extends Action<AccountActionType.SET_POSITIONS> {
  positions: PositionsResponse
}

export function setPositions(positions: PositionsResponse): SetPositionsAction {
  return {
    type: AccountActionType.SET_POSITIONS,
    positions,
  }
}

export interface UpdatePositionsHistoryAction
  extends Action<AccountActionType.UPDATE_POSITIONS_HISTORY> {
  options: any
}

export function updatePositionsHistory(options: any): UpdatePositionsHistoryAction {
  return {
    type: AccountActionType.UPDATE_POSITIONS_HISTORY,
    options,
  }
}

export interface SetPositionsHistoryAction
  extends Action<AccountActionType.SET_POSITIONS_HISTORY> {
  positionsHistory: PositionsResponse
}

export function setPositionsHistory(positionsHistory: PositionsResponse): SetPositionsHistoryAction {
  return {
    type: AccountActionType.SET_POSITIONS_HISTORY,
    positionsHistory,
  }
}

export interface SetAddressAction
  extends Action<AccountActionType.SET_ADDRESS> {
  address: string
}

export function setAddress(address: string): SetAddressAction {
  return {
    type: AccountActionType.SET_ADDRESS,
    address,
  }
}

export interface SetTransactionTypesAction
  extends Action<AccountActionType.SET_TYPES> {
  types: string[]
}
export function setTypes(types: string[]): SetTransactionTypesAction {
  return {
    type: AccountActionType.SET_TYPES,
    types,
  }
}
export interface SetOpenOrdersAction
  extends Action<AccountActionType.SET_OPEN_ORDERS> {
  openOrders: Order[]
}
export function setOpenOrders(openOrders: Order[]): SetOpenOrdersAction {
  return {
    type: AccountActionType.SET_OPEN_ORDERS,
    openOrders,
  }
}

export interface UpdateOpenOrdersAction
  extends Action<AccountActionType.UPDATE_OPEN_ORDERS> {
  options: any
}

export function updateOpenOrders(options: any): UpdateOpenOrdersAction {
  return {
    type: AccountActionType.UPDATE_OPEN_ORDERS,
    options,
  }
}

export interface SetOrdersAction extends Action<AccountActionType.SET_ORDERS> {
  orders: Order[]
}
export function setOrders(orders: Order[]): SetOrdersAction {
  return {
    type: AccountActionType.SET_ORDERS,
    orders,
  }
}

export interface UpdateOrdersAction
  extends Action<AccountActionType.UPDATE_ORDERS> {
  options: any
}

export function updateOrders(options: any): UpdateOrdersAction {
  return {
    type: AccountActionType.UPDATE_ORDERS,
    options,
  }
}

export interface SetTradesAction
  extends Action<AccountActionType.SET_ACCOUNT_TRADES> {
  trades: TradesReponse
}
export function setTrades(trades: TradesReponse): SetTradesAction {
  return {
    type: AccountActionType.SET_ACCOUNT_TRADES,
    trades,
  }
}

export interface UpdateTradesAction
  extends Action<AccountActionType.UPDATE_TRADES> {
  options: any
}

export function updateTrades(options: any): UpdateTradesAction {
  return {
    type: AccountActionType.UPDATE_TRADES,
    options,
  }
}

export interface SetDelegationsAction
  extends Action<AccountActionType.SET_DELEGATIONS> {
  delegations: Models.Staking.DelegationResponse[]
}
export function setDelegations(delegations: Models.Staking.DelegationResponse[]): SetDelegationsAction {
  return {
    type: AccountActionType.SET_DELEGATIONS,
    delegations,
  }
}
export interface SetRewardsAction
  extends Action<AccountActionType.SET_REWARDS> {
  rewards: DelegationRewards[]
}
export function setRewards(rewards: DelegationRewards[]): SetRewardsAction {
  return {
    type: AccountActionType.SET_REWARDS,
    rewards,
  }
}

export interface UpdateFiltersAction
  extends Action<AccountActionType.UPDATE_FILTERS> {
  options: any
}

export function updateFilters(options: any): UpdateFiltersAction {
  return {
    type: AccountActionType.UPDATE_FILTERS,
    options,
  }
}

export interface SetCrossChainTransfersAction
  extends Action<AccountActionType.SET_CROSSCHAIN_TRANSFERS> {
  crossChainTransfers: CrossChainTransferDetailed[]
}

export function setCrossChainTransfers(
  crossChainTransfers: CrossChainTransferDetailed[],
): SetCrossChainTransfersAction {
  return {
    type: AccountActionType.SET_CROSSCHAIN_TRANSFERS,
    crossChainTransfers,
  }
}
export interface SetDisablePaginationForwardButtonAction
  extends Action<AccountActionType.SET_DISABLE_PAGINATION_FORWARD_BUTTON> {
  disablePaginationForwardButton: boolean
}

export function setDisablePaginationForwardButton(
  disablePaginationForwardButton: boolean,
): SetDisablePaginationForwardButtonAction {
  return {
    type: AccountActionType.SET_DISABLE_PAGINATION_FORWARD_BUTTON,
    disablePaginationForwardButton,
  }
}

export interface SetInternalTransferDisablePaginationForwardButtonAction
  extends Action<AccountActionType.SET_INTERNAL_TRANSFER_DISABLE_PAGINATION_FORWARD_BUTTON> {
  disableInteralTransferPaginationForwardButton: boolean
}

export function SetInternalTransferDisablePaginationForwardButton(
  disableInteralTransferPaginationForwardButton: boolean,
): SetInternalTransferDisablePaginationForwardButtonAction {
  return {
    type: AccountActionType.SET_INTERNAL_TRANSFER_DISABLE_PAGINATION_FORWARD_BUTTON,
    disableInteralTransferPaginationForwardButton,
  }
}

