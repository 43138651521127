import { CircularProgress, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement } from 'react'

interface StatsNum {
  isLoaded: boolean
  label: any
  loadname: string[]
  title: string
  subtitle?: string
}

const StatsNumber: React.FunctionComponent<StatsNum> = (
  props: StatsNum,
): ReactElement<StatsNum> => {
  const { isLoaded = true, label, loadname = [], title, subtitle } = props
  const classes = useStyles()
  const [loading] = useTaskSubscriber(...loadname)
  const progressStyles = {
    [classes.block]: loading || !isLoaded,
    [classes.none]: !loading && isLoaded,
  }

  const contentShow = {
    [classes.flex]: !loading && isLoaded,
    [classes.none]: !(!loading && isLoaded),
  }

  return (
    <div className={classes.flex}>
      <div className={classes.label}>{label}</div>
      <CircularProgress
        size="0.82rem"
        className={clsx(classes.spinner, classes.transition, progressStyles)}
      />
      <div className={clsx(classes.priceValue, classes.transition, contentShow)}>
        <div>
          {title}
        </div>
        {subtitle && (
          <div className={classes.swthDenom}>
            {subtitle}
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: theme.palette.grey[500],
    fontSize: '0.75rem',
    textAlign: 'center',
  },
  priceValue: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: "0.8125rem",
  },
  spinner: {
    color: theme.palette.grey[400],
  },
  swthDenom: {
    marginLeft: '0.3125rem',
    display: 'inline',
  },
  transition: {
    transition: 'display 0.3s',
  },
  block: {
    display: 'block',
  },
  flex: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  none: {
    display: 'none',
  },
}))

export default StatsNumber
