import { Box, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import { TradeEvent } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/broker/export'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/export'
import { CellLink, DataLoadSegment, ListTable, PaginationByPage, Section, TableEmptyState } from 'js/components'
import { TaskNames, tradesPaginationLimit } from 'js/constants'
import { shorten } from 'js/helpers/utils'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { TradesReponse } from 'js/reducers/account'
import { BIG_ZERO, SimpleMap, adjustHuman, bnOrZero } from 'js/utils'
import moment from 'moment'
import React, { ReactElement, ReactNode } from 'react'

export interface TradesProps {
  filterStatus: boolean
  trades: TradesReponse
  title: string
  action: any
  markets: SimpleMap<Market>
  loadName: string[]
}

interface Props extends TradesProps { }

const Trades: React.FunctionComponent<Props> = ({
  filterStatus = false,
  trades,
  title,
  action,
  markets,
  loadName,
}: Props): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.App.Markets, ...loadName)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [page, setPage] = React.useState(1)
  return (
    <Section title={title}>
      <DataLoadSegment
        filter={filterStatus}
        loading={loading}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Block</TableCell>
              <TableCell>Market</TableCell>
              <TableCell>Side</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell>Order ID (Maker)</TableCell>
              <TableCell>Maker Acount</TableCell>
              <TableCell>Order ID (Taker)</TableCell>
              <TableCell>Taker Account</TableCell>
              <TableCell align="right">Matched At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{trades.data.map((trade, index) => renderTrade(trade, index, sdk))}</TableBody>
        </ListTable>

        {trades.data.length > 0 && (
          <Box marginTop={3}>
            <PaginationByPage
              response={trades}
              action={action}
              page={page}
              setPage={setPage}
              paginationLimit={tradesPaginationLimit}
            />
          </Box>
        )}
        {trades.data.length === 0 && (
          <TableEmptyState itemName="trades" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTrade(trade: TradeEvent, index: number, sdk?: CarbonSDK): ReactNode {
    const priceBN = adjustHuman(trade.price)?.shiftedBy(markets?.[trade.marketId]?.basePrecision.sub(markets?.[trade.marketId].quotePrecision).toNumber())
    const quantityBN = sdk?.token.toHuman(markets?.[trade.marketId]?.base, bnOrZero(trade.quantity)) ?? BIG_ZERO
    const lotDp = bnOrZero(markets?.[trade.marketId]?.lotSize).dp() ?? 0
    const tickDp = bnOrZero(markets?.[trade.marketId]?.tickSize).dp() ?? 0
    return (
      <TableRow
        key={`${trade.makerAddress}${index}trade`}
        hover
      >
        <TableCell>
          <div>
            <div>
              <CellLink to={`/block/${trade.blockHeight?.toNumber()}`}>
                {trade.blockHeight?.toNumber()}
              </CellLink>
            </div>
            <div>
              <Typography style={{ color: 'rgb(140 142 143)', fontSize: '0.78em' }}>
                {moment(trade.blockCreatedAt).format('YYYY-MM-DD HH:mm:ss')}
              </Typography>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <CellLink to={`/market/${encodeURIComponent(trade.marketId)}`}>{trade.marketId}</CellLink>
        </TableCell>
        <TableCell>{trade.takerSide}</TableCell>
        <TableCell align="right">{priceBN.toFormat(tickDp)}</TableCell>
        <TableCell align="right">{quantityBN.toFormat(lotDp)}</TableCell>
        <TableCell align="right">{priceBN.multipliedBy(quantityBN).toFormat(tickDp)}</TableCell>
        <TableCell>
          <CellLink to={`/order/${trade.makerId}`}>{shorten(trade.makerId)}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${trade.makerAddress}`}>
            {shorten(trade.makerAddress)}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/order/${trade.takerId}`}>{shorten(trade.takerId)}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${trade.takerAddress}`}>
            {shorten(trade.takerAddress)}
          </CellLink>
        </TableCell>
        <TableCell align="right">
          <CellLink to={`/block/${trade.blockHeight.toString()}`}>{trade.blockHeight.toString()}</CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default Trades
