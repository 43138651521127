import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { TextProposalFormState } from "js/models"
import store from "js/store"
import { escapeHtmlGo } from "js/utils"
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'

export function textProposalValue(proposer: string | undefined, initialDeposit: DecCoin[]): MsgSubmitProposal {
  const formState = store.getState().governance.submitProposalFormState as TextProposalFormState

  const { title, summary } = formState

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(summary),
    expedited: false,
    initialDeposit,
    proposer: proposer ?? "",
    messages: [],
  }

  return value
}
