import { Box, CircularProgress, IconButton, makeStyles, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { ReactComponent as HorizontalArrowIcon } from 'assets/HorizontalArrowSmall.svg'
import { CarbonSDK } from 'carbon-js-sdk'
import { CrossChainTransferDetailed } from 'carbon-js-sdk/lib/hydrogen'
import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { updateFilters } from 'js/actions/account'
import { CellLink, DataLoadSegment, ListTable, Section, TableEmptyState } from 'js/components'
import BlockchainIcon from 'js/components/BlockchainIcon'
import PaginationByData from 'js/components/PaginationByData'
import { BlockChainPaths, TaskNames } from 'js/constants'
import { shortenHash } from 'js/helpers/utils'
import { useTaskSubscriber } from 'js/hooks'
import { RootState } from 'js/store'
import { bnOrZero, SimpleMap } from 'js/utils'
import { startCase } from 'lodash'
import React, { ReactElement, ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CrosschainTransferFilter, { FilterOption } from './CrossChainTransferFilter'
import { getBlockTime, getCrossChainMappedDetails, getV1ChainMapping, processCrossChainTransferDetails } from './helper'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'

export interface CCTProps {
  sectionClass?: string
}

interface Props extends CCTProps { }

const CrossChainTransfers: React.FunctionComponent<Props> = ({
  sectionClass,
}: Props): ReactElement<Props> => {
  const sdk = useSelector((state: RootState) => state.core.carbonSDK)
  const tokensMap = useSelector((state: RootState) => state.app.tokensMap)
  const crossChainTransfers = useSelector((state: RootState) => state.account.crossChainTransfers)
  const [filters, setFilters] = useState<{ [index: string]: boolean }>()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Account.CrossChainTransfers, TaskNames.Account.CrosschainTransferFilter)
  // For pagination
  const [page, setPage] = useState(1)
  const itemsPerPage = 10
  const crossChainTransfersToDisplay = crossChainTransfers.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)
  const types = ["Deposit", "Withdrawal"]
  const mapTypeToOption = (type: string): FilterOption => {
    return {
      value: type,
    }
  }
  const onFiltersChange = (types: string[]) => {
    const newFilters: { [index: string]: boolean } = {}
    types.forEach((type) => type !== undefined && (newFilters[type] = true))
    setFilters(newFilters)
    dispatch(updateFilters({ filters: newFilters }))
  }
  return (
    <Section
      className={sectionClass}
      title="Crosschain Transfers"
      rightMenu={
        <CrosschainTransferFilter
          className={classes.filter}
          onFilter={onFiltersChange}
          filters={filters}
          options={types.map(mapTypeToOption)}
        />
      }
    >
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Assets</TableCell>
              <TableCell align='right' className={classes.amountCellHeader}>Amount</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.root}>{crossChainTransfersToDisplay.map((transfers, index) => renderTransfer(transfers, index, tokensMap))}</TableBody>
        </ListTable>
        {crossChainTransfers.length > 0 && (
          <Box marginTop={3}>
            <PaginationByData
              data={crossChainTransfers}
              setPage={setPage}
              page={page}
              itemsPerPage={itemsPerPage}
            />
          </Box>
        )}
        {(!crossChainTransfers || crossChainTransfers.length <= 0) && (
          <TableEmptyState itemName="crosschain transfers" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderTransfer(t: CrossChainTransferDetailed, index: number, tokensMap: SimpleMap<Token>): ReactNode {
    const blocktime = getBlockTime(t)
    const time: Dayjs = dayjs(blocktime)

    const processedTransferDetails = processCrossChainTransferDetails(t, tokensMap)
    const { matchToken } = processedTransferDetails
    const decimals = matchToken?.decimals.toNumber() ?? 0
    const amountFormatted = sdk?.token.toHuman(matchToken?.denom ?? "", bnOrZero(t.amount)).toFixed(decimals)


    const paths = sdk?.network === CarbonSDK.Network.MainNet ? BlockChainPaths.mainnet : BlockChainPaths.devnet
    const crossChainMappedDetails = getCrossChainMappedDetails(t, paths, matchToken)
    const {
      sourceTxHashDisplay,
      destinationTxHashDisplay,
      bridgingTxUrl,
      sourceTxUrl,
      sourceBlockHeightUrl,
      sourceAssetUrl,
      sourceAddressUrl,
      destinationTxUrl,
      destinationBlockHeightUrl,
      destinationAssetUrl,
      destinationAddressUrl
    } = crossChainMappedDetails

    const { sourceDenom, destinationDenom } = processedTransferDetails
    const { sourceTxBlockHeight, destinationTxBlockHeight } = processedTransferDetails
    const { sourceBlockchain, bridgingBlockchain, destinationBlockchain, sourceFromCarbon } = processedTransferDetails
    const { from_address, to_address } = t

    const goToBridgingTxUrl = () => window.open(bridgingTxUrl, '_blank')

    return (
      <TableRow key={+ index.toString()} hover>
        <TableCell className={classes.paddingRight}>
          {blocktime ? (
            <Box>
              <div className={classes.noWrap}>{time.format('YYYY-MM-DD')}</div>
              <div className={classes.subText}>{time.format('HH:mm:ss')}</div>
            </Box>) : '-'}
        </TableCell>
        <TableCell className={classes.paddingRight}>
          <Box className={classes.hashContent}>
            <Box>
              {sourceTxUrl === ''
                ? <Typography className={clsx(classes.displayBlock, classes.fixedWidth)}>{shortenHash(sourceTxHashDisplay, 6)}</Typography>
                : <CellLink className={clsx(classes.displayBlock, classes.fixedWidth)} href={sourceTxUrl}>{shortenHash(sourceTxHashDisplay, 6)}</CellLink>}
              {sourceBlockHeightUrl === ''
                ? <Typography className={clsx(classes.displayBlock, classes.subText)}>{sourceTxBlockHeight}</Typography>
                : <CellLink className={clsx(classes.displayBlock, classes.subText)} href={sourceBlockHeightUrl}>{sourceTxBlockHeight.toString()}</CellLink>}
            </Box>
            <Box>
              <HorizontalArrowIcon className={classes.verticalAlign} />
            </Box>
            <Box className={clsx(classes.paddingLeft, classes.verticalAlign)}>
              <IconButton className={classes.blockChainImg} onClick={goToBridgingTxUrl}>
                <BlockchainIcon className={clsx(classes.blockChainImg, classes.bridgingBlockchainImg)} blockchain={bridgingBlockchain} />
              </IconButton>
            </Box>
            <Box className={classes.paddingLeft}>
              <HorizontalArrowIcon className={classes.verticalAlign} />
            </Box>
            <Box className={clsx(classes.paddingLeft, "verticalCenter")}>
              {destinationTxUrl === ''
                ? <Typography className={clsx(classes.displayBlock, classes.fixedWidth)}>{shortenHash(destinationTxHashDisplay, 6)}</Typography>
                : <CellLink className={clsx(classes.displayBlock, classes.fixedWidth)} href={destinationTxUrl}>{shortenHash(destinationTxHashDisplay, 6)}</CellLink>}
              {destinationBlockHeightUrl === ''
                ? <Typography className={clsx(classes.displayBlock, classes.subText)}>{destinationTxBlockHeight || ''}
                  {!destinationTxHashDisplay && !destinationTxBlockHeight && t.status.toLowerCase() === "in_transit" && (<CircularProgress size="1rem" />)}
                </Typography>
                : <CellLink className={clsx(classes.displayBlock, classes.subText)} href={destinationBlockHeightUrl}>{destinationTxBlockHeight || ''}
                  {!destinationTxHashDisplay && !destinationTxBlockHeight && t.status.toLowerCase() === "in_transit" && (<CircularProgress size="1rem" />)}
                </CellLink>}
            </Box>
          </Box>
        </TableCell>
        <TableCell className={classes.paddingRight}>
          <Box className={classes.blockchainRow}>
            <BlockchainIcon className={classes.blockChainImg} blockchain={sourceBlockchain} />
            <div className={classes.blockchain}>{getV1ChainMapping(sourceBlockchain)}</div>
          </Box>
          {sourceAddressUrl === ''
            ? <Typography className={clsx(classes.displayBlock, classes.subText)}>{shortenHash(from_address, 7)}</Typography>
            : <CellLink className={clsx(classes.displayBlock, classes.subText)} href={sourceAddressUrl}>{shortenHash(from_address, 7)}</CellLink>}
        </TableCell>
        <TableCell className={classes.paddingRight}>
          <Box className={classes.blockchainRow}>
            <BlockchainIcon className={classes.blockChainImg} blockchain={destinationBlockchain} />
            <div className={classes.blockchain}>{getV1ChainMapping(destinationBlockchain)}</div>
          </Box>
          {destinationAddressUrl === ''
            ? <Typography className={clsx(classes.displayBlock, classes.subText)}>{shortenHash(to_address, 7)}</Typography>
            : <CellLink className={clsx(classes.displayBlock, classes.subText)} href={destinationAddressUrl}>{shortenHash(to_address, 7)}</CellLink>}
        </TableCell>
        <TableCell className={classes.noWrap}>{sourceFromCarbon ? 'Withdrawal' : 'Deposit'}</TableCell>
        <TableCell className={clsx(classes.noWrap, classes.paddingRight)}>
          {sourceAssetUrl === ''
            ? <Typography className={classes.displayBlock}>{sourceDenom}</Typography>
            : <CellLink className={classes.displayBlock} href={sourceAssetUrl}>{sourceDenom}</CellLink>}
          {destinationAssetUrl === ''
            ? <Typography className={clsx(classes.displayBlock, classes.subText)}>{destinationDenom}</Typography>
            : <CellLink className={clsx(classes.displayBlock, classes.subText)} href={destinationAssetUrl}>{destinationDenom}</CellLink>}
        </TableCell>
        <TableCell className={clsx(classes.alignRight, classes.paddingRight)}>{amountFormatted}</TableCell>
        <TableCell>{startCase(t.status)}</TableCell>
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  paddingRight: {
    paddingRight: theme.spacing(1.5)
  },
  hashContent: {
    display: 'inline-flex',
    paddingRight: theme.spacing(1),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
    '&.verticalCenter': {
      placeSelf: 'center'
    }
  },
  verticalAlign: {
    verticalAlign: 'sub',
  },
  fixedWidth: {
    width: '6rem',
  },
  displayBlock: {
    display: 'block',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  subText: {
    fontSize: '0.8rem',
    fontWeight: 'normal',
  },
  blockChainImg: {
    width: '1rem',
    height: '1rem',
  },
  iconButton: {
    width: '1rem',
    height: '1rem',
  },
  bridgingBlockchainImg: {
    width: '1.25rem',
    height: '1.25rem',
  },
  blockchainRow: {
    display: 'flex',
    alignItems: 'center',
    width: '6.1rem',
  },
  blockchain: {
    paddingLeft: theme.spacing(1),
    display: 'inline-block',
    verticalAlign: 'top',
  },
  alignRight: {
    textAlign: 'right',
  },
  amountCellHeader: {
    paddingRight: '0.75rem',
  },
  filter: {
    marginBottom: theme.spacing(2),
  },
}))

export default CrossChainTransfers
