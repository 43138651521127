import { Box, BoxProps, Grid, IconButton, makeStyles, TextField, Theme } from '@material-ui/core'
import AddCircleOutlinedIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { PoolSelectInput } from 'js/components/form'
import { TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { PoolWeightObject, SetPoolRewardWeightsProposalFormState } from 'js/models/Governance'
import { actions } from 'js/store'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SetPoolRewardWeightsProposalInitialFormState } from '../constants'
import { getErrorMessages, getRequiredFieldsConstraints } from '../Helpers/InputConstraints'
import { weightRequiredFields } from './Helpers/FormConstraints'

interface Props extends BoxProps {
}

const SetPoolRewardWeightsProposalMainForm: React.FC<Props> = (props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const formState = (useRedux((state) => state.governance.submitProposalFormState) || SetPoolRewardWeightsProposalInitialFormState) as SetPoolRewardWeightsProposalFormState
  const requiredFieldsConstraints = getRequiredFieldsConstraints(weightRequiredFields)

  useEffect(() => {
    dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.SubmitProposal))
    dispatch(setSubmitProposalFormState(formState))
    validateRequiredFields(formState)
    // eslint-disable-next-line
  }, [])

  const validateRequiredFields = (formState: SetPoolRewardWeightsProposalFormState) => {
    const errors = getWeightsErrors(formState.weights)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const autoGenerateTitle = () => `Set pool reward weights`

  const getWeightsErrors = (weights: PoolWeightObject[]): string[] => {
    let errors: string[] = []
    weights.forEach((weight) => {
      errors = errors.concat(getErrorMessages(weight, requiredFieldsConstraints))
    })
    return errors
  }

  const handleChange = (formNum: number, formKey: keyof PoolWeightObject) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const newWeight = formState.weights
      newWeight[formNum][formKey] = event.target.value as string
      const newFormState = {
        ...formState,
        weights: newWeight,
        title: autoGenerateTitle(),
      }
      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  const form = (weight: PoolWeightObject, index: number): React.ReactNode =>
    <>
      <Grid item xs={12} md={5}>
        <PoolSelectInput value={weight.poolID} onChange={handleChange(index, 'poolID')} />
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField
          fullWidth
          label="Weight"
          type="number"
          InputLabelProps={{ shrink: true }}
          value={weight.weight}
          onChange={handleChange(index, 'weight')}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <IconButton color="secondary" className={classes.iconButton} onClick={() => handleRemove(index)}>
          <DeleteOutlineIcon className={classes.btnIcon} />
        </IconButton>
        {
          index === formState.weights.length - 1 &&
          <IconButton color="secondary" className={classes.iconButton} onClick={handleAdd}>
            <AddCircleOutlinedIcon className={classes.btnIcon} />
          </IconButton>

        }
      </Grid>
    </>

  const handleAdd = () => {
    let newWeight = formState.weights
    newWeight = newWeight.concat({
      poolID: '',
      weight: '',
    })
    const newFormState = {
      ...formState,
      weights: newWeight,
    }
    dispatch(setSubmitProposalFormState(newFormState))
    validateRequiredFields(newFormState)
  }

  const handleRemove = (index: number) => {
    if (formState.weights.length > 1) {
      const newWeight = formState.weights
      newWeight.splice(index, 1)
      const newFormState = {
        ...formState,
        weights: newWeight,
      }
      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        {formState.weights.map((weight: PoolWeightObject, index) => {
          return form(weight, index)
        })}
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2, 0),
    },
  },
  btnIcon: {
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1rem",
  },
  loginLabel: {
    marginRight: theme.spacing(2),
  },
  warningIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  menuItems: {
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  iconButton: {
    marginTop: '.5rem',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
    },
  },
}))

export default SetPoolRewardWeightsProposalMainForm
