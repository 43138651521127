import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import PaginationButtons from './PaginationButtons'
import { paginationLimit } from 'js/constants/pagination'

// tslint:disable:jsx-no-lambda

interface Props {
  response: any
  action: any
}

const PaginationByOffset: React.FunctionComponent<Props> = ({
  response,
  action,
}: Props): ReactElement<Props> => {
  const dispatch = useDispatch()
  const data = response?.models ?? response?.data ?? response?.entries ?? []
  const meta = response?.meta ?? response
  const count = parseInt(meta?.count)
  const offset = meta?.offset === "" ? 0 : parseInt(meta?.offset)
  const length = typeof data === 'undefined' ? 0 : data.length

  const handleButtonClick = (event: any) => {
    if (data.length > 0) {
      const state: any = {}
      switch (event) {
        case 'next':
          state.offset = length + offset
          break
        case 'last':
          state.offset = count >= paginationLimit ? count - paginationLimit : 0
          break
        case 'previous':
          state.offset = offset - length
          break
        case 'first':
          state.offset = 0
          break
        default:
          break
      }
      dispatch(
        action(state),
      )
    }
  }

  let disableBack: boolean = true
  if (
    data.length > 0 &&
    offset !== 0
  ) {
    disableBack = false
  }

  let disableFoward: boolean = true
  if (
    data.length > 0 &&
    (length + offset) < count
  ) {
    disableFoward = false
  }

  return (
    <PaginationButtons
      handleButtonClick={handleButtonClick}
      disableBack={disableBack}
      disableForward={disableFoward}
    />
  )
}

export default PaginationByOffset
