export const switcheo = {
  default: {
    primary: '#77DDFB',
    accent: '#0F214F',
    grey: '#ABB7BE',
    white: '#FFFFFF',
  },
  primary: {
    800: '#256191',
    700: '#3B87B4',
    600: '#56B1D7',
    500: '#77DDFB',
    400: '#98EDFC',
    300: '#ADF7FD',
    200: '#C9FEFE',
    100: '#E4FEFD',
  },
  background: {
    blue: '#C9FEFE',
    grey: '#E5EFF2',
    white: '#F5FAFA',
  },
  text: {
    primary: '#0F214F',
    button: '#46B1DD',
    grey: '#83979C',
    white: '#EBF7F7',
  },
  success: {
    primary: '#38C156',
    light: '#E3FFEC',
  },
  info: {
    primary: '#77DDFB',
    light: '#D6F8FE',
  },
  warning: {
    primary: '#D78100',
    light: '#FFF4CE',
  },
  danger: {
    primary: '#E84B55',
    light: '#FFE7ED',
  },
  border: '#9DADB2',

  hover: 'rgba(0, 0, 0, 0.08)',

  // OLD COLOURS
  mono: {
    400: '#666666',
    300: '#DEDEDE',
    200: '#E8E8E8',
    100: '#F7F7F7',
    50: '#F8F8F8',
    // accent colours here
    A50: '#F8FAFD', // background for sections in modals (eg swth deposits)
    //chart colors
    C50: '#E0E8EC',
    C500: '#E5EFF2',
    A100: '#EEF0F5', // disabled button colour
    A200: '#E7EDF9', // used in dropdown hovers
    A300: '#EFF3FB', // used in 'you will receive' sections in modals
    A400: '#F2F5FA', // text button hover colour
    A500: '#E3E8F7', // outline
    A600: '#DFE5F0', // scrollbar
    A700: '#D7E0F6', // unused
  },
  green: {
    400: '#338F2B',
    300: '#57B022',
    200: '#65aa88',
    100: '#84B35B',
  },
  accent: '#29CCC4',

  black: '#242626',
  red: '#C94444',
  yellow: '#E6B300',
  beige: '#FAEDD9',

  twitterHover: '#1784c8',
  counterTradeHover: '#a62523',
  copyTradeHover: '#24731d',

  logMsg: '#FAFAFA',
  twitter: '#1B95E0',
  copyTrade: '#338F2B',
  counterTrade: '#DD3C39',
  paginationButtonBorder: 'rgba(6, 60, 79, 0.5)',

  loss: '#DD3C3A',

  chart: [
  '#0F214F', 
  '#77DDFB', 
  '#FF9F85', 
  '#3AEAAB', 
  '#FEC646', 
  '#D78CF1', 
  '#2F80ED', 
  '#E84B55',
  '#38C156',
  '#F2994A',
  '#821DEB',
  '#990B58',
  '#0A6B65',
  '#814D32',
  '#4421A9',
  ],
}
