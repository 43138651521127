import { fieldTypes } from "../../GenericProposal/Helpers/types";
import { getErrorMessages, getInputConstraints } from "../../Helpers/InputConstraints";

export const communityPoolSpendRequiredFields = ['denom', 'recipient', 'amount'];

export const communityPoolSpendConfig = {
    recipient: {
        id: 'recipient',
        label: 'Recipient',
        type: fieldTypes.text,
        tooltip: 'Account to receive funds from community pool',
        constraints: {
          validAddress: true,
        },
      },
    amount: {
        id: 'amount',
        label: 'Amount',
        type: fieldTypes.number,
        tooltip: 'Amount to receive (in human form)',
        constraints: {
          greaterThan: 0,
        },
      },
  }

export function communityPoolSpendValidator(formState: any) {
  return getErrorMessages(formState, getInputConstraints(communityPoolSpendConfig))
}