import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import StatefulButton from 'js/components/StatefulButton'
import React from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  submitLabel: string
  submitLoading?: boolean
  miniTitle: string
  bigTitle: string
  intro: string
  content: React.ReactElement
}

const CustomDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const {
    isOpen,
    onClose,
    onSubmit,
    submitLabel,
    submitLoading,
    miniTitle,
    bigTitle,
    intro,
    content,
  } = props

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#FFFF',
          padding: '1rem',
        },
      }}
      maxWidth='sm'
      fullWidth
    >
      <DialogContent>
        <div className={classes.miniTitle}>{miniTitle}</div>
        <div className={classes.bigTitle}>{bigTitle}</div>
        <div className={classes.intro}>{intro}</div>
        {content}
        <DialogActions className={classes.actions} classes={{ spacing: classes.actionRes }}>
          <StatefulButton
            variant="contained"
            color="secondary"
            loading={submitLoading}
            onClick={onSubmit}
            className={clsx(classes.button, classes.submit)}
          >
            {submitLabel}
          </StatefulButton>
          <Button color="secondary" onClick={onClose} className={clsx(classes.button, classes.cancel)}>
            <Typography color="textSecondary" className={classes.cancelText}>
              Cancel
            </Typography>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  miniTitle: {
    fontSize: '.875rem',
    letterSpacing: '-.0125rem',
    lineHeight: '1.125rem',
    paddingBottom: theme.spacing(1),
    fontWeight: 700,
  },
  bigTitle: {
    fontSize: '1.375rem',
    fontWeight: 'bold',
    letterSpacing: '-.0375rem',
    lineHeight: '1.75rem',
    textTransform: 'capitalize',
  },
  intro: {
    marginTop: '1rem',
    fontSize: '.875rem',
    letterSpacing: '-.0125rem',
    lineHeight: '1.126rem',
  },
  inputContainer: {
    marginTop: '2.5rem',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  actionRes: {
    [theme.breakpoints.only('xs')]: {
      '&> :not(:first-child)': {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
      },
    },
  },
  cancelText: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '1.125rem',
  },
  button: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginRight: theme.spacing(0),
    },
  },
  submit: {
    minWidth: '9rem',
  },
  cancel: {
    minWidth: 'unset',
    padding: '.7rem 1.2rem',
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1),
      justifyContent: 'center',
    },
    backgroundColor: theme.palette.text.primary,
  },
}))

export default CustomDialog
