import { SagaIterator, Task } from 'redux-saga'
import { all, cancel, Effect } from 'redux-saga/effects'

export default abstract class Saga {
  protected tasks?: Task[]

  public *start(): SagaIterator {
    this.tasks = yield all(this.getStartEffects())
    this.tasks = this.tasks!.filter((task: Task): boolean => !!task)
  }

  public *stop(): SagaIterator {
    if (this.tasks) {
      yield cancel(this.tasks)
    }
  }

  public *restart(): SagaIterator {
    yield* this.stop()
    yield* this.start()
  }
  protected abstract getStartEffects(): Effect[]
}
