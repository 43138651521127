import { Box, makeStyles, Theme } from '@material-ui/core'
import { Timeline, TimelineContent, TimelineItem, TimelineSeparator } from '@material-ui/lab'
import { ProposalStatus } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1beta1/gov'
import dayjs from 'dayjs'
import Section from 'js/components/Section'
import React from 'react'
import { ProcessDot } from '../components'
import { useRedux } from 'js/hooks'

const ProposalHistory: React.FC<{}> = () => {
  const classes = useStyles()

  const proposal = useRedux((state) => state.proposal.details)!

  const proposalStatus = proposal.status
  const submitTime = dayjs(proposal.submitTime!).format('YYYY-MM-DD HH:mm:ss')
  const depositEndTime = dayjs(proposal.depositEndTime!).format('YYYY-MM-DD HH:mm:ss')
  const votingEndTime = dayjs(proposal.votingEndTime!).format('YYYY-MM-DD HH:mm:ss')

  const isDepositPeriod =
    proposalStatus === ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD
  const isVotingPeriod =
    proposalStatus === ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD
  const isPassed = proposalStatus === ProposalStatus.PROPOSAL_STATUS_PASSED
  const isRejected = proposalStatus === ProposalStatus.PROPOSAL_STATUS_REJECTED

  const getDotStatus = () => {
    if (isPassed) {
      return 'success'
    } else if (isRejected) {
      return 'rejected'
    }
    return ''
  }

  return (
    <Section title="Proposal History">
      <Timeline align="left" className={classes.timeline}>
        <TimelineItem className={classes.timelineItem}>
          <TimelineSeparator>
            <ProcessDot status="" />
            <div className={classes.timelineConnector} />
          </TimelineSeparator>
          <TimelineContent>
            <Box className={classes.timelineContent}>
              <Box>Proposal Submitted</Box>
              <Box>{submitTime}</Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem className={classes.timelineItem}>
          <TimelineSeparator>
            <ProcessDot status={isDepositPeriod ? "completed" : ""} />
            {isDepositPeriod || <div className={classes.timelineConnector} />}
          </TimelineSeparator>
          <TimelineContent>
            <Box className={classes.timelineContent}>
              <Box>
                {proposalStatus === ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD
                  ? 'Deposit in progress until'
                  : 'End of deposit'}
              </Box>
              <Box>{depositEndTime}</Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
        {(!isDepositPeriod || isVotingPeriod) && (
          <>
            <TimelineItem className={classes.timelineItem}>
              <TimelineSeparator>
                <span>
                  <ProcessDot status={isVotingPeriod ? "completed" : ""} />
                  {isVotingPeriod || (
                    <div className={classes.timelineConnector} />
                  )}
                </span>
              </TimelineSeparator>
              <TimelineContent>
                <Box className={classes.timelineContent}>
                  <Box>
                    {proposalStatus === ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD
                      ? 'Voting in progress until'
                      : 'End of voting'}
                  </Box>
                  <Box>{votingEndTime}</Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </>
        )}
        {(isPassed || isRejected) && (
          <>
            <TimelineItem className={classes.timelineItem}>
              <TimelineSeparator>
                <ProcessDot status={getDotStatus()} />
              </TimelineSeparator>
              <TimelineContent>
                <Box className={classes.timelineContent}>
                  <Box>{isPassed ? "Passed" : "Rejected"}</Box>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </>
        )}
      </Timeline>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  timeline: {
    padding: 'unset',
  },
  timelineItem: {
    '&:before': {
      content: 'unset',
    },
    minHeight: '3.3rem',
  },
  timelineContent: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0.75rem',
  },
  timelineConnector: {
    borderLeft: `1px dashed ${theme.palette.primary.main}`,
    flexGrow: 1,
  },
  timelineDotTransparent: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    boxShadow: 'unset',
    border: 0,
  },
  nonCurrentProcessDot: {
    fontSize: '0.8rem',
  },
  CurrentProcessDot: {
    fontSize: '0.6rem',
  },
}))

export default ProposalHistory
