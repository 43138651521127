import { makeStyles, TableCell } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import { useRedux } from 'js/hooks'
import React, { ReactElement, useMemo } from 'react'
import TooltipHint from './TooltipHint'

interface Props {
  denom?: string
  amount?: string
  tooltipTitle?: string | React.ReactElement
  align?: any
}

const AmountDenomCell: React.FunctionComponent<Props> = ({
  denom,
  amount,
  tooltipTitle,
  align
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const sdk = useRedux(state => state.core.carbonSDK)

  const tokenName = useMemo(() => sdk?.token.getTokenName(denom ?? ''), [sdk, denom])
  return (
    <TableCell align={align ?? "right"}>
      <div className={classes.fees}>
        {tooltipTitle ? <TooltipHint className={classes.tooltip} title={tooltipTitle} /> : <></>}
        {amount} <div className={classes.denom}>{tokenName}</div>
      </div>
    </TableCell>
  )
}

const useStyles = makeStyles({
  fees: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  denom: {
    color: grey[500],
    marginLeft: 6,
    minWidth: 28,
    textAlign: 'left',
  },
  tooltip: {
    marginRight: 5,
  }
})

export default AmountDenomCell
