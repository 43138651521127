import { Box, BoxProps, makeStyles } from '@material-ui/core'
import cls from 'classnames'
import Long from 'long'
import React from 'react'
import ReactJson from 'react-json-view'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflowX: 'auto',
  },
  header: {
    flexBasis: '80px',
    minWidth: '80px',
  },
}))

interface Props extends BoxProps {
  header?: string
  content?: any
}

const JSONBlock: React.FC<Props> = (props: Props) => {
  const { children, className, header, content, ...rest } = props
  const classes = useStyles()
  let contentParsed = undefined
  try {
    contentParsed = JSON.parse(JSON.stringify(content), (_, value) => {
      // search for Long object and convert to string
      if (value == null) {
        return []
      }
      if (typeof (value) === 'object' &&
        value.low !== undefined &&
        value.high !== undefined &&
        value.unsigned !== undefined) {
        return Long.fromValue(value).toString()
      }
      return value
    })
  } catch (err) {
    console.error(err)
  }

  return (
    <Box {...rest} className={cls(classes.root, className)}>
      {!!header?.length && (
        <div className={classes.header}>{header}</div>
      )}
      <ReactJson
        src={contentParsed}
        displayDataTypes={false}
        enableClipboard={false}
        displayObjectSize={false}
        name={false}
      />
    </Box>
  )
}

export default JSONBlock
