import dayjs from 'dayjs'
import store from 'js/store'
import { isValidAddress } from 'js/utils/string'
import validate from 'validate.js'
import { fieldTypes } from '../GenericProposal/Helpers/types'

const TEXT_PATTERN = /^[a-zA-Z0-9_.]+$/

validate.validators.inFuture = function (value: string, options: boolean, key: any, attributes: any) {
  if (options) {
    const givenDatetime = dayjs(value)
    const now = dayjs()
    if (givenDatetime.isBefore(now)) {
      return 'must be in the future'
    }
  }
  return undefined
}

validate.validators.validAddress = function (value: string, options: boolean, key: any, attributes: any) {
  const network = store.getState().app.network
  if (isValidAddress(value, network) === options) {
    return undefined
  }
  if (options) {
    return 'is not a valid address'
  }
}

validate.validators.validJSON = function (value: string, options: boolean, key: any, attributes: any) {
  if (options) {
    try {
      JSON.parse(value)
    } catch (e) {
      return 'is not a valid JSON string'
    }
  }
  return undefined
}

validate.validators.validPattern = function (value: string, options: boolean, key: any, attributes: any) {
  if (options) {
    if (!TEXT_PATTERN.test(value)) {
      return 'must contain only alphanumeric characters, _ and .'
    }
  }
  return undefined
}

const getNumericalityConstraints = (constraints: any) => {
  return {
    numericality: {
      ...constraints,
    },
  }
}

export function getInputConstraints(config: any): any {
  let inputConstraints = {}
  Object.values(config).forEach((value: any) => {
    if (value.constraints) {
      switch (value.type) {
        case fieldTypes.number:
          inputConstraints = {
            ...inputConstraints,
            [value.id]: getNumericalityConstraints(value.constraints),
          }
          break
        default:
          inputConstraints = {
            ...inputConstraints,
            [value.id]: value.constraints,
          }
          break
      }
    }
  })
  return inputConstraints
}

export function getErrorMessages(formState: any, constraints: any): string[] {
  const errors = validate(formState, constraints)
  let errorMsgs: string[] = []
  if (errors) {
    Object.values(errors).forEach((error) => {
      errorMsgs = errorMsgs.concat(error as string[])
    })
  }
  return errorMsgs
}

export function getRequiredFieldsConstraints(fields: string[]) {
  let constraints = {}
  fields.forEach((field) => {
    constraints = {
      ...constraints,
      [field]: {
        presence: { allowEmpty: false },
      },
    }
  })
  return constraints
}
