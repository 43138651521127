import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import basePalette from './base'
import { switcheo } from './colors'

export default {
  ...basePalette,
  type: 'light',
  text: {
    primary: switcheo.text.primary,
    secondary: switcheo.text.white,
    disabled: switcheo.text.grey,
    hint: switcheo.text.button,
  },
  background: {
    paper: switcheo.background.white,
    default: switcheo.default.white,
  },
  divider: switcheo.default.grey,
} as PaletteOptions
