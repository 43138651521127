import { Divider, Grid, makeStyles, Paper, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { InfoIcon } from 'assets'
import { TooltipHint } from 'js/components'
import React, { ReactElement, useState } from 'react'
import clsx from 'clsx'
import { InfoCards } from '../helper'

const AssetInfoHeroCard: React.FunctionComponent<InfoCards> = (props): ReactElement<InfoCards> => {
  const { title, items, title2, items2 } = props
  const classes = useStyles()
  const smallScreen: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [selected, setSelected] = useState<string>('title')

  const onSelect = (option: string) => {
    setSelected(option)
  }

  const selectedItems = selected === "title" ? items : items2 ?? []
  return (
    <Paper className={classes.paperWrapper}>
      <div className={classes.title}>
        <Typography onClick={() => onSelect("title")} variant={'h3'} component={'span'} className={clsx(classes.titleSelect, { active: selected === "title" }, { isOnlyOne: !!!title2 })}>
          {title}
        </Typography>
        {
          title2 ?
            <Typography onClick={() => onSelect("title2")} variant={'h3'} style={{ marginLeft: "20px" }} className={clsx(classes.titleSelect, { active: selected === "title2" })} component={'span'}>
              {title2}
            </Typography>
            : null
        }
      </div>
      {
        selectedItems.map((o, index) => {
          return <div key={o.field + o.value}>
            <Grid container>
              <Grid item style={{ textAlign: 'left', fontWeight: o.bold ? 600 : 100 }}>
                {o.field}
                {o.tooltip ?
                  <TooltipHint title={o.tooltip}>
                    <InfoIcon className={smallScreen ? classes.infoIconSmallScreen : classes.infoIcon} />
                  </TooltipHint>
                  : null}
              </Grid>
              <Grid item style={{ textAlign: 'right', fontWeight: o.bold ? 600 : 100 }}>
                {o.value}
              </Grid>
            </Grid>
            {items.length - 1 !== index ? <Divider className={classes.divider} /> : <></>}
          </div>
        })
      }
    </Paper>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  paperWrapper: {
    padding: theme.spacing(2),
    boxSizing: 'content-box'
  },
  breadcrumb: {
    margin: theme.spacing(1, 0, 2, 0),
    color: theme.palette.text.primary,
  },
  divider: {
    margin: "0.5em 0em"
  },
  title: {
    marginBottom: "1.5em"
  },
  infoIcon: {
    verticalAlign: 'bottom',
    margin: theme.spacing(0, 1.5)
  },
  infoIconSmallScreen: {
    verticalAlign: 'bottom',
  },
  titleSelect: {
    cursor: 'pointer',
    color: theme.palette.text.disabled,
    "&.active": {
      color: theme.palette.text.primary,
      borderBottom: '1px solid black',
      paddingBottom: '5px'
    },
    "&.isOnlyOne": {
      borderBottom: '0px solid black',
      paddingBottom: '5px'
    }
  }
}))

export default AssetInfoHeroCard
