import { Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { atcb_init } from 'add-to-calendar-button'
import 'add-to-calendar-button/assets/css/atcb.min.css'
import { BlockCountdownHeroSVG } from 'assets'
import clsx from 'clsx'
import { CellLink, Page, Section } from 'js/components'
import { useRedux } from 'js/hooks'
import { bnOrZero, SimpleMap } from 'js/utils'
import moment, { Moment } from 'moment'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

interface Props {
}

const BlockCountDown: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const { height: requestedHeight } = useParams();
  const avgBlockTime = useRedux(state => state.app.avgBlockTime)
  const [latestBlock] = useRedux(state => state.core.blocks.slice(-1))
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [countDown, setCountDown] = useState<SimpleMap<number>>()
  const classes = useStyles()
  const {
    currentDate,
    endBlock,
    currentBlock,
    distance,
  } = useMemo(() => {
    const currentDate = moment();
    const endBlock = bnOrZero(requestedHeight).toNumber();
    const currentBlock = bnOrZero(latestBlock?.height).toNumber();
    const distance = endBlock - currentBlock;
    const newEndDate = moment(currentDate).add(avgBlockTime * distance, "seconds");

    if (newEndDate && avgBlockTime && firstLoad) {
      setFirstLoad(false)
      setEndDate(newEndDate)
    }

    // only update new end date if estimation is off by more than 3 minutes
    // if (endDate && Math.abs(endDate.diff(newEndDate, "minutes")) > 20) {
    //   setEndDate(newEndDate);
    // }

    return {
      currentDate,
      endBlock,
      currentBlock,
      distance,
    }
    // ignore endDate updates for this effect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestBlock, requestedHeight, avgBlockTime]);


  useEffect(atcb_init, [endDate])

  useEffect(() => {
    if (!endDate) return
    const copyEndDate = moment(endDate).format()
    const endDateTemp = moment(copyEndDate)
    const interval = setInterval(() => {
      const currentDateTemp = moment(currentDate)
      endDateTemp.subtract({ second: 1 })
      const duration = moment.duration(endDateTemp.diff(currentDateTemp));

      const days = Math.floor(duration.asDays())
      const hours = Math.floor(duration.asHours() % 24)
      const minutes = Math.floor(duration.asMinutes() % 60)
      const seconds = Math.floor(duration.asSeconds() % 60)
      const countDownMap = {
        days,
        hours,
        minutes,
        seconds
      }
      setCountDown(countDownMap)
    }, 1000)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate])

  return (
    <Page className={classes.pagePadding}>
      <Grid container justifyContent={"center"}>
        <Grid item md={6}>
          <Typography variant={"h2"}>
            Block {'#'}{endBlock} is coming at
          </Typography>
          <Typography variant={"subtitle2"} className={classes.blockDescription}>
            around {endDate?.local().format('LLLL') ?? ""}
          </Typography>
          <Section>
            <Grid container spacing={4}>
              <Grid item xs={6} md={3}>
                <TimeComponent timer={countDown?.days} unit={"Days"} />
              </Grid>
              <Grid item xs={6} md={3}>
                <TimeComponent timer={countDown?.hours} unit={"Hours"} />
              </Grid>
              <Grid item xs={6} md={3}>
                <TimeComponent timer={countDown?.minutes} unit={"Minutes"} />
              </Grid>
              <Grid item xs={6} md={3}>
                <TimeComponent timer={countDown?.seconds} unit={"Seconds"} />
              </Grid>
            </Grid>
          </Section>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.remainingBlock}>
                <Typography component={'span'}>
                  Current Block
                </Typography>
                <Typography component={'span'} className={classes.blockCount}>
                  <CellLink href={`/block/${currentBlock}`}>
                    {'#'}{currentBlock}
                  </CellLink>
                </Typography>
              </div>
              <Divider />
              <div className={classes.remainingBlock}>
                <Typography component={'span'}>
                  Remaining Block
                </Typography>
                <Typography component={'span'} className={classes.blockCount}>
                  {distance}
                </Typography>
              </div>
            </Grid>
          </Grid>
          {!!endDate && (
            <div className={classes.addToCalendarContainer}>
              Be notified on your calendar when the block is mined.
              <div className={clsx('atcb', classes.addToCalendarBtn)}>
                {JSON.stringify({
                  name: `Carbon block #${endBlock}`,
                  startDate: endDate.utc().format("YYYY-MM-DD"),
                  startTime: endDate.utc().format("HH:mm"),
                  endTime: endDate.utc().format("HH:mm"),
                  endDate: endDate.utc().format("YYYY-MM-DD"),
                  options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Yahoo'],
                  trigger: "click",
                  iCalFileName: `Carbon block #${endBlock}`,
                  label: 'Add Event'
                })}
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
          <BlockCountdownHeroSVG className={classes.img} />
        </Grid>
      </Grid>
    </Page >
  )
}

const TimeComponent = (props: any) => {
  const { timer, unit } = props;
  const classes = useStyles()
  return (
    <div>
      <span>
        <Typography variant={"h1"} align={"center"} color={"primary"} className={classes.timer}>
          {timer}
        </Typography>
        <br />
        <Typography variant={"subtitle2"} color={"secondary"} align={"center"}>
          {unit}
        </Typography>
      </span>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  addToCalendarContainer: {
    marginTop: theme.spacing(2)
  },
  addToCalendarBtn: {
    display: 'none',
    '@media (min-width: 965px)': {
      marginLeft: "-1.5em"
    },
  },
  pagePadding: {
    overflow: 'hidden',
    padding: "10em 5em",
    '@media (max-width: 965px)': {
      padding: "4em 0em"
    },
  },
  timer: {
    fontSize: '3.5rem'
  },
  remainingBlock: {
    margin: theme.spacing(1, 0, 1),
  },
  blockDescription: {
    margin: theme.spacing(1, 0, 3),
    fontWeight: 600
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1, 0, 3),
    padding: 0,
  },
  listItem: {
    fontWeight: 'bold',
    padding: theme.spacing(1.25, 0),
    alignItems: 'flex-start',
  },
  bottom: {
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
  },
  blockCount: {
    float: 'right'
  },
  img: {
    '@media (max-width: 965px)': {
      width: "95%"
    },
  }
}))

export default BlockCountDown

