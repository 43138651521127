import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import { call, Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import { clear, setOrder } from '../actions/order'
import { runSagaTask, waitforSDK } from './helper'
import Saga from './Saga'
import { QueryGetOrderResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/query'

export default class Order extends Saga {
  private readonly id: string
  private readonly isMobile: boolean
  constructor(id: string, isMobile: boolean) {
    super()
    this.isMobile = isMobile
    this.id = id
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [[this, this.fetchOrder], [this, this.watchSetNetwork]].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchOrder(): any {
    const id = this.id
    yield runSagaTask(TaskNames.Order.Detail, function* () {
      const sdk = yield* waitforSDK()
      const orderQueryClient = sdk.query.order

      const order = (yield call([orderQueryClient, orderQueryClient.Order], {
        id,
      })) as QueryGetOrderResponse
      yield put(setOrder(order.order))
    });
  }
}
