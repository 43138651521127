import { Box, makeStyles, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import { DataLoadSegment, ListTable, PaginationByData, TableEmptyState, TokenIcon } from "js/components"
import { TaskNames } from "js/constants"
import { useRedux, useTaskSubscriber } from "js/hooks"
import { bnOrZero } from "js/utils"
import React, { useMemo, useState } from "react"

const AllianceAssetsAPYTable: React.FunctionComponent<{}> = (): React.ReactElement<{}> => {
	const classes = useStyles()
	const denomToAPYMap = useRedux(state => state.alliance.denomToAPYMap)
	const allianceAssets = useRedux(state => state.alliance.allianceAssets)
	const allianceAssetDenoms = Object.keys(denomToAPYMap)
	const sdk = useRedux(state => state.core.carbonSDK)
	const [loading] = useTaskSubscriber(TaskNames.Alliance.AllianceAPY)

	const assetAmounts = useMemo(() => {
		const amounts: { [denom: string]: number } = {}
		for (const denom of allianceAssetDenoms) {
		  const totalTokens = allianceAssets.find(asset => asset.denom === denom)?.totalTokens
		  amounts[denom] = bnOrZero(totalTokens).shiftedBy(-(sdk?.token.getDecimals(denom) ?? 0)).toNumber()
		}
		return amounts
	  }, [sdk, allianceAssets, allianceAssetDenoms])

	const [page, setPage] = useState(1)
	const itemsPerPage = 10
	const assetsToDisplay = allianceAssetDenoms.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)
	return (
		<DataLoadSegment loading={loading}>
			<ListTable>
				<TableHead>
					<TableRow>
						<TableCell>Token</TableCell>
						<TableCell>Denom</TableCell>
						<TableCell>Amount Staked</TableCell>
						<TableCell>APY</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{assetsToDisplay.map((denom, index) =>
						<TableRow key={index + denom} hover>
							<TableCell>
								<TokenIcon denom={denom} className={classes.svg} />
								<Typography component="span">
									{sdk?.token.getTokenName(denom)}
								</Typography>
							</TableCell>
							<TableCell>{denom}</TableCell>
							<TableCell>{assetAmounts[denom]}</TableCell>
							<TableCell>{`${denomToAPYMap[denom].toFixed(2)}%`}</TableCell>
						</TableRow>
					)}
				</TableBody>
			</ListTable>
			{allianceAssetDenoms.length < 1 ? (
				<TableEmptyState itemName="assets" />
			) : (
				<Box marginTop={3}>
					<PaginationByData
						data={allianceAssetDenoms}
						setPage={setPage}
						page={page}
						itemsPerPage={itemsPerPage}
					/>
				</Box>
			)}
		</DataLoadSegment>
	)
}

const useStyles = makeStyles({
	svg: {
		fontSize: 'inherit',
		width: '1.3em',
		height: '1.3em',
		verticalAlign: 'sub',
		marginRight: '0.2em'
	},
})

export default AllianceAssetsAPYTable