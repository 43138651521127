import { Box, makeStyles, Typography } from '@material-ui/core'
import { TypographyProps } from '@material-ui/core/Typography' // eslint-disable-line import/no-unresolved
import clsx from 'clsx'
import { extractBoxProps, PartialBoxProps } from 'js/utils/typing/labelComponent'
import React from 'react'

interface Props extends TypographyProps, PartialBoxProps {
  boxClass?: string
  empty?: boolean
}

const TypographyLabel: React.FC<Props> = (props: Props) => {
  const { boxProps, restProps } = extractBoxProps(props)
  const { boxClass, className, children, empty, ...rest } = restProps

  const classes = useStyles(props)

  const content = !empty ? children : (<span>&nbsp;</span>)

  return (
    <Box className={boxClass} {...boxProps}>
      <Typography
        variant="body2"
        color="textPrimary"
        {...rest}
        className={clsx(classes.root, className)}
      >
        {content}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.0125rem',
    fontWeight: 400,
  },
}))

export default TypographyLabel
