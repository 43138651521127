import { Proposal, TallyResult } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/gov";
import { ProposalVoteEntry } from "carbon-js-sdk/lib/insights";
import { Record, RecordOf } from "immutable";
import { ProposalAction, ProposalActionTypes } from "js/actions/proposal";

interface ProposalProps {
  details?: Proposal
  liveVoteTally?: TallyResult
  deposits: ProposalVoteEntry[]
  nonValidatorVotes: ProposalVoteEntry[]
  validatorVotes: ProposalValidatorVoteEntry[],
}

export interface ProposalValidatorVoteEntry extends ProposalVoteEntry {
  numOfDelegations: number
}

const makeState: Record.Factory<ProposalProps> = Record<ProposalProps>({
  details: undefined,
  liveVoteTally: undefined,
  deposits: [],
  nonValidatorVotes: [],
  validatorVotes: [],
})

export type ProposalState = RecordOf<ProposalProps>

function proposalReducer(
  state: ProposalState = makeState(),
  action: ProposalAction,
): ProposalState {
  switch (action.type) {
    case ProposalActionTypes.CLEAR:
      return makeState()
    case ProposalActionTypes.SET_PROPOSAL_DETAILS:
      return state.set('details', action.details)
    case ProposalActionTypes.SET_PROPOSAL_DEPOSITS:
      return state.set('deposits', action.deposits)
    case ProposalActionTypes.SET_PROPOSAL_VALIDATOR_VOTES:
      return state.set('validatorVotes', action.votes)
    case ProposalActionTypes.SET_PROPOSAL_NON_VALIDATOR_VOTES:
      return state.set('nonValidatorVotes', action.votes)
    case ProposalActionTypes.SET_LIVE_VOTE_TALLY:
      return state.set('liveVoteTally', action.tally)
    default:
      return state
  }
}

export default proposalReducer