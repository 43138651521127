import BigNumber from 'bignumber.js'
import { Insights } from 'carbon-js-sdk'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { Record, RecordOf } from 'immutable'
import { TokenAction, TokenActionType } from '../actions/token'

interface TokenWithSupply extends Token {
  totalSupply: BigNumber
}
export interface TokenProps {
  token?: TokenWithSupply
  rich: Insights.BalanceDetails[]
  oraclesPriceChartDataResponse: any
}
const makeState: Record.Factory<TokenProps> = Record<TokenProps>({
  token: undefined,
  rich: [],
  oraclesPriceChartDataResponse: [],
})
export type TokenState = RecordOf<TokenProps>

function tokenReducer(
  state: TokenState = makeState(),
  action: TokenAction,
): TokenState {
  switch (action.type) {
    case TokenActionType.CLEAR:
      return makeState()
    case TokenActionType.SET_TOKEN_INFO:
      return state.set('token', action.token)
    case TokenActionType.SET_RICH_ACCOUNTS_BALANCES:
      return state.updateIn(['rich'], () => action.rich)
    case TokenActionType.SET_ORACLES_PRICE_CHART:
      return state.set('oraclesPriceChartDataResponse', action.oraclesPriceChartDataResponse)
    default:
      return state
  }
}

export default tokenReducer
