import { Box, BoxProps, IconButton, InputAdornment, InputBase } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement, useState } from 'react'
import { SearchIcon } from '../../../../layouts/Header/assets'

interface Props extends BoxProps {
  onSearch: (types: string) => void
}

const PoolSearcher: React.FunctionComponent<Props> = ({
  className, onSearch
}: Props): ReactElement<Props> => {
  const [input, setInput] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const updateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let input = event.target.value
    setInput(input)
    triggerSearch(input)
  }

  const triggerSearch = async (searchInput: string) => {
      searchInput = searchInput.trim().toUpperCase()
      onSearch(searchInput)
  }

  const detectEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerSearch(input)
    }
  }

  if (firstLoad && input !== '') {
    triggerSearch(input);
    setFirstLoad(false)
  }

  const classes = useStyles()
  const handleSearch = () => triggerSearch(input)
  return (
    <Box className={clsx(classes.search, className)}>
      <div className={classes.searchWrapper}>
        <InputBase
          placeholder="Search by Validator Name"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          fullWidth
          id="search-input"
          inputProps={{ 'aria-label': 'search' }}
          onKeyDown={detectEnterKeyPress}
          value={input}
          autoComplete=''
          endAdornment={(
            <InputAdornment position="end">
              <IconButton onClick={handleSearch} className={classes.iconBtn}>
                <SearchIcon className={classes.searchIcon} />
              </IconButton>
            </InputAdornment>
          )}
          onChange={updateInput}
        />
      </div>
    </Box>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  search: {
    position: 'relative',
    minWidth: '20rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '12rem',
      maxWidth: '26rem'
    }
  },
  searchIcon: {
    width: '1.25rem',
    maxHeight: '1.25rem',
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
  },
  inputRoot: {
    color: 'inherit',
    backgroundColor: switcheo.background.grey,
    borderRadius: 6
  },
  inputInput: {
    padding: theme.spacing(1.25, 1.5),
    ...theme.typography.body1,
    color: theme.palette.text.disabled,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
}))

export default PoolSearcher
