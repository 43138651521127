import { TableBody, TableHead, TableRow, Theme, makeStyles, Box } from '@material-ui/core'
import { QueryGetFundingHistoryResponse } from 'carbon-js-sdk/lib/insights'
import { DataLoadSegment, ListTable, Section, TableCellInfo, TableEmptyState, TableSectionCell } from 'js/components'
import PaginationByData from 'js/components/PaginationByData'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, useState } from 'react'
import FundingFilter from './FundingFilter'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'

interface Props {
  cellClass?: string
  headerCells: TableCellInfo[]
  loadName: string
  models: QueryGetFundingHistoryResponse | undefined,
  rowComponent: any
  title?: string
  addInfo?: {
    [index: string]: any
  }
  itemName?: string
  pagination?: boolean
  searchBar?: boolean
  searchKey?: string
  placeholder?: string
  onFilter?: (market: string[]) => void
  perpMarkets?: Market[]
}

const TableSection: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { cellClass, headerCells = [], loadName, models, rowComponent: RowComponent, title, addInfo, itemName, pagination = false, onFilter, perpMarkets } = props
  const [loading] = useTaskSubscriber(loadName)
  const classes = useStyles()

  //Pagination Settings
  const itemsPerPage = 10
  const [page, setPage] = useState<number>(1)
  let itemsToDisplay = models ? [...models?.entries] : []
  let itemsFiltered: any = []

  itemsToDisplay = pagination ? [...itemsToDisplay.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)] : itemsToDisplay
  const filters = perpMarkets?.map((m: Market) => {
    const result = { label: m.displayName, value: m.id, selected: false }
    return result
  })

  return (
    <Section title={title} rightMenu={
      filters ? (
        <Box className={classes.rightMenuWrapper}>
          <FundingFilter
            options={filters}
            onFilter={onFilter}
          />
        </Box>
      ) : undefined
    }>
      <DataLoadSegment
        loading={loading}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              {headerCells.map((cell, index) => {
                const { align = 'right', content } = cell
                return (
                  <TableSectionCell
                    align={align}
                    className={cellClass}
                    content={content}
                    key={index}
                  />
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsToDisplay.map((model: any, index: number) => (
              <RowComponent
                key={`rowComponent${index}`}
                model={model}
                addInfo={addInfo}
              />
            ))
            }
          </TableBody>
        </ListTable>
        {(!models || models?.entries?.length <= 0) && (
          <TableEmptyState itemName={itemName} />
        )}
      </DataLoadSegment>
      {pagination && (
        <Box marginTop={3}>
          <PaginationByData
            data={itemsFiltered}
            setPage={setPage}
            page={page}
            itemsPerPage={itemsPerPage}
          />
        </Box>
      )}
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  rightMenuWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
}))

export default TableSection
