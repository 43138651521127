import { TableRow } from '@material-ui/core'
import { TableCellInfo, TableSectionCell } from 'js/components'
import { useRedux } from 'js/hooks'
import { FullTokenBalance } from 'js/models/Balance'
import { bnOrZero, BN_ZERO } from 'js/utils'
import React, { ReactElement } from 'react'

interface Props {
  addInfo: any
  model: FullTokenBalance
}

const CirculateRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { addInfo, model } = props
  const { totalBonded } = addInfo
  const sdk = useRedux((state) => state.core.carbonSDK)

  const denom = model?.denom
  let availableBN = sdk?.token.toHuman(denom, bnOrZero(model?.available)) ?? BN_ZERO
  const orderBN = sdk?.token.toHuman(denom, bnOrZero(model?.order)) ?? BN_ZERO
  const positionBN = sdk?.token.toHuman(denom, bnOrZero(model?.position)) ?? BN_ZERO
  let totalBN = availableBN.plus(orderBN).plus(positionBN)
  let stakedBN = BN_ZERO
  if (denom === 'swth') {
    stakedBN = sdk?.token.toHuman('swth', totalBonded) ?? stakedBN
    totalBN = totalBN.plus(stakedBN)
  }

  const CirculateCells: TableCellInfo[] = [
    {
      align: 'inherit',
      content: denom,
      to: `/token/${encodeURIComponent(denom)}`,
    },
    {
      content: availableBN.toFormat(2),
    },
    {
      content: orderBN.toFormat(2),
    },
    {
      content: positionBN.toFormat(2),
    },
    {
      content: stakedBN.toFormat(2),
    },
    {
      content: totalBN.toFormat(2),
    }
  ]

  return (
    <TableRow key={denom} hover>
      {CirculateCells.map((cell: TableCellInfo, index: number) => (
        <TableSectionCell {...cell} key={index} />
      ))}
    </TableRow>
  )
}

export default CirculateRow
