import { TxEvent } from '@cosmjs/tendermint-rpc/build/tendermint37'
import BigNumber from 'bignumber.js'
import { ActiveAccounts, TotalUser, UserGrowth } from 'carbon-js-sdk/lib/insights'
import { Record, RecordOf } from 'immutable'
import { BIG_ZERO } from 'js/utils'
import { RestModels } from 'tradehub-api-js'
import { DashboardAction, DashboardActionType } from '../actions/dashboard'

export interface TotalUsersChartDataResponse {
  totalUsersChartData: TotalUser[]
  activeAccountsChartData: ActiveAccounts[]
}
export interface ActiveUsersChartDataResponse {
  userGrowthChartData: UserGrowth[]
  activeAccountsChartData: ActiveAccounts[]
}

export interface DashboardProps {
  transactions: TxEvent[]
  insurance?: BigNumber
  insuranceIsLoaded: boolean
  oi: BigNumber
  oiIsLoaded: boolean
  volume: BigNumber
  volumeIsLoaded: boolean
  avgReward: BigNumber
  activeWallets: BigNumber
  rewardsDistributed: RestModels.TokenAmount[]
  totalUsersChartDataResponse: TotalUsersChartDataResponse
  activeUsersChartDataResponse: ActiveUsersChartDataResponse
  distributionRewardsChartDataResponse: any
}

const makeState: Record.Factory<DashboardProps> = Record<DashboardProps>({
  transactions: [],
  insurance: undefined,
  insuranceIsLoaded: false,
  oiIsLoaded: false,
  volume: BIG_ZERO,
  oi: BIG_ZERO,
  volumeIsLoaded: false,
  avgReward: BIG_ZERO,
  activeWallets: BIG_ZERO,
  rewardsDistributed: [],
  totalUsersChartDataResponse: {
    totalUsersChartData: [],
    activeAccountsChartData: [],
  },
  activeUsersChartDataResponse: {
    userGrowthChartData: [],
    activeAccountsChartData: [],
  },
  distributionRewardsChartDataResponse: []
})
export type DashboardState = RecordOf<DashboardProps>

function dashboardReducer(
  state: DashboardState = makeState(),
  action: DashboardAction,
): DashboardState {
  switch (action.type) {
    case DashboardActionType.CLEAR:
      return makeState()
    case DashboardActionType.SET_INSURANCE_BALANCES:
      return state
        .updateIn(['insurance'], () => action.insurance)
        .updateIn(['insuranceIsLoaded'], () => true)
    case DashboardActionType.SET_TRANSACTIONS:
      return state.updateIn(['transactions'], () => action.transactions)
    case DashboardActionType.SET_OI:
      return state
        .updateIn(['oi'], (): any => {
          return action.oi
        })
        .updateIn(['oiIsLoaded'], () => true)
    case DashboardActionType.SET_VOLUME:
      return state
        .updateIn(['volume'], (): any => {
          return action.volume
        })
        .updateIn(['volumeIsLoaded'], () => true)
    case DashboardActionType.SET_AVG_REWARD:
      return state.set('avgReward', action.avgReward)
    case DashboardActionType.SET_ACTIVE_WALLETS:
      return state.set('activeWallets', action.activeWallets)
    case DashboardActionType.SET_REWARDS_DISTRIBUTED:
      return state.set('rewardsDistributed', action.rewardsDistributed)
    case DashboardActionType.SET_TOTAL_USERS_CHART:
      return state.set('totalUsersChartDataResponse', action.totalUsersChartDataResponse)
    case DashboardActionType.SET_ACTIVE_USERS_CHART:
      return state.set('activeUsersChartDataResponse', action.activeUsersChartDataResponse)
    case DashboardActionType.SET_DISTRIBUTION_REWARDS_CHART:
      return state.set('distributionRewardsChartDataResponse', action.distributionRewardsChartDataResponse)
    default:
      return state
  }
}

export default dashboardReducer
