import { TaskNames } from 'js/constants'
import { paginationLimit } from 'js/constants/pagination'
import { parseNumber } from 'js/utils'
import { SagaIterator } from 'redux-saga'
import { Effect, put, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, LeaderboardHistoryActionType, setLeaderboardHistory } from '../actions/leaderboardHistory'
import { getInitializedSDK, runSagaTask } from './helper'
import Saga from './Saga'

const limit = paginationLimit

export default class Leaderboard extends Saga {
  private readonly isMobile: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchLeaderboard],
      [this, this.watchSetNetwork],
      [this, this.watchLeaderboardHistoryUpdates],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchLeaderboard(): any {
    yield runSagaTask(TaskNames.Leaderboard.D30, function* () {
      const sdk = yield* getInitializedSDK()
      const params = new URLSearchParams(window.location.search)
      const from = parseNumber(params.get('from') ?? undefined)?.toNumber()
      const to = parseNumber(params.get('to') ?? undefined)?.toNumber()

      const leaderboardResult = (yield sdk.api.getLeaderboard({
        from, limit, to
      })) as RestModels.LeaderboardResult
      yield put(setLeaderboardHistory(leaderboardResult))
    })
  }

  private *watchLeaderboardHistoryUpdates(): any {
    yield takeLatest(
      LeaderboardHistoryActionType.UPDATE_LEADERBOARD_HISTORY,
      this.handleUpdateLeaderboardHistory,
    )
  }

  private *handleUpdateLeaderboardHistory(action: any): any {
    yield runSagaTask(TaskNames.LeaderboardHistory.leaderboard, function* () {
      const sdk = yield* getInitializedSDK()
      const params = new URLSearchParams(window.location.search)
      const from = parseNumber(params.get('from') ?? undefined)?.toNumber()
      const to = parseNumber(params.get('to') ?? undefined)?.toNumber()

      const leaderboardResult = (yield sdk.api.getLeaderboard({
        from, limit, to,
        ...action.options
      })) as RestModels.LeaderboardResult
      yield put(setLeaderboardHistory(leaderboardResult))
    })
  }

}
