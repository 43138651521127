import { Box, BoxProps, Grid, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core'
import { GovUtils, CarbonTx } from 'carbon-js-sdk'
import { Proposal, ProposalStatus } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/gov'
import { setIsSpamProposalsFiltered } from 'js/actions/governance'
import { DataLoadSegment, Page, Section, StandardBtn, TableEmptyState } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CheckboxFilter, SectionTable } from './components'
import ProposalFilter, { FilterOption } from './Proposal/components/ProposalFilter'
import { MsgExecLegacyContent } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/tx'
import { wallet } from '@cityofzion/neon-core'

// manually flags out spam proposals
const spamProposal: any = {
  325: true,
  329: true,
  362: true,
  379: true
}

interface Props extends BoxProps { }

// const escapeRegExp = (str: string) => str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
// const mapFriendlyName = (name: string) => escapeRegExp(name)?.replace(/\s+/g, '\\s*')
// const parameterFriendlyNames = Object.values(ProposalTypes.Parameters).map(mapFriendlyName)
// const PARAMS_REGEX = new RegExp(`(${parameterFriendlyNames.join('|')})`, 'i')

const Governance: React.FC<Props> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const proposals = useRedux((state) => state.governance.proposals)
  // const wallet = useRedux((state) => state.core.carbonSDK?.wallet)
  const isSpamProposalsFiltered = useRedux<boolean>((state) => state.governance.isSpamProposalsFiltered)
  const [loading] = useTaskSubscriber(TaskNames.Governance.Proposals);
  const getTypeFromTypeUrl = (set: Set<string>, url: string | undefined, isLegacy: boolean) => {
    // helper function to generate types
    if (typeof url === 'undefined') {
      return;
    }
    let position = url.lastIndexOf(".") + 1;
    let result
    if (isLegacy) {
      result = url.substring(position).slice(0, -8).replace(/([A-Z])/g, ' $1').trim();
    } else {
      result = url.substring(position).replace(/([A-Z])/g, ' $1').trim();
    }
    set.add(result);
  }

  const getAllProposalTypes = () => {
    // generate the types based on submitted proposals
    let types = new Set<string>();
    proposals.forEach((e) => {
      e.messages.forEach((msg) => {
        let typeUrl: string | undefined = msg.typeUrl;
        let { value } = msg;
        let isLegacy = false

        if (typeUrl === CarbonTx.Types.MsgGovExecLegacyContent) {
          isLegacy = true
          const wrapperMsg = MsgExecLegacyContent.decode(value);
          typeUrl = wrapperMsg.content?.typeUrl;
        }
        return getTypeFromTypeUrl(types, typeUrl, isLegacy);
      })
    });
    return [...types];
  }

  let allProposalTypes = getAllProposalTypes();
  const [filters, SetFilters] = useState<string[]>(allProposalTypes);
  const proposalTypesFilter = (filters: string[], proposal: Proposal) => {
    // TODO: Remove after 2 days
    if (proposal.id.toNumber() === 351) {
      return false;
    }
    // there can be proposals with no messages
    if (proposal.messages.length === 0) {
      return true;
    }

    // predicate to filter the proposals
    const msg = proposal.messages[0];
    let typeUrl: string | undefined = msg.typeUrl;
    let { value } = msg;
    let isLegacy = false

    // default select all
    if (filters.length === 0) {
      return true;
    }

    if (typeUrl === CarbonTx.Types.MsgGovExecLegacyContent) {
      isLegacy = true
      const wrapperMsg = MsgExecLegacyContent.decode(value);
      typeUrl = wrapperMsg.content?.typeUrl;
    }

    if (typeof typeUrl === 'undefined') {
      return false;
    }

    // get the typeUrl
    let position = typeUrl.lastIndexOf(".") + 1;
    let result;
    if (isLegacy) {
      result = typeUrl.substring(position).slice(0, -8).replace(/([A-Z])/g, ' $1').trim();
    } else {
      result = typeUrl.substring(position).replace(/([A-Z])/g, ' $1').trim();
    }
    return filters.includes(result);
  }

  const onFilter = (filters: string[]) => {
    // function passed to the selection drop down for filter
    SetFilters(filters);
  }
  const makeFilter = (status: ProposalStatus) => (proposal: Proposal): boolean => proposal.status === status
  let votingProposals = proposals.filter(proposal => proposalTypesFilter(filters, proposal)).filter(makeFilter(ProposalStatus.PROPOSAL_STATUS_VOTING_PERIOD))
  let depositingProposals = proposals.filter(proposal => proposalTypesFilter(filters, proposal)).filter(makeFilter(ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD))
  let completedProposals = proposals.filter(proposal => proposalTypesFilter(filters, proposal)).filter(makeFilter(ProposalStatus.PROPOSAL_STATUS_PASSED));
  let rejectedProposals = proposals.filter(proposal => proposalTypesFilter(filters, proposal)).filter(makeFilter(ProposalStatus.PROPOSAL_STATUS_REJECTED));
  const filterSpam = (proposal: Proposal) => {
    const content = proposal.messages[0]
    if (content && content.typeUrl === CarbonTx.Types.MsgGovExecLegacyContent) {
      // is legacy prop
      return (GovUtils.decodeContent(content).value.title?.split(" ").length > 2 && !spamProposal[proposal.id.toNumber()]) && !GovUtils.decodeContent(content).value.title?.toLowerCase()?.includes('atom airdrop')
    } else {
      return (proposal.title?.split(" ").length > 2 && !spamProposal[proposal.id.toNumber()])
    }
  }

  if (isSpamProposalsFiltered) {
    votingProposals = votingProposals.filter(filterSpam)
    depositingProposals = depositingProposals.filter(filterSpam)
    completedProposals = completedProposals.filter(filterSpam)
    rejectedProposals = rejectedProposals.filter(filterSpam)
  }

  const handleChangeSpamFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsSpamProposalsFiltered(!isSpamProposalsFiltered))
  }

  const mapTypeToOption = (type: string): FilterOption => {
    return {
      value: type,
      label: type
    }
  }

  return (
    <Page title="Governance Proposals">
      <Section variant="primary" className={classes.descriptionContainer}>
        <Box>
          <Typography component="h2" variant="h1">
            Drive growth of the Carbon ecosystem
          </Typography>

          <Box marginTop={3} width={'0px'} minWidth="100%">
            <Typography variant="body1" className={classes.description}>
              Exchange ideas with the community via the forum and Discord on
              topics that will radically decentralize Carbon and
              improve the protocol. Take the useful ideas and submit governance
              proposals, vote on proposals, and support proposals that you
              resonate with by making deposits.
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-start"
          flex={1}
        >

          <Tooltip arrow placement="top" title={wallet ? '' : "Login before submitting a proposal"}>
            <span>
              <StandardBtn
                className={classes.submitButton}
                to={wallet ? Paths.SubmitProposal : Paths.Login}
                variant="contained"
                color="secondary"
              >
                Submit Proposal
              </StandardBtn>
            </span>
          </Tooltip>
        </Box>
      </Section>

      <DataLoadSegment
        loading={loading}
        itemClass={classes.loadingRoot}
      >
        {proposals.length > 0 && (
          <Grid container alignItems="center" justify="space-between" className={classes.space}>
            <ProposalFilter
              onFilter={onFilter}
              options={allProposalTypes.map(mapTypeToOption)}
            />
            <CheckboxFilter
              checked={isSpamProposalsFiltered}
              label="Hide potential spam proposals"
              onChange={handleChangeSpamFilter}
            />
          </Grid>
        )}

        {proposals.length > 0 && (
          <Fragment>
            <SectionTable
              title="Proposals in Voting Period"
              models={votingProposals}
            />
            <SectionTable
              title="Proposals in Deposit Period"
              models={depositingProposals}
            />
            <SectionTable
              title="Completed Proposals"
              models={completedProposals}
            />
            <SectionTable
              title="Rejected Proposals"
              models={rejectedProposals}
            />
          </Fragment>
        )}

        {proposals.length === 0 && (
          <Section className={classes.emptySection}>
            <div className={classes.outerDiv}>
              <TableEmptyState boxClass={classes.box} itemName="proposals" />
              <div className={classes.submitDiv}>
                {/* 
                TODO: uncomment once proposal submission has been fixed
                <StandardBtn
                  className={classes.emptySubmitBtn}
                  color="secondary"
                  to={wallet ? Paths.SubmitProposal : Paths.Login}
                  variant="contained"
                >
                  Submit Proposal
                </StandardBtn> */}
              </div>
            </div>
          </Section>
        )}
      </DataLoadSegment>
    </Page >
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    margin: theme.spacing(0, 0, 4),
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: "0px !important",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  description: {
    fontSize: '1.125rem',
    lineHeight: '1.375rem',
  },
  emptySection: {
    marginTop: theme.spacing(4),
  },
  emptySubmitBtn: {
    fontSize: '1rem',
    minWidth: '11rem',
  },
  outerDiv: {
    margin: theme.spacing(3, 0),
  },
  loadingRoot: {
    paddingTop: theme.spacing(3),
  },
  space: {
    margin: theme.spacing(3, 0),
  },
  submitButton: {
    fontSize: "1rem",
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  submitDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default Governance;
