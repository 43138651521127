import { Page, Section } from "js/components";
import React from "react";
import { AllianceAssetsAPYTable, AlliancesStakeChart } from "./components"
import AlliancesRewardsChart from "./components/AlliancesRewardsChart";

const Alliance: React.FunctionComponent<{}> = (): React.ReactElement<{}> => {

  return (
    <Page title="Alliances">
      <Section title="Alliance Assets">
        <AllianceAssetsAPYTable />
      </Section>
      <AlliancesStakeChart />
      <AlliancesRewardsChart />
    </Page>
  )

}

export default Alliance