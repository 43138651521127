import { Box, makeStyles, TableBody, TableCell, TableHead, TableRow, Theme, Tooltip } from '@material-ui/core'
import StarIcon from '@material-ui/icons/StarOutlined'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { CellLink, DataLoadSegment, ListTable, TableEmptyState, PaginationByData } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { getVoteOption } from '../helper'
import { shortenHash } from 'js/helpers'

const VoteHistory: React.FC<{}> = () => {
  const [votesLoading] = useTaskSubscriber(TaskNames.Proposal.Votes) 
  const sortedVotes = useRedux((state) => state.proposal.nonValidatorVotes)
    .sort((prev, next) => next.height - prev.height)
  const wallet = useRedux((state) => state.core.carbonSDK?.wallet)
  const classes = useStyles()

  // For pagination
  const [page, setPage] = useState(1)
  const itemsPerPage = 10
  const votesToDisplay = sortedVotes.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)
  return (
    <Box>
      <DataLoadSegment loading={votesLoading}>
        {votesToDisplay.length > 0 ? (
          <>
            <ListTable>
              <TableHead>
                <TableRow>
                  <TableCell>Height</TableCell>
                  <TableCell>Option</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>Hash</TableCell>
                  <TableCell>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {votesToDisplay.map((vote, index) => 
                  <TableRow key={vote.hash + index} hover>
                    <TableCell>
                      <CellLink to={`/block/${vote.height}`}>{vote.height}</CellLink>
                    </TableCell>
                    <TableCell>{getVoteOption(vote.input)}</TableCell>
                    <TableCell>
                      <CellLink to={`/account/${vote.address}`}>{vote.address}</CellLink>
                      {
                        // displays an icon if voter is same as current user
                        wallet?.bech32Address === vote.address && (
                          <Tooltip arrow placement="top" title="Your vote">
                            <StarIcon className={classes.meIcon} />
                          </Tooltip>
                        )
                      }
                    </TableCell>
                    <TableCell>
                      <CellLink to={`/transaction/${vote.hash}`}>{shortenHash(vote.hash, 20)}</CellLink>
                    </TableCell>
                    <TableCell align="right">{dayjs(vote.timestamp).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </ListTable>
            <Box marginTop={3}>
              <PaginationByData
                data={sortedVotes}
                setPage={setPage}
                page={page}
                itemsPerPage={itemsPerPage}/>
            </Box>
          </>
        ) : <TableEmptyState itemName="votes"/>}
      </DataLoadSegment>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
  meIcon: {
    width: '0.75rem',
    height: '0.75rem',
    verticalAlign: 'sub',
    marginLeft: theme.spacing(2),
  },
}))

export default VoteHistory
