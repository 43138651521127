import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import rootSaga from 'js/sagas'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import sessionStorageMiddleware, {
  loadSessionStorageState,
} from 'sessionStorage'
import localStorageMiddleware, {
  loadLocalStorageState,
} from '../../localStorage'
import { actions } from './actions'
import createRootReducer, { RootState } from './reducers'

const ignoredActions = [actions.Layout.ActionType.ADD_BACKGROUND_LOADING, actions.Layout.ActionType.REMOVE_BACKGROUND_LOADING];

export const history = createBrowserHistory()

function configureStore(initialState?: RootState) {
  const sagaMiddleware = createSagaMiddleware()
  const persistedState: any = mergeStates(
    loadLocalStorageState(),
    loadSessionStorageState(),
  )
  const preloadedState: any = mergeStates(initialState, persistedState)

  const middlewares = [
    sessionStorageMiddleware,
    localStorageMiddleware,
    routerMiddleware(history),
    sagaMiddleware,
  ]

  if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    && !process.env.REACT_APP_DISABLE_REDUX_LOGGER) {
    middlewares.push(createLogger({
      predicate: (state, action) => !ignoredActions.includes(action.type),
    }))
  }

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(
      applyMiddleware(...middlewares),
    ),
  )
  sagaMiddleware.run(rootSaga)
  return store
}

function mergeStates(targetState: any, sourceState: any): any {
  const mergedState: any = {}
  Object.entries(targetState || {}).forEach(
    ([key, state]: [string, any]): void => {
      mergedState[key] = state.mergeDeep(sourceState[key])
    },
  )
  Object.entries(sourceState).forEach(([key, state]: [string, any]): void => {
    if (!mergedState[key]) {
      mergedState[key] = state
    }
  })
  return mergedState
}

export default configureStore
