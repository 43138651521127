import { Models } from 'carbon-js-sdk'
import { PageRequest } from 'carbon-js-sdk/lib/codec/cosmos/base/query/v1beta1/pagination'
import { TaskNames, validatorDelegationsLimit } from 'js/constants'
import { getSWTHAddressFromValidatorOperatorAddress } from 'js/helpers'
import { BIG_ZERO, bnOrZero, SimpleMap, ValPair } from 'js/utils'
import { SagaIterator } from 'redux-saga'
import { call, delay, Effect, put, select, spawn, takeLatest } from 'redux-saga/effects'
import { RestModels } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { clear, setDelegations, setProfile, setSelfbondAmount, setValidator } from '../actions/validator'
import { getInitializedSDK, runSagaTask, selectState, waitforSDK } from './helper'
import Saga from './Saga'

export default class Validators extends Saga {
  private readonly address: string
  private readonly isMobile: boolean
  constructor(address: string, isMobile: boolean) {
    super()
    this.isMobile = isMobile

    this.address = address
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchProfile]),
      call([this, this.fetchValidator]),
      call([this, this.fetchDelegations]),
      // call([this, this.fetchBlocks]),
      spawn([this, this.watchSetNetwork]),
      // spawn([this, this.watchBlocksFilters]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  // TODO: Migrate account
  private *fetchProfile(): any {
    try {
      const sdk = yield* getInitializedSDK()
      const profile = (yield sdk.api.getProfile({ account: this.address })) as RestModels.Profile
      yield put(setProfile(profile))
    } catch (err) {
      console.error(err)
    }
  }

  private *fetchValidator(): any {
    const validatorAddress = this.address
    yield runSagaTask(TaskNames.Validator.Detail, function* () {
      let valAddrMap = (yield selectState((state) => state.core.valAddrMap)) as SimpleMap<ValPair>
      const sdk = yield* waitforSDK()
      const stakingQueryClient = sdk.query.staking

      while (!Object.values(valAddrMap).length) {
        yield delay(1001)
        valAddrMap = (yield select((state) => state.core.valAddrMap)) as SimpleMap<ValPair>
      }

      const validator = valAddrMap[validatorAddress]?.carbonValidator
      if (validator) {
        const response = (yield call([stakingQueryClient, stakingQueryClient.ValidatorDelegations], {
          validatorAddr: validator.operatorAddress,
          pagination: PageRequest.fromPartial({
            limit: validatorDelegationsLimit
          })
        })) as Models.Staking.QueryValidatorDelegationsResponse
        let selfBond = BIG_ZERO
        const delegations = response.delegationResponses
        const walletAddress = getSWTHAddressFromValidatorOperatorAddress(validator.operatorAddress, sdk?.network)
        for (const delegation of delegations) {
          if (delegation.delegation?.delegatorAddress === walletAddress) {
            selfBond = sdk.token.toHuman('swth', bnOrZero(delegation?.balance?.amount!)) ?? BIG_ZERO
          }
        }
        yield put(setValidator(validator))
        yield put(setSelfbondAmount(selfBond))
      }
    })
  }
  private *fetchDelegations(): any {
    const validatorAddress = this.address
    yield runSagaTask(TaskNames.Validator.Delegations, function* () {
      let valAddrMap = (yield selectState((state) => state.core.valAddrMap)) as SimpleMap<ValPair>
      const sdk = yield* waitforSDK()
      const stakingQueryClient = sdk.query.staking

      while (!Object.values(valAddrMap).length) {
        yield delay(1001)
        valAddrMap = (yield select((state) => state.core.valAddrMap)) as SimpleMap<ValPair>
      }

      const validator = valAddrMap[validatorAddress]?.carbonValidator

      if (validator) {
        const response = (yield call([stakingQueryClient, stakingQueryClient.ValidatorDelegations], {
          validatorAddr: validator.operatorAddress,
          pagination: PageRequest.fromPartial({
            limit: validatorDelegationsLimit
          })
        })) as Models.Staking.QueryValidatorDelegationsResponse

        response.delegationResponses.sort((lhs, rhs) => {
          if (!rhs.delegation?.shares || rhs.delegation?.shares === '0') return -1
          return bnOrZero(rhs.delegation?.shares).comparedTo(lhs.delegation?.shares ?? '0')
        })

        yield put(setDelegations(response.delegationResponses))
      }
    })
  }

  // // TODO: Migrate blocks
  // private *fetchBlocks(): any {
  //   const validatorAddress = this.address
  //   yield runSagaTask(TaskNames.Validator.Blocks, function* () {
  //     const sdk = yield* waitforSDK()
  //     let valAddrMap = (yield selectState((state) => state.core.valAddrMap)) as SimpleMap<ValPair>

  //     while (!Object.values(valAddrMap).length) {
  //       yield delay(1001)
  //       valAddrMap = (yield select((state) => state.core.valAddrMap)) as SimpleMap<ValPair>
  //     }

  //     const validator = valAddrMap[validatorAddress]?.carbonValidator

  //     const blocks = (yield sdk.api.getBlocksPaged({
  //       proposer: operators[validator],
  //       limit: 10,
  //     })) as RestTypes.ResultsMinMax<RestModels.Block>
  //     yield put(setBlocks(blocks))
  //   })
  // }

  // private *watchBlocksFilters(): any {
  //   yield takeLatest(
  //     ValidatorActionType.UPDATE_BLOCKS_FILTERS,
  //     this.handleBlocksFilter,
  //     { address: this.address },
  //   )
  // }
  // private *handleBlocksFilter(options: BlocksOptions, action: any): Generator {
  //   const validator = this.address
  //   yield runSagaTask(TaskNames.Validator.BlocksFilter, function* () {
  //     const sdk = yield* getInitializedSDK()
  //     let operators: any = yield select((state) => state.app.operatorsMap)

  //     while (!Object.values(operators).length) {
  //       yield delay(1001)
  //       operators = yield select((state) => state.app.operatorsMap)
  //     }

  //     const blocks = (yield sdk.api.getBlocksPaged({
  //       ...action.options.pagination,
  //       limit: 10,
  //       proposer: operators[validator],
  //     })) as RestTypes.ResultsMinMax<RestModels.Block>
  //     if(action.options.pagination.order_by === "asc") {
  //       blocks.data.reverse()
  //     }
  //     yield put(setBlocks(blocks))
  //   })
  // }
}
