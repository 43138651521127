import { Box, FormControl, InputLabel, MenuItem, Select, SelectProps } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Token } from "carbon-js-sdk/lib/codec/Switcheo/carbon/coin/export"
import clsx from "clsx"
import { useRedux } from "js/hooks"
import React, { useMemo } from "react"

interface Props extends SelectProps {
  defaultText?: string
}

const tokenBlackList = [
  "nneo1", // error token
]

const TokenSelectInput: React.FC<Props> = (props: Props) => {
  const { children, label, className, onChange, defaultText, ...rest } = props
  const classes = useStyles()
  const tokensMap = useRedux((state) => state.app.tokensMap)
  const allTokens = Object.values(tokensMap)
  const sdk = useRedux((state) => state.core.carbonSDK)

  const _defaultText = defaultText ?? "Select Token"

  const _onChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>, child: React.ReactNode) => {
    onChange?.(event, child)
  }

  const tokens = useMemo(() => {
    return allTokens.filter((token) => !tokenBlackList.includes(token.denom))
  }, [allTokens])

  return (
    <Box className={classes.root}>
      <FormControl fullWidth>
        <InputLabel shrink>
          {label}
        </InputLabel>
        <Select
          displayEmpty
          {...rest}
          className={clsx(classes.select, className)}
          onChange={_onChange}
          MenuProps={{ getContentAnchorEl: null }}
        >
          <MenuItem value="" disabled>{_defaultText}</MenuItem>
          {
            tokens.map((token: Token) =>
              <MenuItem key={`${token.denom}-${token.tokenAddress}`} value={token.denom}>
                [{token.denom?.toUpperCase()}] {token.name} {sdk?.token.getBlockchain(token.denom) ? `(${sdk?.token.getBlockchain(token.denom)})` : ""}
              </MenuItem>,
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  select: {

  },
}))

export default TokenSelectInput
