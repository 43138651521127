import { Box, BoxProps, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import ColorProgress from 'js/components/ColorProgress'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement } from 'react'
import DataRow from './DataRow'

type Props = BoxProps & {
  header?: string
  value?: string
  color?: string
  progress?: number
}

const DataScale: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const {
    color = switcheo.green[400],
    progress = 0,
    header, value,
    className, children, ...rest
  } = props
  const classes = useStyles(props)

  return (
    <Box
      {...rest}
      className={clsx(classes.root, className)}
    >
      <DataRow
        className={classes.dataRow}
        header={header}
        value={value}
      />
      <ColorProgress
        progressColor={color}
        variant="determinate"
        value={progress}
      />
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  dataRow: {
    padding: theme.spacing(1.5, 0, .75),
  },
}))

export default DataScale
