import {
  Dialog, Hidden, IconButton, makeStyles, Button
} from '@material-ui/core'
import {
  Close,
} from '@material-ui/icons'
import clsx from 'clsx'
import React from 'react'

import StatefulButton from './StatefulButton'

interface ModalProps {
  open: boolean
  title?: any
  children: any
  submitText?: any
  className?: string // for the dialog only. add another prop if you want to modify the backdrop
  afterActionDiv?: any // for anything that renders after the action buttons
  handleClose: () => void
  handleSubmit?: () => void
  submitDisabled?: boolean
  showActions?: boolean // whether to show action buttons
  canClose?: boolean // whether the modal can be closed
  titleClassName?: string
  containerClassName?: string
  bodyClassName?: string
  submitLoading?: boolean | undefined
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    open, handleClose, handleSubmit, title, children, className, afterActionDiv = null,
    submitDisabled = false, showActions = true, submitText = null, canClose = true,
    titleClassName, containerClassName, bodyClassName, submitLoading = undefined,
  } = props

  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={() => {
        canClose && handleClose && handleClose()
      }}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: clsx(classes.container, className) }}
      fullWidth
    >
      {
        canClose
        && (
          <IconButton className={classes.closeIconButton} onClick={handleClose}>
            <Close className={classes.closeIcon} />
          </IconButton>
        )
      }
      <div className={containerClassName || classes.innerContainer}>
        <div className={titleClassName || classes.title}>
          {title}
        </div>
        <div className={bodyClassName || classes.body}>
          {children}
        </div>
        {
          showActions
          && (
            <div className={classes.actions}>
              <Hidden only="xs">
                <Button
                  className={clsx(classes.actionButton, classes.cancelButton)}
                  variant="text"
                  onClick={handleClose}
                >
                  Cancel
              </Button>
              </Hidden>
              <StatefulButton
                className={clsx(classes.actionButton, classes.submitButton)}
                onClick={handleSubmit}
                loading={submitLoading || false}
                disabled={submitDisabled}
              >
                {submitText || 'Confirm'}
              </StatefulButton>
              <Hidden smUp>
                <Button
                  className={clsx(classes.actionButton, classes.cancelButton)}
                  variant="text"
                  onClick={handleClose}
                >
                  Cancel
              </Button>
              </Hidden>
            </div>
          )
        }
      </div>
      <div>
        {
          afterActionDiv
        }
      </div>
    </Dialog>
  )
}

export const BlankModal: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    open, handleClose, children, className, canClose = true,
  } = props

  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={() => {
        canClose && handleClose && handleClose()
      }}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: clsx(classes.container, className) }}
      fullWidth
    >
      {
        canClose
        && (
          <IconButton className={classes.closeIconButton} onClick={handleClose}>
            <Close className={classes.closeIcon} />
          </IconButton>
        )
      }
      {children}
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.background.default,
    position: 'relative',
    minHeight: '5rem',
    minWidth: '5rem',
    borderRadius: '2px',
    '@media (max-width: 565px)': {
      margin: 0,
      width: '99%',
    },
  },
  innerContainer: {
    padding: '2.2rem',
  },
  closeIconButton: {
    position: 'absolute',
    right: '0.8rem',
    top: '0.8rem',
  },
  closeIcon: {
    fontSize: '2rem',
    color: theme.palette.text.primary,
  },
  title: {
    ...theme.typography.h1,
    fontSize: '1.125rem',
    letterSpacing: '0.00889em',
    lineHeight: 1.333,
    textTransform: 'capitalize',
  },
  body: {
    margin: '1.5rem 0',
  },
  actions: {
    textAlign: 'right',
  },
  actionButton: {
    padding: '1rem 2rem',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  cancelButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(0),
    },
  },
  submitButton: {
    minWidth: '9.375rem',
  },
  label: {
    color: theme.palette.text.primary,
  },
}))

export default Modal
