import { Proposal, TallyResult } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/gov";
import { ProposalVoteEntry } from "carbon-js-sdk/lib/insights";
import { ProposalValidatorVoteEntry } from "js/reducers/proposal";
import { Action } from "redux";

export enum ProposalActionTypes {
  CLEAR = 'CLEAR_PROPOSAL_INFO',
  SET_PROPOSAL_DETAILS = 'SET_PROPOSAL_DETAILS',
  SET_PROPOSAL_DEPOSITS = 'SET_PROPOSAL_DEPOSITS',
  SET_PROPOSAL_NON_VALIDATOR_VOTES = 'SET_PROPOSAL_NON_VALIDATOR_VOTES',
  SET_PROPOSAL_VALIDATOR_VOTES = 'SET_PROPOSAL_VALIDATOR_VOTES',
  SET_LIVE_VOTE_TALLY = 'SET_LIVE_VOTE_TALLY'
}

export type ProposalAction =
  | ClearAction
  | SetProposalDetailsAction
  | SetProposalNonValidatorVotesAction
  | SetProposalValidatorVotesAction
  | SetProposalDepositsAction
  | SetLiveVoteTallyAction

export interface ClearAction extends Action<ProposalActionTypes.CLEAR> { }

export function clear(): ClearAction {
  return {
    type: ProposalActionTypes.CLEAR,
  }
}

export interface SetProposalDetailsAction extends
  Action<ProposalActionTypes.SET_PROPOSAL_DETAILS> {
  details?: Proposal
}

export function setProposalDetails(details?: Proposal): SetProposalDetailsAction {
  return {
    type: ProposalActionTypes.SET_PROPOSAL_DETAILS,
    details
  }
}

export interface SetProposalNonValidatorVotesAction extends
  Action<ProposalActionTypes.SET_PROPOSAL_NON_VALIDATOR_VOTES> {
  votes: ProposalVoteEntry[]
}

export function setProposalNonValidatorVotes(votes: ProposalVoteEntry[]): SetProposalNonValidatorVotesAction {
  return {
    type: ProposalActionTypes.SET_PROPOSAL_NON_VALIDATOR_VOTES,
    votes
  }
}

export interface SetProposalValidatorVotesAction extends
  Action<ProposalActionTypes.SET_PROPOSAL_VALIDATOR_VOTES> {
  votes: ProposalValidatorVoteEntry[]
}

export function setProposalValidatorVotes(votes: ProposalValidatorVoteEntry[]): SetProposalValidatorVotesAction {
  return {
    type: ProposalActionTypes.SET_PROPOSAL_VALIDATOR_VOTES,
    votes
  }
}

export interface SetProposalDepositsAction extends
  Action<ProposalActionTypes.SET_PROPOSAL_DEPOSITS> {
  deposits: ProposalVoteEntry[]
}

export function setProposalDeposits(deposits: ProposalVoteEntry[]): SetProposalDepositsAction {
  return {
    type: ProposalActionTypes.SET_PROPOSAL_DEPOSITS,
    deposits
  }
}

export interface SetLiveVoteTallyAction
  extends Action<ProposalActionTypes.SET_LIVE_VOTE_TALLY> {
  tally: TallyResult
}

export function setLiveVoteTally(tally: TallyResult): SetLiveVoteTallyAction {
  return {
    type: ProposalActionTypes.SET_LIVE_VOTE_TALLY,
    tally
  }
}
