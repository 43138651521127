import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import { bnOrZero } from 'carbon-js-sdk/lib/util/number'
import { updateFilters } from 'js/actions/transactions'
import { updateDateFiltersMsgCountChart, updateDateFiltersTotalTxCountChart } from 'js/actions/transactions'
import { ChartContainer, Page, TooltipHint, Transactions } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import GasFees from './GasFees'
import MessageCountChart from './MessageCountChart'
import TransactionCountChart from './TransactionCountChart'
import TransactionFilter, { FilterOption } from './TransactionFilter'

interface Props { }

const activeTasks: string[] = [
  TaskNames.Transactions.List,
  TaskNames.Transactions.Types,
  TaskNames.Transactions.Filter,
  TaskNames.Transactions.TotalChart,
  TaskNames.Transactions.MsgChart,
  TaskNames.Transactions.DateFilterTotal,
  TaskNames.Transactions.DateFilterMsg,
]

const TransactionList: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const { transactions, types, gasFees, transactionTotalChartData, transactionMsgCountChartData } = useRedux((state) => state.transactions)
  const [filters, setFilters] = useState<{ [index: string]: boolean }>()
  const [resetPage, setResetPage] = useState(false)
  const dispatch = useDispatch()
  const totalTransactionCount = transactionTotalChartData[transactionTotalChartData.length - 1]?.txTotal ?? 0
  let sum = 0
  transactionMsgCountChartData[transactionMsgCountChartData.length - 1]?.txEvent.forEach((item) => {
    sum += item.total
  })
  const mapTypeToOption = (type: string): FilterOption => {
    return {
      value: type,
    }
  }
  const onFiltersChange = (types: string[]) => {
    const newFilters: { [index: string]: boolean } = {}
    types.forEach((type) => type !== undefined && (newFilters[type] = true))
    setResetPage(true)
    setFilters(newFilters)
    dispatch(updateFilters({ filters: newFilters }))
  }

  return (
    <Page
      title="Transactions"
    >
      <ChartContainer
        taskNames={[TaskNames.Transactions.MsgChart]}
        title={
          <Box className={classes.chartTitleBox}>
            <Box className={classes.chartTitle}>
              <Typography className={classes.chartTitleFirst} variant={"h4"} component={"span"}>
                {`Messages Count`} <TooltipHint title={"Sum of all user messages"} className={classes.tooltip} />
              </Typography>
            </Box>
            <Typography variant={"h3"} component={"span"}>
              {`Total: ${bnOrZero(sum).toFormat()}`}
            </Typography>
          </Box>
        }
        action={updateDateFiltersMsgCountChart}
      >
        <MessageCountChart data={transactionMsgCountChartData} />
      </ChartContainer>
      <ChartContainer
        taskNames={[TaskNames.Transactions.TotalChart]}
        title={
          <Box className={classes.chartTitleBox}>
            <Box className={classes.chartTitle}>
              <Typography className={classes.chartTitleFirst} variant={"h4"} component={"span"}>
                {`Transactions Count`} <TooltipHint title={"Sum of all user transactions"} className={classes.tooltip} />
              </Typography>
            </Box>
            <Typography variant={"h3"} component={"span"}>
              {`Total: ${bnOrZero(totalTransactionCount).toFormat()}`}
            </Typography>
          </Box>
        }
        action={updateDateFiltersTotalTxCountChart}
      >
        <TransactionCountChart data={transactionTotalChartData} />
      </ChartContainer>
      <Transactions
        title={'All Transactions'}
        transactions={transactions}
        filters={filters}
        filterStatus
        action={updateFilters}
        loadName={activeTasks}
        resetPage={resetPage}
        setPageReset={setResetPage}
        rightMenu={
          <TransactionFilter
            className={classes.filter}
            onFilter={onFiltersChange}
            filters={filters}
            options={types.map(mapTypeToOption)}
          />
        }
      />
      <GasFees
        fees={gasFees}
        title="Transaction Gas Fees"
        loadName={[TaskNames.Transactions.Types]}
      />
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    marginBottom: theme.spacing(2),
  },
  chartTitle: {
    display: 'block',
  },
  chartTitleFirst: {
    fontWeight: 'bold',
  },
  tooltip: {
    pointer: 'cursor',
    align: 'center',
  },
  chartTitleBox: {
    paddingBottom: theme.spacing(2),
  }
}))

export default TransactionList
