import { Insights } from "carbon-js-sdk";
import { Distribution } from "carbon-js-sdk/lib/codec";
import { AllianceAsset } from "carbon-js-sdk/lib/codec";
import { QueryAlliancesResponse } from "carbon-js-sdk/lib/codec";
import dayjs from "dayjs";
import { AllianceActionType, FetchAlliancesRewardsChartAction, FetchAlliancesStakeChartAction, clear, setAllianceAPY, setAllianceAssets, setAlliancesRewardsChartData, setAlliancesStakeChartData } from "js/actions/alliance";
import { AppActionType } from "js/actions/app";
import { CARBON_GENESIS_BLOCKTIME, TaskNames } from "js/constants";
import { BN_ZERO, SHIFT_DECIMALS, bnOrZero } from "js/utils";
import moment from "moment";
import { SagaIterator } from "redux-saga";
import { Effect, call, delay, put, spawn, takeLatest } from "redux-saga/effects";
import Saga from "./Saga";
import { runSagaTask, selectState, waitforSDK } from "./helper";

export default class Alliance extends Saga {
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      call([this, this.fetchAlliances]),
      call([this, this.calculateAPYstats]),
      spawn([this, this.watchSetNetwork]),
      spawn([this, this.watchChartUpdates]),
    ]
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *watchChartUpdates(): SagaIterator {
    // TODO: Add takeLatest for chart updates (total staked + rewards claimed)
    yield takeLatest(AllianceActionType.FETCH_ALLIANCES_STAKE_CHART,
      (action: FetchAlliancesStakeChartAction) => this.fetchAlliancesStakeChart(action.options))
    yield takeLatest(AllianceActionType.FETCH_ALLIANCES_REWARDS_CHART,
      (action: FetchAlliancesRewardsChartAction) => this.fetchAlliancesRewardsChart(action.options))
  }

  private *fetchAlliances() {
    yield runSagaTask(TaskNames.Alliance.AllAlliances, function* () {
      const sdk = yield* waitforSDK()
      const allianceQueryClient = sdk.query.alliance
      const { alliances } = (yield call([allianceQueryClient, allianceQueryClient.Alliances],
        {}
      )) as QueryAlliancesResponse
      yield put(setAllianceAssets(alliances))
    })
  }

  private *calculateAPYstats() {
    yield runSagaTask(TaskNames.Alliance.AllianceAPY, function* () {
      const sdk = yield* waitforSDK()
      let allianceAssets = (yield selectState((state) => state.alliance.allianceAssets)) as AllianceAsset[]

      while (allianceAssets.length <= 0) {
        yield delay(1000)
        allianceAssets = (yield selectState((state) => state.alliance.allianceAssets)) as AllianceAsset[]
      }

      const distributionParamsResponse = (yield call([sdk.query.distribution, sdk.query.distribution.Params], {})) as Distribution.QueryParamsResponse

      const stakingRewardsPercentage = distributionParamsResponse.params
        ? Object.values(distributionParamsResponse.params)
          .map((param) => bnOrZero(param).shiftedBy(-SHIFT_DECIMALS))
          .reduce((prev, curr) => prev.minus(curr), bnOrZero(1))
        : 0.4

      const allianceParamsResponse = (yield call([sdk.query.alliance, sdk.query.alliance.Params], {})) as any

      const takeRateInstancesPerYear = allianceParamsResponse?.params?.takeRateClaimInterval
        ? Math.floor(365 * 24 * 60 * 60 / allianceParamsResponse?.params?.takeRateClaimInterval?.seconds.toNumber())
        : 0

      const balanceDistributionResponse = (yield call([sdk.insights, sdk.insights.BalanceDistribution], {
        // extrapolate annual staking rewards from weekly rewards
        from: dayjs().subtract(7, 'days').toISOString(),
        until: dayjs().subtract(1, 'days').toISOString(),
      })) as Insights.InsightsQueryResponse<Insights.QueryGetBalanceDistributionResponse>

      const averageRewardsDistributedPerYear = balanceDistributionResponse.result.entries
        .map(entry => bnOrZero(entry.amountValue))
        .reduce((prev, curr) => prev.plus(curr), BN_ZERO)
        .dividedBy(balanceDistributionResponse.result.entries.length) // per day avg
        .times(365) // annualized
        .times(stakingRewardsPercentage ?? 1) // percentage of rewards given to stakers

      const allianceRewardInfo = allianceAssets.map((asset) => {
        return {
          denom: asset.denom,
          rewardWeight: bnOrZero(asset.rewardWeight).shiftedBy(-SHIFT_DECIMALS),
          takeRate: bnOrZero(asset.takeRate).shiftedBy(-SHIFT_DECIMALS),
          totalTokens: bnOrZero(asset.totalTokens).shiftedBy(-(sdk?.token.getDecimals(asset.denom) ?? 0)),
          price: sdk?.token.getUSDValue(asset.denom) ?? BN_ZERO
        }
      })

      // reward weights including swth
      const rewardWeightsSum = allianceRewardInfo.reduce((prev, curr) => prev.plus(curr.rewardWeight), BN_ZERO).plus(1)

      const APYMap: { [denom: string]: number } = {}

      allianceRewardInfo.forEach((asset) => {
        const { denom, rewardWeight, totalTokens, price, takeRate } = asset
        const totalTokensPrice = totalTokens.times(price)
        const proportionOfRewards = rewardWeight.dividedBy(rewardWeightsSum)
        const rewardsInUSD = proportionOfRewards.times(averageRewardsDistributedPerYear!)
        const portionLeftPerTake = bnOrZero(1).minus(takeRate)
        const proportionAfterTake = Math.exp(Math.log(portionLeftPerTake.toNumber()) * (takeRateInstancesPerYear ?? 0))
        const apy = rewardsInUSD.dividedBy(totalTokensPrice).times(proportionAfterTake)
        APYMap[denom] = apy.times(100).toNumber()
      })

      yield put(setAllianceAPY(APYMap))
    })
  }

  private *fetchAlliancesStakeChart(options: any) {
    yield runSagaTask(TaskNames.Alliance.AlliancesStakeChart, function* () {
      const sdk = yield* waitforSDK()
      const from = options
        ? moment(moment(options.startDate).format('YYYY-MM-DDT00:00:00+00'))
        : moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00'))
      const until = options
        ? moment(moment(options.endDate).format('YYYY-MM-DDT00:00:00+00'))
        : moment(moment(Date()).subtract(1, "days").format('YYYY-MM-DDT00:00:00+00'))
      const alliancesStakeResponse = (yield call([sdk.insights, sdk.insights.AlliancesStake], {
        from: from.unix().toString(),
        until: until.unix().toString(),
      })) as Insights.InsightsQueryResponse<Insights.QueryGetAlliancesStakeResponse>
      yield put(setAlliancesStakeChartData(alliancesStakeResponse.result.entries))
    })
  }

  private *fetchAlliancesRewardsChart(options: any) {
    yield runSagaTask(TaskNames.Alliance.AlliancesRewardsChart, function* () {
      const sdk = yield* waitforSDK()
      const from = options
        ? moment(moment(options.startDate).format('YYYY-MM-DDT00:00:00+00'))
        : moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00'))
      const until = options
        ? moment(moment(options.endDate).format('YYYY-MM-DDT00:00:00+00'))
        : moment(moment(Date()).subtract(1, "days").format('YYYY-MM-DDT00:00:00+00'))
      const alliancesRewardsResponse = (yield call([sdk.insights, sdk.insights.AlliancesRewards], {
        from: from.unix().toString(),
        until: until.unix().toString(),
      })) as Insights.InsightsQueryResponse<Insights.QueryGetAlliancesRewardsResponse>
      yield put(setAlliancesRewardsChartData(alliancesRewardsResponse.result.entries))
    })
  }
}

