import { Box, FormControl, InputLabel, MenuItem, Select, SelectProps } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ExtendedPool } from "carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/query"
import clsx from "clsx"
import { useRedux } from "js/hooks"
import React from "react"

interface Props extends SelectProps {
  defaultText?: string
}

const PoolSelectInput: React.FC<Props> = (props: Props) => {
  const { children, label, className, onChange, defaultText, ...rest } = props
  const classes = useStyles()
  const pools = useRedux((state) => state.pools.pools)
  const tokensMap = useRedux((state) => state.app.tokensMap)
  const _defaultText = defaultText ?? "Select a Pool"

  const _onChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>, child: React.ReactNode) => {
    onChange?.(event, child)
  }

  return (
    <Box className={classes.root}>
      <FormControl fullWidth>
        <InputLabel shrink>
          {label}
        </InputLabel>
        <Select
          displayEmpty
          {...rest}
          className={clsx(classes.select, className)}
          onChange={_onChange}
          MenuProps={{ getContentAnchorEl: null }}
        >
          <MenuItem value="" disabled>{_defaultText}</MenuItem>
          {
            pools.map((pool: ExtendedPool, index) => {
              let poolName = pool.pool?.name
              if (poolName?.includes("IBC/")) {
                const ibcDenom = poolName.match(/IBC\S+/g)
                if (ibcDenom) {
                  const tokenInfo = tokensMap[ibcDenom[0]?.replace("IBC/", "ibc/")]
                  const denom = tokenInfo.denom
                  const truncatedDenom = denom.length > 14 ? `${denom.substring(0, 14)}...` : denom
                  poolName = pool.pool?.name.replace(ibcDenom[0]?.toLocaleUpperCase(), `${tokenInfo.symbol} (${truncatedDenom})`)
                }
              }
              return <MenuItem key={pool.pool?.id.toString() ?? index} value={pool.pool?.id.toString() ?? ''}>
                {poolName} {`[id: ${pool.pool?.id}]`}
              </MenuItem>
            }
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  select: {

  },
}))

export default PoolSelectInput
