import { Box, Button, makeStyles, IconButton } from '@material-ui/core'
import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { PageFirst, PageLast, PageNext, PagePrevious } from 'assets'
// tslint:disable:jsx-no-lambda

interface Props {
  handleButtonClick: any
  disableForward: boolean
  disableBack: boolean
  removeLast?: boolean
}

const PaginationButtons: React.FunctionComponent<Props> = ({
  handleButtonClick,
  disableBack,
  disableForward,
  removeLast,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <IconButton
        classes={{
          root: classes.iconButton,
        }}
        onClick={() => handleButtonClick('first')}
        disabled={disableBack}
      >
        <PageFirst className={clsx({
          [classes.disabledIcon]: disableBack,
        })} />
      </IconButton>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => handleButtonClick('previous')}
        name="previous"
        className={classes.buttons}
        disabled={disableBack}
      >
        <PagePrevious className={clsx(classes.leftArrow, {
          [classes.disabledIcon]: disableBack,
        })} />
        Prev
      </Button>
      <Button
        variant="contained"
        color="secondary"
        className={classes.buttons}
        onClick={() => handleButtonClick('next')}
        name="next"
        disabled={disableForward}
      >
        Next
        <PageNext className={clsx(classes.rightArrow, {
          [classes.disabledIcon]: disableForward,
        })} />
      </Button>
      {removeLast ? "" : <IconButton
        classes={{
          root: classes.iconButton,
        }}
        onClick={() => handleButtonClick('last')}
        disabled={disableForward}
      >
        <PageLast className={clsx({
          [classes.disabledIcon]: disableForward,
        })} />
      </IconButton>}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto auto'
    },
  },
  buttons: {
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(0, 0.5),
    borderRadius: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      minWidth: 0,
      padding: '0.3rem',
      justifyContent: 'center',
      margin: 0
    },
  },
  disabledIcon: {
    '& rect': {
      fill: theme.palette.text.disabled,
    },
    '& path': {
      fill: theme.palette.text.disabled,
    },
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  leftArrow: {
    marginRight: theme.spacing(1),
  },
  rightArrow: {
    marginLeft: theme.spacing(1),
  },
}))

export default PaginationButtons
