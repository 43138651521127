
import { Box, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import { fetchAlliancesRewardsChart } from 'js/actions/alliance'
import { ChartContainer } from 'js/components'
import { TaskNames } from 'js/constants'
import { formatAlliancesRewardsChartData } from 'js/helpers'
import { useRedux } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { bnOrZero, hexToRgba } from 'js/utils'
import React, { useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'

const AlliancesRewardsChart:  React.FunctionComponent<{}> = (): React.ReactElement<{}> => {
  const alliancesRewardsChartData = useRedux(state => state.alliance.alliancesRewardsChartData)
  const [currentIndex, setCurrentIndex] = useState<number>(alliancesRewardsChartData.length - 1 ?? 0)
  const totalRewardsValue = alliancesRewardsChartData[currentIndex]?.totalRewardsValue ?? 0
  const sdk = useRedux(state => state.core.carbonSDK)

  const [chartLabels, chartData] = useMemo(() => {
    const { labels, data } = formatAlliancesRewardsChartData(alliancesRewardsChartData)
    const chartData = data.map((item, index) => {
      const color = hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90)
      return ({
        label: item.denom === "Others" ? item.denom : (sdk?.token.getTokenName(item.denom) ?? item.denom),
        fill: true,
        lineTension: 0.1,
        backgroundColor: color,
        borderColor: color,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: item.dataset
      })
    })
    return [labels, chartData]
  }, [alliancesRewardsChartData, sdk])

  const classes = useStyles()
  const theme = useTheme()
  const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'))
  const maxXAxis = isMobileXs ? 3 : isMobileSm ? 5 : 10
  const toolTipPosition = isMobileSm ? 'top' : 'right' as const
  const yTitleShowWhenMobile = isMobileXs ? false : true

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: true,
    },
    scales: {
      x: {
        type: 'time' as 'time',
        time: {
          unit: 'day' as 'day',
          displayFormats: {
            day: 'DD MMM',
            month: 'MMM YY'
          },
          tooltipFormat: "ll",
        },
        ticks: {
          autoSkip: false,
          beginAtZero: true,
          alwaysShowLastTick: true,
          display: true,
          maxTicksLimit: maxXAxis,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
        }
      },
      y: {
        stacked: true,
        title: {
          display: yTitleShowWhenMobile,
          text: 'Value Of Total Alliance Rewards Claimed (USD)'
        },
        ticks: {
          callback: function (val: any) {
            return bnOrZero(val).toFormat()
          },
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        suggestedMin: 0,
        grid: {
          display: true,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
        },
      }
    },
    plugins: {
      TimeScale: {
        x: {
          type: 'time'
        }
      },
      tooltip: {
        mode: 'index' as 'index',
        intersect: false,
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 10,
        boxPadding: 2,
        callbacks: {
          beforeTitle: function (context: any) {
            setCurrentIndex(context[0].dataIndex)
            return ""
          },
          label: function (context: any) {
            return context.dataset.label + ': $' + bnOrZero(context.dataset.data[context.dataIndex].y).toFormat(2) + ' (' + bnOrZero(context.dataset.data[context.dataIndex].totalRewards).toFormat() + ')'
          }
        }
      },
      crosshair: {
        line: {
          color: switcheo.text.grey,
          width: 2,
          dashPattern: [5, 5],
        }
      },
      legend: {
        position: toolTipPosition as any,
        align: 'start' as const,
        fullSize: false,
        labels: {
          boxWidth: 20,
          boxHeight: 5,
        },
      },
    },
  };

  const finalData = {
    labels: chartLabels,
    datasets: chartData,
  }

  return (
    <ChartContainer
    title={
      <Box className={classes.chartTitleBox}>
        <Box>
          <Typography className={classes.statsTitle} variant={"h3"} component={"span"}>
            {"Total Claimed Alliance Rewards"}
          </Typography>
        </Box>
      <Typography variant={"h3"} component={"span"}>
        {`$${bnOrZero(totalRewardsValue).toFormat(2)}`}
      </Typography>
      </Box>
    }
    taskNames={[TaskNames.Alliance.AlliancesRewardsChart]}
    action={fetchAlliancesRewardsChart}
  >
    <Line options={options} data={finalData} />
  </ChartContainer>
  )
}

const useStyles: any = makeStyles((theme) => ({
  statsTitle: {
    fontWeight: 'bold',
  },
  chartTitleBox: {
    paddingBottom: theme.spacing(2),
  }
}))

export default AlliancesRewardsChart