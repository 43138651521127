import { useMediaQuery, useTheme } from '@material-ui/core';
import { Params } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/params';
import { RateStrategyParams } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/cdp/rate_strategy_params';
import { bnOrZero } from 'carbon-js-sdk/lib/util/number';
import {
  Chart as ChartJS,

  Plugin, registerables
} from 'chart.js';
import 'chartjs-adapter-moment';
import chartPluginCrosshair from 'chartjs-plugin-crosshair';
import { formatInterestRateModelChartData } from 'js/helpers';
import { switcheo } from 'js/theme/palettes/colors';
import React, { ReactElement, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { getInterestRateReturn, parseRateStrategy } from '../helper';

type Props = {
  allAssetsInfo: any
  cdpParams: Params
  allRateStrategies: RateStrategyParams[]
}

const InterestRateModelChart: React.FunctionComponent<any> = (
  props: Props,
): ReactElement<Props> => {
  const { allAssetsInfo, cdpParams, allRateStrategies } = props
  const theme = useTheme()
  const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'))
  const maxXAxis = isMobileXs ? 3 : isMobileSm ? 5 : 10
  const toolTipPosition = isMobileSm ? 'top' : 'right' as const
  const yTitleShowWhenMobile = isMobileXs ? false : true
  const [chartData, setchartData] = useState<any>(undefined);
  const [chartLabels, setchartLabels] = useState<any>(undefined);

  ChartJS.register(...registerables, chartPluginCrosshair);
  useEffect(() => {
    if (allAssetsInfo && cdpParams && allRateStrategies) {
      const tempData = []
      for (let i = 0; i <= 100; i = i + 0.5) {
        let tempAssetInfo: any = {}
        if (Object.keys(allAssetsInfo).length) {
          tempAssetInfo = { ...allAssetsInfo }
          tempAssetInfo.utilizationRate = i
        }
        const { borrowApr } = getInterestRateReturn(tempAssetInfo, cdpParams, allRateStrategies)
        tempData.push({
          utilizationRate: i,
          interestRate: Number(bnOrZero(borrowApr).shiftedBy(-2).abs().toNumber().toFixed(2))
        })
      }
      const [labels, chartData] = formatInterestRateModelChartData(tempData)
      setchartData(chartData)
      setchartLabels(labels)
    }
  }, [allAssetsInfo, cdpParams, allRateStrategies])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          // autoSkip: true,
          beginAtZero: true,
          alwaysShowLastTick: true,
          display: true,
          maxTicksLimit: maxXAxis,
          minRotation: 0,
          maxRotation: 0,
          autoSkip: true
        },
        grid: {
          display: false,
        }
      },
      y: {
        title: {
          display: yTitleShowWhenMobile,
          // text: 'Utilization',
        },
        ticks: {
          callback: function (val: any) {
            return `${bnOrZero(val).toFormat()}%`;
          },
          display: true,
          minRotation: 0,
          maxRotation: 0,
          autoSkip: true,
          beginAtZero: true,
          alwaysShowLastTick: true,
          maxTicksLimit: maxXAxis,
        },
        grid: {
          display: true,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
        }
      }
    },
    plugins: {
      tooltip: {
        mode: 'index' as 'index',
        intersect: false,
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 10,
        boxPadding: 2,
        callbacks: {
          title: function (context: any) {
            return `Utilization Rate: ${context[0]?.raw?.x}%`;
          },
          label: function (context: any) {
            if (context.dataset.label.includes("Borrow")) {
              return `${context?.dataset.label}: ${context?.raw?.y}%`;
            } else return `${context?.dataset.label}: ${context?.raw?.x}%`;
          }
        }
      },
      crosshair: {
        line: {
          color: switcheo.text.grey,
          width: 2,
          dashPattern: [5, 5],
        }
      },
      legend: {
        position: toolTipPosition as any,
        align: 'start' as const,
        fullSize: false,
        labels: {
          boxWidth: 20,
          boxHeight: 5,
        },
      },
    },
  };

  // Optimal
  function roundHalf(num: number) {
    return Math.round(num * 2) / 2;
  }
  const currentUtilization = allAssetsInfo.utilizationRate
  const currentUtilizationPercent = Number(bnOrZero(currentUtilization).shiftedBy(-2).toFixed(2))
  //data set
  const datasetCurrentUtilization: any = []
  for (let i = 0; i < 200; i++) {
    datasetCurrentUtilization.push(i === roundHalf(currentUtilizationPercent) * 2 ? { x: currentUtilizationPercent, y: 0 } : { x: null, y: null })
  }
  // Current Utilization
  const rateStrategy = allRateStrategies?.find((r) => r.name === allAssetsInfo?.rateStrategyName)
  const { optimalUsage } = parseRateStrategy(rateStrategy)
  const optimalUsagePercent = bnOrZero(optimalUsage).shiftedBy(-2).toNumber()
  const datasetoptimalUsagePercent: any = []
  for (let i = 0; i < 200; i++) {
    datasetoptimalUsagePercent.push(i === roundHalf(optimalUsagePercent) * 2 ? { x: optimalUsagePercent, y: 0 } : { x: null, y: null })
  }

  const finalData = () => {
    return {
      labels: chartLabels ?? [],
      ticks: {
        autoSkip: true,
        maxTicksLimit: 20
      },
      datasets: [
        {
          label: 'Current Rate',
          fill: false,
          // spanGaps: true,
          lineTension: 0.2,
          // backgroundColor: switcheo.chart[2],
          borderColor: switcheo.chart[2],
          borderDash: [],
          borderDashOffset: 0.0,
          pointBorderColor: switcheo.chart[2],
          pointBackgroundColor: switcheo.chart[2],
          pointBorderWidth: 6,
          pointHoverRadius: 10,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: switcheo.chart[2],
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 10,
          data: datasetCurrentUtilization,
        },
        {
          label: 'Optimal Rate',
          spanGaps: true,
          fill: false,
          lineTension: 0.2,
          backgroundColor: switcheo.chart[1],
          borderColor: switcheo.chart[1],
          borderDash: [],
          borderDashOffset: 0.0,
          pointBorderColor: switcheo.chart[1],
          pointBackgroundColor: switcheo.chart[1],
          pointBorderWidth: 6,
          pointHoverRadius: 10,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: switcheo.chart[1],
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 10,
          data: datasetoptimalUsagePercent,
        },
        {
          label: 'Borrow Interest',
          fill: false,
          lineTension: 0.2,
          backgroundColor: switcheo.chart[0],
          borderColor: switcheo.chart[0],
          borderDash: [],
          borderDashOffset: 0.0,
          pointBorderColor: switcheo.chart[0],
          pointBackgroundColor: switcheo.chart[0],
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: switcheo.chart[0],
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: chartData ?? [],
        },
      ],
    };
  }
  const plugins: Plugin[] = [{
    id: "tooltipLine",
    afterDraw: (chart) => {
      if (!chart) return
      if (chart.tooltip) {
        // chart.tooltip.opacity = 1
        const {
          ctx
        } = chart;
        const currentRateMeta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
        const optimalRateMeta = chart.getDatasetMeta(1);
        const data = currentRateMeta.data;
        const optimalData = optimalRateMeta.data;
        const pointIndex = data.findIndex((o: any) => Number(o.x) > 0) ?? 0
        const lineLeftOffset = data[pointIndex]?.x
        const optimalPointIndex = optimalData.findIndex((o: any) => Number(o.x) > 0) ?? 0
        const optimalLineLeftOffset = optimalData[optimalPointIndex]?.x
        const scale = chart.scales.y;
        const isMobile = scale.top > 70 && scale.bottom < 200
        const isLargeMobile = scale.top > 50 && scale.top < 70 && scale.bottom < 200
        let lineOffset = 10
        let textOffset = scale.top
        if (isMobile) {
          lineOffset = scale.top + 70
          textOffset = -70
        }
        else if (isLargeMobile) {
          lineOffset = scale.top + 30
          textOffset = -10
        }

        const currentOptimalDiff = optimalUsagePercent < currentUtilizationPercent ? Math.min((currentUtilizationPercent - optimalUsagePercent) * 2, isMobile ? 10 : 50) : 0
        // render vertical line for current and optimal rate
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([3, 3]);
        ctx.strokeStyle = '#0F214F';
        ctx.moveTo(lineLeftOffset, (scale.bottom - scale.top) / 2 - scale.top + (isMobile ? lineOffset + 20 : lineOffset) + currentOptimalDiff);
        ctx.lineTo(lineLeftOffset, scale.bottom);
        ctx.stroke();

        ctx.beginPath();
        ctx.setLineDash([3, 3]);
        ctx.strokeStyle = '#0F214F';
        ctx.moveTo(optimalLineLeftOffset, (scale.bottom - scale.top) / 2 - scale.top + lineOffset + (isMobile ? 0 : 30));
        ctx.lineTo(optimalLineLeftOffset, scale.bottom);
        ctx.stroke();

        // write label
        ctx.fillStyle = "#0F214F";
        ctx.textAlign = 'center';
        ctx.fillText(`Current Rate: ${currentUtilizationPercent.toFixed(2)}%`, lineLeftOffset, (scale.bottom - scale.top) / 2 - (isMobile ? textOffset - 20 : textOffset) + currentOptimalDiff);
        ctx.fillText(`Optimal Rate: ${optimalUsagePercent.toFixed(2)}%`, optimalLineLeftOffset, (scale.bottom - scale.top) / 2 - textOffset + (isMobile ? 0 : 30));

        ctx.restore();
      }
    }
  }];

  return (
    <Line options={options} data={finalData()} plugins={plugins} />
  )
}

export default InterestRateModelChart

