import { TableCellInfo } from 'js/components'

export const NodeHeaders: TableCellInfo[] = [
  {
    align: 'inherit',
    content: 'Moniker',
  },
  {
    align: 'inherit',
    content: 'Creator',
  },
  {
    align: 'left',
    content: 'Uptime',
  },
  {
    align: 'inherit',
    content: 'Node ID',
  },
  {
    align: 'inherit',
    content: 'App Version',
  },
  {
    align: 'inherit',
    content: 'App Commit',
  },
  {
    align: 'inherit',
    content: 'Height',
  },
]
