import { TableCell, TableRow } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { AmountDenomCell, CellLink } from 'js/components'
import React, { ReactElement } from 'react'
import { bnOrZero, SimpleMap, ValPair } from 'js/utils'
import { Models } from 'carbon-js-sdk'
import { useRedux } from 'js/hooks'

type Props = {
  delegation: Models.Staking.DelegationResponse
  valAddrMap: SimpleMap<ValPair>
}

const DelegationRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { delegation, valAddrMap } = props
  const amountBN: BigNumber = bnOrZero(delegation?.balance?.amount)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const denom = delegation?.balance?.denom
  const tokenDecimals = denom ? sdk?.getTokenClient()?.getDecimals(denom) : 0

  return (
    <TableRow hover>
      <TableCell>
        <CellLink to={`/validator/${delegation?.delegation?.validatorAddress}`}>
          {delegation?.delegation?.validatorAddress && delegation?.delegation?.validatorAddress in valAddrMap
            ? valAddrMap[delegation?.delegation?.validatorAddress]?.carbonValidator.description?.moniker
            : delegation?.delegation?.validatorAddress}
        </CellLink>
      </TableCell>
      <AmountDenomCell
        amount={tokenDecimals ? amountBN.shiftedBy(-tokenDecimals).toFormat(8) : 'Unable to load'}
        denom={delegation?.balance?.denom}
      />
    </TableRow>
  )
}

export default DelegationRow
