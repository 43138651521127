import { MsgGasCost } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/fee/export'
import { Transaction } from 'carbon-js-sdk/lib/insights'
import { TransactionsResponse } from 'js/reducers/transactions'
import { Action } from 'redux'

export enum TransactionsActionType {
  CLEAR = 'CLEAR_TRANSACTIONS',
  SET_TRANSACTIONS = 'SET_TRANSACTIONS',
  SET_GAS_FEES = 'SET_GAS_FEES',
  SET_TYPES = 'SET_TYPES',
  SET_TRANSACTION_TOTAL_CHART = 'SET_TRANSACTION_TOTAL_CHART',
  SET_TRANSACTION_MSG_CHART = 'SET_TRANSACTION_MSG_CHART',
  SET_DISABLE_PAGINATION_FORWARD_BUTTON = 'SET_DISABLE_PAGINATION_FORWARD_BUTTON',
  UPDATE_DATE_FILTERS_MSG_COUNT = 'UPDATE_DATE_FILTERS_MSG_COUNT',
  UPDATE_DATE_FILTERS_TOTAL_COUNT = 'UPDATE_DATE_FILTERS_TOTAL_COUNT',
  UPDATE_FILTERS = 'UPDATE_FILTERS',
}

export type TransactionsAction =
  | ClearAction
  | SetTransactionsAction
  | SetGasFeesAction
  | SetTransactionTypesAction
  | SetTransactionTotalChartAction
  | SetTransactionMsgChartAction
  | SetDisablePaginationForwardButtonAction
  | UpdateDateFiltersMsgCountChartAction
  | UpdateDateFiltersTotalTxCountChartAction
  | UpdateFiltersAction

export interface ClearAction extends Action<TransactionsActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: TransactionsActionType.CLEAR,
  }
}

export interface SetTransactionsAction
  extends Action<TransactionsActionType.SET_TRANSACTIONS> {
  transactions: TransactionsResponse
}
export function setTransactions(
  transactions: TransactionsResponse,
): SetTransactionsAction {
  return {
    type: TransactionsActionType.SET_TRANSACTIONS,
    transactions,
  }
}

export interface SetTransactionTypesAction
  extends Action<TransactionsActionType.SET_TYPES> {
  types: string[]
}
export function setTypes(types: string[]): SetTransactionTypesAction {
  return {
    type: TransactionsActionType.SET_TYPES,
    types,
  }
}

export interface SetGasFeesAction
  extends Action<TransactionsActionType.SET_GAS_FEES> {
  gasFees: MsgGasCost[]
}
export function setGasFees(gasFees: MsgGasCost[]): SetGasFeesAction {
  return {
    type: TransactionsActionType.SET_GAS_FEES,
    gasFees,
  }
}

export interface SetTransactionTotalChartAction
  extends Action<TransactionsActionType.SET_TRANSACTION_TOTAL_CHART> {
  transactionTotalChartData: Transaction[]
}
export function setTransactionTotalChartData(transactionTotalChartData: Transaction[]): SetTransactionTotalChartAction {
  return {
    type: TransactionsActionType.SET_TRANSACTION_TOTAL_CHART,
    transactionTotalChartData,
  }
}
export interface SetTransactionMsgChartAction
  extends Action<TransactionsActionType.SET_TRANSACTION_MSG_CHART> {
  transactionMsgCountChartData: Transaction[]
}
export function setTransactionMsgChartData(transactionMsgCountChartData: Transaction[]): SetTransactionMsgChartAction {
  return {
    type: TransactionsActionType.SET_TRANSACTION_MSG_CHART,
    transactionMsgCountChartData,
  }
}

export interface UpdateDateFiltersMsgCountChartAction
  extends Action<TransactionsActionType.UPDATE_DATE_FILTERS_MSG_COUNT> {
  options: any
}

export function updateDateFiltersMsgCountChart(options: any): UpdateDateFiltersMsgCountChartAction {
  return {
    type: TransactionsActionType.UPDATE_DATE_FILTERS_MSG_COUNT,
    options,
  }
}
export interface UpdateDateFiltersTotalTxCountChartAction
  extends Action<TransactionsActionType.UPDATE_DATE_FILTERS_TOTAL_COUNT> {
  options: any
}

export function updateDateFiltersTotalTxCountChart(options: any): UpdateDateFiltersTotalTxCountChartAction {
  return {
    type: TransactionsActionType.UPDATE_DATE_FILTERS_TOTAL_COUNT,
    options,
  }
}


export interface UpdateFiltersAction
  extends Action<TransactionsActionType.UPDATE_FILTERS> {
  options: any
}

export function updateFilters(options: any): UpdateFiltersAction {
  return {
    type: TransactionsActionType.UPDATE_FILTERS,
    options,
  }
}

export interface SetDisablePaginationForwardButtonAction
  extends Action<TransactionsActionType.SET_DISABLE_PAGINATION_FORWARD_BUTTON> {
  disablePaginationForwardButton: boolean
}

export function setDisablePaginationForwardButton(
  disablePaginationForwardButton: boolean,
): SetDisablePaginationForwardButtonAction {
  return {
    type: TransactionsActionType.SET_DISABLE_PAGINATION_FORWARD_BUTTON,
    disablePaginationForwardButton,
  }
}