import { Box, Grid, makeStyles, TableBody, TableHead, TableRow, Theme, Typography } from '@material-ui/core'
import { updateDateFilters } from 'js/actions/validators'
import { ChartContainer, DataLoadSegment, ListTable, Page, Section, TableEmptyState, TooltipHint } from 'js/components'
import { TaskNames } from 'js/constants'
import { useAsyncTask, useRedux, useTaskSubscriber } from 'js/hooks'
import { ValidatorsProps } from 'js/reducers/validators'
import { BIG_ZERO, bnOrZero } from 'js/utils'
import { CheckboxFilter } from 'js/views/Governance/components'
import Long from 'long'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { DistributionParams, SlashingParams, StakingChart, TooltipCell, ValidatorRow, ValidatorsSummary } from './components'
import OracleSlashing from './components/OracleSlashing'
import ValidatorPoolSearcher from './components/ValidatorPoolSearcher'
import { HeaderCells } from './validatorsConfig'

interface Props extends ValidatorsProps {
}

const Validators: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(TaskNames.App.Validators)
  const classes = useStyles(props)
  const { slashingParams, distributionParams, totalStakedChartData } = useRedux((state) => state.validators)
  const { delegatorsMap, signingInfo, avgBlockTime, latestBlock } = useRedux((state) => state.app)
  const valAddrMap = useRedux((state) => state.core.valAddrMap)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [totalTokens, setTotalTokens] = useState(BIG_ZERO)
  const [hideUnbonded, setHideUnbonded] = useState<boolean>(true)
  const [getOracleWindow] = useAsyncTask("getOracleWindow")
  const [getOracleVote] = useAsyncTask("getOracleVote")
  const [getParticipation] = useAsyncTask("getParticipation")
  const [participation, setParticipation] = useState<any>([])
  const [oracleWindow, setOracleWindow] = useState<number>(0)
  const [valAddrMapWithOracleInfo, setValAddrMapWithOracleInfo] = useState<any>({})
  const [oracleIds, setOracleIds] = useState<string[]>([])
  const totalProposalToCheck = 10

  const validators = useMemo(
    () => {
      let totalTokens = BIG_ZERO
      let results = Object.values(valAddrMap).map(pair => pair.carbonValidator).sort((lhs, rhs) => bnOrZero(rhs.tokens).comparedTo(lhs.tokens))
      for (const result of results) {
        totalTokens = totalTokens.plus(sdk?.token.toHuman('swth', bnOrZero(result.tokens)) ?? BIG_ZERO)
      }
      setTotalTokens(totalTokens);
      return results
    }, [valAddrMap, sdk]
  )

  const [searchInput, setSearchInput] = useState('')
  const [filteredValidators, setFilteredValidators] = useState(validators)

  const onSearch = (searchInput: string) => {
    setSearchInput(searchInput)
  }

  useEffect(() => {
    let searchedData = validators;
    if (searchInput !== '') {
      searchedData = searchedData.filter(
        (data) => {
          return data.description?.moniker.toUpperCase().includes(searchInput.toUpperCase())
        }
      )
    }
    setFilteredValidators(searchedData)
  }, [validators, searchInput])

  useEffect(() => {
    if (!sdk) return
    getParticipation(async () => {
      const networkURL = sdk.network === 'mainnet' ? '' : sdk.network === 'testnet' ? 'test-' : 'dev-'
      const participationRaw = await (await fetch(`https://${networkURL}api-insights.carbon.network/gov/proposal/participation?validator=true&last=${totalProposalToCheck}`)).json() // TODO: add to sdk
      setParticipation(participationRaw?.result?.entries)
    })
  }, [sdk]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!sdk) return
    getOracleWindow(async () => {
      const oracleWindow = await sdk.query.params.Params({ subspace: 'oracle', key: 'OracleSlashWindowBlock' })
      setOracleWindow(Number(oracleWindow?.param?.value ?? 0))
    })
  }, [sdk]) // eslint-disable-line react-hooks/exhaustive-deps
  const currentBlockHeight = latestBlock?.height ?? 0
  const currentBlockInWindow = (currentBlockHeight % oracleWindow) ?? 0
  const oracleWindowStartBlock = currentBlockHeight - currentBlockInWindow
  const oracleWindowEndBlock = currentBlockHeight + (oracleWindow - currentBlockInWindow)

  const getOracleVotingInfo = async () => {
    if (!valAddrMap || !Object.keys(valAddrMap).length) return
    try {
      const oracleVotesWindow = await sdk?.query?.oracle?.OracleVotesWindowAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      const oracleSlashCount = await sdk?.query?.oracle?.SlashCounterAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      const oraclesIdsInfo = await sdk?.query?.oracle?.OracleAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })
      const temp: any = { ...valAddrMapWithOracleInfo }
      for (const val of oracleSlashCount?.slashCounters ?? []) {
        if (!temp[val?.validator]) temp[val?.validator] = {}
        temp[val?.validator].validator = val?.validator
        temp[val?.validator].slashCount = val?.slashCount
        temp[val?.validator].prevSlashCount = val?.prevSlashCount
        temp[val?.validator].moniker = valAddrMap[val?.validator]?.carbonValidator?.description?.moniker ?? ""
      }
      for (const oracleVote of oracleVotesWindow?.oracleVotesWindows ?? []) {
        if (!temp[oracleVote?.validator]) temp[oracleVote?.validator] = {}
        if (!temp[oracleVote?.validator].vote) temp[oracleVote?.validator].vote = {}
        if (!temp[oracleVote?.validator].vote[oracleVote?.oracleId]) temp[oracleVote?.validator].vote[oracleVote?.oracleId] = {}
        temp[oracleVote?.validator].vote[oracleVote?.oracleId].id = oracleVote?.oracleId
        temp[oracleVote?.validator].vote[oracleVote?.oracleId].count = oracleVote?.voteCount?.toNumber()
      }
      setValAddrMapWithOracleInfo(temp)
      const oracleIdsArray = oraclesIdsInfo?.oracles.map((oraclesId) => oraclesId.id)
      setOracleIds(oracleIdsArray ?? [])
    }
    catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!sdk || !valAddrMap) return
    let loadingOracleInfo = false
    let interval: any
    getOracleVote(async () => {
      if (!oracleIds?.length) {
        getOracleVotingInfo() //initialise
      }
      interval = setInterval(async () => {
        if (loadingOracleInfo) return
        loadingOracleInfo = true
        await getOracleVotingInfo()
        loadingOracleInfo = false
      }, 10000)
    })
    return () => clearInterval(interval)
  }, [sdk, valAddrMap]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleHideUnbonded = () => {
    setHideUnbonded((curr) => !curr)
  }

  return (
    <Page title="Validators">
      <ValidatorsSummary
        blockTime={avgBlockTime}
        info={signingInfo}
        totalTokens={totalTokens}
        validators={validators}
      />
      <ChartContainer
        taskNames={[TaskNames.Validators.TotalStakedChart]}
        title={
          <Box className={classes.chartTitleBox}>
            <Typography variant={"h3"} component={"span"}>
              {`SWTH Staked TVL`} <TooltipHint title={"Total SWTH staked"} className={classes.tooltip} />
            </Typography>
          </Box>
        }
        action={updateDateFilters}
        footer={"Information is an estimate and may not be accurate at the moment"}
      >
        <StakingChart data={totalStakedChartData} />
      </ChartContainer>
      <Section title="Validators" rightMenu={
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={classes.checkbox}>
            <CheckboxFilter
              checked={hideUnbonded}
              label="Hide Unbonded"
              onChange={handleHideUnbonded}
            />

          </Grid>
          <Grid item xs={12} md={6}>
            <ValidatorPoolSearcher
              onSearch={onSearch}
            />

          </Grid>
        </Grid>
      }>
        <DataLoadSegment
          loading={loading}
        >
          <ListTable>
            <TableHead>
              <TableRow>
                {HeaderCells.map((header, id) =>
                  <TooltipCell {...header} key={id} />,
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredValidators.filter((validator) => hideUnbonded ? validator?.status?.toString() !== "1" : true).map((validator, index) => {
                const delegatorList = delegatorsMap[validator.operatorAddress]
                const slashCount = valAddrMapWithOracleInfo[validator.operatorAddress]?.slashCount?.toNumber()
                const participationInfo = participation.find((o: any) => o.validatorAddress === validator.operatorAddress)
                return (
                  <ValidatorRow
                    delegatorList={delegatorList}
                    key={validator.operatorAddress}
                    index={index}
                    valAddrMap={valAddrMap}
                    info={signingInfo}
                    totalTokens={totalTokens}
                    validator={validator}
                    slashCount={slashCount}
                    participation={participationInfo?.participated}
                    totalProposal={totalProposalToCheck}
                  />
                )
              })}
            </TableBody>
          </ListTable>
          {!filteredValidators.length && (
            <TableEmptyState itemName="validators" />
          )}
        </DataLoadSegment>
      </Section>
      <OracleSlashing
        oracleWindowStartBlock={oracleWindowStartBlock}
        oracleWindowEndBlock={oracleWindowEndBlock}
        validators={valAddrMapWithOracleInfo}
        oracleIds={oracleIds}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SlashingParams params={slashingParams} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DistributionParams distributionParams={distributionParams} />
        </Grid>
      </Grid>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    pointer: 'cursor',
  },
  chartTitleBox: {
    paddingBottom: theme.spacing(2),
  },
  checkbox: {
    maxWidth: '10.1rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: "fit-content",
    },

  }
}))

export default Validators
