import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
// import { AddressUtils } from 'carbon-js-sdk'
import { CellLink, DataLoadSegment, NotFoundState, Page, Section, TabsButtons } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useAsyncTask, useRedux, useTaskSubscriber } from 'js/hooks'
import { adjustHuman, BIG_ZERO, bnOrZero } from 'js/utils'
import React, { ReactElement, useEffect, useState } from 'react'
import { getBondStatus } from '../Validators/validatorsConfig'
import { CurrentPool, Degelations, ProfileAvatar, ProfileDetails } from './components'
import { items } from './validatorConfig'
import * as bech32 from "bech32";
import { getSWTHAddressFromValidatorOperatorAddress } from 'js/helpers'
import TransactionFilter, { FilterOption } from '../../Transactions/TransactionList/TransactionFilter'
import { updateFilters } from 'js/actions/account'
import { QueryAllTransactionResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/query'
import { TransactionsResponse } from 'js/reducers/transactions'
import ValidatorTransactions from './components/ValidatorTransactions'
import Long from 'long'
import { Transaction } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/transaction'

interface Props {
}

const Validator: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.App.Validators, TaskNames.Validator.Detail, TaskNames.App.SigningInfo)
  const { transactions } = useRedux((state) => state.transactions)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const valAddrMap = useRedux((state) => state.core.valAddrMap)
  // TODO: Add blocks
  const signingInfo = useRedux((state) => state.app.signingInfo)
  const { validator, delegations, selfbond } = useRedux((state) => state.validator)
  const [tabId, setTabId] = useState(0)
  const [keybaseProfile, setKeybaseProfile] = useState({
    url: '',
    image: '',
  })
  const [imgLoading, setImgLoading] = useState(false)
  const [filters, setFilters] = useState<string[]>([]);
  const [resetPage, setResetPage] = useState(false)

  const delegatorShares = sdk?.token.toHuman('swth', adjustHuman(validator?.delegatorShares ?? '')) ?? BIG_ZERO
  const consensusAddress = valAddrMap[validator?.operatorAddress ?? ""]?.consAddress
  const consensusPubkey = Buffer.from(validator?.consensusPubkey?.value ?? new Uint8Array()).toString("hex");
  const consAddrDecoded = consensusAddress ? bech32.decode(consensusAddress) : undefined;
  const consensusAddressHex = consAddrDecoded ? `0x${Buffer.from(bech32.fromWords(consAddrDecoded.words)).toString("hex")}` : "";
  const walletAddress = getSWTHAddressFromValidatorOperatorAddress(validator?.operatorAddress, sdk?.network)
  const validatorProfile = {
    ...validator?.description,
    operatorAddress: validator?.operatorAddress,
    consensusPubkey: consensusPubkey.toUpperCase(),
    consensusAddress: consensusAddress ?? "",
    consensusAddressHex,
    walletAddress,
    tokens: sdk?.token.toHuman('swth', bnOrZero(validator?.tokens)).toFormat(8),
    delegatorShares: delegatorShares.toFormat(8),
    delegators: delegations.length,
    selfBond: `${selfbond.toFormat(8)} (${selfbond
      .div(delegatorShares)
      .times(100)
      .toFormat(2)}%)`,
    jailed: validator?.jailed,
    bondStatus: getBondStatus(validator?.status ?? -1),
    unbondingHeight: validator?.unbondingHeight,
    unbondingTime: validator?.unbondingTime,
    commissionRate: adjustHuman(validator?.commission?.commissionRates?.rate ?? '').shiftedBy(2).toNumber(),
    maxRate: adjustHuman(validator?.commission?.commissionRates?.maxRate ?? '').shiftedBy(2).toNumber(),
    maxChangeRate: adjustHuman(validator?.commission?.commissionRates?.maxChangeRate ?? '').shiftedBy(2).toNumber(),
    activeSince: bnOrZero(signingInfo[consensusAddress ?? ""]?.startHeight.toNumber()).toNumber(),
    missedBlocksCount: signingInfo[consensusAddress ?? ""]?.missedBlocksCounter.toNumber() ?? 216000,
  }

  const [getValidatorTransactions] = useAsyncTask('getValidatorTransactions')
  const [validatorTransactions, setValidatorTransactions] = useState<TransactionsResponse>(transactions)
  const [filteredValidatorTransactions, setFilteredValidatorTransactions] = useState<TransactionsResponse>(transactions)
  const [validatorTransactionsTypes, setValidatorTransactionTypes] = useState<string[]>([])

  useEffect(() => {
    if (!sdk) return
    getValidatorTransactions(async () => {
      try {
        if (walletAddress === '') {
          return;
        }
        const transactionQueryClient = sdk.query.misc;
        const validatorsTransactionsResponse = await transactionQueryClient.TransactionAll({
          hash: '',
          address: walletAddress,
          msgTypeFilters: [],
          pagination: {
            limit: new Long(10),
            offset: Long.UZERO,
            key: new Uint8Array(),
            countTotal: false,
            reverse: false,
          }
        }) as QueryAllTransactionResponse;

        const transactionResults = validatorsTransactionsResponse.transactions
        const validatorTransactionResults = {
          data: transactionResults.sort((a: Transaction, b: Transaction) => b.blockHeight.toNumber() - a.blockHeight.toNumber()),
          meta: {
            totalPages: validatorsTransactionsResponse.pagination?.total.toNumber() ?? 0,
          }
        }
        let types = new Set<string>();
        validatorTransactionResults.data.forEach((e) => {
          let messageType = e.messages[0].messageType?.messageType
          if (messageType) {
            types.add(getTypeFromTypeUrl(messageType))
          }
        })
        setValidatorTransactions(validatorTransactionResults)
        setValidatorTransactionTypes([...types])
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, walletAddress])

  useEffect(() => {
    let transactions = validatorTransactions.data
    if (filters.length > 0) {
      transactions = transactions.filter(
        (e) => {
          return e.messages[0].messageType?.messageType && filters.includes(getTypeFromTypeUrl(e.messages[0].messageType?.messageType))
        })
    }
    let meta = validatorTransactions.meta
    setFilteredValidatorTransactions({
      data: transactions,
      meta: meta
    })
  }, [filters, validatorTransactions])

  const getTypeFromTypeUrl = (url: string) => {
    // helper function to generate transaction types
    let position = url.lastIndexOf(".") + 4;
    const result = url.substring(position).trim();
    return result
  }

  useEffect(() => {
    const identity = validator?.description?.identity
    if (identity?.length !== 40) return
    setImgLoading(true)

    fetch(
      `https://keybase.io/_/api/1.0/user/lookup.json?key_fingerprint=${identity}&fields=basics,pictures`,
    )
      .then((r: any) => (r ? r.json() : null))
      .then((json: any) => {
        if (json && json?.them && json?.them[0]) {
          const user = json?.them[0]
          const image = user?.pictures?.primary?.url
          const url = `https://keybase.io/${user?.basics?.username}`
          setKeybaseProfile({ url, image })
        }
      })
  }, [validator])

  const handleChange = (event: any, newValue: number) => {
    setTabId(newValue)
  }
  const onFilter = (filters: string[]) => {
    setFilters(filters)
  }

  const mapTypeToOption = (type: string): FilterOption => {
    return {
      value: type,
    }
  }


  return (
    <Page title="Validator Details">
      <DataLoadSegment
        loading={loading}
        size="2.5rem"
      >
        {validator?.operatorAddress && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <ProfileAvatar
                imgLoading={imgLoading}
                keybaseProfile={keybaseProfile}
                setImgLoading={setImgLoading}
                validator={validatorProfile}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <ProfileDetails keybaseProfile={keybaseProfile} validator={validatorProfile} />
            </Grid>
            <Grid item xs={12}>
              <Section className={classes.tabsRoot}>
                <TabsButtons handleChange={handleChange} items={items} tabClass={classes.tab} value={tabId} />
                <div role="tabpanel" hidden={tabId !== 0}>
                  <CurrentPool validator={validatorProfile} />
                </div>
                <div role="tabpanel" hidden={tabId !== 1}>
                  <ValidatorTransactions
                    title="Transactions"
                    transactions={filteredValidatorTransactions}
                    action={updateFilters}
                    filterStatus
                    loadName={[
                      TaskNames.Account.Transactions,
                      TaskNames.Account.TransactionType,
                      TaskNames.Account.TransactionFilter,
                    ]}
                    filters={filters}
                    sectionClass={classes.transactions}
                    resetPage={resetPage}
                    setPageReset={setResetPage}
                    rightMenu={
                      validatorTransactions.data.length > 0 ?
                        <TransactionFilter
                          className={classes.filter}
                          onFilter={onFilter}
                          options={validatorTransactionsTypes.map(mapTypeToOption)}
                        /> : undefined
                    }
                  />
                </div>
                <div role="tabpanel" hidden={tabId !== 2}>
                  <Degelations
                    title="Delegators"
                    delegations={delegations}
                  />
                </div>
              </Section>
            </Grid>
          </Grid>
        )}
        {!validator?.operatorAddress && (
          <NotFoundState title="No Validator Found">
            <Typography variant="body1">
              We can’t find any validator with this ID. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Validators}>
                Validators
              </CellLink>
              &nbsp;page to view existing validators.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    padding: theme.spacing(3),
  },
  tab: {
    fontSize: '1.125rem',
  },
  tabsRoot: {
    padding: 0,
  },
  title: {
    textAlign: 'left',
  },
  transactions: {
    padding: theme.spacing(3),
  },
  filter: {
    marginBottom: theme.spacing(2),
  },
}))

export default Validator
