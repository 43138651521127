import { Box, BoxProps, IconButton, InputAdornment, InputBase } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { switcheo } from 'js/theme/palettes/colors'
import React, { ReactElement, useEffect, useState } from 'react'
import { SearchIcon } from '../layouts/Header/assets'

interface Props extends BoxProps {
  onSearch: (types: string) => void
  placeholder?: string
  forceInput?: string
}

const TableSearchBar: React.FunctionComponent<Props> = ({
  className, onSearch, placeholder, forceInput
}: Props): ReactElement<Props> => {
  const [input, setInput] = useState(forceInput ?? '')
  const [firstLoad, setFirstLoad] = useState(true)
  const updateInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let input = event.target.value
    setInput(input)
    triggerSearch(input)
  }
  const [showSearchResults, setShowSearchResults] = React.useState(false)

  const triggerSearch = async (searchInput: string) => {
    searchInput = searchInput.trim().toUpperCase()
    onSearch(searchInput)
  }

  useEffect(() => {
    if (!forceInput && input) setInput('')
    if (forceInput) setInput(forceInput)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceInput])

  const detectEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerSearch(input)
    }
  }

  if (firstLoad && input !== '') {
    triggerSearch(input);
    setFirstLoad(false)
  }

  const dropdownRef = React.useRef<HTMLDivElement | null>(null)
  const handleClickOutside = React.useCallback((event: any) => {
    if (dropdownRef.current === null) return
    if (dropdownRef.current.contains(event.target)) {
      setShowSearchResults(true)
    } else {
      setShowSearchResults(false)
    }
  }, [dropdownRef])

  // click away listener
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside)
  }, [handleClickOutside])

  const classes = useStyles()
  const handleSearch = () => triggerSearch(input)
  return (
    <Box className={clsx(classes.search, className)}>
      <div ref={dropdownRef} className={classes.searchWrapper}>
        <InputBase
          placeholder={placeholder}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          fullWidth
          id="search-input"
          inputProps={{ 'aria-label': 'search' }}
          onKeyDown={detectEnterKeyPress}
          value={input}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton onClick={handleSearch} className={classes.iconBtn}>
                <SearchIcon className={classes.searchIcon} />
              </IconButton>
            </InputAdornment>
          )}
          onChange={updateInput}
        />
      </div>
      <div className={clsx(classes.searchMenu, { showSearchResults })}>
      </div>
    </Box>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  search: {
    position: 'relative',
    minWidth: '24rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    }
  },
  searchIcon: {
    width: '1.25rem',
    maxHeight: '1.25rem',
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
  },
  searchMenu: {
    backgroundColor: theme.palette.secondary.main,
    maxHeight: '50vh',
    overflow: 'auto',
    display: 'none',
    '& .MuiList-root': {
      zIndex: theme.zIndex.modal + 2000,
    },
    '&.showSearchResults': {
      display: 'block',
      position: 'absolute',
      left: 0,
      right: 0,
      zIndex: theme.zIndex.modal + 2000,
      [theme.breakpoints.down('sm')]: {
        top: '3.4rem',
      },
    },
    '& .MuiListSubheader-root': {
      color: theme.palette.primary.main,
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
      letterSpacing: '-0.0125rem',
      padding: theme.spacing(1.5, 2),
      fontWeight: 'bold',
      position: 'unset',
    },
    '& .MuiListItem-root': {
      color: theme.palette.common.white,
      cursor: 'pointer',
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      letterSpacing: '-0.0125rem',
      padding: theme.spacing(1.5, 2),
      '&:hover': {
        backgroundColor: switcheo.primary[800],
      },
    },
  },
  inputRoot: {
    color: 'inherit',
    backgroundColor: switcheo.background.grey,
    borderRadius: 6
  },
  inputInput: {
    padding: theme.spacing(1.25, 1.5),
    ...theme.typography.body1,
    color: theme.palette.text.disabled,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
  },
}))

export default TableSearchBar
