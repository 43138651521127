import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { Insights } from 'carbon-js-sdk'
import { CommonAssetName } from 'carbon-js-sdk/lib/constant'
import { updateDateFiltersUtilisationChartData } from 'js/actions/liquidityPools'
import { CellLink, ChartContainer, Section } from 'js/components'
import { CARBON_GENESIS_BLOCKTIME } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { switcheo } from 'js/theme/palettes/colors'
import { adjustHuman, BN_ZERO, bnOrZero } from 'js/utils'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import IndividualPoolChart from '../Charts/IndividualPoolChart'
//import PoolTransactions from './PoolTransactions'
import { NavigateIcon } from 'assets'
import { PoolVolume } from 'carbon-js-sdk/lib/insights'
import clsx from 'clsx'
import LiquidityPoolOverview from '../common/LiquidityPoolOverview'
import { ExtendedPool } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/query'

interface Props {
  pool: ExtendedPool
  total: BigNumber
  rewards: BigNumber
  volume: BigNumber
}

interface PoolVolumeWithPrice extends PoolVolume {
  volumeShifted: number | string
}

interface PoolResponse extends Insights.QueryGetPoolVolumeResponse {
  entries: PoolVolumeWithPrice[];
}

const PoolDetailsComponents: React.FC<Props> = (
  props: Props,
): React.ReactElement<Props> => {
  const { pool, total, volume, rewards } = props
  const {
    pool: poolInfo,
    rewardsWeight,
  } = pool
  const { id, denomA, denomB, amountA, amountB, swapFee, denom, ampBps } = poolInfo!
  //const { transactions } = useRedux((state) => state.transactions)
  const [getTotalPoolVolume] = useAsyncTask('getTotalPoolVolume')
  const classes = useStyles()
  const sdk = useRedux((state) => state.core.carbonSDK)
  const symbolA = sdk?.token.getTokenName(denomA)
  const symbolB = sdk?.token.getTokenName(denomB)
  const isCdpTokenA = denomA.includes("cibt/")
  const isCdpTokenB = denomA.includes("cibt/")

  const amountABN = sdk?.token.toHuman(denomA, bnOrZero(amountA)) ?? BN_ZERO
  const amountBBN = sdk?.token.toHuman(denomB, bnOrZero(amountB)) ?? BN_ZERO
  const swapFeeBN = adjustHuman(swapFee)

  // const marketName = markets[market]?.displayName ?? market
  const quoteCurrency = denomA
  const quoteUSDBN = sdk?.token.getUSDValue(quoteCurrency) ?? BN_ZERO

  // const quoteSymbol = sdk?.token.getTokenName(quoteCurrency ?? '') ?? ''
  const notionalVolume = volume.toFormat(2)
  const swthUSD = sdk?.token.getUSDValue(CommonAssetName.swth) ?? BN_ZERO
  let weeklyRewards = rewards.times(rewardsWeight).div(total)
  if (weeklyRewards.isNaN()) {
    weeklyRewards = BN_ZERO
  }
  const notionalWeeklyRewards = swthUSD.times(weeklyRewards)
  const tokenAUSD = sdk?.token.getUSDValue(denomA) ?? BN_ZERO
  const notionalTokenA = amountABN.times(tokenAUSD)
  const tokenBUSD = sdk?.token.getUSDValue(denomB) ?? BN_ZERO
  const notionalTokenB = amountBBN.times(tokenBUSD)

  const notionalAB = notionalTokenA.plus(notionalTokenB)
  const apy = notionalAB.isZero() ? BN_ZERO : notionalWeeklyRewards.div(notionalAB).times(52).shiftedBy(2)
  const weightA = adjustHuman(poolInfo?.weightA ?? '').shiftedBy(2)
  const weightB = adjustHuman(poolInfo?.weightB ?? '').shiftedBy(2)
  const priceInA = amountABN.div(amountBBN).div(weightA).times(weightB)
  const priceInB = amountBBN.div(amountABN).div(weightB).times(weightA)

  const [poolLiquidity, setPoolLiquidity] = useState<any>([]);
  const [poolVolume, setPoolVolume] = useState<any>([]);
  const [poolTotalLiquidity, setTotalPoolLiquidity] = useState<any>(0);
  const [poolTotalVolume, setTotalPoolVolume] = useState<any>(0);
  const startDate = moment(CARBON_GENESIS_BLOCKTIME)
  const endDate = moment()

  const [dateRange, setDateRange] = React.useState<any>({
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  });

  const handleDate = useCallback((data: any) => {
    setDateRange(data)
  }, []);
  let chainA = sdk?.token.getBlockchain(denomA) ?? "Terra"
  let chainB = sdk?.token.getBlockchain(denomB) ?? "Terra"

  const chainNameMap: {
    [index: string]: string
  } = {
    native: 'Carbon',
    eth: 'Ethereum',
    bsc: 'BSC',
    neo: 'Neo Legacy',
    zil: 'Zilliqa',
    btc: 'Osmosis'
  }

  if (chainNameMap[chainA]) {
    chainA = chainNameMap[chainA]
  }

  if (chainNameMap[chainB]) {
    chainB = chainNameMap[chainB]
  }

  useEffect(() => {
    if (!sdk) return
    getTotalPoolVolume(async () => {
      try {
        const from = moment(moment(CARBON_GENESIS_BLOCKTIME).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
        const until = moment(moment(Date.now()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()

        const InsightsQueryClient = sdk.insights;
        const poolVolumeResponse = await InsightsQueryClient.PoolVolume({
          from: from,
          until: until,
          poolId: id.toNumber()
        }) as Insights.InsightsQueryResponse<PoolResponse>
        const poolLiquidityResponse = await InsightsQueryClient.PoolsLiquidity({
          from: from,
          until: until,
          poolId: id.toString()
        }) as Insights.InsightsQueryResponse<Insights.QueryGetPoolsLiquidityResponse>
        if (poolLiquidity.length === 0) {
          setPoolLiquidity(poolLiquidityResponse.result.entries)
        }
        if (poolVolume.length === 0) {
          setPoolVolume(poolVolumeResponse.result.entries)
          if (poolVolumeResponse.result.entries?.length) setTotalPoolVolume(poolVolumeResponse.result.entries[poolVolumeResponse.result.entries.length - 1].volume)
        }
      }
      catch (err) {
        console.error(err)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk])
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Section className={classes.boxLeft}>
            <LiquidityPoolOverview symbolA={symbolA} symbolB={symbolB} weightA={weightA} weightB={weightB} chainA={chainA} chainB={chainB}
              isCdpTokenA={isCdpTokenA} isCdpTokenB={isCdpTokenB} poolId={id} />
            <Box style={{ minWidth: 300, margin: '40px 0 80px 0' }}>
              <div className={classes.rowLeft}>
                <span>Market</span>
                <span>
                  <div>{"-"}</div>
                </span>
              </div>
              <div className={classes.rowLeft}>
                <span>Rewards APY</span>
                <span>{apy.isPositive() && !apy.isZero() ? `${apy.toFormat(2)}%` : "-"}</span>
              </div>
              <div className={classes.rowLeft}>
                <span>24H Volume</span>
                <span>{notionalVolume ? (
                  <>
                    <span style={{ fontWeight: 700 }}>{`$${notionalVolume} `}</span>
                    {/* <span>{`(${adjustedVolume} ${quoteSymbol})`}</span> */}
                  </>
                ) : (
                  <div>{"-"}</div>
                )}</span>
              </div>
              <div style={{ display: 'flex', padding: '10px 0', justifyContent: 'space-between' }}>
                <span>Total Value</span>
                <span style={{ fontWeight: 700 }}>{notionalAB.isZero() ? '-' : `$${notionalAB.toFormat(2)}`}</span>
              </div>
            </Box>
            <CellLink style={{ display: 'flex', alignItems: 'center' }} href={`https://app.dem.exchange/pools/add/${denom}`}>Add Liquidity&nbsp;<NavigateIcon /></CellLink>
          </Section>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Section className={classes.boxRight}>
            <Box style={{ minWidth: 500 }}>
              <div className={classes.rowRight}>
                <span style={{ minWidth: 230 }}>Pool Token</span>
                <span><CellLink to={`/token/${denom}`}>{denom}</CellLink></span>
              </div>
              <div className={classes.rowRight}>
                <span style={{ minWidth: 230 }}>Pool Weights</span>
                <span>{weightA.toFormat()}% / {weightB.toFormat()}%</span>
              </div>
              <div className={classes.rowRight}>
                <span style={{ minWidth: 230 }}>Weekly Est. Rewards</span>
                <span style={{ fontWeight: 700 }} >{`${notionalWeeklyRewards.toFormat(2)}`}&nbsp;</span><span>{`(${weeklyRewards.toFormat(0)} SWTH)`}</span>
              </div>
              <div className={classes.rowRight}>
                <span style={{ minWidth: 230 }}>{`Price in ${symbolA}`}</span>
                <span>{notionalAB.isZero() ? '-' : `${priceInA.toFormat(8)} ${symbolA}`}</span>
              </div>
              <div className={classes.rowRight}>
                <span style={{ minWidth: 230 }}>{`Price in ${symbolB}`}</span>
                <span>{notionalAB.isZero() ? '-' : `${priceInB.toFormat(8)} ${symbolB}`}</span>
              </div>
              <div className={classes.rowRight}>
                <span style={{ minWidth: 230 }}>Token A</span>
                <span style={{ fontWeight: 700 }} >{notionalTokenA.isZero() ? '-' : `$${notionalTokenA.toFormat(2)}`}&nbsp;</span>
                <span> ({amountABN.toFormat()} {symbolA})</span>
              </div>
              <div className={classes.rowRight}>
                <span style={{ minWidth: 230 }}>Token B</span>
                <span style={{ fontWeight: 700 }} >{notionalTokenB.isZero() ? '-' : `$${notionalTokenB.toFormat(2)}`}&nbsp;</span>
                <span> ({amountBBN.toFormat()} {symbolB})</span>
              </div>
              <div className={classes.rowRight}>
                <span style={{ minWidth: 230 }}>Amplification Factor</span>
                <span>{bnOrZero(ampBps.toNumber()).shiftedBy(-4).toFixed(2)}x</span>
              </div>
              <div className={classes.rowRight}>
                <span style={{ minWidth: 230 }}>Swap Fee</span>
                <span>{swapFeeBN.times(100).toFormat(4)}%</span>
              </div>
              {/* <div style={{ display: 'flex', padding: '10px 0' }}>
                <span style={{ minWidth: 230 }}>Number of Quotes</span>
                <span>{numQuotes.toString()}</span>
              </div> */}
            </Box>
          </Section>
        </Grid>
        <Grid item xs={12}>
          <Section>
            <ChartContainer
              title={
                <Box className={classes.chartTitleBoxVolumeLiquidity}>
                  <Box className={clsx(classes.chartTitleBlock, classes.rightPadding)}>
                    <Typography className={classes.chartTitleFirst} variant={"h4"} component={"span"}>
                      {`Pool Volume`}
                    </Typography>
                    <Typography variant={"h3"} component={"span"}>
                      {`$${poolTotalVolume === 0 ? 0 : bnOrZero(poolTotalVolume).toFormat(2)}`}
                    </Typography>
                  </Box>
                  <Box className={classes.chartTitleBlock}>
                    <Typography className={classes.chartTitleFirst} variant={"h4"} component={"span"}>
                      {`Pool Liquidity`}
                    </Typography>
                    <Typography variant={"h3"} component={"span"}>
                      {`$${poolTotalLiquidity === 0 ? 0 : bnOrZero(poolTotalLiquidity).toFormat(2)}`}
                    </Typography>
                  </Box>
                </Box>
              }
              onSelectDate={handleDate}
              action={updateDateFiltersUtilisationChartData}
            >
              <IndividualPoolChart volume={poolVolume} liquidity={poolLiquidity} dateFilter={dateRange} setTotalLiquidity={setTotalPoolLiquidity} setTotalVolume={setTotalPoolVolume} price={quoteUSDBN} />
            </ChartContainer>
          </Section>
        </Grid>
        {/* <Grid item xs={12}>
          <PoolTransactions transactions={transactions} action={undefined} loadName={[]} title="Transactions"/>
        </Grid> */}
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  rowLeft: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    borderBottom: `1px solid ${switcheo.default.accent}`,
    padding: '10px 0',
    color: switcheo.default.accent,
    fontSize: 14
  },
  rowRight: {
    display: 'flex',
    padding: '10px 0',
    width: '100%',
    borderBottom: `1px solid ${switcheo.default.grey}`,
    color: switcheo.default.accent,
    fontSize: 14
  },
  boxLeft: {
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    height: '100%',
    backgroundColor: theme.palette.primary.light,
  },
  boxRight: {
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    height: '100%',
    paddingBottom: 40,
    backgroundColor: theme.palette.background.paper,
  },
  label: {
    fontWeight: 'bold',
  },
  notional: {
    color: switcheo.mono[400],
  },
  filter: {
    marginBottom: theme.spacing(2),
  },
  chartTitle: {
    display: 'block',
  },
  chartTitleFirst: {
    fontWeight: 'bold',
  },
  tooltip: {
    pointer: 'cursor',
    align: 'center',
  },
  chartTitleBox: {
    paddingBottom: theme.spacing(2),
  },
  chartTitleBlock: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  chartTitleBoxVolumeLiquidity: {
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  rightPadding: {
    paddingRight: theme.spacing(2),
  },
}))

export default PoolDetailsComponents
