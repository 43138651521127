import { CarbonSDK } from "carbon-js-sdk"

export function returnUrlNet(network?: string | null) {
  switch (network?.toLowerCase()) {
    case 'mainnet':
    case 'main':
    case CarbonSDK.Network.MainNet:
      return 'main'
    case 'testnet':
    case 'test':
    case CarbonSDK.Network.TestNet:
      return 'test'
    case 'devnet':
    case 'dev':
    case CarbonSDK.Network.DevNet:
      return 'dev'
    case 'localnet':
    case 'localhost':
    case 'local':
    case CarbonSDK.Network.LocalHost:
      return 'local'
    default:
      const isProduction = process.env.NODE_ENV === 'production'
      return isProduction ? 'main' : 'test'
  }
}

export function parseNet(network: CarbonSDK.Network) {
  switch (network) {
    case CarbonSDK.Network.MainNet:
      return 'main'
    case CarbonSDK.Network.TestNet:
      return 'test'
    case CarbonSDK.Network.DevNet:
      return 'dev'
    case CarbonSDK.Network.LocalHost:
      return 'local'
    default:
      throw new Error(`Failed to parse net: ${network}`)
  }
}

export const getNetworkLabel = (network: CarbonSDK.Network) => {
  switch (network) {
    case CarbonSDK.Network.MainNet: return 'Mainnet'
    case CarbonSDK.Network.TestNet: return 'Testnet'
    case CarbonSDK.Network.DevNet: return 'Devnet'
    case CarbonSDK.Network.LocalHost: return 'Localhost'
  }
}
