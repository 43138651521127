import { TxEvent } from '@cosmjs/tendermint-rpc/build/tendermint37'
import { CarbonSDK } from 'carbon-js-sdk'
import { setLatestBlock } from 'js/actions/app'
import { setTransactions } from 'js/actions/dashboard'
import { SimpleBlock } from 'js/models'
import { actions } from 'js/store'
import { Comparators } from 'js/utils'
import { eventChannel, EventChannel } from 'redux-saga'
import { fork, put, take } from 'redux-saga/effects'
import { selectState } from './helper'

export type WsEvent = {
  type: 'tx'
  result: TxEvent[]
  network: CarbonSDK.Network
} | {
  type: 'block'
  result: SimpleBlock[]
  network: CarbonSDK.Network
}

export let emitEvent: ((input: WsEvent) => void) | null = null

const socketChannel: EventChannel<WsEvent> = createEventChannel()
function createEventChannel(): EventChannel<WsEvent> {
  return eventChannel((onMsgCallback) => { // Use eventChannel to queue messages
    emitEvent = onMsgCallback
    return () => {
      emitEvent = null
    }
  })
}

export function getSocketChannel() {
  return socketChannel
}

function* listenForTmWsEvents() {
  while (true) {
    try {
      const event: WsEvent = yield take(getSocketChannel())
      const network: CarbonSDK.Network = yield selectState((state) => state.core.carbonSDK?.network)
      // NOTE: @cosmjs/tendermint-rpc WebSocketClient seems to have a bug
      // where removed subscriptions are re-subscribed when new subscriptions
      // are added, causing duplicate update events.

      if (event.network !== network) return;
      
      switch (event.type) {
        case 'tx':
          const txEvents: TxEvent[] = yield selectState((state) => state.dashboard.transactions)
          for (const newEvent of event.result) {
            if (!txEvents.find(event => Comparators.arrayEquals(newEvent.hash, event.hash)))
              txEvents.unshift(newEvent)
          }
          txEvents.sort((lhs, rhs) => rhs.height - lhs.height);
          if (txEvents.length > 5) txEvents.splice(5, txEvents.length - 5);
          yield put(setTransactions([...txEvents]));
          break
        case 'block':
          const blockEvents: SimpleBlock[] = yield selectState((state) => state.core.blocks)
          for (const newBlock of event.result) {
            if (!blockEvents.find(block => block.height === newBlock.height))
              blockEvents.unshift(...event.result);
          }
          blockEvents.sort((lhs, rhs) => rhs.height - lhs.height);
          // 101 blocks to calc block time for 100 blocks
          if (blockEvents.length > 101) blockEvents.splice(101, blockEvents.length - 101);
          if (blockEvents.length)
            yield put(setLatestBlock(blockEvents?.[0]))
          yield put(actions.Core.updateBlocks([...blockEvents]));
          break
      }
    } catch (err) {
      console.error(err)
    }

  }
}

export default function* webSocketSaga() {
  try {
    yield fork(listenForTmWsEvents)
  } catch (err) {
    console.error(err)
    throw new Error('Ws Saga Error')
  }
}
