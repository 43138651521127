import BigNumber from "bignumber.js"
import { Constants } from "js/constants"
import { RestModels } from "tradehub-api-js"

function formatDepositAmount(depositAmount: string): RestModels.TokenAmount[] {
  const initialDeposit = []
  const bnDepositAmount = new BigNumber(depositAmount)
  if (bnDepositAmount.gt(0)) {
    initialDeposit.push({
      denom: 'swth',
      amount: new BigNumber(depositAmount)
        .shiftedBy(Constants.Decimals.SWTH)
        .decimalPlaces(0)
        .toString(),
    })
  }
  return initialDeposit
}

export default formatDepositAmount
