import { QueryGetLiquidationEngineResponse } from 'carbon-js-sdk/lib/insights'
import { SimpleMap } from 'js/utils'
import { Action } from 'redux'
import { RestModels } from 'tradehub-api-js'

export enum EventsActionType {
  CLEAR = 'CLEAR_EVENTS',
  SET_LE_ORDERS = 'SET_LE_ORDERS',
  SET_EXPIRED_MARKETS = 'SET_EXPIRED_MARKETS',
  SET_LE_ACC_ORDERS = 'SET_LE_ACC_ORDERS',
  UPDATE_ORDERS_FILTERS = 'UPDATE_ORDERS_FILTERS',
  UPDATE_LE_ACC_ORDERS_FILTERS = 'UPDATE_LE_ACC_ORDERS_FILTERS',
}

export type EventsAction =
  | ClearAction
  | SetLEOrdersAction
  | SetExpiredMarketsAction
  | UpdateOrdersFiltersAction
  | SetLETradesAction

export interface ClearAction extends Action<EventsActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: EventsActionType.CLEAR,
  }
}

export interface SetLEOrdersAction
  extends Action<EventsActionType.SET_LE_ORDERS> {
  le: QueryGetLiquidationEngineResponse
}

export function setLEOrders(le: QueryGetLiquidationEngineResponse): SetLEOrdersAction {
  return {
    type: EventsActionType.SET_LE_ORDERS,
    le,
  }
}
export interface SetLETradesAction
  extends Action<EventsActionType.SET_LE_ACC_ORDERS> {
  leAccOrders: QueryGetLiquidationEngineResponse
}

export function setLeAccOrders(leAccOrders: QueryGetLiquidationEngineResponse): SetLETradesAction {
  return {
    type: EventsActionType.SET_LE_ACC_ORDERS,
    leAccOrders,
  }
}

export interface SetExpiredMarketsAction
  extends Action<EventsActionType.SET_EXPIRED_MARKETS> {
  settledMarkets: SimpleMap<RestModels.Market>
}

export function setExpiredMarkets(
  settledMarkets: SimpleMap<RestModels.Market>,
): SetExpiredMarketsAction {
  return {
    type: EventsActionType.SET_EXPIRED_MARKETS,
    settledMarkets,
  }
}

export interface UpdateOrdersFiltersAction
  extends Action<EventsActionType.UPDATE_ORDERS_FILTERS> {
  options: any
}

export function updateOrdersFilters(options: any): UpdateOrdersFiltersAction {
  return {
    type: EventsActionType.UPDATE_ORDERS_FILTERS,
    options,
  }
}

export interface UpdateLeAccOrdersFiltersAction
  extends Action<EventsActionType.UPDATE_LE_ACC_ORDERS_FILTERS> {
  options: any
}

export function updateLeAccOrdersFilters(options: any): UpdateLeAccOrdersFiltersAction {
  return {
    type: EventsActionType.UPDATE_LE_ACC_ORDERS_FILTERS,
    options,
  }
}
