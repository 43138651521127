import { PoolWeightObject, SetPoolRewardWeightsProposalFormState } from "js/models"
import store from "js/store"
import { escapeHtmlGo } from "js/utils"
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { CarbonTx } from "carbon-js-sdk"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { MsgSetRewardsWeights } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/tx'

export function setPoolRewardWeightsProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as SetPoolRewardWeightsProposalFormState

  const { title, summary, metadata, weights } = formState
  const rewardsWeightParams = weights.map(
    (weight: PoolWeightObject) => {
      return {
        poolId: weight.poolID,
        weight: weight.weight,
      }
    }
  )
  const formattedParams = {
    weights: rewardsWeightParams
  }

  const msg = MsgSetRewardsWeights.fromPartial({
    creator: authority,
    setRewardsWeightsParams: formattedParams
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    expedited: false,
    initialDeposit,
    proposer: proposer ?? "",
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgSetRewardsWeights,
        value: MsgSetRewardsWeights.encode(msg).finish()
      }
    ],
  }

  return value
}
