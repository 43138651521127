import { QueryGetFundingHistoryResponse } from 'carbon-js-sdk/lib/insights'
import { TableSection } from 'js/components'
import Page from 'js/components/Page'
import { TaskNames } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { SettledMarketRender, FundingTableRender } from './components'
import FundingTable from './components/FundingTable'
import { FundingHeaders, SettledHeaders } from './eventsConfig'
interface Props {
}
// tslint:disable:prettier
const Futures: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const { settledMarkets } = useRedux((state) => state.events)
  const { markets } = useRedux((state) => state.app)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [getFundingHistory] = useAsyncTask('getFundingHistory')
  const [fundingHistory, setFundingHistory] = useState<QueryGetFundingHistoryResponse>()
  const [selectedMarket, setSelectedMarket] = useState<string[]>([])

  const perpMarkets = Object.values(markets).filter((m) => m.marketType === "futures" && moment(m.expiryTime).unix() === 0).map((o) => o)
  useEffect(() => {
    getFundingHistory(async () => {
      if (!sdk) return
      const marketQuery = selectedMarket?.join(",")
      const fundingHistoryResult = await sdk.insights.FundingHistory({ market: marketQuery })
      setFundingHistory(fundingHistoryResult.result)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk, selectedMarket])

  const onMarketSelect = (market: string[]) => {
    setSelectedMarket(market)
  }

  return (
    <Page title="Futures">
      <FundingTable
        perpMarkets={perpMarkets}
        headerCells={FundingHeaders}
        models={fundingHistory}
        rowComponent={FundingTableRender}
        onFilter={onMarketSelect}
        loadName={"getFundingHistory"}
        title="Funding History"
        itemName="funding"
        pagination
      />
      {
        Object.keys(settledMarkets).length > 0 && (
          <TableSection
            headerCells={SettledHeaders}
            loadName={TaskNames.Events.SettledMarkets}
            models={Object.values(settledMarkets)}
            rowComponent={SettledMarketRender}
            title="Settled Markets"
            itemName="markets"
          />
        )
      }
    </Page>
  )
}

export default Futures
