import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, makeStyles, Theme, Typography } from '@material-ui/core'
import  { ReactComponent as CloseIcon } from '../../../assets/CloseIcon.svg'
import { Pagination, PaginationItem, PaginationRenderItemParams } from '@material-ui/lab'
import clsx from 'clsx'
import { TutorialKeys } from 'js/constants'
import { useRedux } from 'js/hooks'
import { TutorialProgress } from 'js/models'
import { actions } from 'js/store'
import { ValueOf } from 'js/utils'
import React, { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import StandardBtn from '../StandardBtn'

export type TutorialDialogProps = Omit<DialogProps, 'open'>
type Props = TutorialDialogProps & {
  tutorialKey: ValueOf<typeof TutorialKeys>
  tutorials: Tutorial[]
}

export type Tutorial = {
  TutorialContent: React.FunctionComponent
  title?: string
  skippable?: boolean
  nextButtonLabel?: string
  nextAction?: 'close' | (() => void)
}

const TutorialDialog: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> | null => {
  const { className, tutorialKey, tutorials, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const progress = useRedux<TutorialProgress | undefined>(
    (state) => state.tutorial[tutorialKey],
  )

  const getTutorial = () => {
    // step is 1 indexed
    if (!progress || progress.step < 1 || progress.step > tutorials.length) {
      return null
    }

    return tutorials[progress.step - 1]
  }

  const tutorial = getTutorial()

  if (!tutorial || !progress?.showing) {
    return null
  }

  const {
    TutorialContent,
    skippable,
    nextAction,
    nextButtonLabel,
    title,
  } = tutorial

  const onClose = () => {
    dispatch(actions.Tutorial.updateProgress(tutorialKey, {
      ...progress!,
      showing: false,
    }))
  }

  const onStep = (nextStep?: number) => {
    if (!nextStep && progress?.step === tutorials.length) {
      return onClose()
    }

    // steps are 1 indexed (starts from 1)
    // if step next from current progress
    if (!nextStep) {
      nextStep = (progress?.step || 1) + 1
    }

    // over stepped, ignore
    if (nextStep! > tutorials.length) {
      return
    }

    dispatch(actions.Tutorial.updateProgress(tutorialKey, {
      ...progress!,
      step: nextStep,
    }))
  }

  const onChangeStep = (_event: React.ChangeEvent<unknown>, value: number) => {
    onStep(value)
  }

  const onNext = () => {
    if (nextAction === 'close') {
      return onClose()
    }

    if (nextAction) {
      return nextAction()
    }

    onStep()
  }

  const renderItem = (item: PaginationRenderItemParams) => <PaginationItem {...item} page={undefined} />

  return (
    <Dialog
      open={progress?.showing || false}
      keepMounted
      {...rest}
      className={clsx(classes.root, className)}
    >
      <DialogTitle>
        <Typography className={classes.header} variant='body1'>Tutorial</Typography>
        <Typography variant='h2'>{title || `Step ${progress?.step}`}</Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {TutorialContent && (
          <TutorialContent />
        )}
      </DialogContent>
      <DialogActions
        className={classes.actions}
        classes={{ spacing: classes.actionRes }}
      >
        <StandardBtn
          className={classes.actionButton}
          onClick={onNext}
          variant='contained'
          color='primary'
        >
          {nextButtonLabel || 'Next'}
        </StandardBtn>
        {skippable && (
          <Button
            className={clsx(classes.actionButton, classes.skipButton)}
            onClick={onClose}
          >
            Skip Tutorial
          </Button>
        )}
        <Box flex={1} />
      </DialogActions>
      {tutorials.length > 1 && (
        <DialogContent className={classes.paginContainer}>
          <Pagination
            count={tutorials.length}
            page={progress?.step || 1}
            onChange={onChangeStep}
            hidePrevButton
            hideNextButton
            variant='outlined'
            size='small'
            className={classes.pagination}
            renderItem={renderItem}
          />
        </DialogContent>
      )}
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-root': {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
      width: '624px',
      '&>*:first-child': {
        paddingTop: theme.spacing(3),
      },
      '&>*:last-child': {
        paddingBottom: theme.spacing(3),
      },
    },
  },
  artwork: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '15rem',
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(1, 3),
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  actionButton: {
    minWidth: '9rem',
    marginRight: theme.spacing(1),
    marginBottom: '3rem',
    fontSize: '1rem',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    backgroundColor: theme.palette.text.primary,
    fontWeight: 700,
    color: theme.palette.text.secondary
  },
  actionRes: {
    [theme.breakpoints.only('xs')]: {
      '&> :not(:first-child)': {
        margin: theme.spacing(0.5, 0, 0, 0),
      },
    },
  },
  skipButton: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    textTransform: 'none',
    padding: '.7rem 1.2rem',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
    },
  },
  paginContainer: {
    padding: theme.spacing(1, 3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      height: '0.375rem',
      minWidth: '0.375rem',
      width: '0.375rem',
      padding: 0,
      margin: theme.spacing(0, 1),
      '&.Mui-selected': {
        backgroundColor: theme.palette.text.primary,
      },
    },
  },
  header: {
    fontWeight: 'bold',
  },
}))

export default TutorialDialog
