import BigNumber from 'bignumber.js'
import { Models } from 'carbon-js-sdk'
import { Record, RecordOf } from 'immutable'
import { RestModels, RestTypes } from 'tradehub-api-js'
import { ValidatorAction, ValidatorActionType } from '../actions/validator'

export interface ValidatorProps {
  profile?: RestModels.Profile
  validator?: Models.Staking.Validator
  delegations: Models.Staking.DelegationResponse[]
  blocks?: RestTypes.ResultsMinMax<RestModels.Block>
  selfbond: BigNumber
}

const makeState: Record.Factory<ValidatorProps> = Record<ValidatorProps>({
  profile: undefined,
  validator: undefined,
  delegations: [],
  blocks: undefined,
  selfbond: new BigNumber(0),
})
export type ValidatorState = RecordOf<ValidatorProps>

function validatorReducer(
  state: ValidatorState = makeState(),
  action: ValidatorAction,
): ValidatorState {
  switch (action.type) {
    case ValidatorActionType.CLEAR:
      return makeState()
    case ValidatorActionType.SET_PROFILE:
      return state.updateIn(['profile'], () => action.profile)
    case ValidatorActionType.SET_VALIDATOR:
      return state.updateIn(['validator'], () => action.validator)
    case ValidatorActionType.SET_DELEGATIONS:
      return state.updateIn(['delegations'], () => action.delegations)
    case ValidatorActionType.SET_VALIDATOR_BLOCKS:
      return state.updateIn(['blocks'], () => action.blocks)
    case ValidatorActionType.SET_SELFBOND_AMOUNT:
      return state.updateIn(['selfbond'], () => action.selfbond)
    default:
      return state
  }
}

export default validatorReducer
