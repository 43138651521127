import { Box, createStyles, makeStyles } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import React from 'react'

interface Props {
  title: string
  message: string
}

const Notification: React.FC<Props> = (props: Props) => {
  const { title, message } = props
  const classes = useStyles()

  return (
    <React.Fragment>
      <Box display="flex" className={classes.main}>
        <InfoOutlined className={classes.icon} color="secondary" />
        <div className={classes.textContainer}>
          <div className={classes.title}>{title}</div>
          <div className={classes.message}>{message}</div>
        </div>
      </Box>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    main: {
      wordBreak: 'break-word',
      color: theme.palette.primary.main,
    },
    icon: {
      fontSize: '1.8rem',
      paddingTop: theme.spacing(1),
      marginRight: '1rem',
    },
    title: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(1),
    },
    message: {
      fontWeight: 'unset',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.text.primary,
    },
  }),
)

export default Notification
