import { Plan } from 'carbon-js-sdk/lib/codec/cosmos/upgrade/v1beta1/upgrade'
import dayjs from 'dayjs'
import { SoftwareUpgradeProposalFormState } from 'js/models'
import store from 'js/store'
import { BIG_ZERO, escapeHtmlGo, parseNumber } from "js/utils"
import Long from 'long'
import { fieldTypes } from "../Helpers/types"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { MsgSoftwareUpgrade } from 'carbon-js-sdk/lib/codec/cosmos/upgrade/v1beta1/tx'
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { CarbonTx } from "carbon-js-sdk"

export const softwareUpgradeFormRequiredFields = ['name']

export function softwareUpgradeConfig() {
  return {
    name: {
      id: 'name',
      label: 'Name',
      type: fieldTypes.text,
      tooltip: 'Name of the plan',
    },
    height: {
      id: 'height',
      label: 'Height',
      type: fieldTypes.number,
      tooltip: 'When chain reaches proposed height, software upgrade will begin. Leave blank if using time.',
    },
    info: {
      id: 'info',
      label: 'Info',
      type: fieldTypes.text,
      tooltip: 'Metadata about the upgrade.',
    },
  }
}

export function softwareUpgradeValidator(formState: any): string[] {
  const errors = []
  const time = formState.time as string
  const height = formState.height as string
  if ((time.length > 0 && height.length > 0) || (time.length === 0 && height.length === 0)) {
    errors.push('Must set either time or height, but not both')
  }

  if (time.length > 0) {
    const date = dayjs(time, 'YYYY-MM-DDTHH:mm:ss[Z]', true)
    const now = dayjs()
    if (!date.isValid()) {
      errors.push('Proposed time must be valid and in ISO 8601 format')
    }
    if (date.isBefore(now)) {
      errors.push('Proposed time must be in the future')
    }
  }

  if (height.length > 0) {
    const heightNo = parseNumber(height, BIG_ZERO)
    if (!heightNo?.isInteger() || heightNo.isLessThan(0)) {
      errors.push('Height must be a non-negative integer')
    }
  }
  return errors
}

export function softwareUpgradeProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const formState = store.getState().governance.submitProposalFormState as SoftwareUpgradeProposalFormState

  const { title, summary, metadata, name, height, info } = formState!

  const plan: Plan = {
    name: name,
    height: new Long(parseInt(height)),
    info: info,
  }

  const msg = MsgSoftwareUpgrade.fromPartial({
    authority,
    plan,
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    initialDeposit,
    expedited: false,
    proposer: proposer ?? "",
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgSoftwareUpgrade,
        value: MsgSoftwareUpgrade.encode(msg).finish()
      }
    ],
  }

  return value
}
