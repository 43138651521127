import { Checkbox, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select as SelectMUI, TextField, Theme, Typography, makeStyles } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import React from 'react';
import Select from 'react-select';

import { switcheo } from 'js/theme/palettes/colors';

export function CreateComp(props: any) {
  const { handleSubmit, handleCheckbox, submitLoading, error, tokens, setting } = props
  const classes = useStyles();
  const config = setting.config
  const isLeague = config.type === 'league'
  const isVolume = config.type === 'volume'
  const submitSuccessful = error === 'successful'
  return (
    <div>
      <Typography variant={"h1"}>Settings</Typography>
      <Divider style={{
        margin: '1em 0em'
      }} />
      <Typography variant={"h2"} component="div" style={{
        marginBottom: '1em'
      }}>Comp Info</Typography>
      <div style={{
        display: 'grid',
        gap: '2em'
      }}>
        <div style={{
          display: 'flex',
          gap: '1em'
        }}>
          <TextField style={{
            maxWidth: '16.25em',
            width: '100%'
          }} required id="standard-required" label="Competition ID" defaultValue="tradingcomp" onChange={e => props.onChangeInput(e.target.value, "tradingComp")} />
          <TextField style={{
            maxWidth: '16.25em',
            width: '100%'
          }} id="standard-required" label="SubCompetition Id" defaultValue="" onChange={e => props.onChangeInput(e.target.value, "subCompetitionId")} />
          <TextField style={{
            maxWidth: '16.25em',
            width: '100%'
          }} disabled={!isLeague || isVolume} type="number" id="standard-required" label="Lending Quantity" value={config.lendingQuantity} onChange={e => props.onChangeInput(e.target.value, "config", "lendingQuantity")} />
          <FormControl style={{
            maxWidth: '16.25em',
            width: '100%'
          }}>
            <InputLabel id="demo-simple-select-label">Comp Type</InputLabel>
            <SelectMUI labelId="demo-simple-select-label" id="demo-simple-select" value={config.type} onChange={e => props.onChangeInput(e.target.value, "config", "type")}>
              <MenuItem value={"league"}>Demex Trading League</MenuItem>
              <MenuItem value={"pnl"}>PNL</MenuItem>
              <MenuItem value={"volume"}>Volume Tier</MenuItem>
            </SelectMUI>
          </FormControl>
          <TextField style={{
            maxWidth: '16.25em',
            width: '100%'
          }} disabled={isLeague || isVolume} type="number" required id="standard-required" label="min Balance" value={config.minBalance} onChange={e => props.onChangeInput(e.target.value, "config", "minBalance")} />
        </div>
        <div style={{
          display: 'flex',
          gap: '1em'
        }}>
          <TextField id="datetime-local" label="Start" type="datetime-local" value={setting.start} // className={classes.textField}
            InputLabelProps={{
              shrink: true
            }} onChange={e => props.onChangeInput(e.target.value, "start")} />
          <TextField id="datetime-local" label="End" type="datetime-local" value={setting.end} // className={classes.textField}
            InputLabelProps={{
              shrink: true
            }} onChange={e => props.onChangeInput(e.target.value, "end")} />
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1em'
        }}>
          <TextField style={{
            maxWidth: '16.25em',
            // width: '100%'
          }} disabled={!isLeague} type="number" required id="standard-required" label="Spot Multiplier" value={config.spotsMultiplier} onChange={e => props.onChangeInput(e.target.value, "config", "spotsMultiplier")} />
          <TextField style={{
            maxWidth: '16.25em',
            // width: '100%'
          }} disabled={!isLeague} type="number" required id="standard-required" label="Der Multiplier" value={config.derMultiplier} onChange={e => props.onChangeInput(e.target.value, "config", "derMultiplier")} />
          <TextField style={{
            maxWidth: '16.25em',
            // width: '100%'
          }} disabled={!isLeague} type="number" required id="standard-required" label="Promo Multiplier" value={config.promoMultiplier} onChange={e => props.onChangeInput(e.target.value, "config", "promoMultiplier")} />
          <TextField style={{
            maxWidth: '16.25em',
            width: '100%'
          }} disabled={!isLeague} type="number" required id="standard-required" label="$X Vol per Point" value={config.volPerPoint} onChange={e => props.onChangeInput(e.target.value, "config", "volPerPoint")} />
          <div className={classes.allowDecCheckBox} style={{ marginTop: '12px' }}>
            <Typography>Allow Decimals</Typography>
            <Checkbox
              className={classes.checkBox}
              checked={config?.allowDecimals}
              onChange={handleCheckbox}
            />
          </div>
        </div>
        <div style={{}}>
          <Typography variant={"h2"} component="div" style={{
            marginBottom: '0.5em'
          }}>Promo Markets</Typography>
          <Select isDisabled={!isLeague} value={!isLeague ? [] : props.promoMarketOptions} isMulti name="colors" options={props.options} className={classes.select} onChange={e => props.handleSelect(e, "promoMarkets")} />
        </div>
        <div style={{}}>
          <Typography variant={"h2"} component="div" style={{
            marginBottom: '0.5em'
          }}>Markets</Typography>
          <Select isDisabled={isLeague} value={isLeague ? [] : props.marketOptions} isMulti name="colors" options={props.options} className={classes.select} onChange={e => props.handleSelect(e, "markets")} />
        </div>
        <div style={{}}>
          <Typography variant={"h2"} component="div" style={{
            marginBottom: '0.5em'
          }}>Lending</Typography>
          <Select isDisabled={!isLeague || isVolume} isClearable value={config.lending} isMulti name="colors" options={tokens} className={classes.select} onChange={e => props.handleSelect(e, "lending")} />
        </div>
        <div style={{}}>
          <Typography variant={"h2"} component="div" style={{
            marginBottom: '0.5em'
          }}>Check for trades</Typography>
          <TextField style={{
            maxWidth: '11.25em',
            width: '100%',
            marginBottom: '0.5em',
          }} disabled={isLeague || isVolume} type="number" required id="standard-required" label="Number of trades req." value={config.preTradeMarketCount} onChange={e => props.onChangeInput(e.target.value, "config", "preTradeMarketCount")} />
          <TextField style={{
            maxWidth: '11.25em',
            width: '100%',
            marginBottom: '0.5em',
            marginLeft: '1em'
          }} disabled={isLeague || isVolume} type="number" required id="standard-required" label="min Volume per trade" value={config.preTradeMarketVolume} onChange={e => props.onChangeInput(e.target.value, "config", "preTradeMarketVolume")} />
          <Select isDisabled={isLeague || isVolume} value={props.tradingOptions} isMulti name="colors" options={props.options} className={classes.select} onChange={e => props.handleSelect(e, "preTradeMarket")} />
        </div>
        <div id="btn-grps">
          <div className={classes.CTA} onClick={() => submitSuccessful ? '' : handleSubmit()}>{submitLoading ? <CircularProgress /> : submitSuccessful ? <CheckCircleOutline style={{ verticalAlign: 'sub' }} /> : 'Create'}</div>
          {error && <Typography style={{ color: submitSuccessful ? switcheo.success.primary : switcheo.red }}>{submitSuccessful ? '' : `Error: ${error}`} </Typography>}
        </div>
      </div>
    </div>);
}

export const useStyles = makeStyles((theme: Theme) => ({
  select: {
    background: 'lightcyan'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    background: 'lightcyan',
    borderRadius: 14
  },
  innerTabBtn: {
    display: 'flex',
    alignItems: 'center'
  },
  tabDivContainer: {
    minHeight: '20rem',
    display: 'grid',
    padding: '1em 0em'
  },
  menuButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em'
  },
  menuButton: {
    padding: '1em 2em',
    backgroundColor: switcheo.default.accent,
    color: switcheo.text.white,
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1.3rem',
    display: 'flex',
    alignItems: 'center'
  },
  CTA: {
    width: '8em',
    height: '2.5em',
    fontSize: '16px',
    textAlign: 'center',
    lineHeight: '2.5em',
    color: 'rgba(255,255,255,0.9)',
    borderRadius: '10px',
    background: 'linear-gradient(-45deg, #c08bfb, #1300b1, #338AFF, #3CF0C5)',
    backgroundSize: '600%',
    animation: '$anime 16s linear infinite',
    marginTop: '1em',
    cursor: 'pointer'

  },
  "@keyframes anime": {
    "0%": {
      backgroundPosition: '0% 50%'
    },
    "50%": {
      backgroundPosition: '100% 50%'
    },
    "100%": {
      backgroundPosition: '0% 50%'
    }
  },
  allowDecCheckBox: {
    display: 'flex',
    gap: '0.3rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkBox: {
    color: theme.palette.text.primary,
  },
}))