import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import chartPluginCrosshair from 'chartjs-plugin-crosshair';
import { formatPositionHistoryChartData } from 'js/helpers';
import { switcheo } from 'js/theme/palettes/colors';
import { hexToRgba } from 'js/utils';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from "react";
import { Chart } from 'react-chartjs-2';


type Props = {
  data: any
  createdTime: Date | undefined
}

const PositionHistoryChart: React.FunctionComponent<Props> = (
  props: Props
): ReactElement<Props> => {
  const { data, createdTime } = props
  const [chartData, setChartData] = useState<any>(undefined)
  const [chartLabels, setChartLabels] = useState<any>(undefined)

  ChartJS.register(...registerables, chartPluginCrosshair)
  useEffect(() => {
    if (data) {
      const [labels, sortedData] = formatPositionHistoryChartData(data)
      labels.unshift(new Date(createdTime ?? labels[0]))
      sortedData.pnl.unshift(0)
      setChartLabels(labels)
      setChartData(sortedData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const firstDate = moment(chartLabels?.[0])
  const lastDate = moment(chartLabels?.[(chartLabels?.length ?? 1) - 1])
  const positionDurationInDays = lastDate.diff(firstDate, 'hour')

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    innerHeight: '10rem',
    interaction: {
      intersect: true,
    },
    scales: {
      x: {
        type: 'time' as 'time',
        format: 'LL HH:mm',
        time: {
          unit: positionDurationInDays > 48 ? 'day' as 'day' : 'hour' as 'hour',
          stepSize: positionDurationInDays > 48 ? 1 : 1,
          displayFormats: {
            day: 'DD MMM',
            month: 'MMM YY',
            minute: 'HH:mm'
          },
          tooltipFormat: "ll HH:mm",
        },
        ticks: {
          autoSkip: false,
          beginAtZero: true,
          alwaysShowLastTick: true,
          display: true,
          maxTicksLimit: 10,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
        }
      },
      y: {
        ticks: {
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: true,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
        }
      }
    },
    plugins: {
      TimeScale: {
        x: {
          type: 'time'
        }
      },
      tooltip: {
        mode: 'index' as 'index',
        intersect: false,
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 10,
        boxPadding: 2,
      },
      crosshair: false
    },
    crosshair: {
      line: {
        color: switcheo.text.grey,
        width: 2,
        dashPattern: [5, 5],
      }
    },
    legend: {
      display: false
    },
  };

  const datasets = [
    {
      label: 'PNL',
      fill: false,
      lineTension: 0.2,
      backgroundColor: switcheo.chart[0],
      borderColor: hexToRgba(switcheo.chart[0], 0.8),
      borderDash: [],
      borderWidth: 1.5,
      borderDashOffset: 0.0,
      pointBorderColor: switcheo.chart[0],
      pointBackgroundColor: switcheo.chart[0],
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: switcheo.chart[0],
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      data: chartData?.pnl ?? [],
      segment: {
        borderColor: (ctx: any) => {
          const price = ctx.chart.data.datasets[0]?.data[ctx.p0DataIndex]
          return price < 0 ? switcheo.danger.primary : price === 0 ? "Grey" : switcheo.success.primary
        }
      }
    }
  ]

  const finalData = {
    labels: chartLabels ?? [],
    datasets,
  };

  return (
    <div>
      <Chart type='line' options={options} data={finalData} />
    </div>
  )
}

export default PositionHistoryChart