import { bnOrZero } from "carbon-js-sdk/lib/util/number";
import { CommunityPoolSpendProposalFormState } from "js/models";
import store from "js/store";
import { escapeHtmlGo } from "js/utils";
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { CarbonTx } from "carbon-js-sdk"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { MsgCommunityPoolSpend } from "carbon-js-sdk/lib/codec/cosmos/distribution/v1beta1/tx";

export function communityPoolSpendProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const formState = store.getState().governance.submitProposalFormState as CommunityPoolSpendProposalFormState
  const sdk = store.getState().core.carbonSDK
  const { title, summary, metadata, denom, amount, recipient } = formState
  const tokenDecimals = sdk?.token.getDecimals(denom) ?? 0
  const formattedAmount = bnOrZero(amount).shiftedBy(tokenDecimals).toString()

  const msg = MsgCommunityPoolSpend.fromPartial({
    authority,
    recipient,
    amount: [{ denom, amount: formattedAmount }],
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    initialDeposit,
    expedited: false,
    proposer: proposer ?? "",
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgCommunityPoolSpend,
        value: MsgCommunityPoolSpend.encode(msg).finish()
      }
    ],
  }

  return value
}