import { Box, makeStyles, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import { Coin } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/bank/query'
import clsx from 'clsx'
import { CellLink, DataLoadSegment, ListTable, PaginationByPage, Section, TableEmptyState, TooltipHint } from 'js/components'
import { shorten, shortenHash } from 'js/helpers'
import { useTaskSubscriber } from 'js/hooks'
import { RootState } from 'js/store'
import { bnOrZero } from 'js/utils'
import moment from 'moment'
import React, { ReactElement, ReactNode } from 'react'
import { useSelector } from 'react-redux'

// tslint:disable:jsx-no-lambda
interface Props {
  title?: string
  filters?: any
  filterStatus?: boolean
  action: any
  loadName: string[]
  sectionClass?: string
  rightMenu?: React.ReactNode
  resetPage?: boolean
  setPageReset?: any
  sdk?: CarbonSDK
  address: string
}

const InternalTransfer: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { sectionClass, title, filterStatus = false, action, loadName, rightMenu, sdk, address } = props
  const classes = useStyles()

  const [page, setPage] = React.useState(1)
  const [loading] = useTaskSubscriber(...loadName)
  const transactions = useSelector((state: RootState) => state.account.internalTransfer)
  const transfers = transactions.data ?? []

  return (
    <Section className={clsx(classes.root, sectionClass)} title={title} rightMenu={rightMenu}>
      <DataLoadSegment loading={loading} filter={filterStatus}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Block</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell>Sender</TableCell>
              <TableCell>Receiver</TableCell>
              <TableCell>Token</TableCell>
              <TableCell style={{ textAlign: 'left' }} className={classes.tableLastChild}>Memo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transfers.map((transfer, index) => renderInternalTransfer(transfer, address))}
          </TableBody>
        </ListTable>

        {transfers.length > 0 && (
          <Box marginTop={3}>
            <PaginationByPage
              response={transactions}
              action={action}
              page={page}
              setPage={setPage}
              paginationLimit={5}
            />
          </Box>
        )}
        {transfers.length === 0 && (
          <TableEmptyState itemName="Internal Transfer" />
        )}
      </DataLoadSegment>
    </Section>
  )


  function renderInternalTransfer(
    transfers: any,
    address: string
  ): ReactNode {
    const { sender, receiver, transactionHash, coins, transactionMemo, transactionBlockHeight, transactionBlockTime } = transfers
    const isMemoLong = transactionMemo?.length >= 20
    const isSenderYou = address === sender
    return (
      <TableRow key={transactionHash} hover>
        <TableCell>
          <div className={classes.blockTime}>
            <div>
              <CellLink to={`/block/${transactionBlockHeight?.toNumber()}`}>
                {transactionBlockHeight?.toNumber()}
              </CellLink>
            </div>
            <div>
              <Typography style={{ color: 'rgb(140 142 143)', fontSize: '0.78em' }}>
                {moment(transactionBlockTime).format('YYYY-MM-DD HH:mm:ss')}
              </Typography>
            </div>
          </div>
        </TableCell>
        <TableCell align="left">
          <CellLink to={`/transaction/${transactionHash}`}>
            {shortenHash(transactionHash, 4)}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${sender}`} style={isSenderYou ? { color: 'rgb(140 142 143)' } : {}}>
            {isSenderYou ? 'You' : shortenHash(sender, 8)}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${receiver}`} style={isSenderYou ? {} : { color: 'rgb(140 142 143)' }}>
            {isSenderYou ? shortenHash(receiver, 8) : 'You'}
          </CellLink>
        </TableCell>
        <TableCell>
          {coins?.map((o: Coin) => {
            const decimals = sdk?.token.getDecimals(o.denom)
            const symbol = sdk?.token.getSymbol(o.denom)
            const amountBN = bnOrZero(o.amount).shiftedBy(-(decimals ?? 0)).toNumber()
            return (
              <div>
                <div>
                  <span style={{ marginRight: '0.2em' }}>{amountBN?.toString(10)?.toLocaleString()}</span>
                  <CellLink to={`/token/${encodeURIComponent(o.denom)}`}>
                    <Typography variant={"body2"} component="span" className={classes.symbol}>
                      {symbol}
                    </Typography>
                  </CellLink>
                </div>
              </div>
            )
          })}
        </TableCell>
        <TableCell style={{ textAlign: 'left' }}>
          {
            transactionMemo ?
              isMemoLong ?
                <>
                  <span>
                    {shorten(transactionMemo, 20)}
                  </span>
                  <TooltipHint title={transactionMemo} />
                </>
                : transactionMemo
              : <Typography style={{ color: 'rgb(140 142 143)' }}>
                Empty
              </Typography>
          }
        </TableCell>
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  symbol: {
    fontWeight: 700
  },
  tableLastChild: {
    textAlign: 'left'
  },
  blockTime: {
    width: 'max-content'
  },
  root: {
    padding: theme.spacing(1, 3, 3),
  },
  msgTypes: {
    marginLeft: theme.spacing(0.5),
    display: 'inline-flex',
  },
  toolTip: {
    fontSize: '0.8rem',
    textAlign: 'left',
  },
  msgCount: {
    marginLeft: theme.spacing(0.5),
    fontSize: '0.8rem',
    fontWeight: 'bold',
    height: '1.5em',
    width: '1.5em',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '2.5em',
    background: theme.palette.primary.light
  },

}))

export default InternalTransfer
