import { Box, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { ReactComponent as CdpBadge } from 'assets/CdpBadge.svg'
import { ReactComponent as GroupTokenIndicator } from 'assets/GroupTokenIndicator.svg'

import { TokenIcon } from 'js/components'
import { TextComponent } from 'js/components/Text'
import { switcheo } from 'js/theme/palettes/colors'

interface Props {
  symbolA: any
  symbolB: any
  weightA: any
  weightB: any
  chainA: any
  chainB: any
  isCdpTokenA: boolean,
  isCdpTokenB: boolean,
  poolId?: any
}

const LiquidityPoolOverview: React.FC<Props> = (
  props: Props,
): React.ReactElement<Props> => {
  const { symbolA, symbolB, weightA, weightB, chainA, chainB, isCdpTokenA, isCdpTokenB, poolId } = props
  const classes = useStyles()

  const isGroupedTokenA = symbolA === 'USD' && chainA === 'Carbon' ? true : false
  const isGroupedTokenB = symbolB === 'USD' && chainB === 'Carbon' ? true : false

  return (
    <Box style={{ display: 'flex', alignItems: 'center', minWidth: 300 }}>
      <Box className={poolId ? classes.iconContainer_big : classes.iconContainer}>
        <Box className={classes.iconA}>
          <TokenIcon className={clsx(poolId ? classes.icon_big : classes.icon_small)} tokenSymbol={symbolA} />
          {isCdpTokenA && (<CdpBadge className={classes.cdpBadge} />)}
        </Box>
        <Box className={classes.iconB} >
          <TokenIcon className={clsx(poolId ? classes.icon_big : classes.icon_small)} tokenSymbol={symbolB} />
          {isCdpTokenB && (<CdpBadge className={classes.cdpBadge} />)}
        </Box>
      </Box>
      <Box>
        {poolId ? <div className={classes.poolId}>{`#${poolId}`}</div> : undefined}
        <div style={{ display: 'flex' }}>
          <Box style={poolId ? undefined : { width: '106px' }}>
            <div className={classes.poolNameContainer}>
              <TextComponent className={classes.poolName}>{`${weightA}%`}</TextComponent>
              <TextComponent
                className={classes.poolName}
                svgIcon={isGroupedTokenA ? GroupTokenIndicator : undefined}
                showIcon={isGroupedTokenA}
              >
                {`${symbolA}`}
              </TextComponent>
            </div>
            <div className={classes.chainCard}>{chainA}</div>
          </Box>
          <Box className={poolId ? classes.slashLine : classes.verticalLine}>
            {poolId ? "/" : ""}
          </Box>
          <Box>
            <div className={classes.poolNameContainer}>
              <TextComponent className={classes.poolName}>{`${weightB}%`}</TextComponent>
              <TextComponent
                className={classes.poolName}
                svgIcon={isGroupedTokenB ? GroupTokenIndicator : undefined}
                showIcon={isGroupedTokenB}
              >
                {`${symbolB}`}
              </TextComponent>
            </div>
            <div className={classes.chainCard}>{chainB}</div>
          </Box>
        </div>
      </Box >
    </Box >
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  icon_big: {
    width: '44.53px',
    height: '44.8px',
  },
  icon_small: {
    width: '32px',
    height: '32px',
  },
  iconA: {
    bottom: 0,
    left: 0,
    zIndex: 1,
    position: 'absolute'
  },
  iconB: {
    right: 0,
    top: 0,
    position: 'absolute'
  },
  iconContainer_big: {
    height: 56,
    width: 70.16,
    margin: '10px 20px 0 0',
    position: 'relative'
  },
  iconContainer: {
    height: 43,
    width: 53,
    marginRight: 20,
    position: 'relative'
  },
  poolId: {
    color: switcheo.text.grey,
    fontSize: 12,
    marginBottom: 4
  },
  poolNameContainer: {
    display: 'flex',
    gap: '0.3em',
  },
  poolName: {
    fontSize: 18,
    color: switcheo.default.accent,
    fontWeight: 700
  },
  chainCard: {
    color: switcheo.text.grey,
    borderRadius: 4,
    border: `1px solid ${switcheo.text.grey}`,
    padding: '0.5px 3px',
    marginTop: 6,
    width: 'fit-content',
    fontSize: 12,
    fontWeight: 'normal'
  },
  slashLine: {
    verticalAlign: 'baseline',
    fontSize: 16,
    height: '100%',
    margin: '0 8px',
    fontWeight: 700
  },
  verticalLine: {
    marginTop: 10,
    borderLeft: `1px solid ${switcheo.default.grey}`,
    height: 26,
    marginRight: 16
  },
  cdpBadge: {
    position: 'absolute',
    right: theme.spacing(-0.5),
    bottom: theme.spacing(-0.5),
    width: '60%',
    height: '60%',
  },
}))

export default LiquidityPoolOverview
