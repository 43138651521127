import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { ExtendedPool } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/query'
import { SimpleMap } from 'carbon-js-sdk/lib/util/type'
import clsx from 'clsx'
import { updateDateFiltersTotalLiquidityChartData, updateDateFiltersUtilisationChartData, updateDateFiltersVolumeLiquidityChartData } from 'js/actions/liquidityPools'
import { ChartContainer, Page, TooltipHint } from 'js/components'
import { CARBON_GENESIS_BLOCKTIME, TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import { adjustHuman, BIG_ZERO, bnOrZero, DailyLiquiditySum } from 'js/utils'
import moment from 'moment'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import AllPoolsTable from './components/AllPoolsTable/LiquidityPoolsTable'
import TotalLiquidityChart from './components/Charts/TotalLiquidityChart'
import UtilisationChart from './components/Charts/UtilisationChart'
import VolumeLiquidityChart from './components/Charts/VolumeLiquidityChart'
import CommitmentCurve from './components/Curves/CommitmentCurve'
import RewardCurve from './components/Curves/RewardCurve'

interface Props { }

const LiquidityPools: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const { pools, poolsTotalLiquidityChartData, poolsVolumeLiquidityChartData, rewardCurve, commitmentCurve } = useRedux((state) => state.pools)
  const sdk = useRedux((state) => state.core.carbonSDK)
  let total = BIG_ZERO
  const [poolNameMap, setPoolNameMap] = useState<SimpleMap<string>>({})
  const [currentIndex, setCurrentIndex] = useState<number>(poolsTotalLiquidityChartData?.length - 1 ?? 0)
  const startDate = moment(CARBON_GENESIS_BLOCKTIME)
  const endDate = moment()

  const [dateTotalLiquidityRange, setTotalLiquidityDateRange] = React.useState<any>({
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  });
  const [dateVolumeLiquidityRange, setVolumeLiquidityDateRange] = React.useState<any>({
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  });
  const [dateLiquidityUtilisationRange, setLiquidityUtilisationDateRange] = React.useState<any>({
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  });

  // for total liquidity chart
  const [totalLiquiditySums, setTotalLiquiditySums] = useState<DailyLiquiditySum[]>([]);
  const [dailyTotalLiquidity, setDailyTotalLiquidity] = useState(0);

  // for volume liquidity chart
  const [liquiditySumsVLChart, setLiquiditySumsVLChart] = useState<DailyLiquiditySum[]>([]);
  const [dailyTotalLiquidityVLChart, setDailyTotalLiquidityVLChart] = useState(0);
  const [dailyTotalVolume, setDailyTotalVolume] = useState(0);

  pools.forEach((p: ExtendedPool) =>
    total = total.plus(new BigNumber(p.rewardsWeight)),
  )

  useEffect(() => {
    if (currentIndex < 0 && poolsTotalLiquidityChartData?.length > 0) setCurrentIndex(poolsTotalLiquidityChartData?.length - 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolsTotalLiquidityChartData?.length])

  const setCurrentView = (index: number) => {
    if (index !== currentIndex) {
      setCurrentIndex(index)
      const totalVolume = poolsTotalLiquidityChartData[index]?.pools?.reduce((prev: any, curr: any) => Number(prev) + Number(curr?.amountValue), 0)
      setDailyTotalLiquidity(totalVolume)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setCurrentViewCb = useCallback(setCurrentView, [currentIndex])

  useEffect(() => {
    if (pools.length > 0) {
      pools.forEach((p: ExtendedPool) => {
        if (p.pool) {
          const { id, denomA, denomB, weightA, weightB } = p.pool
          const adjustedWeightA = adjustHuman(weightA ?? '').shiftedBy(2)
          const adjustedWeightB = adjustHuman(weightB ?? '').shiftedBy(2)
          const commonDenomA = sdk?.token.getTokenName(denomA)
          const commonDenomB = sdk?.token.getTokenName(denomB)
          setPoolNameMap((poolNameMap) => ({ ...poolNameMap, [id.toNumber()]: `${adjustedWeightA}% ${commonDenomA} / ${adjustedWeightB}% ${commonDenomB}` }))
        }
      }
      )
    }
  }, [sdk, pools])

  const setTotalLiquidityData = useCallback((data: any) => {
    setTotalLiquiditySums(data);
  }, []);

  const setTotalLiquidityDataVLChart = useCallback((data: any) => {
    setLiquiditySumsVLChart(data);
  }, []);

  const handleTotalLiquidityDate = useCallback((data: any) => {
    setTotalLiquidityDateRange(data);
  }, []);

  const handleLiquidityVolumeDate = useCallback((data: any) => {
    setVolumeLiquidityDateRange(data)
  }, []);

  const handleLiquidityUtilizationDate = useCallback((data: any) => {
    setLiquidityUtilisationDateRange(data)
  }, []);

  useEffect(() => {
    if (totalLiquiditySums.length > 0) {
      setDailyTotalLiquidity(totalLiquiditySums.slice(-1)[0].y)
    }
    if (liquiditySumsVLChart.length > 0) {
      setDailyTotalLiquidityVLChart(liquiditySumsVLChart.slice(-1)[0].y)
    }
  }, [totalLiquiditySums, liquiditySumsVLChart])

  return (
    <>
      <Page title="Liquidity Pools">
        <ChartContainer
          taskNames={[TaskNames.Pools.TotalLiquidityChart]}
          title={
            <Box className={classes.chartTitleBox}>
              <Box className={classes.chartTitle}>
                <Typography className={classes.chartTitleFirst} variant={"h4"} component={"span"}>
                  {`Total Liquidity in All Pools`} <TooltipHint title={"Liquidity of popular markets on Carbon"} className={classes.tooltip} />
                </Typography>
              </Box>
              <Typography variant={"h3"} component={"span"}>
                {`$${dailyTotalLiquidity === 0 ? 0 : bnOrZero(dailyTotalLiquidity).toFormat(2)}`}
              </Typography>
            </Box>
          }
          onSelectDate={handleTotalLiquidityDate}
          action={updateDateFiltersTotalLiquidityChartData}
        >
          <TotalLiquidityChart callback={setCurrentViewCb} data={poolsTotalLiquidityChartData} poolNameMap={poolNameMap} setTotalLiquidity={setTotalLiquidityData} dateFilter={dateTotalLiquidityRange} />
        </ChartContainer>
        <ChartContainer
          taskNames={[TaskNames.Pools.VolumeLiquidityChart]}
          title={
            <Box className={classes.chartTitleBoxVolumeLiquidity}>
              <Box className={clsx(classes.chartTitleBlock, classes.rightPadding)}>
                <Typography className={classes.chartTitleFirst} variant={"h4"} component={"span"}>
                  {`Total Pool Volume`}
                </Typography>
                <Typography variant={"h3"} component={"span"}>
                  {`$${dailyTotalVolume === 0 ? 0 : bnOrZero(dailyTotalVolume).toFormat(2)}`}
                </Typography>
              </Box>
              <Box className={classes.chartTitleBlock}>
                <Typography className={classes.chartTitleFirst} variant={"h4"} component={"span"}>
                  {`Total Pool Liquidity`}
                </Typography>
                <Typography variant={"h3"} component={"span"}>
                  {`$${dailyTotalLiquidityVLChart === 0 ? 0 : bnOrZero(dailyTotalLiquidityVLChart + 'e-6').toFormat(2)}m`}
                </Typography>
              </Box>
            </Box>
          }
          onSelectDate={handleLiquidityVolumeDate}
          action={updateDateFiltersVolumeLiquidityChartData}
        >
          <VolumeLiquidityChart volume={poolsVolumeLiquidityChartData} liquidity={poolsTotalLiquidityChartData} setTotalLiquidity={setTotalLiquidityDataVLChart} setTotalVolume={setDailyTotalVolume} dateFilter={dateVolumeLiquidityRange} />
        </ChartContainer>
        <ChartContainer
          taskNames={[TaskNames.Pools.DateFilterUtilisation]}
          title={
            <Box className={classes.chartTitleBox}>
              <Box className={classes.chartTitle}>
                <Typography className={classes.chartTitleFirst} variant={"h4"} component={"span"}>
                  {`Liquidity Utilisation Rate`} <TooltipHint title={"Liquidity utilisation rate for total trading volume each individual market (%)"} className={classes.tooltip} />
                </Typography>
              </Box>
            </Box>
          }
          onSelectDate={handleLiquidityUtilizationDate}
          action={updateDateFiltersUtilisationChartData}
        >
          <UtilisationChart volume={poolsVolumeLiquidityChartData} liquidity={poolsTotalLiquidityChartData} dateFilter={dateLiquidityUtilisationRange} />
        </ChartContainer>
      </Page>
      <br />
      <AllPoolsTable />
      <Grid container spacing={3} style={{ marginTop: '12px' }}>
        {rewardCurve && (
          <Grid item xs={12} md={6}>
            <RewardCurve params={rewardCurve} />
          </Grid>
        )}
        {commitmentCurve && (
          <Grid item xs={12} md={6}>
            <CommitmentCurve params={commitmentCurve} />
          </Grid>
        )}
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  filter: {
    marginBottom: theme.spacing(2),
  },
  chartTitle: {
    display: 'block',
  },
  chartTitleFirst: {
    fontWeight: 'bold',
  },
  tooltip: {
    pointer: 'cursor',
    align: 'center',
  },
  chartTitleBox: {
    paddingBottom: theme.spacing(2),
  },
  chartTitleBlock: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  chartTitleBoxVolumeLiquidity: {
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  rightPadding: {
    paddingRight: theme.spacing(2),
  },
}))

export default LiquidityPools
