import { NewBlockEvent, TxEvent } from '@cosmjs/tendermint-rpc/build/tendermint37'
import BigNumber from 'bignumber.js'
import { ActiveUsersChartDataResponse, TotalUsersChartDataResponse } from 'js/reducers/dashboard'
import { Action } from 'redux'
import { RewardsDistributedResponse } from 'tradehub-api-js'

export enum DashboardActionType {
  CLEAR = 'CLEAR_DASHBOARD',
  SET_BLOCK_TIME = 'SET_BLOCK_TIME',
  RECEIVE_TRANSACTION = 'RECEIVE_DASHBOARD_TRANSACTION',
  SET_BLOCKS = 'SET_DASHBOARD_BLOCKS',
  SET_TRANSACTIONS = 'SET_DASHBOARD_TRANSACTIONS',
  SET_INSURANCE_BALANCES = 'SET_INSURANCE_BALANCES',
  SET_OI = 'SET_OI',
  SET_VOLUME = 'SET_VOLUME',
  SET_AVG_REWARD = 'SET_AVG_REWARD',
  SET_ACTIVE_WALLETS = 'SET_ACTIVE_WALLETS',
  SET_REWARDS_DISTRIBUTED = 'SET_REWARDS_DISTRIBUTED',
  SET_TOTAL_USERS_CHART = 'SET_TOTAL_USERS_CHART',
  SET_ACTIVE_USERS_CHART = 'SET_ACTIVE_USERS_CHART',
  SET_DISTRIBUTION_REWARDS_CHART = 'SET_DISTRIBUTION_REWARDS_CHART',
  UPDATE_DATE_FILTERS_TOTAL_USERS_CHART = 'UPDATE_DATE_FILTERS_TOTAL_USERS_CHART',
  UPDATE_DATE_FILTERS_ACTIVE_USERS_CHART = 'UPDATE_DATE_FILTERS_ACTIVE_USERS_CHART',
  UPDATE_DATE_FILTERS_DISTRIBUTION_REWARDS_CHART = 'UPDATE_DATE_FILTERS_DISTRIBUTION_REWARDS_CHART',
}

export type DashboardAction =
  | ClearAction
  | SetBlockTimeAction
  | SetBlocksAction
  | SetTransactionsAction
  | SetInsuranceBalancesAction
  | SetOIAction
  | SetVolumeAction
  | SetAvgRewardAction
  | SetActiveWalletAction
  | SetRewardsDistributedAction
  | SetTotalUsersChartAction
  | SetActiveUsersChartAction
  | SetDistributionRewardsChartAction
  | UpdateDateFiltersTotalUsersChartAction
  | UpdateDateFiltersActiveUsersChartAction
  | UpdateDateFiltersDistributionRewardsChartAction

export interface ClearAction extends Action<DashboardActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: DashboardActionType.CLEAR,
  }
}

export interface SetBlockTimeAction
  extends Action<DashboardActionType.SET_BLOCK_TIME> {
  blockTime: number
}
export function setBlockTime(blockTime: number): SetBlockTimeAction {
  return {
    type: DashboardActionType.SET_BLOCK_TIME,
    blockTime,
  }
}

export interface SetAvgRewardAction
  extends Action<DashboardActionType.SET_AVG_REWARD> {
  avgReward: BigNumber
}
export function setAvgReward(avgReward: BigNumber): SetAvgRewardAction {
  return {
    type: DashboardActionType.SET_AVG_REWARD,
    avgReward,
  }
}
export interface SetActiveWalletAction
  extends Action<DashboardActionType.SET_ACTIVE_WALLETS> {
  activeWallets: BigNumber
}
export function setActiveWallets(
  activeWallets: BigNumber,
): SetActiveWalletAction {
  return {
    type: DashboardActionType.SET_ACTIVE_WALLETS,
    activeWallets,
  }
}

export interface SetBlocksAction
  extends Action<DashboardActionType.SET_BLOCKS> {
  blocks: NewBlockEvent[]
}
export function setBlocks(blocks: NewBlockEvent[]): SetBlocksAction {
  return {
    type: DashboardActionType.SET_BLOCKS,
    blocks,
  }
}

export interface SetTransactionsAction
  extends Action<DashboardActionType.SET_TRANSACTIONS> {
  transactions: TxEvent[]
}
export function setTransactions(
  transactions: TxEvent[],
): SetTransactionsAction {
  return {
    type: DashboardActionType.SET_TRANSACTIONS,
    transactions,
  }
}

export interface SetInsuranceBalancesAction
  extends Action<DashboardActionType.SET_INSURANCE_BALANCES> {
  insurance: BigNumber
}

export function setInsuranceBalances(
  insurance: BigNumber,
): SetInsuranceBalancesAction {
  return {
    type: DashboardActionType.SET_INSURANCE_BALANCES,
    insurance,
  }
}

export interface SetOIAction extends Action<DashboardActionType.SET_OI> {
  oi: any
}

export function setOI(oi: any): SetOIAction {
  return {
    type: DashboardActionType.SET_OI,
    oi,
  }
}
export interface SetVolumeAction
  extends Action<DashboardActionType.SET_VOLUME> {
  volume: any
}

export function setVolume(volume: any): SetVolumeAction {
  return {
    type: DashboardActionType.SET_VOLUME,
    volume,
  }
}

export interface SetRewardsDistributedAction
  extends Action<DashboardActionType.SET_REWARDS_DISTRIBUTED> {
  rewardsDistributed: RewardsDistributedResponse
}

export function setRewardsDistributed(rewardsDistributed: RewardsDistributedResponse): SetRewardsDistributedAction {
  return {
    type: DashboardActionType.SET_REWARDS_DISTRIBUTED,
    rewardsDistributed,
  }
}

export interface SetTotalUsersChartAction
  extends Action<DashboardActionType.SET_TOTAL_USERS_CHART> {
  totalUsersChartDataResponse: TotalUsersChartDataResponse
}
export function setTotalUsersChartData(totalUsersChartDataResponse: TotalUsersChartDataResponse): SetTotalUsersChartAction {
  return {
    type: DashboardActionType.SET_TOTAL_USERS_CHART,
    totalUsersChartDataResponse,
  }
}
export interface SetActiveUsersChartAction
  extends Action<DashboardActionType.SET_ACTIVE_USERS_CHART> {
  activeUsersChartDataResponse: ActiveUsersChartDataResponse
}
export function setActiveUsersChartData(activeUsersChartDataResponse: ActiveUsersChartDataResponse): SetActiveUsersChartAction {
  return {
    type: DashboardActionType.SET_ACTIVE_USERS_CHART,
    activeUsersChartDataResponse,
  }
}

export interface SetDistributionRewardsChartAction
  extends Action<DashboardActionType.SET_DISTRIBUTION_REWARDS_CHART> {
  distributionRewardsChartDataResponse: any
}
export function setDistributionRewardsChartData(distributionRewardsChartDataResponse: any): SetDistributionRewardsChartAction {
  return {
    type: DashboardActionType.SET_DISTRIBUTION_REWARDS_CHART,
    distributionRewardsChartDataResponse,
  }
}

export interface UpdateDateFiltersTotalUsersChartAction
  extends Action<DashboardActionType.UPDATE_DATE_FILTERS_TOTAL_USERS_CHART> {
  options: any
}

export function updateDateFiltersTotalUsersChart(options: any): UpdateDateFiltersTotalUsersChartAction {
  return {
    type: DashboardActionType.UPDATE_DATE_FILTERS_TOTAL_USERS_CHART,
    options,
  }
}
export interface UpdateDateFiltersActiveUsersChartAction
  extends Action<DashboardActionType.UPDATE_DATE_FILTERS_ACTIVE_USERS_CHART> {
  options: any
}

export function updateDateFiltersActiveUsersChart(options: any): UpdateDateFiltersActiveUsersChartAction {
  return {
    type: DashboardActionType.UPDATE_DATE_FILTERS_ACTIVE_USERS_CHART,
    options,
  }
}
export interface UpdateDateFiltersDistributionRewardsChartAction
  extends Action<DashboardActionType.UPDATE_DATE_FILTERS_DISTRIBUTION_REWARDS_CHART> {
  options: any
}

export function updateDateFiltersDistributionRewardsChart(options: any): UpdateDateFiltersDistributionRewardsChartAction {
  return {
    type: DashboardActionType.UPDATE_DATE_FILTERS_DISTRIBUTION_REWARDS_CHART,
    options,
  }
}
