import { Box, BoxProps, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

type Props = BoxProps & {
  title?: string
  titleClass?: string
  rightMenu?: React.ReactNode
  variant?: 'primary'
}

const Section: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, titleClass, rightMenu, variant, className, children, ...rest } = props
  const classes = useStyles(props)

  const primaryVariant = variant === 'primary'

  return (
    <Box
      component="section"
      {...rest}
      className={clsx(classes.root, className, {
        [classes.primary]: primaryVariant,
      })}
    >
    <Box className={classes.header}>
      {!!title && (
        <Typography className={clsx(classes.title, titleClass)} variant="h2">
          {title}
         
        </Typography>
      )}
      <Box className={classes.rightMenu}>
        {rightMenu}
      </Box>
    </Box>
      {children}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
      border: 'unset',
    },
    borderRadius: 8,
    border: 'unset',
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    border: 'unset',
  },
  title: {
    marginBottom: theme.spacing(3),
    border: 'unset',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: 'unset',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      border: 'unset',
    },
  },
  rightMenu: {
    marginTop: theme.spacing(1),
  },
}))

export default Section
