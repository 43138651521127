import {
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery
} from '@material-ui/core'
import { DataLoadSegment, ListTable, Section, StandardBtn, TableEmptyState, TooltipHint } from 'js/components'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import InfoIcon from '@material-ui/icons/Info'
import { useAsyncTask } from 'js/hooks'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from 'js/store'
import { Insights } from 'carbon-js-sdk'

interface PNLItem {
  label: string
  unix: number
  prevUnix: number
}

const timeline: PNLItem = {
  label: 'Last 24H',
  unix: moment().subtract(24, 'hour').unix(),
  prevUnix: moment().subtract(48, 'hour').unix(),
}
interface Ranking {
  label: string
  currentRank: number | undefined
  lastRank: number | undefined
  rankChange: number
  pnl: string
}

interface Props {
  title?: string
  address: string
}

const PnlTable: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { title, address } = props
  const classes = useStyles()
  const smallScreen: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [rank, setPnlRank] = useState<Ranking>()
  const [getRanking, getRankingLoading] = useAsyncTask('getRanking')
  const sdk = useSelector((state: RootState) => state.core.carbonSDK)

  useEffect(() => {
    if (!address || !sdk || getRankingLoading) return
    getRanking(async () => {
      try {
        const queryParams = { address: address }
        //48 hrs rank
        const pathParams48Hr = { fromUnix: timeline.prevUnix, toUnix: moment().unix() }
        const previousLeaderboardResult: Insights.InsightsQueryResponse<Insights.QueryGetLeaderboardResponse> = await sdk.insights.Leaderboard(pathParams48Hr, queryParams);
        const previousRank = parseInt(previousLeaderboardResult.result?.entries?.rows[0].rank) ?? 0

        //24 hrs rank
        const pathParams24Hr = { fromUnix: timeline.unix, toUnix: moment().unix() }
        const currentLeaderboardResult: Insights.InsightsQueryResponse<Insights.QueryGetLeaderboardResponse> = await sdk.insights.Leaderboard(pathParams24Hr, queryParams);
        const currentRank = parseInt(currentLeaderboardResult.result?.entries?.rows[0].rank) ?? 0
        const realizedPnl = currentLeaderboardResult.result?.entries?.rows[0].realizedPnl

        //total rank size
        const currentOverallLeaderboardResult: Insights.InsightsQueryResponse<Insights.QueryGetLeaderboardResponse> = await sdk.insights.Leaderboard(pathParams24Hr, {});
        const currentPnlLength = currentOverallLeaderboardResult.result.entries.rows.length

        //calculate rank change
        let rankChange = previousRank - currentRank;
        if (previousRank === 0 && currentRank !== 0) {
          rankChange = currentPnlLength - currentRank
        }
        setPnlRank({ label: timeline.label, currentRank: currentRank, lastRank: previousRank, rankChange: rankChange, pnl: realizedPnl } as Ranking)
      } catch (error) {
        console.log(error)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  return (
    <Section title={title}>
      <DataLoadSegment loading={getRankingLoading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>
                Realised PNL
                <TooltipHint title="Only ranked PNL statistics are shown">
                  <InfoIcon className={smallScreen ? classes.infoIconSmallScreen : classes.infoIcon} />
                </TooltipHint>
              </TableCell>
              <TableCell align="center">Rank</TableCell>
              <TableCell align="center">Previous Rank</TableCell>
              <TableCell>Rank Movement</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rank && renderTransaction(rank)}
          </TableBody>
        </ListTable>
        {!rank && (
          <TableEmptyState itemName="PNL Ranking" />
        )}
        <StandardBtn
          className={classes.button}
          color="secondary"
          to="/leaderboard"
          variant="contained"
        >
          View Leaderboard
        </StandardBtn>
      </DataLoadSegment>
    </Section>
  )

  function renderTransaction(
    rank: Ranking,
  ): ReactNode {
    const { label, currentRank, lastRank, rankChange, pnl } = rank
    return (
      <TableRow key={label} hover>
        <TableCell>
          {label}
        </TableCell>
        <TableCell>
          {currentRank === 0 ? "-" : pnl}
        </TableCell>
        <TableCell align="center">
          {currentRank === 0 ? 'Unranked' : `#${currentRank}`}
        </TableCell>
        <TableCell align="center">
          {lastRank === 0 ? 'Unranked' : `#${lastRank}`}
        </TableCell>
        <TableCell>
          {rankChange > 0 && "+"} {rankChange}
        </TableCell>
      </TableRow>
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    fontSize: '1rem',
    marginTop: '2rem',
    width: '12.5rem',
    whiteSpace: 'nowrap'
  },
  infoIcon: {
    verticalAlign: 'bottom',
    margin: theme.spacing(0, 1.5)
  },
  infoIconSmallScreen: {
    verticalAlign: 'bottom',
  }
}))

export default PnlTable
