import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { ProposalStatus } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1beta1/gov'
import { setSubmitProposalId } from 'js/actions/governance'
import { CellLink, DataLoadSegment, JSONBlock, NotFoundState, Page, ProposalTopicLabel, Section, TabsButtons } from 'js/components'
import { DepositStatus, PeriodTag, ProposalStatusProp } from 'js/components/ProposalRow/components'
import { Paths, TaskNames, TutorialKeys } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { actions } from 'js/store'
import { BIG_ZERO, parseNumber, unescapeHtmlGo, } from 'js/utils'
import React, { Fragment, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useDispatch } from 'react-redux'
import { RestModels } from 'tradehub-api-js'
import { CustomTable } from '../components'
import { DepositDialog, VoteDialog } from '../Dialogs'
import { DepositDetails, DepositHistory, ProposalHistory, ValidatorVotes, VoteDetails, VoteHistory } from './components'
import { getProposalDisplayData, isParamsCell, ProposalDescriptionEntry } from './helper'

const TAB_ITEMS = [{
  label: 'Votes',
  key: 'votes',
}, {
  label: 'Validators Votes',
  key: 'validators',
}, {
  label: 'Deposits',
  key: 'deposits',
}]

const ProposalProp: React.FC<{}> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [proposalDetailsLoading] = useTaskSubscriber(TaskNames.Proposal.Details)
  const proposal = useRedux((state) => state.proposal.details)
  const sdk = useRedux((state) => state.core.carbonSDK)
  const [selectedTab, setSelectedTab] = useState(TAB_ITEMS[0].key)
  const [openDepositDialog, setOpenDepositDialog] = useState(false)
  const [openVoteDialog, setOpenVoteDialog] = useState(false)
  const isDepositPeriod = proposal?.status === ProposalStatus.PROPOSAL_STATUS_DEPOSIT_PERIOD
  dispatch(setSubmitProposalId(null))
  const proposalDisplayData = getProposalDisplayData(proposal, sdk)

  // search for params in proposal
  let hasParams = false
  const processdDisplayData = proposalDisplayData?.proposalDescriptionList.map((e: ProposalDescriptionEntry) => {
    const header = e.header
    if (typeof header === 'string') {
      return {
        header: header,
        cell: e.cell,
      }
    }

    hasParams = true

    // parameter change
    return {
      header: (
        <div className={classes.customHeader}>
          <div>
            <Typography variant="h6">Proposal Type</Typography>
            <div>{header.type}</div>
          </div>
          <div>
            <Typography variant="h6">Authority</Typography>
            <CellLink to={`/account/${header.authority}`}>
              {header.authority}
            </CellLink>
          </div>
        </div >
      ),
      cell: (
        <div className={classes.customCell}>
          {isParamsCell(e.cell) && <div><Typography variant="h6">{e.cell.header}</Typography></div>}
          {isParamsCell(e.cell) && <div className={classes.jsonBlock}><JSONBlock content={e.cell.cell} /></div>}
        </div>
      )
    }
  })

  useEffect(() => {
    if (isDepositPeriod) {
      dispatch(actions.Tutorial.triggerTutorial(TutorialKeys.DepositProposal))
    }
    // eslint-disable-next-line
  }, [isDepositPeriod])

  function onClickDeposit(): void {
    setOpenDepositDialog(true)
  }

  function onCloseDeposit(): void {
    setOpenDepositDialog(false)
  }

  function onClickVote(): void {
    setOpenVoteDialog(true)
  }

  function onCloseVote(): void {
    setOpenVoteDialog(false)
  }

  function onTabChange(event: any, key: string): void {
    setSelectedTab(key)
  }

  const displayTitle =
    (<span>
      <ProposalTopicLabel>{proposalDisplayData?.topic}</ProposalTopicLabel>
      {' '}
      {unescapeHtmlGo(proposalDisplayData?.title)}
    </span>)
    ?? <></>

  return (
    <Page
      title={displayTitle}
      backLink={Paths.Governance}
      backLabel="Governance Proposals"
    >
      <DataLoadSegment loading={proposalDetailsLoading}>
        {proposal && (
          <Fragment>
            <Box marginBottom={2} marginTop={2}>
              <PeriodTag proposal={proposal} />
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7} lg={8} className={classes.flexGrid}>
                <Section flexGrow={0}>
                  <CustomTable
                    className={classes.dataTable}
                    headerAndCells={[
                      { header: 'Proposal ID', cell: proposal?.id.toString() },
                      { header: 'Current Status', cell: <ProposalStatusProp proposal={proposal} /> },
                      { header: 'Deposit Status', cell: <DepositStatus proposal={proposal} /> },
                    ]}
                  />
                </Section>

                <Section flex={1}>
                  <Box className={classes.descContainer}>
                    <div className={classes.descTitleContainer}>
                      <Typography variant="h2" className={classes.title}>
                        Proposal Description
                      </Typography>
                    </div>
                    <ReactMarkdown>{unescapeHtmlGo(proposalDisplayData.description)}</ReactMarkdown>
                    {proposalDisplayData.extraInfoJson && (<JSONBlock marginTop={1} content={proposalDisplayData.extraInfoJson} />)}

                    {proposalDisplayData.proposalDescriptionList.length > 0 && (
                      <CustomTable headerAndCells={processdDisplayData} removeDivider={hasParams} className={hasParams ? classes.paramsTable : ''} />
                    )}
                  </Box>
                </Section>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                {
                  isDepositPeriod
                    ? <DepositDetails onClickDeposit={onClickDeposit} />
                    : <VoteDetails onClickVote={onClickVote} />
                }
                <ProposalHistory />
              </Grid>
              <Grid item xs={12}>
                <Section title="Activity">
                  <TabsButtons
                    handleChange={onTabChange}
                    items={TAB_ITEMS}
                    value={selectedTab}
                  />
                  {selectedTab === 'deposits' && (
                    <DepositHistory />
                  )}
                  {selectedTab === 'votes' && (
                    <VoteHistory />
                  )}
                  {selectedTab === 'validators' && (
                    <ValidatorVotes />
                  )}
                </Section>
              </Grid>
            </Grid>
          </Fragment>
        )}
        {!proposal && (
          <NotFoundState title="No Proposal Found">
            <Typography variant="body1">
              We can’t find any proposal with this ID. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Governance}>
                Governance
              </CellLink>
              &nbsp;page to view existing proposals.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>

      {proposal && (
        <DepositDialog
          isOpen={openDepositDialog}
          proposalID={proposal.id.toString()}
          proposalTitle={proposalDisplayData.title}
          proposalTopic={proposalDisplayData.topic}
          currentDeposit={proposal.totalDeposit
            .reduce(
              (a, deposit: RestModels.TokenAmount) =>
                a.plus(parseNumber(deposit.amount, BIG_ZERO)!),
              BIG_ZERO,
            )
            .toString()}
          onClose={onCloseDeposit}
        />
      )}
      {proposal && (
        <VoteDialog
          isOpen={openVoteDialog}
          proposalID={proposal.id.toString()}
          proposalTitle={proposalDisplayData.title}
          proposalTopic={proposalDisplayData.topic}
          onClose={onCloseVote}
        />
      )}
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  flexGrid: {
    display: 'flex',
    flexDirection: 'column',
  },
  descContainer: {
    marginTop: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  descTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  dataTable: {
    marginTop: 0,
    marginBottom: 0,
  },
  customHeader: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    '& > div': {
      marginBottom: theme.spacing(2),
    },
    backgroundColor: theme.palette.background.default,
  },
  customCell: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    '& > div': {
      marginBottom: theme.spacing(2),
    },
    backgroundColor: theme.palette.background.default,
  },
  jsonBlock: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
  },
  paramsTable: {
    maxHeight: '30vh',
    overflowY: 'scroll',
    padding: theme.spacing(1),
  }

}))

export default ProposalProp
