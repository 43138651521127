import { APIPosition } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/position/position'
import { QueryAllPositionRequest, QueryAllPositionResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/position/query'
import { clear, setPosition } from 'js/actions/position'
import { TaskNames } from 'js/constants'
import { SagaIterator } from 'redux-saga'
import {
  Effect,
  call,
  put,
  spawn,
  takeLatest,
} from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import Saga from './Saga'
import { runSagaTask, waitforSDK } from './helper'

export default class Position extends Saga {
  private readonly address: string
  private readonly market: string
  private readonly status: string
  private readonly isMobile: boolean
  constructor(address: string, market: string, status: string, isMobile: boolean) {
    super()
    this.isMobile = isMobile
    this.address = address
    this.market = market
    this.status = status
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [[this, this.fetchPosition], [this, this.watchSetNetwork]].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchPosition(): any {
    const address = this.address
    const market = this.market
    const status = this.status
    yield runSagaTask(TaskNames.Position.Detail, function* () {
      const sdk = yield* waitforSDK()
      const positionQueryClient = sdk.query.position
      const result = (yield call([positionQueryClient, positionQueryClient.PositionAll],
        QueryAllPositionRequest.fromPartial({
          address,
          marketId: market,
          pagination: {
            limit: 10000
          }
        })
      )) as QueryAllPositionResponse
      const pos = result.positions.find((o) => o.openedBlockHeight.eq(status) && o.address === address && o.marketId === decodeURIComponent(market))
      yield put(setPosition(pos as APIPosition))
    });
  }
}