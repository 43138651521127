import { Link, LinkProps, makeStyles, Theme } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from "react-router-dom";

type Props = LinkProps<typeof RouterLink>

const BackLink: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, children, ...rest } = props
  const classes = useStyles(props)
  let history = useHistory();

  return (
    <Link
      component={RouterLink}
      underline="none"
      color="textPrimary"
      {...rest}
      className={clsx(classes.root, className)}
      onClick={() => history.goBack()}
    >
      <ArrowBackIcon fontSize="small" className={classes.backIcon} /> {children}
    </Link>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '0.875rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 700,
    marginBottom: theme.spacing(3),
  },
  backIcon: {
    marginRight: theme.spacing(0.5),
  },
}))

export default BackLink
