import { CarbonSDK } from "carbon-js-sdk"
import { adjustHuman, bnOrZero } from "./number"
import { Market } from "carbon-js-sdk/lib/codec/Switcheo/carbon/market/market"

export function getAdjustedMarket(
  market: Market, sdk: CarbonSDK | undefined,
): Market {
  if (!market || !sdk?.token) {
    return market
  }

  const requiredPrecision = sdk?.token.getDecimals(market?.base ?? '-') ?? 0
  const quotePrecision = sdk?.token.getDecimals(market?.quote ?? '-') ?? 0
  const diffDp = quotePrecision - requiredPrecision

  const lotSize = sdk?.token.toHuman(market?.base ?? '-', bnOrZero(market?.lotSize)).toFormat()
  const tickSize = adjustHuman(market?.tickSize).shiftedBy(-diffDp).toFormat()
  const minQuantity = sdk.token.toHuman(market?.base ?? '-', bnOrZero(market?.minQuantity)).toFormat()

  const riskStepSize = sdk?.token.toHuman(market?.base ?? '', bnOrZero(market?.riskStepSize)).toFormat()
  const initialMarginBase = adjustHuman(market?.initialMarginBase).toFormat()
  const initialMarginStep = adjustHuman(market?.initialMarginStep).toFormat()
  const maintenanceMarginRatio = adjustHuman(market.maintenanceMarginRatio).toFormat()

  return {
    ...market,
    lotSize,
    tickSize,
    minQuantity,
    ...market.marketType === 'futures' && {
      riskStepSize,
      initialMarginBase,
      initialMarginStep,
      maintenanceMarginRatio,
    },
  }
}
