import { TableRow } from '@material-ui/core'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import { TableCellInfo, TableSectionCell } from 'js/components'
import React, { ReactElement } from 'react'

interface Props {
  model: Market
}

const SettledMarketRender: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { model } = props
  const cellData: TableCellInfo[] = [
    {
      align: 'inherit',
      content: model?.displayName,
      to: `/market/${encodeURIComponent(model?.id)}`,
    },
    {
      align: 'inherit',
      content: model?.id,
      // to: `/market/${encodeURIComponent(model?.name)}`,
    },
    {
      content: model?.expiryTime,
      type: 'date',
    },
    {
      content: model?.closedBlockHeight.toNumber(),
      to: `/block/${model?.closedBlockHeight.toNumber()}`,
    }
  ]

  return (
    <TableRow key={model?.id} hover>
      {
        cellData.map((cell: TableCellInfo, index: number) => (
          <TableSectionCell
            key={index}
            {...cell}
          />
        ))
      }
    </TableRow>
  )
}

export default SettledMarketRender
