import { useMediaQuery, useTheme } from '@material-ui/core';
import {
  Chart as ChartJS,
  registerables,
} from 'chart.js';
import 'chartjs-adapter-moment';
import chartPluginCrosshair from 'chartjs-plugin-crosshair';
import { CARBON_GENESIS_BLOCKTIME } from 'js/constants';
import { computeTotalLiquidityDailySums, formatVolumeLiquidityChartData } from 'js/helpers';
import { switcheo } from 'js/theme/palettes/colors';
import { bnOrZero, hexToRgba } from 'js/utils';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

type Props = {
  volume: any,
  liquidity: any,
  dateFilter: any,
  setTotalLiquidity: (data: any) => void
  setTotalVolume: (data: any) => void
}

const VolumeLiquidityChart: React.FunctionComponent<Props> = (
  props: any,
): ReactElement<Props> => {
  const { volume, liquidity, dateFilter, setTotalLiquidity, setTotalVolume } = props
  const theme = useTheme()
  const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'))
  const maxXAxis = isMobileXs ? 3 : isMobileSm ? 5 : 10
  const yTitleShowWhenMobile = isMobileXs ? false : true
  const toolTipPosition = isMobileSm ? 'top' : 'right' as const
  const [chartData, setchartData] = useState<any>(undefined)
  const [chartLabels, setchartLabels] = useState<any>(undefined)
  ChartJS.register(...registerables, chartPluginCrosshair);
  const startDate = moment(CARBON_GENESIS_BLOCKTIME);
  const endDate = moment();
  const dateFilterStart = moment(dateFilter.startDate);
  const dateFilterEnd = moment(dateFilter.endDate);
  const needFilterDataByDate = startDate.format('ll') !== dateFilterStart.format('ll') || endDate.format('ll') !== dateFilterEnd.format('ll');

  useEffect(() => {
    if (volume && liquidity) {
      let filteredVolume = volume;
      let filteredLiquidity = liquidity;

      if (needFilterDataByDate) {
        filteredVolume = volume.filter(
          (dailyData: any) =>
            moment(dailyData.date) >= dateFilter.startDate && moment(dailyData.date) <= moment(dateFilter.endDate).endOf('day'))
        filteredLiquidity = liquidity.filter(
          (dailyData: any) =>
            moment(dailyData.date) >= dateFilter.startDate && moment(dailyData.date) <= moment(dateFilter.endDate).endOf('day')
        )
      }
      const [labels, chartVolumeData] = formatVolumeLiquidityChartData(filteredVolume)
      const totalLiquidityResult = computeTotalLiquidityDailySums(filteredLiquidity);
      const dataConfigs = [
        {
          label: 'Total Volume',
          data: chartVolumeData?.totalVolume ?? [],
          color: 0,
          hidden: false,
          yAxisID: 'y' as 'y',
        },
        {
          label: 'Total Liquidity',
          data: totalLiquidityResult ?? [],
          color: 1,
          hidden: false,
          yAxisID: 'y1' as 'y1',
        },
      ]

      const generateDataSets = dataConfigs.map((dataConfig: any) => {
        return {
          label: dataConfig.label,
          fill: true,
          lineTension: 0.2,
          backgroundColor: hexToRgba(switcheo.chart[dataConfig.color], 0.90),
          borderColor: hexToRgba(switcheo.chart[dataConfig.color], 0.90),
          borderDash: [],
          borderDashOffset: 0.0,
          pointBorderColor: hexToRgba(switcheo.chart[dataConfig.color], 0.90),
          pointBackgroundColor: hexToRgba(switcheo.chart[dataConfig.color], 0.90),
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: hexToRgba(switcheo.chart[dataConfig.color], 0.90),
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: dataConfig.data,
          hidden: dataConfig.hidden,
          yAxisID: dataConfig.yAxisID,
        }
      })
      if (filteredVolume.length > 0) {
        setTotalVolume(filteredVolume.slice(-1)[0].volumeValue);
      }
      setTotalLiquidity(totalLiquidityResult);
      setchartData(generateDataSets)
      setchartLabels(labels)
    }
  }, [volume, liquidity, dateFilter, needFilterDataByDate, setTotalLiquidity, setTotalVolume])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: true,
    },
    scales: {
      x: {
        // stacked: true,
        type: 'time' as 'time',
        time: {
          unit: 'day' as 'day',
          displayFormats: {
            day: 'DD MMM',
            month: 'MMM YY'
          },
          tooltipFormat: "ll",
        },
        ticks: {
          autoSkip: false,
          beginAtZero: true,
          alwaysShowLastTick: true,
          display: true,
          maxTicksLimit: maxXAxis,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
        }
      },
      y: {
        position: 'left' as 'left',
        // staked: true,
        title: {
          display: yTitleShowWhenMobile,
          text: 'Volume'
        },
        suggestedMax: 600000,
        ticks: {
          steps: 6,
          stepSize: 100000,
          callback: function (val: any) {
            return bnOrZero(val).toFormat()
          },
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: true,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
          z: 1,
        }
      },
      y1: {
        display: true,
        position: 'right' as 'right',
        title: {
          display: yTitleShowWhenMobile,
          text: 'Liquidity'
        },
        suggestedMax: 6000000,
        ticks:
        {
          steps: 6,
          stepSize: 1000000,
          callback: function (val: any) {
            return bnOrZero(val).toFormat()
          },
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: true,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
          z: 1,
        }
      }
    },
    plugins: {
      TimeScale: {
        x: {
          type: 'time'
        }
      },
      tooltip: {
        mode: 'index' as 'index',
        intersect: false,
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 10,
        boxPadding: 2,
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }
            return label;
          }
        }
      },
      crosshair: {
        line: {
          color: switcheo.text.grey,
          width: 2,
          dashPattern: [5, 5],
        }
      },
      legend: {
        position: toolTipPosition as any,
        align: 'start' as const,
        fullSize: false,
        labels: {
          boxWidth: 23,
          boxHeight: 8,
        },
      },
    },
  };

  const finalData = {
    labels: chartLabels ?? [],
    datasets: chartData ?? [],
  };
  return (
    <Bar options={options} data={finalData} />
  )
}

export default VolumeLiquidityChart
