import { Blockchain, Insights } from 'carbon-js-sdk'
import { Staking } from 'carbon-js-sdk/lib/codec'
import { QueryAllianceDelegationRewardsResponse, QueryAlliancesDelegationsResponse } from 'carbon-js-sdk/lib/codec'
import { QueryInternalTransfersRequest, QueryInternalTransfersResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/bank/query'
import { QueryTradesRequest, QueryTradesResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/broker/query'
import { QueryGetBalancesResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/query'
import { QueryDelegationTotalRewardsResponse } from 'carbon-js-sdk/lib/codec/cosmos/distribution/v1beta1/query'
import { MessageType } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/message_type'
import { QueryAllMessageTypeResponse, QueryAllTransactionRequest, QueryAllTransactionResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/query'
import { Transaction } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/transaction'
import { QueryAllOrderRequest, QueryAllOrderResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/query'
import { QueryAllPositionRequest, QueryAllPositionResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/position/query'
import { QueryAllProfileResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/profile/query'
import { CrossChainTransferDetailed, GetDetailedTransfersResponse } from 'carbon-js-sdk/lib/hydrogen'
import { UpdateInternalTransferAction, UpdateTradesAction, setDisablePaginationForwardButton, setInternalTransfer, setPositionsHistory, setTransactions, setTypes } from 'js/actions/account'
import { TaskNames, positionsPaginationLimit, userInternalTransferPaginationLimit, userTradesPaginationLimit } from 'js/constants'
import { BN_ZERO, DelegationRewards, RewardEntry, bnOrZero } from 'js/utils'
import Long from 'long'
import { SagaIterator } from 'redux-saga'
import { Effect, call, put, spawn, take, takeLatest } from 'redux-saga/effects'
import { AccountActionType, clear, setAddress, setBalance, setCrossChainTransfers, setDelegations, setOpenOrders, setOrders, setPositions, setProfile, setRewards, setTrades } from '../actions/account'
import { AppActionType } from '../actions/app'
import Saga from './Saga'
import { runSagaTask, waitforSDK } from './helper'

export default class Account extends Saga {
  private address: string
  private msgType: any
  private txKeys: any[]
  private txInternalTransferKeys: any[]
  private currentTxPage: number
  constructor(address: string, msgType: string | null) {
    super()

    this.address = address
    this.msgType = msgType ? msgType.split(',') : []
    this.handleFilters = this.handleFilters.bind(this)
    this.txKeys = []
    this.txInternalTransferKeys = []
    this.currentTxPage = 0
  }

  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    if (this.address.length > 15) {
      return [
        call([this, this.fetchBalance], this.address),
        call([this, this.fetchTransactions], this.address),
        call([this, this.fetchInternalTransfer]),
        call([this, this.fetchTransactionTypes]),
        call([this, this.fetchProfile]),
        call([this, this.fetchCrossChainTransfers], this.address),
        call([this, this.fetchDelegations]),
        call([this, this.fetchRewards]),
        call([this, this.fetchPositions]),
        call([this, this.fetchPositionsHistory]),
        call([this, this.fetchOpenOrders]),
        call([this, this.fetchAddress]),
        call([this, this.fetchOrders]),
        call([this, this.fetchTrades]),
        spawn([this, this.watchPositions]),
        spawn([this, this.watchPositionsHistory]),
        spawn([this, this.watchTrades]),
        spawn([this, this.watchFilters]),
        spawn([this, this.watchSetNetwork]),
        spawn([this, this.watchDataUpdate])
      ]
    }
    return [call([this, this.fetchAddressFromUsername])]
  }

  private *watchDataUpdate(): SagaIterator {
    yield takeLatest(AccountActionType.UPDATE_INTERNAL_TRANSFER, (action: UpdateInternalTransferAction) => this.fetchInternalTransfer(action.options))
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchAddressFromUsername(): any {
    const sdk = yield* waitforSDK()
    const profileQueryClient = sdk.query.profile
    const result = (yield call([profileQueryClient, profileQueryClient.ProfileByUsername], {
      username: this.address,
    })) as QueryAllProfileResponse
    if (result.profiles.length > 0) {
      this.address = result.profiles[0].address
      yield* this.stop()
      yield* this.getStartEffects()
    }
  }

  private *fetchBalance(address: string): any {
    yield runSagaTask(TaskNames.Account.Holdings, function* () {
      const sdk = yield* waitforSDK()
      const coinQueryClient = sdk.query.coin
      const balances = (yield call([coinQueryClient, coinQueryClient.Balances], {
        address,
      })) as QueryGetBalancesResponse
      yield put(setBalance(balances.tokenBalances))
    })
  }

  private *fetchProfile(): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.Profile, function* () {
      // Workaround to allow for displaying profiles without username
      yield put(setProfile({
        address,
        username: '',
        twitter: '',
        lastSeen: '',
        lastSeenBlock: 0,
        firstSeenBlock: 0,
        firstSeen: ''
      }))

      const sdk = yield* waitforSDK()
      // const profileQueryClient = sdk.query.profile
      // const result = (yield call([profileQueryClient, profileQueryClient.Profile], {
      //   address,
      // })) as QueryGetProfileResponse

      //get last seen block and time
      const insightsProfileRaw = (yield (sdk.insights.UserProfile({ address }))) as Insights.InsightsQueryResponse<Insights.QueryGetUserProfileResponse>
      const insightsProfile = insightsProfileRaw?.result?.entries
      if (insightsProfile) {
        yield put(setProfile({
          address: insightsProfile?.address ?? '',
          username: insightsProfile?.username ?? '',
          twitter: insightsProfile?.twitter ?? '',
          lastSeen: insightsProfile?.lastSeen,
          lastSeenBlock: insightsProfile?.lastSeenBlock,
          firstSeenBlock: insightsProfile?.firstSeenBlock,
          firstSeen: insightsProfile?.firstSeen
        }))
      }
    });
  }

  // TODO: Delete once migration done
  private *watchPositions(): any {
    while (true) {
      const address = this.address
      yield runSagaTask(TaskNames.Account.PositionsFilter, function* () {
        const action: any = yield take(AccountActionType.UPDATE_POSITIONS)
        const sdk = yield* waitforSDK()
        const positionQueryClient = sdk.query.position
        const page = Number(action.options.page)
        const offset = (page - 1) * positionsPaginationLimit
        const offsetLong = Long.fromNumber(offset >= 0 ? offset : 0)

        const positions = (yield call([positionQueryClient, positionQueryClient.PositionAll],
          QueryAllPositionRequest.fromPartial({
            address,
            status: 'open',
            pagination: {
              limit: new Long(positionsPaginationLimit),
              offset: offsetLong,
              countTotal: true,
              reverse: false,
            }
          })
        )) as QueryAllPositionResponse
        yield put(setPositions({
          data: positions.positions,
          meta: {
            totalPages: positions.pagination?.total.toNumber() ?? 0,
          }
        }))
      });
      // yield delay(6020)
    }
  }

  private *fetchPositions(): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.Positions, function* () {
      const sdk = yield* waitforSDK()
      const positionQueryClient = sdk.query.position

      const positions = (yield call([positionQueryClient, positionQueryClient.PositionAll],
        QueryAllPositionRequest.fromPartial({
          address,
          status: 'open',
          pagination: {
            limit: new Long(positionsPaginationLimit),
            offset: Long.UZERO,
            countTotal: true,
            reverse: false,
          },
        })
      )) as QueryAllPositionResponse
      yield put(setPositions({
        data: positions.positions,
        meta: {
          totalPages: positions.pagination?.total.toNumber() ?? 0,
        }
      }))
    })
  }

  // TODO: Delete once migration done
  private *watchPositionsHistory(): any {
    while (true) {
      const address = this.address
      yield runSagaTask(TaskNames.Account.PositionsHistoryFilter, function* () {
        const action: any = yield take(AccountActionType.UPDATE_POSITIONS_HISTORY)
        const sdk = yield* waitforSDK()
        const positionQueryClient = sdk.query.position
        const page = Number(action.options.page)
        const offset = (page - 1) * positionsPaginationLimit
        const offsetLong = Long.fromNumber(offset >= 0 ? offset : 0)
        const positions = (yield call([positionQueryClient, positionQueryClient.PositionAll],
          QueryAllPositionRequest.fromPartial({
            address,
            status: 'open',
            pagination: {
              limit: new Long(positionsPaginationLimit),
              offset: offsetLong,
              countTotal: true,
              reverse: false,
            }
          })
        )) as QueryAllPositionResponse
        yield put(setPositions({
          data: positions.positions,
          meta: {
            totalPages: positions.pagination?.total.toNumber() ?? 0,
          }
        }))
      })
    }
  }

  private *fetchPositionsHistory(): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.PositionsHistory, function* () {
      const sdk = yield* waitforSDK()
      const positionQueryClient = sdk.query.position

      const positions = (yield call([positionQueryClient, positionQueryClient.PositionAll],
        QueryAllPositionRequest.fromPartial({
          address,
          status: 'closed',
          pagination: {
            limit: new Long(1000),
            offset: Long.UZERO,
            countTotal: true,
            reverse: false,
          }
        })
      )) as QueryAllPositionResponse
      yield put(setPositionsHistory({
        data: positions.positions,
        meta: {
          totalPages: positions.pagination?.total.toNumber() ?? 0,
        }
      }))
    });
  }

  private *fetchOpenOrders(): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.OpenOrders, function* () {
      const sdk = yield* waitforSDK()
      const orderQueryClient = sdk.query.order
      const ordersResponse = (yield call([orderQueryClient, orderQueryClient.OrderAll], QueryAllOrderRequest.fromPartial({
        pagination: {
          limit: new Long(300),
          offset: Long.UZERO,
          countTotal: true,
          reverse: false,
        },
        address,
        marketId: '',
        orderType: '',
        orderStatus: 'open',
      })
      )) as QueryAllOrderResponse
      yield put(setOpenOrders(ordersResponse.orders?.sort((a, b) => Number(b.blockHeight) - Number(a.blockHeight))))
    })
  }

  private *fetchOrders(): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.OrderList, function* () {
      const sdk = yield* waitforSDK()
      const orderQueryClient = sdk.query.order

      const ordersResponse = (yield call([orderQueryClient, orderQueryClient.OrderAll], QueryAllOrderRequest.fromPartial({
        pagination: {
          limit: new Long(300),
          offset: Long.UZERO,
          countTotal: true,
          reverse: false,
        },
        address,
        marketId: '',
        orderType: '',
        orderStatus: '',
      })
      )) as QueryAllOrderResponse
      yield put(setOrders(ordersResponse.orders?.sort((a, b) => Number(b.blockHeight) - Number(a.blockHeight))))
    })
  }

  private *fetchTrades(): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.TradesByAccount, function* () {
      const sdk = yield* waitforSDK()
      const brokerQueryClient = sdk.query.broker

      const trades = (yield call([brokerQueryClient, brokerQueryClient.Trades],
        QueryTradesRequest.fromPartial({
          address,
          pagination: {
            limit: new Long(5),
            offset: Long.UZERO,
            countTotal: true,
            reverse: false,
          }
        }),
      )) as QueryTradesResponse
      yield put(setTrades({
        data: trades.trades,
        meta: {
          totalPages: trades.pagination?.total.toNumber() ?? 0,
        }
      }))
    });
  }

  private *watchTrades(): any {
    const address = this.address
    while (true) {
      yield runSagaTask(TaskNames.Account.TradesByAccount, function* () {
        const action = (yield take(AccountActionType.UPDATE_TRADES)) as UpdateTradesAction
        const sdk = yield* waitforSDK()
        const brokerQueryClient = sdk.query.broker
        const page = Number(action.options.page)
        const offset = (page - 1) * userTradesPaginationLimit
        const offsetLong = Long.fromNumber(offset >= 0 ? offset : 0)

        const trades = (yield call([brokerQueryClient, brokerQueryClient.Trades], QueryTradesRequest.fromPartial({
          address,
          pagination: {
            limit: new Long(5),
            offset: offsetLong,
            countTotal: true,
            reverse: false,
          }
        }),
        )) as QueryTradesResponse
        yield put(setTrades({
          data: trades.trades,
          meta: {
            totalPages: (trades.pagination?.total.toNumber() ?? 0),
          }
        }))
      });
    }
  }

  private *fetchDelegations(): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.Delegations, function* () {
      const sdk = yield* waitforSDK()
      const stakingQueryClient = sdk.query.staking
      const alliancesStakingClient = sdk.query.alliance

      const response = (yield call([stakingQueryClient, stakingQueryClient.DelegatorDelegations], {
        delegatorAddr: address,
      })) as Staking.QueryDelegatorDelegationsResponse
      const alliancesStaking = (yield call([alliancesStakingClient, alliancesStakingClient.AlliancesDelegation], {
        delegatorAddr: address,
      })) as QueryAlliancesDelegationsResponse
      const allDelegations = response.delegationResponses.concat(alliancesStaking.delegations)
      yield put(setDelegations(allDelegations))
    })
  }

  private *fetchRewards(): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.Rewards, function* () {
      const sdk = yield* waitforSDK()
      const distributionQueryClient = sdk.query.distribution
      const alliancesStakingClient = sdk.query.alliance

      const response = (yield call([distributionQueryClient, distributionQueryClient.DelegationTotalRewards], {
        delegatorAddress: address,
      })) as QueryDelegationTotalRewardsResponse

      const allNativeRewardDenoms = response.total.map(reward => reward.denom)
      const allRewards: DelegationRewards[] = []

      const alliancesDelegation = (yield call([alliancesStakingClient, alliancesStakingClient.AlliancesDelegation], {
        delegatorAddr: address,
      })) as QueryAlliancesDelegationsResponse

      for (const denom of allNativeRewardDenoms) {
        const nativeRewards: RewardEntry[] = []
        const allianceRewards: RewardEntry[] = []
        const denomRewards = {
          denom: denom,
          native: nativeRewards,
          alliance: allianceRewards,
        }
        response.rewards.forEach(reward => {
          const amountForDenom = bnOrZero(reward.reward.find(rewardEntry => rewardEntry.denom === denom)?.amount, BN_ZERO)
          if (amountForDenom.gt(BN_ZERO) && reward.validatorAddress) {
            denomRewards.native.push({
              validatorAddress: reward.validatorAddress,
              amount: amountForDenom,
            })
          }
        })
        allRewards.push(denomRewards)
      }

      for (const delegation of alliancesDelegation.delegations) {
        const delegationDenom = delegation.balance?.denom
        const validatorAddress = delegation.delegation?.validatorAddress
        if (!delegationDenom || !validatorAddress) continue
        const allianceRewards = (yield call([alliancesStakingClient, alliancesStakingClient.AllianceDelegationRewards], {
          delegatorAddr: address,
          validatorAddr: validatorAddress,
          denom: delegationDenom
        })) as QueryAllianceDelegationRewardsResponse
        allianceRewards.rewards.forEach(reward => {
          const amount = bnOrZero(reward.amount, BN_ZERO)
          const rewardEntryIndex = allRewards.findIndex(recordedReward => recordedReward.denom === reward.denom)
          if (rewardEntryIndex < 0) {
            allRewards.push({
              denom: reward.denom,
              native: [],
              alliance: [{ validatorAddress, amount }]
            })
          } else {
            allRewards[rewardEntryIndex].alliance.push({ validatorAddress, amount })
          }
        })
      }

      yield put(setRewards(allRewards))
    })
  }

  private *fetchAddress(): any {
    yield put(setAddress(this.address))
  }

  private *fetchInternalTransfer(options?: any): any {
    const address = this.address
    yield runSagaTask(TaskNames.Account.InternalTransfer, function* () {
      const sdk = yield* waitforSDK()
      const page = Number(options?.page) ?? 0
      const offset = (page - 1) * userInternalTransferPaginationLimit
      const offsetLong = Long.fromNumber(offset >= 0 ? offset : 0)
      const nativeBankClient = sdk.query.nativeBank
      const response = (yield call([nativeBankClient, nativeBankClient.InternalTransfers], QueryInternalTransfersRequest.fromPartial({
        address,
        pagination: {
          limit: new Long(userInternalTransferPaginationLimit),
          offset: offsetLong,
          countTotal: true,
          reverse: false,
        },
      }))) as QueryInternalTransfersResponse
      if (response.internalTransfers.length > 0) {
        yield put(setInternalTransfer({
          data: response.internalTransfers.sort((a, b) => b.transactionBlockHeight.toNumber() - a.transactionBlockHeight.toNumber()),
          meta: {
            totalPages: response.pagination?.total.toNumber() ?? 0,
          }
        }))
      }
    })
  }

  private *fetchTransactions(address: string): any {
    const _this = this
    yield runSagaTask(TaskNames.Account.Transactions, function* () {
      const sdk = yield* waitforSDK()
      const miscQueryClient = sdk.query.misc
      const response = (yield call([miscQueryClient, miscQueryClient.TransactionAll], QueryAllTransactionRequest.fromPartial({
        address,
        pagination: {
          limit: new Long(5),
          key: new Uint8Array(),
          reverse: false,
        },
      }))) as QueryAllTransactionResponse
      _this.txKeys?.push(response.pagination?.nextKey)
      if (response.transactions.length !== 0) {
        yield put(setDisablePaginationForwardButton(false))
      }
      yield put(setTransactions({
        data: response.transactions.sort((a: Transaction, b: Transaction) => b.blockHeight.toNumber() - a.blockHeight.toNumber()),
        meta: {
          totalPages: response.pagination?.total.toNumber() ?? 0,
        }
      }))
    })
  }

  private *fetchTransactionTypes(): any {
    yield runSagaTask(TaskNames.Account.TransactionType, function* () {
      const sdk = yield* waitforSDK()
      const miscQueryClient = sdk.query.misc
      const response = (yield call([miscQueryClient, miscQueryClient.MessageTypeAll], {})) as QueryAllMessageTypeResponse
      const types = response.messageTypes.map((message: MessageType) => message.messageType)
      yield put(setTypes(types))
    });
  }

  private *watchFilters(): any {
    yield takeLatest(AccountActionType.UPDATE_FILTERS,
      (action: any) => this.handleFilters(action, this))
  }

  private *handleFilters(action: any, _this: any): any {
    const address = this.address

    let types: string[] = []
    const filters = action.options.filters
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (filters[key]) {
          types.push(key)
        }
      }
    }

    yield runSagaTask(TaskNames.Account.TransactionFilter, function* () {
      const pageKey = _this.txKeys[Number(action.options.page) - 2] ?? new Uint8Array()
      const sdk = yield* waitforSDK()
      const miscQueryClient = sdk.query.misc
      const isPrev = Number(action.options.page) < _this.currentTxPage
      const response = (yield call([miscQueryClient, miscQueryClient.TransactionAll], QueryAllTransactionRequest.fromPartial({
        address,
        msgTypeFilters: types,
        pagination: {
          limit: new Long(5),
          key: pageKey,
          reverse: false,
        },
      }))) as QueryAllTransactionResponse
      if (!isPrev) _this.txKeys.push(response.pagination?.nextKey)
      if (response.transactions.length === 0 && action.options.page) {
        yield put(setDisablePaginationForwardButton(true))
        return
      } else {
        yield put(setDisablePaginationForwardButton(false))
      }
      yield put(setTransactions({
        data: response.transactions.sort((a: Transaction, b: Transaction) => b.blockHeight.toNumber() - a.blockHeight.toNumber()),
        meta: {
          totalPages: response.pagination?.total.toNumber() ?? 0,
        }
      }))
    })

    yield runSagaTask(TaskNames.Account.CrosschainTransferFilter, function* () {
      const sdk = yield* waitforSDK()

      const transfersResponse = (yield call([sdk.hydrogen, sdk.hydrogen.getDetailedTransfers], {
        address,
      }, 'V2')) as GetDetailedTransfersResponse
      const filteredTransfers = types.length === 1 ? transfersResponse.data.filter((transfer: CrossChainTransferDetailed) => {
        if (types[0] === 'Deposit') {
          return [Blockchain.Carbon.toString(), Blockchain.TradeHub.toString(), Blockchain.Switcheo.toString()].includes(transfer.destination_blockchain!)
        } else if (types[0] === 'Withdrawal') {
          return [Blockchain.Carbon.toString(), Blockchain.TradeHub.toString(), Blockchain.Switcheo.toString()].includes(transfer.source_blockchain!)
        }
        return transfer
      }) : transfersResponse.data

      yield put(setCrossChainTransfers(filteredTransfers))
    })
  }

  private *fetchCrossChainTransfers(address: string): any {
    yield runSagaTask(TaskNames.Account.CrossChainTransfers, function* () {
      const sdk = yield* waitforSDK()

      const transfersResponse = (yield call([sdk.hydrogen, sdk.hydrogen.getDetailedTransfers], {
        address,
      }, 'V2')) as GetDetailedTransfersResponse
      yield put(setCrossChainTransfers(transfersResponse.data))
    });
  }
}
