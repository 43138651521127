import BigNumber from 'bignumber.js'
import { CarbonSDK, Models } from 'carbon-js-sdk'
import { NodeModel } from 'js/reducers/nodes'
import { SimpleBlock } from 'js/models'
import { SimpleMap } from 'js/utils'
import { Action } from 'redux'
import { MenuDropValue } from '../reducers/app'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import { Result } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/oracle/oracle'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/export'
import { TokenPrice } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/pricing/pricing'

export enum AppActionType {
  SET_NETWORK = 'SET_NETWORK',
  SET_DELEGATORS_MAP = 'SET_DELEGATORS_MAP',
  SET_SIGNING_INFO = 'SET_SIGNING_INFO',
  SET_TOTAL_BONDED = 'SET_TOTAL_BONDED',
  SET_TOTAL_STAKED = 'SET_TOTAL_STAKED',
  SET_MARKETS = 'SET_MARKETS',
  SET_GECKO_PRICES = 'SET_GECKO_PRICES',
  SET_LATEST_BLOCK = 'SET_LATEST_BLOCK',
  SET_AVG_BLOCK_TIME = 'SET_AVG_BLOCK_TIME',
  SET_NEO_PRICE = 'SET_NEO_PRICE',
  SET_ORACLE_RESULTS = 'SET_ORACLE_RESULTS',
  SET_SWTH_SUPPLY = 'SET_SWTH_SUPPLY',
  SET_TOKENS_MAP = 'SET_TOKENS_MAP',
  SET_NODES = 'SET_NODES',
  SET_MODE = 'SET_MODE',
  SET_TOTAL_SUPPLY = 'SET_TOTAL_SUPPLY',
  REQUEST_SIGN = 'REQUEST_SIGN',
  SET_SIGN_ERROR = 'SET_SIGN_ERROR',
  FINISH_SIGN = 'FINISH_SIGN',
  SET_NET_DROP_CLOSE = 'SET_NET_DROP_CLOSE',
  SET_NET_DROP_OPEN = 'SET_NET_DROP_OPEN',
  SET_WALLET_DROP_CLOSE = 'SET_WALLET_DROP_CLOSE',
  SET_WALLET_DROP_OPEN = 'SET_WALLET_DROP_OPEN',
  SET_MENU_DROP_ITEM = 'SET_MENU_DROP_ITEM',
  SET_MENU_DROP_CLOSE = 'SET_MENU_DROP_CLOSE',
  SHOW_NODE_INFO_FORM = 'SHOW_NODE_INFO_FORM',
  HIDE_NODE_INFO_FORM = 'HIDE_NODE_INFO_FORM',
  SET_CUSTOM_NODES = 'SET_CUSTOM_NODES',
  SET_SELECTED_NODES = 'SET_SELECTED_NODES',
  SET_AUTO_SELECT_NODE = 'SET_AUTO_SELECT_NODE',
  SET_FORM_NODE = 'SET_FORM_NODE',
  SET_ALL_ORACLE_TOKEN_PRICES = 'SET_ALL_ORACLE_TOKEN_PRICES',
  SET_CONNECT_ERROR = 'SET_CONNECT_ERROR',
}

export type AppAction =
  | SetNetworkAction
  | SetDelegatorsAction
  | SetSigningInfoAction
  | SetTotalBondedAction
  | SetTotalStakedAction
  | SetMarketsAction
  | SetGeckoPricesAction
  | SetNeoPriceAction
  | SetLatestBlockAction
  | SetAvgBlockTimeAction
  | SetOraclesAction
  | SetSWTHSupplyAction
  | SetTokensInfoAction
  | SetNodesAction
  | SetModeAction
  | SetTotalSupplyAction
  | RequestSignAction
  | SetSignErrorAction
  | FinishSignAction
  | NetDropCloseAction
  | NetDropOpenAction
  | WalletDropCloseAction
  | WalletDropOpenAction
  | MenuDropItemAction
  | MenuDropCloseAction
  | ShowNodeInfoFormAction
  | HideNodeInfoFormAction
  | SetCustomNodesAction
  | SetSelectedNodesAction
  | SetAutoSelectNodeAction
  | SetFormNodeAction
  | SetAllOracleTokenPricesAction
  | SetConnectErrorAction

export interface SetNetworkAction extends Action<AppActionType.SET_NETWORK> {
  network: CarbonSDK.Network
}
export function setNetwork(network: CarbonSDK.Network): SetNetworkAction {
  return {
    type: AppActionType.SET_NETWORK,
    network,
  }
}

export interface SetDelegatorsAction
  extends Action<AppActionType.SET_DELEGATORS_MAP> {
  delegators: SimpleMap<Models.Staking.DelegationResponse[]>
}

export function setDelegators(delegators: SimpleMap<Models.Staking.DelegationResponse[]>): SetDelegatorsAction {
  return {
    type: AppActionType.SET_DELEGATORS_MAP,
    delegators,
  }
}

export interface SetSWTHSupplyAction
  extends Action<AppActionType.SET_SWTH_SUPPLY> {
  swthSupply: any
}

export function setSWTHSupply(swthSupply: any): SetSWTHSupplyAction {
  return {
    type: AppActionType.SET_SWTH_SUPPLY,
    swthSupply,
  }
}

export interface SetSigningInfoAction
  extends Action<AppActionType.SET_SIGNING_INFO> {
  signingInfo: SimpleMap<Models.Slashing.ValidatorSigningInfo>
}

export function setSigningInfo(signingInfo: SimpleMap<Models.Slashing.ValidatorSigningInfo>): SetSigningInfoAction {
  return {
    type: AppActionType.SET_SIGNING_INFO,
    signingInfo,
  }
}
export interface SetTotalBondedAction
  extends Action<AppActionType.SET_TOTAL_BONDED> {
  totalBonded: any
}

export function setTotalBonded(totalBonded: any): SetTotalBondedAction {
  return {
    type: AppActionType.SET_TOTAL_BONDED,
    totalBonded,
  }
}
export interface SetTotalStakedAction
  extends Action<AppActionType.SET_TOTAL_STAKED> {
  totalStaked: any
}

export function setTotalStaked(totalStaked: any): SetTotalStakedAction {
  return {
    type: AppActionType.SET_TOTAL_STAKED,
    totalStaked,
  }
}
export interface SetMarketsAction extends Action<AppActionType.SET_MARKETS> {
  markets: SimpleMap<Market>
}

export function setMarkets(markets: SimpleMap<Market>): SetMarketsAction {
  return {
    type: AppActionType.SET_MARKETS,
    markets,
  }
}

export interface SetGeckoPricesAction
  extends Action<AppActionType.SET_GECKO_PRICES> {
  geckoPrices: any
}

export function setGeckoPrices(geckoPrices: any): SetGeckoPricesAction {
  return {
    type: AppActionType.SET_GECKO_PRICES,
    geckoPrices,
  }
}
export interface SetLatestBlockAction extends Action<AppActionType.SET_LATEST_BLOCK> {
  latestBlock: SimpleBlock
}

export function setLatestBlock(latestBlock: SimpleBlock): SetLatestBlockAction {
  return {
    type: AppActionType.SET_LATEST_BLOCK,
    latestBlock,
  }
}
export interface SetAvgBlockTimeAction extends Action<AppActionType.SET_AVG_BLOCK_TIME> {
  avgBlockTime: number
}

export function setAvgBlockTime(avgBlockTime: number): SetAvgBlockTimeAction {
  return {
    type: AppActionType.SET_AVG_BLOCK_TIME,
    avgBlockTime,
  }
}
export interface SetNeoPriceAction extends Action<AppActionType.SET_NEO_PRICE> {
  neoPrice: any
}

export function setNeoPrice(neoPrice: any): SetNeoPriceAction {
  return {
    type: AppActionType.SET_NEO_PRICE,
    neoPrice,
  }
}

export interface SetOraclesAction
  extends Action<AppActionType.SET_ORACLE_RESULTS> {
  oracles: Result[]
}

export function setOracles(oracles: Result[]): SetOraclesAction {
  return {
    type: AppActionType.SET_ORACLE_RESULTS,
    oracles,
  }
}

export interface SetTokensInfoAction
  extends Action<AppActionType.SET_TOKENS_MAP> {
  tokens: SimpleMap<Token>
}

export function setTokensMap(
  tokens: SimpleMap<Token>,
): SetTokensInfoAction {
  return {
    type: AppActionType.SET_TOKENS_MAP,
    tokens,
  }
}

export interface SetAllOracleTokenPricesAction extends Action<AppActionType.SET_ALL_ORACLE_TOKEN_PRICES> {
  tokenPrices: TokenPrice[]
}

export function setAllOracleTokenPrices(tokenPrices: TokenPrice[]): SetAllOracleTokenPricesAction {
  return {
    type: AppActionType.SET_ALL_ORACLE_TOKEN_PRICES,
    tokenPrices
  }
}

export interface SetNodesAction extends Action<AppActionType.SET_NODES> {
  nodes: NodeModel[]
}

export function setNodes(
  nodes: NodeModel[],
): SetNodesAction {
  return {
    type: AppActionType.SET_NODES,
    nodes,
  }
}

export interface SetModeAction extends Action<AppActionType.SET_MODE> {
  mode: string
}

export function setMode(mode: string): SetModeAction {
  return {
    type: AppActionType.SET_MODE,
    mode,
  }
}
export interface SetTotalSupplyAction
  extends Action<AppActionType.SET_TOTAL_SUPPLY> {
  total: BigNumber
}

export function setTotalSupply(total: BigNumber): SetTotalSupplyAction {
  return {
    type: AppActionType.SET_TOTAL_SUPPLY,
    total,
  }
}

export interface RequestSignAction
  extends Action<AppActionType.REQUEST_SIGN> { }

export function requestSign(): RequestSignAction {
  return {
    type: AppActionType.REQUEST_SIGN
  }
}

export interface SetSignErrorAction
  extends Action<AppActionType.SET_SIGN_ERROR> {
  message: string | undefined
}

export function setSignErrors(message?: string): SetSignErrorAction {
  return {
    type: AppActionType.SET_SIGN_ERROR,
    message,
  }
}

export interface FinishSignAction
  extends Action<AppActionType.FINISH_SIGN> { }

export function finishSign(): FinishSignAction {
  return {
    type: AppActionType.FINISH_SIGN,
  }
}

export interface NetDropOpenAction
  extends Action<AppActionType.SET_NET_DROP_OPEN> { }

export function setNetDropOpen(): NetDropOpenAction {
  return {
    type: AppActionType.SET_NET_DROP_OPEN,
  }
}

export interface NetDropCloseAction
  extends Action<AppActionType.SET_NET_DROP_CLOSE> { }

export function setNetDropClose(): NetDropCloseAction {
  return {
    type: AppActionType.SET_NET_DROP_CLOSE,
  }
}

export interface WalletDropOpenAction
  extends Action<AppActionType.SET_WALLET_DROP_OPEN> { }

export function setWalletDropOpen(): WalletDropOpenAction {
  return {
    type: AppActionType.SET_WALLET_DROP_OPEN,
  }
}

export interface WalletDropCloseAction
  extends Action<AppActionType.SET_WALLET_DROP_CLOSE> { }

export function setWalletDropClose(): WalletDropCloseAction {
  return {
    type: AppActionType.SET_WALLET_DROP_CLOSE,
  }
}

export interface MenuDropItemAction
  extends Action<AppActionType.SET_MENU_DROP_ITEM> {
  menuItem: MenuDropValue
}

export function setMenuDropItem(menuItem: MenuDropValue): MenuDropItemAction {
  return {
    type: AppActionType.SET_MENU_DROP_ITEM,
    menuItem,
  }
}

export interface MenuDropCloseAction
  extends Action<AppActionType.SET_MENU_DROP_CLOSE> { }

export function setMenuDropClose(): MenuDropCloseAction {
  return {
    type: AppActionType.SET_MENU_DROP_CLOSE,
  }
}

export interface ShowNodeInfoFormAction extends Action<AppActionType.SHOW_NODE_INFO_FORM> { }

export function showNodeInfoForm(): ShowNodeInfoFormAction {
  return {
    type: AppActionType.SHOW_NODE_INFO_FORM,
  }
}

export interface HideNodeInfoFormAction extends Action<AppActionType.HIDE_NODE_INFO_FORM> { }

export function hideNodeInfoForm(): HideNodeInfoFormAction {
  return {
    type: AppActionType.HIDE_NODE_INFO_FORM,
  }
}

export interface SetCustomNodesAction extends Action<AppActionType.SET_CUSTOM_NODES> {
  nodes: NodeModel[]
}

export function setCustomNodes(nodes: NodeModel[]): SetCustomNodesAction {
  return {
    type: AppActionType.SET_CUSTOM_NODES,
    nodes,
  }
}

export interface SetSelectedNodesAction extends Action<AppActionType.SET_SELECTED_NODES> {
  nodes: SimpleMap<NodeModel>
}

export function setSelectedNodes(nodes: SimpleMap<NodeModel>): SetSelectedNodesAction {
  return {
    type: AppActionType.SET_SELECTED_NODES,
    nodes,
  }
}

export interface SetAutoSelectNodeAction extends Action<AppActionType.SET_AUTO_SELECT_NODE> {
  autoSelect: boolean
}

export function setAutoSelectNode(autoSelect: boolean): SetAutoSelectNodeAction {
  return {
    type: AppActionType.SET_AUTO_SELECT_NODE,
    autoSelect,
  }
}

export interface SetFormNodeAction extends Action<AppActionType.SET_FORM_NODE> {
  node: any
}

export function setFormNode(node: any): SetFormNodeAction {
  return {
    type: AppActionType.SET_FORM_NODE,
    node,
  }
}

export interface SetConnectErrorAction extends Action<AppActionType.SET_CONNECT_ERROR> {
  error?: Error
}

export function setConnectError(error?: Error): SetConnectErrorAction {
  return {
    type: AppActionType.SET_CONNECT_ERROR,
    error,
  }
}
