import { makeStyles, TableCell, Theme } from '@material-ui/core'
import { TooltipHint } from 'js/components'
import React, { ReactElement } from 'react'
import { IndivCell } from './../validatorsConfig'

const TooltipCell: React.FunctionComponent<IndivCell> = (
  props: IndivCell,
): ReactElement<IndivCell> => {
  const { align, label, tooltip, underline } = props
  const classes = useStyles()

  if (tooltip) {
    return (
      <TableCell align={align || 'left'}>
        <TooltipHint title={tooltip} className={classes.tooltip}>
          <span className={underline ? classes.underline : ''}>
            {label}
          </span>
        </TooltipHint>
      </TableCell>
    )
  }
  return (
    <TableCell align={align || 'left'}>
      <span className={underline ? classes.underline : ''}>
        {label}
      </span>
    </TableCell>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    cursor: 'pointer',
  },
  underline: {
    textDecorationColor: theme.palette.text.disabled,
    textDecorationLine: 'underline',
    textDecorationStyle: 'dashed',
    textUnderlinePosition: 'under',
  },
}))

export default TooltipCell
