import { makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import TutorialDialog from 'js/components/TutorialDialog'
import { Tutorial, TutorialDialogProps } from 'js/components/TutorialDialog/TutorialDialog'
import { TutorialKeys } from 'js/constants'
import React, { ReactElement } from 'react'
import { Step1 } from './components'

type Props = TutorialDialogProps

const TUTORIAL_KEY = TutorialKeys.DepositProposal

const tutorials: Tutorial[] = [{
  TutorialContent: Step1,
  title: 'Before submitting a proposal',
  nextButtonLabel: 'Deposit',
  nextAction: 'close',
}]

const DepositProposal: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> | null => {
  const { className, children, ...rest } = props
  const classes = useStyles(props)

  return (
    <TutorialDialog
      tutorialKey={TUTORIAL_KEY}
      tutorials={tutorials}
      {...rest}
      className={clsx(classes.root, className)}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
  },
}))

export default DepositProposal
