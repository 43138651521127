import { Box, makeStyles, Theme } from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'
import BigNumber from 'bignumber.js'
import { makeMoneyLabel } from 'js/utils'
import React, { ReactElement } from 'react'

interface Props {
  denom?: string
  amount?: string
}

const AmountLabel: React.FunctionComponent<Props> = ({
  denom,
  amount: amountString,
}: Props): ReactElement<Props> | null => {
  const classes = useStyles()
  if (['', null, undefined].includes(amountString)) return null
  const amount = new BigNumber(amountString!)
  return (
    <Box component="span" className={classes.fees}>
      {makeMoneyLabel(amount, { currency: denom })}
      <Box component="span" className={classes.denom}>
        {denom?.toUpperCase()}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  fees: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  denom: {
    color: grey[500],
    marginLeft: theme.spacing(1),
  },
}))

export default AmountLabel
