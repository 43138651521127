import { TableRow } from '@material-ui/core'
import { TableCellInfo, TableSectionCell } from 'js/components'
import { bnOrZero } from 'js/utils'
import React, { ReactElement } from 'react'

interface Props {
  model: any
}

const FundingTableRender: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { model } = props
  const fundingRate = bnOrZero(model?.fundingRate).toFixed(6)
  const cellData: TableCellInfo[] = [
    {
      align: 'inherit',
      content: model?.market,
      to: `/market/${encodeURIComponent(model?.market)}`,
    },
    {
      align: 'inherit',
      content: model?.lastFundingAt,
      type: 'date',
    },
    {
      content: fundingRate,
    }
  ]

  return (
    <TableRow key={model?.market} hover>
      {
        cellData.map((cell: TableCellInfo, index: number) => (
          <TableSectionCell
            key={index}
            {...cell}
          />
        ))
      }
    </TableRow>
  )
}

export default FundingTableRender
