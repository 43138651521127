import { HTTP, PathParams } from './http'

export interface RequestResult {
  status: number
  statusText: string
  url: string
  headers: Headers
  data?: any
}

export class RequestError extends Error {
  public result: RequestResult
  constructor(result: RequestResult, message?: string) {
    super(message)
    this.result = result
    this.message = message || result.statusText
  }
}

const parseResponse = async (response: Response) => {
  const { status, statusText, headers, url } = response
  const result: RequestResult = { status, statusText, headers, url }
  try {
    const responseJson = await response.json()
    result.data = responseJson
  } catch (e) { }
  if (response.status >= 400 && response.status < 600) {
    throw new RequestError(result, result.data?.error || 'unknown error')
  }

  return result
}

export type APIRequester = (options?: RequestInit) => Promise<RequestResult>
export interface APIExecutor {
  get: APIRequester
  post: APIRequester
  del: APIRequester
  multipost: APIRequester
}
export interface EndpointMap {
  [index: string]: string
}
export interface APIHandler<M extends EndpointMap> {
  path: (
    path: keyof M,
    routeParams?: PathParams,
    queryParams?: PathParams,
  ) => APIExecutor
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default <M extends EndpointMap>(
  apiPrefix: string,
  endpoints: M,
): APIHandler<M> => {
  const http = new HTTP(apiPrefix, endpoints)
  const executor = (url: string): APIExecutor => ({
    get: async (options: RequestInit = {}) =>
      await http.get({ url, ...options }).then(parseResponse),
    post: async (options: RequestInit = {}) =>
      await http.post({ url, ...options }).then(parseResponse),
    del: async (options: RequestInit = {}) =>
      await http.del({ url, ...options }).then(parseResponse),
    multipost: async (options: RequestInit = {}) =>
      await http.multiPost({ url, ...options }).then(parseResponse),
  })
  return {
    path: (
      path: keyof M,
      routeParams?: PathParams,
      queryParams?: PathParams,
    ): APIExecutor => {
      const url = http.path(path, routeParams, queryParams)
      return executor(url)
    },
  }
}
