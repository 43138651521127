import { fieldTypes } from '../../GenericProposal/Helpers/types'
import { getErrorMessages, getInputConstraints } from '../../Helpers/InputConstraints'

export const requiredFields = [
  'blockchain',
  'name',
  'symbol',
  'decimals',
  'tokenAddress',
]

const basicConfig = {
  symbol: {
    id: 'symbol',
    type: fieldTypes.text,
    constraints: {
      validPattern: true,
    },
  },
  decimals: {
    id: 'decimals',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 18,
      onlyInteger: true,
    },
  },
}

export function createTokenValidator(formState: any) {
  return getErrorMessages(formState, getInputConstraints(basicConfig))
}
