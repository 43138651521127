import { FormControl, InputAdornment, InputLabel, SvgIcon, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutlineRounded'
import BigNumber from 'bignumber.js'
import customToast from 'js/components/Toast/Toast'
import { TaskName } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { bnOrZero, makeMoneyLabel } from 'js/utils'
import React, { useState } from 'react'
import { CustomDialog, CustomTable } from '../components'
import CustomInput from '../CustomInput'

interface Props {
  isOpen: boolean
  proposalID: string
  proposalTitle: string
  proposalTopic: string
  currentDeposit: string
  onClose: () => void
}

const DepositDialog: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const {
    isOpen,
    proposalTitle,
    proposalTopic,
    currentDeposit,
    onClose,
  } = props

  const [depositAmount, setDepositAmount] = useState('')
  const [runDeposit, loading, error] = useAsyncTask(TaskName.DepositProposal)

  const depositParams = useRedux((state) => state.governance.depositParams)
  const sdk = useRedux(state => state.core.carbonSDK)
  const minDeposit = depositParams?.minDeposit?.find((deposit) => deposit.denom === 'swth')
  const minDepositAmount = minDeposit?.amount ?? '-'

  const handleClose = () => {
    setDepositAmount('')
    onClose()
  }

  const handleChangeDepositAmount = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setDepositAmount(event.target.value as string)
  }

  function onDeposit(): void {
    runDeposit(async () => {
      if (!sdk?.wallet) return
      const result = await sdk.gov.deposit({
        proposalId: parseInt(props.proposalID),
        amount: bnOrZero(depositAmount).shiftedBy(8).toNumber(),
        denom: 'swth',
      })
      if (result && !error) {
        customToast('Notification', 'Deposit sucessfully placed')
        handleClose()
      }
    })
  }

  const proposalTopicText = proposalTopic ? `[${proposalTopic}] ` : ''

  return (
    <>
      <CustomDialog
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={onDeposit}
        submitLabel={'Deposit'}
        submitLoading={loading}
        miniTitle={'Deposit for'}
        bigTitle={`${proposalTopicText}${proposalTitle}`}
        intro={
          'You can support this proposal by contributing to the deposit. When it reaches the minimum deposit amount of SWTH, the proposal will enter the voting period.'
        }
        content={
          <>
            <div className={classes.inputContainer}>
              <FormControl fullWidth>
                <InputLabel shrink>Deposit Amount</InputLabel>
                <CustomInput
                  onChange={handleChangeDepositAmount}
                  value={depositAmount}
                  endAdornment={
                    <InputAdornment position="end">SWTH</InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className={classes.warningContainer}>
              <SvgIcon
                className={classes.warningIcon}
                component={ErrorOutlineIcon}
                fontSize="small"
              />
              Deposit amounts are at risk of being burnt. Learn more about the
              potential risks here.
            </div>
            <CustomTable
              headerAndCells={[
                {
                  header: 'Current Deposit',
                  cell: `${makeMoneyLabel(new BigNumber(currentDeposit || 0))} SWTH`,
                  cellClass: classes.cellClass,
                },
                {
                  header: 'To Reach Min Deposit',
                  cell: `${makeMoneyLabel(new BigNumber(minDepositAmount || 0))} SWTH`,
                  cellClass: classes.cellClass,
                },
                {
                  header: 'Network Fee',
                  cell: '1 SWTH',
                  cellClass: classes.cellClass,
                },
              ]}
            />
            <Typography variant='body1' color='error'>{error?.message}</Typography>
          </>
        }
      />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  cellClass: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'right',
    },
  },
  inputContainer: {
    marginTop: '2.5rem',
  },
  warningContainer: {
    marginTop: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '.75rem',
    letterSpacing: '-.0125rem',
    lineHeight: '.875rem',
  },
  warningIcon: {
    margin: '0 0.3rem 0 0',
  },
  tableContainer: {
    margin: '2rem 0',
    '& div:last-child': {
      border: 0,
    },
  },
  tableRow: {
    display: 'flex',
    borderBottom: 'solid 1px #073C50',
    padding: '0.8125rem 0',
    fontSize: '.875rem',
    fontWeight: 'bold',
    letterSpacing: '-.0125rem',
    lineHeight: '1.125rem',
  },
  tableLabel: {
    width: '40%',
  },
  tableValue: {
    width: '60%',
  },
}))

export default DepositDialog
