import { fieldTypes } from '../../GenericProposal/Helpers/types'
import { getErrorMessages, getInputConstraints } from '../../Helpers/InputConstraints'

export const weightRequiredFields = ['poolID', 'weight']

const weightConfig = {
  weight: {
    id: 'weight',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
      onlyInteger: true,
    },
  },
}

export function setPoolRewardWeightsValidator(formState: any) {
  const errors: string[] = []
  const weights = formState.weights
  weights.forEach((weight: any) => {
    const weightErrors = getErrorMessages(weight, getInputConstraints(weightConfig))
    weightErrors.forEach((error) => errors.push('Pool ID ' + weight.poolID + ': ' + error))
  })
  return errors
}
