import { Box, BoxProps, Divider, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { DataLoadSegment, JSONBlock } from 'js/components'
import { useTaskSubscriber } from 'js/hooks'
import React from 'react'

interface Props extends BoxProps {
  headerAndCells: {
    header: string | React.ReactNode
    headerClass?: string
    cell: string | React.ReactNode
    cellClass?: string
  }[]
  loadname?: string[]
  removeDivider?: boolean
}

const CustomTable: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { headerAndCells, className, removeDivider, loadname = [], ...rest } = props
  const [loading] = useTaskSubscriber(...loadname)

  return (
    <Box
      {...rest}
      className={clsx(classes.root, className)}
    >
      <DataLoadSegment loading={loading} filter>
        {headerAndCells.map(
          (headerAndCell, index) => {
            let cell = headerAndCell.cell
            // Use JSONBlock to display objects and not React elements
            if (typeof headerAndCell.cell === 'object' && !React.isValidElement(headerAndCell.cell)) {
              cell = <JSONBlock content={headerAndCell.cell} />
            }
            
            return (
            <React.Fragment key={index}>
              <Box display='flex' paddingY={1.5}>
                <Typography variant='body1' className={clsx(classes.label, headerAndCell?.headerClass)}>
                  {headerAndCell.header}
                </Typography>
                <Typography variant='body1' className={clsx(classes.value, headerAndCell?.cellClass)}>
                  {React.isValidElement(headerAndCell.cell) ? (
                    headerAndCell.cell
                  ) : (<>{cell}</>)}
                </Typography>
              </Box>
              {index + 1 < headerAndCells.length && !removeDivider && (<Divider />)}
            </React.Fragment>
          )},
        )}
      </DataLoadSegment>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '2rem 0',
    '& div:last-child': {
      border: 0,
    },
  },
  label: {
    width: '40%',
  },
  value: {
    width: '60%',
    textAlign: 'left',
  },
}))

export default CustomTable
