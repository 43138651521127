import { Box, BoxProps, Container, useMediaQuery } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { ProposalStep } from "./components";

interface Props extends BoxProps {
  currentStep?: number
  stepTitles: string[]
}

const ProposalStepProgress: React.FC<Props> = (props: Props) => {
  const { children, className, currentStep = 0, stepTitles, ...rest } = props;
  const classes = useStyles();
  const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Container className={classes.container} maxWidth="lg">
        <Box className={classes.steps}>
          {
            stepTitles.map((title, index) => (
              <React.Fragment key={index}>
                <ProposalStep className={classes.step} step={index + 1} active={currentStep >= index}>{title}</ProposalStep>
                {
                  index !== stepTitles.length - 1 && !isMdDown && (
                    <Box className={classes.divider} /> 
                  )
                }
              </React.Fragment>
            ))
          }
        </Box>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  divider: {
    flex: 1,
    borderBottom: `1px dashed ${theme.palette.primary.light}`,
    margin: theme.spacing(0, 2),
  },
  steps: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  step: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
}))

export default ProposalStepProgress;
