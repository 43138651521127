import { Models } from 'carbon-js-sdk'
import { CrossChainTransferDetailed } from 'carbon-js-sdk/lib/hydrogen'
import { Record, RecordOf } from 'immutable'
import { RestModels } from 'tradehub-api-js'
import { AccountAction, AccountActionType } from '../actions/account'
import { PositionsResponse } from './position'
import { TransactionsResponse } from './transactions'
import { DelegationRewards } from 'js/utils'
import { TradeEvent } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/broker/export'
import { InternalTransfer } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/bank/export'
import { TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/export'
import { Profile } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/profile/export'
import { Order } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/export'

export interface TradesReponse {
  data: TradeEvent[],
  meta: {
    totalPages: number
  }
}

export interface InternalTransferResponse {
  data: InternalTransfer[],
  meta: {
    totalPages: number,
  }
}
export interface AccountProps {
  balance: TokenBalance[]
  transactions: TransactionsResponse
  internalTransfer: InternalTransferResponse
  tokens: RestModels.Token[]
  types: string[]
  profile?: Profile
  positions: PositionsResponse
  positionsHistory: PositionsResponse
  openOrders: Order[]
  orders: Order[]
  tradesByAccount: TradesReponse
  delegations: Models.Staking.DelegationResponse[]
  rewards: DelegationRewards[]
  address: string
  crossChainTransfers: CrossChainTransferDetailed[]
  disablePaginationForwardButton: boolean
  disableInternalTransferPaginationForwardButton: boolean
}

const makeState: Record.Factory<AccountProps> = Record<AccountProps>({
  balance: [],
  transactions: {
    data: [],
    meta: {
      totalPages: 0,
    }
  },
  internalTransfer: {
    data: [],
    meta: {
      totalPages: 0
    }
  },
  tokens: [],
  types: [],
  profile: undefined,
  positions: {
    data: [],
    meta: {
      totalPages: 0,
    },
  },
  positionsHistory: {
    data: [],
    meta: {
      totalPages: 0,
    },
  },
  openOrders: [],
  orders: [],
  tradesByAccount: {
    data: [],
    meta: {
      totalPages: 0,
    },
  },
  delegations: [],
  rewards: [],
  address: '',
  crossChainTransfers: [],
  disablePaginationForwardButton: false,
  disableInternalTransferPaginationForwardButton: false
})
export type AccountState = RecordOf<AccountProps>

function accountReducer(
  state: AccountState = makeState(),
  action: AccountAction,
): AccountState {
  switch (action.type) {
    case AccountActionType.CLEAR:
      return makeState()
    case AccountActionType.SET_BALANCE:
      return state.updateIn(['balance'], () => action.balance)
    case AccountActionType.SET_TRANSACTIONS:
      return state.updateIn(['transactions'], () => action.transactions)
    case AccountActionType.SET_INTERNAL_TRANSFER:
      return state.updateIn(['internalTransfer'], () => action.internalTransfer)
    case AccountActionType.SET_TYPES:
      return state.updateIn(
        ['types'],
        (): string[] => {
          return action.types
        },
      )
    case AccountActionType.SET_PROFILE:
      return state.updateIn(['profile'], () => action.profile)
    case AccountActionType.SET_POSITIONS:
      return state.updateIn(['positions'], () => action.positions)
    case AccountActionType.SET_POSITIONS_HISTORY:
      return state.updateIn(['positionsHistory'], () => action.positionsHistory)
    case AccountActionType.SET_OPEN_ORDERS:
      return state.updateIn(['openOrders'], () => action.openOrders)
    case AccountActionType.SET_ORDERS:
      return state.updateIn(['orders'], () => action.orders)
    case AccountActionType.SET_ACCOUNT_TRADES:
      return state.updateIn(['tradesByAccount'], () => action.trades)
    case AccountActionType.SET_DELEGATIONS:
      return state.updateIn(['delegations'], () => action.delegations)
    case AccountActionType.SET_REWARDS:
      return state.updateIn(['rewards'], () => action.rewards)
    case AccountActionType.SET_ADDRESS:
      return state.updateIn(['address'], () => action.address)
    case AccountActionType.SET_CROSSCHAIN_TRANSFERS:
      return state.updateIn(['crossChainTransfers'], () => action.crossChainTransfers)
    case AccountActionType.SET_DISABLE_PAGINATION_FORWARD_BUTTON:
      return state.updateIn(['disablePaginationForwardButton'], () => action.disablePaginationForwardButton)
    case AccountActionType.SET_INTERNAL_TRANSFER_DISABLE_PAGINATION_FORWARD_BUTTON:
      return state.updateIn(['disableInteralTransferPaginationForwardButton'], () => action.disableInteralTransferPaginationForwardButton)
    default:
      return state
  }
}

export default accountReducer
