import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { DataLoadSegment, ListTable, Section, TableCellInfo, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { RootState } from 'js/store'
import { bnOrZero } from 'js/utils'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import DelegationRow from './DelegationRow'

export interface OrdersProps {
  title: string
}

interface Props extends OrdersProps { }

const Delegations: React.FunctionComponent<Props> = ({
  title,
}: Props): ReactElement<Props> => {
  const delegations = useSelector((state: RootState) => state.account.delegations)
  const [loading] = useTaskSubscriber(TaskNames.Account.Delegations)
  const valAddrMap = useRedux((state) => state.core.valAddrMap)
  const headerCells: TableCellInfo[] = [
    {
      align: 'inherit',
      content: 'Validator',
    },
    {
      content: 'Amount',
    }
  ]

  return (
    <Section title={title}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              {headerCells.map((cell: TableCellInfo) => (
                <TableCell align={cell?.align} key={cell?.content}>
                  {cell?.content}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              delegations.filter((delegation) => bnOrZero(delegation?.balance?.amount).gt(0)).map((delegation, index) => (
                <DelegationRow
                  delegation={delegation}
                  valAddrMap={valAddrMap}
                  key={(delegation?.delegation?.validatorAddress ?? '') + index}
                />
              ))
            }
          </TableBody>
        </ListTable>
        {!delegations?.length && (
          <TableEmptyState itemName="delegators" />
        )}
      </DataLoadSegment>
    </Section>
  )
}

export default Delegations
