import { Theme, makeStyles } from '@material-ui/core'
import { updateOpenOrders, updateOrders } from 'js/actions/account'
import { Orders } from 'js/components'
import { TaskNames } from 'js/constants'
import { RootState } from 'js/store'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'

export interface OrdersProps {
}

interface Props extends OrdersProps { }

const OrdersWrapper: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const openOrders = useSelector((state: RootState) => state.account.openOrders)
  const orders = useSelector((state: RootState) => state.account.orders)
  const markets = useSelector((state: RootState) => state.app.markets)
  const classes = useStyles()
  return (
    <>
      <Orders
        filterStatus={false}
        orders={openOrders}
        title="Open Orders"
        action={updateOpenOrders}
        markets={markets}
        loadName={[
          TaskNames.Account.OpenOrders,
          TaskNames.Account.OpenOrdersFilter,
        ]}
        loadCondition={!openOrders}
        sectionClass={classes.sectionClass}
      />
      <Orders
        filterStatus
        orders={orders}
        title="Order History"
        action={updateOrders}
        markets={markets}
        loadName={[
          TaskNames.Account.OrderList,
          TaskNames.Account.OrderFilter,
        ]}
        loadCondition={!orders}
      />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  sectionClass: {
    marginBottom: theme.spacing(3),
  },
}))

export default OrdersWrapper
