import { fieldTypes } from '../../GenericProposal/Helpers/types'

export const setCommitmentCurveFormRequiredFields = ['maxDuration', 'maxRewardMultiplier']

export const setCommitmentCurveConfig = {
  maxDuration: {
    id: 'maxDuration',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      onlyInteger: true,
    },
  },
  maxRewardMultiplier: {
    id: 'maxRewardMultiplier',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 100,
      onlyInteger: true,
    },
  },
}
