import { CarbonSDK, Insights } from 'carbon-js-sdk'
import { QueryCoinBalancesResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/insurance/query'
import { QueryAllMarketResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/query'
import { MarketStats } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/marketstats/marketstats'
import { QueryMarketStatsResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/marketstats/query'
import { QueryGetActiveAccountsResponse } from 'carbon-js-sdk/lib/insights'
import { CARBON_GENESIS_BLOCKTIME, TaskNames } from 'js/constants'
import { parseMarketStats } from 'js/helpers'
import { MarketStatItem } from 'js/models/utils'
import { actions } from 'js/store'
import { BN_ZERO, bnOrZero, logger } from 'js/utils'
import Long from 'long'
import moment from 'moment'
import { SagaIterator } from 'redux-saga'
import { Effect, call, put, race, spawn, take, takeLatest } from 'redux-saga/effects'
import { RestModels } from 'tradehub-api-js'
import { AppActionType } from '../actions/app'
import { DashboardActionType, UpdateDateFiltersActiveUsersChartAction, UpdateDateFiltersDistributionRewardsChartAction, UpdateDateFiltersTotalUsersChartAction, clear, setActiveUsersChartData, setActiveWallets, setDistributionRewardsChartData, setInsuranceBalances, setRewardsDistributed, setTotalUsersChartData, setVolume } from '../actions/dashboard'
import Saga from './Saga'
import { emitEvent } from './WebSocket'
import { getInitializedSDK, runSagaTask, selectState, waitForTmWsClient, waitforSDK } from './helper'

export default class Dashboard extends Saga {
  private readonly isMobile: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchInsuranceBalances],
      [this, this.listenTransactions],
      [this, this.fetchMarketStats],
      [this, this.fetchActiveWallets],
      [this, this.fetchRewardsDistributed],
      [this, this.watchSetNetwork],
      [this, this.watchChartUpdates] // chart watcher
    ].map(spawn)
  }

  private *watchChartUpdates(): SagaIterator {
    yield takeLatest(DashboardActionType.UPDATE_DATE_FILTERS_TOTAL_USERS_CHART,
      (action: UpdateDateFiltersTotalUsersChartAction) => this.handleTotalUsersChart(action.options))
    yield takeLatest(DashboardActionType.UPDATE_DATE_FILTERS_ACTIVE_USERS_CHART,
      (action: UpdateDateFiltersActiveUsersChartAction) => this.handleActiveUsersChart(action.options))
    yield takeLatest(DashboardActionType.UPDATE_DATE_FILTERS_DISTRIBUTION_REWARDS_CHART,
      (action: UpdateDateFiltersDistributionRewardsChartAction) => this.handleDistributionRewardsChart(action.options))
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, function* () {
      yield put(clear());
    })
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *listenTransactions(): any {
    const listener = (network: CarbonSDK.Network) => ({
      next: (value: any) => {
        emitEvent?.({ type: 'tx', result: [value], network })
      },
      error: (err: any) => {
        console.error("tx subscription error")
        console.error(err)
      },
    })

    while (true) {
      logger("dashboard saga", "listenTransactions")
      let subscription = null;
      try {
        const tmWsClient = yield* waitForTmWsClient()
        const network = (yield* waitforSDK())?.network;

        logger("dashboard saga", "subscribing to txs")
        const stream = tmWsClient?.subscribeTx()
        subscription = yield call([stream, stream.subscribe], listener(network));

        yield race({
          tmWsClientChanged: take(actions.Core.ActionType.CORE_UPDATE_TM_WS_CLIENT),
        });
      } catch (error) {
        console.error("listenTransactions error")
        console.error(error)
      } finally {
        logger("dashboard saga", "unsubscribing txs", subscription)
        subscription?.unsubscribe?.();
      }
    }
  }

  private *fetchInsuranceBalances(): any {
    yield runSagaTask(TaskNames.App.InsuranceSupply, function* () {
      logger("dashboard saga", "fetchInsuranceBalances runSagaTask")
      const sdk = yield* waitforSDK()
      const insuranceQueryClient = sdk.query.insurance

      const balanceResponse = (yield call([insuranceQueryClient, insuranceQueryClient.CoinBalances], {})) as QueryCoinBalancesResponse
      let totalInsuranceFund = bnOrZero()
      for (let insurance of balanceResponse?.coins) {
        const adjustedFund = sdk.token.toHuman(insurance?.denom, bnOrZero(insurance?.amount ?? 0))
        totalInsuranceFund = totalInsuranceFund.plus(adjustedFund)
      }
      yield put(setInsuranceBalances(totalInsuranceFund))
    })
  }

  private *fetchActiveWallets(): any {
    yield runSagaTask(TaskNames.Dashboard.Wallets, function* () {
      logger("dashboard saga", "fetchActiveWallets runSagaTask")
      const sdk = yield* waitforSDK()
      const activeAccountsResponse = (yield call([sdk.insights, sdk.insights.ActiveAccounts], {})) as Insights.InsightsQueryResponse<QueryGetActiveAccountsResponse>
      const latestTotalUsers = activeAccountsResponse.result.entries[0].last1DCount
      yield put(setActiveWallets(bnOrZero(latestTotalUsers)))
    })
  }

  private *fetchMarketStats(): any {
    yield runSagaTask(TaskNames.Dashboard.MarketStats, function* () {
      logger("dashboard saga", "fetchMarketStats runSagaTask")
      const sdk = yield* waitforSDK()

      const allMarketsInfo = (yield sdk.query.market.MarketAll({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })) as QueryAllMarketResponse
      const statsResponse = (yield sdk.query.marketstats.MarketStats({
        pagination: {
          limit: new Long(10000),
          offset: Long.UZERO,
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        }
      })) as QueryMarketStatsResponse
      const marketStatItems = statsResponse.marketstats.map((stat: MarketStats) => (
        parseMarketStats(stat)),
      );

      let volume24H = BN_ZERO
      let uniqueMarket: any = {}
      yield (sdk?.token.reloadUSDValues());
      marketStatItems.forEach((market: MarketStatItem) => {
        const marketItem = allMarketsInfo.markets.find((o) => o.id === market.market)
        if (!uniqueMarket[marketItem?.id ?? ""]) {
          uniqueMarket[marketItem?.id ?? ""] = 1
          const baseDenom = marketItem?.base ?? ""
          // const quoteDenom = marketItem?.quote ?? ""

          const symbolUsd = sdk?.token.getUSDValue(baseDenom) ?? BN_ZERO
          const adjustedVolume = sdk?.token.toHuman(baseDenom, market.dayVolume) ?? BN_ZERO
          const usdVolume = symbolUsd.times(adjustedVolume)
          volume24H = volume24H.plus(usdVolume)
        }
      });

      yield put(setVolume(bnOrZero(volume24H)))
      // yield put(setOI(oiBN)) //TODO: Check if this is needed
    })
  }

  // TODO: Migrate carbon insights
  private *fetchRewardsDistributed(): any {
    yield runSagaTask(TaskNames.Dashboard.Distribution, function* () {
      logger("dashboard saga", "fetchMarketStats fetchRewardsDistributed")
      const sdk = yield* getInitializedSDK()
      const network = (yield selectState((state) => state.app.network)) as CarbonSDK.Network
      const account = (network === CarbonSDK.Network.MainNet || network === CarbonSDK.Network.DevNet)
        ? 'swth1jv65s3grqf6v6jl3dp4t6c9t9rk99cd8cpw26x'
        : 'tswth1jv65s3grqf6v6jl3dp4t6c9t9rk99cd8ukl6rr'
      const rewards = (yield sdk.api.getRewardsDistributed({ account })) as RestModels.TokenAmount[]
      yield put(setRewardsDistributed(rewards))
    })
  }
  private *handleTotalUsersChart(options?: any): any {
    yield runSagaTask(TaskNames.Dashboard.TotalUsersChart, function* () {
      const sdk = yield* waitforSDK()
      const from = options
        ? moment(moment(options.startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
        : moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = options
        ? moment(moment(options.endDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
        : moment(moment(Date()).subtract(1, "days").format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const totalUsersResponse = (yield call([sdk.insights, sdk.insights.TotalUsers], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetTotalUsersResponse>
      const activeAccountsResponse = (yield call([sdk.insights, sdk.insights.ActiveAccounts], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetActiveAccountsResponse>
      yield put(setTotalUsersChartData({
        totalUsersChartData: totalUsersResponse.result.entries,
        activeAccountsChartData: activeAccountsResponse.result.entries,
      }))
    })
  }

  private *handleActiveUsersChart(options?: any): any {
    yield runSagaTask(TaskNames.Dashboard.ActiveUsersChart, function* () {
      const sdk = yield* waitforSDK()
      const fromGrowth = options
        ? moment(moment(options.startDate).subtract(1, "days").format('YYYY-MM-DDT00:00:00+00')).unix().toString()
        : moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).subtract(1, 'day').format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const untilGrowth = options
        ? moment(moment(options.endDate).add(1, "days").format('YYYY-MM-DDT00:00:00+00')).unix().toString()
        : moment(moment(Date()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const fromActive = options
        ? moment(moment(options.startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
        : moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).subtract(1, 'day').format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const untilActive = options
        ? moment(moment(options.endDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
        : moment(moment(Date()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const userGrowthResponse = (yield call([sdk.insights, sdk.insights.UserGrowth], {
        from: fromGrowth,
        until: untilGrowth,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetUserGrowthResponse>
      const activeAccountsResponse = (yield call([sdk.insights, sdk.insights.ActiveAccounts], {
        from: fromActive,
        until: untilActive,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetActiveAccountsResponse>
      yield put(setActiveUsersChartData({
        userGrowthChartData: userGrowthResponse.result.entries,
        activeAccountsChartData: activeAccountsResponse.result.entries,
      }))
    })
  }

  private *handleDistributionRewardsChart(options?: any): any {
    yield runSagaTask(TaskNames.Dashboard.DistributionRewardsChart, function* () {
      const sdk = yield* waitforSDK()
      const from = options
        ? moment(moment(options.startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
        : moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = options
        ? moment(moment(options.endDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
        : moment(moment(Date()).subtract(1, "days").format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const distributionRewardsResponse = (yield call([sdk.insights, sdk.insights.BalanceDistribution], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetBalanceDistributionResponse>
      yield put(setDistributionRewardsChartData(distributionRewardsResponse.result.entries))
    })
  }

}
