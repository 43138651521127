import { Insights } from 'carbon-js-sdk'
import { QueryAllOrderResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/query'
import { CARBON_GENESIS_BLOCKTIME, TaskNames } from 'js/constants'
import moment from 'moment'
import { SagaIterator } from 'redux-saga'
import { Effect, call, put, spawn, takeLatest } from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import { MarketsActionType, clear, setMarketsVolumeChartData, setOrders } from '../actions/markets'
import Saga from './Saga'
import { runSagaTask, waitforSDK } from './helper'

export default class Markets extends Saga {
  private readonly isMobile: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchMarketVolumeChart],
      [this, this.watchSetNetwork],
      [this, this.watchDateFilters],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *handleOrderFilters(action: any): Generator {
    yield runSagaTask(TaskNames.Markets.OrderFilter, function* () {
      const sdk = yield* waitforSDK()
      const orderQueryClient = sdk.query.order

      const ordersResponse = (yield call([orderQueryClient, orderQueryClient.OrderAll], {
        address: '',
        marketId: '',
        orderType: '',
        orderStatus: '',
      })) as QueryAllOrderResponse
      yield put(setOrders(ordersResponse.orders))
    });
  }

  private *fetchMarketVolumeChart(): any {
    if (this.isMobile) return
    yield runSagaTask(TaskNames.Markets.VolumeChart, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon 
      const from = moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(Date.now()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const marketsVolumeResponse = (yield call([sdk.insights, sdk.insights.MarketsVolume], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetMarketVolumeResponse>
      yield put(setMarketsVolumeChartData(marketsVolumeResponse.result.entries))
    })
  }

  private *watchDateFilters(): any {
    yield takeLatest(
      MarketsActionType.UPDATE_DATE_FILTERS_MARKETS_VOLUME_CHART,
      this.handleDateFiltersMarketsVolume,
    )
  }

  private *handleDateFiltersMarketsVolume(action: any): any {
    yield runSagaTask(TaskNames.Markets.DateFilter, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon 
      const from = moment(moment(action.options.startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(action.options.endDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const marketsVolumeResponse = (yield call([sdk.insights, sdk.insights.MarketsVolume], {
        from,
        until,
        interval: action.options?.interval ?? "day"
      })) as Insights.InsightsQueryResponse<Insights.QueryGetMarketVolumeResponse>
      yield put(setMarketsVolumeChartData(marketsVolumeResponse.result.entries))
    })
  }

}
