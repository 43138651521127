import { BoxProps, makeStyles, TableBody, TableCell, TableRow, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { DataLoadSegment, ListTable, Section, TooltipHint } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { BIG_ZERO, makeMoneyLabel, makeTimeLeftLabel, parseNumber } from 'js/utils'
import React, { ReactElement } from 'react'

interface Props extends BoxProps {
}

const ProposalInfo: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const depositParams = useRedux((state) => state.governance.depositParams)
  const [loading] = useTaskSubscriber(TaskNames.Governance.DepositParams)

  return (
    <Section marginTop={2} title="Proposal Information" {...rest} className={clsx(classes.root, className)}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableBody>
            <TableRow>
              <TableCell>
                Deposit Period
                <TooltipHint title="During deposit period, the proposal must reach the minimum deposit requirement of 10,000 SWTH. Once the requirement is met, the proposal automatically enters into the Voting Phase." />
              </TableCell>
              <TableCell>{makeTimeLeftLabel(parseNumber(depositParams?.maxDepositPeriod?.seconds?.toNumber() ?? 0))}</TableCell>
            </TableRow>
            {depositParams?.minDeposit.map((minToken, index) => (
              <TableRow key={index}>
                <TableCell>Min Deposit</TableCell>
                <TableCell>{makeMoneyLabel(parseNumber(minToken.amount, BIG_ZERO)!)} {minToken.denom.toUpperCase()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ListTable>
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))

export default ProposalInfo
