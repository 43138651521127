import { BIG_ZERO, parseNumber } from 'js/utils'
import { fieldTypes } from '../../GenericProposal/Helpers/types'
import { getErrorMessages, getInputConstraints } from '../../Helpers/InputConstraints'

export const updateSpotMarketRequiredFields = [
  'name',
  'displayName',
  'marketDescription',
  'minQuantity',
]

export const updateFuturesMarketRequiredFields = updateSpotMarketRequiredFields.concat([
  'riskStepSize',
  'initialMarginBase',
  'initialMarginStep',
  'maintenanceMarginRatio',
  'tradingBandwidth',
  'maxLiquidationOrderTicket',
  'maxLiquidationOrderDuration',
  'impactSize',
  'markPriceBand',
  'lastPriceProtectedBand',
])

const basicConfig = {
  minQuantity: {
    id: 'minQuantity',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
    },
  },
  lotSize: {
    id: 'lotSize',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
    },
  },
}

const futuresConfig = {
  riskStepSize: {
    id: 'riskStepSize',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
    },
  },
  initialMarginBase: {
    id: 'initialMarginBase',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
    },
  },
  initialMarginStep: {
    id: 'initialMarginStep',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 0,
    },
  },
  maintenanceMarginRatio: {
    id: 'maintenanceMarginRatio',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      lessThan: 1,
    },
  },
  tradingBandwidth: {
    id: 'tradingBandwidth',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      lessThan: 20000,
    },
  },
  maxLiquidationOrderTicket: {
    id: 'maxLiquidationOrderTicket',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      onlyInteger: true,
    },
  },
  maxLiquidationOrderDuration: {
    id: 'maxLiquidationOrderDuration',
    type: fieldTypes.number,
    constraints: {
      greaterThanOrEqualTo: 30,
      onlyInteger: true,
    },
  },
  impactSize: {
    id: 'impactSize',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      onlyInteger: true,
    },
  },
  markPriceBand: {
    id: 'markPriceBand',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      lessThan: 20000,
      onlyInteger: true,
    },
  },
  lastPriceProtectedBand: {
    id: 'lastPriceProtectedBand',
    type: fieldTypes.number,
    constraints: {
      greaterThan: 0,
      lessThan: 20000,
      onlyInteger: true,
    },
  },
}

export function updateMarketValidator(formState: any) {
  let errors = getErrorMessages(formState, getInputConstraints(basicConfig))

  if (formState.marketType === 'futures') {
    errors = errors.concat(getErrorMessages(formState, getInputConstraints(futuresConfig)))

    const riskStepSize = parseNumber(formState.riskStepSize, BIG_ZERO)!
    const initialMarginStep = parseNumber(formState.initialMarginStep, BIG_ZERO)!
    if (!(riskStepSize.isZero() === initialMarginStep.isZero()) ||
      !(riskStepSize.isGreaterThan(0) && initialMarginStep.isGreaterThan(0))) {
      errors.push('Risk step size and initial margin step must be either both zero or both not zero')
    }
  }
  return errors
}
