import { APIPosition } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/position/position'
import { Record, RecordOf } from 'immutable'
import { PositionActon, PositionActionType } from 'js/actions/position'

export interface PositionsResponse {
  data: APIPosition[]
  meta: {
    totalPages: number
  }
}
export interface PositionProps {
  position?: APIPosition
}

const makeState: Record.Factory<PositionProps> = Record<PositionProps>({
  position: undefined,
})
export type PositionState = RecordOf<PositionProps>

export default function positionReducer(
  state: PositionState = makeState(),
  action: PositionActon,
): PositionState {
  switch (action.type) {
    case PositionActionType.CLEAR:
      return makeState()
    case PositionActionType.SET_POSITION:
      return state.updateIn(
        ['position'],
        () => {
          return action.position
        },
      )
    default:
      return state
  }
}
