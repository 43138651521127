import { InputAdornment, InputBase, InputBaseProps, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { ReactComponent as Hide } from './Hide.svg'
import { ReactComponent as Show } from './Show.svg'

interface Props extends InputBaseProps {
  showText: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isDecrypting: boolean
}

const LoginInput: React.FC<Props> = (props: Props) => {
  const { showText, onChange, isDecrypting, ...rest } = props
  const classes = useStyles()
  const [showVisibilityToggle, setShowVisibilityToggle] = useState(showText)
  const toggleShowVisibilityToggle = () =>
    setShowVisibilityToggle(!showVisibilityToggle)

  return (
    <InputBase
      className={classes.root}
      fullWidth
      type={showVisibilityToggle ? 'text' : 'password'}
      onChange={onChange}
      disabled={isDecrypting}
      endAdornment={
        <InputAdornment position="end" onClick={toggleShowVisibilityToggle}>
          {showVisibilityToggle ? (
            <Show className={classes.icon} />
          ) : (
            <Hide className={classes.icon} />
          )}
        </InputAdornment>
      }
      {...rest}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.MuiInputBase-root': {
      margin: theme.spacing(3, 0, 0),
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    '& .MuiInputBase-input': {
      padding: '0.625rem 0.75rem',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginRight: theme.spacing(1.25),
    },
  },
  icon: {
    cursor: 'pointer',
    width: '1.125rem',
    '& path': {
      fill: theme.palette.secondary.main,
    },
  },
}))

export default LoginInput
