import { Action } from 'redux'

export enum ActionType {
  ADD_BACKGROUND_LOADING = 'LAYOUT_ADD_BACKGROUND_LOADING',
  REMOVE_BACKGROUND_LOADING = 'LAYOUT_REMOVE_BACKGROUND_LOADING',
}

export type LayoutAction =
  | AddBackgroundLoadingAction
  | RemoveBackgroundLoadingAction

export interface AddBackgroundLoadingAction extends Action<ActionType.ADD_BACKGROUND_LOADING> {
  name: string
  uuid: string
}
export function addBackgroundLoading(name: string, uuid: string) {
  return {
    type: ActionType.ADD_BACKGROUND_LOADING,
    name, uuid,
  }
}

export interface RemoveBackgroundLoadingAction extends Action<ActionType.REMOVE_BACKGROUND_LOADING> {
  uuid: string
}
export function removeBackgroundLoading(uuid: string) {
  return {
    type: ActionType.REMOVE_BACKGROUND_LOADING,
    uuid,
  }
}
