import { UpdatePoolProposalFormState } from 'js/models'
import store from 'js/store'
import { escapeHtmlGo, bnOrZero } from 'js/utils'
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { CarbonTx } from "carbon-js-sdk"
import { MsgSubmitProposal } from "carbon-js-sdk/lib/codec/cosmos/gov/v1/tx"
import { MsgUpdatePool } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/tx'

export const requiredFields = ['poolID', 'swapFee', 'numQuotes']

export function updatePoolProposalValue(proposer: string | undefined, initialDeposit: DecCoin[], authority: string): MsgSubmitProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as UpdatePoolProposalFormState

  const { title, summary, metadata, poolID, swapFee, numQuotes } = formState

  const updatePoolParams = {
    poolId: parseInt(poolID),
    swapFee: bnOrZero(swapFee).shiftedBy(-2).toString(),
    numQuotes: parseInt(numQuotes)
  }

  const msg = MsgUpdatePool.fromPartial({
    creator: authority,
    updatePoolParams
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    initialDeposit,
    expedited: false,
    proposer: proposer ?? "",
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgUpdatePool,
        value: MsgUpdatePool.encode(msg).finish()
      }
    ],
  }

  return value
}
