import BigNumber from 'bignumber.js'
import { CarbonSDK, Models } from 'carbon-js-sdk'
import { Record, RecordOf } from 'immutable'
import { returnUrlNet, SimpleMap } from 'js/utils'
import { AppAction, AppActionType } from '../actions/app'
import { NodeModel } from "js/reducers/nodes";
import { SimpleBlock } from 'js/models'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/export'
import { Result } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/oracle/export'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/export'
import { TokenPrice } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/pricing/pricing'
export type MenuDropValue = string | null

interface Props {
  network: CarbonSDK.Network
  delegatorsMap: SimpleMap<Models.Staking.DelegationResponse[]>
  signingInfo: SimpleMap<Models.Slashing.ValidatorSigningInfo>
  totalBonded: BigNumber
  totalStaked: BigNumber
  markets: SimpleMap<Market>
  geckoPrices: any
  neoPrice: any
  oracles: Result[]
  swthSupply: BigNumber
  tokensMap: SimpleMap<Token>
  nodes: any[],
  mode: string
  total: BigNumber
  latestBlock?: SimpleBlock
  avgBlockTime: number
  readonly isSigningModalShown: boolean
  readonly signError?: string
  netDrop: boolean
  walletDrop: boolean
  menuDrop: MenuDropValue
  isShowNodeInfoForm: boolean
  customNodes: NodeModel[]
  selectedNodes: SimpleMap<NodeModel>
  autoSelectNode: boolean
  formNode?: any
  allOracleTokenPrices: TokenPrice[]
  connectError?: Error
}

const params = new URLSearchParams(window.location.search)
const network = CarbonSDK.parseNetwork(returnUrlNet(params.get('net')))!

export const defaultGeckoPrice: any = { usd: 0, btc: 0, eth: 0, sgd: 0 }
export const defaultNeoPrice: any = { usd: 0 }

export const makeState: Record.Factory<Props> = Record<Props>({
  network,
  delegatorsMap: {},
  signingInfo: {},
  totalBonded: new BigNumber(0),
  totalStaked: new BigNumber(0),
  markets: {},
  geckoPrices: {
    switcheo: defaultGeckoPrice,
    ethereum: defaultGeckoPrice,
    bitcoin: defaultGeckoPrice,
    dai: defaultGeckoPrice,
  },
  neoPrice: defaultNeoPrice,
  oracles: [],
  swthSupply: new BigNumber(0),
  tokensMap: {},
  allOracleTokenPrices: [],
  nodes: [],
  mode: 'bright',
  total: new BigNumber(0),
  latestBlock: undefined,
  avgBlockTime: 0,
  isSigningModalShown: false,
  signError: undefined,
  netDrop: false,
  walletDrop: false,
  menuDrop: null,
  isShowNodeInfoForm: false,
  customNodes: [],
  selectedNodes: {},
  autoSelectNode: true,
  formNode: undefined,
  connectError: undefined,
})
export type AppState = RecordOf<Props>

function appReducer(
  state: AppState = makeState(),
  action: AppAction,
): AppState {
  switch (action.type) {
    case AppActionType.SET_NETWORK:
      const network = action.network
      return state.merge({ network })
    case AppActionType.SET_DELEGATORS_MAP:
      return state.updateIn(['delegatorsMap'], () => action.delegators)
    case AppActionType.SET_SIGNING_INFO:
      return state.updateIn(['signingInfo'], () => action.signingInfo)
    case AppActionType.SET_TOTAL_BONDED:
      return state.updateIn(['totalBonded'], (): [] => {
        return action.totalBonded
      })
    case AppActionType.SET_TOTAL_STAKED:
      return state.updateIn(['totalStaked'], (): [] => {
        return action.totalStaked
      })
    case AppActionType.SET_MARKETS:
      return state.updateIn(['markets'], () => action.markets)
    case AppActionType.SET_GECKO_PRICES:
      return state.updateIn(['geckoPrices'], (): {} => {
        return action.geckoPrices
      })
    case AppActionType.SET_NEO_PRICE:
      return state.updateIn(['neoPrice'], (): {} => {
        return action.neoPrice
      })
    case AppActionType.SET_ORACLE_RESULTS:
      return state.updateIn(['oracles'], () => action.oracles)
    case AppActionType.SET_SWTH_SUPPLY:
      return state.updateIn(['swthSupply'], (): any => {
        return action.swthSupply
      })
    case AppActionType.SET_LATEST_BLOCK:
      return state.updateIn(['latestBlock'], (): any => {
        return action.latestBlock
      })
    case AppActionType.SET_AVG_BLOCK_TIME:
      return state.updateIn(['avgBlockTime'], (): any => {
        return action.avgBlockTime
      })
    case AppActionType.SET_TOKENS_MAP:
      return state.updateIn(['tokensMap'], () => action.tokens)
    case AppActionType.SET_NODES:
      return state.set('nodes', action.nodes)
    case AppActionType.SET_MODE:
      // localStorage.setItem(AppActionType.SET_MODE, action.mode)
      return state.set('mode', action.mode)
    case AppActionType.SET_TOTAL_SUPPLY:
      return state.set('total', action.total)
    case AppActionType.REQUEST_SIGN:
      return state.set('isSigningModalShown', true)
    case AppActionType.SET_SIGN_ERROR:
      return state.set('signError', action.message)
    case AppActionType.FINISH_SIGN:
      return state.set('isSigningModalShown', false)
    case AppActionType.SET_NET_DROP_CLOSE:
      return state.set('netDrop', false)
    case AppActionType.SET_NET_DROP_OPEN:
      return state.set('netDrop', true)
    case AppActionType.SET_WALLET_DROP_CLOSE:
      return state.set('walletDrop', false)
    case AppActionType.SET_WALLET_DROP_OPEN:
      return state.set('walletDrop', true)
    case AppActionType.SET_MENU_DROP_ITEM:
      return state.set('menuDrop', action.menuItem)
    case AppActionType.SET_MENU_DROP_CLOSE:
      return state.set('menuDrop', null)
    case AppActionType.SHOW_NODE_INFO_FORM:
      return state.set('isShowNodeInfoForm', true)
    case AppActionType.HIDE_NODE_INFO_FORM:
      return state.set('isShowNodeInfoForm', false)
    case AppActionType.SET_CUSTOM_NODES:
      return state.set('customNodes', action.nodes)
    case AppActionType.SET_SELECTED_NODES:
      return state.set('selectedNodes', action.nodes)
    case AppActionType.SET_AUTO_SELECT_NODE:
      return state.set('autoSelectNode', action.autoSelect)
    case AppActionType.SET_FORM_NODE:
      return state.set('formNode', action.node)
    case AppActionType.SET_ALL_ORACLE_TOKEN_PRICES:
      return state.set('allOracleTokenPrices', action.tokenPrices)
    case AppActionType.SET_CONNECT_ERROR:
      return state.set('connectError', action.error)
    default:
      return state
  }
}

export default appReducer
