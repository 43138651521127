import { makeStyles, Theme } from '@material-ui/core'
import { TokenClient } from 'carbon-js-sdk/lib/clients'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { TableSection } from 'js/components'
import Page from 'js/components/Page'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { ReactElement, useMemo } from 'react'
import { CirculateRow, TokenInfoRow } from './components'
import { CirculateHeaders, InfoHeaders } from './tokenListConfig'

interface Props { }

const TokenList: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const classes = useStyles()
  const { totalBonded, swthSupply } = useRedux((state) => state.app)
  const { total, info } = useRedux((state) => state.tokens)

  const { tokens, poolTokens } = useMemo(() => {
    return info.reduce((result, token) => {
      if (TokenClient.isPoolToken(token.denom)) {
        result.poolTokens.push(token)
      } else {
        result.tokens.push(token)
      }

      return result
    }, { tokens: [] as Token[], poolTokens: [] as Token[] })
  }, [info])

  return (
    <Page title="Tokens Information">
      <TableSection
        cellClass={classes.cellClass}
        headerCells={InfoHeaders}
        loadName={TaskNames.Tokens.Info}
        models={tokens}
        rowComponent={TokenInfoRow}
        title="Tokens"
        itemName="tokens"
        pagination
        searchBar
        searchKeys={["denom", "symbol"]}
        placeholder="Search for token (eg. SWTH)"
      />
      <TableSection
        cellClass={classes.cellClass}
        headerCells={InfoHeaders}
        loadName={TaskNames.Tokens.Info}
        models={poolTokens}
        rowComponent={TokenInfoRow}
        title="Pool Tokens"
        itemName="tokens"
        pagination
        searchBar
        searchKeys={["denom", "symbol"]}
        placeholder="Search for token (eg. SWTH)"
      />
      <TableSection
        headerCells={CirculateHeaders}
        loadName={TaskNames.Tokens.CirculateSupply}
        models={total}
        rowComponent={CirculateRow}
        title="Total Circulating Supply"
        addInfo={{ swthSupply, totalBonded }}
        itemName="tokens"
        pagination
        searchBar
        searchKeys={["denom", "symbol"]}
        placeholder="Search for token (eg. SWTH)"
      />
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  cellClass: {
    padding: '0.5rem 1.15rem 0.5rem 0.7rem',
    whiteSpace: 'nowrap',
  },
}))

export default TokenList
