import { Box, Card, Link, makeStyles, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { CarbonSDK, Leap, LeapAccount } from 'carbon-js-sdk'
import { StatefulButton } from 'js/components'
import customToast from 'js/components/Toast/Toast'
import { Paths } from 'js/constants'
import { useAsyncTask, useRedux } from 'js/hooks'
import { updateCarbonSDK } from 'js/store/core'
import { switcheo } from 'js/theme/palettes/colors'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { TypographyLabel } from './components'
import { ReactComponent as LeapDark } from './LeapDark.svg'

interface Props { }

export interface LeapWindow {
  leap?: Leap
}

const LeapWalletLogin: React.FC<Props> = (props: Props) => {
  const sdk = useRedux((state) => state.core.carbonSDK)
  const network = useRedux((state) => state.app.network)
  const [runConnectLeap, loading, error] = useAsyncTask('connectLeap')
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [chainId, setChainId] = useState<string | null>(null)

  const hasLeap = typeof (window as LeapWindow).leap !== 'undefined'

  useMemo(async () => {
    if (!sdk) return null
    await LeapAccount.getChainId(sdk).then((chainId) => { setChainId(chainId) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk?.networkConfig])

  const handleLogin = async (leap: Leap) => {
    if (!chainId || !leap) return
    const account = await leap.getKey(chainId)
    const LeapSigner = LeapAccount.createLeapSigner(leap, chainId);
    const pubKeyBase64 = Buffer.from(account.pubKey).toString('base64')
    const connectedSDK = await CarbonSDK.instanceWithSigner(
      LeapSigner,
      pubKeyBase64,
      { network },
      { providerAgent: 'leap-extension' },
    )

    dispatch(updateCarbonSDK(connectedSDK))
    customToast('Success!', 'You have successfully logged in.')
    history.push(sessionStorage.getItem(`before-login`) ?? Paths.Home)
  }

  const onLogin = async () => {
    runConnectLeap(async () => {
      if (!hasLeap || !chainId) return
      const leap = (window as LeapWindow).leap!

      try {
        await leap!.enable(chainId)
        handleLogin(leap!)
      } catch (error) {
        if ((error as any)?.message === 'key doesn\'t exist') {
          throw new Error('No Leap account set up. Click on the Leap extension to set up your account.')
        }
        throw error
      }
    })
  }

  return (
    <Box className={classes.container}>
      <Typography variant="h1">
        Connect with Leap Wallet
      </Typography>
      <Card elevation={0} className={classes.card}>
        <Box display="flex" justifyContent="center" paddingX={8} marginTop={8}>
          <LeapDark className={classes.svg} />
        </Box>
        {!hasLeap && (
          <TypographyLabel padding={6} align="center"><strong>Enable Leap Wallet</strong> extension or install with the button below.</TypographyLabel>
        )}

        {hasLeap && (
          <TypographyLabel padding={6} align="center">&ldquo;Approve&rdquo; connection on the Leap Wallet window.</TypographyLabel>
        )}
      </Card>

      {!!error?.message && (
        <Box marginTop={4}>
          <Alert severity="error">
            <AlertTitle>Login Error</AlertTitle>
            {error.message}
          </Alert>
        </Box>
      )}

      <Box className={classes.actionClass}>
        {!hasLeap && (
          <Link href="https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg" target="_blank">
            <StatefulButton
              className={classes.loginBtn}
              tabIndex={3}
              loading={loading}
              variant="contained"
              color="secondary"
            >
              Get Leap for Chrome
            </StatefulButton>
          </Link>
        )}
        {hasLeap && (
          <StatefulButton
            className={classes.loginBtn}
            tabIndex={3}
            loading={loading}
            variant="contained"
            color="secondary"
            onClick={onLogin}
          >
            Login
          </StatefulButton>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  actionClass: {
    marginTop: theme.spacing(4),
    justifyContent: 'flex-end',
  },
  breakword: {
    wordBreak: 'break-all',
  },
  container: {
    border: `1px solid ${switcheo.mono.A500}`,
    padding: theme.spacing(3),
  },
  card: {
    backgroundColor: 'transparent',
    display: 'flex',
    borderRadius: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    // marginBottom: theme.spacing(8),
    margin: theme.spacing(4, 0),
    border: `1px solid ${switcheo.mono.A500}`,
  },
  title: {
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  subtitleBox: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  svg: {
    height: 150,
    width: 150,
  },
  loginBtn: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
}))

export default LeapWalletLogin
