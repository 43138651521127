import { TutorialKeys } from 'js/constants'
import { TutorialProgress } from 'js/models'
import { ValueOf } from 'js/utils'
import { Action } from 'redux'

type Keys = ValueOf<typeof TutorialKeys>

export enum ActionType {
  CLEAR = 'TUTORIAL_CLEAR',
  UPDATE_PROGRESS = 'TUTORIAL_UPDATE_PROGRESS',
  TRIGGER = 'TUTORIAL_TRIGGER',
}

export type TutorialAction =
  | ClearAction
  | UpdateProgressAction
  | TriggerTutorialAction

export interface ClearAction extends Action<ActionType.CLEAR> { }
export function clear(): ClearAction {
  return {
    type: ActionType.CLEAR,
  }
}

export interface UpdateProgressAction extends Action<ActionType.UPDATE_PROGRESS> {
  tutorialKey: string
  progress: TutorialProgress
}
export function updateProgress(tutorialKey: Keys, progress: TutorialProgress): UpdateProgressAction {
  return {
    type: ActionType.UPDATE_PROGRESS,
    tutorialKey, progress,
  }
}

export interface TriggerTutorialAction extends Action<ActionType.TRIGGER> {
  tutorialKey: Keys
}
export function triggerTutorial(tutorialKey: Keys): TriggerTutorialAction {
  return {
    type: ActionType.TRIGGER,
    tutorialKey,
  }
}
