import { updateFilters } from 'js/actions/blocks'
import CommonBlocks from 'js/components/Blocks'
import Page from 'js/components/Page'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import { BlocksProps } from 'js/reducers/blocks'
import React, { ReactElement } from 'react'

interface Props extends BlocksProps {
  titleClass?: string
}

const Blocks: React.FunctionComponent<Props> = ({
  titleClass,
}: Props): ReactElement<Props> => {
  const { blocks } = useRedux((state) => state.blocks)

  return (
    <Page title="Blocks">
      <CommonBlocks
        blocks={blocks}
        action={updateFilters}
        filter
        loadname={[
          TaskNames.Blocks.List,
          TaskNames.Blocks.Filter,
        ]}
        titleClass={titleClass}
      />
    </Page>
  )
}

export default Blocks
