import { Insights } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/export'
import { Record, RecordOf } from 'immutable'
import { RestResponse } from 'tradehub-api-js'
import { MarketAction, MarketActionType } from '../actions/market'
import { TradesReponse } from './account'
import { Order } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/export'
export interface MarketProps {
  market?: Market
  stats?: RestResponse.MarketStat
  orders: Order[]
  trades: TradesReponse
  largePositions?: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
  riskyLongs?: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
  riskyShorts?: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
  profitablePositions?: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
  topProfits: any
}

const makeState: Record.Factory<MarketProps> = Record<MarketProps>({
  market: undefined,
  stats: undefined,
  orders: [],
  trades: {
    data: [],
    meta: {
      totalPages: 0,
    }
  },
  largePositions: undefined,
  riskyLongs: undefined,
  riskyShorts: undefined,
  profitablePositions: undefined,
  topProfits: [],
})
export type MarketState = RecordOf<MarketProps>

function marketsReducer(
  state: MarketState = makeState(),
  action: MarketAction,
): MarketState {
  switch (action.type) {
    case MarketActionType.CLEAR:
      return makeState()
    case MarketActionType.SET_MARKET:
      return state.updateIn(['market'], () => action.market)
    case MarketActionType.SET_STATS:
      return state.updateIn(['stats'], () => action.stats)
    case MarketActionType.SET_ORDERS:
      return state.updateIn(['orders'], () => action.orders)
    case MarketActionType.SET_TRADES:
      return state.updateIn(['trades'], () => action.trades)
    case MarketActionType.SET_LARGE_POSITIONS:
      return state.updateIn(['largePositions'], () => action.largePositions)
    case MarketActionType.SET_RISKY_LONG_POSITIONS:
      return state.updateIn(['riskyLongs'], () => action.riskyPositions)
    case MarketActionType.SET_PROFITABLE_POSITIONS:
      return state.updateIn(['profitablePositions'], () => action.profitablePositions)
    default:
      return state
  }
}

export default marketsReducer
