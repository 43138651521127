import { Tendermint37Client } from "@cosmjs/tendermint-rpc"
import { CarbonSDK } from 'carbon-js-sdk'
import { AppActionType, SetNetworkAction } from 'js/actions/app'
import { SimpleBlock } from "js/models"
import { SimpleMap, ValPair } from "js/utils"
import { TradeHubSDK } from 'tradehub-api-js'
import { ActionType, CoreAction } from './action'

export type CoreState = {
  sdk: TradeHubSDK | null
  carbonSDK?: CarbonSDK
  tmWsClient?: Tendermint37Client
  storedMnemonics: string | null
  storedNetwork: string | null
  valAddrMap: SimpleMap<ValPair>
  blocks: SimpleBlock[]
}

const initialState: CoreState = {
  sdk: null,
  carbonSDK: undefined,
  tmWsClient: undefined,
  storedMnemonics: null,
  storedNetwork: null,
  valAddrMap: {},
  blocks: [],
}

const reducer = (
  state: CoreState = initialState,
  action: CoreAction | SetNetworkAction,
): CoreState => {
  switch (action.type) {
    case ActionType.CORE_UPDATE_SDK:
      if (action.sdk) {
        state.storedMnemonics = null
        state.storedNetwork = null
      }
      return {
        ...state,
        sdk: action.sdk,
      }
    case ActionType.CORE_UPDATE_TM_WS_CLIENT:
      return {
        ...state,
        tmWsClient: action.tmWsClient,
      }
    case ActionType.CORE_UPDATE_VAL_ADDR_MAP:
      return {
        ...state,
        valAddrMap: {
          ...action.map,
        },
      }
    case ActionType.CORE_UPDATE_BLOCKS:
      return {
        ...state,
        blocks: [
          ...action.blocks
        ],
      }
    case ActionType.CORE_UPDATE_CARBON_SDK:
      if (action.sdk) {
        state.storedMnemonics = null
        state.storedNetwork = null
      }
      return {
        ...state,
        carbonSDK: action.sdk,
      }
    case AppActionType.SET_NETWORK:
      // no change to state if
      if (
        // SDK's network is the same as target network
        CarbonSDK.parseNetwork(action.network) === state.carbonSDK?.network

        // SDK not initialized in the first place
        || !state.carbonSDK
      ) {
        // return unchanged state
        return state
      }

      // return empty SDK to trigger saga initalization
      return {
        ...initialState,
        storedNetwork: action.network,
        storedMnemonics: state.carbonSDK?.wallet?.mnemonic ?? null,
      }
    default:
      return state
  }
}

export default reducer
