import { Box, BoxProps, Grid, makeStyles, TextField, Theme } from '@material-ui/core'
import clsx from 'clsx'
import { setSubmitProposalFormState, setSubmitProposalFormValidationErrors } from 'js/actions/governance'
import { Constants } from 'js/constants'
import { useRedux } from 'js/hooks'
import { RootProposalFormState, SubmitProposalFormState } from 'js/models'
import { escapeHtmlGo } from 'js/utils'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { TextProposalInitialFormState } from './constants'
import { getErrorMessages } from './Helpers/InputConstraints'

interface Props extends BoxProps {
}

const SHOW_WORDCOUNT_THRESHOLD = 0.8 // at 80% of limit

export const DetailsStepFormConstraints = {
  title: {
    presence: {
      allowEmpty: false,
    },
    length: {
      maximum: Constants.Proposal.MaxTitleLength,
    },
  },
  summary: {
    presence: {
      allowEmpty: false,
    },
    length: {
      maximum: Constants.Proposal.MaxDescLength,
    },
  },
}

const DetailsStep = (props: Props) => {
  const { className, ...rest } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const formState = useRedux((state) => state.governance.submitProposalFormState) || TextProposalInitialFormState

  useEffect(() => {
    if (formState === TextProposalInitialFormState) {
      dispatch(setSubmitProposalFormState(formState))
    }
    validateRequiredFields(formState)
    // eslint-disable-next-line
  }, [])

  const validateRequiredFields = (formState: SubmitProposalFormState) => {
    const errors = getErrorMessages(formState, DetailsStepFormConstraints)
    dispatch(setSubmitProposalFormValidationErrors(errors))
  }

  const handleFormChange = (key: keyof RootProposalFormState) => {
    return (event: ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      const newFormState = {
        ...formState,
        [key]: value,
      }

      dispatch(setSubmitProposalFormState(newFormState))
      validateRequiredFields(newFormState)
    }
  }

  const getDescriptionHelperText = () => {
    const descriptionLength = escapeHtmlGo(formState.summary).length
    if (descriptionLength > SHOW_WORDCOUNT_THRESHOLD * Constants.Proposal.MaxDescLength) {
      return `${descriptionLength}/${Constants.Proposal.MaxDescLength}`
    }
    return ''
  }

  const getTitleHelperText = () => {
    const titleLength = escapeHtmlGo(formState.title).length
    if (titleLength > SHOW_WORDCOUNT_THRESHOLD * Constants.Proposal.MaxTitleLength) {
      return `${titleLength}/${Constants.Proposal.MaxTitleLength}`
    }
    return ''
  }

  const detailsStep = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Proposal Title"
          InputLabelProps={{ shrink: true }}
          helperText={getTitleHelperText()}
          value={formState.title}
          onChange={handleFormChange('title')}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={7}
          label="Proposal Description"
          helperText={getDescriptionHelperText()}
          InputLabelProps={{ shrink: true }}
          value={formState.summary}
          onChange={handleFormChange('summary')}
        />
      </Grid>
    </Grid>
  )

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      {detailsStep}
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1.5, 0),
    },
  },
}))

export default DetailsStep
