import { Typography } from '@material-ui/core'
import { NotFoundState } from 'js/components'
import querystring from 'query-string'
import React from 'react'

type Props = {
}

const NoSearchResults: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const params = querystring.parse(window.location.search)
  const searchParam = params.q
  return (
    <NotFoundState title="No Results Found">
      <Typography variant="body1">
        {
          `We can't find any data matching your search for "${searchParam}".`
        }
      </Typography>
      <Typography variant="body1">
        Please try another search.
      </Typography>
    </NotFoundState>
  )
}

export default NoSearchResults
