import { makeStyles, TableBody, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { RewardCurve as RewardCurveType } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/liquiditypool/reward'
import dayjs from 'dayjs'
import { DataLoadSegment, ListTable, Section } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import { bnOrZero } from 'js/utils'
import React, { ReactElement } from 'react'
import { ListTableRow } from '../../../Staking/Validators/components'

interface Props {
  params: RewardCurveType
}

const RewardCurve: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { params } = props
  const classes = useStyles()
  const [loading] = useTaskSubscriber(TaskNames.Pools.RewardCurve)
  const cellFormat = (key: keyof RewardCurveType) => {
    switch (key) {
      case 'startTime':
        return dayjs(params[key]).format('YYYY-MM-DD HH:mm:ss')
      default:
        return bnOrZero(params[key]?.toString()).toString()
    }
  }
  // rewardBps = initialBps * ((10000 - reduction) / 10000) ^ reductions
  const basisPoints = bnOrZero(10000)
  const initial = bnOrZero(params.initialReward)
  const reduction = bnOrZero(params.reduction)
  const floor = bnOrZero(params.finalReward)
  const reductionsMade = bnOrZero(params.reductionsMade)
  const currentRewardBeforeFloor = initial.times(((basisPoints.minus(reduction)).div(basisPoints)).pow(reductionsMade))
  const currentReward = BigNumber.maximum(currentRewardBeforeFloor, floor)
  const startTime = dayjs(params.startTime)
  const currentRewardHuman = currentReward.shiftedBy(-2)
  let currentRewardString = '-'

  if (!currentRewardHuman.isNaN() && !startTime.isAfter(dayjs()) && !initial.isZero()) {
    currentRewardString = currentReward.toFixed(0)
  }
  let currentRewardHumanString = ''
  if (!currentRewardHuman.isNaN() && !startTime.isAfter(dayjs()) && !initial.isZero()) {
    currentRewardHumanString = `(${currentRewardHuman.toFixed(2)}%)`
  }
  return (
    <Section title="Reward Curve" className={classes.section}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableBody>
            <ListTableRow
              headerClass={classes.headerClass}
              key="current_reward"
              keyVal="current_reward"
              valueClass={classes.valueClass}
            >
              <div className={classes.stringContainer}>
                <div>{currentRewardString}</div>
                <div className={classes.sharePercent}>{currentRewardHumanString}</div>
              </div>
            </ListTableRow>
            {
              (Object.keys(params) as (keyof RewardCurveType)[]).map((each) => {
                if (each === 'interval') {
                  return (
                    <ListTableRow
                      headerClass={classes.headerClass}
                      key={each}
                      keyVal={each}
                      valueClass={classes.valueClass}
                    >
                      <div className={classes.stringContainer}>
                        <div>{cellFormat(each)}</div>
                        <div className={classes.sharePercent}>seconds</div>
                      </div>
                    </ListTableRow>
                  )
                }
                if (each === 'initialReward' || each === 'reduction' || each === 'finalReward') {
                  const humanValue = new BigNumber(cellFormat(each)).shiftedBy(-2)
                  const humanValueString = `(${humanValue.toFormat()}%)`
                  return (
                    <ListTableRow
                      headerClass={classes.headerClass}
                      key={each}
                      keyVal={each}
                      valueClass={classes.valueClass}
                    >
                      <div className={classes.stringContainer}>
                        <div>{cellFormat(each)}</div>
                        <div className={classes.sharePercent}>{humanValueString}</div>
                      </div>
                    </ListTableRow>
                  )
                }
                return (
                  <ListTableRow
                    headerClass={classes.headerClass}
                    key={each}
                    keyVal={each}
                    valueClass={classes.valueClass}
                  >
                    {cellFormat(each)}
                  </ListTableRow>
                )
              })
            }
          </TableBody>
        </ListTable>
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  headerClass: {
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
  section: {
    height: '100%',
  },
  sharePercent: {
    color: theme.palette.grey[500],
    marginLeft: 6,
  },
  stringContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  valueClass: {
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    },
  },
}))

export default RewardCurve
