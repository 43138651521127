import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'
import { switcheo } from '../palettes/colors'

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiMenu']>> => ({
  list: {
    backgroundColor: switcheo.background.grey,
    padding: 0,
  },
  paper: {
    borderRadius: 0,
  },
})
