import { Box, makeStyles, Tab, Tabs, Theme } from '@material-ui/core';
import { Add, EditOutlined, Search } from '@material-ui/icons';
import { useRedux } from 'js/hooks';
import React, { useEffect, useState } from 'react';
import { CreateComp } from './CreateComp';
import { Moment } from 'moment';

interface CompSetting {
  tradingComp: string,
  start: undefined | Moment,
  end: undefined | Moment,
  config: {
    type: string,
    minBalance: number,
    lending: any[],
    markets: string[],
    promoMarkets: string[],
    preTradeMarket: string[],
    preTradeMarketCount: number,
    preTradeMarketVolume: number,
    spotsMultiplier: number,
    derMultiplier: number,
    promoMultiplier: number,
    volPerPoint: number,
    allowDecimals: boolean,
    lendingQuantity: number
  },
}

export const CompetitionSettings = (props: any) => {
  const { password } = props
  const [tab, setTab] = useState(0);
  const markets = useRedux((state) => state.app.markets);
  const tokens = useRedux((state) => state.app.tokensMap);
  const network = useRedux((state) => state.app.network);
  const classes = useStyles();
  const [marketOptions, setMarketOptions] = useState<any[]>([]);
  const [promoMarketOptions, setPromoMarketOptions] = useState<[]>([]);
  const [lendingOptions, setLendingOptions] = useState<any>([]);
  const [tradingOptions, setTradingOptions] = useState<any[]>([]);
  const [compSettings, setCompSettings] = useState<CompSetting>({
    tradingComp: '',
    start: undefined,
    end: undefined,
    config: {
      type: "league",
      minBalance: 0,
      lending: [],
      markets: [],
      promoMarkets: [],
      preTradeMarket: [],
      preTradeMarketCount: 3,
      preTradeMarketVolume: 100,
      spotsMultiplier: 1,
      derMultiplier: 2,
      promoMultiplier: 0,
      volPerPoint: 100,
      allowDecimals: true,
      lendingQuantity: 0
    },
  });
  const [submitLoading, setSubmitLoading] = useState(false)
  const [iniLoading, setIniLoading] = useState(true)
  const [error, setError] = useState('')

  const options: any[] = Object.values(markets).filter((o) => o.isActive === true)?.map((mk) => { return { value: mk.id, label: mk.displayName }; });

  useEffect(() => {
    if ((Object.values(markets).length !== 0 && marketOptions.length === 0 && iniLoading)) {
      setMarketOptions(options);
      setCompSettings((prev) => {
        const temp = { ...prev };
        temp.config.markets = options?.map((o: any) => o.value);
        return { ...temp };
      });
      setIniLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketOptions.length, markets]);

  useEffect(() => {
    setCompSettings((prev) => {
      const temp = { ...prev };
      temp.config.lending = lendingOptions
      return { ...temp };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lendingOptions]);

  const handleTabChange = (event: any, id: number) => {
    setTab(id);
  };

  const onChangeInput = (value: any, key: any, configKey: any = undefined) => {
    setCompSettings((prev) => {
      let temp: any = { ...prev };
      if (configKey)
        temp.config[configKey] = value;
      else
        temp[key] = value;
      return temp;
    });
  };

  const handleSelect = (newOptions: any, type: 'markets' | 'lending' | 'preTradeMarket' | 'promoMarkets') => {
    if (type === 'markets') {
      setMarketOptions(newOptions);
    }
    else if (type === 'promoMarkets') {
      setPromoMarketOptions(newOptions);
    }
    else if (type === 'lending') {
      setLendingOptions(newOptions);
    }
    else setTradingOptions(newOptions)
    setCompSettings((prev) => {
      const temp = { ...prev };
      if (type === 'lending') temp.config[type] = newOptions?.value
      else {
        const optionsAsArray = newOptions?.map((o: any) => o.value)
        temp.config[type] = [...optionsAsArray];
        return { ...temp };
      }
      return { ...temp };
    });
  };

  const handleCheckbox = () => {
    setCompSettings({
      ...compSettings,
      config: {
        ...compSettings.config,
        allowDecimals: !compSettings.config.allowDecimals
      }
    })
  }

  const handleSubmit = async () => {
    setSubmitLoading(true)
    try {
      const networkURL = network === 'mainnet' ? '' : network === 'testnet' ? 'test-' : 'dev-'
      compSettings.config.lending = compSettings.config.lending.map((o) => o?.value ?? o)
      const response = await fetch(`https://${networkURL}api-insights.carbon.network/competition/competition/create`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...compSettings, competitionId: compSettings.tradingComp, auth: password })
        })
      const responseJSON = await response.json()
      if (responseJSON?.error?.message) setError(responseJSON?.error?.message)
      else if (responseJSON?.result?.status === 'successful') setError('successful')
      else setError('')
    }
    catch (err) {
      const error = err as Error
      console.log(error)
      setError(error.message)
    }
    setSubmitLoading(false)
  }
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1.5fr 8fr' }}>
      <Box style={{ borderRight: '1px solid grey', paddingRight: '0.5em' }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example"
          orientation="vertical"
          variant="scrollable"
        >
          <Tab label={<div className={classes.innerTabBtn}>
            <Add style={{ marginRight: '0.5em' }} />Create Comp
          </div>} id="create-comp" />
          <Tab label={<div className={classes.innerTabBtn}>
            <EditOutlined style={{ marginRight: '0.5em' }} />  Edit Comp
          </div>} id="edit-comp" />
          <Tab label={<div className={classes.innerTabBtn}>
            <Search style={{ marginRight: '0.5em' }} />  View Comp
          </div>} id="view-comp" />
        </Tabs>
      </Box>
      <Box id="contentContainer" style={{ padding: '2em 3em' }}>
        <div hidden={tab !== 0}>
          <CreateComp
            tradingOptions={tradingOptions}
            submitLoading={submitLoading}
            error={error}
            handleSubmit={handleSubmit}
            classes={classes}
            marketOptions={marketOptions}
            lendingOptions={lendingOptions}
            promoMarketOptions={promoMarketOptions}
            tokens={Object.values(tokens)?.map((tk) => { return { value: tk.denom, label: tk.denom } })}
            setting={compSettings}
            options={options}
            onChangeInput={onChangeInput}
            handleSelect={handleSelect}
            handleCheckbox={handleCheckbox}
          />
        </div>
        <div hidden={tab !== 1}>
          Coming soon!
        </div>
        <div hidden={tab !== 2}>
          Coming soon!
        </div>
      </Box>
    </div>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  innerTabBtn: {
    display: 'flex',
    alignItems: 'center'
  }
}))