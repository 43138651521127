import { Insights } from 'carbon-js-sdk'
import { QueryAllMsgGasCostResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/fee/query'
import { CARBON_GENESIS_BLOCKTIME, TaskNames, txnsPaginationLimit } from 'js/constants'
import Long from 'long'
import moment from 'moment'
import { SagaIterator } from 'redux-saga'
import { Effect, call, put, spawn, takeLatest } from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import {
  TransactionsActionType,
  clear, setDisablePaginationForwardButton, setGasFees,
  setTransactionMsgChartData,
  setTransactionTotalChartData,
  setTransactions,
  setTypes
} from '../actions/transactions'
import Saga from './Saga'
import { runSagaTask, waitforSDK } from './helper'
import { QueryAllMessageTypeResponse, QueryAllTransactionRequest, QueryAllTransactionResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/query'
import { Transaction } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/transaction'
import { MessageType } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/misc/message_type'

export default class Transactions extends Saga {
  private readonly isMobile: boolean
  private txKeys: any[]
  private currentTxPage: number
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
    this.txKeys = []
    this.currentTxPage = 0
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchTransactions],
      [this, this.fetchTransactionTypes],
      [this, this.fetchTransactionTotalChart],
      [this, this.fetchTransactionMsgChart],
      [this, this.watchFilters],
      [this, this.watchDateFilters],
      [this, this.fetchGasFees],
      [this, this.watchSetNetwork],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchTransactions(): any {
    const _this = this
    yield runSagaTask(TaskNames.Transactions.List, function* () {
      const sdk = yield* waitforSDK()
      const miscQueryClient = sdk.query.misc
      const response = (yield call([miscQueryClient, miscQueryClient.TransactionAll], QueryAllTransactionRequest.fromPartial({
        pagination: {
          limit: new Long(txnsPaginationLimit),
          key: new Uint8Array(),
          countTotal: false,
          reverse: false,
        },
      }))) as QueryAllTransactionResponse
      _this.txKeys?.push(response.pagination?.nextKey)
      if (response.transactions.length !== 0) {
        yield put(setDisablePaginationForwardButton(false))
      }
      yield put(setTransactions({
        data: response.transactions.sort((a: Transaction, b: Transaction) => b.blockHeight.toNumber() - a.blockHeight.toNumber()),
        meta: {
          totalPages: response.pagination?.total.toNumber() ?? 0,
        }
      })
      )
    })
  }

  private *fetchTransactionTypes(): any {
    yield runSagaTask(TaskNames.Transactions.Types, function* () {
      const sdk = yield* waitforSDK()
      const miscQueryClient = sdk.query.misc
      const response = (yield call([miscQueryClient, miscQueryClient.MessageTypeAll], {})) as QueryAllMessageTypeResponse
      const types = response.messageTypes.map((message: MessageType) => message.messageType)
      yield put(setTypes(types))
    });
  }

  private *watchFilters(): any {
    yield takeLatest(
      TransactionsActionType.UPDATE_FILTERS,
      (action: any) => this.handleFilters(action, this),
    )
  }

  private *handleFilters(action: any, _this: any): any {
    let msgTypes: string[] = []
    const filters = action.options.filters
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (filters[key]) {
          msgTypes.push(key)
        }
      }
    }
    yield runSagaTask(TaskNames.Transactions.Filter, function* () {
      const sdk = yield* waitforSDK()
      const miscQueryClient = sdk.query.misc
      const pageKey = _this.txKeys[Number(action.options.page) - 2] ?? new Uint8Array()
      const isPrev = Number(action.options.page) < _this.currentTxPage
      const response = (yield call([miscQueryClient, miscQueryClient.TransactionAll], QueryAllTransactionRequest.fromPartial({
        msgTypeFilters: msgTypes,
        pagination: {
          limit: new Long(txnsPaginationLimit),
          key: pageKey,
          countTotal: false,
          reverse: false,
        },
      }))) as QueryAllTransactionResponse
      if (!isPrev) _this.txKeys.push(response.pagination?.nextKey)
      _this.currentTxPage = action.options.page
      if (response.transactions.length === 0 && action.options.page) {
        yield put(setDisablePaginationForwardButton(true))
        return
      } else {
        yield put(setDisablePaginationForwardButton(false))
      }
      yield put(setTransactions({
        data: response.transactions.sort((a: Transaction, b: Transaction) => b.blockHeight.toNumber() - a.blockHeight.toNumber()),
        meta: {
          totalPages: response.pagination?.total.toNumber() ?? 0,
        }
      }))
    })
  }

  private *watchDateFilters(): any {
    yield takeLatest(
      TransactionsActionType.UPDATE_DATE_FILTERS_MSG_COUNT,
      this.handleDateFiltersMsg,
    )
    yield takeLatest(
      TransactionsActionType.UPDATE_DATE_FILTERS_TOTAL_COUNT,
      this.handleDateFiltersTotal,
    )
  }

  private *handleDateFiltersTotal(action: any): any {
    yield runSagaTask(TaskNames.Transactions.DateFilterTotal, function* () {
      const sdk = yield* waitforSDK()
      const from = moment(moment(action.options.startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(action.options.endDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const transactionResponse = (yield call([sdk.insights, sdk.insights.Transaction], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetTransactionResponse>
      yield put(setTransactionTotalChartData(transactionResponse.result.entries))
    })
  }

  private *handleDateFiltersMsg(action: any): any {
    yield runSagaTask(TaskNames.Transactions.DateFilterMsg, function* () {
      const sdk = yield* waitforSDK()
      const from = moment(moment(action.options.startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(action.options.endDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const transactionResponse = (yield call([sdk.insights, sdk.insights.Transaction], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetTransactionResponse>
      yield put(setTransactionMsgChartData(transactionResponse.result.entries))
    })
  }

  private *fetchTransactionTotalChart(): any {
    if (this.isMobile) return
    yield runSagaTask(TaskNames.Transactions.TotalChart, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon
      const from = moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(Date.now()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const transactionResponse = (yield call([sdk.insights, sdk.insights.Transaction], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetTransactionResponse>
      yield put(setTransactionTotalChartData(transactionResponse.result.entries))
    })
  }



  private *fetchTransactionMsgChart(): any {
    if (this.isMobile) return
    yield runSagaTask(TaskNames.Transactions.MsgChart, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon
      const from = moment(moment.max(moment(CARBON_GENESIS_BLOCKTIME), moment().subtract(3, 'months')).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(Date.now()).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const transactionResponse = (yield call([sdk.insights, sdk.insights.Transaction], {
        from,
        until,
      })) as Insights.InsightsQueryResponse<Insights.QueryGetTransactionResponse>
      yield put(setTransactionMsgChartData(transactionResponse.result.entries))
    })
  }

  private *fetchGasFees(): any {
    yield runSagaTask(TaskNames.Transactions.Types, function* () {
      const sdk = yield* waitforSDK()
      const feeQueryClient = sdk.query.fee
      const fees = (yield call([feeQueryClient, feeQueryClient.MsgGasCostAll], {})) as QueryAllMsgGasCostResponse
      yield put(setGasFees(fees.msgGasCosts))
    })
  }
}
