import { LinearProgress, LinearProgressProps, makeStyles, Theme } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

type Props = LinearProgressProps & {
  progressColor?: string
}

const ColorProgress: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { className, progressColor, ...rest } = props
  const classes = useStyles(props)

  return (
    <LinearProgress {...rest} className={clsx(classes.root, className)} />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: theme.spacing(.5),
    backgroundColor: 'rgba(6, 60, 79, .1)',
    '& .MuiLinearProgress-bar': {
      backgroundColor: ({ progressColor }: Props) => progressColor || theme.palette.primary.main,
    },
  },
}))

export default ColorProgress
