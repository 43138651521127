import { TableCellInfo } from "js/components"

export const InfoHeaders: TableCellInfo[] = [
  {
    align: "center",
    content: "Chain",
  },
  {
    align: 'left',
    content: 'Name',
  },
  {
    align: "left",
    content: 'Lend APR',
  },
  {
    align: "left",
    content: 'Borrow Interest',
  },
  {
    align: "left",
    content: 'Total Supplied',
  },
  {
    align: "left",
    content: 'Available to Borrow',
  },
  {
    align: "left",
    content: 'Utilization Rate',
  },
  {
    align: "left",
    content: 'Denom',
  },
  {
    align: "left",
    content: 'Token Address',
  },
]

export const InfoMintHeaders: TableCellInfo[] = [
  {
    align: "center",
    content: "Chain",
  },
  {
    align: 'left',
    content: 'Name',
  },
  {
    align: "left",
    content: 'Mint Interest',
  },
  {
    align: "left",
    content: 'Total Minted',
  },
  {
    align: "left",
    content: 'Denom',
  },
  {
    align: "right",
    content: 'Token Address',
  },
]

export const CirculateHeaders: TableCellInfo[] = [
  { align: 'inherit', content: 'Token' },
  { content: 'Available' },
  { content: 'Order' },
  { content: 'Position' },
  { content: 'Staked' },
  { content: 'Total' },
]
