import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Insights } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/market'
import { updateLargePositions } from 'js/actions/market'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, PaginationByOffset, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import { SimpleMap, bnOrZero } from 'js/utils'
import React, { ReactElement, ReactNode } from 'react'

interface Props {
  largePositions?: Insights.InsightsQueryResponse<Insights.QueryGetPositionsViewResponse>
  markets?: SimpleMap<Market>
}

const ActiveTasks = [
  TaskNames.App.Markets,
  TaskNames.Market.LargePositions,
  TaskNames.Market.LargeFilter,
]

const LargePositions: React.FunctionComponent<Props> = ({
  largePositions,
  markets,
}: Props): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(...ActiveTasks)

  return (
    <Section title="Largest Positions">
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Account</TableCell>
              <TableCell align="right">Position Size</TableCell>
              <TableCell align="right">Avg Price</TableCell>
              <TableCell align="right">Realized PnL</TableCell>
              <TableCell align="right">Margin Allocated</TableCell>
              <TableCell align="right">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{largePositions?.result.entries?.map(renderLargePosition)}</TableBody>
        </ListTable>

        {!!largePositions?.result.entries?.length && (
          <Box marginTop={3}>
            <PaginationByOffset
              action={updateLargePositions}
              // setPage={setPage}
              // page={page}
              response={largePositions}
            // paginationLimit={positionsPaginationLimit}
            />
          </Box>
        )}
        {(!largePositions?.result.entries?.length) && (
          <TableEmptyState itemName="positions" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderLargePosition(
    position: Insights.GetPositionsViewEntry,
    index: number,
  ): ReactNode {
    const entryPriceBN = bnOrZero(position.entryPrice)
    const realizedPnlBN = bnOrZero(position.realizedPnl)
    const allocatedMarginBN = bnOrZero(position.allocatedMarginAmount)
    const lotDp: number = bnOrZero(markets?.[position.market]?.lotSize).dp() ?? 0
    const lotsBN = bnOrZero(position.lots)
    const baseDenom = markets?.[position?.market ?? ""]?.base

    const { username, address } = position
    return (
      <TableRow key={address + index} hover>
        <TableCell>
          <CellLink to={`/account/${address}`}>
            {username === '' ? address : username}
          </CellLink>
        </TableCell>
        <AmountDenomCell amount={lotsBN.toFormat(lotDp)} denom={baseDenom} />
        <TableCell align="right">{entryPriceBN.toFormat(2)}</TableCell>
        <TableCell align="right">{realizedPnlBN.toFormat(2)}</TableCell>
        <AmountDenomCell denom={position.allocatedMarginAmount} amount={allocatedMarginBN.toFormat(2)} />
        <TableCell align="right">
          <CellLink to={`/block/${position.openedBlockHeight}`}>
            {position.openedBlockHeight}
          </CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default LargePositions
