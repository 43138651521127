import { Typography } from '@material-ui/core'
import { CellLink, DataLoadSegment, NotFoundState, Page } from 'js/components'
import { Paths, TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import React, { Fragment, ReactElement } from 'react'
import Information from './Information'
import RichList from './Richlist'
// import Information from './Information'
// import Richlist from './Richlist'
// import TokenOraclePriceChart from './TokenOraclePriceChart'
// import { updateDateFiltersOraclesPriceChart } from 'js/actions/token'

interface Props { }

const TokenComponent: React.FunctionComponent<Props> = (): ReactElement<Props> => {
  const sdk = useRedux((state) => state.core.carbonSDK)
  const { token, rich } = useRedux((state) => state.token) 
  const [tokenDetailsLoading] = useTaskSubscriber(TaskNames.Token.Detail, TaskNames.App.AllOracleTokenPrices)
  // const denomsWithOracleData = useRedux((state) => state.app.allOracleTokenPrices).map(tokenPrice => tokenPrice.denom)
  // const { oraclesPriceChartDataResponse } = useRedux((state) => state.token)
  // const tokenHasOracleData = denomsWithOracleData.includes(token?.denom ?? '')
  let pageTitle = sdk?.token.getTokenDesc(token?.denom ?? '')
  if (pageTitle?.includes("IBC")) {
    pageTitle = token?.symbol
  }
  return (
    <Page title={pageTitle}>
      <DataLoadSegment loading={tokenDetailsLoading}>
        {token?.name && (
          <Fragment>
            {/* {tokenHasOracleData && (<ChartContainer
              taskNames={[TaskNames.Token.PricesChart]}
              title={
                  <Typography variant={"h3"} component={"span"}>
                    {`Token Price`}
                  </Typography>
              }
              date={true}
              action={updateDateFiltersOraclesPriceChart}
            >
              <TokenOraclePriceChart data={oraclesPriceChartDataResponse} />
            </ChartContainer>)} */}
            <Information token={token} />
            <RichList rich={rich} />
          </Fragment>
        )}
        {!token?.name && (
          <NotFoundState title="No Token Found">
            <Typography variant="body1">
              We can’t find any token with this ID. Please check your network setting or go back to the&nbsp;
              <CellLink to={Paths.Tokens}>
                Tokens
              </CellLink>
              &nbsp;page to view existing tokens.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

export default TokenComponent
