export interface ScrollOpts {
  offset?: number
}

export const getHeaderOffset = () => {
  const width = window.innerWidth;

  if (width < 600) {
    return 56
  } else if (width < 960) {
    return 64
  } else {
    return 145
  }
}

export const scrollToView = (el: HTMLElement, opts: ScrollOpts = {}) => {
  const headerOffset = getHeaderOffset();
  const yOffset = (opts.offset ?? -16) - headerOffset;
  const scrollTo = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({top: scrollTo, behavior: 'auto'});
}
