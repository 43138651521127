import { Divider, makeStyles, Theme, Typography } from '@material-ui/core'
import { DataLoadSegment, PaperBox } from 'js/components'
import { useTaskSubscriber } from 'js/hooks'
import React, { Fragment, ReactElement } from 'react'

type Props = {
  children: any
  loadCondition?: boolean
  loadname?: string[]
  title: string
}

const StatsSummaryBox: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { children, loadCondition = true, loadname = [], title } = props
  const classes = useStyles()
  const [loading] = useTaskSubscriber(...loadname)

  return (
    <PaperBox
      title={title}
      className={classes.box}
      titleClass={classes.titleClass}
    >
      <DataLoadSegment
        loading={loading}
        className={classes.loaderDiv}
        filter
        spinnerClass={classes.spinner}
      >
        {loadCondition ?
          (
            <Fragment>
              <Divider className={classes.divider} />
                <Typography variant="h1" className={classes.titleSpace}>
                  {children}
                </Typography>
              <Divider className={classes.divider} />
            </Fragment>
          ) : null
        }
      </DataLoadSegment>
    </PaperBox>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(3),
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
  loaderDiv: {
    height: '100%',
  },
  spinner: {
    margin: theme.spacing(0),
  },
  titleClass: {
    minHeight: '1.5rem',
    margin: theme.spacing(0, 0, 3, 0),
  },
  titleSpace: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default StatsSummaryBox
