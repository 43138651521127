import { Grid, makeStyles, Theme } from '@material-ui/core'
import { Models } from 'carbon-js-sdk'
import { BondStatus } from 'carbon-js-sdk/lib/codec/cosmos/staking/v1beta1/staking'
import { TaskNames } from 'js/constants'
import { useRedux } from 'js/hooks'
import { bnOrZero, SimpleMap } from 'js/utils'
import React, { ReactElement } from 'react'
import { StatsSummaryBox } from '../components'

type Props = {
  blockTime: number,
  info: SimpleMap<Models.Slashing.ValidatorSigningInfo>
  totalTokens: any,
  validators: Models.Staking.Validator[]
}

const ValidatorsSummary: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { blockTime, totalTokens, validators } = props
  const classes = useStyles()
  const latestBlock = useRedux((state) => state.app.latestBlock)
  const { blocks } = useRedux((state) => state.core)

  // Filter and get number of bonded validators
  const bondedValidators = validators.filter((each) => each?.status === BondStatus.BOND_STATUS_BONDED)
  const activeValidators = bnOrZero(bondedValidators.length || 0)

  // Get total number of validators
  const totalValidators = bnOrZero(23)

  // Calculate average block time
  const blockTimeBN = bnOrZero(blockTime)
  const latestBlockBN = bnOrZero(latestBlock?.height || 0)

  return (
    <div className={classes.gridMargin}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} md={3}>
          <StatsSummaryBox
            title="Active Validators"
            loadCondition={!totalValidators.isNaN()}
            loadname={[
              TaskNames.App.Validators,
            ]}
          >
            {activeValidators.toFormat(0)}
            <span className={classes.smallText}> / {totalValidators.toFormat(0)}</span>
          </StatsSummaryBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsSummaryBox
            title="Height"
            loadCondition={!latestBlockBN.isNaN()}
            loadname={[TaskNames.App.LatestBlock]}
          >
            {latestBlockBN.toFormat()}
          </StatsSummaryBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsSummaryBox
            title="Total Stake"
            loadCondition={!totalTokens.isNaN()}
            loadname={[
              TaskNames.App.Validators,
            ]}
          >
            {totalTokens.toFormat(0)}
          </StatsSummaryBox>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatsSummaryBox
            title={`Block Time (Last ${Math.max(blocks.length - 1, 1)})`}
            loadCondition={!blockTimeBN.isNaN()}
            loadname={[
              TaskNames.Validators.BlockTime,
            ]}
          >
            {blockTimeBN.toFixed(2)}s
          </StatsSummaryBox>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  gridMargin: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  smallText: {
    ...theme.typography.h2,
  },
}))

export default ValidatorsSummary
