import { Box, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { CarbonSDK } from 'carbon-js-sdk'
import { Market } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/market/export'
import { Order } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/export'
import { CellLink, DataLoadSegment, ListTable, Section, TableEmptyState } from 'js/components'
import { shorten } from 'js/helpers/utils'
import { useTaskSubscriber } from 'js/hooks'
import { RootState } from 'js/store'
import { BIG_ZERO, SimpleMap, adjustHuman, bnOrZero } from 'js/utils'
import React, { ReactElement, ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import PaginationByData from './PaginationByData'
import { OrderStatusLabel } from './ParsedLabel'

export interface OrdersProps {
  filterStatus: boolean
  orders: Order[]
  title: string
  action: any
  markets: SimpleMap<Market>
  loadName: string[]
  loadCondition?: boolean
  sectionClass?: string
}

const Orders: React.FunctionComponent<OrdersProps> = ({
  filterStatus = false,
  orders,
  title,
  action,
  markets,
  loadName,
  loadCondition = true,
  sectionClass,
}: OrdersProps): ReactElement<OrdersProps> => {
  const [loading] = useTaskSubscriber(...loadName)
  const sdk = useSelector((state: RootState) => state.core.carbonSDK)
  // For pagination
  const [page, setPage] = useState(1)
  const itemsPerPage = 20
  const ordersToDisplay = orders.slice((page - 1) * itemsPerPage, ((page - 1) * itemsPerPage) + itemsPerPage)
  return (
    <Section className={sectionClass} title={title}>
      <DataLoadSegment
        filter={filterStatus}
        loading={loading && loadCondition}
      >
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Market</TableCell>
              <TableCell>Order Type</TableCell>
              <TableCell>Side</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Filled</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{ordersToDisplay.map((order) => renderOrder(order, sdk))}</TableBody>
        </ListTable>
        {orders.length > 0 && (
          <Box marginTop={3}>
            <PaginationByData
              data={orders}
              setPage={setPage}
              page={page}
              itemsPerPage={itemsPerPage}
            />
          </Box>
        )}
        {orders.length === 0 && (
          <TableEmptyState itemName={title.toLowerCase()} />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderOrder(order: Order, sdk?: CarbonSDK): ReactNode {
    const {
      address,
      marketId: marketName,
      orderType,
      side,
      price,
      quantity,
      filled,
      status,
      blockHeight,
      id,
    } = order
    const market = markets[marketName]
    const priceBN = adjustHuman(price)?.shiftedBy(market?.basePrecision?.sub(market?.quotePrecision)?.toNumber() ?? 0) ?? BIG_ZERO
    const quantityBN = sdk?.token.toHuman(market?.base, bnOrZero(quantity)) ?? BIG_ZERO
    const filledBN = sdk?.token.toHuman(market?.base, bnOrZero(filled)) ?? BIG_ZERO
    const lotDp = bnOrZero(market?.lotSize).dp() ?? 0
    const tickDp = bnOrZero(market?.tickSize).dp() ?? 0

    return (
      <TableRow key={id} hover>
        <TableCell>
          <CellLink to={`/order/${id}`}>{shorten(id)}</CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/account/${address}`}>
            {shorten(address)}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/market/${encodeURIComponent(marketName)}`}>{marketName}</CellLink>
        </TableCell>
        <TableCell>{orderType}</TableCell>
        <TableCell>{side}</TableCell>
        <TableCell align="right">{priceBN.toFormat(tickDp)}</TableCell>
        <TableCell align="right">{quantityBN.toFormat(lotDp)}</TableCell>
        <TableCell align="right">{filledBN.toFormat(lotDp)}</TableCell>
        <TableCell>
          <OrderStatusLabel>{status}</OrderStatusLabel>
        </TableCell>
        <TableCell align="right">
          <CellLink to={`/block/${blockHeight.toString()}`}>{blockHeight.toString()}</CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default Orders
