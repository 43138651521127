import { Box, Divider, Grow, List, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core'
import { MobileMenuArrowIcon } from 'assets'
import { CarbonSDK } from 'carbon-js-sdk'
import clsx from 'clsx'
import NodeSelectDropdown from 'js/components/NodeSelectDropdown'
import React, { ReactElement, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Login, SearchInput } from '../components'
import { desktopMenuItems, MenuRouteItem } from '../headerConfig'

interface Props {
  network: CarbonSDK.Network
  toggleDrawer: (forceClose?: boolean) => (event: any) => void
}

const DrawerMenuList: React.FunctionComponent<Props> = ({
  network,
  toggleDrawer,
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const [drawerState, setDrawerState] = useState<Record<string, boolean>>({})

  useEffect(() => {
    let state: Record<string, boolean> = {}
    //construct drawerState
    for (const item of desktopMenuItems) {
      if (item?.items?.length) state[item.label] = false
    }
    setDrawerState(state)
  }, [])


  const drawerStateHandler = (label: string) => {
    const currentState = drawerState[label]
    let state = { ...drawerState, [label]: !currentState }
    setDrawerState(state)
  }
  return (
    <div
      role="presentation"
      onKeyDown={toggleDrawer(true)}
    >
      <List className={classes.listPad}>
        <SearchInput className={classes.searchInput} network={network} />
        <Divider className={classes.menuDivider} />
        {desktopMenuItems.map((item: MenuRouteItem, index: number) => (
          <React.Fragment key={index + item.label}>
            {
              item?.href ?
                <ListItem
                  component={"a"}
                  href={item?.href}
                  // to={item?.href || item?.link}
                  className={classes.menuListItem}
                  onClick={toggleDrawer()}
                  button
                >
                  <ListItemText primary={item?.label} />
                </ListItem>
                :
                item?.items?.length ?
                  <ListItem
                    className={classes.menuListItem}
                    onClick={() => drawerStateHandler(item?.label)}
                    button
                  >
                    <ListItemText primary={item?.label} />
                    <MobileMenuArrowIcon className={clsx(classes.MobileMenuArrowIcon, { active: drawerState[item?.label] })} />
                  </ListItem>
                  :
                  <ListItem
                    component={NavLink}
                    to={item?.link ?? ""}
                    className={classes.menuListItem}
                    onClick={toggleDrawer()}
                    button
                  >
                    <ListItemText primary={item?.label} />
                  </ListItem>
            }
            <Divider className={classes.menuDivider} />
            {
              !!(item?.items?.length) && drawerState[item?.label] && (
                <Box className={classes.MobileSubMenuWrapper}>
                  {item.items.map((item, index) => {
                    const baseTimer = 500
                    const maxTimer = 2 * baseTimer
                    const timeout = (index + 1) * baseTimer > maxTimer ? maxTimer : (index + 1) * baseTimer
                    return <Grow in={true} timeout={timeout} key={item?.label}>
                      {
                        item?.href ?
                          <ListItem
                            component={"a"}
                            href={item?.href}
                            // to={item?.href || item?.link}
                            className={classes.menuListItemChildren}
                            onClick={toggleDrawer()}
                            button
                          >
                            <ListItemText primary={item?.label} />
                          </ListItem> :
                          <ListItem
                            component={NavLink}
                            to={item?.link ?? ""}
                            className={classes.menuListItemChildren}
                            onClick={toggleDrawer()}
                            button
                          >
                            <ListItemText primary={item?.label} />
                          </ListItem>
                      }
                    </Grow>
                  })}
                </Box>
              )
            }
          </React.Fragment>
        ))}
        <NodeSelectDropdown />
        <Divider className={classes.menuDivider} />
        <Login toggleDrawer={toggleDrawer} />
      </List>
    </div>
  )
}

const useStyles: any = makeStyles((theme: Theme) => ({
  searchInput: {
    margin: theme.spacing(0, 1.5),
    height: '4.25rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  menuListItem: {
    '&.MuiListItem-root .MuiTypography-body1': {
      fontSize: '1.7rem',
      lineHeight: '3.5rem',
      letterSpacing: '-0.125rem',
      fontWeight: 'bold',
    },
  },
  menuListItemChildren: {
    '&.MuiListItem-root .MuiTypography-body1': {
      fontSize: '1rem',
      lineHeight: '2.5rem',
      letterSpacing: '0rem',
      fontWeight: 'bold',
      background: "#E5EFF2"
    },
  },
  MobileSubMenuWrapper: {
    background: "#E5EFF2"
  },
  menuDivider: {
    backgroundColor: theme.palette.text.primary,
    margin: theme.spacing(0, 1.5),
  },
  listPad: {
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0),
    },
  },
  MobileMenuArrowIcon: {
    transition: 'transform 0.2s ease-out',
    '&.active': {
      transform: "rotate(-90deg)"
    }
  }
}))

export default DrawerMenuList
