import { useMediaQuery, useTheme } from '@material-ui/core';
import { bnOrZero } from 'carbon-js-sdk/lib/util/number';
import { SimpleMap } from 'carbon-js-sdk/lib/util/type';
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import 'chartjs-adapter-moment';
import chartPluginCrosshair from 'chartjs-plugin-crosshair';
import { CARBON_GENESIS_BLOCKTIME } from 'js/constants';
import { formatTotalLiquidityChartData } from 'js/helpers';
import { switcheo } from 'js/theme/palettes/colors';
import { hexToRgba } from 'js/utils';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

type Props = {
  data: any
  poolNameMap: SimpleMap<string>
  dateFilter: any
  setTotalLiquidity: (data: any) => void
  callback: () => void
}

const TotalLiquidityChart: React.FunctionComponent<any> = (
  props: any,
): ReactElement<Props> => {
  const { data, poolNameMap, setTotalLiquidity, dateFilter, callback } = props
  const theme = useTheme()
  const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'))
  const maxXAxis = isMobileXs ? 3 : isMobileSm ? 5 : 10
  const toolTipPosition = isMobileSm ? 'top' : 'right' as const
  const yTitleShowWhenMobile = isMobileXs ? false : true
  const [chartData, setchartData] = useState<any>(undefined)
  const [chartLabels, setchartLabels] = useState<any>(undefined)
  const startDate = moment(CARBON_GENESIS_BLOCKTIME);
  const endDate = moment();
  const dateFilterStart = moment(dateFilter.startDate);
  const dateFilterEnd = moment(dateFilter.endDate);
  const needFilterDataByDate = startDate.format('ll') !== dateFilterStart.format('ll') || endDate.format('ll') !== dateFilterEnd.format('ll');

  ChartJS.register(...registerables, chartPluginCrosshair)

  useEffect(() => {
    if (data) {
      let filteredData = data;
      if (needFilterDataByDate) {
        filteredData = data.filter(
          (dailyData: any) =>
            moment(dailyData.date) >= moment(dateFilter.startDate) && moment(dailyData.date) <= moment(dateFilter.endDate).endOf('day')
        )
      }
      const totalLiquidityResult = formatTotalLiquidityChartData(filteredData);
      const labels = totalLiquidityResult.labels;
      let sortedData = totalLiquidityResult.data;
      const dailySums = totalLiquidityResult.dailySumsResult;
      const dataConfig: any = []
      sortedData?.forEach((item: any, index: number) => {
        const poolName = poolNameMap[item[0]] ?? "Others"
        const label = poolName
        dataConfig.push({
          label,
          fill: true,
          lineTension: 0.2,
          backgroundColor: hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90),
          borderColor: hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90),
          borderDash: [],
          borderDashOffset: 0.0,
          pointBorderColor: hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90),
          pointBackgroundColor: hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90),
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: hexToRgba(switcheo.chart[index % switcheo.chart.length], 0.90),
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          data: item[1] ?? [],
          hidden: ["Others"].includes(item[0]) ? true : false,
        })
      })
      setTotalLiquidity(dailySums);
      setchartData(dataConfig)
      setchartLabels(labels)
    }
  }, [poolNameMap, data, setTotalLiquidity, needFilterDataByDate, dateFilter])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: true,
    },
    scales: {
      x: {
        type: 'time' as 'time',
        time: {
          unit: 'day' as 'day',
          displayFormats: {
            day: 'DD MMM',
            month: 'MMM YY'
          },
          tooltipFormat: "ll",
        },
        ticks: {
          autoSkip: false,
          beginAtZero: true,
          alwaysShowLastTick: true,
          display: true,
          maxTicksLimit: maxXAxis,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
        }
      },
      y: {
        stacked: true,
        title: {
          display: yTitleShowWhenMobile,
          text: 'TVL',
        },
        ticks: {
          callback: function (val: any) {
            return bnOrZero(val).toFormat()
          },
          display: true,
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: true,
          tickBorderDash: [5, 5],
          borderDash: [5, 5],
        }
      }
    },
    plugins: {
      TimeScale: {
        x: {
          type: 'time'
        }
      },
      tooltip: {
        mode: 'index' as 'index',
        intersect: false,
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 10,
        boxPadding: 2,
        callbacks: {
          beforeTitle: function (context: any) {
            callback(context[0]?.dataIndex ?? 0)
            return ""
          },
          label: function (context: any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }
            return label;
          }
        }
      },
      crosshair: {
        line: {
          color: switcheo.text.grey,
          width: 2,
          dashPattern: [5, 5],
        }
      },
      legend: {
        position: toolTipPosition as any,
        align: 'start' as const,
        fullSize: false,
        labels: {
          boxWidth: 10,
          boxHeight: 5,
        },
        maxWidth: 330
      },
    },
  };

  const finalData = {
    labels: chartLabels ?? [],
    datasets: chartData ?? [],
  };
  return (
    <Line options={options} data={finalData} />
  )
}

export default TotalLiquidityChart

