import { Avatar, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { Fragment, ReactElement } from 'react'

type Props = {
  avatarClass?: string
  initialClass?: string
  srcUrl: string
  loading: boolean
  name: string
  setLoading: any
  spinnerSize: string | number | undefined
}

const ProfileAvatarIcon: React.FunctionComponent<Props> = (props: Props): ReactElement<Props> => {
  const { avatarClass, initialClass, srcUrl, loading, name, setLoading, spinnerSize } = props
  const classes = useStyles()

  const createPlaceholder = (name: string) => {
    if (name) {
      const nameNoSymbols = name.replace(/[^a-zA-Z0-9\s]/g, '').trim()
      const initial = nameNoSymbols.substring(0, 1).toUpperCase() || "V"    // V for Validator
      return initial
    }
    return "V"   // V for Validator
  }

  setTimeout(() => {
    if (loading) setLoading(false)
  }, 6000)

  return (
    <Fragment>
      <Avatar
        alt="Profile Image from Keybase"
        variant="circular"
        className={clsx(avatarClass, classes.placeholder)}
        src={srcUrl}
        imgProps={{
          onLoad: () => setLoading(false),
          onError: () => setLoading(false),
        }}
      >
        {loading && (
          <CircularProgress size={spinnerSize} className={classes.spinner} />
        )}
        {!loading && srcUrl === '' && (
          <Typography variant="h1" className={clsx(initialClass, classes.initial)}>
            {createPlaceholder(name)}
          </Typography>
        )}
      </Avatar>
    </Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  initial: {},
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  spinner: {
    '&.MuiCircularProgress-colorPrimary': {
      color: theme.palette.primary.contrastText,
    },
  },
}))

export default ProfileAvatarIcon
