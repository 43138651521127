import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiInput']>> => ({
  root: {
    borderRadius: 8,
    '& .MuiInput-input': {
      padding: theme.spacing(1.25),
    },
    '& .MuiInputAdornment-positionEnd': {
      marginRight: theme.spacing(1.25),
    },
    '& .MuiInputBase-input': {
      border: `1px solid #9DADB2`,
    },
  },
  underline: {
    '&::after': {
      display: 'none',
    },
  },
})
