/* eslint-disable @typescript-eslint/no-unused-vars */
import { Insights } from 'carbon-js-sdk'
import { QueryTradesRequest, QueryTradesResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/broker/query'
import { QueryAllOrderRequest, QueryAllOrderResponse } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/order/query'
import { TaskNames } from 'js/constants'
import Long from 'long'
import moment from 'moment'
import { SagaIterator } from 'redux-saga'
import { Effect, call, put, spawn, takeLatest } from 'redux-saga/effects'
import { AppActionType } from '../actions/app'
import { MarketsActionType, clear, setMarketsVolumeChartData, setOrders, setTrades } from '../actions/markets'
import Saga from './Saga'
import { runSagaTask, waitforSDK } from './helper'

export default class TradingHistory extends Saga {
  private readonly isMobile: boolean
  constructor(isMobile: boolean) {
    super()
    this.isMobile = isMobile
  }
  /** @override */
  public *stop(): SagaIterator {
    yield* super.stop()
    yield put(clear())
  }

  protected getStartEffects(): Effect[] {
    return [
      [this, this.fetchOrders],
      [this, this.fetchTrades],
      [this, this.watchSetNetwork],
      [this, this.watchOrdersFilters],
      [this, this.watchTradesFilters],
      [this, this.watchDateFilters],
    ].map(spawn)
  }

  private *watchSetNetwork(): SagaIterator {
    yield takeLatest(AppActionType.SET_NETWORK, super.restart.bind(this))
  }

  private *fetchOrders(): any {
    yield runSagaTask(TaskNames.Markets.Orders, function* () {
      const sdk = yield* waitforSDK()
      const orderQueryClient = sdk.query.order

      const ordersResponse = (yield call([orderQueryClient, orderQueryClient.OrderAll], QueryAllOrderRequest.fromPartial({
        pagination: {
          limit: new Long(300),
          offset: Long.UZERO,
          countTotal: true,
          reverse: false,
        },
        address: '',
        marketId: '',
        orderType: '',
        orderStatus: '',
      })
      )) as QueryAllOrderResponse
      yield put(setOrders(ordersResponse.orders?.sort((a, b) => Number(b.blockHeight) - Number(a.blockHeight))))
    });
  }

  private *watchOrdersFilters(): any {
    yield takeLatest(
      MarketsActionType.UPDATE_ORDERS_FILTERS,
      this.handleOrderFilters,
    )
  }

  private *handleOrderFilters(action: any): Generator {
    yield runSagaTask(TaskNames.Markets.OrderFilter, function* () {
      const sdk = yield* waitforSDK()
      const orderQueryClient = sdk.query.order
      const page = Number(action.options.page)
      const offset = (page - 1) * 300
      const offsetLong = Long.fromNumber(offset >= 0 ? offset : 0)
      const ordersResponse = (yield call([orderQueryClient, orderQueryClient.OrderAll], QueryAllOrderRequest.fromPartial({
        pagination: {
          limit: new Long(300),
          offset: offsetLong,
          countTotal: true,
          reverse: false,
        },
        address: '',
        marketId: '',
        orderType: '',
        orderStatus: '',
      })
      )) as QueryAllOrderResponse
      yield put(setOrders(ordersResponse.orders?.sort((a, b) => Number(b.blockHeight) - Number(a.blockHeight))))
    });
  }

  private *fetchTrades(): any {
    const _this = this
    yield runSagaTask(TaskNames.Markets.Trades, function* () {
      const sdk = yield* waitforSDK()
      const brokerQueryClient = sdk.query.broker
      const trades = (yield call([brokerQueryClient, brokerQueryClient.Trades],
        QueryTradesRequest.fromPartial({
          pagination: {
            limit: new Long(20),
            offset: Long.UZERO,
            countTotal: true,
            reverse: false,
          }
        }),
      )) as QueryTradesResponse
      yield put(setTrades({
        data: trades.trades,
        meta: {
          totalPages: trades.pagination?.total.toNumber() ?? 0,
        }
      }))
    })
  }

  private *watchTradesFilters(): any {
    yield takeLatest(
      MarketsActionType.UPDATE_TRADES_FILTERS,
      (action: any) => this.handleTradesFilters(action, this),
    )
  }
  private *handleTradesFilters(action: any, _this: any): Generator {
    yield runSagaTask(TaskNames.Markets.TradeFilter, function* () {
      const sdk = yield* waitforSDK()
      const brokerQueryClient = sdk.query.broker
      const page = Number(action.options.page)
      const offset = (page - 1) * 20
      const offsetLong = Long.fromNumber(offset >= 0 ? offset : 0)
      const trades = (yield call([brokerQueryClient, brokerQueryClient.Trades],
        QueryTradesRequest.fromPartial({
          pagination: {
            limit: new Long(20),
            offset: offsetLong,
            countTotal: true,
            reverse: false,
          }
        }),
      )) as QueryTradesResponse
      yield put(setTrades({
        data: trades.trades,
        meta: {
          totalPages: trades.pagination?.total.toNumber() ?? 0,
        }
      }))
    })
  }

  private *watchDateFilters(): any {
    yield takeLatest(
      MarketsActionType.UPDATE_DATE_FILTERS_MARKETS_VOLUME_CHART,
      this.handleDateFiltersMarketsVolume,
    )
  }

  private *handleDateFiltersMarketsVolume(action: any): any {
    yield runSagaTask(TaskNames.Markets.DateFilter, function* () {
      const sdk = yield* waitforSDK()
      // Query from genesis block of carbon 
      const from = moment(moment(action.options.startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const until = moment(moment(action.options.endDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
      const marketsVolumeResponse = (yield call([sdk.insights, sdk.insights.MarketsVolume], {
        from,
        until,
        interval: action.options?.interval ?? "day"
      })) as Insights.InsightsQueryResponse<Insights.QueryGetMarketVolumeResponse>
      yield put(setMarketsVolumeChartData(marketsVolumeResponse.result.entries))
    })
  }

}
