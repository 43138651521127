import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement } from 'react'

interface Props {
  isLoaded: boolean
  children: any
  c?: any
  className?: string
}

const Loader: React.FunctionComponent<Props> = ({
  isLoaded,
  children,
  className,
}: Props): ReactElement<Props> => {
  const classes = useStyles()

  const opacityStyle = {
    [classes.show]: isLoaded,
    [classes.noShow]: !isLoaded,
  }

  return (
    <div className={clsx(classes.root, className, opacityStyle)}>
      {children}
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    display: 'block',
    textAlign: 'left',
    fontSize: '0.8125rem',
    transition: 'opacity 0.3s',
  },
  noShow: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
})

export default Loader
