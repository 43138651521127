import { makeStyles, SvgIcon, SvgIconProps } from '@material-ui/core'
import clsx from 'clsx'
import { ReactComponent as PlaceholderIcon } from 'assets/Placeholder.svg'
import { TokenIconPath } from 'js/constants'
import { useRedux } from 'js/hooks'
import React, { useState } from 'react'

function getTokenIconURL(tokenName: string, fileType: string = 'svg') {
  return TokenIconPath.replace(':token_name', tokenName).replace(':file_type', fileType)
}

const tokenNameMap: {
  [index: string]: string
} = {
  USTC: "UST",
  BSC: 'BNB',
  BTCB: 'BTC',
  IUSD: 'USD',
  nNEO: 'NEO'
}

export interface TokenIconProps extends SvgIconProps {
  denom?: string
  tokenSymbol?: string
}

const TokenIcon: React.FunctionComponent<TokenIconProps> = (
  props: TokenIconProps,
) => {
  const { denom, className, tokenSymbol, ...rest } = props
  const sdk = useRedux((state) => state.core.carbonSDK)
  const classes = useStyles()
  const [imageSrcError, setImageSrcError] = useState(false);
  const [imageSrcIndex, setImageSrcIndex] = useState(0);

  let symbolToFetch = ""
  if (tokenSymbol) {
    symbolToFetch = tokenSymbol
  } else if (denom) {
    symbolToFetch = sdk?.token.getTokenName(denom) ?? ""
  }

  symbolToFetch = symbolToFetch.replace("cib", "")
  symbolToFetch = tokenNameMap[symbolToFetch] ?? symbolToFetch

  const imageSrcArray = symbolToFetch
    ? [
      getTokenIconURL(symbolToFetch, 'svg'),
      getTokenIconURL(symbolToFetch, 'png'),
    ] : []

  const handleImgSrcError = () => {
    if (imageSrcIndex < imageSrcArray.length - 1) {
      setImageSrcIndex(imageSrcIndex + 1);
    } else {
      setImageSrcError(true);
    }
  }

  let defaultIcon: React.ReactNode = <PlaceholderIcon />

  return !imageSrcError && symbolToFetch ? (
    <img className={clsx(classes.svg, className)} src={imageSrcArray[imageSrcIndex]} onError={handleImgSrcError} alt={symbolToFetch} />
  ) : (
    <SvgIcon
      {...rest}
      className={clsx(classes.svg, className)}
    >
      {defaultIcon}
    </SvgIcon>
  )
}

const useStyles = makeStyles({
  svg: {
    fontSize: 'inherit',
    width: '2em',
    height: '2em',
  },
})

export default TokenIcon
