import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { Constants } from 'js/constants/generic'
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: 'a few seconds',
    m: "1 minute",
    mm: "%d minutes",
    h: "1 hour",
    hh: "%d hours",
    d: "1 day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "1 year",
    yy: "%d years",
  },
})

export const BIG_ONE = new BigNumber(1)
export const BIG_ZERO = new BigNumber(0)

export const makeTimeLeftLabel = (durationInNs?: BigNumber | null): string | undefined => {
  if (durationInNs === undefined || durationInNs === null) return

  return dayjs().add(durationInNs.toNumber(), 'seconds').fromNow(true)
}

export interface MakeMoneyLabelOptions {
  currency?: string
  formatDecimals?: number
  decimals?: number
}

export const formatTokenNumber = (value: BigNumber, decimals: number) => {
  return value.shiftedBy(-decimals)
}

export const makeMoneyLabel = (unitlessAmount?: BigNumber, options: MakeMoneyLabelOptions = {}) => {
  if (!unitlessAmount) return
  const decimalsMap: { [index: string]: number } = Constants.Decimals
  let decimals = options.decimals
  if (decimals === undefined) {
    decimals = decimalsMap[options.currency?.toUpperCase() || ''] || Constants.Decimals.SWTH
  }
  return formatTokenNumber(unitlessAmount, decimals).toFormat(options.formatDecimals || 0)
}

export interface MakePercentLabelOptions {
  suffix?: string
  formatDecimals?: number
  shiftDecimals?: number
}
export const makePercentLabel = (decimalValue?: BigNumber, options: MakePercentLabelOptions = {}) => {
  if (!decimalValue) return
  const shiftDecimals = options.shiftDecimals || 2
  const formatDecimals = options.formatDecimals || 2
  const suffix = options.suffix || '%'
  return `${decimalValue.shiftedBy(shiftDecimals).toFormat(formatDecimals)} ${suffix}`.trim()
}

export const divideSafely = (numerator: BigNumber, denominator?: BigNumber) => {
  if (!denominator) return BIG_ZERO
  const result = numerator.div(denominator)
  if (result.isNaN() || !result.isFinite()) {
    return BIG_ZERO
  }
  return result
}

export const parseNumber = (number: string | number = '0', defaultValue?: BigNumber): BigNumber | null => {
  const parsedNumber = typeof number === 'string' ? JSON.parse(number) : number
  const bnNumber = new BigNumber(parsedNumber)
  if (bnNumber.isNaN() || !bnNumber.isFinite()) {
    return defaultValue || null
  }
  return bnNumber
}

// get the number of decimal places seen in a number
export function getDecimalPlaces(num: string | number): number {
  const moduloResult = new BigNumber(num).modulo(1)
  return moduloResult.gt(0) ? moduloResult.toString(10).split('.')[1].length : 0
}

export const BN_ZERO = new BigNumber(0)
export const BN_ONE = new BigNumber(1)

export const parseBN = (input?: string | BigNumber | number, defaultValue?: BigNumber) => {
  if (!input && input !== 0) return defaultValue;
  const result = BigNumber.isBigNumber(input) ? input : new BigNumber(input);
  if (!result.isFinite() || result.isNaN())
    return defaultValue;

  return result;
};

export const bnOrZero = (input?: string | BigNumber | number, defaultValue: BigNumber = BN_ZERO) => {
  return parseBN(input, defaultValue)!;
};

export const SHIFT_DECIMALS = 18

export const adjustHuman = (value: string): BigNumber => bnOrZero(value).shiftedBy(-SHIFT_DECIMALS)

export const adjustUnitless = (value: string): BigNumber => bnOrZero(value).shiftedBy(SHIFT_DECIMALS)

export function decodeTallyParamsToBN(uint8Array: Uint8Array) {
  const decodedStr = new TextDecoder().decode(uint8Array)
  return adjustHuman(decodedStr)
}
