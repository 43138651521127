import daysjs from 'dayjs'
import { LoadingTasks } from 'js/models'
import { ActionType, LayoutAction } from './action'

export type LayoutState = {
  loadingTasks: LoadingTasks
  tasksRegistry: { [index: string]: any }
}

const initialState: LayoutState = {
  loadingTasks: {},
  tasksRegistry: {},
}

const reducer = (
  state: LayoutState = initialState,
  action: LayoutAction,
) => {
  let loadingTask: any = null
  let taskName: string | undefined
  switch (action.type) {
    case ActionType.ADD_BACKGROUND_LOADING:
      loadingTask = state.loadingTasks[action.name] || {}
      loadingTask[action.uuid] = daysjs()
      state.tasksRegistry[action.uuid] = action.name
      return {
        ...state,
        loadingTasks: {
          ...state.loadingTasks,
          [action.name]: loadingTask,
        },
        tasksRegistry: {
          ...state.tasksRegistry,
        },
      }
    case ActionType.REMOVE_BACKGROUND_LOADING:
      taskName = state.tasksRegistry[action.uuid]
      if (!taskName) {
        return state
      }
      loadingTask = state.loadingTasks[taskName!]
      if (!loadingTask || !loadingTask[action.uuid]) {
        return state
      }
      
      delete loadingTask[action.uuid]
      if (!Object.keys(loadingTask).length) {
        delete state.loadingTasks[taskName!]
      }
      delete state.tasksRegistry[action.uuid]
      return {
        ...state,
        loadingTasks: {
          ...state.loadingTasks,
        },
        tasksRegistry: {
          ...state.tasksRegistry,
        },
      }
    default:
      return state
  }
}

export default reducer
