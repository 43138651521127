import { Token, TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { Record, RecordOf } from 'immutable'
import { TokensAction, TokensActionType } from '../actions/tokens'

export interface TokensProps {
  total: TokenBalance[]
  info: Token[]
}
const makeState: Record.Factory<TokensProps> = Record<TokensProps>({
  total: [],
  info: [],
})
export type TokensState = RecordOf<TokensProps>

function tokensReducer(
  state: TokensState = makeState(),
  action: TokensAction,
): TokensState {
  switch (action.type) {
    case TokensActionType.CLEAR:
      return makeState()
    case TokensActionType.SET_TOKENS_BALANCES:
      return state.updateIn(['total'], () => action.total)
    case TokensActionType.SET_TOKENS_INFO:
      return state.updateIn(['info'], () => action.info)
    default:
      return state
  }
}

export default tokensReducer
