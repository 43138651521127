import { IndexedTx } from '@cosmjs/stargate'
import { Record, RecordOf } from 'immutable'
import { BlockAction, BlockActionType } from '../actions/block'
import { BlockProp } from './blocks'
import { BlockResultsResponse } from '@cosmjs/tendermint-rpc/build/tendermint37'

export interface BlockProps {
  transactions: IndexedTx[]
  events?: BlockResultsResponse
  blockInfo?: BlockProp
}
const makeState: Record.Factory<BlockProps> = Record<BlockProps>({
  transactions: [],
  events: undefined,
  blockInfo: undefined,
})
export type BlockState = RecordOf<BlockProps>

function blockReducer(
  state: BlockState = makeState(),
  action: BlockAction,
): BlockState {
  switch (action.type) {
    case BlockActionType.CLEAR:
      return makeState()
    case BlockActionType.SET_TRANSACTIONS:
      return state.updateIn(['transactions'], () => action.transactions)
    case BlockActionType.SET_EVENTS:
      return state.set('events', action.events)
    case BlockActionType.SET_BLOCK_INFO:
      return state.set('blockInfo', action.blockInfo)
    default:
      return state
  }
}

export default blockReducer
