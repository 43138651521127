import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { DataLoadSegment, NotFoundState, Page, Section, TabsButtons } from 'js/components'
import { TaskNames } from 'js/constants'
import { RootState } from 'js/store'
import React, { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { Timeline } from 'react-twitter-widgets'
import {
  CDP,
  CrossChainTransfers, Delegations, Holdings,
  InternalTransfersWrapper,
  OrdersWrapper,
  PnlTable, PositionsWrapper, Profile, Rewards, TradesWrapper
} from './components'
import TransactionsWrapper from './components/TransactionsWrapper'
import { TokenBalance } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import EVMTransfers from './components/EVMTransfers'
export interface UserCDPBalance extends TokenBalance {
  underlying: BigNumber
  underlyingDenom: string
  collateralized: BigNumber
}

interface Props {
}

const addQueryParam = (key: string, value: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.pushState({}, '', url.toString());
};

const getQueryParam = (key: string) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(key) || '';
};

// TODO: Migrate components to carbon-js-sdk
const AccountComponent: React.FunctionComponent<Props> = (): ReactElement<Props> => {

  let profile = useSelector((state: RootState) => state.account.profile)
  const tab = (new URLSearchParams(window.location.search)).get('tab')
  const address = window.location.pathname.split('/')[2];

  const classes = useStyles()

  const tabMap: any = {
    'holdings': 0,
    'stakings': 1,
    'cdp': 2,
    'positions': 3,
    'orders': 4,
    'trades': 5,
    'external_transfers': 6,
    'internal_transfers': 7,
    'evm_transfers': 8,
    'transactions': 9,
    'pnl': 10,
  }
  const [tabId, setTabId] = useState(tabMap[tab ?? 'holdings'])
  const items = [
    { label: 'Holdings' },
    { label: 'Stakings' },
    { label: 'CDP' },
    { label: 'Positions' },
    { label: 'Orders' },
    { label: 'Trades' },
    { label: 'Crosschain Transfers' },
    { label: 'Internal Transfers' },
    { label: 'Carbon EVM Transfers' },
    { label: 'Transactions' },
    { label: 'PNL Ranking' },
  ]

  const handleChange = (event: any, newValue: number) => {
    const tabTitle = Object.entries(tabMap).find(([_, id]) => id === newValue) ?? ''
    const tabKey = getQueryParam('tab')
    if (tabTitle && tabKey !== tabTitle[0]) addQueryParam('tab', tabTitle[0])
    setTabId(newValue)
  }

  if (!profile?.address) profile = { address, username: '', twitter: '' }
  return (
    <Page title="Address Details">
      <DataLoadSegment
        tasknames={[TaskNames.Account.Profile]}
        size="2.5rem"
      >
        {(
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={profile?.twitter ? 8 : 12}>
                  <Profile profile={profile} />
                </Grid>
                {
                  profile?.twitter && (
                    <Grid item xs={12} sm={profile?.twitter ? 4 : 12}>
                      <Timeline
                        dataSource={{
                          sourceType: 'profile',
                          screenName: `${profile?.twitter || `switcheonetwork`}`,
                        }}
                        options={{
                          height: '275',
                          width: '100%',
                        }}
                      />
                    </Grid>
                  )
                }
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Section className={classes.tabsRoot}>
                <TabsButtons handleChange={handleChange} items={items} tabClass={classes.tab} value={tabId} />
                {tabId === 0 && (
                  <Holdings
                    address={address}
                  />
                )}
                {tabId === 1 && (
                  <React.Fragment>
                    <Delegations
                      title="Stakings"
                    />
                    <Rewards />
                  </React.Fragment>
                )}
                {tabId === 2 && (
                  <CDP address={address} />
                )}
                {tabId === 3 && (
                  <PositionsWrapper />
                )}
                {tabId === 4 && (
                  <OrdersWrapper />
                )}
                {tabId === 5 && (
                  <TradesWrapper address={address} />
                )}
                {tabId === 6 && (
                  <CrossChainTransfers sectionClass={classes.sectionClass} />
                )}
                {tabId === 7 && (
                  <InternalTransfersWrapper address={address} />
                )}
                {tabId === 8 && (
                  <EVMTransfers address={address} />
                )}
                {tabId === 9 && (
                  <TransactionsWrapper />
                )}
                <div role="tabpanel" hidden={tabId !== 10}>
                  <PnlTable
                    title="PNL Ranking"
                    address={address}
                  />
                </div>
              </Section>
            </Grid>
          </Grid>
        )}
        {!address && (
          <NotFoundState title="No Account Found">
            <Typography variant="body1">
              We can’t find any account with this address. Please check your network setting or try searching for another account.
            </Typography>
          </NotFoundState>
        )}
      </DataLoadSegment>
    </Page>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    padding: theme.spacing(3),
  },
  tab: {
    fontSize: '1.125rem',
  },
  tabsRoot: {
    padding: 0,
  },
  title: {
    textAlign: 'left',
  },
  container: {},
  gridContainer: {
    display: 'grid',
    gridGap: '1.2rem',
    gridTemplateAreas: `'holdings'`,
    marginTop: '1.2rem',
  },
  item: {
    padding: '1.5rem 2rem',
  },
  address: {
    marginLeft: '0.8rem',
  },
  addressContainer: {
    extend: 'item',
  },
  holdingsContainer: {
    extend: 'item',
    gridArea: 'holdings',
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
  },
  sectionClass: {
    marginBottom: theme.spacing(3),
  },
  transactions: {
    padding: theme.spacing(3),
  },
  filter: {
    marginBottom: theme.spacing(2),
  },
}))

export default AccountComponent
