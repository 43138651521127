import { TableCellInfo } from 'js/components'

export const SettledHeaders: TableCellInfo[] = [
  {
    align: 'inherit',
    content: 'Display Name',
  },
  {
    align: 'inherit',
    content: 'Name',
  },
  {
    content: 'Expiry Date',
  },
  {
    content: 'Settled At Height',
  }
]

export const FundingHeaders: TableCellInfo[] = [
  {
    align: 'inherit',
    content: 'Market',
  },
  {
    content: 'Last Funding At',
  },
  {
    content: 'Funding Rate',
  },
]
