import { makeStyles, TableCell, TableRow } from '@material-ui/core'
import { CellLink, TableCellInfo, TableSectionCell, TooltipHint } from 'js/components'
import { copyTextToClipBoard, shorten } from 'js/helpers/utils'
import React, { ReactElement, useState } from 'react'
import clsx from 'clsx'
import { Email, Telegram } from '@material-ui/icons';
import { CopyTradeDarkSVG } from 'assets'

interface Props {
  model: any // TODO: change to model after sdk update
}

interface UptimeStyle {
  bad?: boolean,
  good?: boolean,
  ok?: boolean
}

interface Copy {
  [key: string]: boolean
}

const NodeRow: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { model } = props
  const classes = useStyles()
  const [copied, setCopied] = useState<Copy>({})
  const NodeRowValues: TableCellInfo[] = [
    {
      align: 'inherit',
      content: model?.moniker,
    },
    {
      align: 'inherit',
      content: "creator",
    },
    {
      align: 'left',
      content: "uptime",
    },
    {
      align: 'inherit',
      content: model?.nodeId,
    },
    {
      align: 'inherit',
      content: model?.appVersion,
    },
    {
      align: 'inherit',
      content: model?.appCommit ? shorten(model?.appCommit) : model?.appCommit,
    },
    {
      align: 'inherit',
      content: model?.latestBlockHeight,
      to: `/block/${model?.latestBlockHeight}`,
    },
  ]

  const colorizedUptime = (uptime: number = 0) => {
    let style: UptimeStyle = {}
    if (uptime < 60) style.bad = true
    else if (uptime < 90) style.ok = true
    else style.good = true
    return style
  }

  const copyText = (text: string) => {
    copyTextToClipBoard(text)
    //set Copied to true to show Copied UI
    setCopied((old: Copy) => { return { ...old, [text]: true } })
    setTimeout(() => setCopied((old: Copy) => { return { ...old, [text]: false } }), 1300)
  }

  return (
    <TableRow key={model?.nodeId} hover>
      {NodeRowValues.map((nodeVal: TableCellInfo, index: number) => {
        switch (nodeVal.content) {
          case "creator":
            return <TableCell className={classes.creator} align={nodeVal.align}>
              <div><span>{model?.creator?.name}</span></div>
              <div style={{ marginTop: 5 }}>
                <CellLink href={model?.creator?.telegram} style={{ padding: "5px 5px 5px 0px" }}>
                  <Telegram width={25} color={'secondary'} />
                </CellLink>
                <CellLink href={`mailto:${model?.creator?.email}`} style={{ padding: 5 }}>
                  <Email width={30} color={'secondary'} />
                </CellLink>
              </div>
            </TableCell>
          case "uptime":
            return <TableCell className={classes.uptime} align={nodeVal.align}>
              <div className={classes.urlContainer}>
                <span>
                  <TooltipHint title={model?.rpcUrl}>
                    <span>
                      {shorten(model?.rpcUrl, 12)}
                    </span>
                  </TooltipHint>
                </span>
                <TooltipHint title={"Copied!"} open={!!copied[model?.rpcUrl]}>
                  <CopyTradeDarkSVG onClick={() => copyText(model?.rpcUrl)} fontSize={"small"} className={classes.iconButton} />
                </TooltipHint>
                <span className={clsx(classes.value, { ...colorizedUptime(model?.rpcUptime) })}>
                  {model.rpcUptime}%
                </span>
              </div>
              <div className={classes.urlContainer}>
                <span>
                  <TooltipHint title={model?.wsUrl}>
                    <span>
                      {shorten(model?.wsUrl, 12)}
                    </span>
                  </TooltipHint>
                </span>
                <TooltipHint title={"Copied!"} open={!!copied[model?.wsUrl]}>
                  <CopyTradeDarkSVG onClick={() => copyText(model?.wsUrl)} fontSize={"small"} className={classes.iconButton} />
                </TooltipHint>
                <span className={clsx(classes.value, { ...colorizedUptime(model?.wsUptime) })}>
                  {model.wsUptime}%
                </span>
              </div>
              <div className={classes.urlContainer}>
                <span>
                  <TooltipHint title={model?.tmWsUrl}>
                    <span>
                      {shorten(model?.tmWsUrl, 12)}
                    </span>
                  </TooltipHint>
                </span>
                <TooltipHint title={"Copied!"} open={!!copied[model?.tmWsUrl]}>
                  <CopyTradeDarkSVG onClick={() => copyText(model?.tmWsUrl)} fontSize={"small"} className={classes.iconButton} />
                </TooltipHint>
                <span className={clsx(classes.value, { ...colorizedUptime(model?.tmWsUrlUptime) })}>
                  {model.tmWsUrlUptime}%
                </span>
              </div>
              <div className={classes.urlContainer}>
                <span>
                  <TooltipHint title={model?.insightsUrl}>
                    <span>
                      {shorten(model?.insightsUrl, 12)}
                    </span>
                  </TooltipHint>
                </span>
                <TooltipHint title={"Copied!"} open={!!copied[model?.insightUrl]}>
                  <CopyTradeDarkSVG onClick={() => copyText(model?.insightUrl)} fontSize={"small"} className={classes.iconButton} />
                </TooltipHint>
                <span className={clsx(classes.value, { ...colorizedUptime(model?.insightUptime) })}>
                  {model.insightUptime}%
                </span>
              </div>
            </TableCell>
          default:
            return <TableSectionCell {...nodeVal} key={model?.nodeId + index} />
        }
      })}
    </TableRow>
  )
}

export default NodeRow

const useStyles = makeStyles((theme) => ({
  value: {
    float: 'right',
    textTransform: 'capitalize',
    "&.bad": {
      color: theme.palette.error.light
    },
    "&.ok": {
      color: theme.palette.warning.light
    },
    "&.good": {
      color: theme.palette.success.light
    },
  },
  iconButton: {
    margin: theme.spacing(0, 0.5, 0, 0.5),
    cursor: 'pointer',
    justifySelf: "center"
  },
  creator: {
    width: 180,
    textAlign: 'left'
  },
  uptime: {
    width: 220,
    textAlign: 'left'
  },
  urlContainer: {
    display: "grid",
    gridTemplateColumns: "100px 1fr 1fr",
    alignItems: "center"
  }
}))
