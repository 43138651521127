import { Box, BoxProps, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { CarbonSDK, TokenUtils } from 'carbon-js-sdk'
import { APIPosition } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/position/export'
import { sha256 } from 'js-sha256'
import { positionsPaginationLimit } from 'js/constants'
import { shortenHash } from 'js/helpers/utils'
import { useTaskSubscriber } from 'js/hooks'
import { PositionsResponse } from 'js/reducers/position'
import { RootState } from 'js/store'
import { BIG_ZERO, adjustHuman, bnOrZero } from 'js/utils'
import React, { ReactElement, ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { AmountDenomCell, CellLink, DataLoadSegment, ListTable, Section, TableEmptyState } from '../components'
import PaginationByData from './PaginationByData'
export interface PositionProps extends BoxProps {
  loadName: string[]
  positions: PositionsResponse
  action: any
  closedOnly?: boolean
  title: string
}

interface Props extends PositionProps { }

const Positions: React.FunctionComponent<Props> = ({
  loadName,
  positions,
  action,
  closedOnly = false,
  ...rest
}: Props): ReactElement<Props> => {
  const [loading] = useTaskSubscriber(...loadName)
  const sdk = useSelector((state: RootState) => state.core.carbonSDK)
  const markets = useSelector((state: RootState) => state.app.markets)
  // For pagination
  const [page, setPage] = useState(1)
  const positionsDataToDisplay = positions.data.slice((page - 1) * positionsPaginationLimit, ((page - 1) * positionsPaginationLimit) + positionsPaginationLimit)

  return (
    <Section {...rest}>
      <DataLoadSegment loading={loading}>
        <ListTable>
          <TableHead>
            <TableRow>
              <TableCell>Position ID</TableCell>
              <TableCell>Market</TableCell>
              {!closedOnly && (
                <TableCell align="right">Position Size</TableCell>
              )}
              <TableCell align="right">Avg Price</TableCell>
              <TableCell align="right">Realized PnL</TableCell>
              {!closedOnly && (
                <TableCell align="right">Margin Allocated</TableCell>
              )}
              <TableCell align="right">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{positionsDataToDisplay.map((position, index) => renderPosition(position, index, sdk))}</TableBody>
        </ListTable>
        {positionsDataToDisplay.length > 0 && (
          <Box marginTop={3}>
            <PaginationByData
              data={positions.data}
              setPage={setPage}
              page={page}
              itemsPerPage={positionsPaginationLimit}
            />
          </Box>
        )}
        {positionsDataToDisplay.length === 0 && (
          <TableEmptyState itemName="positions" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderPosition(position: APIPosition, index: number, sdk?: CarbonSDK): ReactNode {
    const market = markets[position.marketId]
    const baseDenom = market?.base ?? ''
    const quoteDenom = market?.quote ?? ''
    const symbolOverride = market?.marketType === 'futures' ? TokenUtils.FuturesDenomOverride : undefined
    const baseSymbol = sdk?.token.getTokenName(market?.base ?? '', symbolOverride).toUpperCase() ?? ''
    const quoteSymbol = sdk?.token.getTokenName(market?.quote ?? '', symbolOverride).toUpperCase() ?? ''
    const lotsBN = sdk?.token.toHuman(baseDenom, bnOrZero(position.lots)) ?? BIG_ZERO
    const entryPriceBN = adjustHuman(position.avgEntryPrice).shiftedBy(market?.basePrecision.sub(market?.quotePrecision).toNumber())
    const realizedPnlBN = sdk?.token.toHuman(quoteDenom, bnOrZero(position.realizedPnl)) ?? BIG_ZERO
    const allocatedMarginBN = sdk?.token.toHuman(quoteDenom, adjustHuman(position.avgAllocatedMargin)) ?? BIG_ZERO
    // const positionStatus = position.closedBlockHeight.toNumber() > 0 ? 'closed' : 'open'
    return (
      <TableRow key={`${position.marketId}${index}`} hover>
        <TableCell>
          <CellLink to={`/position/${position.address}/${encodeURIComponent(position.marketId)}/${position.openedBlockHeight}`}>
            {shortenHash(sha256(position.address + position.marketId + position.openedBlockHeight).toString(), 2).toLocaleUpperCase()}
          </CellLink>
        </TableCell>
        <TableCell>
          <CellLink to={`/market/${encodeURIComponent(position.marketId)}`}>{position.marketId}</CellLink>
        </TableCell>
        {!closedOnly && (
          <TableCell align="right">
            {lotsBN.toString()} {baseSymbol}
          </TableCell>
        )}
        <TableCell align="right">{entryPriceBN.toFormat(2)}</TableCell>
        <TableCell align="right">{realizedPnlBN.toFormat(2)}</TableCell>
        {!closedOnly && (
          <AmountDenomCell denom={quoteSymbol} amount={allocatedMarginBN.toFormat(2)} />
        )}
        <TableCell align="right">
          <CellLink to={`/block/${position.openedBlockHeight?.toString()}`}>
            {position.openedBlockHeight?.toString()}
          </CellLink>
        </TableCell>
      </TableRow>
    )
  }
}

export default Positions
