import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'
import accountReducer, { AccountState } from 'js/reducers/account'
import appReducer, { AppState } from 'js/reducers/app'
import blockReducer, { BlockState } from 'js/reducers/block'
import blocksReducer, { BlocksState } from 'js/reducers/blocks'
import dashboardReducer, { DashboardState } from 'js/reducers/dashboard'
import eventsReducer, { EventsState } from 'js/reducers/events'
import governanceReducer, { GovernanceState } from 'js/reducers/governance'
import leaderboardReducer, { LeaderboardState } from 'js/reducers/leaderboard'
import leaderboardHistoryReducer, { LeaderboardHistoryState } from 'js/reducers/leaderboardHistory'
import poolsReducer, { LiquidityPoolsState } from 'js/reducers/liquidityPools'
import marketReducer, { MarketState } from 'js/reducers/market'
import marketsReducer, { MarketsState } from 'js/reducers/markets'
import nodesReducer, { NodesState } from 'js/reducers/nodes'
import orderReducer, { OrderState } from 'js/reducers/order'
import positionReducer, { PositionState } from 'js/reducers/position'
import tokenReducer, { TokenState } from 'js/reducers/token'
import tokensReducer, { TokensState } from 'js/reducers/tokens'
import transactionReducer, { TransactionState } from 'js/reducers/transaction'
import transactionsReducer, { TransactionsState } from 'js/reducers/transactions'
import validatorReducer, { ValidatorState } from 'js/reducers/validator'
import validatorsReducer, { ValidatorsState } from 'js/reducers/validators'
import { combineReducers, Reducer } from 'redux'
import layoutReducer, { LayoutState } from './layout'
import coreReducer, { CoreState } from './core'
import tutorialReducer, { TutorialState } from './tutorial'
import proposalReducer, { ProposalState } from 'js/reducers/proposal'
import allianceReducer, { AllianceState } from 'js/reducers/alliance'

export interface RootState {
  app: AppState
  core: CoreState
  account: AccountState
  alliance: AllianceState
  tokens: TokensState
  block: BlockState
  blocks: BlocksState
  dashboard: DashboardState
  events: EventsState
  leaderboard: LeaderboardState
  leaderboardHistory: LeaderboardHistoryState
  market: MarketState
  markets: MarketsState
  nodes: NodesState
  order: OrderState
  pools: LiquidityPoolsState
  proposal: ProposalState
  router: RouterState
  transaction: TransactionState
  transactions: TransactionsState
  tutorial: TutorialState
  token: TokenState
  validator: ValidatorState
  validators: ValidatorsState
  governance: GovernanceState
  layout: LayoutState
  position: PositionState
}

function createRootReducer(history: History<any>): Reducer<RootState> {
  return combineReducers<RootState>({
    app: appReducer,
    core: coreReducer,
    account: accountReducer,
    alliance: allianceReducer,
    tokens: tokensReducer,
    block: blockReducer,
    blocks: blocksReducer,
    dashboard: dashboardReducer,
    events: eventsReducer,
    leaderboard: leaderboardReducer,
    leaderboardHistory: leaderboardHistoryReducer,
    market: marketReducer,
    markets: marketsReducer,
    nodes: nodesReducer,
    order: orderReducer,
    position: positionReducer,
    pools: poolsReducer,
    proposal: proposalReducer,
    router: connectRouter(history),
    transaction: transactionReducer,
    transactions: transactionsReducer,
    tutorial: tutorialReducer,
    token: tokenReducer,
    validator: validatorReducer,
    validators: validatorsReducer,
    governance: governanceReducer,
    layout: layoutReducer,
  })
}

export default createRootReducer
