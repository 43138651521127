import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiTooltip']>> => ({
  tooltipArrow: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
  },
  tooltip: {
    ...theme.typography.body2,
    color: theme.palette.secondary.contrastText,
    borderRadius: 8,
  },
  arrow: {
    color: theme.palette.secondary.main,
  },
})
