import {
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlProps,
    Input,
    ListItemText,
    ListSubheader,
    makeStyles,
    MenuItem,
    Select,
    Theme
  } from '@material-ui/core'
  
  import clsx from 'clsx'
  import { TxTypeLabel } from 'js/components/ParsedLabel'
  import { switcheo } from 'js/theme/palettes/colors'
  import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
  
  export interface FilterOption {
    label?: string
    value: string
    selected?: boolean
  }
  
  type Props = FormControlProps & {
    options: FilterOption[]
    filters?: { [index: string]: boolean }
    onFilter?: (types: string[]) => void
  }
  
  const PoolFilter: React.FunctionComponent<Props> = (
    props: Props,
  ): ReactElement<Props> => {
    const {
      title,
      className,
      children,
      options,
      filters,
      onFilter,
      ...rest
    } = props
    const classes = useStyles(props)
    const [selectedTypes, setSelectedTypes] = useState<string[]>([])
  
    useEffect(() => {
      if (filters) {
        setSelectedTypes(Object.keys(filters))
      }
    }, [filters])
  
    const onChange = (evt: ChangeEvent<any>, child: React.ReactNode) => {
      setSelectedTypes([...evt.target.value])
    }
    const onConfirmFilter = () => {
      if (typeof onFilter === 'function') {
        onFilter(selectedTypes)
      }
    }
    const renderValue = (input: any) => {
      const selectedTypes = input as string[]
      if (!selectedTypes.length) return 'Chain'
      return `Chains Selected: ${selectedTypes.length}`
    }
    return (
      <FormControl {...rest} className={clsx(classes.root, className)}>
        <Select
          multiple
          displayEmpty
          variant="filled"
          value={selectedTypes}
          onChange={onChange}
          onClose={onConfirmFilter}
          input={<Input />}
          MenuProps={{ getContentAnchorEl: null, className: classes.popOver }}
          className={classes.selectInput}
          renderValue={renderValue}
        >
          <ListSubheader className={classes.subheader}>Chains</ListSubheader>
          {!options.length && (
            <MenuItem disabled className={classes.loadingItem}>
              <CircularProgress size='1rem' color='secondary' />
            </MenuItem>
          )}
          {options.map((option, index) => {
            return (
              <MenuItem
                className={classes.menuItem}
                key={index}
                value={option.value}
              >
                <Checkbox
                  color="primary"
                  checked={selectedTypes.includes(option.value)}
                />
                <ListItemText
                  primary={
                    <TxTypeLabel>{option.label}</TxTypeLabel>
                  }
                />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }
  
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      minWidth: '16rem',
      maxWidth: '18rem',
      '& .MuiInputBase-input': {
        border: `none`,
      },
      margin: '0 12px 10px 0'
    },
    subheader: {
      backgroundColor: switcheo.background.grey,
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
      letterSpacing: '-0.0125rem',
      color: theme.palette.secondary.main,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(2),
      fontWeight: 'bold',
    },
    loadingItem: {
      justifyContent: 'center',
    },
    popOver: {
      '& .MuiMenu-paper': {
        backgroundColor: switcheo.background.grey,
        borderRadius: 8,
      },
    },
    selectInput: {
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        color: theme.palette.text.primary,
      },
    },
    menuItem: {
      backgroundColor: switcheo.background.grey,
      padding: theme.spacing(0.75, 1.5),
      '&:hover': {
        backgroundColor: switcheo.hover,
      },
      '& .MuiCheckbox-root': {
        padding: 0,
        paddingRight: theme.spacing(1),
        color: theme.palette.secondary.main,
      },
  
      '& .MuiListItemText-root': {
        fontSize: '1rem',
        lineHeight: '1.25rem',
        letterSpacing: '-0.0125rem',
        color: theme.palette.primary.contrastText,
      },
    },
  }))
  
  export default PoolFilter