import { Box, Button, makeStyles, Theme } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { CellLink } from 'js/components'
import { switcheo } from 'js/theme/palettes/colors'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import React from 'react'
import LiquidityPoolOverview from '../common/LiquidityPoolOverview'
import { rowData } from './LiquidityPoolsTable'

interface Props {
  data: rowData,
  loadMore: Boolean,
  loadedAll: Boolean,
  onLoadMore: () => void
}

const LiquidityPoolRow: React.FC<Props> = (
  props: Props,
): React.ReactElement<Props> => {
  const { data, loadMore, loadedAll, onLoadMore } = props
  const classes = useStyles()
  const formatNumber = (number: BigNumber) => {
    var n = number.toNumber()
    if (n < 1e3) return n.toFixed(2);
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };
  return (
    <Box key={data.denom} className={classes.root}>
      {loadMore && !loadedAll
        ? <Box className={classes.overlay}>
          <Button className={classes.overLayButton} onClick={onLoadMore}>Load More</Button>
        </Box>
        : undefined
      }
      <Box style={{ marginLeft: '32px', width: '350px' }}>
        <LiquidityPoolOverview symbolA={data.symbolA} symbolB={data.symbolB} weightA={data.weightA} weightB={data.weightB}
          chainA={data.chainA} chainB={data.chainB} isCdpTokenA={data.isCdpTokenA} isCdpTokenB={data.isCdpTokenB} />
      </Box>
      <Box style={{ width: '265px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ width: '100px' }}>
            <div >{formatNumber(data.amountABN)} {data.symbolA}</div>
            <div className={classes.notional}>{data.notionalTokenA.isZero() ? '-' : `$${data.notionalTokenA.toFormat(2)}`}</div>
          </Box>
          <Box className={classes.verticalLine}></Box>
          <Box style={{ marginLeft: 16 }}>
            <div >{formatNumber(data.amountBBN)} {data.symbolB}</div>
            <div className={classes.notional}>{data.notionalTokenB.isZero() ? '-' : `$${data.notionalTokenB.toFormat(2)}`}</div>
          </Box>
        </div>
      </Box>
      <Box style={{ width: '125px' }}>
        <div>{data.notionalAB.isZero() ? '-' : `$${data.notionalAB.toFormat(2)}`}</div>
      </Box>
      <Box style={{ width: '125px' }}>
        <div>
          {data?.notionalVolume ? (
            <span>
              <div>{`$${formatNumber(data.notionalVolume)}`}</div>
            </span>
          ) : (
            <div>{"-"}</div>
          )}
        </div>
      </Box>
      <Box style={{ width: '125px' }}>
        <div>{data.apy.isPositive() && !data.apy.isZero() ? `${data.apy.toFormat(2)}%` : "-"}</div>
      </Box>
      <Box style={{ marginRight: '32px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '250px' }}>
        {data.marketName ? (
          <CellLink to={`/market/${encodeURIComponent(data.market)}`}>{data.marketName}</CellLink>
        ) : (
          <div>{"-"}</div>
        )}
        <CellLink style={{ display: 'flex', alignItems: 'center' }} to={`/pool/${data.id}`}>View More <ArrowForwardIcon style={{ marginLeft: 6, fontSize: 16 }} /></CellLink>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    height: '115px',
    padding: '24px 0',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    color: switcheo.default.accent,
    fontWeight: 700,
    fontSize: 14,
    minWidth: '1200px',
    position: 'relative',
  },
  overlay: {
    backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #FFFFFF 100%)',
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    zIndex: 2,
    display: 'grid',
    placeContent: 'center',
    position: 'absolute'
  },
  overLayButton: {
    backgroundColor: switcheo.default.accent,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 700,
    color: 'white',
    textTransform: 'none',
    padding: '10px 32px',
    "&:hover": {
      backgroundColor: switcheo.default.accent
    }
  },
  icon: {
    width: '32px',
    height: '32px',
  },
  iconA: {
    bottom: 0,
    left: 0,
    zIndex: 1,
    position: 'absolute'
  },
  iconB: {
    right: 0,
    top: 0,
    position: 'absolute'
  },
  iconContainer: {
    height: 43,
    width: 53,
    marginRight: 20,
    position: 'relative'
  },
  poolName: {
    fontSize: 18,
    color: '#0F214F'
  },
  chainCard: {
    borderColor: switcheo.mono[400],
    color: switcheo.mono[400],
    borderRadius: 4,
    border: `1px solid ${switcheo.text.grey}`,
    padding: '1px 3px',
    marginTop: 8,
    width: 'fit-content',
    fontSize: 12,
    fontWeight: 'normal'
  },
  verticalLine: {
    borderLeft: `1px solid ${switcheo.default.grey}`,
    height: 24
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    border: 'unset',
  },
  label: {
    fontWeight: 'bold',
  },
  notional: {
    color: switcheo.mono[400],
    fontWeight: 'normal',
    fontSize: 12
  },
  filter: {
    marginBottom: theme.spacing(2),
  },
  chartTitle: {
    display: 'block',
  },
  chartTitleFirst: {
    fontWeight: 'bold',
  },
  tooltip: {
    pointer: 'cursor',
    align: 'center',
  },
  chartTitleBox: {
    paddingBottom: theme.spacing(2),
  },
  chartTitleBlock: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  chartTitleBoxVolumeLiquidity: {
    display: 'flex',
    paddingRight: theme.spacing(2),
  },
  rightPadding: {
    paddingRight: theme.spacing(2),
  },
}))

export default LiquidityPoolRow
