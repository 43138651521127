// tslint:disable: no-bitwise
export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : ((r & 0x3) | 0x8)
    return v.toString(16)
  })
}

export const reverseStr = (str: string, charNum: number = 1) => {
  if (!str) return ''
  let oldStr = str
  let newStr = ''
  while (oldStr.length > 0) {
    newStr = `${newStr}${oldStr.substr(-charNum)}`
    oldStr = oldStr.substring(0, oldStr.length - charNum)
  }
  return newStr
}
