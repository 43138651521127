import { IndexedTx } from '@cosmjs/stargate'
import { CarbonTx } from 'carbon-js-sdk'
import { DataLoadSegment, Section, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import React, { ReactElement, useMemo } from 'react'
import LogMessage from './LogMessage'
import { Network } from 'carbon-js-sdk/lib/constant'

interface Props {
  transaction: IndexedTx,
  decodedTx?: CarbonTx.Tx,
  network: Network
}

const TransactMessages: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const { transaction, decodedTx, network } = props
  const [loading] = useTaskSubscriber(TaskNames.Transaction.Detail)
  const msgs = decodedTx?.body?.messages ?? []

  const parsedLog = useMemo(() => {
    const rawLog = transaction.rawLog ?? ''
    let parsedLog: { [key in number | 'error']?: any } = {}
    try {
      parsedLog = JSON.parse(rawLog)
    } catch (err) {
      console.error(err)
      parsedLog = [{ log: transaction.rawLog }]
    }
    return parsedLog
  }, [transaction])

  const logsGroupedByMsgIndex: any = {}

  for (const event of transaction?.events) {
    const msgIndex = event?.attributes.find((o) => o.key === 'msg_index')?.value
    if (msgIndex) {
      if (!logsGroupedByMsgIndex[msgIndex]?.length) logsGroupedByMsgIndex[msgIndex] = []
      logsGroupedByMsgIndex[msgIndex].push(event)
    }
  }

  const finalLog = parsedLog[0] && !logsGroupedByMsgIndex[0] ? parsedLog : logsGroupedByMsgIndex

  return (
    <>
      <Section title="Messages">
        <DataLoadSegment loading={loading}>
          {msgs.map((msg, index) => (
            <LogMessage msgLog={msg} key={index} logs={finalLog[index]} network={network} />
          ))}
          {msgs.length === 0 && (
            <TableEmptyState itemName="messages" />
          )}
        </DataLoadSegment>
      </Section>
    </>
  )
}

export default TransactMessages
