import { Divider, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import { CellLink, TooltipHint } from 'js/components'
import React, { ReactElement } from 'react'

interface Props {
  title: string,
  mainDisplay: string,
  subDisplay: any,
  mainDisplayURL?: string,
  assetURL?: string,
}

const AssetDetailHeroCard: React.FunctionComponent<Props> = (props): ReactElement<Props> => {
  const { title, mainDisplay, subDisplay, mainDisplayURL } = props
  const classes = useStyles()
  return (
    <Paper className={classes.paperWrapper}>
      <div>
        <Typography variant={'h3'}>
          <TooltipHint title="Carbon Interest Bearing Token">
            <span>{title}</span>
          </TooltipHint>
        </Typography>
      </div>
      <Divider className={classes.dividerTop} />
      <div>
        <Typography variant={'h1'} className={classes.mainDisplay}>
          {
            mainDisplayURL ? <CellLink to={mainDisplayURL}>{mainDisplay}</CellLink>
              : mainDisplay
          }
        </Typography>
      </div>
      <Divider className={classes.dividerBottom} />
      <div>
        <Typography variant={'body1'}>
          {subDisplay}
        </Typography>
      </div>
    </Paper>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  mainDisplay: {
    fontSize: '2rem',
  },
  paperWrapper: {
    padding: theme.spacing(2),
    boxSizing: 'content-box'
  },
  breadcrumb: {
    margin: theme.spacing(1, 0, 2, 0),
    color: theme.palette.text.primary,
  },
  dividerTop: {
    margin: "1em 0em 0.5em 0em"
  },
  dividerBottom: {
    margin: "0.5em 0em 1em 0em"
  }
}))

export default AssetDetailHeroCard
