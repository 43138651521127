import { Models } from 'carbon-js-sdk'
import { Stake } from 'carbon-js-sdk/lib/insights'
import { Action } from 'redux'

export enum ValidatorsActionType {
  CLEAR = 'CLEAR_ACCOUNT',
  SET_SLASHING_PARAMS = 'SET_SLASHING_PARAMS',
  SET_DISTRIBUTION_PARAMS = 'SET_DISTRIBUTION_PARAMS',
  SET_BLOCK_TIME = 'SET_BLOCK_TIME',
  SET_TOTAL_STAKED_CHART_DATA = 'SET_TOTAL_STAKED_CHART_DATA',
  UPDATE_DATE_FILTERS = 'UPDATE_DATE_FILTERS',
}

export type ValidatorsAction =
  | ClearAction
  | SetSlashingParamsAction
  | SetDistributionParamsAction
  | SetBlockTimeAction
  | SetTotalStakedChartDataAction
  | UpdateDateFiltersAction

export interface ClearAction extends Action<ValidatorsActionType.CLEAR> {}

export function clear(): ClearAction {
  return {
    type: ValidatorsActionType.CLEAR,
  }
}

export interface SetSlashingParamsAction
  extends Action<ValidatorsActionType.SET_SLASHING_PARAMS> {
  slashingParams?: Models.Slashing.Params
}

export function setSlashingParams(slashingParams?: Models.Slashing.Params): SetSlashingParamsAction {
  return {
    type: ValidatorsActionType.SET_SLASHING_PARAMS,
    slashingParams,
  }
}

export interface SetDistributionParamsAction
  extends Action<ValidatorsActionType.SET_DISTRIBUTION_PARAMS> {
  distributionParams?: Models.Distribution.Params
}

export function setDistributionParams(
  distributionParams?: Models.Distribution.Params,
): SetDistributionParamsAction {
  return {
    type: ValidatorsActionType.SET_DISTRIBUTION_PARAMS,
    distributionParams,
  }
}

export interface SetBlockTimeAction
  extends Action<ValidatorsActionType.SET_BLOCK_TIME> {
  blockTime: number
}

export function setBlockTime(blockTime: number): SetBlockTimeAction {
  return {
    type: ValidatorsActionType.SET_BLOCK_TIME,
    blockTime,
  }
}

export interface SetTotalStakedChartDataAction
  extends Action<ValidatorsActionType.SET_TOTAL_STAKED_CHART_DATA> {
  totalStakedChartData: Stake[]
}

export function setTotalStakedChartData(totalStakedChartData: Stake[]): SetTotalStakedChartDataAction {
  return {
    type: ValidatorsActionType.SET_TOTAL_STAKED_CHART_DATA,
    totalStakedChartData,
  }
}
export interface UpdateDateFiltersAction
  extends Action<ValidatorsActionType.UPDATE_DATE_FILTERS> {
  options: any
}

export function updateDateFilters(options: any): UpdateDateFiltersAction {
  return {
    type: ValidatorsActionType.UPDATE_DATE_FILTERS,
    options,
  }
}
