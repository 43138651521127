import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { CellLink, DataLoadSegment, DatetimeCell, ListTable, Section, StandardBtn, TableEmptyState } from 'js/components'
import { TaskNames } from 'js/constants'
import { useRedux, useTaskSubscriber } from 'js/hooks'
import { SimpleBlock } from 'js/models'
import React, { ReactElement, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

interface Props {
}

// eslint-disable-next-line no-empty-pattern
const Blocks: React.FunctionComponent<Props> = ({
}: Props): ReactElement<Props> => {
  const classes = useStyles()
  const blocks = useSelector((state: any) => state.core.blocks)
  const varAddrMap = useRedux(state => state.core.valAddrMap);
  const [loading] = useTaskSubscriber(TaskNames.App.Validators, TaskNames.Dashboard.Blocks)

  const blocksToShow = blocks.slice(0, 5);

  return (
    <Section className={classes.container} title="Latest Blocks">
      <DataLoadSegment loading={loading}>
        <ListTable className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Height</TableCell>
              <TableCell className={classes.wideTitle}>Proposer</TableCell>
              <TableCell align="right">Txs</TableCell>
              <TableCell align="right" className={classes.wideTitle}>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TransitionGroup
              enter
              component={null}
              appear={false}
              exit={false}
            >
              {blocksToShow.map(renderBlock)}
            </TransitionGroup>
          </TableBody>
        </ListTable>
        {blocksToShow.length > 0 && (
          <StandardBtn
            className={classes.button}
            color="secondary"
            to="/blocks"
            variant="contained"
          >
            View More
          </StandardBtn>
        )}
        {blocksToShow.length === 0 && (
          <TableEmptyState itemName="blocks" />
        )}
      </DataLoadSegment>
    </Section>
  )

  function renderBlock(block: SimpleBlock, index: number): ReactNode {
    const height = block.height
    const time = block.time
    const proposerAddress = block.proposerAddress
    const valPair = Object.values(varAddrMap).find((pair) => {
      if (!pair.tmValidator?.address) return false;
      return Buffer.from(pair.tmValidator?.address).toString("hex") === proposerAddress;
    })
    return (
      <CSSTransition key={index} timeout={400}>
        <TableRow hover>
          <TableCell>
            <CellLink to={`/block/${height}`}>{height}</CellLink>
          </TableCell>
          <TableCell style={{ width: "100%" }}>
            {!valPair ? (
              proposerAddress
            ) : (
              <CellLink to={`/validator/${valPair.carbonValidator.operatorAddress}`}>
                {valPair.carbonValidator.description?.moniker}
              </CellLink>
            )}
          </TableCell>
          <TableCell align="right">{block.txCount}</TableCell>
          <DatetimeCell data={time} />
        </TableRow>
      </CSSTransition>
    )
  }
}

const useStyles: any = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    fontSize: '1.375rem',
    fontWeight: 700,
    paddingBottom: theme.spacing(2),
  },
  row: {
    height: '3.3125rem',
    '&.enter': {
      opacity: 0.1,
    },
    '&.enter-active': {
      opacity: 1,
      transition: 'opacity 400ms ease-in',
    },
  },
  wideTitle: {
    minWidth: '10.625rem',
  },
  table: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: 'auto',
  },
}))

export default Blocks
