import { CarbonTx } from 'carbon-js-sdk'
import { MsgCreateToken } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/tx'
import { DecCoin } from 'carbon-js-sdk/lib/codec/cosmos/base/v1beta1/coin'
import { MsgSubmitProposal } from 'carbon-js-sdk/lib/codec/cosmos/gov/v1/tx'
import { CreateTokenProposalFormState } from 'js/models'
import store from 'js/store'
import { escapeHtmlGo } from 'js/utils'

export function createTokenProposalValue(proposer: string | undefined, initialDeposit: DecCoin[]): MsgSubmitProposal {
  const state = store.getState()
  const formState = state.governance.submitProposalFormState as CreateTokenProposalFormState
  const sdk = state.core.carbonSDK
  const wallet = sdk?.getConnectedWallet()
  const { title, summary, metadata, name, symbol, decimals,
    bridgeId, chainId, tokenAddress, lockProxyHash } = formState

  const msg = MsgCreateToken.fromPartial({
    creator: wallet?.bech32Address!,
    createTokenParams: {
      name,
      symbol,
      decimals: parseInt(decimals),
      chainId: parseInt(chainId),
      bridgeId: parseInt(bridgeId),
      bridgeAddress: lockProxyHash.replace(/^0x/, '').toLowerCase(),
      tokenAddress: tokenAddress.replace(/^0x/, '').toLowerCase(),
    }
  })

  const value = {
    title: escapeHtmlGo(title),
    summary: escapeHtmlGo(summary),
    metadata: escapeHtmlGo(metadata),
    initialDeposit,
    expedited: false,
    proposer: proposer ?? '',
    messages: [
      {
        typeUrl: CarbonTx.Types.MsgCreateToken,
        value: MsgCreateToken.encode(msg).finish()
      }
    ]
  }

  return value
}
