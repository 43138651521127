import { StyleRules, Theme } from '@material-ui/core'
import { ComponentNameToClassKey } from '@material-ui/core/styles/overrides'
import { switcheo } from '../palettes/colors'

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  theme: Theme,
): Partial<StyleRules<ComponentNameToClassKey['MuiSelect']>> => ({
  filled: {
    backgroundColor: switcheo.background.grey,
    color: theme.palette.primary.contrastText,
    borderRadius: 8,
    fontWeight: 'bold',
    fontSize: '0.875rem',
    '&:focus': {
      backgroundColor: switcheo.background.grey,
      borderRadius: 8,
    },
  },
  root: {
    padding: theme.spacing(1.5),
  },
})
