import { InputAdornment, InputBaseProps } from '@material-ui/core'
import React from 'react'

export interface Module {
  name: string
  params: string[]
}
export const Modules: Module[] = [
  {
    name: 'auth',
    params: [
      "maxMemoCharacters",
      "txSigLimit",
      "txSizeCostPerByte",
      "sigVerifyCostED25519",
      "sigVerifyCostSecp256k1",
    ],
  },
  {
    name: 'bank',
    params: [
      "defaultSendEnabled",
    ],
  },
  {
    name: 'gov',
    params: [
      "depositparams",
      "votingparams",
      "quorum",
      "threshold",
      "vetoThreshold",
    ],
  },
  {
    name: 'distribution',
    params: [
      "communityTax",
      "withdrawAddrEnabled",
    ],
  },
  {
    name: 'slashing',
    params: [
      "signedBlocksWindow",
      "minSignedPerWindow",
      "downtimeJailDuration",
      "slashFractionDoubleSign",
      "slashFractionDowntime",
    ],
  },
  {
    name: 'staking',
    params: [
      "unbondingTime",
      "maxValidators",
      "keyMaxEntries",
      "historicalEntries",
      "bondDenom",
    ],
  },
  {
    name: 'oracle',
    params: [
      "oracleSlashEnabled",
      "oracleSlashWindowBlock",
      "oracleSlashInitial",
      "oracleSlashIncrement",
      "oracleContinuousSlashMax",
      "newlyBondedWindowAllowance",
      "voteTimestampFutureAllowance",
      "oracleMinVoteFactor",
      "maxPowerToSlashFactor",
    ],
  },
]

const decimalInputProps: InputBaseProps = {
  type: 'number',
}
const percentInputProps: InputBaseProps = {
  type: 'number',
  inputProps: { min: 0, max: 100 },
  endAdornment: (
    <InputAdornment position="end">%</InputAdornment>
  ),
}
const unintInputProps: InputBaseProps = {
  type: 'number',
  inputProps: { min: 0, step: 1 },
}
const coinInputProps: InputBaseProps = {
  type: 'number',
  inputProps: { min: 0 },
  endAdornment: (
    <InputAdornment position="end">SWTH</InputAdornment>
  ),
}
const timeInputProps: InputBaseProps = {
  type: 'number',
  inputProps: { min: 0 },
  endAdornment: (
    <InputAdornment position="end">hours</InputAdornment>
  ),
}

type ModuleParamsTypeObject = {
  parameter: string
  type: string
  example: string
  inputProps?: InputBaseProps
}
const ModuleParamsTypes: ModuleParamsTypeObject[] = [
  { parameter: 'maxMemoCharacters', type: 'uint64', example: '256', inputProps: unintInputProps },
  { parameter: 'txSigLimit', type: 'uint64', example: '7', inputProps: unintInputProps },
  { parameter: 'txSizeCostPerByte', type: 'uint64', example: '10', inputProps: unintInputProps },
  { parameter: 'sigVerifyCostED25519', type: 'uint64', example: '590', inputProps: unintInputProps },
  { parameter: 'sigVerifyCostSecp256k1', type: 'uint64', example: '1000', inputProps: unintInputProps },
  { parameter: 'defaultSendEnabled', type: 'bool', example: 'true' },
  { parameter: 'depositparams-min_deposit', type: 'coin', example: '1.0000000', inputProps: coinInputProps },
  { parameter: 'depositparams-max_deposit_period', type: 'time', example: '48', inputProps: timeInputProps },
  { parameter: 'votingparams-voting_period', type: 'time', example: '48', inputProps: timeInputProps },
  { parameter: 'quorum', type: 'percent', example: '33.4%', inputProps: percentInputProps },
  { parameter: 'threshold', type: 'percent', example: '50%', inputProps: percentInputProps },
  { parameter: 'vetoThreshold', type: 'percent', example: '33.4%', inputProps: percentInputProps },
  { parameter: 'communityTax', type: 'number', example: '0.1%', inputProps: decimalInputProps },
  { parameter: 'withdrawAddrEnabled', type: 'bool', example: 'true' },
  { parameter: 'signedBlocksWindow', type: 'uint64', example: '100', inputProps: unintInputProps },
  { parameter: 'minSignedPerWindow', type: 'number', example: '0.500000000000000000', inputProps: decimalInputProps },
  { parameter: 'downtimeJailDuration', type: 'time', example: '0.166667', inputProps: timeInputProps },
  { parameter: 'slashFractionDoubleSign', type: 'number', example: '0.050000000000000000', inputProps: decimalInputProps },
  { parameter: 'slashFractionDowntime', type: 'number', example: '0.010000000000000000', inputProps: decimalInputProps },
  { parameter: 'unbondingTime', type: 'time', example: '72', inputProps: timeInputProps },
  { parameter: 'maxValidators', type: 'uint16', example: '100', inputProps: unintInputProps },
  { parameter: 'keyMaxEntries', type: 'uint16', example: '7', inputProps: unintInputProps },
  { parameter: 'historicalEntries', type: 'uint16', example: '3', inputProps: unintInputProps },

  { parameter: 'oracleSlashEnabled', type: 'bool', example: 'true' },
  { parameter: 'oracleSlashWindowBlock', type: 'uint32', example: '1600', inputProps: unintInputProps },
  { parameter: 'oracleSlashInitial', type: 'number', example: '0.000000000000000000', inputProps: decimalInputProps },
  { parameter: 'oracleSlashIncrement', type: 'number', example: '0.000000491000000000', inputProps: decimalInputProps },
  { parameter: 'oracleContinuousSlashMax', type: 'uint32', example: '24', inputProps: unintInputProps },
  { parameter: 'newlyBondedWindowAllowance', type: 'uint32', example: '1', inputProps: unintInputProps },
  { parameter: 'voteTimestampFutureAllowance', type: 'time', example: '3600', inputProps: timeInputProps },
  { parameter: 'oracleMinVoteFactor', type: 'number', example: '0.250000000000000000', inputProps: decimalInputProps },
  { parameter: 'maxPowerToSlashFactor', type: 'number', example: '0.700000000000000000', inputProps: decimalInputProps },
  { parameter: 'bondDenom', type: 'string', example: '"swth"' },
]

export const getParamType = (parameter: string) => {
  return ModuleParamsTypes.find((moduleParamType) => {
    return moduleParamType.parameter === parameter
  })
}
