import { makeStyles, TableBody, Theme } from '@material-ui/core'
import { Models } from 'carbon-js-sdk'
import { DataLoadSegment, ListTable, Section } from 'js/components'
import { TaskNames } from 'js/constants'
import { useTaskSubscriber } from 'js/hooks'
import { adjustHuman } from 'js/utils'
import React, { ReactElement } from 'react'
import { ListTableRow } from '../components'

type Props = {
  distributionParams?: Models.Distribution.Params
}

const DistributionParams: React.FunctionComponent<Props> = (
  props: Props,
): ReactElement<Props> => {
  const classes = useStyles()
  const { distributionParams } = props
  const [loading] = useTaskSubscriber(TaskNames.Validators.Distribution)

  const cellFormat = (key: keyof Models.Distribution.Params) => {
    switch (key) {
      case 'withdrawAddrEnabled':
        return distributionParams?.[key] ? 'Yes' : 'No'
      case 'liquidityProviderReward':
        return distributionParams?.[key] ? `${adjustHuman(distributionParams[key]).times(100).toFormat(2)}%` : '-'
      case 'communityTax':
        return distributionParams?.[key] ? `${adjustHuman(distributionParams[key]).times(100).toFormat(2)}%` : '-'
      default:
        return distributionParams?.[key] ?? '-'
    }
  }

  return (
    <Section title="Distribution Parameters" className={classes.section}>
      <DataLoadSegment loading={loading || !distributionParams}>
        {
          distributionParams && (
            <ListTable>
              <TableBody>
                {
                  Object.keys(distributionParams).map((each, index) => {
                    const label = each?.replace(/([A-Z])/g, " $1")
                    return (
                      <ListTableRow
                        headerClass={classes.headerClass}
                        key={index}
                        keyVal={label}
                        valueClass={classes.valueClass}
                      >
                        {cellFormat(each as keyof Models.Distribution.Params)}
                      </ListTableRow>
                    )
                  },
                  )}
              </TableBody>
            </ListTable>
          )
        }
      </DataLoadSegment>
    </Section>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  headerClass: {
    [theme.breakpoints.only('xs')]: {
      width: '60%',
    },
  },
  section: {
    height: '100%',
  },
  valueClass: {
    [theme.breakpoints.only('xs')]: {
      width: '40%',
    },
  },
}))

export default DistributionParams
